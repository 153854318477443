/** @format */

import React from "react";

class Trailers extends React.Component {
  state = {
    durationdata: [],
  };
  // reduce decimal to one place
  roundDecimal = (float) => {
    const rounded = Math.round(float * 10) / 10;
    return rounded;
  };

  // event delegation for the Movies links.
  handleClick(e, id, titleString, year) {
    e.preventDefault();
    this.goToMoviePage(id, titleString, year);
  }
  // make a random color for the placeholder link
  // for movies with no poster available.
  getRandomBgColor = () => {
    var somecolors = [
      "c238c2",
      "c23838",
      "614875",
      "2548a1",
      "25a14c",
      "9ba125",
      "a17a25",
      "a12525",
      "f0820c",
      "d61773",
      "ce17d4",
      "601ea6",
      "2a1fa6",
      "0bb8db",
      "0dd91e",
      "388c3f",
      "378a73",
      "d45137",
    ];

    const rand = Math.floor(Math.random() * somecolors.length);
    const uriSegA = "https://dummyimage.com/285x437/";
    const uriSegB = "/ffffff.png&text=Poster+Not+Available";
    return uriSegA + somecolors[rand] + uriSegB;
  };

  getYouTubeTrailerDuration(id, index) {

    fetch(
      "https://www.googleapis.com/youtube/v3/videos?id=" +
        id +
        "&part=contentDetails&key=AIzaSyCeWzb-EMjQaWpkHQ1p2IqhDHmh_dCPtJs"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        console.log(index);
        /* else console.log (response.json()); */
        //response.json().then(data => console.log(data.items[0].contentDetails.duration))
      })
      .then((data) => {
        this.setState((prevState) => ({
          durationdata: prevState.data.concat(data),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
    //return 'https://www.youtube.com/watch?v=' + thelink;
  }
  // take movie title, return URI friendly string.
  goToMoviePage = (id, titleString, year) => {
    const regex = / /gi;
    const regexDash = /---/gi;
    const regexspecial = /[:'()…,'!.?\][]/gi;
    const regexAmp = /&/gi;
    const regexSlash = /[/]/gi;
    const regexMisc = /½/gi;
    const regexSup2 = /[²]/gi;
    const regexSup3 = /[³]/gi;

    const tt = titleString
      .replace(regex, "-")
      .replace(regexDash, "-")
      .replace(regexspecial, "")
      .replace(regexAmp, "and")
      .replace(regexSlash, "-")
      .replace(regexMisc, "-and-a-half")
      .replace(regexSup2, "-2")
      .replace(regexSup3, "-3")
      .toLocaleLowerCase();

    const rooturi = "";
    const page = "/movies/" + id + "/" + tt + "-" + year;
    document.location = rooturi + page;
  };
  render() {
    if (this.props.pagedata === null || this.props.pagedata === undefined) {
      // Render loading state ...
      const index = this.props.count;
      return (
        <React.Fragment>
          <div className="movie-item-style-2 movie-item-style-1">loading</div>
        </React.Fragment>
      );
    } else {
      const { poster_path, original_title, vote_average, release_date, id } =
        this.props.pagedata;
      const index = this.props.pagedata.length;
      return (
        <React.Fragment>
          <div className="trailers">
            <div className="container">
              <div className="row ipad-width">
                <div className="col-md-12">
                  <div className="title-hd">
                    <h2>Recently Added Trailers</h2>
                    {/* <a href='#' className='viewall'>
											View all <i className='ion-ios-arrow-right'></i>
										</a> */}
                  </div>
                  <div className="videos">
                    <div className="slider-for-2 video-ft">
                      {Object.keys(this.props.pagedata).map((key, index) => (
                        <div key={key}>
                          {this.props.pagedata[key] !== undefined && (
                            <iframe
                              className="item-video"
                              src=""
                              data-src={
                                "https://www.youtube.com/embed/" +
                                this.props.pagedata[key].key
                              }
                            ></iframe>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="slider-nav-2 thumb-ft">
                      {Object.keys(this.props.pagedata).map((key, index) => (
                        <div className="item" key={key}>
                          {this.props.pagedata[key] !== undefined && (
                            <div>
                              <div className="trailer-img">
                                <img
                                  src={
                                    "https://img.youtube.com/vi/" +
                                    this.props.pagedata[key].key +
                                    "/default.jpg"
                                  }
                                  alt={
                                    this.props.pagedata[key].name.split("|")[0]
                                  }
                                  width="4096"
                                  height="2737"
                                  style={{
                                    minWidth: "100px",
                                    maxWidth: "100px",
                                  }}
                                />
                              </div>
                              <div className="trailer-infor">
                                <h4 className="desc">
                                  {this.props.pagedata[key].name.split("|")[0]}
                                </h4>
                                <p>
                                  {/* {this.getYouTubeTrailerDuration(this.props.pagedata[key].key)} */}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default Trailers;
