/** @format */

const movies = {
  "2024-08-14T19:35:45Z": {
    type: "movie",
    title: "Disappear Completely",
    year: 2024,
    trakt_rating: 5.53165,
    trakt_id: 504912,
    imdb_id: "tt8851084",
    tmdb_id: 671317,
    url: "https://trakt.tv/movies/disappear-completely-2024",
    released: "2024-02-29",
    runtime: 100,
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-08-12T13:44:38Z": {
    type: "movie",
    title: "Starve Acre",
    year: 2024,
    trakt_rating: 5.73643,
    trakt_id: 748426,
    imdb_id: "tt17521612",
    tmdb_id: 933090,
    url: "https://trakt.tv/movies/starve-acre-2024",
    released: "2024-07-26",
    runtime: 98,
    genres: "horror,drama",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-08-09T21:24:34Z": {
    type: "movie",
    title: "The Ministry of Ungentlemanly Warfare",
    year: 2024,
    trakt_rating: 7.47507,
    trakt_id: 634646,
    imdb_id: "tt5177120",
    tmdb_id: 799583,
    url: "https://trakt.tv/movies/the-ministry-of-ungentlemanly-warfare-2024",
    released: "2024-04-19",
    runtime: 120,
    genres: "war,action,comedy",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-08-09T14:52:50Z": {
    type: "movie",
    title: "The Devil's Bath",
    year: 2024,
    trakt_rating: 6.56,
    trakt_id: 747553,
    imdb_id: "tt29141112",
    tmdb_id: 931944,
    url: "https://trakt.tv/movies/the-devil-s-bath-2024",
    released: "2024-03-08",
    runtime: 121,
    genres: "horror,mystery,history",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-08-09T14:06:36Z": {
    type: "movie",
    title: "Furiosa: A Mad Max Saga",
    year: 2024,
    trakt_rating: 7.46839,
    trakt_id: 623532,
    imdb_id: "tt12037194",
    tmdb_id: 786892,
    url: "https://trakt.tv/movies/furiosa-a-mad-max-saga-2024",
    released: "2024-05-24",
    runtime: 149,
    genres: "action,science-fiction,adventure",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-08-05T16:58:28Z": {
    type: "movie",
    title: "Despicable Me 4",
    year: 2024,
    trakt_rating: 6.95502,
    trakt_id: 367444,
    imdb_id: "tt7510222",
    tmdb_id: 519182,
    url: "https://trakt.tv/movies/despicable-me-4-2024",
    released: "2024-07-03",
    runtime: 94,
    genres: "animation,family,comedy,superhero,action",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-08-04T14:27:25Z": {
    type: "movie",
    title: "The King Tide",
    year: 2024,
    trakt_rating: 6.14894,
    trakt_id: 934304,
    imdb_id: "tt6632018",
    tmdb_id: 1159477,
    url: "https://trakt.tv/movies/the-king-tide-2024",
    released: "2024-04-26",
    runtime: 100,
    genres: "thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-08-02T14:18:12Z": {
    type: "movie",
    title: "MaXXXine",
    year: 2024,
    trakt_rating: 6.63939,
    trakt_id: 823073,
    imdb_id: "tt22048412",
    tmdb_id: 1023922,
    url: "https://trakt.tv/movies/maxxxine-2024",
    released: "2024-07-05",
    runtime: 104,
    genres: "horror,crime,mystery",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-07-26T13:04:26Z": {
    type: "movie",
    title: "A Quiet Place: Day One",
    year: 2024,
    trakt_rating: 6.82744,
    trakt_id: 600962,
    imdb_id: "tt13433802",
    tmdb_id: 762441,
    url: "https://trakt.tv/movies/a-quiet-place-day-one-2024",
    released: "2024-06-28",
    runtime: 99,
    genres: "horror,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-07-20T16:12:57Z": {
    type: "movie",
    title: "Thelma",
    year: 2024,
    trakt_rating: 7.50781,
    trakt_id: 845969,
    imdb_id: "tt23778968",
    tmdb_id: 1051891,
    url: "https://trakt.tv/movies/thelma-2024",
    released: "2024-06-21",
    runtime: 98,
    genres: "action,comedy,adventure",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-07-12T21:56:25Z": {
    type: "movie",
    title: "Megamind vs. the Doom Syndicate",
    year: 2024,
    trakt_rating: 5.32915,
    trakt_id: 1002115,
    imdb_id: "tt31122323",
    tmdb_id: 1239251,
    url: "https://trakt.tv/movies/megamind-vs-the-doom-syndicate-2024",
    released: "2024-03-01",
    runtime: 83,
    genres: "animation,comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-07-12T21:55:59Z": {
    type: "movie",
    title: "IF",
    year: 2024,
    trakt_rating: 7.15064,
    trakt_id: 478218,
    imdb_id: "tt11152168",
    tmdb_id: 639720,
    url: "https://trakt.tv/movies/if-2024",
    released: "2024-05-17",
    runtime: 104,
    genres: "comedy,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-07-09T13:37:55Z": {
    type: "movie",
    title: "The Exorcism",
    year: 2024,
    trakt_rating: 4.43836,
    trakt_id: 483953,
    imdb_id: "tt11240348",
    tmdb_id: 646683,
    url: "https://trakt.tv/movies/the-exorcism-2024",
    released: "2024-06-21",
    runtime: 95,
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-07-09T11:57:39Z": {
    type: "movie",
    title: "The Bikeriders",
    year: 2024,
    trakt_rating: 6.94118,
    trakt_id: 810586,
    imdb_id: "tt21454134",
    tmdb_id: 1008409,
    url: "https://trakt.tv/movies/the-bikeriders-2024",
    released: "2024-06-21",
    runtime: 116,
    genres: "drama,crime",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-06-29T13:35:22Z": {
    type: "movie",
    title: "The Watchers",
    year: 2024,
    trakt_rating: 6.08911,
    trakt_id: 873670,
    imdb_id: "tt26736843",
    tmdb_id: 1086747,
    url: "https://trakt.tv/movies/the-watchers-2024",
    released: "2024-06-07",
    runtime: 102,
    genres: "mystery,horror,fantasy",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-06-29T13:01:07Z": {
    type: "movie",
    title: "Totally Killer",
    year: 2023,
    trakt_rating: 6.78311,
    trakt_id: 783573,
    imdb_id: "tt11426232",
    tmdb_id: 974931,
    url: "https://trakt.tv/movies/totally-killer-2023",
    released: "2023-10-05",
    runtime: 105,
    genres: "horror,comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-06-17T11:17:51Z": {
    type: "movie",
    title: "New Life",
    year: 2024,
    trakt_rating: 6.19512,
    trakt_id: 924778,
    imdb_id: "tt18973958",
    tmdb_id: 1148027,
    url: "https://trakt.tv/movies/new-life-2024",
    released: "2024-05-03",
    runtime: 85,
    genres: "horror,thriller,mystery,science-fiction",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-06-04T11:07:59Z": {
    type: "movie",
    title: "We Were Once Kids",
    year: 2021,
    trakt_rating: 6.57692,
    trakt_id: 654484,
    imdb_id: "tt14502642",
    tmdb_id: 821433,
    url: "https://trakt.tv/movies/we-were-once-kids-2021",
    released: "2021-08-06",
    runtime: 88,
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-05-27T17:21:53Z": {
    type: "movie",
    title: "The First Omen",
    year: 2024,
    trakt_rating: 6.60722,
    trakt_id: 304347,
    imdb_id: "tt5672290",
    tmdb_id: 437342,
    url: "https://trakt.tv/movies/the-first-omen-2024",
    released: "2024-04-05",
    runtime: 119,
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-05-24T11:09:21Z": {
    type: "movie",
    title: "Civil War",
    year: 2024,
    trakt_rating: 7.29869,
    trakt_id: 745438,
    imdb_id: "tt17279496",
    tmdb_id: 929590,
    url: "https://trakt.tv/movies/civil-war-2024",
    released: "2024-04-11",
    runtime: 109,
    genres: "action,war,drama",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-05-15T19:50:24Z": {
    type: "movie",
    title: "Godzilla Minus One",
    year: 2023,
    trakt_rating: 7.95431,
    trakt_id: 755432,
    imdb_id: "tt23289160",
    tmdb_id: 940721,
    url: "https://trakt.tv/movies/godzilla-minus-one-2023",
    released: "2023-11-03",
    runtime: 125,
    genres: "science-fiction,action,horror",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-05-14T17:25:36Z": {
    type: "movie",
    title: "Godzilla x Kong: The New Empire",
    year: 2024,
    trakt_rating: 7.12117,
    trakt_id: 656266,
    imdb_id: "tt14539740",
    tmdb_id: 823464,
    url: "https://trakt.tv/movies/godzilla-x-kong-the-new-empire-2024",
    released: "2024-03-29",
    runtime: 115,
    genres: "action,science-fiction,adventure",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-05-14T17:17:24Z": {
    type: "movie",
    title: "The Iron Claw",
    year: 2023,
    trakt_rating: 7.54979,
    trakt_id: 679147,
    imdb_id: "tt21064584",
    tmdb_id: 850165,
    url: "https://trakt.tv/movies/the-iron-claw-2023",
    released: "2023-12-22",
    runtime: 132,
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-05-14T17:17:15Z": {
    type: "movie",
    title: "Abigail",
    year: 2024,
    trakt_rating: 6.98538,
    trakt_id: 894636,
    imdb_id: "tt27489557",
    tmdb_id: 1111873,
    url: "https://trakt.tv/movies/abigail-2024",
    released: "2024-04-19",
    runtime: 109,
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-05-12T17:39:09Z": {
    type: "movie",
    title: "Tremors 2: Aftershocks",
    year: 1996,
    trakt_rating: 6.40634,
    trakt_id: 6206,
    imdb_id: "tt0114720",
    tmdb_id: 11069,
    url: "https://trakt.tv/movies/tremors-2-aftershocks-1996",
    released: "1996-04-09",
    runtime: 99,
    genres: "action,comedy,science-fiction,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-05-05T12:52:05Z": {
    type: "movie",
    title: "Turtles All the Way Down",
    year: 2024,
    trakt_rating: 7.31343,
    trakt_id: 425296,
    imdb_id: "tt7737224",
    tmdb_id: 574451,
    url: "https://trakt.tv/movies/turtles-all-the-way-down-2024",
    released: "2024-05-02",
    runtime: 111,
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-05-04T23:15:46Z": {
    type: "movie",
    title: "Mars Express",
    year: 2023,
    trakt_rating: 7.64748,
    trakt_id: 434196,
    imdb_id: "tt26915336",
    tmdb_id: 586810,
    url: "https://trakt.tv/movies/mars-express-2023",
    released: "2023-11-22",
    runtime: 85,
    genres: "science-fiction,animation,action,mystery",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-30T13:49:58Z": {
    type: "movie",
    title: "Infested",
    year: 2023,
    trakt_rating: 6.62827,
    trakt_id: 856069,
    imdb_id: "tt26744289",
    tmdb_id: 1063879,
    url: "https://trakt.tv/movies/infested-2023",
    released: "2023-12-27",
    runtime: 106,
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-27T23:24:56Z": {
    type: "movie",
    title: "The Coffee Table",
    year: 2024,
    trakt_rating: 7.33333,
    trakt_id: 849714,
    imdb_id: "tt21874760",
    tmdb_id: 1056380,
    url: "https://trakt.tv/movies/the-coffee-table-2024",
    released: "2024-04-19",
    runtime: 90,
    genres: "drama,thriller,comedy,horror",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-25T13:58:06Z": {
    type: "movie",
    title: "Love Lies Bleeding",
    year: 2024,
    trakt_rating: 6.8299,
    trakt_id: 762235,
    imdb_id: "tt19637052",
    tmdb_id: 948549,
    url: "https://trakt.tv/movies/love-lies-bleeding-2024",
    released: "2024-03-08",
    runtime: 104,
    genres: "thriller,crime,romance",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-23T12:13:19Z": {
    type: "movie",
    title: "Arthur the King",
    year: 2024,
    trakt_rating: 6.875,
    trakt_id: 461598,
    imdb_id: "tt10720352",
    tmdb_id: 618588,
    url: "https://trakt.tv/movies/arthur-the-king-2024",
    released: "2024-03-15",
    runtime: 107,
    genres: "drama,adventure",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-23T12:12:16Z": {
    type: "movie",
    title: "Monkey Man",
    year: 2024,
    trakt_rating: 7.31717,
    trakt_id: 525826,
    imdb_id: "tt9214772",
    tmdb_id: 560016,
    url: "https://trakt.tv/movies/monkey-man-2024",
    released: "2024-04-05",
    runtime: 121,
    genres: "thriller,action,crime",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-21T20:59:06Z": {
    type: "movie",
    title: "The Platform",
    year: 2019,
    trakt_rating: 6.97966,
    trakt_id: 464098,
    imdb_id: "tt8228288",
    tmdb_id: 619264,
    url: "https://trakt.tv/movies/the-platform-2019",
    released: "2019-11-08",
    runtime: 95,
    genres: "drama,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-21T01:52:05Z": {
    type: "movie",
    title: "Late Night with the Devil",
    year: 2024,
    trakt_rating: 7.18448,
    trakt_id: 753560,
    imdb_id: "tt14966898",
    tmdb_id: 938614,
    url: "https://trakt.tv/movies/late-night-with-the-devil-2024",
    released: "2024-04-19",
    runtime: 93,
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-22T23:03:28Z": {
    type: "movie",
    title: "Kung Fu Panda 3",
    year: 2016,
    trakt_rating: 7.49206,
    trakt_id: 93870,
    imdb_id: "tt2267968",
    tmdb_id: 140300,
    url: "https://trakt.tv/movies/kung-fu-panda-3-2016",
    released: "2016-01-29",
    runtime: 95,
    genres: "action,adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-22T23:03:14Z": {
    type: "movie",
    title: "Kung Fu Panda 2",
    year: 2011,
    trakt_rating: 7.44513,
    trakt_id: 34439,
    imdb_id: "tt1302011",
    tmdb_id: 49444,
    url: "https://trakt.tv/movies/kung-fu-panda-2-2011",
    released: "2011-05-26",
    runtime: 91,
    genres: "animation,family,comedy",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-20T22:09:18Z": {
    type: "movie",
    title: "Kung Fu Panda",
    year: 2008,
    trakt_rating: 7.65988,
    trakt_id: 4783,
    imdb_id: "tt0441773",
    tmdb_id: 9502,
    url: "https://trakt.tv/movies/kung-fu-panda-2008",
    released: "2008-06-06",
    runtime: 90,
    genres: "animation,family,adventure,comedy,action",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-17T22:14:45Z": {
    type: "movie",
    title: "Ghostbusters: Frozen Empire",
    year: 2024,
    trakt_rating: 6.87876,
    trakt_id: 778125,
    imdb_id: "tt21235248",
    tmdb_id: 967847,
    url: "https://trakt.tv/movies/ghostbusters-frozen-empire-2024",
    released: "2024-03-22",
    runtime: 115,
    genres: "fantasy,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-16T11:56:09Z": {
    type: "movie",
    title: "Immaculate",
    year: 2024,
    trakt_rating: 6.23849,
    trakt_id: 838057,
    imdb_id: "tt23137390",
    tmdb_id: 1041613,
    url: "https://trakt.tv/movies/immaculate-2024",
    released: "2024-03-22",
    runtime: 89,
    genres: "horror,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-15T22:28:29Z": {
    type: "movie",
    title: "Out of Darkness",
    year: 2024,
    trakt_rating: 5.9152,
    trakt_id: 656423,
    imdb_id: "tt7527682",
    tmdb_id: 823491,
    url: "https://trakt.tv/movies/out-of-darkness-2024",
    released: "2024-02-09",
    runtime: 87,
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-15T22:23:38Z": {
    type: "movie",
    title: "You'll Never Find Me",
    year: 2024,
    trakt_rating: 6.02303,
    trakt_id: 899043,
    imdb_id: "tt22023218",
    tmdb_id: 1117321,
    url: "https://trakt.tv/movies/you-ll-never-find-me-2024",
    released: "2024-03-14",
    runtime: 96,
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-09T20:31:57Z": {
    type: "movie",
    title: "Sleeping Dogs",
    year: 2024,
    trakt_rating: 6.75806,
    trakt_id: 786335,
    imdb_id: "tt8542964",
    tmdb_id: 978592,
    url: "https://trakt.tv/movies/sleeping-dogs-2024",
    released: "2024-03-21",
    runtime: 110,
    genres: "drama,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-09T20:31:40Z": {
    type: "movie",
    title: "Kung Fu Panda 4",
    year: 2024,
    trakt_rating: 6.93348,
    trakt_id: 813333,
    imdb_id: "tt21692408",
    tmdb_id: 1011985,
    url: "https://trakt.tv/movies/kung-fu-panda-4-2024",
    released: "2024-03-08",
    runtime: 94,
    genres: "animation,action,adventure,comedy,family",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-06T00:15:23Z": {
    type: "movie",
    title: "Perfect Days",
    year: 2023,
    trakt_rating: 7.9067,
    trakt_id: 785033,
    imdb_id: "tt27503384",
    tmdb_id: 976893,
    url: "https://trakt.tv/movies/perfect-days-2023",
    released: "2023-12-21",
    runtime: 124,
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-04-05T23:40:53Z": {
    type: "movie",
    title: "Dune: Part Two",
    year: 2024,
    trakt_rating: 8.59337,
    trakt_id: 537449,
    imdb_id: "tt15239678",
    tmdb_id: 693134,
    url: "https://trakt.tv/movies/dune-part-two-2024",
    released: "2024-03-01",
    runtime: 167,
    genres: "science-fiction,adventure",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-03-29T17:00:08Z": {
    type: "movie",
    title: "Breaking Boundaries: The Science of Our Planet",
    year: 2021,
    trakt_rating: 7.47664,
    trakt_id: 660478,
    imdb_id: "tt14539726",
    tmdb_id: 828146,
    url: "https://trakt.tv/movies/breaking-boundaries-the-science-of-our-planet-2021",
    released: "2021-06-04",
    runtime: 73,
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-03-20T01:49:34Z": {
    type: "movie",
    title: "The Passenger",
    year: 2023,
    trakt_rating: 6.50546,
    trakt_id: 769474,
    imdb_id: "tt21979910",
    tmdb_id: 957314,
    url: "https://trakt.tv/movies/the-passenger-2023",
    released: "2023-08-04",
    runtime: 98,
    genres: "thriller,crime,drama,horror",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-03-18T16:01:40Z": {
    type: "movie",
    title: "Code 8",
    year: 2019,
    trakt_rating: 6.59699,
    trakt_id: 308302,
    imdb_id: "tt6259380",
    tmdb_id: 461130,
    url: "https://trakt.tv/movies/code-8-2019",
    released: "2019-12-13",
    runtime: 99,
    genres: "science-fiction,action,crime,superhero",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-03-18T16:01:39Z": {
    type: "movie",
    title: "Code 8 Part II",
    year: 2024,
    trakt_rating: 6.67943,
    trakt_id: 747745,
    imdb_id: "tt14764464",
    tmdb_id: 932420,
    url: "https://trakt.tv/movies/code-8-part-ii-2024",
    released: "2024-02-28",
    runtime: 101,
    genres: "science-fiction,action,superhero,crime",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-03-16T13:54:15Z": {
    type: "movie",
    title: "The Dead Center",
    year: 2019,
    trakt_rating: 6.21116,
    trakt_id: 399761,
    imdb_id: "tt8806104",
    tmdb_id: 549859,
    url: "https://trakt.tv/movies/the-dead-center-2019",
    released: "2018-09-21",
    runtime: 93,
    genres: "thriller,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-03-15T19:36:11Z": {
    type: "movie",
    title: "The Animal Kingdom",
    year: 2023,
    trakt_rating: 6.98387,
    trakt_id: 757370,
    imdb_id: "tt16606592",
    tmdb_id: 943134,
    url: "https://trakt.tv/movies/the-animal-kingdom-2023",
    released: "2023-10-04",
    runtime: 127,
    genres: "science-fiction,adventure,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-03-15T19:35:56Z": {
    type: "movie",
    title: "Anatomy of a Fall",
    year: 2023,
    trakt_rating: 7.60239,
    trakt_id: 733410,
    imdb_id: "tt17009710",
    tmdb_id: 915935,
    url: "https://trakt.tv/movies/anatomy-of-a-fall-2023",
    released: "2023-08-23",
    runtime: 152,
    genres: "mystery,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-03-15T19:25:32Z": {
    type: "movie",
    title: "The Lobster",
    year: 2015,
    trakt_rating: 6.89407,
    trakt_id: 154718,
    imdb_id: "tt3464902",
    tmdb_id: 254320,
    url: "https://trakt.tv/movies/the-lobster-2015",
    released: "2015-10-28",
    runtime: 119,
    genres: "comedy,romance,drama",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-03-15T12:51:22Z": {
    type: "movie",
    title: "Stopmotion",
    year: 2024,
    trakt_rating: 5.31579,
    trakt_id: 671406,
    imdb_id: "tt14852624",
    tmdb_id: 840889,
    url: "https://trakt.tv/movies/stopmotion-2024",
    released: "2023-10-07",
    runtime: 93,
    genres: "horror,animation",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-03-12T12:41:05Z": {
    type: "movie",
    title: "Origin",
    year: 2023,
    trakt_rating: 6.95745,
    trakt_id: 592953,
    imdb_id: "tt13321244",
    tmdb_id: 753336,
    url: "https://trakt.tv/movies/origin-2023-12-08",
    released: "2023-12-08",
    runtime: 141,
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-03-10T22:27:49Z": {
    type: "movie",
    title: "Alice in Wonderland",
    year: 1951,
    trakt_rating: 7.46192,
    trakt_id: 7010,
    imdb_id: "tt0043274",
    tmdb_id: 12092,
    url: "https://trakt.tv/movies/alice-in-wonderland-1951",
    released: "1951-07-28",
    runtime: 75,
    genres: "animation,family,fantasy,adventure",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-03-10T12:55:58Z": {
    type: "movie",
    title: "Damsel",
    year: 2024,
    trakt_rating: 7.03484,
    trakt_id: 601689,
    imdb_id: "tt13452446",
    tmdb_id: 763215,
    url: "https://trakt.tv/movies/damsel-2024",
    released: "2024-03-08",
    runtime: 110,
    genres: "fantasy,adventure,action",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-03-10T12:55:35Z": {
    type: "movie",
    title: "Suitable Flesh",
    year: 2023,
    trakt_rating: 5.5618,
    trakt_id: 809251,
    imdb_id: "tt21397580",
    tmdb_id: 1006947,
    url: "https://trakt.tv/movies/suitable-flesh-2023",
    released: "2023-10-27",
    runtime: 99,
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": false,
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-26T17:36:29Z": {
    type: "movie",
    title: "Poor Things",
    year: "2023",
    trakt_rating: "8.07947",
    trakt_id: "628026",
    imdb_id: "tt14230458",
    tmdb_id: "792307",
    url: "https://trakt.tv/movies/poor-things-2023",
    released: "2023-12-08",
    runtime: "141",
    genres: "science-fiction,comedy,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-21T02:10:17Z": {
    type: "movie",
    title: "The Abyss",
    year: "2023",
    trakt_rating: "6.10714",
    trakt_id: "847625",
    imdb_id: "tt22016156",
    tmdb_id: "1053544",
    url: "https://trakt.tv/movies/the-abyss-2023",
    released: "2023-09-15",
    runtime: "104",
    genres: "thriller,drama,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-20T12:37:53Z": {
    type: "movie",
    title: "The Zone of Interest",
    year: "2023",
    trakt_rating: "7.23032",
    trakt_id: "314502",
    imdb_id: "tt7160372",
    tmdb_id: "467244",
    url: "https://trakt.tv/movies/the-zone-of-interest-2023",
    released: "2023-12-15",
    runtime: "105",
    genres: "drama,war,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-20T12:37:22Z": {
    type: "movie",
    title: "Memory",
    year: "2023",
    trakt_rating: "6.88889",
    trakt_id: "786934",
    imdb_id: "tt19864828",
    tmdb_id: "979097",
    url: "https://trakt.tv/movies/memory-2023",
    released: "2023-12-22",
    runtime: "99",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-20T12:37:03Z": {
    type: "movie",
    title: "Mean Girls",
    year: "2024",
    trakt_rating: "6.71087",
    trakt_id: "506803",
    imdb_id: "tt11762114",
    tmdb_id: "673593",
    url: "https://trakt.tv/movies/mean-girls-2024",
    released: "2024-01-12",
    runtime: "113",
    genres: "comedy,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-17T02:53:43Z": {
    type: "movie",
    title: "Double Blind",
    year: "2024",
    trakt_rating: "6.33333",
    trakt_id: "918285",
    imdb_id: "tt14729020",
    tmdb_id: "1140648",
    tvdb_id: "",
    url: "https://trakt.tv/movies/double-blind-2024",
    released: "2024-02-09",
    runtime: "90",
    genres: "horror,thriller,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-06T12:29:49Z": {
    type: "movie",
    title: "The End We Start From",
    year: "2023",
    trakt_rating: "5.8",
    trakt_id: "782515",
    imdb_id: "tt21810682",
    tmdb_id: "973778",
    url: "https://trakt.tv/movies/the-end-we-start-from-2023",
    released: "2023-12-08",
    runtime: "101",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-06T12:29:40Z": {
    type: "movie",
    title: "I.S.S.",
    year: "2024",
    trakt_rating: "5.78652",
    trakt_id: "651903",
    imdb_id: "tt13655120",
    tmdb_id: "790462",
    url: "https://trakt.tv/movies/i-s-s-2024",
    released: "2024-01-19",
    runtime: "95",
    genres: "thriller,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-06T12:29:32Z": {
    type: "movie",
    title: "American Fiction",
    year: "2023",
    trakt_rating: "7.58537",
    trakt_id: "849767",
    imdb_id: "tt23561236",
    tmdb_id: "1056360",
    url: "https://trakt.tv/movies/american-fiction-2023",
    released: "2023-12-15",
    runtime: "117",
    genres: "drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-05T17:03:07Z": {
    type: "movie",
    title: "Orion and the Dark",
    year: "2024",
    trakt_rating: "6.46591",
    trakt_id: "917554",
    imdb_id: "tt28066777",
    tmdb_id: "1139829",
    url: "https://trakt.tv/movies/orion-and-the-dark-2024",
    released: "2024-02-02",
    runtime: "90",
    genres: "family,comedy,animation,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-02T13:07:14Z": {
    type: "movie",
    title: "The Tiger's Apprentice",
    year: "2024",
    trakt_rating: "2.5",
    trakt_id: "444254",
    imdb_id: "tt5834410",
    tmdb_id: "598387",
    url: "https://trakt.tv/movies/the-tiger-s-apprentice-2024",
    released: "2024-02-02",
    runtime: "99",
    genres: "action,animation,adventure,family,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-01T23:10:52Z": {
    type: "movie",
    title: "Destroy All Neighbors",
    year: "2024",
    trakt_rating: "5.89706",
    trakt_id: "783208",
    imdb_id: "tt7884368",
    tmdb_id: "974558",
    url: "https://trakt.tv/movies/destroy-all-neighbors-2024",
    released: "2024-01-12",
    runtime: "86",
    genres: "horror,comedy,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-01T13:16:19Z": {
    type: "movie",
    title: "Percy Jackson: Sea of Monsters",
    year: "2013",
    trakt_rating: "6.38545",
    trakt_id: "56319",
    imdb_id: "tt1854564",
    tmdb_id: "76285",
    url: "https://trakt.tv/movies/percy-jackson-sea-of-monsters-2013",
    released: "2013-08-07",
    runtime: "106",
    genres: "adventure,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-02-01T13:17:19Z": {
    type: "movie",
    title: "Percy Jackson & the Olympians: The Lightning Thief",
    year: "2010",
    trakt_rating: "6.42602",
    trakt_id: "20979",
    imdb_id: "tt0814255",
    tmdb_id: "32657",
    url: "https://trakt.tv/movies/percy-jackson-the-olympians-the-lightning-thief-2010",
    released: "2010-02-11",
    runtime: "118",
    genres: "adventure,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-01-20T01:21:56Z": {
    type: "movie",
    title: "Wonka",
    year: "2023",
    trakt_rating: "7.34244",
    trakt_id: "624003",
    imdb_id: "tt6166392",
    tmdb_id: "787699",
    url: "https://trakt.tv/movies/wonka-2023",
    released: "2023-12-15",
    runtime: "117",
    genres: "family,comedy,musical,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-01-20T01:21:39Z": {
    type: "movie",
    title: "The Kitchen",
    year: "2023",
    trakt_rating: "5.52174",
    trakt_id: "768864",
    imdb_id: "tt4460800",
    tmdb_id: "956262",
    url: "https://trakt.tv/movies/the-kitchen-2023",
    released: "2024-01-19",
    runtime: "107",
    genres: "thriller,science-fiction,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-01-20T01:21:04Z": {
    type: "movie",
    title: "Love and Monsters",
    year: "2020",
    trakt_rating: "7.26211",
    trakt_id: "436962",
    imdb_id: "tt2222042",
    tmdb_id: "590223",
    url: "https://trakt.tv/movies/love-and-monsters-2020",
    released: "2020-10-16",
    runtime: "109",
    genres: "adventure,comedy,action,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-01-08T18:03:21Z": {
    type: "movie",
    title: "Napoleon",
    year: "2023",
    trakt_rating: "6.42116",
    trakt_id: "593127",
    imdb_id: "tt13287846",
    tmdb_id: "753342",
    url: "https://trakt.tv/movies/napoleon-2023",
    released: "2023-11-22",
    runtime: "158",
    genres: "history,drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2024-01-05T00:41:26Z": {
    type: "movie",
    title: "Society of the Snow",
    year: "2023",
    trakt_rating: "7.93333",
    trakt_id: "725415",
    imdb_id: "tt16277242",
    tmdb_id: "906126",
    url: "https://trakt.tv/movies/society-of-the-snow-2023",
    released: "2024-01-04",
    runtime: "144",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-12-21T21:44:47Z": {
    type: "movie",
    title: "Elf",
    year: "2003",
    trakt_rating: "7.52198",
    trakt_id: "5887",
    imdb_id: "tt0319343",
    tmdb_id: "10719",
    url: "https://trakt.tv/movies/elf-2003",
    released: "2003-10-09",
    runtime: "97",
    genres: "comedy,fantasy,family,holiday,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-12-21T03:33:50Z": {
    type: "movie",
    title: "Raging Grace",
    year: "2023",
    trakt_rating: "6.13333",
    trakt_id: "704355",
    imdb_id: "tt14946978",
    tmdb_id: "879689",
    url: "https://trakt.tv/movies/raging-grace-2023",
    released: "2023-12-29",
    runtime: "100",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-12-21T03:33:39Z": {
    type: "movie",
    title: "Mister Organ",
    year: "2022",
    trakt_rating: "7.21622",
    trakt_id: "814725",
    imdb_id: "tt21996838",
    tmdb_id: "1013602",
    url: "https://trakt.tv/movies/mister-organ-2022",
    released: "2022-11-10",
    runtime: "96",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-12-21T03:33:29Z": {
    type: "movie",
    title: "Birth/Rebirth",
    year: "2023",
    trakt_rating: "6.07725",
    trakt_id: "851735",
    imdb_id: "tt9048804",
    tmdb_id: "1058638",
    url: "https://trakt.tv/movies/birth-rebirth-2023",
    released: "2023-08-18",
    runtime: "96",
    genres: "drama,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-12-19T12:44:39Z": {
    type: "movie",
    title: "Thanksgiving",
    year: "2023",
    trakt_rating: "6.64685",
    trakt_id: "861357",
    imdb_id: "tt1448754",
    tmdb_id: "1071215",
    url: "https://trakt.tv/movies/thanksgiving-2023",
    released: "2023-11-17",
    runtime: "106",
    genres: "horror,thriller,mystery,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-12-10T17:05:48Z": {
    type: "movie",
    title: "The Hunger Games: The Ballad of Songbirds & Snakes",
    year: "2023",
    trakt_rating: "7.41173",
    trakt_id: "539738",
    imdb_id: "tt10545296",
    tmdb_id: "695721",
    url: "https://trakt.tv/movies/the-hunger-games-the-ballad-of-songbirds-snakes-2023",
    released: "2023-11-17",
    runtime: "157",
    genres: "action,romance,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-12-08T11:59:37Z": {
    type: "movie",
    title: "Leave the World Behind",
    year: "2023",
    trakt_rating: "5.875",
    trakt_id: "568646",
    imdb_id: "tt12747748",
    tmdb_id: "726209",
    url: "https://trakt.tv/movies/leave-the-world-behind-2023",
    released: "2023-12-08",
    runtime: "140",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-30T22:40:01Z": {
    type: "movie",
    title: "Oppenheimer",
    year: "2023",
    trakt_rating: "8.23013",
    trakt_id: "698292",
    imdb_id: "tt15398776",
    tmdb_id: "872585",
    url: "https://trakt.tv/movies/oppenheimer-2023",
    released: "2023-07-21",
    runtime: "181",
    genres: "history,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-28T23:59:29Z": {
    type: "movie",
    title: "Beyond Utopia",
    year: "2023",
    trakt_rating: "6.66667",
    trakt_id: "860877",
    imdb_id: "tt25470468",
    tmdb_id: "1070032",
    tvdb_id: "",
    url: "https://trakt.tv/movies/beyond-utopia-2023",
    released: "2023-10-23",
    runtime: "116",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-23T14:53:44Z": {
    type: "movie",
    title: "The Naughty Nine",
    year: "2023",
    trakt_rating: "5.5",
    trakt_id: "750890",
    imdb_id: "tt16491414",
    tmdb_id: "936059",
    url: "https://trakt.tv/movies/the-naughty-nine-2023",
    released: "2023-11-22",
    runtime: "90",
    genres: "adventure,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-21T13:25:37Z": {
    type: "movie",
    title: "Leo",
    year: "2023",
    trakt_rating: "6.2",
    trakt_id: "864650",
    imdb_id: "tt5755238",
    tmdb_id: "1075794",
    url: "https://trakt.tv/movies/leo-2023",
    released: "2023-11-11",
    runtime: "102",
    genres: "musical,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-18T23:10:45Z": {
    type: "movie",
    title: "Five Nights at Freddy's",
    year: "2023",
    trakt_rating: "6.16786",
    trakt_id: "354192",
    imdb_id: "tt4589218",
    tmdb_id: "507089",
    url: "https://trakt.tv/movies/five-nights-at-freddy-s-2023",
    released: "2023-10-26",
    runtime: "110",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-13T19:43:36Z": {
    type: "movie",
    title: "The Creator",
    year: "2023",
    trakt_rating: "7.17098",
    trakt_id: "503878",
    imdb_id: "tt11858890",
    tmdb_id: "670292",
    url: "https://trakt.tv/movies/the-creator-2023",
    released: "2023-09-29",
    runtime: "134",
    genres: "science-fiction,action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-10T13:55:58Z": {
    type: "movie",
    title: "The Killer",
    year: "2023",
    trakt_rating: "6.80292",
    trakt_id: "635402",
    imdb_id: "tt1136617",
    tmdb_id: "800158",
    url: "https://trakt.tv/movies/the-killer-2023",
    released: "2023-10-27",
    runtime: "118",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-05T13:17:20Z": {
    type: "movie",
    title: "The Amazing Maurice",
    year: "2022",
    trakt_rating: "6.59626",
    trakt_id: "510014",
    imdb_id: "tt10473036",
    tmdb_id: "676710",
    url: "https://trakt.tv/movies/the-amazing-maurice-2022",
    released: "2022-12-16",
    runtime: "93",
    genres: "adventure,animation,comedy,family,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-05T13:17:13Z": {
    type: "movie",
    title: "The Slumber Party",
    year: "2023",
    trakt_rating: "7.34615",
    trakt_id: "828009",
    imdb_id: "tt22375620",
    tmdb_id: "1029549",
    url: "https://trakt.tv/movies/the-slumber-party-2023",
    released: "2023-07-27",
    runtime: "83",
    genres: "comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-05T13:16:59Z": {
    type: "movie",
    title: "Nimona",
    year: "2023",
    trakt_rating: "8.10985",
    trakt_id: "772756",
    imdb_id: "tt19500164",
    tmdb_id: "961323",
    url: "https://trakt.tv/movies/nimona-2023",
    released: "2023-06-23",
    runtime: "102",
    genres: "fantasy,animation,adventure,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-05T13:15:22Z": {
    type: "movie",
    title: "Chupa",
    year: "2023",
    trakt_rating: "6.21739",
    trakt_id: "578460",
    imdb_id: "tt14923260",
    tmdb_id: "736790",
    url: "https://trakt.tv/movies/chupa-2023",
    released: "2023-04-07",
    runtime: "98",
    genres: "family,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-05T13:15:04Z": {
    type: "movie",
    title: "Crater",
    year: "2023",
    trakt_rating: "6.2953",
    trakt_id: "463416",
    imdb_id: "tt5264838",
    tmdb_id: "620705",
    url: "https://trakt.tv/movies/crater-2023",
    released: "2023-05-12",
    runtime: "104",
    genres: "science-fiction,adventure,family,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-05T13:06:03Z": {
    type: "movie",
    title: "Spy Kids: Armageddon",
    year: "2023",
    trakt_rating: "5.44635",
    trakt_id: "629084",
    imdb_id: "tt13978520",
    tmdb_id: "790493",
    url: "https://trakt.tv/movies/spy-kids-armageddon-2023",
    released: "2023-09-22",
    runtime: "98",
    genres: "family,adventure,action,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-11-05T13:05:41Z": {
    type: "movie",
    title: "The Monkey King",
    year: "2023",
    trakt_rating: "6.27766",
    trakt_id: "664460",
    imdb_id: "tt8637498",
    tmdb_id: "832502",
    url: "https://trakt.tv/movies/the-monkey-king-2023",
    released: "2023-08-11",
    runtime: "92",
    genres: "fantasy,adventure,family,comedy,animation",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-10-31T23:00:09Z": {
    type: "movie",
    title: "The Last House on the Left",
    year: "1972",
    trakt_rating: "5.95994",
    trakt_id: "9441",
    imdb_id: "tt0068833",
    tmdb_id: "15516",
    url: "https://trakt.tv/movies/the-last-house-on-the-left-1972",
    released: "1972-08-30",
    runtime: "84",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-10-31T12:12:31Z": {
    type: "movie",
    title: "A Haunting in Venice",
    year: "2023",
    trakt_rating: "6.73237",
    trakt_id: "759594",
    imdb_id: "tt22687790",
    tmdb_id: "945729",
    url: "https://trakt.tv/movies/a-haunting-in-venice-2023",
    released: "2023-09-15",
    runtime: "104",
    genres: "crime,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-10-28T01:27:31Z": {
    type: "movie",
    title: "When Evil Lurks",
    year: "2023",
    trakt_rating: "7.2",
    trakt_id: "585277",
    imdb_id: "tt16300962",
    tmdb_id: "744857",
    url: "https://trakt.tv/movies/when-evil-lurks-2023",
    released: "2023-11-09",
    runtime: "100",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-10-27T20:08:04Z": {
    type: "movie",
    title: "City on Fire",
    year: "1987",
    trakt_rating: "7.37594",
    trakt_id: "28024",
    imdb_id: "tt0093435",
    tmdb_id: "42120",
    url: "https://trakt.tv/movies/city-on-fire-1987",
    released: "1987-02-13",
    runtime: "105",
    genres: "action,thriller,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-10-24T11:50:30Z": {
    type: "movie",
    title: "The Exorcist: Believer",
    year: "2023",
    trakt_rating: "5.5928",
    trakt_id: "641669",
    imdb_id: "tt12921446",
    tmdb_id: "807172",
    url: "https://trakt.tv/movies/the-exorcist-believer-2023",
    released: "2023-10-06",
    runtime: "111",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-10-21T17:43:53Z": {
    type: "movie",
    title: "They Cloned Tyrone",
    year: "2023",
    trakt_rating: "6.9098",
    trakt_id: "578435",
    imdb_id: "tt9873892",
    tmdb_id: "736769",
    url: "https://trakt.tv/movies/they-cloned-tyrone-2023",
    released: "2023-07-21",
    runtime: "122",
    genres: "science-fiction,comedy,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-10-18T02:18:57Z": {
    type: "movie",
    title: "Fremont",
    year: "2023",
    trakt_rating: "5.27273",
    trakt_id: "843579",
    imdb_id: "tt8591526",
    tmdb_id: "1048522",
    url: "https://trakt.tv/movies/fremont-2023",
    released: "2023-08-25",
    runtime: "92",
    genres: "drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-10-18T02:04:33Z": {
    type: "movie",
    title: "The Conference",
    year: "2023",
    trakt_rating: "5.63576",
    trakt_id: "935586",
    imdb_id: "tt26547864",
    tmdb_id: "1161048",
    url: "https://trakt.tv/movies/the-conference-2023",
    released: "2023-10-13",
    runtime: "100",
    genres: "comedy,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-10-10T14:25:57Z": {
    type: "movie",
    title: "It Lives Inside",
    year: "2023",
    trakt_rating: "5.77966",
    trakt_id: "824003",
    imdb_id: "tt15683734",
    tmdb_id: "1024773",
    url: "https://trakt.tv/movies/it-lives-inside-2023",
    released: "2023-09-22",
    runtime: "99",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-10-06T12:06:09Z": {
    type: "movie",
    title: "One Piece Film Red",
    year: "2022",
    trakt_rating: "6.9871",
    trakt_id: "722637",
    imdb_id: "tt16183464",
    tmdb_id: "900667",
    url: "https://trakt.tv/movies/one-piece-film-red-2022",
    released: "2022-08-06",
    runtime: "115",
    genres: "anime,adventure,action,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-10-03T11:53:32Z": {
    type: "movie",
    title: "The Bay",
    year: "2012",
    trakt_rating: "6.04167",
    trakt_id: "84996",
    imdb_id: "tt1713476",
    tmdb_id: "123105",
    url: "https://trakt.tv/movies/the-bay-2012",
    released: "2012-11-02",
    runtime: "84",
    genres: "horror,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-10-01T02:09:10Z": {
    type: "movie",
    title: "Mission: Impossible - Dead Reckoning Part One",
    year: "2023",
    trakt_rating: "7.85288",
    trakt_id: "425809",
    imdb_id: "tt9603212",
    tmdb_id: "575264",
    url: "https://trakt.tv/movies/mission-impossible-dead-reckoning-part-one-2023",
    released: "2023-07-12",
    runtime: "164",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-25T14:48:11Z": {
    type: "movie",
    title: "No One Will Save You",
    year: "2023",
    trakt_rating: "6.45494",
    trakt_id: "653782",
    imdb_id: "tt14509110",
    tmdb_id: "820609",
    url: "https://trakt.tv/movies/no-one-will-save-you-2023",
    released: "2023-09-22",
    runtime: "93",
    genres: "science-fiction,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-22T12:42:48Z": {
    type: "movie",
    title: "Bottoms",
    year: "2023",
    trakt_rating: "7.70229",
    trakt_id: "648528",
    imdb_id: "tt17527468",
    tmdb_id: "814776",
    url: "https://trakt.tv/movies/bottoms-2023",
    released: "2023-08-25",
    runtime: "92",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-18T12:44:56Z": {
    type: "movie",
    title: "Blue Beetle",
    year: "2023",
    trakt_rating: "6.99031",
    trakt_id: "415437",
    imdb_id: "tt9362930",
    tmdb_id: "565770",
    url: "https://trakt.tv/movies/blue-beetle-2023",
    released: "2023-08-18",
    runtime: "128",
    genres: "action,science-fiction,superhero,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-12T16:53:58Z": {
    type: "movie",
    title: "The Little Mermaid",
    year: "2023",
    trakt_rating: "6.42028",
    trakt_id: "293908",
    imdb_id: "tt5971474",
    tmdb_id: "447277",
    url: "https://trakt.tv/movies/the-little-mermaid-2023",
    released: "2023-05-26",
    runtime: "135",
    genres: "adventure,family,fantasy,romance,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-12T16:51:54Z": {
    type: "movie",
    title: "Final Cut",
    year: "2022",
    trakt_rating: "6.68702",
    trakt_id: "680984",
    imdb_id: "tt14317880",
    tmdb_id: "852438",
    url: "https://trakt.tv/movies/final-cut-2022",
    released: "2023-07-14",
    runtime: "110",
    genres: "comedy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-12T16:50:33Z": {
    type: "movie",
    title: "One Cut of the Dead",
    year: "2017",
    trakt_rating: "7.70007",
    trakt_id: "361003",
    imdb_id: "tt7914416",
    tmdb_id: "513434",
    url: "https://trakt.tv/movies/one-cut-of-the-dead-2017",
    released: "2017-11-04",
    runtime: "96",
    genres: "comedy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-12T16:47:23Z": {
    type: "movie",
    title: "Anchorage",
    year: "2021",
    trakt_rating: "1.0",
    trakt_id: "632669",
    imdb_id: "tt12819466",
    tmdb_id: "797413",
    url: "https://trakt.tv/movies/anchorage-2021",
    released: "2023-06-16",
    runtime: "81",
    genres: "drama,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-11T16:04:50Z": {
    type: "movie",
    title: "Talk to Me",
    year: "2023",
    trakt_rating: "7.25326",
    trakt_id: "810487",
    imdb_id: "tt10638522",
    tmdb_id: "1008042",
    url: "https://trakt.tv/movies/talk-to-me-2023",
    released: "2023-07-27",
    runtime: "95",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-10T13:31:34Z": {
    type: "movie",
    title: "The Unbinding",
    year: "2023",
    trakt_rating: "8.5",
    trakt_id: "894993",
    imdb_id: "tt27485427",
    tmdb_id: "1112303",
    url: "https://trakt.tv/movies/the-unbinding-2023",
    released: "2023-09-08",
    runtime: "98",
    genres: "documentary,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-05T16:44:32Z": {
    type: "movie",
    title: "Perpetrator",
    year: "2023",
    trakt_rating: "5.15625",
    trakt_id: "854033",
    imdb_id: "tt24082454",
    tmdb_id: "1061656",
    url: "https://trakt.tv/movies/perpetrator-2023",
    released: "2023-09-01",
    runtime: "100",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-05T16:42:04Z": {
    type: "movie",
    title: "Teenage Mutant Ninja Turtles: Mutant Mayhem",
    year: "2023",
    trakt_rating: "7.43299",
    trakt_id: "458434",
    imdb_id: "tt8589698",
    tmdb_id: "614930",
    url: "https://trakt.tv/movies/teenage-mutant-ninja-turtles-mutant-mayhem-2023",
    released: "2023-08-02",
    runtime: "100",
    genres: "action,comedy,animation,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-05T15:31:47Z": {
    type: "movie",
    title: "Indiana Jones and the Dial of Destiny",
    year: "2023",
    trakt_rating: "6.91646",
    trakt_id: "216712",
    imdb_id: "tt1462764",
    tmdb_id: "335977",
    url: "https://trakt.tv/movies/indiana-jones-and-the-dial-of-destiny-2023",
    released: "2023-06-30",
    runtime: "155",
    genres: "action,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-05T15:21:57Z": {
    type: "movie",
    title: "8 Mile",
    year: "2002",
    trakt_rating: "7.40164",
    trakt_id: "36",
    imdb_id: "tt0298203",
    tmdb_id: "65",
    url: "https://trakt.tv/movies/8-mile-2002",
    released: "2002-11-08",
    runtime: "111",
    genres: "drama,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-09-02T20:53:00Z": {
    type: "movie",
    title: "Barbie",
    year: "2023",
    trakt_rating: "7.58064",
    trakt_id: "242104",
    imdb_id: "tt1517268",
    tmdb_id: "346698",
    url: "https://trakt.tv/movies/barbie-2023",
    released: "2023-07-21",
    runtime: "114",
    genres: "comedy,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-08-29T01:54:04Z": {
    type: "movie",
    title: "Brightwood",
    year: "2022",
    trakt_rating: "10.0",
    trakt_id: "813918",
    imdb_id: "tt14736734",
    tmdb_id: "1012706",
    url: "https://trakt.tv/movies/brightwood-2022",
    released: "2023-08-22",
    runtime: "84",
    genres: "horror,science-fiction,romance,drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-08-28T22:13:02Z": {
    type: "movie",
    title: "The Boogeyman",
    year: "2023",
    trakt_rating: "6.34",
    trakt_id: "384486",
    imdb_id: "tt3427252",
    tmdb_id: "532408",
    url: "https://trakt.tv/movies/the-boogeyman-2023",
    released: "2023-06-02",
    runtime: "99",
    genres: "horror,mystery,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-08-21T22:14:42Z": {
    type: "movie",
    title: "The Angry Black Girl and Her Monster",
    year: "2023",
    trakt_rating: "6.10938",
    trakt_id: "871176",
    imdb_id: "tt19896150",
    tmdb_id: "1083858",
    url: "https://trakt.tv/movies/the-angry-black-girl-and-her-monster-2023",
    released: "2023-06-09",
    runtime: "91",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-08-14T15:06:54Z": {
    type: "movie",
    title: "Elemental",
    year: "2023",
    trakt_rating: "7.38524",
    trakt_id: "784814",
    imdb_id: "tt15789038",
    tmdb_id: "976573",
    url: "https://trakt.tv/movies/elemental-2023",
    released: "2023-06-16",
    runtime: "102",
    genres: "animation,fantasy,family,comedy,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-07-22T11:13:39Z": {
    type: "movie",
    title: "Raw",
    year: "2016",
    trakt_rating: "7.08414",
    trakt_id: "235965",
    imdb_id: "tt4954522",
    tmdb_id: "393519",
    url: "https://trakt.tv/movies/raw-2016",
    released: "2017-03-10",
    runtime: "99",
    genres: "drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-07-18T11:39:33Z": {
    type: "movie",
    title: "Ruby Gillman, Teenage Kraken",
    year: "2023",
    trakt_rating: "6.2093",
    trakt_id: "836833",
    imdb_id: "tt27155038",
    tmdb_id: "1040148",
    url: "https://trakt.tv/movies/ruby-gillman-teenage-kraken-2023",
    released: "2023-06-30",
    runtime: "91",
    genres: "animation,family,fantasy,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-07-11T12:08:59Z": {
    type: "movie",
    title: "Transformers: Rise of the Beasts",
    year: "2023",
    trakt_rating: "6.85614",
    trakt_id: "501235",
    imdb_id: "tt5090568",
    tmdb_id: "667538",
    url: "https://trakt.tv/movies/transformers-rise-of-the-beasts-2023",
    released: "2023-06-09",
    runtime: "127",
    genres: "action,science-fiction,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-07-09T13:42:35Z": {
    type: "movie",
    title: "Death of a Ladies' Man",
    year: "2020",
    trakt_rating: "6.03333",
    trakt_id: "493900",
    imdb_id: "tt7923118",
    tmdb_id: "659927",
    url: "https://trakt.tv/movies/death-of-a-ladies-man-2020",
    released: "2020-09-24",
    runtime: "104",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-07-07T15:47:51Z": {
    type: "movie",
    title: "The Aviator",
    year: "2004",
    trakt_rating: "7.36808",
    trakt_id: "1752",
    imdb_id: "tt0338751",
    tmdb_id: "2567",
    url: "https://trakt.tv/movies/the-aviator-2004",
    released: "2004-12-17",
    runtime: "170",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-07-07T13:32:12Z": {
    type: "movie",
    title: "The Blackening",
    year: "2023",
    trakt_rating: "6.3952",
    trakt_id: "810573",
    imdb_id: "tt11703244",
    tmdb_id: "1008392",
    url: "https://trakt.tv/movies/the-blackening-2023",
    released: "2023-06-16",
    runtime: "97",
    genres: "horror,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-06-24T22:45:32Z": {
    type: "movie",
    title: "Avatar: The Way of Water",
    year: "2022",
    trakt_rating: "7.67122",
    trakt_id: "56580",
    imdb_id: "tt1630029",
    tmdb_id: "76600",
    url: "https://trakt.tv/movies/avatar-the-way-of-water-2022",
    released: "2022-12-16",
    runtime: "192",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-06-13T14:22:18Z": {
    type: "movie",
    title: "Kids vs. Aliens",
    year: "2023",
    trakt_rating: "4.94667",
    trakt_id: "815004",
    imdb_id: "tt21862626",
    tmdb_id: "1013870",
    url: "https://trakt.tv/movies/kids-vs-aliens-2023",
    released: "2023-01-20",
    runtime: "75",
    genres: "science-fiction,horror,adventure,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-06-13T11:13:02Z": {
    type: "movie",
    title: "Beau Is Afraid",
    year: "2023",
    trakt_rating: "6.73086",
    trakt_id: "633435",
    imdb_id: "tt13521006",
    tmdb_id: "798286",
    url: "https://trakt.tv/movies/beau-is-afraid-2023",
    released: "2023-04-14",
    runtime: "179",
    genres: "comedy,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-06-07T17:33:49Z": {
    type: "movie",
    title: "Spider-Man: Across the Spider-Verse",
    year: "2023",
    trakt_rating: "8.83461",
    trakt_id: "420606",
    imdb_id: "tt9362722",
    tmdb_id: "569094",
    url: "https://trakt.tv/movies/spider-man-across-the-spider-verse-2023",
    released: "2023-06-02",
    runtime: "140",
    genres: "animation,action,science-fiction,superhero,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-05-30T16:08:56Z": {
    type: "movie",
    title: "The Disruptors",
    year: "2021",
    trakt_rating: "8.28571",
    trakt_id: "756138",
    imdb_id: "tt14854294",
    tmdb_id: "941738",
    url: "https://trakt.tv/movies/the-disruptors-2021",
    released: "2021-04-20",
    runtime: "98",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-05-28T23:27:01Z": {
    type: "movie",
    title: "Dungeons & Dragons: Honor Among Thieves",
    year: "2023",
    trakt_rating: "7.48182",
    trakt_id: "340442",
    imdb_id: "tt2906216",
    tmdb_id: "493529",
    url: "https://trakt.tv/movies/dungeons-dragons-honor-among-thieves-2023",
    released: "2023-03-31",
    runtime: "134",
    genres: "fantasy,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-05-22T23:12:44Z": {
    type: "movie",
    title: "John Wick: Chapter 4",
    year: "2023",
    trakt_rating: "7.74633",
    trakt_id: "448646",
    imdb_id: "tt10366206",
    tmdb_id: "603692",
    url: "https://trakt.tv/movies/john-wick-chapter-4-2023",
    released: "2023-03-24",
    runtime: "170",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-05-22T23:12:40Z": {
    type: "movie",
    title: "Sisu",
    year: "2023",
    trakt_rating: "7.28993",
    trakt_id: "671155",
    imdb_id: "tt14846026",
    tmdb_id: "840326",
    url: "https://trakt.tv/movies/sisu-2023",
    released: "2023-04-28",
    runtime: "91",
    genres: "war,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-05-20T22:21:32Z": {
    type: "movie",
    title: "Allegiant",
    year: "2016",
    trakt_rating: "6.66236",
    trakt_id: "161418",
    imdb_id: "tt3410834",
    tmdb_id: "262504",
    url: "https://trakt.tv/movies/allegiant-2016",
    released: "2016-03-18",
    runtime: "120",
    genres: "science-fiction,adventure,action,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-05-20T22:21:26Z": {
    type: "movie",
    title: "Insurgent",
    year: "2015",
    trakt_rating: "6.71399",
    trakt_id: "161414",
    imdb_id: "tt2908446",
    tmdb_id: "262500",
    url: "https://trakt.tv/movies/insurgent-2015",
    released: "2015-03-20",
    runtime: "119",
    genres: "science-fiction,thriller,adventure,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-05-20T22:21:18Z": {
    type: "movie",
    title: "Divergent",
    year: "2014",
    trakt_rating: "6.98518",
    trakt_id: "102162",
    imdb_id: "tt1840309",
    tmdb_id: "157350",
    url: "https://trakt.tv/movies/divergent-2014",
    released: "2014-03-21",
    runtime: "139",
    genres: "action,science-fiction,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-05-20T20:02:35Z": {
    type: "movie",
    title: "The Ciambra",
    year: "2017",
    trakt_rating: "7.44318",
    trakt_id: "299774",
    imdb_id: "tt6802896",
    tmdb_id: "453270",
    url: "https://trakt.tv/movies/the-ciambra-2017",
    released: "2017-08-31",
    runtime: "118",
    genres: "drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-05-16T21:43:04Z": {
    type: "movie",
    title: "My Octopus Teacher",
    year: "2020",
    trakt_rating: "7.92124",
    trakt_id: "527067",
    imdb_id: "tt12888462",
    tmdb_id: "682110",
    url: "https://trakt.tv/movies/my-octopus-teacher-2020",
    released: "2020-09-07",
    runtime: "85",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-05-14T16:56:54Z": {
    type: "movie",
    title: "The Super Mario Bros. Movie",
    year: "2023",
    trakt_rating: "7.52835",
    trakt_id: "349425",
    imdb_id: "tt6718170",
    tmdb_id: "502356",
    url: "https://trakt.tv/movies/the-super-mario-bros-movie-2023",
    released: "2023-04-05",
    runtime: "93",
    genres: "adventure,animation,family,fantasy,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-05-09T11:43:25Z": {
    type: "movie",
    title: "Evil Dead Rise",
    year: "2023",
    trakt_rating: "6.79009",
    trakt_id: "556530",
    imdb_id: "tt13345606",
    tmdb_id: "713704",
    url: "https://trakt.tv/movies/evil-dead-rise-2023",
    released: "2023-04-21",
    runtime: "96",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-05-03T02:11:25Z": {
    type: "movie",
    title: "Coded Bias",
    year: "2020",
    trakt_rating: "7.02484",
    trakt_id: "488548",
    imdb_id: "tt11394170",
    tmdb_id: "653734",
    url: "https://trakt.tv/movies/coded-bias-2020",
    released: "2020-11-11",
    runtime: "86",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-05-02T12:20:42Z": {
    type: "movie",
    title: "Renfield",
    year: "2023",
    trakt_rating: "6.81938",
    trakt_id: "485769",
    imdb_id: "tt11358390",
    tmdb_id: "649609",
    url: "https://trakt.tv/movies/renfield-2023",
    released: "2023-04-14",
    runtime: "93",
    genres: "horror,comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-04-20T16:18:04Z": {
    type: "movie",
    title: "Enys Men",
    year: "2023",
    trakt_rating: "4.82353",
    trakt_id: "581037",
    imdb_id: "tt11698800",
    tmdb_id: "740049",
    url: "https://trakt.tv/movies/enys-men-2023",
    released: "2023-01-13",
    runtime: "90",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-04-10T15:42:58Z": {
    type: "movie",
    title: "65",
    year: "2023",
    trakt_rating: "6.05891",
    trakt_id: "543972",
    imdb_id: "tt12261776",
    tmdb_id: "700391",
    url: "https://trakt.tv/movies/65-2023",
    released: "2023-03-10",
    runtime: "92",
    genres: "science-fiction,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-04-10T13:23:21Z": {
    type: "movie",
    title: "The Trial",
    year: "1962",
    trakt_rating: "7.1408",
    trakt_id: "1994",
    imdb_id: "tt0057427",
    tmdb_id: "3009",
    url: "https://trakt.tv/movies/the-trial-1962",
    released: "1962-12-21",
    runtime: "119",
    genres: "crime,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-04-05T11:23:17Z": {
    type: "movie",
    title: "Babylon",
    year: "2022",
    trakt_rating: "6.96273",
    trakt_id: "459051",
    imdb_id: "tt10640346",
    tmdb_id: "615777",
    url: "https://trakt.tv/movies/babylon-2022",
    released: "2022-12-23",
    runtime: "189",
    genres: "drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-04-04T11:31:13Z": {
    type: "movie",
    title: "Grimsby",
    year: "2016",
    trakt_rating: "6.81136",
    trakt_id: "165674",
    imdb_id: "tt3381008",
    tmdb_id: "267193",
    url: "https://trakt.tv/movies/grimsby-2016",
    released: "2016-03-11",
    runtime: "83",
    genres: "comedy,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-03-11T00:08:59Z": {
    type: "movie",
    title: "Luther: The Fallen Sun",
    year: "2023",
    trakt_rating: "6.9409",
    trakt_id: "564840",
    imdb_id: "tt3155298",
    tmdb_id: "722149",
    url: "https://trakt.tv/movies/luther-the-fallen-sun-2023",
    released: "2023-02-24",
    runtime: "129",
    genres: "crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-03-08T21:19:32Z": {
    type: "movie",
    title: "The Whale",
    year: "2022",
    trakt_rating: "7.73159",
    trakt_id: "621589",
    imdb_id: "tt13833688",
    tmdb_id: "785084",
    url: "https://trakt.tv/movies/the-whale-2022",
    released: "2022-12-09",
    runtime: "116",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-03-02T13:52:51Z": {
    type: "movie",
    title: "Unwelcome",
    year: "2023",
    trakt_rating: "5.61111",
    trakt_id: "597911",
    imdb_id: "tt12447796",
    tmdb_id: "758769",
    url: "https://trakt.tv/movies/unwelcome-2023",
    released: "2023-01-27",
    runtime: "104",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-02-27T21:55:20Z": {
    type: "movie",
    title: "The Banshees of Inisherin",
    year: "2022",
    trakt_rating: "7.45492",
    trakt_id: "507545",
    imdb_id: "tt11813216",
    tmdb_id: "674324",
    url: "https://trakt.tv/movies/the-banshees-of-inisherin-2022",
    released: "2022-10-21",
    runtime: "114",
    genres: "drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-02-25T02:33:20Z": {
    type: "movie",
    title: "The Menu",
    year: "2022",
    trakt_rating: "7.29788",
    trakt_id: "439843",
    imdb_id: "tt9764362",
    tmdb_id: "593643",
    url: "https://trakt.tv/movies/the-menu-2022",
    released: "2022-11-18",
    runtime: "107",
    genres: "horror,thriller,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-02-22T03:07:17Z": {
    type: "movie",
    title: "Your Vice Is a Locked Room and Only I Have the Key",
    year: "1972",
    trakt_rating: "6.84071",
    trakt_id: "51103",
    imdb_id: "tt0069421",
    tmdb_id: "69872",
    url: "https://trakt.tv/movies/your-vice-is-a-locked-room-and-only-i-have-the-key-1972",
    released: "1972-08-04",
    runtime: "97",
    genres: "mystery,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-02-22T03:05:27Z": {
    type: "movie",
    title: "Valerie and Her Week of Wonders",
    year: "1970",
    trakt_rating: "7.06751",
    trakt_id: "31748",
    imdb_id: "tt0066516",
    tmdb_id: "46315",
    url: "https://trakt.tv/movies/valerie-and-her-week-of-wonders-1970",
    released: "1970-10-16",
    runtime: "73",
    genres: "drama,fantasy,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-02-21T13:59:18Z": {
    type: "movie",
    title: "Knock at the Cabin",
    year: "2023",
    trakt_rating: "6.43581",
    trakt_id: "471824",
    imdb_id: "tt15679400",
    tmdb_id: "631842",
    url: "https://trakt.tv/movies/knock-at-the-cabin-2023",
    released: "2023-02-03",
    runtime: "100",
    genres: "horror,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-02-19T18:22:01Z": {
    type: "movie",
    title: "The Outwaters",
    year: "2023",
    trakt_rating: "4.07692",
    trakt_id: "592820",
    imdb_id: "tt13259610",
    tmdb_id: "752867",
    url: "https://trakt.tv/movies/the-outwaters-2023",
    released: "2023-02-09",
    runtime: "110",
    genres: "horror,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-02-18T23:19:03Z": {
    type: "movie",
    title: "Drinkwater",
    year: "2022",
    trakt_rating: "6.42857",
    trakt_id: "702831",
    imdb_id: "tt12991200",
    tmdb_id: "878214",
    url: "https://trakt.tv/movies/drinkwater-2022",
    released: "2022-10-14",
    runtime: "112",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-02-14T12:15:57Z": {
    type: "movie",
    title: "Infinity Pool",
    year: "2023",
    trakt_rating: "5.93977",
    trakt_id: "500815",
    imdb_id: "tt10365998",
    tmdb_id: "667216",
    url: "https://trakt.tv/movies/infinity-pool-2023",
    released: "2023-01-27",
    runtime: "118",
    genres: "science-fiction,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-02-13T00:11:46Z": {
    type: "movie",
    title: "You People",
    year: "2023",
    trakt_rating: "6.00909",
    trakt_id: "692773",
    imdb_id: "tt14826022",
    tmdb_id: "866413",
    url: "https://trakt.tv/movies/you-people-2023",
    released: "2023-01-20",
    runtime: "117",
    genres: "comedy,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-02-13T00:11:22Z": {
    type: "movie",
    title: "Vesper",
    year: "2022",
    trakt_rating: "6.24585",
    trakt_id: "784663",
    imdb_id: "tt20225374",
    tmdb_id: "976720",
    url: "https://trakt.tv/movies/vesper-2022",
    released: "2022-08-17",
    runtime: "112",
    genres: "science-fiction,drama,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-02-12T18:23:39Z": {
    type: "movie",
    title: "Alice, Darling",
    year: "2022",
    trakt_rating: "5.83398",
    trakt_id: "678990",
    imdb_id: "tt11687104",
    tmdb_id: "850028",
    url: "https://trakt.tv/movies/alice-darling-2022",
    released: "2023-02-03",
    runtime: "90",
    genres: "thriller,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-26T03:38:38Z": {
    type: "movie",
    title: "Scare Me",
    year: "2020",
    trakt_rating: "6.04545",
    trakt_id: "488418",
    imdb_id: "tt10126434",
    tmdb_id: "653643",
    url: "https://trakt.tv/movies/scare-me-2020",
    released: "2020-10-01",
    runtime: "104",
    genres: "horror,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-26T01:25:32Z": {
    type: "movie",
    title: "The Devil Rides Out",
    year: "1968",
    trakt_rating: "7.16379",
    trakt_id: "26048",
    imdb_id: "tt0062885",
    tmdb_id: "39891",
    url: "https://trakt.tv/movies/the-devil-rides-out-1968",
    released: "1968-07-20",
    runtime: "96",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-26T01:25:19Z": {
    type: "movie",
    title: "The Blood on Satan's Claw",
    year: "1971",
    trakt_rating: "6.29518",
    trakt_id: "16331",
    imdb_id: "tt0066849",
    tmdb_id: "26774",
    url: "https://trakt.tv/movies/the-blood-on-satan-s-claw-1971",
    released: "1971-01-28",
    runtime: "97",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-25T15:37:10Z": {
    type: "movie",
    title: "November",
    year: "2017",
    trakt_rating: "6.93956",
    trakt_id: "283895",
    imdb_id: "tt6164502",
    tmdb_id: "437583",
    url: "https://trakt.tv/movies/november-2017",
    released: "2017-02-03",
    runtime: "115",
    genres: "drama,fantasy,horror,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-25T15:35:26Z": {
    type: "movie",
    title: "The Lair of the White Worm",
    year: "1988",
    trakt_rating: "6.45038",
    trakt_id: "6443",
    imdb_id: "tt0095488",
    tmdb_id: "11347",
    url: "https://trakt.tv/movies/the-lair-of-the-white-worm-1988",
    released: "1989-03-10",
    runtime: "93",
    genres: "horror,comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-25T15:26:38Z": {
    type: "movie",
    title: "Witchfinder General",
    year: "1968",
    trakt_rating: "6.88412",
    trakt_id: "20510",
    imdb_id: "tt0063285",
    tmdb_id: "31965",
    url: "https://trakt.tv/movies/witchfinder-general-1968",
    released: "1968-05-17",
    runtime: "87",
    genres: "drama,history,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-25T15:26:26Z": {
    type: "movie",
    title: "A Field in England",
    year: "2013",
    trakt_rating: "6.42857",
    trakt_id: "113092",
    imdb_id: "tt2375574",
    tmdb_id: "179111",
    url: "https://trakt.tv/movies/a-field-in-england-2013",
    released: "2013-07-05",
    runtime: "90",
    genres: "drama,history,thriller,horror,comedy,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-25T02:32:32Z": {
    type: "movie",
    title: "M3GAN",
    year: "2022",
    trakt_rating: "6.78594",
    trakt_id: "388074",
    imdb_id: "tt8760708",
    tmdb_id: "536554",
    url: "https://trakt.tv/movies/m3gan-2022",
    released: "2023-01-06",
    runtime: "102",
    genres: "horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-24T13:45:23Z": {
    type: "movie",
    title: "The Bird with the Crystal Plumage",
    year: "1970",
    trakt_rating: "7.28903",
    trakt_id: "12491",
    imdb_id: "tt0065143",
    tmdb_id: "20345",
    url: "https://trakt.tv/movies/the-bird-with-the-crystal-plumage-1970",
    released: "1970-06-24",
    runtime: "96",
    genres: "horror,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-20T22:33:23Z": {
    type: "movie",
    title: "Black Adam",
    year: "2022",
    trakt_rating: "6.68901",
    trakt_id: "288212",
    imdb_id: "tt6443346",
    tmdb_id: "436270",
    url: "https://trakt.tv/movies/black-adam-2022",
    released: "2022-10-21",
    runtime: "124",
    genres: "action,superhero,science-fiction,fantasy,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-20T07:47:06Z": {
    type: "movie",
    title: "The Hunger",
    year: "1983",
    trakt_rating: "7.04252",
    trakt_id: "6728",
    imdb_id: "tt0085701",
    tmdb_id: "11654",
    url: "https://trakt.tv/movies/the-hunger-1983",
    released: "1983-04-29",
    runtime: "97",
    genres: "horror,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-15T21:23:05Z": {
    type: "movie",
    title: "Sissy",
    year: "2022",
    trakt_rating: "6.49221",
    trakt_id: "748999",
    imdb_id: "tt9602472",
    tmdb_id: "934207",
    url: "https://trakt.tv/movies/sissy-2022",
    released: "2022-11-03",
    runtime: "102",
    genres: "horror,thriller,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-13T13:17:23Z": {
    type: "movie",
    title: "Babyteeth",
    year: "2020",
    trakt_rating: "7.19714",
    trakt_id: "370370",
    imdb_id: "tt8399664",
    tmdb_id: "522098",
    url: "https://trakt.tv/movies/babyteeth-2020",
    released: "2020-07-23",
    runtime: "118",
    genres: "drama,comedy,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-10T02:26:56Z": {
    type: "movie",
    title: "Dr. Jekyll and Mr. Hyde",
    year: "1931",
    trakt_rating: "7.30531",
    trakt_id: "2003",
    imdb_id: "tt0022835",
    tmdb_id: "3019",
    url: "https://trakt.tv/movies/dr-jekyll-and-mr-hyde-1931",
    released: "1931-12-24",
    runtime: "98",
    genres: "horror,science-fiction,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-06T13:39:07Z": {
    type: "movie",
    title: "The Pale Blue Eye",
    year: "2022",
    trakt_rating: "6.7289",
    trakt_id: "635822",
    imdb_id: "tt14138650",
    tmdb_id: "800815",
    url: "https://trakt.tv/movies/the-pale-blue-eye-2022",
    released: "2022-12-23",
    runtime: "130",
    genres: "crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-05T21:53:52Z": {
    type: "movie",
    title: "Decision to Leave",
    year: "2022",
    trakt_rating: "7.35852",
    trakt_id: "548877",
    imdb_id: "tt12477480",
    tmdb_id: "705996",
    url: "https://trakt.tv/movies/decision-to-leave-2022",
    released: "2022-06-29",
    runtime: "138",
    genres: "mystery,crime,drama,romance,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2023-01-05T17:50:25Z": {
    type: "movie",
    title: "All Quiet on the Western Front",
    year: "2022",
    trakt_rating: "7.7355",
    trakt_id: "34092",
    imdb_id: "tt1016150",
    tmdb_id: "49046",
    url: "https://trakt.tv/movies/all-quiet-on-the-western-front-2022",
    released: "2022-10-07",
    runtime: "147",
    genres: "drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-12-30T02:27:47Z": {
    type: "movie",
    title: "Event Horizon",
    year: "1997",
    trakt_rating: "6.9954",
    trakt_id: "4177",
    imdb_id: "tt0119081",
    tmdb_id: "8413",
    url: "https://trakt.tv/movies/event-horizon-1997",
    released: "1997-08-15",
    runtime: "96",
    genres: "horror,mystery,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-12-24T03:02:50Z": {
    type: "movie",
    title: "A Boy Called Christmas",
    year: "2021",
    trakt_rating: "7.11206",
    trakt_id: "458949",
    imdb_id: "tt10187208",
    tmdb_id: "615666",
    url: "https://trakt.tv/movies/a-boy-called-christmas-2021",
    released: "2021-11-26",
    runtime: "104",
    genres: "family,fantasy,holiday,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-12-23T12:45:20Z": {
    type: "movie",
    title: "Glass Onion: A Knives Out Mystery",
    year: "2022",
    trakt_rating: "7.32406",
    trakt_id: "495668",
    imdb_id: "tt11564570",
    tmdb_id: "661374",
    url: "https://trakt.tv/movies/glass-onion-a-knives-out-mystery-2022",
    released: "2022-11-23",
    runtime: "140",
    genres: "mystery,comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-12-17T16:50:39Z": {
    type: "movie",
    title: "Smile",
    year: "2022",
    trakt_rating: "6.6324",
    trakt_id: "706971",
    imdb_id: "tt15474916",
    tmdb_id: "882598",
    url: "https://trakt.tv/movies/smile-2022-09-30",
    released: "2022-09-30",
    runtime: "115",
    genres: "horror,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-12-10T12:30:18Z": {
    type: "movie",
    title: "The Addams Family",
    year: "1991",
    trakt_rating: "7.37443",
    trakt_id: "1932",
    imdb_id: "tt0101272",
    tmdb_id: "2907",
    url: "https://trakt.tv/movies/the-addams-family-1991",
    released: "1991-11-22",
    runtime: "102",
    genres: "comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-12-06T03:41:22Z": {
    type: "movie",
    title: "Slumberland",
    year: "2022",
    trakt_rating: "7.09715",
    trakt_id: "502226",
    imdb_id: "tt13320662",
    tmdb_id: "668461",
    url: "https://trakt.tv/movies/slumberland-2022",
    released: "2022-11-18",
    runtime: "117",
    genres: "fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-11-18T02:12:53Z": {
    type: "movie",
    title: "Moonage Daydream",
    year: "2022",
    trakt_rating: "7.63312",
    trakt_id: "769499",
    imdb_id: "tt9883832",
    tmdb_id: "957457",
    url: "https://trakt.tv/movies/moonage-daydream-2022",
    released: "2022-09-16",
    runtime: "135",
    genres: "documentary,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-11-13T13:30:11Z": {
    type: "movie",
    title: "Enola Holmes",
    year: "2020",
    trakt_rating: "7.05436",
    trakt_id: "344612",
    imdb_id: "tt7846844",
    tmdb_id: "497582",
    url: "https://trakt.tv/movies/enola-holmes-2020",
    released: "2020-09-23",
    runtime: "123",
    genres: "mystery,crime,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-11-13T13:29:41Z": {
    type: "movie",
    title: "Enola Holmes 2",
    year: "2022",
    trakt_rating: "7.21329",
    trakt_id: "661649",
    imdb_id: "tt14641788",
    tmdb_id: "829280",
    url: "https://trakt.tv/movies/enola-holmes-2-2022",
    released: "2022-11-04",
    runtime: "129",
    genres: "mystery,adventure,crime,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-11-08T12:26:31Z": {
    type: "movie",
    title: "Neptune Frost",
    year: "2022",
    trakt_rating: "6.57143",
    trakt_id: "503978",
    imdb_id: "tt11873472",
    tmdb_id: "671109",
    url: "https://trakt.tv/movies/neptune-frost-2022",
    released: "2022-06-03",
    runtime: "110",
    genres: "musical,science-fiction,drama,music,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-30T23:11:30Z": {
    type: "movie",
    title: "Host",
    year: "2020",
    trakt_rating: "6.57988",
    trakt_id: "565713",
    imdb_id: "tt12749596",
    tmdb_id: "723072",
    url: "https://trakt.tv/movies/host-2020",
    released: "2020-07-30",
    runtime: "57",
    genres: "horror,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-30T23:11:11Z": {
    type: "movie",
    title: "The Lighthouse",
    year: "2019",
    trakt_rating: "7.19258",
    trakt_id: "351214",
    imdb_id: "tt7984734",
    tmdb_id: "503919",
    url: "https://trakt.tv/movies/the-lighthouse-2019",
    released: "2019-10-18",
    runtime: "109",
    genres: "drama,fantasy,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-30T19:35:42Z": {
    type: "movie",
    title: "The Boy Behind The Door",
    year: "2020",
    trakt_rating: "6.44946",
    trakt_id: "582526",
    imdb_id: "tt10772662",
    tmdb_id: "741613",
    url: "https://trakt.tv/movies/the-boy-behind-the-door-2020",
    released: "2021-07-29",
    runtime: "88",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-30T19:35:22Z": {
    type: "movie",
    title: "Coming Home in the Dark",
    year: "2021",
    trakt_rating: "6.23404",
    trakt_id: "554451",
    imdb_id: "tt6874762",
    tmdb_id: "711963",
    url: "https://trakt.tv/movies/coming-home-in-the-dark-2021",
    released: "2021-08-12",
    runtime: "93",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-28T01:54:01Z": {
    type: "movie",
    title: "The Lair",
    year: "2022",
    trakt_rating: "5.57908",
    trakt_id: "599187",
    imdb_id: "tt13468602",
    tmdb_id: "760204",
    url: "https://trakt.tv/movies/the-lair-2022",
    released: "2022-10-28",
    runtime: "93",
    genres: "action,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-25T12:35:08Z": {
    type: "movie",
    title: "Barbarian",
    year: "2022",
    trakt_rating: "6.96547",
    trakt_id: "731538",
    imdb_id: "tt15791034",
    tmdb_id: "913290",
    url: "https://trakt.tv/movies/barbarian-2022",
    released: "2022-09-09",
    runtime: "103",
    genres: "thriller,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-24T12:50:44Z": {
    type: "movie",
    title: "Don't Worry Darling",
    year: "2022",
    trakt_rating: "6.5401",
    trakt_id: "464903",
    imdb_id: "tt10731256",
    tmdb_id: "619730",
    url: "https://trakt.tv/movies/don-t-worry-darling-2022",
    released: "2022-09-23",
    runtime: "123",
    genres: "thriller,mystery,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-23T13:30:11Z": {
    type: "movie",
    title: "The Stranger",
    year: "2022",
    trakt_rating: "6.51093",
    trakt_id: "678226",
    imdb_id: "tt11897478",
    tmdb_id: "848791",
    url: "https://trakt.tv/movies/the-stranger-2022",
    released: "2022-10-19",
    runtime: "117",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-21T13:06:43Z": {
    type: "movie",
    title: "Pearl",
    year: "2022",
    trakt_rating: "7.13402",
    trakt_id: "763144",
    imdb_id: "tt18925334",
    tmdb_id: "949423",
    url: "https://trakt.tv/movies/pearl-2022",
    released: "2022-09-16",
    runtime: "102",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-21T13:06:26Z": {
    type: "movie",
    title: "Matriarch",
    year: "2022",
    trakt_rating: "5.08081",
    trakt_id: "823771",
    imdb_id: "tt17202326",
    tmdb_id: "1024535",
    url: "https://trakt.tv/movies/matriarch-2022",
    released: "2022-10-21",
    runtime: "85",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-20T13:46:56Z": {
    type: "movie",
    title: "V/H/S/99",
    year: "2022",
    trakt_rating: "5.20566",
    trakt_id: "808430",
    imdb_id: "tt21651560",
    tmdb_id: "1005776",
    url: "https://trakt.tv/movies/v-h-s-99-2022",
    released: "2022-10-20",
    runtime: "109",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-18T15:40:10Z": {
    type: "movie",
    title: "Piggy",
    year: "2022",
    trakt_rating: "6.39935",
    trakt_id: "677621",
    imdb_id: "tt10399608",
    tmdb_id: "848058",
    url: "https://trakt.tv/movies/piggy-2022",
    released: "2022-11-02",
    runtime: "99",
    genres: "thriller,drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-09T11:40:47Z": {
    type: "movie",
    title: "Deadstream",
    year: "2022",
    trakt_rating: "6.62639",
    trakt_id: "710270",
    imdb_id: "tt12788488",
    tmdb_id: "886083",
    url: "https://trakt.tv/movies/deadstream-2022",
    released: "2022-10-06",
    runtime: "87",
    genres: "horror,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-07T15:10:54Z": {
    type: "movie",
    title: "White Noise",
    year: "2020",
    trakt_rating: "6.47368",
    trakt_id: "555549",
    imdb_id: "tt12569462",
    tmdb_id: "712749",
    url: "https://trakt.tv/movies/white-noise-2020",
    released: "2020-06-20",
    runtime: "94",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-07T12:36:13Z": {
    type: "movie",
    title: "Hellraiser",
    year: "2022",
    trakt_rating: "6.31677",
    trakt_id: "219412",
    imdb_id: "tt0887261",
    tmdb_id: "338947",
    url: "https://trakt.tv/movies/hellraiser-2022",
    released: "2022-10-07",
    runtime: "121",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-07T12:33:19Z": {
    type: "movie",
    title: "Tin Can",
    year: "2022",
    trakt_rating: "4.38095",
    trakt_id: "592142",
    imdb_id: "tt8413040",
    tmdb_id: "752510",
    url: "https://trakt.tv/movies/tin-can-2022",
    released: "2021-08-07",
    runtime: "104",
    genres: "science-fiction,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-05T14:39:29Z": {
    type: "movie",
    title: "House of Darkness",
    year: "2022",
    trakt_rating: "5.45946",
    trakt_id: "757068",
    imdb_id: "tt18224610",
    tmdb_id: "942890",
    url: "https://trakt.tv/movies/house-of-darkness-2022",
    released: "2022-09-09",
    runtime: "88",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-04T01:16:48Z": {
    type: "movie",
    title: "Stage Fright",
    year: "1950",
    trakt_rating: "7.2767",
    trakt_id: "1342",
    imdb_id: "tt0042994",
    tmdb_id: "1978",
    url: "https://trakt.tv/movies/stage-fright-1950",
    released: "1950-08-28",
    runtime: "110",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-04T01:07:26Z": {
    type: "movie",
    title: "The Timekeepers of Eternity",
    year: "2021",
    trakt_rating: "6.85714",
    trakt_id: "699748",
    imdb_id: "tt15528116",
    tmdb_id: "874167",
    url: "https://trakt.tv/movies/the-timekeepers-of-eternity-2021",
    released: "2023-06-10",
    runtime: "64",
    genres: "animation,mystery,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-04T01:07:05Z": {
    type: "movie",
    title: "King Kong",
    year: "1933",
    trakt_rating: "7.52517",
    trakt_id: "202",
    imdb_id: "tt0024216",
    tmdb_id: "244",
    url: "https://trakt.tv/movies/king-kong-1933",
    released: "1933-03-15",
    runtime: "104",
    genres: "adventure,fantasy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-02T11:42:55Z": {
    type: "movie",
    title: "One by One We Will Take You: The Untold Saga of The Evil Dead",
    year: "2007",
    trakt_rating: "6.6",
    trakt_id: "179022",
    imdb_id: "tt4400714",
    tmdb_id: "281619",
    url: "https://trakt.tv/movies/one-by-one-we-will-take-you-the-untold-saga-of-the-evil-dead-2007",
    released: "2007-12-18",
    runtime: "53",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-01T16:48:51Z": {
    type: "movie",
    title: "Beware the Slenderman",
    year: "2016",
    trakt_rating: "6.62435",
    trakt_id: "228726",
    imdb_id: "tt5329376",
    tmdb_id: "376424",
    url: "https://trakt.tv/movies/beware-the-slenderman-2016",
    released: "2016-03-11",
    runtime: "117",
    genres: "crime,documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-01T12:33:14Z": {
    type: "movie",
    title: "V/H/S/94",
    year: "2021",
    trakt_rating: "5.63083",
    trakt_id: "592891",
    imdb_id: "tt14867006",
    tmdb_id: "753453",
    url: "https://trakt.tv/movies/v-h-s-94-2021",
    released: "2021-10-06",
    runtime: "103",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-10-01T12:30:39Z": {
    type: "movie",
    title: "V/H/S: Viral",
    year: "2014",
    trakt_rating: "4.96746",
    trakt_id: "166209",
    imdb_id: "tt3704538",
    tmdb_id: "267806",
    url: "https://trakt.tv/movies/v-h-s-viral-2014",
    released: "2014-10-23",
    runtime: "81",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-28T01:42:03Z": {
    type: "movie",
    title: "Summer of 84",
    year: "2018",
    trakt_rating: "7.05696",
    trakt_id: "317550",
    imdb_id: "tt5774450",
    tmdb_id: "470229",
    url: "https://trakt.tv/movies/summer-of-84-2018",
    released: "2018-08-10",
    runtime: "105",
    genres: "thriller,mystery,drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-27T23:15:01Z": {
    type: "movie",
    title: "Twin Peaks: Fire Walk with Me",
    year: "1992",
    trakt_rating: "7.47627",
    trakt_id: "1297",
    imdb_id: "tt0105665",
    tmdb_id: "1923",
    url: "https://trakt.tv/movies/twin-peaks-fire-walk-with-me-1992",
    released: "1992-08-28",
    runtime: "135",
    genres: "drama,mystery,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-27T23:11:47Z": {
    type: "movie",
    title: "Inland Empire",
    year: "2006",
    trakt_rating: "7.09539",
    trakt_id: "1160",
    imdb_id: "tt0460829",
    tmdb_id: "1730",
    url: "https://trakt.tv/movies/inland-empire-2006",
    released: "2006-12-06",
    runtime: "180",
    genres: "mystery,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-27T22:56:28Z": {
    type: "movie",
    title: "Mulholland Drive",
    year: "2001",
    trakt_rating: "7.70187",
    trakt_id: "827",
    imdb_id: "tt0166924",
    tmdb_id: "1018",
    url: "https://trakt.tv/movies/mulholland-drive-2001",
    released: "2001-10-19",
    runtime: "147",
    genres: "drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-27T22:51:37Z": {
    type: "movie",
    title: "Eraserhead",
    year: "1977",
    trakt_rating: "7.22178",
    trakt_id: "815",
    imdb_id: "tt0074486",
    tmdb_id: "985",
    url: "https://trakt.tv/movies/eraserhead-1977",
    released: "1977-03-19",
    runtime: "89",
    genres: "fantasy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-27T22:02:49Z": {
    type: "movie",
    title: "Lost Highway",
    year: "1997",
    trakt_rating: "7.58615",
    trakt_id: "514",
    imdb_id: "tt0116922",
    tmdb_id: "638",
    url: "https://trakt.tv/movies/lost-highway-1997",
    released: "1997-02-18",
    runtime: "134",
    genres: "drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-27T20:48:14Z": {
    type: "movie",
    title: "A Forbidden Orange",
    year: "2021",
    trakt_rating: "6.84",
    trakt_id: "712415",
    imdb_id: "tt15717468",
    tmdb_id: "888492",
    url: "https://trakt.tv/movies/a-forbidden-orange-2021",
    released: "2021-10-23",
    runtime: "85",
    genres: "documentary,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-26T20:49:18Z": {
    type: "movie",
    title: "Bullet Train",
    year: "2022",
    trakt_rating: "7.50399",
    trakt_id: "561702",
    imdb_id: "tt12593682",
    tmdb_id: "718930",
    url: "https://trakt.tv/movies/bullet-train-2022",
    released: "2022-08-05",
    runtime: "126",
    genres: "action,comedy,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-25T18:08:29Z": {
    type: "movie",
    title: "Insomnia",
    year: "2002",
    trakt_rating: "7.20998",
    trakt_id: "271",
    imdb_id: "tt0278504",
    tmdb_id: "320",
    url: "https://trakt.tv/movies/insomnia-2002",
    released: "2002-05-24",
    runtime: "118",
    genres: "crime,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-23T19:49:26Z": {
    type: "movie",
    title: "Notes on a Scandal",
    year: "2006",
    trakt_rating: "7.24772",
    trakt_id: "874",
    imdb_id: "tt0465551",
    tmdb_id: "1259",
    url: "https://trakt.tv/movies/notes-on-a-scandal-2006",
    released: "2006-12-25",
    runtime: "92",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-20T14:58:20Z": {
    type: "movie",
    title: "Häxan",
    year: "1922",
    trakt_rating: "7.52568",
    trakt_id: "40904",
    imdb_id: "tt0013257",
    tmdb_id: "57283",
    url: "https://trakt.tv/movies/haxan-1922",
    released: "1922-09-18",
    runtime: "105",
    genres: "horror,documentary,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-19T22:30:48Z": {
    type: "movie",
    title: "The Bitter Stems",
    year: "1956",
    trakt_rating: "6.625",
    trakt_id: "116182",
    imdb_id: "tt0143920",
    tmdb_id: "185250",
    url: "https://trakt.tv/movies/the-bitter-stems-1956",
    released: "1956-11-04",
    runtime: "92",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-18T23:25:56Z": {
    type: "movie",
    title: "Scarborough",
    year: "2021",
    trakt_rating: "7.5",
    trakt_id: "683586",
    imdb_id: "tt12890988",
    tmdb_id: "855136",
    url: "https://trakt.tv/movies/scarborough-2021",
    released: "2021-09-10",
    runtime: "136",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-18T12:49:26Z": {
    type: "movie",
    title: "Key Largo",
    year: "1948",
    trakt_rating: "7.64356",
    trakt_id: "6153",
    imdb_id: "tt0040506",
    tmdb_id: "11016",
    url: "https://trakt.tv/movies/key-largo-1948",
    released: "1948-07-16",
    runtime: "100",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-17T16:42:23Z": {
    type: "movie",
    title: "The Cabinet of Dr. Caligari",
    year: "1920",
    trakt_rating: "7.79382",
    trakt_id: "192",
    imdb_id: "tt0010323",
    tmdb_id: "234",
    url: "https://trakt.tv/movies/the-cabinet-of-dr-caligari-1920",
    released: "1920-02-27",
    runtime: "78",
    genres: "crime,drama,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-16T15:46:04Z": {
    type: "movie",
    title: "Angel Heart",
    year: "1987",
    trakt_rating: "7.3294",
    trakt_id: "511",
    imdb_id: "tt0092563",
    tmdb_id: "635",
    url: "https://trakt.tv/movies/angel-heart-1987",
    released: "1987-03-06",
    runtime: "113",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-16T14:34:00Z": {
    type: "movie",
    title: "Sirocco",
    year: "1951",
    trakt_rating: "6.73333",
    trakt_id: "19011",
    imdb_id: "tt0044040",
    tmdb_id: "30025",
    url: "https://trakt.tv/movies/sirocco-1951",
    released: "1951-06-13",
    runtime: "98",
    genres: "action,adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-16T14:30:57Z": {
    type: "movie",
    title: "The Big Heat",
    year: "1953",
    trakt_rating: "7.79954",
    trakt_id: "8791",
    imdb_id: "tt0045555",
    tmdb_id: "14580",
    url: "https://trakt.tv/movies/the-big-heat-1953",
    released: "1953-10-14",
    runtime: "89",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-16T14:24:44Z": {
    type: "movie",
    title: "Deadline - U.S.A.",
    year: "1952",
    trakt_rating: "7.4026",
    trakt_id: "23075",
    imdb_id: "tt0044533",
    tmdb_id: "36334",
    url: "https://trakt.tv/movies/deadline-u-s-a-1952",
    released: "1952-03-14",
    runtime: "87",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-16T13:33:58Z": {
    type: "movie",
    title: "The Seventh Seal",
    year: "1957",
    trakt_rating: "7.97169",
    trakt_id: "384",
    imdb_id: "tt0050976",
    tmdb_id: "490",
    url: "https://trakt.tv/movies/the-seventh-seal-1957",
    released: "1957-02-16",
    runtime: "96",
    genres: "drama,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-16T12:03:36Z": {
    type: "movie",
    title: "Manhunter",
    year: "1986",
    trakt_rating: "7.14234",
    trakt_id: "6542",
    imdb_id: "tt0091474",
    tmdb_id: "11454",
    url: "https://trakt.tv/movies/manhunter-1986",
    released: "1986-08-14",
    runtime: "120",
    genres: "crime,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-16T11:56:16Z": {
    type: "movie",
    title: "Speak No Evil",
    year: "2022",
    trakt_rating: "6.54399",
    trakt_id: "665177",
    imdb_id: "tt14253846",
    tmdb_id: "833339",
    url: "https://trakt.tv/movies/speak-no-evil-2022",
    released: "2022-03-17",
    runtime: "97",
    genres: "horror,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-15T01:10:15Z": {
    type: "movie",
    title: "Raising Cain",
    year: "1992",
    trakt_rating: "6.51504",
    trakt_id: "8309",
    imdb_id: "tt0105217",
    tmdb_id: "13937",
    url: "https://trakt.tv/movies/raising-cain-1992",
    released: "1992-08-07",
    runtime: "92",
    genres: "crime,drama,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-13T13:38:37Z": {
    type: "movie",
    title: "Salò, or the 120 Days of Sodom",
    year: "1976",
    trakt_rating: "5.48134",
    trakt_id: "3069",
    imdb_id: "tt0073650",
    tmdb_id: "5336",
    url: "https://trakt.tv/movies/salo-or-the-120-days-of-sodom-1976",
    released: "1976-05-19",
    runtime: "117",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-13T11:47:41Z": {
    type: "movie",
    title: "Three Thousand Years of Longing",
    year: "2022",
    trakt_rating: "6.99083",
    trakt_id: "407402",
    imdb_id: "tt9198364",
    tmdb_id: "556694",
    url: "https://trakt.tv/movies/three-thousand-years-of-longing-2022",
    released: "2022-09-01",
    runtime: "108",
    genres: "fantasy,drama,adventure,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-12T21:45:50Z": {
    type: "movie",
    title: "Godzilla",
    year: "1954",
    trakt_rating: "7.45581",
    trakt_id: "1118",
    imdb_id: "tt0047034",
    tmdb_id: "1678",
    url: "https://trakt.tv/movies/godzilla-1954",
    released: "1954-11-03",
    runtime: "96",
    genres: "horror,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-12T21:40:19Z": {
    type: "movie",
    title: "Godzilla, King of the Monsters!",
    year: "1956",
    trakt_rating: "6.6625",
    trakt_id: "11675",
    imdb_id: "tt0197521",
    tmdb_id: "18983",
    url: "https://trakt.tv/movies/godzilla-king-of-the-monsters-1956",
    released: "1956-04-27",
    runtime: "81",
    genres: "horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-11T14:16:33Z": {
    type: "movie",
    title: "Pierrot le Fou",
    year: "1965",
    trakt_rating: "7.50386",
    trakt_id: "1862",
    imdb_id: "tt0059592",
    tmdb_id: "2786",
    url: "https://trakt.tv/movies/pierrot-le-fou-1965",
    released: "1965-11-05",
    runtime: "110",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-11T12:27:56Z": {
    type: "movie",
    title: "The Osterman Weekend",
    year: "1983",
    trakt_rating: "6.34266",
    trakt_id: "7141",
    imdb_id: "tt0086058",
    tmdb_id: "12239",
    url: "https://trakt.tv/movies/the-osterman-weekend-1983",
    released: "1983-10-14",
    runtime: "103",
    genres: "action,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-11T12:24:16Z": {
    type: "movie",
    title: "Jules and Jim",
    year: "1962",
    trakt_rating: "7.5592",
    trakt_id: "1074",
    imdb_id: "tt0055032",
    tmdb_id: "1628",
    url: "https://trakt.tv/movies/jules-and-jim-1962",
    released: "1962-01-23",
    runtime: "110",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-11T12:24:07Z": {
    type: "movie",
    title: "Le Beau Serge",
    year: "1958",
    trakt_rating: "7.64583",
    trakt_id: "3145",
    imdb_id: "tt0051404",
    tmdb_id: "5562",
    url: "https://trakt.tv/movies/le-beau-serge-1958",
    released: "1958-06-06",
    runtime: "98",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-11T12:17:50Z": {
    type: "movie",
    title: "The 400 Blows",
    year: "1959",
    trakt_rating: "7.99179",
    trakt_id: "113",
    imdb_id: "tt0053198",
    tmdb_id: "147",
    url: "https://trakt.tv/movies/the-400-blows-1959",
    released: "1959-06-03",
    runtime: "99",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-11T12:17:39Z": {
    type: "movie",
    title: "Band of Outsiders",
    year: "1964",
    trakt_rating: "7.6036",
    trakt_id: "4040",
    imdb_id: "tt0057869",
    tmdb_id: "8073",
    url: "https://trakt.tv/movies/band-of-outsiders-1964",
    released: "1964-08-05",
    runtime: "97",
    genres: "crime,drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-11T12:15:10Z": {
    type: "movie",
    title: "Hiroshima Mon Amour",
    year: "1959",
    trakt_rating: "7.79256",
    trakt_id: "3136",
    imdb_id: "tt0052893",
    tmdb_id: "5544",
    url: "https://trakt.tv/movies/hiroshima-mon-amour-1959",
    released: "1959-06-10",
    runtime: "92",
    genres: "drama,history,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-11T12:11:44Z": {
    type: "movie",
    title: "Shoot the Piano Player",
    year: "1960",
    trakt_rating: "7.5",
    trakt_id: "1204",
    imdb_id: "tt0054389",
    tmdb_id: "1818",
    url: "https://trakt.tv/movies/shoot-the-piano-player-1960",
    released: "1960-11-25",
    runtime: "85",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-11T11:42:34Z": {
    type: "movie",
    title: "The Cousins",
    year: "1959",
    trakt_rating: "7.61702",
    trakt_id: "1661",
    imdb_id: "tt0052708",
    tmdb_id: "2363",
    url: "https://trakt.tv/movies/the-cousins-1959",
    released: "1959-03-11",
    runtime: "112",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-11T11:34:57Z": {
    type: "movie",
    title: "Alphaville",
    year: "1965",
    trakt_rating: "7.21348",
    trakt_id: "4039",
    imdb_id: "tt0058898",
    tmdb_id: "8072",
    url: "https://trakt.tv/movies/alphaville-1965",
    released: "1965-05-05",
    runtime: "100",
    genres: "drama,mystery,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-10T23:01:28Z": {
    type: "movie",
    title: "Breathless",
    year: "1960",
    trakt_rating: "7.55739",
    trakt_id: "225",
    imdb_id: "tt0053472",
    tmdb_id: "269",
    url: "https://trakt.tv/movies/breathless-1960",
    released: "1960-03-16",
    runtime: "90",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-10T22:59:34Z": {
    type: "movie",
    title: "Blow-Up",
    year: "1966",
    trakt_rating: "7.30999",
    trakt_id: "835",
    imdb_id: "tt0060176",
    tmdb_id: "1052",
    url: "https://trakt.tv/movies/blow-up-1966",
    released: "1966-12-18",
    runtime: "111",
    genres: "drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-10T22:59:17Z": {
    type: "movie",
    title: "Blow Out",
    year: "1981",
    trakt_rating: "7.34206",
    trakt_id: "6718",
    imdb_id: "tt0082085",
    tmdb_id: "11644",
    url: "https://trakt.tv/movies/blow-out-1981",
    released: "1981-07-24",
    runtime: "108",
    genres: "thriller,crime,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-08T12:03:46Z": {
    type: "movie",
    title: "Beast",
    year: "2022",
    trakt_rating: "6.28816",
    trakt_id: "599542",
    imdb_id: "tt13223398",
    tmdb_id: "760741",
    url: "https://trakt.tv/movies/beast-2022",
    released: "2022-08-19",
    runtime: "93",
    genres: "thriller,action,horror,adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-08T11:54:11Z": {
    type: "movie",
    title: "Guillermo del Toro's Pinocchio",
    year: "2022",
    trakt_rating: "7.58801",
    trakt_id: "406579",
    imdb_id: "tt1488589",
    tmdb_id: "555604",
    url: "https://trakt.tv/movies/guillermo-del-toro-s-pinocchio-2022",
    released: "2022-11-18",
    runtime: "117",
    genres: "animation,fantasy,drama,musical,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-08T01:48:15Z": {
    type: "movie",
    title: "Mildred Pierce",
    year: "1945",
    trakt_rating: "7.84964",
    trakt_id: "2165",
    imdb_id: "tt0037913",
    tmdb_id: "3309",
    url: "https://trakt.tv/movies/mildred-pierce-1945",
    released: "1945-10-20",
    runtime: "111",
    genres: "drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-08T01:46:23Z": {
    type: "movie",
    title: "Sunset Boulevard",
    year: "1950",
    trakt_rating: "8.20727",
    trakt_id: "477",
    imdb_id: "tt0043014",
    tmdb_id: "599",
    url: "https://trakt.tv/movies/sunset-boulevard-1950",
    released: "1950-08-10",
    runtime: "110",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-08T01:46:13Z": {
    type: "movie",
    title: "Touch of Evil",
    year: "1958",
    trakt_rating: "7.69984",
    trakt_id: "975",
    imdb_id: "tt0052311",
    tmdb_id: "1480",
    url: "https://trakt.tv/movies/touch-of-evil-1958",
    released: "1958-03-30",
    runtime: "111",
    genres: "crime,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-07T16:00:25Z": {
    type: "movie",
    title: "The 39 Steps",
    year: "1935",
    trakt_rating: "7.40609",
    trakt_id: "218",
    imdb_id: "tt0026029",
    tmdb_id: "260",
    url: "https://trakt.tv/movies/the-39-steps-1935",
    released: "1935-06-06",
    runtime: "86",
    genres: "mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-07T15:38:39Z": {
    type: "movie",
    title: "Double Indemnity",
    year: "1944",
    trakt_rating: "8.06162",
    trakt_id: "826",
    imdb_id: "tt0036775",
    tmdb_id: "996",
    url: "https://trakt.tv/movies/double-indemnity-1944",
    released: "1944-06-14",
    runtime: "107",
    genres: "thriller,crime,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-07T15:30:58Z": {
    type: "movie",
    title: "Thor: Love and Thunder",
    year: "2022",
    trakt_rating: "6.51683",
    trakt_id: "459282",
    imdb_id: "tt10648342",
    tmdb_id: "616037",
    url: "https://trakt.tv/movies/thor-love-and-thunder-2022",
    released: "2022-07-07",
    runtime: "119",
    genres: "superhero,action,fantasy,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-04T12:42:23Z": {
    type: "movie",
    title: "Enter the Void",
    year: "2009",
    trakt_rating: "7.23061",
    trakt_id: "22111",
    imdb_id: "tt1191111",
    tmdb_id: "34647",
    url: "https://trakt.tv/movies/enter-the-void-2009",
    released: "2009-06-17",
    runtime: "161",
    genres: "drama,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-04T12:11:13Z": {
    type: "movie",
    title: "No Man of God",
    year: "2021",
    trakt_rating: "6.73778",
    trakt_id: "559328",
    imdb_id: "tt13507778",
    tmdb_id: "716594",
    url: "https://trakt.tv/movies/no-man-of-god-2021",
    released: "2021-08-27",
    runtime: "100",
    genres: "history,drama,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-04T12:09:17Z": {
    type: "movie",
    title: "Cure",
    year: "1997",
    trakt_rating: "7.46123",
    trakt_id: "22892",
    imdb_id: "tt0123948",
    tmdb_id: "36095",
    url: "https://trakt.tv/movies/cure-1997",
    released: "1997-12-27",
    runtime: "111",
    genres: "crime,horror,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-04T01:59:45Z": {
    type: "movie",
    title: "Intolerable Cruelty",
    year: "2003",
    trakt_rating: "6.41608",
    trakt_id: "6805",
    imdb_id: "tt0138524",
    tmdb_id: "11775",
    url: "https://trakt.tv/movies/intolerable-cruelty-2003",
    released: "2003-09-02",
    runtime: "100",
    genres: "comedy,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-04T01:56:19Z": {
    type: "movie",
    title: "The Ballad of Buster Scruggs",
    year: "2018",
    trakt_rating: "7.16616",
    trakt_id: "389471",
    imdb_id: "tt6412452",
    tmdb_id: "537996",
    url: "https://trakt.tv/movies/the-ballad-of-buster-scruggs-2018",
    released: "2018-11-09",
    runtime: "132",
    genres: "comedy,drama,western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-04T01:30:23Z": {
    type: "movie",
    title: "The Innocents",
    year: "2021",
    trakt_rating: "7.07616",
    trakt_id: "494882",
    imdb_id: "tt4028464",
    tmdb_id: "660942",
    url: "https://trakt.tv/movies/the-innocents-2021",
    released: "2021-09-03",
    runtime: "117",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-04T01:29:33Z": {
    type: "movie",
    title: "Mope",
    year: "2019",
    trakt_rating: "5.66667",
    trakt_id: "415102",
    imdb_id: "tt6927492",
    tmdb_id: "565377",
    url: "https://trakt.tv/movies/mope-2019",
    released: "2019-01-27",
    runtime: "105",
    genres: "drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-09-03T14:05:21Z": {
    type: "movie",
    title: "The Other Woman",
    year: "2014",
    trakt_rating: "6.74564",
    trakt_id: "120866",
    imdb_id: "tt2203939",
    tmdb_id: "193610",
    url: "https://trakt.tv/movies/the-other-woman-2014",
    released: "2014-04-25",
    runtime: "109",
    genres: "comedy,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-08-31T19:46:06Z": {
    type: "movie",
    title: "Fall",
    year: "2022",
    trakt_rating: "6.72341",
    trakt_id: "792296",
    imdb_id: "tt15325794",
    tmdb_id: "985939",
    url: "https://trakt.tv/movies/fall-2022",
    released: "2022-08-12",
    runtime: "107",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-08-25T11:24:03Z": {
    type: "movie",
    title: "Nope",
    year: "2022",
    trakt_rating: "6.79258",
    trakt_id: "601018",
    imdb_id: "tt10954984",
    tmdb_id: "762504",
    url: "https://trakt.tv/movies/nope-2022",
    released: "2022-07-22",
    runtime: "130",
    genres: "horror,mystery,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-08-23T11:18:59Z": {
    type: "movie",
    title: "DC League of Super-Pets",
    year: "2022",
    trakt_rating: "7.10811",
    trakt_id: "391105",
    imdb_id: "tt8912936",
    tmdb_id: "539681",
    url: "https://trakt.tv/movies/dc-league-of-super-pets-2022",
    released: "2022-07-29",
    runtime: "105",
    genres: "animation,superhero,action,family,comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-08-22T13:11:28Z": {
    type: "movie",
    title: "When I Consume You",
    year: "2021",
    trakt_rating: "4.7619",
    trakt_id: "625778",
    imdb_id: "tt11454278",
    tmdb_id: "789382",
    url: "https://trakt.tv/movies/when-i-consume-you-2021",
    released: "2022-08-16",
    runtime: "89",
    genres: "mystery,drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-08-18T15:48:07Z": {
    type: "movie",
    title: "Diabolique",
    year: "1955",
    trakt_rating: "7.95871",
    trakt_id: "670",
    imdb_id: "tt0046911",
    tmdb_id: "827",
    url: "https://trakt.tv/movies/diabolique-1955",
    released: "1955-01-29",
    runtime: "117",
    genres: "drama,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-08-18T14:26:56Z": {
    type: "movie",
    title: "Day Shift",
    year: "2022",
    trakt_rating: "6.63681",
    trakt_id: "595062",
    imdb_id: "tt13314558",
    tmdb_id: "755566",
    url: "https://trakt.tv/movies/day-shift-2022",
    released: "2022-08-12",
    runtime: "113",
    genres: "fantasy,action,horror,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-08-17T23:35:51Z": {
    type: "movie",
    title: "Glorious",
    year: "2022",
    trakt_rating: "6.05611",
    trakt_id: "754588",
    imdb_id: "tt12724306",
    tmdb_id: "939914",
    url: "https://trakt.tv/movies/glorious-2022",
    released: "2022-08-18",
    runtime: "79",
    genres: "horror,fantasy,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-08-12T12:50:07Z": {
    type: "movie",
    title: "Hypochondriac",
    year: "2022",
    trakt_rating: "5.34921",
    trakt_id: "733583",
    imdb_id: "tt12186098",
    tmdb_id: "915982",
    url: "https://trakt.tv/movies/hypochondriac-2022",
    released: "2022-08-04",
    runtime: "96",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-08-05T11:46:28Z": {
    type: "movie",
    title: "Prey",
    year: "2022",
    trakt_rating: "7.39772",
    trakt_id: "604722",
    imdb_id: "tt11866324",
    tmdb_id: "766507",
    url: "https://trakt.tv/movies/prey-2022",
    released: "2022-08-05",
    runtime: "100",
    genres: "action,thriller,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-08-05T11:46:14Z": {
    type: "movie",
    title: "Resurrection",
    year: "2022",
    trakt_rating: "6.14613",
    trakt_id: "698200",
    imdb_id: "tt11540726",
    tmdb_id: "872497",
    url: "https://trakt.tv/movies/resurrection-2022",
    released: "2022-07-29",
    runtime: "103",
    genres: "thriller,horror,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-08-04T12:16:47Z": {
    type: "movie",
    title: "What Josiah Saw",
    year: "2021",
    trakt_rating: "6.16883",
    trakt_id: "540694",
    imdb_id: "tt3097396",
    tmdb_id: "696605",
    url: "https://trakt.tv/movies/what-josiah-saw-2021",
    released: "2022-08-04",
    runtime: "120",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-08-02T15:58:06Z": {
    type: "movie",
    title: "Gone in the Night",
    year: "2022",
    trakt_rating: "5.47427",
    trakt_id: "699550",
    imdb_id: "tt15426294",
    tmdb_id: "874355",
    url: "https://trakt.tv/movies/gone-in-the-night-2022",
    released: "2022-07-15",
    runtime: "90",
    genres: "mystery,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-08-02T13:33:40Z": {
    type: "movie",
    title: "Minions: The Rise of Gru",
    year: "2022",
    trakt_rating: "7.0746",
    trakt_id: "284359",
    imdb_id: "tt5113044",
    tmdb_id: "438148",
    url: "https://trakt.tv/movies/minions-the-rise-of-gru-2022",
    released: "2022-07-01",
    runtime: "87",
    genres: "animation,family,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-30T14:13:13Z": {
    type: "movie",
    title: "Let Him Have It",
    year: "1991",
    trakt_rating: "7.57143",
    trakt_id: "46307",
    imdb_id: "tt0102288",
    tmdb_id: "64167",
    url: "https://trakt.tv/movies/let-him-have-it-1991",
    released: "1991-10-04",
    runtime: "115",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-30T14:10:07Z": {
    type: "movie",
    title: "Hatching",
    year: "2022",
    trakt_rating: "6.24839",
    trakt_id: "365799",
    imdb_id: "tt12519030",
    tmdb_id: "517302",
    url: "https://trakt.tv/movies/hatching-2022",
    released: "2022-01-23",
    runtime: "91",
    genres: "horror,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-30T02:21:27Z": {
    type: "movie",
    title: "Professor Marston and the Wonder Women",
    year: "2017",
    trakt_rating: "7.55085",
    trakt_id: "268072",
    imdb_id: "tt6133130",
    tmdb_id: "420622",
    url: "https://trakt.tv/movies/professor-marston-and-the-wonder-women-2017",
    released: "2017-10-13",
    runtime: "108",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-30T01:41:03Z": {
    type: "movie",
    title: "The Medium",
    year: "2021",
    trakt_rating: "6.7247",
    trakt_id: "586137",
    imdb_id: "tt13446168",
    tmdb_id: "745881",
    url: "https://trakt.tv/movies/the-medium-2021",
    released: "2021-07-14",
    runtime: "131",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-29T20:42:22Z": {
    type: "movie",
    title: "Pulse",
    year: "2001",
    trakt_rating: "6.53481",
    trakt_id: "16760",
    imdb_id: "tt0286751",
    tmdb_id: "27324",
    url: "https://trakt.tv/movies/pulse-2001",
    released: "2001-02-03",
    runtime: "119",
    genres: "horror,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-29T20:34:55Z": {
    type: "movie",
    title: "Terrified",
    year: "2018",
    trakt_rating: "6.57979",
    trakt_id: "336706",
    imdb_id: "tt7549892",
    tmdb_id: "489430",
    url: "https://trakt.tv/movies/terrified-2018",
    released: "2018-05-03",
    runtime: "88",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-24T14:40:10Z": {
    type: "movie",
    title: "Coherence",
    year: "2013",
    trakt_rating: "7.45605",
    trakt_id: "135581",
    imdb_id: "tt2866360",
    tmdb_id: "220289",
    url: "https://trakt.tv/movies/coherence-2013",
    released: "2014-08-06",
    runtime: "89",
    genres: "science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-22T12:22:09Z": {
    type: "movie",
    title: "The Empty Man",
    year: "2020",
    trakt_rating: "6.20662",
    trakt_id: "364989",
    imdb_id: "tt5867314",
    tmdb_id: "516632",
    url: "https://trakt.tv/movies/the-empty-man-2020",
    released: "2020-10-23",
    runtime: "137",
    genres: "horror,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-20T21:56:10Z": {
    type: "movie",
    title: "Hellbender",
    year: "2021",
    trakt_rating: "6.09565",
    trakt_id: "678345",
    imdb_id: "tt14905650",
    tmdb_id: "848886",
    url: "https://trakt.tv/movies/hellbender-2021",
    released: "2021-09-28",
    runtime: "83",
    genres: "horror,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-20T21:50:11Z": {
    type: "movie",
    title: "You Won't Be Alone",
    year: "2022",
    trakt_rating: "6.67602",
    trakt_id: "707166",
    imdb_id: "tt8296030",
    tmdb_id: "883083",
    url: "https://trakt.tv/movies/you-won-t-be-alone-2022",
    released: "2022-09-22",
    runtime: "109",
    genres: "horror,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-20T21:46:13Z": {
    type: "movie",
    title: "The Outfit",
    year: "2022",
    trakt_rating: "7.43274",
    trakt_id: "634914",
    imdb_id: "tt14114802",
    tmdb_id: "799876",
    url: "https://trakt.tv/movies/the-outfit-2022",
    released: "2022-03-18",
    runtime: "105",
    genres: "crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-16T14:34:26Z": {
    type: "movie",
    title: "She Will",
    year: "2022",
    trakt_rating: "5.81287",
    trakt_id: "680860",
    imdb_id: "tt9340916",
    tmdb_id: "852485",
    url: "https://trakt.tv/movies/she-will-2022",
    released: "2022-07-22",
    runtime: "95",
    genres: "thriller,horror,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-14T11:32:22Z": {
    type: "movie",
    title: "Lightyear",
    year: "2022",
    trakt_rating: "6.84447",
    trakt_id: "561241",
    imdb_id: "tt10298810",
    tmdb_id: "718789",
    url: "https://trakt.tv/movies/lightyear-2022",
    released: "2022-06-17",
    runtime: "105",
    genres: "animation,family,action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-14T11:32:11Z": {
    type: "movie",
    title: "The Black Phone",
    year: "2022",
    trakt_rating: "7.19098",
    trakt_id: "596179",
    imdb_id: "tt7144666",
    tmdb_id: "756999",
    url: "https://trakt.tv/movies/the-black-phone-2022",
    released: "2022-06-24",
    runtime: "103",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-14T00:33:01Z": {
    type: "movie",
    title: "The Pool",
    year: "2018",
    trakt_rating: "6.03226",
    trakt_id: "400058",
    imdb_id: "tt9128686",
    tmdb_id: "550201",
    url: "https://trakt.tv/movies/the-pool-2018",
    released: "2018-09-27",
    runtime: "91",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-11T13:43:00Z": {
    type: "movie",
    title: "Thief",
    year: "1981",
    trakt_rating: "7.43369",
    trakt_id: "6606",
    imdb_id: "tt0083190",
    tmdb_id: "11524",
    url: "https://trakt.tv/movies/thief-1981",
    released: "1981-03-27",
    runtime: "123",
    genres: "crime,thriller,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-07-02T17:26:25Z": {
    type: "movie",
    title: "I See You",
    year: "2019",
    trakt_rating: "7.0673",
    trakt_id: "372647",
    imdb_id: "tt6079516",
    tmdb_id: "524251",
    url: "https://trakt.tv/movies/i-see-you-2019-12-06",
    released: "2019-12-06",
    runtime: "98",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-26T01:45:08Z": {
    type: "movie",
    title: "Men",
    year: "2022",
    trakt_rating: "5.90199",
    trakt_id: "617534",
    imdb_id: "tt13841850",
    tmdb_id: "780609",
    url: "https://trakt.tv/movies/men-2022",
    released: "2022-06-01",
    runtime: "100",
    genres: "horror,thriller,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-26T01:44:16Z": {
    type: "movie",
    title: "Nitram",
    year: "2021",
    trakt_rating: "6.94462",
    trakt_id: "632611",
    imdb_id: "tt13694628",
    tmdb_id: "797457",
    url: "https://trakt.tv/movies/nitram-2021",
    released: "2021-09-30",
    runtime: "112",
    genres: "thriller,drama,mystery,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-25T17:42:57Z": {
    type: "movie",
    title: "Flux Gourmet",
    year: "2022",
    trakt_rating: "5.68103",
    trakt_id: "678193",
    imdb_id: "tt15010292",
    tmdb_id: "848818",
    url: "https://trakt.tv/movies/flux-gourmet-2022",
    released: "2022-06-24",
    runtime: "112",
    genres: "drama,comedy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-25T17:35:54Z": {
    type: "movie",
    title: "Gaia",
    year: "2021",
    trakt_rating: "5.93534",
    trakt_id: "631449",
    imdb_id: "tt11881160",
    tmdb_id: "795853",
    url: "https://trakt.tv/movies/gaia-2021",
    released: "2021-06-18",
    runtime: "97",
    genres: "horror,fantasy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-25T17:16:33Z": {
    type: "movie",
    title: "Caveat",
    year: "2022",
    trakt_rating: "6.38416",
    trakt_id: "585480",
    imdb_id: "tt7917178",
    tmdb_id: "744746",
    url: "https://trakt.tv/movies/caveat-2022",
    released: "2021-06-03",
    runtime: "88",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-21T14:28:51Z": {
    type: "movie",
    title: "Doctor Strange in the Multiverse of Madness",
    year: "2022",
    trakt_rating: "7.18416",
    trakt_id: "299910",
    imdb_id: "tt9419884",
    tmdb_id: "453395",
    url: "https://trakt.tv/movies/doctor-strange-in-the-multiverse-of-madness-2022",
    released: "2022-05-06",
    runtime: "126",
    genres: "fantasy,action,adventure,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-21T12:10:12Z": {
    type: "movie",
    title: "Crimes of the Future",
    year: "2022",
    trakt_rating: "5.71868",
    trakt_id: "653224",
    imdb_id: "tt14549466",
    tmdb_id: "819876",
    url: "https://trakt.tv/movies/crimes-of-the-future-2022",
    released: "2022-06-03",
    runtime: "107",
    genres: "science-fiction,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-21T12:09:51Z": {
    type: "movie",
    title: "Watcher",
    year: "2022",
    trakt_rating: "6.52045",
    trakt_id: "641879",
    imdb_id: "tt12004038",
    tmdb_id: "807356",
    url: "https://trakt.tv/movies/watcher-2022",
    released: "2022-01-21",
    runtime: "96",
    genres: "thriller,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-13T16:56:54Z": {
    type: "movie",
    title: "Edmond",
    year: "2005",
    trakt_rating: "5.99355",
    trakt_id: "11127",
    imdb_id: "tt0443496",
    tmdb_id: "18191",
    url: "https://trakt.tv/movies/edmond-2005",
    released: "2006-07-14",
    runtime: "82",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-13T16:00:49Z": {
    type: "movie",
    title: "The Unbearable Weight of Massive Talent",
    year: "2022",
    trakt_rating: "7.12439",
    trakt_id: "485187",
    imdb_id: "tt11291274",
    tmdb_id: "648579",
    url: "https://trakt.tv/movies/the-unbearable-weight-of-massive-talent-2022",
    released: "2022-04-22",
    runtime: "107",
    genres: "comedy,action,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-12T18:11:36Z": {
    type: "movie",
    title: "Blow the Man Down",
    year: "2019",
    trakt_rating: "6.70877",
    trakt_id: "434097",
    imdb_id: "tt8299768",
    tmdb_id: "586451",
    url: "https://trakt.tv/movies/blow-the-man-down-2019",
    released: "2020-03-20",
    runtime: "90",
    genres: "drama,mystery,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-12T02:46:15Z": {
    type: "movie",
    title: "What Ever Happened to Baby Jane?",
    year: "1962",
    trakt_rating: "8.03929",
    trakt_id: "5452",
    imdb_id: "tt0056687",
    tmdb_id: "10242",
    url: "https://trakt.tv/movies/what-ever-happened-to-baby-jane-1962",
    released: "1962-10-12",
    runtime: "135",
    genres: "drama,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-09T11:00:38Z": {
    type: "movie",
    title: "Downton Abbey: A New Era",
    year: "2022",
    trakt_rating: "7.55279",
    trakt_id: "653785",
    imdb_id: "tt11703710",
    tmdb_id: "820446",
    url: "https://trakt.tv/movies/downton-abbey-a-new-era-2022",
    released: "2022-04-27",
    runtime: "125",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-05T22:50:49Z": {
    type: "movie",
    title: "Bull",
    year: "2021",
    trakt_rating: "6.69429",
    trakt_id: "654991",
    imdb_id: "tt13266998",
    tmdb_id: "821792",
    url: "https://trakt.tv/movies/bull-2021",
    released: "2021-11-05",
    runtime: "87",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-06-04T22:51:47Z": {
    type: "movie",
    title: "Mucho Mucho Amor: The Legend of Walter Mercado",
    year: "2020",
    trakt_rating: "7.50376",
    trakt_id: "488505",
    imdb_id: "tt11378264",
    tmdb_id: "653735",
    url: "https://trakt.tv/movies/mucho-mucho-amor-the-legend-of-walter-mercado-2020",
    released: "2020-07-08",
    runtime: "96",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-05-25T15:29:32Z": {
    type: "movie",
    title: "Here Before",
    year: "2022",
    trakt_rating: "5.84043",
    trakt_id: "503227",
    imdb_id: "tt8649190",
    tmdb_id: "669400",
    url: "https://trakt.tv/movies/here-before-2022",
    released: "2022-02-18",
    runtime: "83",
    genres: "thriller,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-05-17T23:45:52Z": {
    type: "movie",
    title: "Everything Everywhere All at Once",
    year: "2022",
    trakt_rating: "8.00222",
    trakt_id: "396092",
    imdb_id: "tt6710474",
    tmdb_id: "545611",
    url: "https://trakt.tv/movies/everything-everywhere-all-at-once-2022",
    released: "2022-03-25",
    runtime: "140",
    genres: "science-fiction,action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-05-14T17:20:22Z": {
    type: "movie",
    title: "The Lost City",
    year: "2022",
    trakt_rating: "6.57427",
    trakt_id: "592440",
    imdb_id: "tt13320622",
    tmdb_id: "752623",
    url: "https://trakt.tv/movies/the-lost-city-2022",
    released: "2022-03-25",
    runtime: "112",
    genres: "comedy,adventure,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-05-14T13:57:35Z": {
    type: "movie",
    title: "Morbius",
    year: "2022",
    trakt_rating: "5.81076",
    trakt_id: "374949",
    imdb_id: "tt5108870",
    tmdb_id: "526896",
    url: "https://trakt.tv/movies/morbius-2022",
    released: "2022-04-01",
    runtime: "105",
    genres: "action,science-fiction,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-05-13T13:58:43Z": {
    type: "movie",
    title: "Sneakerella",
    year: "2022",
    trakt_rating: "6.23358",
    trakt_id: "595137",
    imdb_id: "tt10160886",
    tmdb_id: "756187",
    url: "https://trakt.tv/movies/sneakerella-2022",
    released: "2022-05-13",
    runtime: "110",
    genres: "comedy,family,musical,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-05-12T12:56:37Z": {
    type: "movie",
    title: "The Northman",
    year: "2022",
    trakt_rating: "6.82294",
    trakt_id: "478776",
    imdb_id: "tt11138512",
    tmdb_id: "639933",
    url: "https://trakt.tv/movies/the-northman-2022",
    released: "2022-04-22",
    runtime: "137",
    genres: "fantasy,adventure,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-05-11T12:02:01Z": {
    type: "movie",
    title: "Operation Mincemeat",
    year: "2022",
    trakt_rating: "6.94571",
    trakt_id: "495296",
    imdb_id: "tt1879016",
    tmdb_id: "661231",
    url: "https://trakt.tv/movies/operation-mincemeat-2022",
    released: "2022-05-06",
    runtime: "128",
    genres: "war,history,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-05-06T17:39:46Z": {
    type: "movie",
    title: "The Power",
    year: "2021",
    trakt_rating: "6.11421",
    trakt_id: "599828",
    imdb_id: "tt9314984",
    tmdb_id: "760919",
    url: "https://trakt.tv/movies/the-power-2021-04-08",
    released: "2021-04-08",
    runtime: "92",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-05-04T13:32:28Z": {
    type: "movie",
    title: "Sonic the Hedgehog 2",
    year: "2022",
    trakt_rating: "7.05071",
    trakt_id: "508640",
    imdb_id: "tt12412888",
    tmdb_id: "675353",
    url: "https://trakt.tv/movies/sonic-the-hedgehog-2-2022",
    released: "2022-04-08",
    runtime: "122",
    genres: "family,comedy,action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-05-02T02:32:40Z": {
    type: "movie",
    title: "The Bad Guys",
    year: "2022",
    trakt_rating: "7.4387",
    trakt_id: "469856",
    imdb_id: "tt8115900",
    tmdb_id: "629542",
    url: "https://trakt.tv/movies/the-bad-guys-2022",
    released: "2022-04-22",
    runtime: "100",
    genres: "animation,comedy,crime,action,adventure,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-05-01T21:53:03Z": {
    type: "movie",
    title: "The Batman",
    year: "2022",
    trakt_rating: "7.63853",
    trakt_id: "259852",
    imdb_id: "tt1877830",
    tmdb_id: "414906",
    url: "https://trakt.tv/movies/the-batman-2022",
    released: "2022-03-04",
    runtime: "177",
    genres: "crime,superhero,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-05-01T20:18:19Z": {
    type: "movie",
    title: "Uncut Gems",
    year: "2019",
    trakt_rating: "7.23792",
    trakt_id: "328678",
    imdb_id: "tt5727208",
    tmdb_id: "473033",
    url: "https://trakt.tv/movies/uncut-gems-2019",
    released: "2019-12-13",
    runtime: "136",
    genres: "crime,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-04-13T18:56:04Z": {
    type: "movie",
    title: "X",
    year: "2022",
    trakt_rating: "6.49643",
    trakt_id: "599217",
    imdb_id: "tt13560574",
    tmdb_id: "760104",
    url: "https://trakt.tv/movies/x-2022",
    released: "2022-03-18",
    runtime: "106",
    genres: "horror,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-04-08T15:57:50Z": {
    type: "movie",
    title: "Metal Lords",
    year: "2022",
    trakt_rating: "6.87562",
    trakt_id: "581075",
    imdb_id: "tt12141112",
    tmdb_id: "739993",
    url: "https://trakt.tv/movies/metal-lords-2022",
    released: "2022-04-08",
    runtime: "97",
    genres: "comedy,drama,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-04-03T23:29:54Z": {
    type: "movie",
    title: "You Are Not My Mother",
    year: "2022",
    trakt_rating: "6.0942",
    trakt_id: "609034",
    imdb_id: "tt10406596",
    tmdb_id: "771536",
    url: "https://trakt.tv/movies/you-are-not-my-mother-2022",
    released: "2022-03-04",
    runtime: "93",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-31T23:39:38Z": {
    type: "movie",
    title: "The Rescue",
    year: "2021",
    trakt_rating: "8.16298",
    trakt_id: "524376",
    imdb_id: "tt9098872",
    tmdb_id: "680058",
    url: "https://trakt.tv/movies/the-rescue-2021-10-08",
    released: "2021-10-08",
    runtime: "107",
    genres: "documentary,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-31T17:47:57Z": {
    type: "movie",
    title: "Pig",
    year: "2021",
    trakt_rating: "6.92466",
    trakt_id: "474763",
    imdb_id: "tt11003218",
    tmdb_id: "635731",
    url: "https://trakt.tv/movies/pig-2021",
    released: "2021-07-16",
    runtime: "92",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-31T17:47:43Z": {
    type: "movie",
    title: "Becoming Cousteau",
    year: "2021",
    trakt_rating: "7.45238",
    trakt_id: "612599",
    imdb_id: "tt10334438",
    tmdb_id: "774823",
    url: "https://trakt.tv/movies/becoming-cousteau-2021",
    released: "2021-10-22",
    runtime: "94",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-29T18:09:34Z": {
    type: "movie",
    title: "Moonfall",
    year: "2022",
    trakt_rating: "6.16826",
    trakt_id: "250807",
    imdb_id: "tt5834426",
    tmdb_id: "406759",
    url: "https://trakt.tv/movies/moonfall-2022",
    released: "2022-02-04",
    runtime: "130",
    genres: "science-fiction,adventure,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-29T18:05:53Z": {
    type: "movie",
    title: "Deep Water",
    year: "2022",
    trakt_rating: "5.98818",
    trakt_id: "462892",
    imdb_id: "tt2180339",
    tmdb_id: "619979",
    url: "https://trakt.tv/movies/deep-water-2022",
    released: "2022-03-18",
    runtime: "116",
    genres: "thriller,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-29T18:05:43Z": {
    type: "movie",
    title: "The 355",
    year: "2022",
    trakt_rating: "6.56428",
    trakt_id: "370205",
    imdb_id: "tt8356942",
    tmdb_id: "522016",
    url: "https://trakt.tv/movies/the-355-2022",
    released: "2022-01-07",
    runtime: "122",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-29T18:05:30Z": {
    type: "movie",
    title: "Eden",
    year: "2014",
    trakt_rating: "5.95",
    trakt_id: "180628",
    imdb_id: "tt3090634",
    tmdb_id: "283330",
    url: "https://trakt.tv/movies/eden-2014-11-19",
    released: "2014-11-19",
    runtime: "131",
    genres: "drama,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-29T18:05:19Z": {
    type: "movie",
    title: "24 Hour Party People",
    year: "2002",
    trakt_rating: "7.34455",
    trakt_id: "1834",
    imdb_id: "tt0274309",
    tmdb_id: "2750",
    url: "https://trakt.tv/movies/24-hour-party-people-2002",
    released: "2002-02-13",
    runtime: "117",
    genres: "comedy,drama,musical,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-28T13:19:25Z": {
    type: "movie",
    title: "King Richard",
    year: "2021",
    trakt_rating: "7.7873",
    trakt_id: "458405",
    imdb_id: "tt9620288",
    tmdb_id: "614917",
    url: "https://trakt.tv/movies/king-richard-2021",
    released: "2021-11-19",
    runtime: "145",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-25T22:31:40Z": {
    type: "movie",
    title: "CODA",
    year: "2021",
    trakt_rating: "8.16443",
    trakt_id: "613749",
    imdb_id: "tt10366460",
    tmdb_id: "776503",
    url: "https://trakt.tv/movies/coda-2021",
    released: "2021-08-13",
    runtime: "112",
    genres: "drama,music,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-24T15:19:26Z": {
    type: "movie",
    title: "Dagon",
    year: "2001",
    trakt_rating: "6.42829",
    trakt_id: "5993",
    imdb_id: "tt0264508",
    tmdb_id: "10836",
    url: "https://trakt.tv/movies/dagon-2001",
    released: "2001-10-12",
    runtime: "95",
    genres: "fantasy,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-17T03:07:21Z": {
    type: "movie",
    title: "The Immortal",
    year: "2019",
    trakt_rating: "7.07724",
    trakt_id: "445597",
    imdb_id: "tt9048786",
    tmdb_id: "599845",
    url: "https://trakt.tv/movies/the-immortal-2019",
    released: "2019-12-05",
    runtime: "115",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-12T15:45:33Z": {
    type: "movie",
    title: "Studio 666",
    year: "2022",
    trakt_rating: "6.1581",
    trakt_id: "718675",
    imdb_id: "tt15374070",
    tmdb_id: "895744",
    url: "https://trakt.tv/movies/studio-666-2022",
    released: "2022-02-25",
    runtime: "106",
    genres: "horror,comedy,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-12T14:12:36Z": {
    type: "movie",
    title: "Spider-Man: No Way Home",
    year: "2021",
    trakt_rating: "8.18225",
    trakt_id: "474180",
    imdb_id: "tt10872600",
    tmdb_id: "634649",
    url: "https://trakt.tv/movies/spider-man-no-way-home-2021",
    released: "2021-12-17",
    runtime: "148",
    genres: "superhero,action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-11T14:22:20Z": {
    type: "movie",
    title: "Turning Red",
    year: "2022",
    trakt_rating: "7.35052",
    trakt_id: "356441",
    imdb_id: "tt8097030",
    tmdb_id: "508947",
    url: "https://trakt.tv/movies/turning-red-2022",
    released: "2022-03-11",
    runtime: "100",
    genres: "animation,comedy,family,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-03-11T14:22:12Z": {
    type: "movie",
    title: "Dog",
    year: "2022",
    trakt_rating: "7.2106",
    trakt_id: "505231",
    imdb_id: "tt11252248",
    tmdb_id: "626735",
    url: "https://trakt.tv/movies/dog-2022",
    released: "2022-02-18",
    runtime: "102",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-02-11T15:19:40Z": {
    type: "movie",
    title: "Marry Me",
    year: "2022",
    trakt_rating: "6.94665",
    trakt_id: "458914",
    imdb_id: "tt10223460",
    tmdb_id: "615904",
    url: "https://trakt.tv/movies/marry-me-2022",
    released: "2022-02-11",
    runtime: "112",
    genres: "comedy,romance,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-02-01T15:22:55Z": {
    type: "movie",
    title: "Nightmare Alley",
    year: "2021",
    trakt_rating: "6.9266",
    trakt_id: "443066",
    imdb_id: "tt7740496",
    tmdb_id: "597208",
    url: "https://trakt.tv/movies/nightmare-alley-2021",
    released: "2021-12-17",
    runtime: "150",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-02-01T15:21:58Z": {
    type: "movie",
    title: "House of Gucci",
    year: "2021",
    trakt_rating: "6.70438",
    trakt_id: "481862",
    imdb_id: "tt11214590",
    tmdb_id: "644495",
    url: "https://trakt.tv/movies/house-of-gucci-2021",
    released: "2021-11-24",
    runtime: "158",
    genres: "drama,crime,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-01-18T17:53:27Z": {
    type: "movie",
    title: "The Tragedy of Macbeth",
    year: "2021",
    trakt_rating: "6.98954",
    trakt_id: "437960",
    imdb_id: "tt10095582",
    tmdb_id: "591538",
    url: "https://trakt.tv/movies/the-tragedy-of-macbeth-2021",
    released: "2021-12-05",
    runtime: "105",
    genres: "drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-01-12T00:05:01Z": {
    type: "movie",
    title: "Eternals",
    year: "2021",
    trakt_rating: "6.58971",
    trakt_id: "372798",
    imdb_id: "tt9032400",
    tmdb_id: "524434",
    url: "https://trakt.tv/movies/eternals-2021",
    released: "2021-11-05",
    runtime: "156",
    genres: "superhero,science-fiction,action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-01-09T14:56:25Z": {
    type: "movie",
    title: "Sing 2",
    year: "2021",
    trakt_rating: "7.71221",
    trakt_id: "284832",
    imdb_id: "tt6467266",
    tmdb_id: "438695",
    url: "https://trakt.tv/movies/sing-2-2021",
    released: "2021-12-22",
    runtime: "110",
    genres: "animation,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-01-07T00:24:48Z": {
    type: "movie",
    title: "A Serbian Film",
    year: "2010",
    trakt_rating: "5.07431",
    trakt_id: "54321",
    imdb_id: "tt1273235",
    tmdb_id: "73861",
    url: "https://trakt.tv/movies/a-serbian-film-2010",
    released: "2010-06-16",
    runtime: "104",
    genres: "crime,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-01-05T14:42:58Z": {
    type: "movie",
    title: "Lake Mungo",
    year: "2009",
    trakt_rating: "6.32268",
    trakt_id: "16801",
    imdb_id: "tt0816556",
    tmdb_id: "27374",
    url: "https://trakt.tv/movies/lake-mungo-2009",
    released: "2009-07-30",
    runtime: "87",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2022-01-04T14:16:40Z": {
    type: "movie",
    title: "Ghostbusters: Afterlife",
    year: "2021",
    trakt_rating: "7.33157",
    trakt_id: "270182",
    imdb_id: "tt4513678",
    tmdb_id: "425909",
    url: "https://trakt.tv/movies/ghostbusters-afterlife-2021",
    released: "2021-11-19",
    runtime: "124",
    genres: "comedy,fantasy,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-12-25T14:37:43Z": {
    type: "movie",
    title: "Encanto",
    year: "2021",
    trakt_rating: "7.54953",
    trakt_id: "419442",
    imdb_id: "tt2953050",
    tmdb_id: "568124",
    url: "https://trakt.tv/movies/encanto-2021",
    released: "2021-11-24",
    runtime: "102",
    genres: "animation,family,fantasy,comedy,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-12-25T14:36:17Z": {
    type: "movie",
    title: "Resident Evil: Welcome to Raccoon City",
    year: "2021",
    trakt_rating: "5.65221",
    trakt_id: "307489",
    imdb_id: "tt6920084",
    tmdb_id: "460458",
    url: "https://trakt.tv/movies/resident-evil-welcome-to-raccoon-city-2021",
    released: "2021-11-24",
    runtime: "107",
    genres: "action,horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-12-13T09:43:25Z": {
    type: "movie",
    title: "Antlers",
    year: "2021",
    trakt_rating: "6.19168",
    trakt_id: "364794",
    imdb_id: "tt7740510",
    tmdb_id: "516329",
    url: "https://trakt.tv/movies/antlers-2021",
    released: "2021-10-29",
    runtime: "99",
    genres: "horror,mystery,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-12-10T11:37:50Z": {
    type: "movie",
    title: "Encounter",
    year: "2021",
    trakt_rating: "5.99387",
    trakt_id: "570905",
    imdb_id: "tt12800524",
    tmdb_id: "728526",
    url: "https://trakt.tv/movies/encounter-2021-12-03",
    released: "2021-12-03",
    runtime: "108",
    genres: "drama,thriller,science-fiction,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-12-09T06:49:16Z": {
    type: "movie",
    title: "Titane",
    year: "2021",
    trakt_rating: "6.32145",
    trakt_id: "470576",
    imdb_id: "tt10944760",
    tmdb_id: "630240",
    url: "https://trakt.tv/movies/titane-2021",
    released: "2021-07-28",
    runtime: "108",
    genres: "thriller,drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-12-09T03:31:25Z": {
    type: "movie",
    title: "Clifford the Big Red Dog",
    year: "2021",
    trakt_rating: "6.86612",
    trakt_id: "432842",
    imdb_id: "tt2397461",
    tmdb_id: "585245",
    url: "https://trakt.tv/movies/clifford-the-big-red-dog-2021",
    released: "2021-11-10",
    runtime: "97",
    genres: "family,comedy,fantasy,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-12-09T03:30:02Z": {
    type: "movie",
    title: "8-Bit Christmas",
    year: "2021",
    trakt_rating: "6.96544",
    trakt_id: "637209",
    imdb_id: "tt11540284",
    tmdb_id: "802217",
    url: "https://trakt.tv/movies/8-bit-christmas-2021",
    released: "2021-11-24",
    runtime: "98",
    genres: "family,comedy,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-12-09T03:29:48Z": {
    type: "movie",
    title: "BLONDE. Purple",
    year: "2021",
    trakt_rating: "4.5",
    trakt_id: "714350",
    imdb_id: "tt13677962",
    tmdb_id: "890691",
    url: "https://trakt.tv/movies/blonde-purple-2021",
    released: "2021-11-30",
    runtime: "129",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-12-09T03:29:33Z": {
    type: "movie",
    title: "Deadlock",
    year: "2021",
    trakt_rating: "5.40541",
    trakt_id: "561712",
    imdb_id: "tt10957044",
    tmdb_id: "718949",
    url: "https://trakt.tv/movies/deadlock-2021-12-03",
    released: "2021-12-03",
    runtime: "96",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-12-09T03:29:21Z": {
    type: "movie",
    title: "Hard Luck Love Song",
    year: "2021",
    trakt_rating: "5.52",
    trakt_id: "387948",
    imdb_id: "tt8709338",
    tmdb_id: "536208",
    url: "https://trakt.tv/movies/hard-luck-love-song-2021",
    released: "2021-10-15",
    runtime: "104",
    genres: "romance,drama,thriller,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-12-09T03:28:26Z": {
    type: "movie",
    title: "Silent Night",
    year: "2021",
    trakt_rating: "6.23138",
    trakt_id: "498540",
    imdb_id: "tt11628854",
    tmdb_id: "664574",
    url: "https://trakt.tv/movies/silent-night-2021",
    released: "2021-12-03",
    runtime: "92",
    genres: "comedy,holiday,science-fiction,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-12-01T03:22:05Z": {
    type: "movie",
    title: "Ron's Gone Wrong",
    year: "2021",
    trakt_rating: "7.51327",
    trakt_id: "329543",
    imdb_id: "tt7504818",
    tmdb_id: "482321",
    url: "https://trakt.tv/movies/ron-s-gone-wrong-2021",
    released: "2021-10-22",
    runtime: "107",
    genres: "animation,science-fiction,family,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-11-25T20:53:09Z": {
    type: "movie",
    title: "May the Devil Take You",
    year: "2018",
    trakt_rating: "6.24171",
    trakt_id: "373269",
    imdb_id: "tt8765496",
    tmdb_id: "524738",
    url: "https://trakt.tv/movies/may-the-devil-take-you-2018",
    released: "2018-08-09",
    runtime: "110",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-11-23T03:09:36Z": {
    type: "movie",
    title: "Venom: Let There Be Carnage",
    year: "2021",
    trakt_rating: "6.3987",
    trakt_id: "429764",
    imdb_id: "tt7097896",
    tmdb_id: "580489",
    url: "https://trakt.tv/movies/venom-let-there-be-carnage-2021",
    released: "2021-10-01",
    runtime: "97",
    genres: "action,science-fiction,superhero,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-11-12T12:26:00Z": {
    type: "movie",
    title: "Home Sweet Home Alone",
    year: "2021",
    trakt_rating: "5.20634",
    trakt_id: "489416",
    imdb_id: "tt11012066",
    tmdb_id: "654974",
    url: "https://trakt.tv/movies/home-sweet-home-alone-2021",
    released: "2021-11-12",
    runtime: "93",
    genres: "family,comedy,holiday,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-11-10T02:49:01Z": {
    type: "movie",
    title: "Shang-Chi and the Legend of the Ten Rings",
    year: "2021",
    trakt_rating: "7.5387",
    trakt_id: "416151",
    imdb_id: "tt9376612",
    tmdb_id: "566525",
    url: "https://trakt.tv/movies/shang-chi-and-the-legend-of-the-ten-rings-2021",
    released: "2021-09-03",
    runtime: "132",
    genres: "action,adventure,fantasy,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-11-09T06:02:13Z": {
    type: "movie",
    title: "No Time to Die",
    year: "2021",
    trakt_rating: "7.42986",
    trakt_id: "254935",
    imdb_id: "tt2382320",
    tmdb_id: "370172",
    url: "https://trakt.tv/movies/no-time-to-die-2021",
    released: "2021-10-08",
    runtime: "163",
    genres: "action,thriller,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-10-23T05:45:37Z": {
    type: "movie",
    title: "The Descent",
    year: "2005",
    trakt_rating: "7.20911",
    trakt_id: "4681",
    imdb_id: "tt0435625",
    tmdb_id: "9392",
    url: "https://trakt.tv/movies/the-descent-2005",
    released: "2005-07-08",
    runtime: "99",
    genres: "adventure,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-10-18T13:53:57Z": {
    type: "movie",
    title: "Dear Evan Hansen",
    year: "2021",
    trakt_rating: "6.36038",
    trakt_id: "417301",
    imdb_id: "tt9357050",
    tmdb_id: "567690",
    url: "https://trakt.tv/movies/dear-evan-hansen-2021",
    released: "2021-09-24",
    runtime: "137",
    genres: "drama,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-10-17T03:37:58Z": {
    type: "movie",
    title: "Dune",
    year: "2021",
    trakt_rating: "7.94182",
    trakt_id: "287071",
    imdb_id: "tt1160419",
    tmdb_id: "438631",
    url: "https://trakt.tv/movies/dune-2021",
    released: "2021-10-21",
    runtime: "155",
    genres: "science-fiction,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-10-17T03:04:15Z": {
    type: "movie",
    title: "The Room",
    year: "2003",
    trakt_rating: "4.7811",
    trakt_id: "10677",
    imdb_id: "tt0368226",
    tmdb_id: "17473",
    url: "https://trakt.tv/movies/the-room-2003",
    released: "2003-06-27",
    runtime: "99",
    genres: "drama,romance,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-10-05T04:40:46Z": {
    type: "movie",
    title: "The Night House",
    year: "2021",
    trakt_rating: "6.60287",
    trakt_id: "397697",
    imdb_id: "tt9731534",
    tmdb_id: "547565",
    url: "https://trakt.tv/movies/the-night-house-2021",
    released: "2021-08-20",
    runtime: "107",
    genres: "horror,thriller,mystery,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-10-02T08:33:09Z": {
    type: "movie",
    title: "The Addams Family 2",
    year: "2021",
    trakt_rating: "6.5038",
    trakt_id: "480426",
    imdb_id: "tt11125620",
    tmdb_id: "639721",
    url: "https://trakt.tv/movies/the-addams-family-2-2021",
    released: "2021-10-01",
    runtime: "93",
    genres: "animation,comedy,family,adventure,fantasy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-10-01T14:15:17Z": {
    type: "movie",
    title: "The Many Saints of Newark",
    year: "2021",
    trakt_rating: "6.58576",
    trakt_id: "372839",
    imdb_id: "tt8110232",
    tmdb_id: "524369",
    url: "https://trakt.tv/movies/the-many-saints-of-newark-2021",
    released: "2021-10-01",
    runtime: "120",
    genres: "crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-09-30T02:25:29Z": {
    type: "movie",
    title: "Kate",
    year: "2021",
    trakt_rating: "6.7301",
    trakt_id: "443935",
    imdb_id: "tt7737528",
    tmdb_id: "597891",
    url: "https://trakt.tv/movies/kate-2021",
    released: "2021-09-10",
    runtime: "106",
    genres: "action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-09-23T13:47:16Z": {
    type: "movie",
    title: "Free Guy",
    year: "2021",
    trakt_rating: "7.38512",
    trakt_id: "400704",
    imdb_id: "tt6264654",
    tmdb_id: "550988",
    url: "https://trakt.tv/movies/free-guy-2021",
    released: "2021-08-13",
    runtime: "115",
    genres: "comedy,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-09-21T20:23:38Z": {
    type: "movie",
    title: "Old",
    year: "2021",
    trakt_rating: "6.15332",
    trakt_id: "471634",
    imdb_id: "tt10954652",
    tmdb_id: "631843",
    url: "https://trakt.tv/movies/old-2021",
    released: "2021-07-23",
    runtime: "108",
    genres: "thriller,mystery,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-09-16T04:44:41Z": {
    type: "movie",
    title: "Candyman",
    year: "2021",
    trakt_rating: "6.18932",
    trakt_id: "414857",
    imdb_id: "tt9347730",
    tmdb_id: "565028",
    url: "https://trakt.tv/movies/candyman-2021",
    released: "2021-08-27",
    runtime: "91",
    genres: "horror,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-08-19T08:00:36Z": {
    type: "movie",
    title: "Stillwater",
    year: "2021",
    trakt_rating: "6.78573",
    trakt_id: "459736",
    imdb_id: "tt10696896",
    tmdb_id: "616651",
    url: "https://trakt.tv/movies/stillwater-2021",
    released: "2021-07-30",
    runtime: "139",
    genres: "drama,thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-08-08T09:02:23Z": {
    type: "movie",
    title: "Jungle Cruise",
    year: "2021",
    trakt_rating: "7.05808",
    trakt_id: "297576",
    imdb_id: "tt0870154",
    tmdb_id: "451048",
    url: "https://trakt.tv/movies/jungle-cruise-2021",
    released: "2021-07-30",
    runtime: "127",
    genres: "adventure,fantasy,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-08-07T21:59:44Z": {
    type: "movie",
    title: "The Suicide Squad",
    year: "2021",
    trakt_rating: "7.263",
    trakt_id: "283364",
    imdb_id: "tt6334354",
    tmdb_id: "436969",
    url: "https://trakt.tv/movies/the-suicide-squad-2021",
    released: "2021-08-05",
    runtime: "132",
    genres: "action,adventure,superhero,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-08-04T19:19:32Z": {
    type: "movie",
    title: "Super Mario Bros.",
    year: "1993",
    trakt_rating: "5.15676",
    trakt_id: "4883",
    imdb_id: "tt0108255",
    tmdb_id: "9607",
    url: "https://trakt.tv/movies/super-mario-bros-1993",
    released: "1993-05-28",
    runtime: "104",
    genres: "adventure,comedy,family,science-fiction,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-07-21T06:08:30Z": {
    type: "movie",
    title: "Nobody",
    year: "2021",
    trakt_rating: "7.67462",
    trakt_id: "458837",
    imdb_id: "tt7888964",
    tmdb_id: "615457",
    url: "https://trakt.tv/movies/nobody-2021",
    released: "2021-03-26",
    runtime: "92",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-07-16T14:05:42Z": {
    type: "movie",
    title: "Space Jam: A New Legacy",
    year: "2021",
    trakt_rating: "5.79709",
    trakt_id: "277430",
    imdb_id: "tt3554046",
    tmdb_id: "379686",
    url: "https://trakt.tv/movies/space-jam-a-new-legacy-2021",
    released: "2021-07-16",
    runtime: "115",
    genres: "comedy,family,animation,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-07-11T16:33:32Z": {
    type: "movie",
    title: "A Quiet Place Part II",
    year: "2021",
    trakt_rating: "7.3402",
    trakt_id: "369071",
    imdb_id: "tt8332922",
    tmdb_id: "520763",
    url: "https://trakt.tv/movies/a-quiet-place-part-ii-2021",
    released: "2021-05-28",
    runtime: "97",
    genres: "thriller,science-fiction,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-07-11T15:19:04Z": {
    type: "movie",
    title: "Black Widow",
    year: "2021",
    trakt_rating: "7.06617",
    trakt_id: "344686",
    imdb_id: "tt3480822",
    tmdb_id: "497698",
    url: "https://trakt.tv/movies/black-widow-2021",
    released: "2021-07-09",
    runtime: "134",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-07-10T22:37:34Z": {
    type: "movie",
    title: "Censor",
    year: "2021",
    trakt_rating: "6.1405",
    trakt_id: "497689",
    imdb_id: "tt10329614",
    tmdb_id: "663866",
    url: "https://trakt.tv/movies/censor-2021",
    released: "2021-08-20",
    runtime: "84",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-07-02T18:59:03Z": {
    type: "movie",
    title: "The Boss Baby: Family Business",
    year: "2021",
    trakt_rating: "6.89813",
    trakt_id: "306320",
    imdb_id: "tt6932874",
    tmdb_id: "459151",
    url: "https://trakt.tv/movies/the-boss-baby-family-business-2021",
    released: "2021-07-01",
    runtime: "107",
    genres: "animation,comedy,adventure,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-06-21T19:51:49Z": {
    type: "movie",
    title: "Luca",
    year: "2021",
    trakt_rating: "7.7564",
    trakt_id: "356328",
    imdb_id: "tt12801262",
    tmdb_id: "508943",
    url: "https://trakt.tv/movies/luca-2021",
    released: "2021-06-18",
    runtime: "95",
    genres: "animation,comedy,fantasy,adventure,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-06-17T02:26:28Z": {
    type: "movie",
    title: "Army of the Dead",
    year: "2021",
    trakt_rating: "6.18737",
    trakt_id: "350970",
    imdb_id: "tt0993840",
    tmdb_id: "503736",
    url: "https://trakt.tv/movies/army-of-the-dead-2021",
    released: "2021-05-14",
    runtime: "148",
    genres: "action,crime,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-06-12T00:58:50Z": {
    type: "movie",
    title: "In the Heights",
    year: "2021",
    trakt_rating: "7.45057",
    trakt_id: "315136",
    imdb_id: "tt1321510",
    tmdb_id: "467909",
    url: "https://trakt.tv/movies/in-the-heights-2021",
    released: "2021-06-10",
    runtime: "143",
    genres: "drama,romance,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-06-08T14:53:00Z": {
    type: "movie",
    title: "Save Yourselves!",
    year: "2020",
    trakt_rating: "6.11691",
    trakt_id: "488442",
    imdb_id: "tt7873348",
    tmdb_id: "653569",
    url: "https://trakt.tv/movies/save-yourselves-2020",
    released: "2020-10-02",
    runtime: "93",
    genres: "comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-06-08T07:15:57Z": {
    type: "movie",
    title: "Cruella",
    year: "2021",
    trakt_rating: "7.67607",
    trakt_id: "218008",
    imdb_id: "tt3228774",
    tmdb_id: "337404",
    url: "https://trakt.tv/movies/cruella-2021",
    released: "2021-05-28",
    runtime: "134",
    genres: "comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-06-03T15:57:40Z": {
    type: "movie",
    title: "DragonHeart",
    year: "1996",
    trakt_rating: "6.9326",
    trakt_id: "4331",
    imdb_id: "tt0116136",
    tmdb_id: "8840",
    url: "https://trakt.tv/movies/dragonheart-1996",
    released: "1996-05-31",
    runtime: "103",
    genres: "fantasy,action,adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-05-24T03:31:25Z": {
    type: "movie",
    title: "Menace II Society",
    year: "1993",
    trakt_rating: "7.90146",
    trakt_id: "4796",
    imdb_id: "tt0107554",
    tmdb_id: "9516",
    url: "https://trakt.tv/movies/menace-ii-society-1993",
    released: "1993-05-26",
    runtime: "97",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-05-24T01:23:09Z": {
    type: "movie",
    title: "Lapsis",
    year: "2021",
    trakt_rating: "6.35",
    trakt_id: "499538",
    imdb_id: "tt11540272",
    tmdb_id: "665888",
    url: "https://trakt.tv/movies/lapsis-2021",
    released: "2021-02-12",
    runtime: "108",
    genres: "science-fiction,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-05-24T01:15:37Z": {
    type: "movie",
    title: "The Vigil",
    year: "2020",
    trakt_rating: "5.9839",
    trakt_id: "464099",
    imdb_id: "tt10793644",
    tmdb_id: "621707",
    url: "https://trakt.tv/movies/the-vigil-2020",
    released: "2021-02-26",
    runtime: "88",
    genres: "horror,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-05-22T22:05:50Z": {
    type: "movie",
    title: "Tangled",
    year: "2010",
    trakt_rating: "7.8761",
    trakt_id: "25084",
    imdb_id: "tt0398286",
    tmdb_id: "38757",
    url: "https://trakt.tv/movies/tangled-2010",
    released: "2010-11-24",
    runtime: "100",
    genres: "animation,family,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-05-13T02:14:03Z": {
    type: "movie",
    title: "Maleficent: Mistress of Evil",
    year: "2019",
    trakt_rating: "7.26868",
    trakt_id: "265465",
    imdb_id: "tt4777008",
    tmdb_id: "420809",
    url: "https://trakt.tv/movies/maleficent-mistress-of-evil-2019",
    released: "2019-10-18",
    runtime: "119",
    genres: "fantasy,adventure,family,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-05-13T02:13:37Z": {
    type: "movie",
    title: "Maleficent",
    year: "2014",
    trakt_rating: "7.37275",
    trakt_id: "73425",
    imdb_id: "tt1587310",
    tmdb_id: "102651",
    url: "https://trakt.tv/movies/maleficent-2014",
    released: "2014-05-30",
    runtime: "97",
    genres: "action,adventure,fantasy,romance,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-05-09T12:15:43Z": {
    type: "movie",
    title: "Resident Evil",
    year: "2002",
    trakt_rating: "7.13733",
    trakt_id: "1032",
    imdb_id: "tt0120804",
    tmdb_id: "1576",
    url: "https://trakt.tv/movies/resident-evil-2002",
    released: "2002-03-15",
    runtime: "100",
    genres: "action,horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-05-09T01:21:42Z": {
    type: "movie",
    title: "Resident Evil: Apocalypse",
    year: "2004",
    trakt_rating: "6.75282",
    trakt_id: "1033",
    imdb_id: "tt0318627",
    tmdb_id: "1577",
    url: "https://trakt.tv/movies/resident-evil-apocalypse-2004",
    released: "2004-09-10",
    runtime: "94",
    genres: "action,horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-05-07T15:22:16Z": {
    type: "movie",
    title: "The SpongeBob Movie: Sponge on the Run",
    year: "2020",
    trakt_rating: "6.65037",
    trakt_id: "243441",
    imdb_id: "tt4823776",
    tmdb_id: "400160",
    url: "https://trakt.tv/movies/the-spongebob-movie-sponge-on-the-run-2020",
    released: "2021-03-04",
    runtime: "95",
    genres: "fantasy,animation,adventure,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-05-07T15:20:49Z": {
    type: "movie",
    title: "Scooby-Doo! The Sword and the Scoob",
    year: "2021",
    trakt_rating: "6.86559",
    trakt_id: "527094",
    imdb_id: "tt13676256",
    tmdb_id: "682254",
    url: "https://trakt.tv/movies/scooby-doo-the-sword-and-the-scoob-2021",
    released: "2021-02-23",
    runtime: "72",
    genres: "animation,comedy,family,mystery,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-05-07T15:20:11Z": {
    type: "movie",
    title: "Happy Halloween, Scooby-Doo!",
    year: "2020",
    trakt_rating: "6.98444",
    trakt_id: "564243",
    imdb_id: "tt12838958",
    tmdb_id: "721656",
    url: "https://trakt.tv/movies/happy-halloween-scooby-doo-2020",
    released: "2020-10-06",
    runtime: "76",
    genres: "animation,family,mystery,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-05-07T15:19:12Z": {
    type: "movie",
    title: "Flora & Ulysses",
    year: "2021",
    trakt_rating: "6.74061",
    trakt_id: "464113",
    imdb_id: "tt8521736",
    tmdb_id: "621876",
    url: "https://trakt.tv/movies/flora-ulysses-2021",
    released: "2021-02-19",
    runtime: "91",
    genres: "family,adventure,comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-05-05T17:14:46Z": {
    type: "movie",
    title: "The Mitchells vs. the Machines",
    year: "2021",
    trakt_rating: "7.87891",
    trakt_id: "349011",
    imdb_id: "tt7979580",
    tmdb_id: "501929",
    url: "https://trakt.tv/movies/the-mitchells-vs-the-machines-2021",
    released: "2021-04-22",
    runtime: "110",
    genres: "animation,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-04-09T13:02:32Z": {
    type: "movie",
    title: "Don't Kill It",
    year: "2016",
    trakt_rating: "5.96786",
    trakt_id: "250142",
    imdb_id: "tt3113696",
    tmdb_id: "405882",
    url: "https://trakt.tv/movies/don-t-kill-it-2016",
    released: "2017-03-03",
    runtime: "93",
    genres: "horror,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-03-31T11:12:05Z": {
    type: "movie",
    title: "Godzilla vs. Kong",
    year: "2021",
    trakt_rating: "6.7988",
    trakt_id: "242869",
    imdb_id: "tt5034838",
    tmdb_id: "399566",
    url: "https://trakt.tv/movies/godzilla-vs-kong-2021",
    released: "2021-03-31",
    runtime: "114",
    genres: "action,fantasy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-03-26T11:04:10Z": {
    type: "movie",
    title: "The Father",
    year: "2020",
    trakt_rating: "8.16868",
    trakt_id: "445922",
    imdb_id: "tt10272386",
    tmdb_id: "600354",
    url: "https://trakt.tv/movies/the-father-2020",
    released: "2021-02-26",
    runtime: "96",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-03-23T13:15:13Z": {
    type: "movie",
    title: "Hell House LLC",
    year: "2015",
    trakt_rating: "6.78979",
    trakt_id: "224591",
    imdb_id: "tt4267026",
    tmdb_id: "359246",
    url: "https://trakt.tv/movies/hell-house-llc-2015",
    released: "2016-11-01",
    runtime: "93",
    genres: "thriller,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-03-20T19:53:57Z": {
    type: "movie",
    title: "Gonjiam: Haunted Asylum",
    year: "2018",
    trakt_rating: "6.975",
    trakt_id: "355936",
    imdb_id: "tt8119752",
    tmdb_id: "508642",
    url: "https://trakt.tv/movies/gonjiam-haunted-asylum-2018",
    released: "2018-03-28",
    runtime: "95",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-03-20T19:51:17Z": {
    type: "movie",
    title: "The Legend of La Llorona",
    year: "2022",
    trakt_rating: "4.74167",
    trakt_id: "471917",
    imdb_id: "tt7267498",
    tmdb_id: "631947",
    url: "https://trakt.tv/movies/the-legend-of-la-llorona-2022",
    released: "2022-01-07",
    runtime: "98",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-03-09T04:20:48Z": {
    type: "movie",
    title: "Raya and the Last Dragon",
    year: "2021",
    trakt_rating: "7.67461",
    trakt_id: "375905",
    imdb_id: "tt5109280",
    tmdb_id: "527774",
    url: "https://trakt.tv/movies/raya-and-the-last-dragon-2021",
    released: "2021-03-05",
    runtime: "107",
    genres: "adventure,animation,fantasy,family,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-03-03T07:11:11Z": {
    type: "movie",
    title: "Saint Maud",
    year: "2019",
    trakt_rating: "6.57247",
    trakt_id: "426224",
    imdb_id: "tt7557108",
    tmdb_id: "575776",
    url: "https://trakt.tv/movies/saint-maud-2019",
    released: "2019-12-27",
    runtime: "85",
    genres: "horror,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-03-02T15:33:09Z": {
    type: "movie",
    title: "Bone Tomahawk",
    year: "2015",
    trakt_rating: "7.03725",
    trakt_id: "195457",
    imdb_id: "tt2494362",
    tmdb_id: "294963",
    url: "https://trakt.tv/movies/bone-tomahawk-2015",
    released: "2015-10-23",
    runtime: "133",
    genres: "western,horror,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-02-22T02:03:34Z": {
    type: "movie",
    title: "Nomadland",
    year: "2021",
    trakt_rating: "7.2414",
    trakt_id: "430483",
    imdb_id: "tt9770150",
    tmdb_id: "581734",
    url: "https://trakt.tv/movies/nomadland-2021",
    released: "2020-12-04",
    runtime: "108",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-02-09T16:39:29Z": {
    type: "movie",
    title: "Alexander and the Terrible, Horrible, No Good, Very Bad Day",
    year: "2014",
    trakt_rating: "6.75761",
    trakt_id: "134751",
    imdb_id: "tt1698641",
    tmdb_id: "218778",
    url: "https://trakt.tv/movies/alexander-and-the-terrible-horrible-no-good-very-bad-day-2014",
    released: "2014-10-10",
    runtime: "81",
    genres: "comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-01-31T02:58:04Z": {
    type: "movie",
    title: "Derek DelGaudio's In & of Itself",
    year: "2020",
    trakt_rating: "7.82508",
    trakt_id: "526250",
    imdb_id: "tt11916302",
    tmdb_id: "681459",
    url: "https://trakt.tv/movies/derek-delgaudio-s-in-of-itself-2020",
    released: "2020-10-24",
    runtime: "90",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-01-26T06:53:47Z": {
    type: "movie",
    title: "The Dissident",
    year: "2020",
    trakt_rating: "7.7212",
    trakt_id: "489835",
    imdb_id: "tt11382384",
    tmdb_id: "653746",
    url: "https://trakt.tv/movies/the-dissident-2020",
    released: "2020-12-18",
    runtime: "119",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-01-15T23:52:34Z": {
    type: "movie",
    title: "Hunter Hunter",
    year: "2020",
    trakt_rating: "6.77488",
    trakt_id: "598780",
    imdb_id: "tt2226162",
    tmdb_id: "759902",
    url: "https://trakt.tv/movies/hunter-hunter-2020",
    released: "2020-12-18",
    runtime: "93",
    genres: "horror,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2021-01-06T13:54:22Z": {
    type: "movie",
    title: "Run",
    year: "2020",
    trakt_rating: "7.15204",
    trakt_id: "396539",
    imdb_id: "tt8633478",
    tmdb_id: "546121",
    url: "https://trakt.tv/movies/run-2020",
    released: "2020-11-20",
    runtime: "90",
    genres: "thriller,horror,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-27T18:48:41Z": {
    type: "movie",
    title: "Wonder Woman 1984",
    year: "2020",
    trakt_rating: "6.01192",
    trakt_id: "311212",
    imdb_id: "tt7126948",
    tmdb_id: "464052",
    url: "https://trakt.tv/movies/wonder-woman-1984-2020",
    released: "2020-12-25",
    runtime: "151",
    genres: "fantasy,action,adventure,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-27T12:44:09Z": {
    type: "movie",
    title: "Soul",
    year: "2020",
    trakt_rating: "8.05186",
    trakt_id: "355959",
    imdb_id: "tt2948372",
    tmdb_id: "508442",
    url: "https://trakt.tv/movies/soul-2020",
    released: "2020-12-25",
    runtime: "101",
    genres: "animation,family,comedy,fantasy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-26T21:59:09Z": {
    type: "movie",
    title: "Skin",
    year: "2019",
    trakt_rating: "7.07903",
    trakt_id: "393267",
    imdb_id: "tt6043142",
    tmdb_id: "542417",
    url: "https://trakt.tv/movies/skin-2019",
    released: "2019-07-26",
    runtime: "110",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-26T21:58:55Z": {
    type: "movie",
    title: "She Dies Tomorrow",
    year: "2020",
    trakt_rating: "4.9675",
    trakt_id: "498262",
    imdb_id: "tt11614912",
    tmdb_id: "664297",
    url: "https://trakt.tv/movies/she-dies-tomorrow-2020",
    released: "2020-07-31",
    runtime: "85",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-26T21:58:38Z": {
    type: "movie",
    title: "Over the Moon",
    year: "2020",
    trakt_rating: "6.9861",
    trakt_id: "410666",
    imdb_id: "tt7488208",
    tmdb_id: "560050",
    url: "https://trakt.tv/movies/over-the-moon-2020",
    released: "2020-10-16",
    runtime: "100",
    genres: "animation,adventure,family,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-26T21:58:04Z": {
    type: "movie",
    title: "The Kid",
    year: "1921",
    trakt_rating: "8.11892",
    trakt_id: "5318",
    imdb_id: "tt0012349",
    tmdb_id: "10098",
    url: "https://trakt.tv/movies/the-kid-1921",
    released: "1921-01-21",
    runtime: "68",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-18T03:21:02Z": {
    type: "movie",
    title: "The Croods: A New Age",
    year: "2020",
    trakt_rating: "7.42857",
    trakt_id: "379134",
    imdb_id: "tt2850386",
    tmdb_id: "529203",
    url: "https://trakt.tv/movies/the-croods-a-new-age-2020",
    released: "2020-11-25",
    runtime: "95",
    genres: "animation,family,fantasy,comedy,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-15T02:27:24Z": {
    type: "movie",
    title: "Da 5 Bloods",
    year: "2020",
    trakt_rating: "6.71963",
    trakt_id: "430349",
    imdb_id: "tt9777644",
    tmdb_id: "581859",
    url: "https://trakt.tv/movies/da-5-bloods-2020",
    released: "2020-06-12",
    runtime: "156",
    genres: "drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-15T02:26:45Z": {
    type: "movie",
    title: "Shirley",
    year: "2020",
    trakt_rating: "6.22801",
    trakt_id: "397339",
    imdb_id: "tt8430598",
    tmdb_id: "547017",
    url: "https://trakt.tv/movies/shirley-2020",
    released: "2020-06-05",
    runtime: "107",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-07T02:38:38Z": {
    type: "movie",
    title: "Banksy and the Rise of Outlaw Art",
    year: "2020",
    trakt_rating: "7.22785",
    trakt_id: "506198",
    imdb_id: "tt11393026",
    tmdb_id: "672699",
    url: "https://trakt.tv/movies/banksy-and-the-rise-of-outlaw-art-2020",
    released: "2020-02-28",
    runtime: "108",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-04T04:33:09Z": {
    type: "movie",
    title: "The Great Hack",
    year: "2019",
    trakt_rating: "7.21773",
    trakt_id: "415873",
    imdb_id: "tt4736550",
    tmdb_id: "566222",
    url: "https://trakt.tv/movies/the-great-hack-2019",
    released: "2019-07-24",
    runtime: "114",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-04T04:23:22Z": {
    type: "movie",
    title: "Harpoon",
    year: "2019",
    trakt_rating: "6.36272",
    trakt_id: "424873",
    imdb_id: "tt7831358",
    tmdb_id: "574284",
    url: "https://trakt.tv/movies/harpoon-2019",
    released: "2019-01-24",
    runtime: "82",
    genres: "thriller,comedy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-04T04:17:57Z": {
    type: "movie",
    title: "Ready or Not",
    year: "2019",
    trakt_rating: "7.18914",
    trakt_id: "417095",
    imdb_id: "tt7798634",
    tmdb_id: "567609",
    url: "https://trakt.tv/movies/ready-or-not-2019",
    released: "2019-08-21",
    runtime: "95",
    genres: "horror,thriller,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-04T04:04:02Z": {
    type: "movie",
    title: "Blood Quantum",
    year: "2019",
    trakt_rating: "5.97704",
    trakt_id: "438616",
    imdb_id: "tt7394674",
    tmdb_id: "592230",
    url: "https://trakt.tv/movies/blood-quantum-2019",
    released: "2020-04-28",
    runtime: "98",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-04T04:00:30Z": {
    type: "movie",
    title: "The Rental",
    year: "2020",
    trakt_rating: "5.87123",
    trakt_id: "435214",
    imdb_id: "tt10003008",
    tmdb_id: "587496",
    url: "https://trakt.tv/movies/the-rental-2020",
    released: "2020-07-24",
    runtime: "88",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-04T03:52:31Z": {
    type: "movie",
    title: "The Nest",
    year: "2020",
    trakt_rating: "6.1623",
    trakt_id: "395696",
    imdb_id: "tt8338762",
    tmdb_id: "545237",
    url: "https://trakt.tv/movies/the-nest-2020",
    released: "2020-09-18",
    runtime: "107",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-12-04T03:52:21Z": {
    type: "movie",
    title: "The Wolf of Snow Hollow",
    year: "2020",
    trakt_rating: "6.23256",
    trakt_id: "487247",
    imdb_id: "tt11140488",
    tmdb_id: "652004",
    url: "https://trakt.tv/movies/the-wolf-of-snow-hollow-2020",
    released: "2020-10-09",
    runtime: "84",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-11-28T03:18:17Z": {
    type: "movie",
    title: "Tenet",
    year: "2020",
    trakt_rating: "7.31379",
    trakt_id: "427989",
    imdb_id: "tt6723592",
    tmdb_id: "577922",
    url: "https://trakt.tv/movies/tenet-2020",
    released: "2020-09-03",
    runtime: "150",
    genres: "action,thriller,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-11-22T16:15:12Z": {
    type: "movie",
    title: "The Gold Rush",
    year: "1925",
    trakt_rating: "7.93834",
    trakt_id: "798",
    imdb_id: "tt0015864",
    tmdb_id: "962",
    url: "https://trakt.tv/movies/the-gold-rush-1925",
    released: "1925-08-15",
    runtime: "95",
    genres: "comedy,drama,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-11-22T16:12:37Z": {
    type: "movie",
    title: "City Lights",
    year: "1931",
    trakt_rating: "8.20562",
    trakt_id: "744",
    imdb_id: "tt0021749",
    tmdb_id: "901",
    url: "https://trakt.tv/movies/city-lights-1931",
    released: "1931-03-07",
    runtime: "87",
    genres: "comedy,romance,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-11-22T15:38:04Z": {
    type: "movie",
    title: "The Dark and the Wicked",
    year: "2020",
    trakt_rating: "6.25352",
    trakt_id: "525608",
    imdb_id: "tt10229558",
    tmdb_id: "680593",
    url: "https://trakt.tv/movies/the-dark-and-the-wicked-2020",
    released: "2020-11-06",
    runtime: "95",
    genres: "horror,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-11-20T03:00:20Z": {
    type: "movie",
    title: "The Great Dictator",
    year: "1940",
    trakt_rating: "8.28341",
    trakt_id: "757",
    imdb_id: "tt0032553",
    tmdb_id: "914",
    url: "https://trakt.tv/movies/the-great-dictator-1940",
    released: "1940-10-23",
    runtime: "125",
    genres: "comedy,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-11-07T02:45:28Z": {
    type: "movie",
    title: "House",
    year: "1977",
    trakt_rating: "7.45918",
    trakt_id: "15494",
    imdb_id: "tt0076162",
    tmdb_id: "25623",
    url: "https://trakt.tv/movies/house-1977",
    released: "1977-08-26",
    runtime: "88",
    genres: "comedy,fantasy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-10-29T01:10:47Z": {
    type: "movie",
    title: "Roald Dahl's The Witches",
    year: "2020",
    trakt_rating: "6.20237",
    trakt_id: "382997",
    imdb_id: "tt0805647",
    tmdb_id: "531219",
    url: "https://trakt.tv/movies/roald-dahl-s-the-witches-2020",
    released: "2020-10-22",
    runtime: "106",
    genres: "fantasy,comedy,adventure,family,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-10-29T01:09:37Z": {
    type: "movie",
    title:
      "Borat: Cultural Learnings of America for Make Benefit Glorious Nation of Kazakhstan",
    year: "2006",
    trakt_rating: "7.02049",
    trakt_id: "389",
    imdb_id: "tt0443453",
    tmdb_id: "496",
    url: "https://trakt.tv/movies/borat-cultural-learnings-of-america-for-make-benefit-glorious-nation-of-kazakhstan-2006",
    released: "2006-11-02",
    runtime: "84",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-10-29T01:09:28Z": {
    type: "movie",
    title: "Borat Subsequent Moviefilm",
    year: "2020",
    trakt_rating: "6.6915",
    trakt_id: "581795",
    imdb_id: "tt13143964",
    tmdb_id: "740985",
    url: "https://trakt.tv/movies/borat-subsequent-moviefilm-2020",
    released: "2020-10-23",
    runtime: "96",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-10-28T16:14:06Z": {
    type: "movie",
    title: "Lady and the Tramp",
    year: "1955",
    trakt_rating: "7.44265",
    trakt_id: "5539",
    imdb_id: "tt0048280",
    tmdb_id: "10340",
    url: "https://trakt.tv/movies/lady-and-the-tramp-1955",
    released: "1955-06-22",
    runtime: "76",
    genres: "animation,family,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-10-28T12:38:35Z": {
    type: "movie",
    title: "The Willoughbys",
    year: "2020",
    trakt_rating: "6.94437",
    trakt_id: "410583",
    imdb_id: "tt5206260",
    tmdb_id: "560044",
    url: "https://trakt.tv/movies/the-willoughbys-2020",
    released: "2020-04-22",
    runtime: "92",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-10-23T16:14:27Z": {
    type: "movie",
    title: "Extra Ordinary",
    year: "2019",
    trakt_rating: "6.81544",
    trakt_id: "389609",
    imdb_id: "tt8233874",
    tmdb_id: "538227",
    url: "https://trakt.tv/movies/extra-ordinary-2019",
    released: "2019-09-13",
    runtime: "94",
    genres: "fantasy,comedy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-10-20T19:49:47Z": {
    type: "movie",
    title: "Revenge of the Pink Panther",
    year: "1978",
    trakt_rating: "7.19927",
    trakt_id: "3476",
    imdb_id: "tt0078163",
    tmdb_id: "6081",
    url: "https://trakt.tv/movies/revenge-of-the-pink-panther-1978",
    released: "1978-07-19",
    runtime: "99",
    genres: "comedy,crime,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-10-12T20:55:49Z": {
    type: "movie",
    title: "Free Birds",
    year: "2013",
    trakt_rating: "6.5784",
    trakt_id: "111430",
    imdb_id: "tt1621039",
    tmdb_id: "175574",
    url: "https://trakt.tv/movies/free-birds-2013",
    released: "2013-11-01",
    runtime: "91",
    genres: "animation,comedy,family,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-10-07T02:45:59Z": {
    type: "movie",
    title: "The Lie",
    year: "2018",
    trakt_rating: "6.17115",
    trakt_id: "390741",
    imdb_id: "tt7945440",
    tmdb_id: "539649",
    url: "https://trakt.tv/movies/the-lie-2018",
    released: "2020-10-06",
    runtime: "95",
    genres: "drama,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-10-02T23:21:35Z": {
    type: "movie",
    title: "Mr. Peabody & Sherman",
    year: "2014",
    trakt_rating: "7.18628",
    trakt_id: "61874",
    imdb_id: "tt0864835",
    tmdb_id: "82703",
    url: "https://trakt.tv/movies/mr-peabody-sherman-2014",
    released: "2014-03-07",
    runtime: "92",
    genres: "adventure,animation,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-10-01T22:37:47Z": {
    type: "movie",
    title: "Possessor Uncut",
    year: "2020",
    trakt_rating: "6.40502",
    trakt_id: "282301",
    imdb_id: "tt5918982",
    tmdb_id: "435615",
    url: "https://trakt.tv/movies/possessor-uncut-2020",
    released: "2020-10-02",
    runtime: "104",
    genres: "thriller,science-fiction,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-10-01T22:27:07Z": {
    type: "movie",
    title: "Sno Babies",
    year: "2020",
    trakt_rating: "6.0",
    trakt_id: "571171",
    imdb_id: "tt4755910",
    tmdb_id: "728889",
    url: "https://trakt.tv/movies/sno-babies-2020",
    released: "2020-09-28",
    runtime: "109",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-09-16T14:55:18Z": {
    type: "movie",
    title: "The Man Who Knew Too Much",
    year: "1956",
    trakt_rating: "7.37925",
    trakt_id: "453",
    imdb_id: "tt0049470",
    tmdb_id: "574",
    url: "https://trakt.tv/movies/the-man-who-knew-too-much-1956",
    released: "1956-05-16",
    runtime: "120",
    genres: "mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-09-16T14:50:56Z": {
    type: "movie",
    title: "The Wolf Man",
    year: "1941",
    trakt_rating: "7.14991",
    trakt_id: "8090",
    imdb_id: "tt0034398",
    tmdb_id: "13666",
    url: "https://trakt.tv/movies/the-wolf-man-1941",
    released: "1941-12-12",
    runtime: "70",
    genres: "drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-09-11T21:38:53Z": {
    type: "movie",
    title: "Madagascar: Escape 2 Africa",
    year: "2008",
    trakt_rating: "6.98541",
    trakt_id: "5705",
    imdb_id: "tt0479952",
    tmdb_id: "10527",
    url: "https://trakt.tv/movies/madagascar-escape-2-africa-2008",
    released: "2008-11-06",
    runtime: "89",
    genres: "animation,family,comedy,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-09-07T23:58:45Z": {
    type: "movie",
    title: "The Croods",
    year: "2013",
    trakt_rating: "7.39635",
    trakt_id: "34505",
    imdb_id: "tt0481499",
    tmdb_id: "49519",
    url: "https://trakt.tv/movies/the-croods-2013",
    released: "2013-03-22",
    runtime: "98",
    genres: "animation,comedy,family,adventure,fantasy,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-09-07T22:06:01Z": {
    type: "movie",
    title: "All Dogs Go to Heaven",
    year: "1989",
    trakt_rating: "7.20099",
    trakt_id: "6580",
    imdb_id: "tt0096787",
    tmdb_id: "11497",
    url: "https://trakt.tv/movies/all-dogs-go-to-heaven-1989",
    released: "1989-11-17",
    runtime: "89",
    genres: "animation,family,comedy,drama,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-09-07T22:05:55Z": {
    type: "movie",
    title: "Chicken Little",
    year: "2005",
    trakt_rating: "6.26355",
    trakt_id: "5209",
    imdb_id: "tt0371606",
    tmdb_id: "9982",
    url: "https://trakt.tv/movies/chicken-little-2005",
    released: "2005-11-04",
    runtime: "81",
    genres: "animation,comedy,family,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-09-07T12:25:07Z": {
    type: "movie",
    title: "I'm Thinking of Ending Things",
    year: "2020",
    trakt_rating: "6.42007",
    trakt_id: "348052",
    imdb_id: "tt7939766",
    tmdb_id: "500840",
    url: "https://trakt.tv/movies/i-m-thinking-of-ending-things-2020",
    released: "2020-08-28",
    runtime: "135",
    genres: "drama,mystery,thriller,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-08-27T03:40:51Z": {
    type: "movie",
    title: "The Nightingale",
    year: "2018",
    trakt_rating: "7.33756",
    trakt_id: "243386",
    imdb_id: "tt4068576",
    tmdb_id: "400090",
    url: "https://trakt.tv/movies/the-nightingale-2018",
    released: "2019-08-29",
    runtime: "136",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-08-25T22:20:38Z": {
    type: "movie",
    title: "Peninsula",
    year: "2020",
    trakt_rating: "6.09351",
    trakt_id: "455571",
    imdb_id: "tt8850222",
    tmdb_id: "581392",
    url: "https://trakt.tv/movies/peninsula-2020",
    released: "2020-07-15",
    runtime: "115",
    genres: "action,horror,thriller,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-07-22T18:56:31Z": {
    type: "movie",
    title: "Pirates of the Caribbean: On Stranger Tides",
    year: "2011",
    trakt_rating: "7.06466",
    trakt_id: "1244",
    imdb_id: "tt1298650",
    tmdb_id: "1865",
    url: "https://trakt.tv/movies/pirates-of-the-caribbean-on-stranger-tides-2011",
    released: "2011-05-20",
    runtime: "137",
    genres: "action,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-07-22T18:56:23Z": {
    type: "movie",
    title: "Pirates of the Caribbean: Dead Man's Chest",
    year: "2006",
    trakt_rating: "7.6264",
    trakt_id: "31",
    imdb_id: "tt0383574",
    tmdb_id: "58",
    url: "https://trakt.tv/movies/pirates-of-the-caribbean-dead-man-s-chest-2006",
    released: "2006-07-07",
    runtime: "151",
    genres: "action,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-07-22T18:56:18Z": {
    type: "movie",
    title: "Pirates of the Caribbean: At World's End",
    year: "2007",
    trakt_rating: "7.46434",
    trakt_id: "239",
    imdb_id: "tt0449088",
    tmdb_id: "285",
    url: "https://trakt.tv/movies/pirates-of-the-caribbean-at-world-s-end-2007",
    released: "2007-05-19",
    runtime: "169",
    genres: "action,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-07-22T18:54:34Z": {
    type: "movie",
    title: "Pirates of the Caribbean: Dead Men Tell No Tales",
    year: "2017",
    trakt_rating: "7.11787",
    trakt_id: "106667",
    imdb_id: "tt1790809",
    tmdb_id: "166426",
    url: "https://trakt.tv/movies/pirates-of-the-caribbean-dead-men-tell-no-tales-2017",
    released: "2017-05-26",
    runtime: "128",
    genres: "action,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-07-21T20:49:38Z": {
    type: "movie",
    title: "Pirates of the Caribbean: The Curse of the Black Pearl",
    year: "2003",
    trakt_rating: "8.09562",
    trakt_id: "18",
    imdb_id: "tt0325980",
    tmdb_id: "22",
    url: "https://trakt.tv/movies/pirates-of-the-caribbean-the-curse-of-the-black-pearl-2003",
    released: "2003-07-09",
    runtime: "143",
    genres: "action,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-07-11T13:10:08Z": {
    type: "movie",
    title: "Greyhound",
    year: "2020",
    trakt_rating: "7.40222",
    trakt_id: "364601",
    imdb_id: "tt6048922",
    tmdb_id: "516486",
    url: "https://trakt.tv/movies/greyhound-2020",
    released: "2020-07-10",
    runtime: "91",
    genres: "war,action,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-07-07T21:04:18Z": {
    type: "movie",
    title: "Mary Poppins Returns",
    year: "2018",
    trakt_rating: "7.05371",
    trakt_id: "243783",
    imdb_id: "tt5028340",
    tmdb_id: "400650",
    url: "https://trakt.tv/movies/mary-poppins-returns-2018",
    released: "2018-12-19",
    runtime: "131",
    genres: "fantasy,family,comedy,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-06-22T01:25:19Z": {
    type: "movie",
    title: "The Bugs Bunny/Road Runner Movie",
    year: "1979",
    trakt_rating: "7.55602",
    trakt_id: "12279",
    imdb_id: "tt0078915",
    tmdb_id: "20007",
    url: "https://trakt.tv/movies/the-bugs-bunny-road-runner-movie-1979",
    released: "1979-09-30",
    runtime: "98",
    genres: "animation,family,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-06-05T23:29:18Z": {
    type: "movie",
    title: "Scrat: Spaced Out",
    year: "2016",
    trakt_rating: "7.09959",
    trakt_id: "269058",
    imdb_id: "tt6151810",
    tmdb_id: "421725",
    url: "https://trakt.tv/movies/scrat-spaced-out-2016",
    released: "2016-10-11",
    runtime: "15",
    genres: "comedy,animation,science-fiction,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-06-03T15:07:24Z": {
    type: "movie",
    title: "Tigers Are Not Afraid",
    year: "2017",
    trakt_rating: "7.04558",
    trakt_id: "321861",
    imdb_id: "tt4823434",
    tmdb_id: "349067",
    url: "https://trakt.tv/movies/tigers-are-not-afraid-2017",
    released: "2017-11-02",
    runtime: "83",
    genres: "horror,fantasy,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-05-30T08:32:56Z": {
    type: "movie",
    title: "For We Are Many",
    year: "2019",
    trakt_rating: "4.53333",
    trakt_id: "457830",
    imdb_id: "tt7445304",
    tmdb_id: "614206",
    url: "https://trakt.tv/movies/for-we-are-many-2019",
    released: "2019-08-26",
    runtime: "85",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-05-26T21:46:51Z": {
    type: "movie",
    title: "Ice Age: The Meltdown",
    year: "2006",
    trakt_rating: "7.1334",
    trakt_id: "790",
    imdb_id: "tt0438097",
    tmdb_id: "950",
    url: "https://trakt.tv/movies/ice-age-the-meltdown-2006",
    released: "2006-03-31",
    runtime: "91",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-05-23T02:34:06Z": {
    type: "movie",
    title: "I'm Gonna Git You Sucka",
    year: "1988",
    trakt_rating: "7.0977",
    trakt_id: "10407",
    imdb_id: "tt0095348",
    tmdb_id: "17006",
    url: "https://trakt.tv/movies/i-m-gonna-git-you-sucka-1988",
    released: "1988-12-14",
    runtime: "88",
    genres: "comedy,action,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-05-16T06:44:48Z": {
    type: "movie",
    title: "Castle in the Ground",
    year: "2021",
    trakt_rating: "6.07576",
    trakt_id: "438677",
    imdb_id: "tt3689484",
    tmdb_id: "592662",
    url: "https://trakt.tv/movies/castle-in-the-ground-2021",
    released: "2019-09-05",
    runtime: "105",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-05-16T06:43:48Z": {
    type: "movie",
    title: "Scoob!",
    year: "2020",
    trakt_rating: "6.83013",
    trakt_id: "226209",
    imdb_id: "tt3152592",
    tmdb_id: "385103",
    url: "https://trakt.tv/movies/scoob-2020",
    released: "2020-05-15",
    runtime: "93",
    genres: "family,animation,comedy,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-05-09T16:50:50Z": {
    type: "movie",
    title: "Gretel & Hansel",
    year: "2020",
    trakt_rating: "5.79813",
    trakt_id: "393114",
    imdb_id: "tt9086228",
    tmdb_id: "542224",
    url: "https://trakt.tv/movies/gretel-hansel-2020",
    released: "2020-01-31",
    runtime: "87",
    genres: "horror,fantasy,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-05-09T00:32:40Z": {
    type: "movie",
    title: "The Jesus Rolls",
    year: "2019",
    trakt_rating: "4.91333",
    trakt_id: "273361",
    imdb_id: "tt5974030",
    tmdb_id: "429416",
    url: "https://trakt.tv/movies/the-jesus-rolls-2019",
    released: "2020-03-06",
    runtime: "85",
    genres: "comedy,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-05-08T15:56:03Z": {
    type: "movie",
    title: "Porno",
    year: "2019",
    trakt_rating: "5.33214",
    trakt_id: "426447",
    imdb_id: "tt8485772",
    tmdb_id: "576004",
    url: "https://trakt.tv/movies/porno-2019",
    released: "2020-05-08",
    runtime: "98",
    genres: "comedy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-30T05:37:12Z": {
    type: "movie",
    title: "The Rhythm Section",
    year: "2020",
    trakt_rating: "6.28101",
    trakt_id: "313813",
    imdb_id: "tt7134096",
    tmdb_id: "466622",
    url: "https://trakt.tv/movies/the-rhythm-section-2020",
    released: "2020-01-31",
    runtime: "109",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-30T02:26:08Z": {
    type: "movie",
    title: "Open Water 2: Adrift",
    year: "2006",
    trakt_rating: "5.80505",
    trakt_id: "4868",
    imdb_id: "tt0470055",
    tmdb_id: "9592",
    url: "https://trakt.tv/movies/open-water-2-adrift-2006",
    released: "2006-08-10",
    runtime: "94",
    genres: "drama,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-27T05:29:57Z": {
    type: "movie",
    title: "Cast a Deadly Spell",
    year: "1991",
    trakt_rating: "6.66887",
    trakt_id: "37120",
    imdb_id: "tt0101550",
    tmdb_id: "52660",
    url: "https://trakt.tv/movies/cast-a-deadly-spell-1991",
    released: "1991-09-07",
    runtime: "96",
    genres: "comedy,fantasy,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-25T20:36:48Z": {
    type: "movie",
    title: "Monsters University",
    year: "2013",
    trakt_rating: "7.46732",
    trakt_id: "44679",
    imdb_id: "tt1453405",
    tmdb_id: "62211",
    url: "https://trakt.tv/movies/monsters-university-2013",
    released: "2013-06-21",
    runtime: "104",
    genres: "animation,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-25T20:23:55Z": {
    type: "movie",
    title: "Ratatouille",
    year: "2007",
    trakt_rating: "7.96391",
    trakt_id: "1416",
    imdb_id: "tt0382932",
    tmdb_id: "2062",
    url: "https://trakt.tv/movies/ratatouille-2007",
    released: "2007-06-29",
    runtime: "111",
    genres: "animation,fantasy,family,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-24T18:17:50Z": {
    type: "movie",
    title: "A Bug's Life",
    year: "1998",
    trakt_rating: "7.36278",
    trakt_id: "4771",
    imdb_id: "tt0120623",
    tmdb_id: "9487",
    url: "https://trakt.tv/movies/a-bug-s-life-1998",
    released: "1998-11-25",
    runtime: "95",
    genres: "animation,family,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-24T02:13:08Z": {
    type: "movie",
    title: "Swallow",
    year: "2020",
    trakt_rating: "6.67264",
    trakt_id: "433827",
    imdb_id: "tt8372298",
    tmdb_id: "586461",
    url: "https://trakt.tv/movies/swallow-2020",
    released: "2020-03-06",
    runtime: "96",
    genres: "thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-21T00:24:37Z": {
    type: "movie",
    title: "Gorky Park",
    year: "1983",
    trakt_rating: "7.08532",
    trakt_id: "4112",
    imdb_id: "tt0085615",
    tmdb_id: "8289",
    url: "https://trakt.tv/movies/gorky-park-1983",
    released: "1983-12-15",
    runtime: "128",
    genres: "drama,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-12T10:53:49Z": {
    type: "movie",
    title: "Turbo Kid",
    year: "2015",
    trakt_rating: "6.83882",
    trakt_id: "195462",
    imdb_id: "tt3672742",
    tmdb_id: "310135",
    url: "https://trakt.tv/movies/turbo-kid-2015",
    released: "2015-08-14",
    runtime: "95",
    genres: "action,adventure,comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-12T10:50:49Z": {
    type: "movie",
    title: "The Game",
    year: "1997",
    trakt_rating: "7.9573",
    trakt_id: "1789",
    imdb_id: "tt0119174",
    tmdb_id: "2649",
    url: "https://trakt.tv/movies/the-game-1997",
    released: "1997-09-12",
    runtime: "129",
    genres: "drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-12T05:19:13Z": {
    type: "movie",
    title: "The Inventor: Out for Blood in Silicon Valley",
    year: "2019",
    trakt_rating: "7.33633",
    trakt_id: "415884",
    imdb_id: "tt8488126",
    tmdb_id: "566228",
    url: "https://trakt.tv/movies/the-inventor-out-for-blood-in-silicon-valley-2019",
    released: "2019-01-24",
    runtime: "119",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-12T01:51:01Z": {
    type: "movie",
    title: "Like a Boss",
    year: "2020",
    trakt_rating: "6.10473",
    trakt_id: "374426",
    imdb_id: "tt7545266",
    tmdb_id: "526019",
    url: "https://trakt.tv/movies/like-a-boss-2020",
    released: "2020-01-07",
    runtime: "83",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-07T06:14:45Z": {
    type: "movie",
    title: "Nosferatu the Vampyre",
    year: "1979",
    trakt_rating: "7.19581",
    trakt_id: "3609",
    imdb_id: "tt0079641",
    tmdb_id: "6404",
    url: "https://trakt.tv/movies/nosferatu-the-vampyre-1979",
    released: "1979-04-12",
    runtime: "107",
    genres: "drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-06T23:26:12Z": {
    type: "movie",
    title: "Trolls World Tour",
    year: "2020",
    trakt_rating: "7.27576",
    trakt_id: "293547",
    imdb_id: "tt6587640",
    tmdb_id: "446893",
    url: "https://trakt.tv/movies/trolls-world-tour-2020",
    released: "2020-04-10",
    runtime: "90",
    genres: "animation,family,comedy,fantasy,adventure,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-06T01:39:23Z": {
    type: "movie",
    title: "Seven Stages to Achieve Eternal Bliss",
    year: "2020",
    trakt_rating: "6.33628",
    trakt_id: "349291",
    imdb_id: "tt4270452",
    tmdb_id: "502104",
    url: "https://trakt.tv/movies/seven-stages-to-achieve-eternal-bliss-2020",
    released: "2020-03-06",
    runtime: "93",
    genres: "comedy,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-06T01:37:03Z": {
    type: "movie",
    title: "The Hudsucker Proxy",
    year: "1994",
    trakt_rating: "7.3771",
    trakt_id: "6949",
    imdb_id: "tt0110074",
    tmdb_id: "11934",
    url: "https://trakt.tv/movies/the-hudsucker-proxy-1994",
    released: "1994-03-11",
    runtime: "111",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-05T09:45:19Z": {
    type: "movie",
    title: "Manou the Swift",
    year: "2019",
    trakt_rating: "6.58879",
    trakt_id: "371019",
    imdb_id: "tt4153828",
    tmdb_id: "522887",
    url: "https://trakt.tv/movies/manou-the-swift-2019",
    released: "2019-02-28",
    runtime: "88",
    genres: "animation,adventure,family,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-04-03T02:22:47Z": {
    type: "movie",
    title: "Saturday Night Fever",
    year: "1977",
    trakt_rating: "6.84099",
    trakt_id: "6147",
    imdb_id: "tt0076666",
    tmdb_id: "11009",
    url: "https://trakt.tv/movies/saturday-night-fever-1977",
    released: "1977-12-16",
    runtime: "119",
    genres: "drama,music,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-31T09:08:54Z": {
    type: "movie",
    title: "Sonic the Hedgehog",
    year: "2020",
    trakt_rating: "7.0717",
    trakt_id: "301015",
    imdb_id: "tt3794354",
    tmdb_id: "454626",
    url: "https://trakt.tv/movies/sonic-the-hedgehog-2020",
    released: "2020-02-14",
    runtime: "99",
    genres: "action,science-fiction,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-29T01:22:43Z": {
    type: "movie",
    title: "StarDog and TurboCat",
    year: "2019",
    trakt_rating: "6.21429",
    trakt_id: "364008",
    imdb_id: "tt7531096",
    tmdb_id: "515789",
    url: "https://trakt.tv/movies/stardog-and-turbocat-2019",
    released: "2019-12-06",
    runtime: "88",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-28T02:06:25Z": {
    type: "movie",
    title: "M",
    year: "1931",
    trakt_rating: "8.06181",
    trakt_id: "675",
    imdb_id: "tt0022100",
    tmdb_id: "832",
    url: "https://trakt.tv/movies/m-1931",
    released: "1931-05-11",
    runtime: "109",
    genres: "action,crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-28T00:02:24Z": {
    type: "movie",
    title: "The Call of the Wild",
    year: "2020",
    trakt_rating: "7.39524",
    trakt_id: "329002",
    imdb_id: "tt7504726",
    tmdb_id: "481848",
    url: "https://trakt.tv/movies/the-call-of-the-wild-2020",
    released: "2020-02-21",
    runtime: "100",
    genres: "adventure,drama,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-25T12:01:05Z": {
    type: "movie",
    title: "Dolittle",
    year: "2020",
    trakt_rating: "6.6881",
    trakt_id: "294821",
    imdb_id: "tt6673612",
    tmdb_id: "448119",
    url: "https://trakt.tv/movies/dolittle-2020",
    released: "2020-01-17",
    runtime: "101",
    genres: "fantasy,adventure,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-21T23:15:39Z": {
    type: "movie",
    title:
      "Birds of Prey (and the Fantabulous Emancipation of One Harley Quinn)",
    year: "2020",
    trakt_rating: "6.6471",
    trakt_id: "342639",
    imdb_id: "tt7713068",
    tmdb_id: "495764",
    url: "https://trakt.tv/movies/birds-of-prey-and-the-fantabulous-emancipation-of-one-harley-quinn-2020",
    released: "2020-02-07",
    runtime: "109",
    genres: "action,crime,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-21T04:38:35Z": {
    type: "movie",
    title: "Onward",
    year: "2020",
    trakt_rating: "7.69629",
    trakt_id: "355892",
    imdb_id: "tt7146812",
    tmdb_id: "508439",
    url: "https://trakt.tv/movies/onward-2020",
    released: "2020-02-29",
    runtime: "103",
    genres: "animation,family,adventure,comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-20T06:35:47Z": {
    type: "movie",
    title: "The Invisible Man",
    year: "2020",
    trakt_rating: "7.20381",
    trakt_id: "428130",
    imdb_id: "tt1051906",
    tmdb_id: "570670",
    url: "https://trakt.tv/movies/the-invisible-man-2020",
    released: "2020-02-28",
    runtime: "124",
    genres: "horror,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-18T01:42:40Z": {
    type: "movie",
    title: "Luz",
    year: "2019",
    trakt_rating: "6.06757",
    trakt_id: "345236",
    imdb_id: "tt7858616",
    tmdb_id: "498062",
    url: "https://trakt.tv/movies/luz-2019",
    released: "2019-03-21",
    runtime: "70",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-16T18:34:01Z": {
    type: "movie",
    title: "Cats",
    year: "2019",
    trakt_rating: "3.98174",
    trakt_id: "388373",
    imdb_id: "tt5697572",
    tmdb_id: "536869",
    url: "https://trakt.tv/movies/cats-2019",
    released: "2019-12-20",
    runtime: "110",
    genres: "comedy,drama,fantasy,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-16T02:26:18Z": {
    type: "movie",
    title: "Avatar",
    year: "2009",
    trakt_rating: "7.8552",
    trakt_id: "12269",
    imdb_id: "tt0499549",
    tmdb_id: "19995",
    url: "https://trakt.tv/movies/avatar-2009",
    released: "2009-12-18",
    runtime: "162",
    genres: "action,adventure,fantasy,science-fiction,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-14T12:39:06Z": {
    type: "movie",
    title: "Star Wars: The Rise of Skywalker",
    year: "2019",
    trakt_rating: "6.75098",
    trakt_id: "114335",
    imdb_id: "tt2527338",
    tmdb_id: "181812",
    url: "https://trakt.tv/movies/star-wars-the-rise-of-skywalker-2019",
    released: "2019-12-20",
    runtime: "142",
    genres: "action,science-fiction,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-12T01:09:20Z": {
    type: "movie",
    title: "1917",
    year: "2019",
    trakt_rating: "8.14534",
    trakt_id: "382862",
    imdb_id: "tt8579674",
    tmdb_id: "530915",
    url: "https://trakt.tv/movies/1917-2019",
    released: "2019-12-25",
    runtime: "119",
    genres: "war,drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-10T15:51:12Z": {
    type: "movie",
    title: "The Grudge",
    year: "2019",
    trakt_rating: "5.41402",
    trakt_id: "312247",
    imdb_id: "tt3612126",
    tmdb_id: "465086",
    url: "https://trakt.tv/movies/the-grudge-2019",
    released: "2020-01-03",
    runtime: "93",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-08T16:23:36Z": {
    type: "movie",
    title: "Come to Daddy",
    year: "2019",
    trakt_rating: "6.2951",
    trakt_id: "434139",
    imdb_id: "tt8816194",
    tmdb_id: "586592",
    url: "https://trakt.tv/movies/come-to-daddy-2019",
    released: "2020-02-07",
    runtime: "96",
    genres: "comedy,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-05T04:42:15Z": {
    type: "movie",
    title: "Bliss",
    year: "2019",
    trakt_rating: "6.17015",
    trakt_id: "434165",
    imdb_id: "tt9026524",
    tmdb_id: "586586",
    url: "https://trakt.tv/movies/bliss-2019",
    released: "2019-09-27",
    runtime: "80",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-03-01T14:25:48Z": {
    type: "movie",
    title: "Jumanji: The Next Level",
    year: "2019",
    trakt_rating: "7.10599",
    trakt_id: "360095",
    imdb_id: "tt7975244",
    tmdb_id: "512200",
    url: "https://trakt.tv/movies/jumanji-the-next-level-2019",
    released: "2019-12-13",
    runtime: "123",
    genres: "adventure,comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-02-28T03:21:22Z": {
    type: "movie",
    title: "Spies in Disguise",
    year: "2019",
    trakt_rating: "7.38312",
    trakt_id: "277572",
    imdb_id: "tt5814534",
    tmdb_id: "431693",
    url: "https://trakt.tv/movies/spies-in-disguise-2019",
    released: "2019-12-25",
    runtime: "102",
    genres: "animation,action,adventure,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-02-28T02:04:28Z": {
    type: "movie",
    title: "Dark Waters",
    year: "2019",
    trakt_rating: "7.75232",
    trakt_id: "401889",
    imdb_id: "tt9071322",
    tmdb_id: "552178",
    url: "https://trakt.tv/movies/dark-waters-2019",
    released: "2019-11-22",
    runtime: "127",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-02-25T13:14:56Z": {
    type: "movie",
    title: "Bombshell",
    year: "2019",
    trakt_rating: "7.05121",
    trakt_id: "373904",
    imdb_id: "tt6394270",
    tmdb_id: "525661",
    url: "https://trakt.tv/movies/bombshell-2019",
    released: "2019-12-13",
    runtime: "109",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-02-21T22:57:46Z": {
    type: "movie",
    title: "Cats",
    year: "1998",
    trakt_rating: "7.12438",
    trakt_id: "140685",
    imdb_id: "tt0173714",
    tmdb_id: "26598",
    url: "https://trakt.tv/movies/cats-1998",
    released: "1998-10-05",
    runtime: "116",
    genres: "music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-02-19T02:28:08Z": {
    type: "movie",
    title: "Villains",
    year: "2019",
    trakt_rating: "6.46399",
    trakt_id: "357461",
    imdb_id: "tt6378710",
    tmdb_id: "510388",
    url: "https://trakt.tv/movies/villains-2019",
    released: "2019-09-20",
    runtime: "90",
    genres: "comedy,horror,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-02-14T14:13:50Z": {
    type: "movie",
    title: "Daniel Isn't Real",
    year: "2019",
    trakt_rating: "6.37695",
    trakt_id: "389082",
    imdb_id: "tt8745960",
    tmdb_id: "537788",
    url: "https://trakt.tv/movies/daniel-isn-t-real-2019",
    released: "2019-12-06",
    runtime: "100",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-02-14T03:00:09Z": {
    type: "movie",
    title: "Jumanji: Welcome to the Jungle",
    year: "2017",
    trakt_rating: "7.3424",
    trakt_id: "222254",
    imdb_id: "tt2283362",
    tmdb_id: "353486",
    url: "https://trakt.tv/movies/jumanji-welcome-to-the-jungle-2017",
    released: "2017-12-20",
    runtime: "119",
    genres: "action,adventure,comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-02-14T02:49:20Z": {
    type: "movie",
    title: "Frozen II",
    year: "2019",
    trakt_rating: "7.33835",
    trakt_id: "211394",
    imdb_id: "tt4520988",
    tmdb_id: "330457",
    url: "https://trakt.tv/movies/frozen-ii-2019",
    released: "2019-11-22",
    runtime: "103",
    genres: "animation,family,adventure,comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-02-05T00:27:57Z": {
    type: "movie",
    title: "Motherless Brooklyn",
    year: "2019",
    trakt_rating: "7.15843",
    trakt_id: "361154",
    imdb_id: "tt0385887",
    tmdb_id: "504562",
    url: "https://trakt.tv/movies/motherless-brooklyn-2019",
    released: "2019-11-01",
    runtime: "145",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-02-05T00:25:05Z": {
    type: "movie",
    title: "Jojo Rabbit",
    year: "2019",
    trakt_rating: "8.00523",
    trakt_id: "363514",
    imdb_id: "tt2584384",
    tmdb_id: "515001",
    url: "https://trakt.tv/movies/jojo-rabbit-2019",
    released: "2019-10-18",
    runtime: "108",
    genres: "comedy,war,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-02-04T12:54:00Z": {
    type: "movie",
    title: "A Beautiful Day in the Neighborhood",
    year: "2019",
    trakt_rating: "7.30616",
    trakt_id: "348923",
    imdb_id: "tt3224458",
    tmdb_id: "501907",
    url: "https://trakt.tv/movies/a-beautiful-day-in-the-neighborhood-2019",
    released: "2019-11-22",
    runtime: "109",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-29T01:01:59Z": {
    type: "movie",
    title: "Ford v Ferrari",
    year: "2019",
    trakt_rating: "8.20104",
    trakt_id: "324762",
    imdb_id: "tt1950186",
    tmdb_id: "359724",
    url: "https://trakt.tv/movies/ford-v-ferrari-2019",
    released: "2019-11-15",
    runtime: "153",
    genres: "drama,history,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-26T04:17:38Z": {
    type: "movie",
    title: "The Hidden Fortress",
    year: "1958",
    trakt_rating: "8.05893",
    trakt_id: "837",
    imdb_id: "tt0051808",
    tmdb_id: "1059",
    url: "https://trakt.tv/movies/the-hidden-fortress-1958",
    released: "1958-12-28",
    runtime: "139",
    genres: "action,adventure,comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-26T03:27:20Z": {
    type: "movie",
    title: "Arctic Dogs",
    year: "2019",
    trakt_rating: "6.66197",
    trakt_id: "298468",
    imdb_id: "tt4426464",
    tmdb_id: "416153",
    url: "https://trakt.tv/movies/arctic-dogs-2019",
    released: "2019-11-01",
    runtime: "93",
    genres: "animation,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-22T06:07:36Z": {
    type: "movie",
    title: "Doctor Sleep",
    year: "2019",
    trakt_rating: "7.5258",
    trakt_id: "353053",
    imdb_id: "tt5606664",
    tmdb_id: "501170",
    url: "https://trakt.tv/movies/doctor-sleep-2019",
    released: "2019-11-08",
    runtime: "152",
    genres: "horror,thriller,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-20T03:37:48Z": {
    type: "movie",
    title: "Braid",
    year: "2019",
    trakt_rating: "5.77778",
    trakt_id: "357569",
    imdb_id: "tt4638148",
    tmdb_id: "510284",
    url: "https://trakt.tv/movies/braid-2019",
    released: "2019-02-01",
    runtime: "85",
    genres: "thriller,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-18T08:23:53Z": {
    type: "movie",
    title: "Stop Making Sense",
    year: "1984",
    trakt_rating: "8.44989",
    trakt_id: "14488",
    imdb_id: "tt0088178",
    tmdb_id: "24128",
    url: "https://trakt.tv/movies/stop-making-sense-1984",
    released: "1984-11-16",
    runtime: "88",
    genres: "music,documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-17T22:58:21Z": {
    type: "movie",
    title: "Color Out of Space",
    year: "2019",
    trakt_rating: "6.31573",
    trakt_id: "398564",
    imdb_id: "tt5073642",
    tmdb_id: "548473",
    url: "https://trakt.tv/movies/color-out-of-space-2019",
    released: "2020-01-24",
    runtime: "111",
    genres: "science-fiction,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-16T19:08:20Z": {
    type: "movie",
    title: "Zog",
    year: "2018",
    trakt_rating: "7.56647",
    trakt_id: "421986",
    imdb_id: "tt9109620",
    tmdb_id: "570701",
    url: "https://trakt.tv/movies/zog-2018",
    released: "2020-08-24",
    runtime: "27",
    genres: "animation,family,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-15T02:53:25Z": {
    type: "movie",
    title: "Terminator: Dark Fate",
    year: "2019",
    trakt_rating: "6.90699",
    trakt_id: "187745",
    imdb_id: "tt6450804",
    tmdb_id: "290859",
    url: "https://trakt.tv/movies/terminator-dark-fate-2019",
    released: "2019-11-01",
    runtime: "128",
    genres: "science-fiction,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-12T03:45:28Z": {
    type: "movie",
    title: "Ted 2",
    year: "2015",
    trakt_rating: "6.64249",
    trakt_id: "132669",
    imdb_id: "tt2637276",
    tmdb_id: "214756",
    url: "https://trakt.tv/movies/ted-2-2015",
    released: "2015-06-26",
    runtime: "115",
    genres: "comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-08T08:06:24Z": {
    type: "movie",
    title: "Memory: The Origins of Alien",
    year: "2019",
    trakt_rating: "7.19643",
    trakt_id: "415266",
    imdb_id: "tt8974964",
    tmdb_id: "565591",
    url: "https://trakt.tv/movies/memory-the-origins-of-alien-2019",
    released: "2019-01-24",
    runtime: "95",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-08T05:13:10Z": {
    type: "movie",
    title: "West Side Story",
    year: "1961",
    trakt_rating: "7.44636",
    trakt_id: "1156",
    imdb_id: "tt0055614",
    tmdb_id: "1725",
    url: "https://trakt.tv/movies/west-side-story-1961",
    released: "1961-12-13",
    runtime: "151",
    genres: "crime,drama,romance,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-08T05:12:54Z": {
    type: "movie",
    title: "Hair",
    year: "1979",
    trakt_rating: "7.72347",
    trakt_id: "5828",
    imdb_id: "tt0079261",
    tmdb_id: "10654",
    url: "https://trakt.tv/movies/hair-1979",
    released: "1979-03-14",
    runtime: "121",
    genres: "drama,music,musical,comedy,romance,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-08T05:12:40Z": {
    type: "movie",
    title: "Grease",
    year: "1978",
    trakt_rating: "7.41404",
    trakt_id: "497",
    imdb_id: "tt0077631",
    tmdb_id: "621",
    url: "https://trakt.tv/movies/grease-1978",
    released: "1978-07-07",
    runtime: "110",
    genres: "romance,musical,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-03T12:03:45Z": {
    type: "movie",
    title: "Knives Out",
    year: "2019",
    trakt_rating: "8.03534",
    trakt_id: "396788",
    imdb_id: "tt8946378",
    tmdb_id: "546554",
    url: "https://trakt.tv/movies/knives-out-2019",
    released: "2019-11-27",
    runtime: "131",
    genres: "comedy,crime,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-01T09:06:52Z": {
    type: "movie",
    title: "Freaks",
    year: "1932",
    trakt_rating: "7.70779",
    trakt_id: "102",
    imdb_id: "tt0022913",
    tmdb_id: "136",
    url: "https://trakt.tv/movies/freaks-1932",
    released: "1932-02-12",
    runtime: "62",
    genres: "drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-01T08:15:45Z": {
    type: "movie",
    title: "The Secret of NIMH",
    year: "1982",
    trakt_rating: "7.72579",
    trakt_id: "6776",
    imdb_id: "tt0084649",
    tmdb_id: "11704",
    url: "https://trakt.tv/movies/the-secret-of-nimh-1982",
    released: "1982-07-02",
    runtime: "82",
    genres: "animation,family,fantasy,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2020-01-01T08:11:58Z": {
    type: "movie",
    title: "David Brent: Life on the Road",
    year: "2016",
    trakt_rating: "6.69771",
    trakt_id: "220257",
    imdb_id: "tt3137630",
    tmdb_id: "339792",
    url: "https://trakt.tv/movies/david-brent-life-on-the-road-2016",
    released: "2016-08-19",
    runtime: "96",
    genres: "comedy,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-12-25T03:49:53Z": {
    type: "movie",
    title: "The Addams Family",
    year: "2019",
    trakt_rating: "6.71018",
    trakt_id: "328196",
    imdb_id: "tt1620981",
    tmdb_id: "481084",
    url: "https://trakt.tv/movies/the-addams-family-2019",
    released: "2019-10-11",
    runtime: "86",
    genres: "animation,comedy,family,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-12-17T05:56:47Z": {
    type: "movie",
    title: "Joker",
    year: "2019",
    trakt_rating: "8.15653",
    trakt_id: "322988",
    imdb_id: "tt7286456",
    tmdb_id: "475557",
    url: "https://trakt.tv/movies/joker-2019",
    released: "2019-10-04",
    runtime: "122",
    genres: "crime,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-12-17T02:31:01Z": {
    type: "movie",
    title: "Over the Hedge",
    year: "2006",
    trakt_rating: "6.96368",
    trakt_id: "3942",
    imdb_id: "tt0327084",
    tmdb_id: "7518",
    url: "https://trakt.tv/movies/over-the-hedge-2006",
    released: "2006-05-19",
    runtime: "83",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-12-17T02:30:42Z": {
    type: "movie",
    title: "Monsters vs Aliens",
    year: "2009",
    trakt_rating: "6.71052",
    trakt_id: "9437",
    imdb_id: "tt0892782",
    tmdb_id: "15512",
    url: "https://trakt.tv/movies/monsters-vs-aliens-2009",
    released: "2009-03-26",
    runtime: "94",
    genres: "adventure,animation,science-fiction,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-12-17T01:46:17Z": {
    type: "movie",
    title: "Zombieland: Double Tap",
    year: "2019",
    trakt_rating: "7.07054",
    trakt_id: "219433",
    imdb_id: "tt1560220",
    tmdb_id: "338967",
    url: "https://trakt.tv/movies/zombieland-double-tap-2019",
    released: "2019-10-18",
    runtime: "99",
    genres: "horror,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-12-12T05:52:00Z": {
    type: "movie",
    title: "The Spiderwick Chronicles",
    year: "2008",
    trakt_rating: "6.92335",
    trakt_id: "4072",
    imdb_id: "tt0416236",
    tmdb_id: "8204",
    url: "https://trakt.tv/movies/the-spiderwick-chronicles-2008",
    released: "2008-02-14",
    runtime: "95",
    genres: "fantasy,adventure,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-12-12T05:51:35Z": {
    type: "movie",
    title: "Crumb",
    year: "1995",
    trakt_rating: "7.73273",
    trakt_id: "16173",
    imdb_id: "tt0109508",
    tmdb_id: "26564",
    url: "https://trakt.tv/movies/crumb-1995",
    released: "1995-04-28",
    runtime: "119",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-12-07T09:13:14Z": {
    type: "movie",
    title: "The World, the Flesh and the Devil",
    year: "1959",
    trakt_rating: "7.09859",
    trakt_id: "2863",
    imdb_id: "tt0053454",
    tmdb_id: "4928",
    url: "https://trakt.tv/movies/the-world-the-flesh-and-the-devil-1959",
    released: "1959-05-01",
    runtime: "95",
    genres: "drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-12-06T21:43:21Z": {
    type: "movie",
    title: "Home Alone 2: Lost in New York",
    year: "1992",
    trakt_rating: "7.31094",
    trakt_id: "615",
    imdb_id: "tt0104431",
    tmdb_id: "772",
    url: "https://trakt.tv/movies/home-alone-2-lost-in-new-york-1992",
    released: "1992-11-19",
    runtime: "120",
    genres: "adventure,comedy,family,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-12-06T01:44:10Z": {
    type: "movie",
    title: "Ad Astra",
    year: "2019",
    trakt_rating: "6.38711",
    trakt_id: "298164",
    imdb_id: "tt2935510",
    tmdb_id: "419704",
    url: "https://trakt.tv/movies/ad-astra-2019",
    released: "2019-09-20",
    runtime: "123",
    genres: "science-fiction,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-12-06T01:42:53Z": {
    type: "movie",
    title: "The Report",
    year: "2019",
    trakt_rating: "7.42837",
    trakt_id: "372779",
    imdb_id: "tt8236336",
    tmdb_id: "524348",
    url: "https://trakt.tv/movies/the-report-2019",
    released: "2019-11-15",
    runtime: "118",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-12-03T01:03:45Z": {
    type: "movie",
    title: "Once Upon a Time… in Hollywood",
    year: "2019",
    trakt_rating: "7.34338",
    trakt_id: "313464",
    imdb_id: "tt7131622",
    tmdb_id: "466272",
    url: "https://trakt.tv/movies/once-upon-a-time-in-hollywood-2019",
    released: "2019-07-26",
    runtime: "162",
    genres: "drama,comedy,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-11-28T05:16:30Z": {
    type: "movie",
    title: "The Irishman",
    year: "2019",
    trakt_rating: "7.63799",
    trakt_id: "242125",
    imdb_id: "tt1302006",
    tmdb_id: "398978",
    url: "https://trakt.tv/movies/the-irishman-2019",
    released: "2019-11-01",
    runtime: "209",
    genres: "crime,history,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-11-28T05:16:05Z": {
    type: "movie",
    title: "Hustlers",
    year: "2019",
    trakt_rating: "6.66426",
    trakt_id: "392042",
    imdb_id: "tt5503686",
    tmdb_id: "540901",
    url: "https://trakt.tv/movies/hustlers-2019",
    released: "2019-09-13",
    runtime: "110",
    genres: "comedy,drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-11-28T01:09:42Z": {
    type: "movie",
    title: "Abominable",
    year: "2019",
    trakt_rating: "7.62272",
    trakt_id: "277187",
    imdb_id: "tt6324278",
    tmdb_id: "431580",
    url: "https://trakt.tv/movies/abominable-2019",
    released: "2019-09-27",
    runtime: "97",
    genres: "animation,adventure,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-11-20T02:55:20Z": {
    type: "movie",
    title: "It Chapter Two",
    year: "2019",
    trakt_rating: "6.93398",
    trakt_id: "322000",
    imdb_id: "tt7349950",
    tmdb_id: "474350",
    url: "https://trakt.tv/movies/it-chapter-two-2019",
    released: "2019-09-06",
    runtime: "169",
    genres: "horror,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-11-16T01:44:01Z": {
    type: "movie",
    title: "Night at the Museum",
    year: "2006",
    trakt_rating: "6.8166",
    trakt_id: "1048",
    imdb_id: "tt0477347",
    tmdb_id: "1593",
    url: "https://trakt.tv/movies/night-at-the-museum-2006",
    released: "2006-12-21",
    runtime: "108",
    genres: "action,adventure,comedy,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-11-16T01:44:00Z": {
    type: "movie",
    title: "Night at the Museum: Battle of the Smithsonian",
    year: "2009",
    trakt_rating: "6.55107",
    trakt_id: "11228",
    imdb_id: "tt1078912",
    tmdb_id: "18360",
    url: "https://trakt.tv/movies/night-at-the-museum-battle-of-the-smithsonian-2009",
    released: "2009-05-21",
    runtime: "105",
    genres: "action,adventure,comedy,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-11-01T08:12:55Z": {
    type: "movie",
    title: "Parasite",
    year: "2019",
    trakt_rating: "8.52553",
    trakt_id: "343180",
    imdb_id: "tt6751668",
    tmdb_id: "496243",
    url: "https://trakt.tv/movies/parasite-2019",
    released: "2019-05-30",
    runtime: "133",
    genres: "comedy,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-11-01T08:12:38Z": {
    type: "movie",
    title: "Good Boys",
    year: "2019",
    trakt_rating: "7.17228",
    trakt_id: "369915",
    imdb_id: "tt6977338",
    tmdb_id: "521777",
    url: "https://trakt.tv/movies/good-boys-2019",
    released: "2019-08-16",
    runtime: "89",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-10-27T02:19:55Z": {
    type: "movie",
    title: "Dolemite Is My Name",
    year: "2019",
    trakt_rating: "7.37139",
    trakt_id: "377030",
    imdb_id: "tt8526872",
    tmdb_id: "528888",
    url: "https://trakt.tv/movies/dolemite-is-my-name-2019",
    released: "2019-10-04",
    runtime: "118",
    genres: "drama,comedy,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-10-22T18:12:05Z": {
    type: "movie",
    title: "Scary Stories to Tell in the Dark",
    year: "2019",
    trakt_rating: "6.63808",
    trakt_id: "271335",
    imdb_id: "tt3387520",
    tmdb_id: "417384",
    url: "https://trakt.tv/movies/scary-stories-to-tell-in-the-dark-2019",
    released: "2019-08-09",
    runtime: "108",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-10-19T04:00:06Z": {
    type: "movie",
    title: "Extremely Wicked, Shockingly Evil and Vile",
    year: "2019",
    trakt_rating: "7.08468",
    trakt_id: "303985",
    imdb_id: "tt2481498",
    tmdb_id: "457799",
    url: "https://trakt.tv/movies/extremely-wicked-shockingly-evil-and-vile-2019",
    released: "2019-05-03",
    runtime: "111",
    genres: "crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-10-13T03:47:55Z": {
    type: "movie",
    title: "The Lion King",
    year: "2019",
    trakt_rating: "7.32192",
    trakt_id: "265470",
    imdb_id: "tt6105098",
    tmdb_id: "420818",
    url: "https://trakt.tv/movies/the-lion-king-2019",
    released: "2019-07-19",
    runtime: "118",
    genres: "adventure,musical,family,drama,animation",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-10-13T03:36:42Z": {
    type: "movie",
    title: "El Camino: A Breaking Bad Movie",
    year: "2019",
    trakt_rating: "7.23475",
    trakt_id: "410927",
    imdb_id: "tt9243946",
    tmdb_id: "559969",
    url: "https://trakt.tv/movies/el-camino-a-breaking-bad-movie-2019",
    released: "2019-10-11",
    runtime: "123",
    genres: "crime,drama,thriller,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-10-09T01:23:08Z": {
    type: "movie",
    title: "Of Mice and Men",
    year: "1992",
    trakt_rating: "7.32406",
    trakt_id: "4885",
    imdb_id: "tt0105046",
    tmdb_id: "9609",
    url: "https://trakt.tv/movies/of-mice-and-men-1992",
    released: "1992-10-02",
    runtime: "110",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-10-09T01:18:21Z": {
    type: "movie",
    title: "The Grapes of Wrath",
    year: "1940",
    trakt_rating: "7.67828",
    trakt_id: "474",
    imdb_id: "tt0032551",
    tmdb_id: "596",
    url: "https://trakt.tv/movies/the-grapes-of-wrath-1940",
    released: "1940-03-15",
    runtime: "129",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-10-05T22:20:41Z": {
    type: "movie",
    title: "3 from Hell",
    year: "2019",
    trakt_rating: "6.57886",
    trakt_id: "336150",
    imdb_id: "tt8134742",
    tmdb_id: "489064",
    url: "https://trakt.tv/movies/3-from-hell-2019",
    released: "2019-09-16",
    runtime: "115",
    genres: "horror,drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-10-05T22:19:12Z": {
    type: "movie",
    title: "Knife+Heart",
    year: "2018",
    trakt_rating: "6.46073",
    trakt_id: "323384",
    imdb_id: "tt6426028",
    tmdb_id: "475930",
    url: "https://trakt.tv/movies/knife-heart-2018",
    released: "2018-06-27",
    runtime: "103",
    genres: "horror,romance,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-10-05T00:09:53Z": {
    type: "movie",
    title: "The Lord of the Rings",
    year: "1978",
    trakt_rating: "6.70332",
    trakt_id: "91",
    imdb_id: "tt0077869",
    tmdb_id: "123",
    url: "https://trakt.tv/movies/the-lord-of-the-rings-1978",
    released: "1978-11-15",
    runtime: "132",
    genres: "adventure,animation,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-10-03T01:47:58Z": {
    type: "movie",
    title: "Light of My Life",
    year: "2019",
    trakt_rating: "6.81043",
    trakt_id: "286911",
    imdb_id: "tt6063090",
    tmdb_id: "441183",
    url: "https://trakt.tv/movies/light-of-my-life-2019",
    released: "2019-08-09",
    runtime: "119",
    genres: "drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-09-28T19:45:29Z": {
    type: "movie",
    title: "Midsommar",
    year: "2019",
    trakt_rating: "7.11263",
    trakt_id: "382491",
    imdb_id: "tt8772262",
    tmdb_id: "530385",
    url: "https://trakt.tv/movies/midsommar-2019",
    released: "2019-07-03",
    runtime: "147",
    genres: "horror,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-09-20T01:50:57Z": {
    type: "movie",
    title: "The Dead Don't Die",
    year: "2019",
    trakt_rating: "5.70357",
    trakt_id: "387368",
    imdb_id: "tt8695030",
    tmdb_id: "535581",
    url: "https://trakt.tv/movies/the-dead-don-t-die-2019",
    released: "2019-06-14",
    runtime: "104",
    genres: "comedy,horror,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-09-17T02:05:33Z": {
    type: "movie",
    title: "Yesterday",
    year: "2019",
    trakt_rating: "7.20132",
    trakt_id: "363597",
    imdb_id: "tt8079248",
    tmdb_id: "515195",
    url: "https://trakt.tv/movies/yesterday-2019",
    released: "2019-06-28",
    runtime: "116",
    genres: "comedy,romance,fantasy,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-09-16T00:48:01Z": {
    type: "movie",
    title: "Toy Story 4",
    year: "2019",
    trakt_rating: "7.72679",
    trakt_id: "193972",
    imdb_id: "tt1979376",
    tmdb_id: "301528",
    url: "https://trakt.tv/movies/toy-story-4-2019",
    released: "2019-06-21",
    runtime: "100",
    genres: "animation,comedy,family,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-09-12T00:28:06Z": {
    type: "movie",
    title: "Spider-Man: Far From Home",
    year: "2019",
    trakt_rating: "7.65887",
    trakt_id: "273537",
    imdb_id: "tt6320628",
    tmdb_id: "429617",
    url: "https://trakt.tv/movies/spider-man-far-from-home-2019",
    released: "2019-07-02",
    runtime: "129",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-08-30T19:35:45Z": {
    type: "movie",
    title: "The Dark Crystal",
    year: "1982",
    trakt_rating: "7.48082",
    trakt_id: "6715",
    imdb_id: "tt0083791",
    tmdb_id: "11639",
    url: "https://trakt.tv/movies/the-dark-crystal-1982",
    released: "1982-12-17",
    runtime: "93",
    genres: "fantasy,adventure,family,animation",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-08-30T00:59:36Z": {
    type: "movie",
    title: "Dark Phoenix",
    year: "2019",
    trakt_rating: "6.38526",
    trakt_id: "200946",
    imdb_id: "tt6565702",
    tmdb_id: "320288",
    url: "https://trakt.tv/movies/dark-phoenix-2019",
    released: "2019-06-07",
    runtime: "114",
    genres: "science-fiction,action,superhero,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-08-29T02:56:44Z": {
    type: "movie",
    title: "Quiz Show",
    year: "1994",
    trakt_rating: "7.40394",
    trakt_id: "6538",
    imdb_id: "tt0110932",
    tmdb_id: "11450",
    url: "https://trakt.tv/movies/quiz-show-1994",
    released: "1994-09-16",
    runtime: "133",
    genres: "drama,history,mystery,game-show",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-08-23T01:12:48Z": {
    type: "movie",
    title: "A Bad Moms Christmas",
    year: "2017",
    trakt_rating: "6.79335",
    trakt_id: "277139",
    imdb_id: "tt6359956",
    tmdb_id: "431530",
    url: "https://trakt.tv/movies/a-bad-moms-christmas-2017",
    released: "2017-11-01",
    runtime: "104",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-08-23T01:12:20Z": {
    type: "movie",
    title: "Bad Moms",
    year: "2016",
    trakt_rating: "7.23308",
    trakt_id: "226615",
    imdb_id: "tt4651520",
    tmdb_id: "376659",
    url: "https://trakt.tv/movies/bad-moms-2016",
    released: "2016-07-29",
    runtime: "100",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-08-21T00:44:36Z": {
    type: "movie",
    title: "Men in Black: International",
    year: "2019",
    trakt_rating: "6.51504",
    trakt_id: "326808",
    imdb_id: "tt2283336",
    tmdb_id: "479455",
    url: "https://trakt.tv/movies/men-in-black-international-2019",
    released: "2019-06-14",
    runtime: "115",
    genres: "comedy,science-fiction,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-08-21T00:42:55Z": {
    type: "movie",
    title: "Finding Steve McQueen",
    year: "2019",
    trakt_rating: "7.13699",
    trakt_id: "282898",
    imdb_id: "tt6032328",
    tmdb_id: "433499",
    url: "https://trakt.tv/movies/finding-steve-mcqueen-2019",
    released: "2019-03-15",
    runtime: "91",
    genres: "crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-08-17T15:02:00Z": {
    type: "movie",
    title: "The Secret Life of Pets 2",
    year: "2019",
    trakt_rating: "7.26088",
    trakt_id: "269578",
    imdb_id: "tt5113040",
    tmdb_id: "412117",
    url: "https://trakt.tv/movies/the-secret-life-of-pets-2-2019",
    released: "2019-06-07",
    runtime: "86",
    genres: "animation,comedy,family,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-08-14T01:51:26Z": {
    type: "movie",
    title: "John Wick: Chapter 3 - Parabellum",
    year: "2019",
    trakt_rating: "7.69066",
    trakt_id: "304278",
    imdb_id: "tt6146586",
    tmdb_id: "458156",
    url: "https://trakt.tv/movies/john-wick-chapter-3-parabellum-2019",
    released: "2019-05-17",
    runtime: "131",
    genres: "crime,thriller,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-08-05T22:26:54Z": {
    type: "movie",
    title: "Brightburn",
    year: "2019",
    trakt_rating: "6.59216",
    trakt_id: "383221",
    imdb_id: "tt7752126",
    tmdb_id: "531309",
    url: "https://trakt.tv/movies/brightburn-2019",
    released: "2019-05-24",
    runtime: "91",
    genres: "horror,science-fiction,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-08-05T22:26:28Z": {
    type: "movie",
    title: "127 Hours",
    year: "2010",
    trakt_rating: "7.44246",
    trakt_id: "29851",
    imdb_id: "tt1542344",
    tmdb_id: "44115",
    url: "https://trakt.tv/movies/127-hours-2010",
    released: "2010-11-12",
    runtime: "94",
    genres: "adventure,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-07-31T00:51:01Z": {
    type: "movie",
    title: "Marvel Rising: Heart of Iron",
    year: "2019",
    trakt_rating: "6.62366",
    trakt_id: "431199",
    imdb_id: "tt9073746",
    tmdb_id: "583209",
    url: "https://trakt.tv/movies/marvel-rising-heart-of-iron-2019",
    released: "2019-04-03",
    runtime: "44",
    genres: "animation,science-fiction,action,family,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-07-31T00:37:30Z": {
    type: "movie",
    title: "Micmacs",
    year: "2009",
    trakt_rating: "7.36294",
    trakt_id: "17299",
    imdb_id: "tt1149361",
    tmdb_id: "27936",
    url: "https://trakt.tv/movies/micmacs-2009",
    released: "2009-10-28",
    runtime: "100",
    genres: "comedy,crime,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-07-30T23:46:35Z": {
    type: "movie",
    title: "Godzilla: King of the Monsters",
    year: "2019",
    trakt_rating: "6.66205",
    trakt_id: "240099",
    imdb_id: "tt3741700",
    tmdb_id: "373571",
    url: "https://trakt.tv/movies/godzilla-king-of-the-monsters-2019",
    released: "2019-05-31",
    runtime: "132",
    genres: "science-fiction,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-07-30T00:05:17Z": {
    type: "movie",
    title: "Avengers: Endgame",
    year: "2019",
    trakt_rating: "8.46907",
    trakt_id: "191798",
    imdb_id: "tt4154796",
    tmdb_id: "299534",
    url: "https://trakt.tv/movies/avengers-endgame-2019",
    released: "2019-04-26",
    runtime: "181",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-07-29T01:50:24Z": {
    type: "movie",
    title: "Hellboy",
    year: "2019",
    trakt_rating: "6.05014",
    trakt_id: "303043",
    imdb_id: "tt2274648",
    tmdb_id: "456740",
    url: "https://trakt.tv/movies/hellboy-2019",
    released: "2019-04-12",
    runtime: "121",
    genres: "action,fantasy,adventure,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-07-28T20:58:30Z": {
    type: "movie",
    title: "The Hole in the Ground",
    year: "2019",
    trakt_rating: "6.16054",
    trakt_id: "331587",
    imdb_id: "tt6198946",
    tmdb_id: "484437",
    url: "https://trakt.tv/movies/the-hole-in-the-ground-2019",
    released: "2019-03-01",
    runtime: "90",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-07-28T04:32:01Z": {
    type: "movie",
    title: "Alita: Battle Angel",
    year: "2019",
    trakt_rating: "7.63789",
    trakt_id: "243128",
    imdb_id: "tt0437086",
    tmdb_id: "399579",
    url: "https://trakt.tv/movies/alita-battle-angel-2019",
    released: "2019-02-14",
    runtime: "122",
    genres: "action,science-fiction,adventure,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-07-21T01:53:38Z": {
    type: "movie",
    title: "Pokémon Detective Pikachu",
    year: "2019",
    trakt_rating: "7.15313",
    trakt_id: "294039",
    imdb_id: "tt5884052",
    tmdb_id: "447404",
    url: "https://trakt.tv/movies/pokemon-detective-pikachu-2019",
    released: "2019-05-10",
    runtime: "105",
    genres: "adventure,action,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-07-13T22:36:18Z": {
    type: "movie",
    title: "Monster Hunt 2",
    year: "2018",
    trakt_rating: "6.18797",
    trakt_id: "345124",
    imdb_id: "tt6170484",
    tmdb_id: "497984",
    url: "https://trakt.tv/movies/monster-hunt-2-2018",
    released: "2018-02-16",
    runtime: "110",
    genres: "adventure,comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-07-10T00:27:47Z": {
    type: "movie",
    title: "Missing Link",
    year: "2019",
    trakt_rating: "7.09589",
    trakt_id: "304332",
    imdb_id: "tt6348138",
    tmdb_id: "458253",
    url: "https://trakt.tv/movies/missing-link-2019",
    released: "2019-04-12",
    runtime: "95",
    genres: "animation,comedy,adventure,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-07-09T23:56:41Z": {
    type: "movie",
    title: "Nightmare Cinema",
    year: "2018",
    trakt_rating: "6.12742",
    trakt_id: "327407",
    imdb_id: "tt7349910",
    tmdb_id: "480100",
    url: "https://trakt.tv/movies/nightmare-cinema-2018",
    released: "2018-06-21",
    runtime: "119",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-07-07T00:46:11Z": {
    type: "movie",
    title: "Kubo and the Two Strings",
    year: "2016",
    trakt_rating: "7.97894",
    trakt_id: "192866",
    imdb_id: "tt4302938",
    tmdb_id: "313297",
    url: "https://trakt.tv/movies/kubo-and-the-two-strings-2016",
    released: "2016-08-19",
    runtime: "102",
    genres: "animation,adventure,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-06-28T04:11:18Z": {
    type: "movie",
    title: "Shazam!",
    year: "2019",
    trakt_rating: "7.26264",
    trakt_id: "184992",
    imdb_id: "tt0448115",
    tmdb_id: "287947",
    url: "https://trakt.tv/movies/shazam-2019",
    released: "2019-04-05",
    runtime: "132",
    genres: "superhero,comedy,fantasy,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-06-26T01:01:06Z": {
    type: "movie",
    title: "Altered States",
    year: "1980",
    trakt_rating: "6.925",
    trakt_id: "6623",
    imdb_id: "tt0080360",
    tmdb_id: "11542",
    url: "https://trakt.tv/movies/altered-states-1980",
    released: "1980-12-25",
    runtime: "102",
    genres: "science-fiction,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-06-26T01:00:46Z": {
    type: "movie",
    title: "Pet Sematary",
    year: "2019",
    trakt_rating: "6.1132",
    trakt_id: "102209",
    imdb_id: "tt0837563",
    tmdb_id: "157433",
    url: "https://trakt.tv/movies/pet-sematary-2019",
    released: "2019-04-05",
    runtime: "101",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-06-14T12:48:19Z": {
    type: "movie",
    title: "To Live and Die in L.A.",
    year: "1985",
    trakt_rating: "7.22849",
    trakt_id: "5101",
    imdb_id: "tt0090180",
    tmdb_id: "9846",
    url: "https://trakt.tv/movies/to-live-and-die-in-l-a-1985",
    released: "1985-11-01",
    runtime: "116",
    genres: "crime,thriller,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-06-14T01:21:48Z": {
    type: "movie",
    title: "Dumbo",
    year: "2019",
    trakt_rating: "6.98521",
    trakt_id: "210942",
    imdb_id: "tt3861390",
    tmdb_id: "329996",
    url: "https://trakt.tv/movies/dumbo-2019",
    released: "2019-03-29",
    runtime: "112",
    genres: "family,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-06-11T17:09:43Z": {
    type: "movie",
    title: "Norm of the North: King Sized Adventure",
    year: "2019",
    trakt_rating: "5.64407",
    trakt_id: "446821",
    imdb_id: "tt9428190",
    tmdb_id: "601131",
    url: "https://trakt.tv/movies/norm-of-the-north-king-sized-adventure-2019",
    released: "2019-06-10",
    runtime: "90",
    genres: "animation,adventure,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-06-11T12:11:52Z": {
    type: "movie",
    title: "El Angel",
    year: "2018",
    trakt_rating: "7.07143",
    trakt_id: "354777",
    imdb_id: "tt7204348",
    tmdb_id: "507505",
    url: "https://trakt.tv/movies/el-angel-2018",
    released: "2018-08-09",
    runtime: "115",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-06-05T02:58:21Z": {
    type: "movie",
    title: "Wonder Park",
    year: "2019",
    trakt_rating: "7.20572",
    trakt_id: "243438",
    imdb_id: "tt6428676",
    tmdb_id: "400157",
    url: "https://trakt.tv/movies/wonder-park-2019",
    released: "2019-03-15",
    runtime: "86",
    genres: "animation,comedy,adventure,family,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-06-05T01:24:54Z": {
    type: "movie",
    title: "Treasure Planet",
    year: "2002",
    trakt_rating: "7.63566",
    trakt_id: "4472",
    imdb_id: "tt0133240",
    tmdb_id: "9016",
    url: "https://trakt.tv/movies/treasure-planet-2002",
    released: "2002-11-27",
    runtime: "95",
    genres: "adventure,animation,fantasy,science-fiction,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-05-30T08:48:24Z": {
    type: "movie",
    title: "Captive State",
    year: "2019",
    trakt_rating: "6.34475",
    trakt_id: "273408",
    imdb_id: "tt5968394",
    tmdb_id: "429471",
    url: "https://trakt.tv/movies/captive-state-2019",
    released: "2019-03-15",
    runtime: "110",
    genres: "science-fiction,action,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-05-29T03:03:11Z": {
    type: "movie",
    title: "Game of Thrones: The Last Watch",
    year: "2019",
    trakt_rating: "7.31505",
    trakt_id: "437974",
    imdb_id: "tt10090796",
    tmdb_id: "591278",
    url: "https://trakt.tv/movies/game-of-thrones-the-last-watch-2019",
    released: "2019-05-26",
    runtime: "115",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-05-28T01:08:53Z": {
    type: "movie",
    title: "Captain Marvel",
    year: "2019",
    trakt_rating: "7.25871",
    trakt_id: "193963",
    imdb_id: "tt4154664",
    tmdb_id: "299537",
    url: "https://trakt.tv/movies/captain-marvel-2019",
    released: "2019-03-08",
    runtime: "124",
    genres: "science-fiction,action,adventure,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-05-28T01:04:47Z": {
    type: "movie",
    title: "Spider-Ham: Caught in a Ham",
    year: "2019",
    trakt_rating: "6.66897",
    trakt_id: "431565",
    imdb_id: "tt9803682",
    tmdb_id: "583055",
    url: "https://trakt.tv/movies/spider-ham-caught-in-a-ham-2019",
    released: "2019-03-19",
    runtime: "5",
    genres: "animation,action,comedy,family,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-05-23T05:35:18Z": {
    type: "movie",
    title: "Us",
    year: "2019",
    trakt_rating: "7.03509",
    trakt_id: "305845",
    imdb_id: "tt6857112",
    tmdb_id: "458723",
    url: "https://trakt.tv/movies/us-2019",
    released: "2019-03-22",
    runtime: "116",
    genres: "thriller,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-05-12T13:14:46Z": {
    type: "movie",
    title: "1922",
    year: "2017",
    trakt_rating: "6.45501",
    trakt_id: "317337",
    imdb_id: "tt6214928",
    tmdb_id: "452507",
    url: "https://trakt.tv/movies/1922-2017",
    released: "2017-10-20",
    runtime: "102",
    genres: "crime,drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-05-05T06:08:23Z": {
    type: "movie",
    title: "Apollo 11",
    year: "2019",
    trakt_rating: "8.18444",
    trakt_id: "399358",
    imdb_id: "tt8760684",
    tmdb_id: "549559",
    url: "https://trakt.tv/movies/apollo-11-2019",
    released: "2019-03-01",
    runtime: "93",
    genres: "documentary,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-05-05T06:05:14Z": {
    type: "movie",
    title: "They Shall Not Grow Old",
    year: "2018",
    trakt_rating: "8.16926",
    trakt_id: "394612",
    imdb_id: "tt7905466",
    tmdb_id: "543580",
    url: "https://trakt.tv/movies/they-shall-not-grow-old-2018",
    released: "2018-11-09",
    runtime: "99",
    genres: "documentary,history,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-05-04T00:43:29Z": {
    type: "movie",
    title: "Gandhi",
    year: "1982",
    trakt_rating: "7.70633",
    trakt_id: "626",
    imdb_id: "tt0083987",
    tmdb_id: "783",
    url: "https://trakt.tv/movies/gandhi-1982",
    released: "1982-11-30",
    runtime: "191",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-04-25T07:28:04Z": {
    type: "movie",
    title: "Destroyer",
    year: "2018",
    trakt_rating: "6.4471",
    trakt_id: "318900",
    imdb_id: "tt7137380",
    tmdb_id: "471507",
    url: "https://trakt.tv/movies/destroyer-2018",
    released: "2018-12-25",
    runtime: "121",
    genres: "thriller,crime,drama,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-04-18T01:26:51Z": {
    type: "movie",
    title: "The Lego Movie 2: The Second Part",
    year: "2019",
    trakt_rating: "7.14679",
    trakt_id: "177700",
    imdb_id: "tt3513498",
    tmdb_id: "280217",
    url: "https://trakt.tv/movies/the-lego-movie-2-the-second-part-2019",
    released: "2019-02-08",
    runtime: "106",
    genres: "animation,family,action,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-04-16T00:17:07Z": {
    type: "movie",
    title: "The Professor and the Madman",
    year: "2019",
    trakt_rating: "7.51115",
    trakt_id: "257281",
    imdb_id: "tt5932728",
    tmdb_id: "411728",
    url: "https://trakt.tv/movies/the-professor-and-the-madman-2019",
    released: "2019-05-10",
    runtime: "124",
    genres: "drama,history,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-04-11T04:07:13Z": {
    type: "movie",
    title: "The Secret Life of Pets",
    year: "2016",
    trakt_rating: "7.33664",
    trakt_id: "209011",
    imdb_id: "tt2709768",
    tmdb_id: "328111",
    url: "https://trakt.tv/movies/the-secret-life-of-pets-2016",
    released: "2016-07-08",
    runtime: "86",
    genres: "adventure,comedy,animation,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-04-11T02:32:11Z": {
    type: "movie",
    title: "The Kid Who Would Be King",
    year: "2019",
    trakt_rating: "6.86696",
    trakt_id: "301912",
    imdb_id: "tt6811018",
    tmdb_id: "454294",
    url: "https://trakt.tv/movies/the-kid-who-would-be-king-2019",
    released: "2019-02-15",
    runtime: "120",
    genres: "action,adventure,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-04-05T02:06:09Z": {
    type: "movie",
    title: "The Witch: Part 1. The Subversion",
    year: "2018",
    trakt_rating: "7.76173",
    trakt_id: "382617",
    imdb_id: "tt8574252",
    tmdb_id: "530254",
    url: "https://trakt.tv/movies/the-witch-part-1-the-subversion-2018",
    released: "2018-06-27",
    runtime: "125",
    genres: "action,mystery,science-fiction,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-04-03T13:16:39Z": {
    type: "movie",
    title: "How to Train Your Dragon: The Hidden World",
    year: "2019",
    trakt_rating: "7.94207",
    trakt_id: "106668",
    imdb_id: "tt2386490",
    tmdb_id: "166428",
    url: "https://trakt.tv/movies/how-to-train-your-dragon-the-hidden-world-2019",
    released: "2019-02-22",
    runtime: "104",
    genres: "adventure,animation,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-04-02T08:17:40Z": {
    type: "movie",
    title: "Glass",
    year: "2019",
    trakt_rating: "7.04036",
    trakt_id: "298686",
    imdb_id: "tt6823368",
    tmdb_id: "450465",
    url: "https://trakt.tv/movies/glass-2019",
    released: "2019-01-18",
    runtime: "129",
    genres: "thriller,superhero,drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-04-02T03:49:38Z": {
    type: "movie",
    title: "Pink Floyd: Live at Pompeii",
    year: "1972",
    trakt_rating: "8.6028",
    trakt_id: "15615",
    imdb_id: "tt0069090",
    tmdb_id: "25771",
    url: "https://trakt.tv/movies/pink-floyd-live-at-pompeii-1972",
    released: "1972-08-31",
    runtime: "85",
    genres: "music,documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-31T13:41:12Z": {
    type: "movie",
    title: "Holmes & Watson",
    year: "2018",
    trakt_rating: "5.33906",
    trakt_id: "270885",
    imdb_id: "tt1255919",
    tmdb_id: "426563",
    url: "https://trakt.tv/movies/holmes-watson-2018",
    released: "2018-12-25",
    runtime: "90",
    genres: "mystery,adventure,comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-30T02:06:26Z": {
    type: "movie",
    title: "The Highwaymen",
    year: "2019",
    trakt_rating: "7.25763",
    trakt_id: "347603",
    imdb_id: "tt1860242",
    tmdb_id: "500682",
    url: "https://trakt.tv/movies/the-highwaymen-2019",
    released: "2019-03-15",
    runtime: "133",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-30T02:02:56Z": {
    type: "movie",
    title: "The Defiant Ones",
    year: "1958",
    trakt_rating: "7.49545",
    trakt_id: "6509",
    imdb_id: "tt0051525",
    tmdb_id: "11414",
    url: "https://trakt.tv/movies/the-defiant-ones-1958",
    released: "1958-09-24",
    runtime: "96",
    genres: "drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-22T09:31:30Z": {
    type: "movie",
    title: "Vice",
    year: "2018",
    trakt_rating: "7.19997",
    trakt_id: "312722",
    imdb_id: "tt6266538",
    tmdb_id: "429197",
    url: "https://trakt.tv/movies/vice-2018",
    released: "2018-12-25",
    runtime: "132",
    genres: "drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-20T00:43:12Z": {
    type: "movie",
    title: "Bumblebee",
    year: "2018",
    trakt_rating: "7.24071",
    trakt_id: "269568",
    imdb_id: "tt4701182",
    tmdb_id: "424783",
    url: "https://trakt.tv/movies/bumblebee-2018",
    released: "2018-12-21",
    runtime: "113",
    genres: "science-fiction,action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-13T01:47:42Z": {
    type: "movie",
    title: "Kursk",
    year: "2018",
    trakt_rating: "7.11374",
    trakt_id: "244141",
    imdb_id: "tt4951982",
    tmdb_id: "401200",
    url: "https://trakt.tv/movies/kursk-2018",
    released: "2018-11-07",
    runtime: "118",
    genres: "drama,history,thriller,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-12T00:00:23Z": {
    type: "movie",
    title: "Possum",
    year: "2018",
    trakt_rating: "6.07692",
    trakt_id: "319693",
    imdb_id: "tt6081670",
    tmdb_id: "472269",
    url: "https://trakt.tv/movies/possum-2018",
    released: "2018-10-26",
    runtime: "85",
    genres: "drama,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-06T02:57:14Z": {
    type: "movie",
    title: "Lifeboat",
    year: "1944",
    trakt_rating: "7.43902",
    trakt_id: "7821",
    imdb_id: "tt0037017",
    tmdb_id: "13321",
    url: "https://trakt.tv/movies/lifeboat-1944",
    released: "1944-01-28",
    runtime: "96",
    genres: "war,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-06T02:56:19Z": {
    type: "movie",
    title: "The Trouble with Harry",
    year: "1955",
    trakt_rating: "7.11848",
    trakt_id: "6345",
    imdb_id: "tt0048750",
    tmdb_id: "11219",
    url: "https://trakt.tv/movies/the-trouble-with-harry-1955",
    released: "1955-10-03",
    runtime: "99",
    genres: "comedy,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-06T02:54:21Z": {
    type: "movie",
    title: "The Clovehitch Killer",
    year: "2018",
    trakt_rating: "6.89236",
    trakt_id: "395076",
    imdb_id: "tt6269368",
    tmdb_id: "544431",
    url: "https://trakt.tv/movies/the-clovehitch-killer-2018",
    released: "2018-11-16",
    runtime: "109",
    genres: "mystery,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-05T05:00:18Z": {
    type: "movie",
    title: "Aquaman",
    year: "2018",
    trakt_rating: "7.2569",
    trakt_id: "193968",
    imdb_id: "tt1477834",
    tmdb_id: "297802",
    url: "https://trakt.tv/movies/aquaman-2018",
    released: "2018-12-21",
    runtime: "143",
    genres: "fantasy,superhero,adventure,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-05T01:54:55Z": {
    type: "movie",
    title: "The Killing",
    year: "1956",
    trakt_rating: "7.73428",
    trakt_id: "205",
    imdb_id: "tt0049406",
    tmdb_id: "247",
    url: "https://trakt.tv/movies/the-killing-1956",
    released: "1956-06-06",
    runtime: "85",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-05T00:40:22Z": {
    type: "movie",
    title: "A Streetcar Named Desire",
    year: "1951",
    trakt_rating: "7.62912",
    trakt_id: "574",
    imdb_id: "tt0044081",
    tmdb_id: "702",
    url: "https://trakt.tv/movies/a-streetcar-named-desire-1951",
    released: "1951-09-18",
    runtime: "125",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-04T13:22:16Z": {
    type: "movie",
    title: "Green Street Hooligans",
    year: "2005",
    trakt_rating: "7.57943",
    trakt_id: "4400",
    imdb_id: "tt0385002",
    tmdb_id: "8923",
    url: "https://trakt.tv/movies/green-street-hooligans-2005",
    released: "2005-09-09",
    runtime: "109",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-04T03:36:13Z": {
    type: "movie",
    title: "The Way of the Gun",
    year: "2000",
    trakt_rating: "6.98763",
    trakt_id: "1066",
    imdb_id: "tt0202677",
    tmdb_id: "1619",
    url: "https://trakt.tv/movies/the-way-of-the-gun-2000",
    released: "2000-09-08",
    runtime: "119",
    genres: "action,thriller,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-02T07:38:37Z": {
    type: "movie",
    title: "Fantastic Beasts and Where to Find Them",
    year: "2016",
    trakt_rating: "7.6356",
    trakt_id: "158661",
    imdb_id: "tt3183660",
    tmdb_id: "259316",
    url: "https://trakt.tv/movies/fantastic-beasts-and-where-to-find-them-2016",
    released: "2016-11-18",
    runtime: "132",
    genres: "fantasy,adventure,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-02T07:35:14Z": {
    type: "movie",
    title: "Fantastic Beasts: The Crimes of Grindelwald",
    year: "2018",
    trakt_rating: "6.99104",
    trakt_id: "219417",
    imdb_id: "tt4123430",
    tmdb_id: "338952",
    url: "https://trakt.tv/movies/fantastic-beasts-the-crimes-of-grindelwald-2018",
    released: "2018-11-16",
    runtime: "134",
    genres: "adventure,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-02T07:28:25Z": {
    type: "movie",
    title: "Harry Potter and the Deathly Hallows: Part 2",
    year: "2011",
    trakt_rating: "8.24008",
    trakt_id: "7229",
    imdb_id: "tt1201607",
    tmdb_id: "12445",
    url: "https://trakt.tv/movies/harry-potter-and-the-deathly-hallows-part-2-2011",
    released: "2011-07-15",
    runtime: "130",
    genres: "adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-02T07:25:44Z": {
    type: "movie",
    title: "Harry Potter and the Deathly Hallows: Part 1",
    year: "2010",
    trakt_rating: "7.95062",
    trakt_id: "7228",
    imdb_id: "tt0926084",
    tmdb_id: "12444",
    url: "https://trakt.tv/movies/harry-potter-and-the-deathly-hallows-part-1-2010",
    released: "2010-11-19",
    runtime: "146",
    genres: "adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-02T06:59:08Z": {
    type: "movie",
    title: "Harry Potter and the Half-Blood Prince",
    year: "2009",
    trakt_rating: "7.88436",
    trakt_id: "610",
    imdb_id: "tt0417741",
    tmdb_id: "767",
    url: "https://trakt.tv/movies/harry-potter-and-the-half-blood-prince-2009",
    released: "2009-07-08",
    runtime: "153",
    genres: "adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-02T06:35:14Z": {
    type: "movie",
    title: "Harry Potter and the Goblet of Fire",
    year: "2005",
    trakt_rating: "7.93654",
    trakt_id: "548",
    imdb_id: "tt0330373",
    tmdb_id: "674",
    url: "https://trakt.tv/movies/harry-potter-and-the-goblet-of-fire-2005",
    released: "2005-11-18",
    runtime: "157",
    genres: "adventure,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-02T06:23:27Z": {
    type: "movie",
    title: "Harry Potter and the Order of the Phoenix",
    year: "2007",
    trakt_rating: "7.86114",
    trakt_id: "549",
    imdb_id: "tt0373889",
    tmdb_id: "675",
    url: "https://trakt.tv/movies/harry-potter-and-the-order-of-the-phoenix-2007",
    released: "2007-07-11",
    runtime: "138",
    genres: "adventure,fantasy,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-02T06:03:59Z": {
    type: "movie",
    title: "Harry Potter and the Prisoner of Azkaban",
    year: "2004",
    trakt_rating: "8.15017",
    trakt_id: "547",
    imdb_id: "tt0304141",
    tmdb_id: "673",
    url: "https://trakt.tv/movies/harry-potter-and-the-prisoner-of-azkaban-2004",
    released: "2004-06-04",
    runtime: "141",
    genres: "adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-02T06:02:41Z": {
    type: "movie",
    title: "Harry Potter and the Chamber of Secrets",
    year: "2002",
    trakt_rating: "7.87719",
    trakt_id: "546",
    imdb_id: "tt0295297",
    tmdb_id: "672",
    url: "https://trakt.tv/movies/harry-potter-and-the-chamber-of-secrets-2002",
    released: "2002-11-15",
    runtime: "161",
    genres: "adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-02T05:57:20Z": {
    type: "movie",
    title: "Harry Potter and the Philosopher's Stone",
    year: "2001",
    trakt_rating: "8.03039",
    trakt_id: "545",
    imdb_id: "tt0241527",
    tmdb_id: "671",
    url: "https://trakt.tv/movies/harry-potter-and-the-philosopher-s-stone-2001",
    released: "2001-11-16",
    runtime: "152",
    genres: "adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-03-02T02:03:40Z": {
    type: "movie",
    title: "Talk Radio",
    year: "1988",
    trakt_rating: "7.16384",
    trakt_id: "5349",
    imdb_id: "tt0096219",
    tmdb_id: "10132",
    url: "https://trakt.tv/movies/talk-radio-1988",
    released: "1988-12-21",
    runtime: "110",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-02-28T02:37:33Z": {
    type: "movie",
    title: "Operation Finale",
    year: "2018",
    trakt_rating: "7.13599",
    trakt_id: "340430",
    imdb_id: "tt5208252",
    tmdb_id: "493551",
    url: "https://trakt.tv/movies/operation-finale-2018",
    released: "2018-08-29",
    runtime: "124",
    genres: "drama,history,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-02-23T10:37:05Z": {
    type: "movie",
    title: "Lords of Chaos",
    year: "2018",
    trakt_rating: "7.01616",
    trakt_id: "270555",
    imdb_id: "tt4669296",
    tmdb_id: "426249",
    url: "https://trakt.tv/movies/lords-of-chaos-2018",
    released: "2019-03-22",
    runtime: "118",
    genres: "drama,music,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-02-21T01:50:46Z": {
    type: "movie",
    title: "Aladdin",
    year: "1992",
    trakt_rating: "8.05521",
    trakt_id: "655",
    imdb_id: "tt0103639",
    tmdb_id: "812",
    url: "https://trakt.tv/movies/aladdin-1992",
    released: "1992-11-25",
    runtime: "95",
    genres: "adventure,animation,fantasy,romance,family,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-02-20T00:50:48Z": {
    type: "movie",
    title: "Spider-Man: Into the Spider-Verse",
    year: "2018",
    trakt_rating: "8.65405",
    trakt_id: "205404",
    imdb_id: "tt4633694",
    tmdb_id: "324857",
    url: "https://trakt.tv/movies/spider-man-into-the-spider-verse-2018",
    released: "2018-12-14",
    runtime: "117",
    genres: "action,adventure,animation,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-02-17T02:58:40Z": {
    type: "movie",
    title: "Piercing",
    year: "2018",
    trakt_rating: "6.09896",
    trakt_id: "286221",
    imdb_id: "tt6516314",
    tmdb_id: "440444",
    url: "https://trakt.tv/movies/piercing-2018",
    released: "2019-02-01",
    runtime: "82",
    genres: "thriller,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-02-16T11:51:09Z": {
    type: "movie",
    title: "Ralph Breaks the Internet",
    year: "2018",
    trakt_rating: "7.42136",
    trakt_id: "249372",
    imdb_id: "tt5848272",
    tmdb_id: "404368",
    url: "https://trakt.tv/movies/ralph-breaks-the-internet-2018",
    released: "2018-11-21",
    runtime: "112",
    genres: "animation,family,comedy,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-02-15T18:36:38Z": {
    type: "movie",
    title: "How to Train Your Dragon 2",
    year: "2014",
    trakt_rating: "7.93388",
    trakt_id: "61873",
    imdb_id: "tt1646971",
    tmdb_id: "82702",
    url: "https://trakt.tv/movies/how-to-train-your-dragon-2-2014",
    released: "2014-06-13",
    runtime: "102",
    genres: "action,adventure,animation,comedy,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-02-14T15:45:00Z": {
    type: "movie",
    title: "Oceans: Our Blue Planet",
    year: "2018",
    trakt_rating: "7.59524",
    trakt_id: "386163",
    imdb_id: "tt8632844",
    tmdb_id: "533962",
    url: "https://trakt.tv/movies/oceans-our-blue-planet-2018",
    released: "2018-06-22",
    runtime: "40",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-02-08T01:02:03Z": {
    type: "movie",
    title: "The Front Runner",
    year: "2018",
    trakt_rating: "6.5288",
    trakt_id: "323949",
    imdb_id: "tt7074886",
    tmdb_id: "476764",
    url: "https://trakt.tv/movies/the-front-runner-2018",
    released: "2018-11-06",
    runtime: "113",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-02-06T05:16:21Z": {
    type: "movie",
    title: "Can You Ever Forgive Me?",
    year: "2018",
    trakt_rating: "7.11748",
    trakt_id: "246669",
    imdb_id: "tt4595882",
    tmdb_id: "401847",
    url: "https://trakt.tv/movies/can-you-ever-forgive-me-2018",
    released: "2018-10-19",
    runtime: "107",
    genres: "drama,crime,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-02-05T01:20:11Z": {
    type: "movie",
    title: "Overlord",
    year: "2018",
    trakt_rating: "6.90364",
    trakt_id: "312112",
    imdb_id: "tt4530422",
    tmdb_id: "438799",
    url: "https://trakt.tv/movies/overlord-2018",
    released: "2018-11-09",
    runtime: "110",
    genres: "horror,war,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-02-05T01:11:40Z": {
    type: "movie",
    title: "Prospect",
    year: "2018",
    trakt_rating: "6.52862",
    trakt_id: "353213",
    imdb_id: "tt7946422",
    tmdb_id: "506072",
    url: "https://trakt.tv/movies/prospect-2018",
    released: "2018-11-02",
    runtime: "98",
    genres: "science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-02-02T03:25:34Z": {
    type: "movie",
    title: "Needful Things",
    year: "1993",
    trakt_rating: "6.93084",
    trakt_id: "5831",
    imdb_id: "tt0107665",
    tmdb_id: "10657",
    url: "https://trakt.tv/movies/needful-things-1993",
    released: "1993-08-27",
    runtime: "120",
    genres: "drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-31T13:56:32Z": {
    type: "movie",
    title: "Fyre Fraud",
    year: "2019",
    trakt_rating: "7.14125",
    trakt_id: "425775",
    imdb_id: "tt7843600",
    tmdb_id: "575190",
    url: "https://trakt.tv/movies/fyre-fraud-2019",
    released: "2019-01-14",
    runtime: "96",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-30T03:50:57Z": {
    type: "movie",
    title: "Memoir of a Murderer",
    year: "2017",
    trakt_rating: "7.43802",
    trakt_id: "280069",
    imdb_id: "tt5729348",
    tmdb_id: "432836",
    url: "https://trakt.tv/movies/memoir-of-a-murderer-2017",
    released: "2017-09-07",
    runtime: "118",
    genres: "thriller,action,crime,mystery,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-26T18:57:48Z": {
    type: "movie",
    title: "House of 1000 Corpses",
    year: "2003",
    trakt_rating: "6.62397",
    trakt_id: "1795",
    imdb_id: "tt0251736",
    tmdb_id: "2662",
    url: "https://trakt.tv/movies/house-of-1000-corpses-2003",
    released: "2003-04-11",
    runtime: "89",
    genres: "horror,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-26T06:18:27Z": {
    type: "movie",
    title: "Slumdog Millionaire",
    year: "2008",
    trakt_rating: "7.90457",
    trakt_id: "7198",
    imdb_id: "tt1010048",
    tmdb_id: "12405",
    url: "https://trakt.tv/movies/slumdog-millionaire-2008",
    released: "2008-11-12",
    runtime: "120",
    genres: "drama,romance,game-show",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-25T03:28:33Z": {
    type: "movie",
    title: "Clockers",
    year: "1995",
    trakt_rating: "7.14006",
    trakt_id: "12686",
    imdb_id: "tt0112688",
    tmdb_id: "20649",
    url: "https://trakt.tv/movies/clockers-1995",
    released: "1995-09-15",
    runtime: "128",
    genres: "crime,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-25T00:56:43Z": {
    type: "movie",
    title: "Play Misty for Me",
    year: "1971",
    trakt_rating: "7.102",
    trakt_id: "9347",
    imdb_id: "tt0067588",
    tmdb_id: "15393",
    url: "https://trakt.tv/movies/play-misty-for-me-1971",
    released: "1971-11-03",
    runtime: "102",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-25T00:56:38Z": {
    type: "movie",
    title: "The Beguiled",
    year: "1971",
    trakt_rating: "7.19767",
    trakt_id: "20464",
    imdb_id: "tt0066819",
    tmdb_id: "31906",
    url: "https://trakt.tv/movies/the-beguiled-1971",
    released: "1971-03-31",
    runtime: "105",
    genres: "drama,war,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-25T00:54:03Z": {
    type: "movie",
    title: "The Gauntlet",
    year: "1977",
    trakt_rating: "6.91158",
    trakt_id: "10831",
    imdb_id: "tt0076070",
    tmdb_id: "17689",
    url: "https://trakt.tv/movies/the-gauntlet-1977",
    released: "1977-12-21",
    runtime: "109",
    genres: "action,crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-24T04:05:23Z": {
    type: "movie",
    title: "A Star Is Born",
    year: "2018",
    trakt_rating: "7.78725",
    trakt_id: "213443",
    imdb_id: "tt1517451",
    tmdb_id: "332562",
    url: "https://trakt.tv/movies/a-star-is-born-2018",
    released: "2018-10-05",
    runtime: "136",
    genres: "drama,romance,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-23T01:05:55Z": {
    type: "movie",
    title: "The Grinch",
    year: "2018",
    trakt_rating: "7.13421",
    trakt_id: "235711",
    imdb_id: "tt2709692",
    tmdb_id: "360920",
    url: "https://trakt.tv/movies/the-grinch-2018",
    released: "2018-11-09",
    runtime: "85",
    genres: "animation,family,comedy,fantasy,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-19T23:20:44Z": {
    type: "movie",
    title: "The Sixth Sense",
    year: "1999",
    trakt_rating: "8.16211",
    trakt_id: "590",
    imdb_id: "tt0167404",
    tmdb_id: "745",
    url: "https://trakt.tv/movies/the-sixth-sense-1999",
    released: "1999-08-06",
    runtime: "107",
    genres: "drama,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-19T22:36:34Z": {
    type: "movie",
    title: "Vanilla Sky",
    year: "2001",
    trakt_rating: "7.13576",
    trakt_id: "1277",
    imdb_id: "tt0259711",
    tmdb_id: "1903",
    url: "https://trakt.tv/movies/vanilla-sky-2001",
    released: "2001-12-10",
    runtime: "136",
    genres: "science-fiction,romance,drama,mystery,thriller,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-19T15:44:12Z": {
    type: "movie",
    title: "On the Waterfront",
    year: "1954",
    trakt_rating: "7.75815",
    trakt_id: "529",
    imdb_id: "tt0047296",
    tmdb_id: "654",
    url: "https://trakt.tv/movies/on-the-waterfront-1954",
    released: "1954-07-28",
    runtime: "108",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-18T23:40:41Z": {
    type: "movie",
    title: "The Sisters Brothers",
    year: "2018",
    trakt_rating: "7.00821",
    trakt_id: "295570",
    imdb_id: "tt4971344",
    tmdb_id: "440161",
    url: "https://trakt.tv/movies/the-sisters-brothers-2018",
    released: "2018-09-21",
    runtime: "121",
    genres: "western,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-18T08:31:31Z": {
    type: "movie",
    title: "Hunter Killer",
    year: "2018",
    trakt_rating: "7.31272",
    trakt_id: "292679",
    imdb_id: "tt1846589",
    tmdb_id: "399402",
    url: "https://trakt.tv/movies/hunter-killer-2018",
    released: "2018-10-26",
    runtime: "121",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-18T04:52:11Z": {
    type: "movie",
    title: "The Girl in the Spider's Web",
    year: "2018",
    trakt_rating: "6.96409",
    trakt_id: "293826",
    imdb_id: "tt5177088",
    tmdb_id: "446807",
    url: "https://trakt.tv/movies/the-girl-in-the-spider-s-web-2018",
    released: "2018-11-09",
    runtime: "115",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-18T04:51:15Z": {
    type: "movie",
    title: "The Girl Who Kicked the Hornet's Nest",
    year: "2009",
    trakt_rating: "7.6446",
    trakt_id: "21547",
    imdb_id: "tt1343097",
    tmdb_id: "33613",
    url: "https://trakt.tv/movies/the-girl-who-kicked-the-hornet-s-nest-2009",
    released: "2009-11-27",
    runtime: "147",
    genres: "action,crime,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-18T04:50:58Z": {
    type: "movie",
    title: "The Girl Who Played with Fire",
    year: "2009",
    trakt_rating: "7.54759",
    trakt_id: "14582",
    imdb_id: "tt1216487",
    tmdb_id: "24253",
    url: "https://trakt.tv/movies/the-girl-who-played-with-fire-2009",
    released: "2009-09-18",
    runtime: "130",
    genres: "action,crime,drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-18T04:48:28Z": {
    type: "movie",
    title: "The Girl with the Dragon Tattoo",
    year: "2009",
    trakt_rating: "7.90805",
    trakt_id: "9400",
    imdb_id: "tt1132620",
    tmdb_id: "15472",
    url: "https://trakt.tv/movies/the-girl-with-the-dragon-tattoo-2009",
    released: "2009-02-27",
    runtime: "152",
    genres: "crime,drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-15T02:58:10Z": {
    type: "movie",
    title: "Bohemian Rhapsody",
    year: "2018",
    trakt_rating: "8.15379",
    trakt_id: "300068",
    imdb_id: "tt1727824",
    tmdb_id: "424694",
    url: "https://trakt.tv/movies/bohemian-rhapsody-2018",
    released: "2018-11-02",
    runtime: "135",
    genres: "drama,music,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-14T02:23:25Z": {
    type: "movie",
    title: "Murder by Death",
    year: "1976",
    trakt_rating: "7.55464",
    trakt_id: "3457",
    imdb_id: "tt0074937",
    tmdb_id: "6037",
    url: "https://trakt.tv/movies/murder-by-death-1976",
    released: "1976-06-23",
    runtime: "94",
    genres: "comedy,crime,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-13T03:44:12Z": {
    type: "movie",
    title: "Suspiria",
    year: "2018",
    trakt_rating: "6.83612",
    trakt_id: "259044",
    imdb_id: "tt1034415",
    tmdb_id: "361292",
    url: "https://trakt.tv/movies/suspiria-2018",
    released: "2018-10-26",
    runtime: "152",
    genres: "horror,thriller,fantasy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-13T00:47:22Z": {
    type: "movie",
    title: "First Man",
    year: "2018",
    trakt_rating: "7.33957",
    trakt_id: "277750",
    imdb_id: "tt1213641",
    tmdb_id: "369972",
    url: "https://trakt.tv/movies/first-man-2018",
    released: "2018-10-12",
    runtime: "141",
    genres: "history,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-10T05:29:48Z": {
    type: "movie",
    title: "The Old Man & the Gun",
    year: "2018",
    trakt_rating: "6.98158",
    trakt_id: "295265",
    imdb_id: "tt2837574",
    tmdb_id: "429203",
    url: "https://trakt.tv/movies/the-old-man-the-gun-2018",
    released: "2018-09-28",
    runtime: "94",
    genres: "comedy,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2019-01-02T02:42:05Z": {
    type: "movie",
    title: "Black Mirror: Bandersnatch",
    year: "2018",
    trakt_rating: "7.00401",
    trakt_id: "421031",
    imdb_id: "tt9495224",
    tmdb_id: "569547",
    url: "https://trakt.tv/movies/black-mirror-bandersnatch-2018",
    released: "2018-12-28",
    runtime: "90",
    genres: "science-fiction,mystery,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-27T00:40:19Z": {
    type: "movie",
    title: "Chappaquiddick",
    year: "2018",
    trakt_rating: "6.85598",
    trakt_id: "280272",
    imdb_id: "tt5270948",
    tmdb_id: "432301",
    url: "https://trakt.tv/movies/chappaquiddick-2018",
    released: "2018-04-06",
    runtime: "107",
    genres: "drama,thriller,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-26T18:38:59Z": {
    type: "movie",
    title: "The House with a Clock in Its Walls",
    year: "2018",
    trakt_rating: "6.92685",
    trakt_id: "311024",
    imdb_id: "tt2119543",
    tmdb_id: "463821",
    url: "https://trakt.tv/movies/the-house-with-a-clock-in-its-walls-2018",
    released: "2018-09-21",
    runtime: "105",
    genres: "fantasy,family,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-25T03:40:33Z": {
    type: "movie",
    title: "Replicas",
    year: "2018",
    trakt_rating: "6.52442",
    trakt_id: "237986",
    imdb_id: "tt4154916",
    tmdb_id: "300681",
    url: "https://trakt.tv/movies/replicas-2018",
    released: "2019-01-11",
    runtime: "107",
    genres: "thriller,science-fiction,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-21T16:53:56Z": {
    type: "movie",
    title: "Bird Box",
    year: "2018",
    trakt_rating: "7.0033",
    trakt_id: "250049",
    imdb_id: "tt2737304",
    tmdb_id: "405774",
    url: "https://trakt.tv/movies/bird-box-2018",
    released: "2018-12-21",
    runtime: "124",
    genres: "thriller,drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-20T05:36:35Z": {
    type: "movie",
    title: "Halloween",
    year: "2018",
    trakt_rating: "6.80451",
    trakt_id: "269236",
    imdb_id: "tt1502407",
    tmdb_id: "424139",
    url: "https://trakt.tv/movies/halloween-2018",
    released: "2018-10-19",
    runtime: "106",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-19T10:41:36Z": {
    type: "movie",
    title: "Fahrenheit 11/9",
    year: "2018",
    trakt_rating: "7.56307",
    trakt_id: "384853",
    imdb_id: "tt8632862",
    tmdb_id: "532908",
    url: "https://trakt.tv/movies/fahrenheit-11-9-2018",
    released: "2018-09-10",
    runtime: "128",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-19T10:29:35Z": {
    type: "movie",
    title: "White Boy Rick",
    year: "2018",
    trakt_rating: "7.07613",
    trakt_id: "284950",
    imdb_id: "tt4537896",
    tmdb_id: "438808",
    url: "https://trakt.tv/movies/white-boy-rick-2018",
    released: "2018-09-14",
    runtime: "111",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-15T02:57:26Z": {
    type: "movie",
    title: "The House That Jack Built",
    year: "2018",
    trakt_rating: "6.83154",
    trakt_id: "241164",
    imdb_id: "tt4003440",
    tmdb_id: "398173",
    url: "https://trakt.tv/movies/the-house-that-jack-built-2018",
    released: "2018-11-29",
    runtime: "152",
    genres: "drama,horror,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-14T01:15:21Z": {
    type: "movie",
    title: "Once Upon a Time in China II",
    year: "1992",
    trakt_rating: "7.64388",
    trakt_id: "5793",
    imdb_id: "tt0105839",
    tmdb_id: "10618",
    url: "https://trakt.tv/movies/once-upon-a-time-in-china-ii-1992",
    released: "1992-04-16",
    runtime: "113",
    genres: "action,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-14T01:15:09Z": {
    type: "movie",
    title: "Once Upon a Time in China",
    year: "1991",
    trakt_rating: "7.37255",
    trakt_id: "5792",
    imdb_id: "tt0103285",
    tmdb_id: "10617",
    url: "https://trakt.tv/movies/once-upon-a-time-in-china-1991",
    released: "1991-08-15",
    runtime: "134",
    genres: "action,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-10T15:41:57Z": {
    type: "movie",
    title: "Charlotte's Web",
    year: "2006",
    trakt_rating: "6.65692",
    trakt_id: "5212",
    imdb_id: "tt0413895",
    tmdb_id: "9986",
    url: "https://trakt.tv/movies/charlotte-s-web-2006",
    released: "2006-12-15",
    runtime: "97",
    genres: "comedy,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-10T03:23:22Z": {
    type: "movie",
    title: "Mowgli: Legend of the Jungle",
    year: "2018",
    trakt_rating: "6.96795",
    trakt_id: "260941",
    imdb_id: "tt2388771",
    tmdb_id: "407436",
    url: "https://trakt.tv/movies/mowgli-legend-of-the-jungle-2018",
    released: "2018-11-29",
    runtime: "105",
    genres: "adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-03T18:22:23Z": {
    type: "movie",
    title: "Smallfoot",
    year: "2018",
    trakt_rating: "7.3122",
    trakt_id: "304151",
    imdb_id: "tt6182908",
    tmdb_id: "446894",
    url: "https://trakt.tv/movies/smallfoot-2018",
    released: "2018-09-28",
    runtime: "96",
    genres: "animation,family,adventure,fantasy,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-12-01T02:28:35Z": {
    type: "movie",
    title: "Mazinger Z: Infinity",
    year: "2017",
    trakt_rating: "6.58681",
    trakt_id: "299470",
    imdb_id: "tt6470762",
    tmdb_id: "452970",
    url: "https://trakt.tv/movies/mazinger-z-infinity-2017",
    released: "2018-01-13",
    runtime: "95",
    genres: "science-fiction,action,anime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-29T04:19:49Z": {
    type: "movie",
    title: "The Nun",
    year: "2018",
    trakt_rating: "6.04617",
    trakt_id: "285153",
    imdb_id: "tt5814060",
    tmdb_id: "439079",
    url: "https://trakt.tv/movies/the-nun-2018",
    released: "2018-09-07",
    runtime: "96",
    genres: "horror,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-29T04:14:38Z": {
    type: "movie",
    title: "Venom",
    year: "2018",
    trakt_rating: "7.11602",
    trakt_id: "216715",
    imdb_id: "tt1270797",
    tmdb_id: "335983",
    url: "https://trakt.tv/movies/venom-2018",
    released: "2018-10-05",
    runtime: "112",
    genres: "science-fiction,superhero,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-28T02:48:05Z": {
    type: "movie",
    title: "Searching",
    year: "2018",
    trakt_rating: "7.78041",
    trakt_id: "336912",
    imdb_id: "tt7668870",
    tmdb_id: "489999",
    url: "https://trakt.tv/movies/searching-2018",
    released: "2018-08-24",
    runtime: "102",
    genres: "thriller,mystery,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-28T02:45:03Z": {
    type: "movie",
    title: "The Predator",
    year: "2018",
    trakt_rating: "6.23582",
    trakt_id: "222316",
    imdb_id: "tt3829266",
    tmdb_id: "346910",
    url: "https://trakt.tv/movies/the-predator-2018",
    released: "2018-09-14",
    runtime: "107",
    genres: "science-fiction,action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-25T13:17:20Z": {
    type: "movie",
    title: "The Grandmaster",
    year: "2013",
    trakt_rating: "6.74687",
    trakt_id: "30505",
    imdb_id: "tt1462900",
    tmdb_id: "44865",
    url: "https://trakt.tv/movies/the-grandmaster-2013",
    released: "2013-01-10",
    runtime: "130",
    genres: "action,drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-25T00:32:34Z": {
    type: "movie",
    title: "Friday the 13th",
    year: "1980",
    trakt_rating: "6.73531",
    trakt_id: "2636",
    imdb_id: "tt0080761",
    tmdb_id: "4488",
    url: "https://trakt.tv/movies/friday-the-13th-1980",
    released: "1980-05-09",
    runtime: "95",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-25T00:29:24Z": {
    type: "movie",
    title: "Indiana Jones and the Kingdom of the Crystal Skull",
    year: "2008",
    trakt_rating: "6.39303",
    trakt_id: "176",
    imdb_id: "tt0367882",
    tmdb_id: "217",
    url: "https://trakt.tv/movies/indiana-jones-and-the-kingdom-of-the-crystal-skull-2008",
    released: "2008-05-22",
    runtime: "122",
    genres: "action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-25T00:29:21Z": {
    type: "movie",
    title: "Indiana Jones and the Temple of Doom",
    year: "1984",
    trakt_rating: "7.79221",
    trakt_id: "56",
    imdb_id: "tt0087469",
    tmdb_id: "87",
    url: "https://trakt.tv/movies/indiana-jones-and-the-temple-of-doom-1984",
    released: "1984-05-23",
    runtime: "118",
    genres: "action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-25T00:29:13Z": {
    type: "movie",
    title: "Indiana Jones and the Last Crusade",
    year: "1989",
    trakt_rating: "8.28162",
    trakt_id: "58",
    imdb_id: "tt0097576",
    tmdb_id: "89",
    url: "https://trakt.tv/movies/indiana-jones-and-the-last-crusade-1989",
    released: "1989-05-24",
    runtime: "127",
    genres: "action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-24T20:27:51Z": {
    type: "movie",
    title: "Iron Monkey",
    year: "1993",
    trakt_rating: "7.82648",
    trakt_id: "7501",
    imdb_id: "tt0108148",
    tmdb_id: "12780",
    url: "https://trakt.tv/movies/iron-monkey-1993",
    released: "1993-09-03",
    runtime: "90",
    genres: "action,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-24T20:16:07Z": {
    type: "movie",
    title: "Captain Fantastic",
    year: "2016",
    trakt_rating: "7.95983",
    trakt_id: "225172",
    imdb_id: "tt3553976",
    tmdb_id: "334533",
    url: "https://trakt.tv/movies/captain-fantastic-2016",
    released: "2016-07-08",
    runtime: "118",
    genres: "drama,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-24T02:12:50Z": {
    type: "movie",
    title: "Cloudy with a Chance of Meatballs",
    year: "2009",
    trakt_rating: "7.23251",
    trakt_id: "13845",
    imdb_id: "tt0844471",
    tmdb_id: "22794",
    url: "https://trakt.tv/movies/cloudy-with-a-chance-of-meatballs-2009",
    released: "2009-09-18",
    runtime: "90",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-24T02:12:41Z": {
    type: "movie",
    title: "Cloudy with a Chance of Meatballs 2",
    year: "2013",
    trakt_rating: "7.08276",
    trakt_id: "77353",
    imdb_id: "tt1985966",
    tmdb_id: "109451",
    url: "https://trakt.tv/movies/cloudy-with-a-chance-of-meatballs-2-2013",
    released: "2013-09-27",
    runtime: "95",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-18T01:31:59Z": {
    type: "movie",
    title: "Alpha",
    year: "2018",
    trakt_rating: "7.21917",
    trakt_id: "268719",
    imdb_id: "tt4244998",
    tmdb_id: "399360",
    url: "https://trakt.tv/movies/alpha-2018",
    released: "2018-08-17",
    runtime: "97",
    genres: "adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-18T01:26:09Z": {
    type: "movie",
    title: "Crazy Rich Asians",
    year: "2018",
    trakt_rating: "7.32734",
    trakt_id: "301543",
    imdb_id: "tt3104988",
    tmdb_id: "455207",
    url: "https://trakt.tv/movies/crazy-rich-asians-2018",
    released: "2018-08-15",
    runtime: "121",
    genres: "comedy,drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-18T01:13:48Z": {
    type: "movie",
    title: "Return to the 36th Chamber",
    year: "1980",
    trakt_rating: "7.03448",
    trakt_id: "33436",
    imdb_id: "tt0081494",
    tmdb_id: "48301",
    url: "https://trakt.tv/movies/return-to-the-36th-chamber-1980",
    released: "1980-08-24",
    runtime: "99",
    genres: "action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-18T01:12:28Z": {
    type: "movie",
    title: "The 36th Chamber of Shaolin",
    year: "1978",
    trakt_rating: "7.70383",
    trakt_id: "6864",
    imdb_id: "tt0078243",
    tmdb_id: "11841",
    url: "https://trakt.tv/movies/the-36th-chamber-of-shaolin-1978",
    released: "1978-02-02",
    runtime: "115",
    genres: "action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-18T01:11:34Z": {
    type: "movie",
    title: "Disciples of the 36th Chamber",
    year: "1985",
    trakt_rating: "6.78022",
    trakt_id: "35374",
    imdb_id: "tt0089029",
    tmdb_id: "50571",
    url: "https://trakt.tv/movies/disciples-of-the-36th-chamber-1985",
    released: "1985-05-17",
    runtime: "89",
    genres: "action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-18T00:59:16Z": {
    type: "movie",
    title: "The Brave Archer 2",
    year: "1978",
    trakt_rating: "7.5",
    trakt_id: "54221",
    imdb_id: "tt0078250",
    tmdb_id: "73751",
    url: "https://trakt.tv/movies/the-brave-archer-2-1978",
    released: "1978-05-13",
    runtime: "110",
    genres: "action,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-17T02:48:47Z": {
    type: "movie",
    title: "South Park: Bigger, Longer & Uncut",
    year: "1999",
    trakt_rating: "7.72852",
    trakt_id: "4759",
    imdb_id: "tt0158983",
    tmdb_id: "9473",
    url: "https://trakt.tv/movies/south-park-bigger-longer-uncut-1999",
    released: "1999-06-30",
    runtime: "81",
    genres: "animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-16T17:17:58Z": {
    type: "movie",
    title: "The Outlaws",
    year: "2017",
    trakt_rating: "7.42727",
    trakt_id: "326888",
    imdb_id: "tt7468056",
    tmdb_id: "479718",
    url: "https://trakt.tv/movies/the-outlaws-2017",
    released: "2017-10-03",
    runtime: "121",
    genres: "action,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-12T01:39:45Z": {
    type: "movie",
    title: "The Fountain",
    year: "2006",
    trakt_rating: "7.1632",
    trakt_id: "920",
    imdb_id: "tt0414993",
    tmdb_id: "1381",
    url: "https://trakt.tv/movies/the-fountain-2006",
    released: "2006-11-22",
    runtime: "96",
    genres: "adventure,drama,science-fiction,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-11T06:30:56Z": {
    type: "movie",
    title: "Phantasm",
    year: "1979",
    trakt_rating: "6.99131",
    trakt_id: "4914",
    imdb_id: "tt0079714",
    tmdb_id: "9638",
    url: "https://trakt.tv/movies/phantasm-1979",
    released: "1979-03-28",
    runtime: "89",
    genres: "horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-10T03:52:04Z": {
    type: "movie",
    title: "Sorry to Bother You",
    year: "2018",
    trakt_rating: "7.03415",
    trakt_id: "269566",
    imdb_id: "tt5688932",
    tmdb_id: "424781",
    url: "https://trakt.tv/movies/sorry-to-bother-you-2018",
    released: "2018-07-06",
    runtime: "112",
    genres: "science-fiction,comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-10T03:51:25Z": {
    type: "movie",
    title: "Prince of Darkness",
    year: "1987",
    trakt_rating: "6.98116",
    trakt_id: "4343",
    imdb_id: "tt0093777",
    tmdb_id: "8852",
    url: "https://trakt.tv/movies/prince-of-darkness-1987",
    released: "1987-10-23",
    runtime: "101",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-09T03:20:24Z": {
    type: "movie",
    title: "True Legend",
    year: "2010",
    trakt_rating: "6.86905",
    trakt_id: "23631",
    imdb_id: "tt1425257",
    tmdb_id: "37034",
    url: "https://trakt.tv/movies/true-legend-2010",
    released: "2011-05-13",
    runtime: "115",
    genres: "action,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-09T00:53:51Z": {
    type: "movie",
    title: "The Matrix Reloaded",
    year: "2003",
    trakt_rating: "7.55865",
    trakt_id: "482",
    imdb_id: "tt0234215",
    tmdb_id: "604",
    url: "https://trakt.tv/movies/the-matrix-reloaded-2003",
    released: "2003-05-15",
    runtime: "138",
    genres: "action,adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-09T00:53:42Z": {
    type: "movie",
    title: "The Matrix Revolutions",
    year: "2003",
    trakt_rating: "7.19061",
    trakt_id: "483",
    imdb_id: "tt0242653",
    tmdb_id: "605",
    url: "https://trakt.tv/movies/the-matrix-revolutions-2003",
    released: "2003-11-05",
    runtime: "129",
    genres: "action,adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-09T00:48:48Z": {
    type: "movie",
    title: "Invasion of the Body Snatchers",
    year: "1956",
    trakt_rating: "7.46449",
    trakt_id: "6630",
    imdb_id: "tt0049366",
    tmdb_id: "11549",
    url: "https://trakt.tv/movies/invasion-of-the-body-snatchers-1956",
    released: "1956-02-05",
    runtime: "80",
    genres: "horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-09T00:30:51Z": {
    type: "movie",
    title: "Go",
    year: "1999",
    trakt_rating: "7.33878",
    trakt_id: "4719",
    imdb_id: "tt0139239",
    tmdb_id: "9430",
    url: "https://trakt.tv/movies/go-1999",
    released: "1999-04-09",
    runtime: "98",
    genres: "comedy,thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-07T02:34:02Z": {
    type: "movie",
    title: "Blindspotting",
    year: "2018",
    trakt_rating: "7.57679",
    trakt_id: "337095",
    imdb_id: "tt7242142",
    tmdb_id: "489930",
    url: "https://trakt.tv/movies/blindspotting-2018",
    released: "2018-07-20",
    runtime: "95",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-07T00:54:39Z": {
    type: "movie",
    title: "Mission: Impossible - Fallout",
    year: "2018",
    trakt_rating: "7.70943",
    trakt_id: "222160",
    imdb_id: "tt4912910",
    tmdb_id: "353081",
    url: "https://trakt.tv/movies/mission-impossible-fallout-2018",
    released: "2018-07-27",
    runtime: "147",
    genres: "action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-07T00:46:49Z": {
    type: "movie",
    title: "Cube",
    year: "1997",
    trakt_rating: "7.22526",
    trakt_id: "343",
    imdb_id: "tt0123755",
    tmdb_id: "431",
    url: "https://trakt.tv/movies/cube-1997",
    released: "1997-09-09",
    runtime: "90",
    genres: "science-fiction,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-05T17:38:30Z": {
    type: "movie",
    title: "Planet of the Apes",
    year: "1968",
    trakt_rating: "7.72227",
    trakt_id: "714",
    imdb_id: "tt0063442",
    tmdb_id: "871",
    url: "https://trakt.tv/movies/planet-of-the-apes-1968",
    released: "1968-02-07",
    runtime: "112",
    genres: "adventure,drama,action,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-04T09:54:20Z": {
    type: "movie",
    title: "78/52",
    year: "2017",
    trakt_rating: "7.52427",
    trakt_id: "274009",
    imdb_id: "tt4372240",
    tmdb_id: "359749",
    url: "https://trakt.tv/movies/78-52-2017",
    released: "2017-10-13",
    runtime: "91",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-04T04:03:59Z": {
    type: "movie",
    title: "Independence Day",
    year: "1996",
    trakt_rating: "7.49199",
    trakt_id: "480",
    imdb_id: "tt0116629",
    tmdb_id: "602",
    url: "https://trakt.tv/movies/independence-day-1996",
    released: "1996-06-25",
    runtime: "145",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-04T03:48:15Z": {
    type: "movie",
    title: "Alien³",
    year: "1992",
    trakt_rating: "6.79316",
    trakt_id: "4044",
    imdb_id: "tt0103644",
    tmdb_id: "8077",
    url: "https://trakt.tv/movies/alien-1992",
    released: "1992-05-22",
    runtime: "114",
    genres: "action,horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-11-04T02:46:01Z": {
    type: "movie",
    title: "The Hustler",
    year: "1961",
    trakt_rating: "7.76168",
    trakt_id: "820",
    imdb_id: "tt0054997",
    tmdb_id: "990",
    url: "https://trakt.tv/movies/the-hustler-1961",
    released: "1961-09-25",
    runtime: "134",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-30T16:54:08Z": {
    type: "movie",
    title: "Papillon",
    year: "2017",
    trakt_rating: "7.57188",
    trakt_id: "280668",
    imdb_id: "tt5093026",
    tmdb_id: "433498",
    url: "https://trakt.tv/movies/papillon-2017",
    released: "2018-08-24",
    runtime: "134",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-30T14:05:06Z": {
    type: "movie",
    title: "Hubble",
    year: "2010",
    trakt_rating: "7.57057",
    trakt_id: "30614",
    imdb_id: "tt1433813",
    tmdb_id: "44992",
    url: "https://trakt.tv/movies/hubble-2010",
    released: "2010-03-19",
    runtime: "44",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-30T04:51:02Z": {
    type: "movie",
    title: "Enemy of the State",
    year: "1998",
    trakt_rating: "7.60949",
    trakt_id: "5057",
    imdb_id: "tt0120660",
    tmdb_id: "9798",
    url: "https://trakt.tv/movies/enemy-of-the-state-1998",
    released: "1998-11-20",
    runtime: "132",
    genres: "action,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-30T04:48:35Z": {
    type: "movie",
    title: "Pee-wee's Big Adventure",
    year: "1985",
    trakt_rating: "6.96663",
    trakt_id: "3210",
    imdb_id: "tt0089791",
    tmdb_id: "5683",
    url: "https://trakt.tv/movies/pee-wee-s-big-adventure-1985",
    released: "1985-07-26",
    runtime: "91",
    genres: "adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-30T01:17:22Z": {
    type: "movie",
    title: "A-X-L",
    year: "2018",
    trakt_rating: "6.74781",
    trakt_id: "284746",
    imdb_id: "tt5709188",
    tmdb_id: "438590",
    url: "https://trakt.tv/movies/a-x-l-2018",
    released: "2018-08-24",
    runtime: "98",
    genres: "science-fiction,action,adventure,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-28T14:03:22Z": {
    type: "movie",
    title: "Broken Arrow",
    year: "1996",
    trakt_rating: "6.51411",
    trakt_id: "4552",
    imdb_id: "tt0115759",
    tmdb_id: "9208",
    url: "https://trakt.tv/movies/broken-arrow-1996",
    released: "1996-02-09",
    runtime: "108",
    genres: "action,thriller,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-28T01:36:48Z": {
    type: "movie",
    title: "The Hills Have Eyes Part II",
    year: "1985",
    trakt_rating: "4.41667",
    trakt_id: "11318",
    imdb_id: "tt0089274",
    tmdb_id: "18477",
    url: "https://trakt.tv/movies/the-hills-have-eyes-part-ii-1985",
    released: "1985-08-02",
    runtime: "86",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-28T01:36:19Z": {
    type: "movie",
    title: "The Texas Chain Saw Massacre",
    year: "1974",
    trakt_rating: "7.28064",
    trakt_id: "19355",
    imdb_id: "tt0072271",
    tmdb_id: "30497",
    url: "https://trakt.tv/movies/the-texas-chain-saw-massacre-1974",
    released: "1974-10-01",
    runtime: "83",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-28T01:28:31Z": {
    type: "movie",
    title: "The Hills Have Eyes",
    year: "1977",
    trakt_rating: "6.4607",
    trakt_id: "7160",
    imdb_id: "tt0077681",
    tmdb_id: "12262",
    url: "https://trakt.tv/movies/the-hills-have-eyes-1977",
    released: "1977-07-22",
    runtime: "90",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-27T22:08:44Z": {
    type: "movie",
    title: "Scream",
    year: "1996",
    trakt_rating: "7.5003",
    trakt_id: "2481",
    imdb_id: "tt0117571",
    tmdb_id: "4232",
    url: "https://trakt.tv/movies/scream-1996",
    released: "1996-12-20",
    runtime: "112",
    genres: "crime,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-27T22:08:14Z": {
    type: "movie",
    title: "Last Exit to Brooklyn",
    year: "1989",
    trakt_rating: "6.91919",
    trakt_id: "7495",
    imdb_id: "tt0097714",
    tmdb_id: "12774",
    url: "https://trakt.tv/movies/last-exit-to-brooklyn-1989",
    released: "1990-05-04",
    runtime: "102",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-27T09:45:33Z": {
    type: "movie",
    title: "The Pink Panther Strikes Again",
    year: "1976",
    trakt_rating: "7.3798",
    trakt_id: "7163",
    imdb_id: "tt0075066",
    tmdb_id: "12268",
    url: "https://trakt.tv/movies/the-pink-panther-strikes-again-1976",
    released: "1976-12-15",
    runtime: "103",
    genres: "comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-27T09:42:33Z": {
    type: "movie",
    title: "The Return of the Pink Panther",
    year: "1975",
    trakt_rating: "7.32991",
    trakt_id: "6866",
    imdb_id: "tt0072081",
    tmdb_id: "11843",
    url: "https://trakt.tv/movies/the-return-of-the-pink-panther-1975",
    released: "1976-02-29",
    runtime: "113",
    genres: "comedy,mystery,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-27T06:32:30Z": {
    type: "movie",
    title: "Christopher Robin",
    year: "2018",
    trakt_rating: "7.56062",
    trakt_id: "265469",
    imdb_id: "tt4575576",
    tmdb_id: "420814",
    url: "https://trakt.tv/movies/christopher-robin-2018",
    released: "2018-08-03",
    runtime: "104",
    genres: "adventure,comedy,family,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-27T02:03:18Z": {
    type: "movie",
    title: "National Lampoon's European Vacation",
    year: "1985",
    trakt_rating: "6.72991",
    trakt_id: "6513",
    imdb_id: "tt0089670",
    tmdb_id: "11418",
    url: "https://trakt.tv/movies/national-lampoon-s-european-vacation-1985",
    released: "1985-07-25",
    runtime: "95",
    genres: "adventure,comedy,romance,game-show",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-27T02:00:32Z": {
    type: "movie",
    title: "National Lampoon's Christmas Vacation",
    year: "1989",
    trakt_rating: "8.0096",
    trakt_id: "3309",
    imdb_id: "tt0097958",
    tmdb_id: "5825",
    url: "https://trakt.tv/movies/national-lampoon-s-christmas-vacation-1989",
    released: "1989-12-01",
    runtime: "97",
    genres: "comedy,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-27T02:00:30Z": {
    type: "movie",
    title: "National Lampoon's Vacation",
    year: "1983",
    trakt_rating: "7.53282",
    trakt_id: "6282",
    imdb_id: "tt0085995",
    tmdb_id: "11153",
    url: "https://trakt.tv/movies/national-lampoon-s-vacation-1983",
    released: "1983-07-28",
    runtime: "99",
    genres: "adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-27T00:30:21Z": {
    type: "movie",
    title: "Return of the One-Armed Swordsman",
    year: "1969",
    trakt_rating: "7.29032",
    trakt_id: "33435",
    imdb_id: "tt0064265",
    tmdb_id: "48300",
    url: "https://trakt.tv/movies/return-of-the-one-armed-swordsman-1969",
    released: "1969-02-28",
    runtime: "106",
    genres: "action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-27T00:09:10Z": {
    type: "movie",
    title: "Submarine",
    year: "2011",
    trakt_rating: "7.5941",
    trakt_id: "34069",
    imdb_id: "tt1440292",
    tmdb_id: "49020",
    url: "https://trakt.tv/movies/submarine-2011",
    released: "2011-06-03",
    runtime: "97",
    genres: "comedy,drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-26T02:14:56Z": {
    type: "movie",
    title: "The Phantom of the Opera",
    year: "1925",
    trakt_rating: "7.31475",
    trakt_id: "800",
    imdb_id: "tt0016220",
    tmdb_id: "964",
    url: "https://trakt.tv/movies/the-phantom-of-the-opera-1925",
    released: "1925-11-15",
    runtime: "107",
    genres: "drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-26T02:12:18Z": {
    type: "movie",
    title: "Frankenstein",
    year: "1931",
    trakt_rating: "7.41556",
    trakt_id: "2018",
    imdb_id: "tt0021884",
    tmdb_id: "3035",
    url: "https://trakt.tv/movies/frankenstein-1931",
    released: "1931-11-19",
    runtime: "70",
    genres: "horror,science-fiction,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-26T02:10:03Z": {
    type: "movie",
    title: "Dracula",
    year: "1931",
    trakt_rating: "7.17277",
    trakt_id: "104",
    imdb_id: "tt0021814",
    tmdb_id: "138",
    url: "https://trakt.tv/movies/dracula-1931",
    released: "1931-02-14",
    runtime: "74",
    genres: "horror,fantasy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-26T01:39:12Z": {
    type: "movie",
    title: "The Hit",
    year: "1984",
    trakt_rating: "6.95152",
    trakt_id: "12081",
    imdb_id: "tt0087414",
    tmdb_id: "19621",
    url: "https://trakt.tv/movies/the-hit-1984",
    released: "1994-09-07",
    runtime: "98",
    genres: "crime,drama,thriller,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-24T02:11:36Z": {
    type: "movie",
    title: "BlacKkKlansman",
    year: "2018",
    trakt_rating: "7.65268",
    trakt_id: "334724",
    imdb_id: "tt7349662",
    tmdb_id: "487558",
    url: "https://trakt.tv/movies/blackkklansman-2018",
    released: "2018-08-10",
    runtime: "135",
    genres: "crime,drama,history,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-24T00:43:22Z": {
    type: "movie",
    title: "Creepshow",
    year: "1982",
    trakt_rating: "7.09725",
    trakt_id: "10012",
    imdb_id: "tt0083767",
    tmdb_id: "16281",
    url: "https://trakt.tv/movies/creepshow-1982",
    released: "1982-11-12",
    runtime: "120",
    genres: "comedy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-24T00:14:03Z": {
    type: "movie",
    title: "Home Alone",
    year: "1990",
    trakt_rating: "7.79197",
    trakt_id: "614",
    imdb_id: "tt0099785",
    tmdb_id: "771",
    url: "https://trakt.tv/movies/home-alone-1990",
    released: "1990-11-16",
    runtime: "103",
    genres: "comedy,family,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-23T23:07:35Z": {
    type: "movie",
    title: "Cinema Paradiso",
    year: "1988",
    trakt_rating: "8.48275",
    trakt_id: "6342",
    imdb_id: "tt0095765",
    tmdb_id: "11216",
    url: "https://trakt.tv/movies/cinema-paradiso-1988",
    released: "1989-09-20",
    runtime: "124",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-22T23:50:04Z": {
    type: "movie",
    title: "Incredibles 2",
    year: "2018",
    trakt_rating: "7.76894",
    trakt_id: "159626",
    imdb_id: "tt3606756",
    tmdb_id: "260513",
    url: "https://trakt.tv/movies/incredibles-2-2018",
    released: "2018-06-15",
    runtime: "118",
    genres: "animation,family,action,adventure,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-22T03:23:36Z": {
    type: "movie",
    title: "A Prophet",
    year: "2009",
    trakt_rating: "7.81127",
    trakt_id: "13203",
    imdb_id: "tt1235166",
    tmdb_id: "21575",
    url: "https://trakt.tv/movies/a-prophet-2009",
    released: "2009-08-26",
    runtime: "155",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-22T03:16:45Z": {
    type: "movie",
    title: "The Dark",
    year: "2018",
    trakt_rating: "6.29268",
    trakt_id: "310523",
    imdb_id: "tt6832388",
    tmdb_id: "463322",
    url: "https://trakt.tv/movies/the-dark-2018",
    released: "2018-10-12",
    runtime: "95",
    genres: "horror,drama,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-22T01:24:56Z": {
    type: "movie",
    title: "Beetlejuice",
    year: "1988",
    trakt_rating: "7.73714",
    trakt_id: "2408",
    imdb_id: "tt0094721",
    tmdb_id: "4011",
    url: "https://trakt.tv/movies/beetlejuice-1988",
    released: "1988-03-30",
    runtime: "92",
    genres: "comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-21T12:47:10Z": {
    type: "movie",
    title: "Blue Ruin",
    year: "2014",
    trakt_rating: "7.05569",
    trakt_id: "117788",
    imdb_id: "tt2359024",
    tmdb_id: "188166",
    url: "https://trakt.tv/movies/blue-ruin-2014",
    released: "2014-04-25",
    runtime: "91",
    genres: "thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-21T06:05:54Z": {
    type: "movie",
    title: "Marrowbone",
    year: "2017",
    trakt_rating: "7.13415",
    trakt_id: "259512",
    imdb_id: "tt5886440",
    tmdb_id: "399366",
    url: "https://trakt.tv/movies/marrowbone-2017",
    released: "2018-04-13",
    runtime: "111",
    genres: "drama,thriller,mystery,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-19T22:38:45Z": {
    type: "movie",
    title: "Rampage",
    year: "2018",
    trakt_rating: "6.85204",
    trakt_id: "271850",
    imdb_id: "tt2231461",
    tmdb_id: "427641",
    url: "https://trakt.tv/movies/rampage-2018",
    released: "2018-04-13",
    runtime: "107",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-19T16:06:30Z": {
    type: "movie",
    title: "The Night Comes for Us",
    year: "2018",
    trakt_rating: "7.15208",
    trakt_id: "296711",
    imdb_id: "tt6116856",
    tmdb_id: "449992",
    url: "https://trakt.tv/movies/the-night-comes-for-us-2018",
    released: "2018-11-17",
    runtime: "121",
    genres: "action,thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-18T02:51:39Z": {
    type: "movie",
    title: "LEGO DC Comics Super Heroes: Justice League vs. Bizarro League",
    year: "2015",
    trakt_rating: "6.71006",
    trakt_id: "203102",
    imdb_id: "tt4189260",
    tmdb_id: "322456",
    url: "https://trakt.tv/movies/lego-dc-comics-super-heroes-justice-league-vs-bizarro-league-2015",
    released: "2015-01-27",
    runtime: "48",
    genres: "action,adventure,animation,family,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-18T01:09:16Z": {
    type: "movie",
    title:
      "LEGO DC Comics Super Heroes: Justice League - Attack of the Legion of Doom!",
    year: "2015",
    trakt_rating: "6.84566",
    trakt_id: "222215",
    imdb_id: "tt4938416",
    tmdb_id: "353595",
    url: "https://trakt.tv/movies/lego-dc-comics-super-heroes-justice-league-attack-of-the-legion-of-doom-2015",
    released: "2015-08-18",
    runtime: "77",
    genres: "family,animation,action,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-17T20:25:14Z": {
    type: "movie",
    title: "Shine",
    year: "1996",
    trakt_rating: "7.60199",
    trakt_id: "3968",
    imdb_id: "tt0117631",
    tmdb_id: "7863",
    url: "https://trakt.tv/movies/shine-1996",
    released: "1996-08-15",
    runtime: "105",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-17T20:22:19Z": {
    type: "movie",
    title: "The Edge",
    year: "1997",
    trakt_rating: "7.27238",
    trakt_id: "4722",
    imdb_id: "tt0119051",
    tmdb_id: "9433",
    url: "https://trakt.tv/movies/the-edge-1997",
    released: "1997-09-26",
    runtime: "117",
    genres: "action,adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-17T20:20:33Z": {
    type: "movie",
    title: "Lego Batman: The Movie - DC Super Heroes Unite",
    year: "2013",
    trakt_rating: "7.29412",
    trakt_id: "112278",
    imdb_id: "tt2465238",
    tmdb_id: "177271",
    url: "https://trakt.tv/movies/lego-batman-the-movie-dc-super-heroes-unite-2013",
    released: "2013-05-21",
    runtime: "71",
    genres: "adventure,animation,family,superhero,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-17T15:40:01Z": {
    type: "movie",
    title: "Tremors",
    year: "1990",
    trakt_rating: "7.29124",
    trakt_id: "4654",
    imdb_id: "tt0100814",
    tmdb_id: "9362",
    url: "https://trakt.tv/movies/tremors-1990",
    released: "1990-01-19",
    runtime: "96",
    genres: "action,horror,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-17T13:01:59Z": {
    type: "movie",
    title: "The House of the Devil",
    year: "2009",
    trakt_rating: "6.34701",
    trakt_id: "15765",
    imdb_id: "tt1172994",
    tmdb_id: "25983",
    url: "https://trakt.tv/movies/the-house-of-the-devil-2009",
    released: "2009-10-30",
    runtime: "95",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-17T00:15:27Z": {
    type: "movie",
    title: "Silkwood",
    year: "1983",
    trakt_rating: "7.34226",
    trakt_id: "7275",
    imdb_id: "tt0086312",
    tmdb_id: "12502",
    url: "https://trakt.tv/movies/silkwood-1983",
    released: "1983-12-14",
    runtime: "131",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-17T00:15:13Z": {
    type: "movie",
    title: "The Young Poisoner's Handbook",
    year: "1995",
    trakt_rating: "6.69565",
    trakt_id: "10368",
    imdb_id: "tt0115033",
    tmdb_id: "16934",
    url: "https://trakt.tv/movies/the-young-poisoner-s-handbook-1995",
    released: "1997-03-25",
    runtime: "99",
    genres: "crime,drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-17T00:14:58Z": {
    type: "movie",
    title: "Shaolin Temple",
    year: "1982",
    trakt_rating: "6.85577",
    trakt_id: "5483",
    imdb_id: "tt0079891",
    tmdb_id: "10275",
    url: "https://trakt.tv/movies/shaolin-temple-1982",
    released: "1982-01-21",
    runtime: "100",
    genres: "action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-13T12:37:09Z": {
    type: "movie",
    title: "The Man Who Would Be King",
    year: "1975",
    trakt_rating: "7.67521",
    trakt_id: "813",
    imdb_id: "tt0073341",
    tmdb_id: "983",
    url: "https://trakt.tv/movies/the-man-who-would-be-king-1975",
    released: "1975-12-17",
    runtime: "129",
    genres: "adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-13T02:19:19Z": {
    type: "movie",
    title: "Look Away",
    year: "2018",
    trakt_rating: "6.34777",
    trakt_id: "374262",
    imdb_id: "tt5834760",
    tmdb_id: "526051",
    url: "https://trakt.tv/movies/look-away-2018",
    released: "2018-10-12",
    runtime: "103",
    genres: "horror,thriller,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-13T02:11:08Z": {
    type: "movie",
    title: "Apostle",
    year: "2018",
    trakt_rating: "6.49925",
    trakt_id: "269372",
    imdb_id: "tt6217306",
    tmdb_id: "424121",
    url: "https://trakt.tv/movies/apostle-2018",
    released: "2018-10-12",
    runtime: "130",
    genres: "thriller,mystery,horror,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-12T15:17:16Z": {
    type: "movie",
    title: "A Bittersweet Life",
    year: "2005",
    trakt_rating: "7.64623",
    trakt_id: "6440",
    imdb_id: "tt0456912",
    tmdb_id: "11344",
    url: "https://trakt.tv/movies/a-bittersweet-life-2005",
    released: "2005-04-01",
    runtime: "120",
    genres: "action,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-12T07:23:59Z": {
    type: "movie",
    title: "The Night Strangler",
    year: "1973",
    trakt_rating: "7.37079",
    trakt_id: "20552",
    imdb_id: "tt0069002",
    tmdb_id: "32021",
    url: "https://trakt.tv/movies/the-night-strangler-1973",
    released: "1973-01-16",
    runtime: "90",
    genres: "crime,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-10T23:50:28Z": {
    type: "movie",
    title: "The Night Stalker",
    year: "1972",
    trakt_rating: "7.21769",
    trakt_id: "9319",
    imdb_id: "tt0067490",
    tmdb_id: "15360",
    url: "https://trakt.tv/movies/the-night-stalker-1972",
    released: "1972-01-11",
    runtime: "74",
    genres: "horror,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-08T04:12:24Z": {
    type: "movie",
    title: "Along with the Gods: The Two Worlds",
    year: "2017",
    trakt_rating: "7.71188",
    trakt_id: "240475",
    imdb_id: "tt7160070",
    tmdb_id: "397567",
    url: "https://trakt.tv/movies/along-with-the-gods-the-two-worlds-2017",
    released: "2018-01-11",
    runtime: "139",
    genres: "fantasy,action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-05T20:52:01Z": {
    type: "movie",
    title: "Con Air",
    year: "1997",
    trakt_rating: "7.2187",
    trakt_id: "1138",
    imdb_id: "tt0118880",
    tmdb_id: "1701",
    url: "https://trakt.tv/movies/con-air-1997",
    released: "1997-06-06",
    runtime: "116",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-05T02:14:35Z": {
    type: "movie",
    title: "Ant-Man and the Wasp",
    year: "2018",
    trakt_rating: "7.35379",
    trakt_id: "223262",
    imdb_id: "tt5095030",
    tmdb_id: "363088",
    url: "https://trakt.tv/movies/ant-man-and-the-wasp-2018",
    released: "2018-07-06",
    runtime: "119",
    genres: "adventure,science-fiction,action,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-03T08:00:07Z": {
    type: "movie",
    title: "Hotel Transylvania 3: Summer Vacation",
    year: "2018",
    trakt_rating: "7.16774",
    trakt_id: "269585",
    imdb_id: "tt5220122",
    tmdb_id: "400155",
    url: "https://trakt.tv/movies/hotel-transylvania-3-summer-vacation-2018",
    released: "2018-06-30",
    runtime: "97",
    genres: "comedy,animation,family,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-03T08:00:04Z": {
    type: "movie",
    title: "Hotel Transylvania 2",
    year: "2015",
    trakt_rating: "7.24739",
    trakt_id: "103449",
    imdb_id: "tt2510894",
    tmdb_id: "159824",
    url: "https://trakt.tv/movies/hotel-transylvania-2-2015",
    released: "2015-09-25",
    runtime: "89",
    genres: "animation,comedy,family,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-03T08:00:02Z": {
    type: "movie",
    title: "Hotel Transylvania",
    year: "2012",
    trakt_rating: "7.39085",
    trakt_id: "56494",
    imdb_id: "tt0837562",
    tmdb_id: "76492",
    url: "https://trakt.tv/movies/hotel-transylvania-2012",
    released: "2012-09-28",
    runtime: "91",
    genres: "animation,comedy,fantasy,family,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-10-02T23:37:14Z": {
    type: "movie",
    title: "Charlie and the Chocolate Factory",
    year: "2005",
    trakt_rating: "6.97579",
    trakt_id: "87",
    imdb_id: "tt0367594",
    tmdb_id: "118",
    url: "https://trakt.tv/movies/charlie-and-the-chocolate-factory-2005",
    released: "2005-07-15",
    runtime: "115",
    genres: "adventure,comedy,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-30T06:25:25Z": {
    type: "movie",
    title: "sex, lies, and videotape",
    year: "1989",
    trakt_rating: "7.22012",
    trakt_id: "942",
    imdb_id: "tt0098724",
    tmdb_id: "1412",
    url: "https://trakt.tv/movies/sex-lies-and-videotape-1989",
    released: "1989-08-04",
    runtime: "101",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-30T02:35:17Z": {
    type: "movie",
    title: "Gotti",
    year: "2018",
    trakt_rating: "6.42332",
    trakt_id: "219570",
    imdb_id: "tt1801552",
    tmdb_id: "339103",
    url: "https://trakt.tv/movies/gotti-2018",
    released: "2018-06-15",
    runtime: "106",
    genres: "crime,drama,history,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-29T18:59:41Z": {
    type: "movie",
    title: "Blue Thunder",
    year: "1983",
    trakt_rating: "6.91221",
    trakt_id: "3580",
    imdb_id: "tt0085255",
    tmdb_id: "6341",
    url: "https://trakt.tv/movies/blue-thunder-1983",
    released: "1983-05-13",
    runtime: "110",
    genres: "action,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-22T19:39:35Z": {
    type: "movie",
    title: "Hercules",
    year: "1997",
    trakt_rating: "7.61316",
    trakt_id: "6983",
    imdb_id: "tt0119282",
    tmdb_id: "11970",
    url: "https://trakt.tv/movies/hercules-1997",
    released: "1997-06-27",
    runtime: "93",
    genres: "adventure,animation,comedy,fantasy,romance,family,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-22T18:07:27Z": {
    type: "movie",
    title: "The Sword in the Stone",
    year: "1963",
    trakt_rating: "7.4444",
    trakt_id: "4527",
    imdb_id: "tt0057546",
    tmdb_id: "9078",
    url: "https://trakt.tv/movies/the-sword-in-the-stone-1963",
    released: "1963-12-25",
    runtime: "79",
    genres: "animation,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-22T17:45:53Z": {
    type: "movie",
    title: "Robin Hood",
    year: "1973",
    trakt_rating: "7.60416",
    trakt_id: "6904",
    imdb_id: "tt0070608",
    tmdb_id: "11886",
    url: "https://trakt.tv/movies/robin-hood-1973",
    released: "1973-11-08",
    runtime: "83",
    genres: "animation,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-22T14:51:57Z": {
    type: "movie",
    title: "Pinocchio",
    year: "1940",
    trakt_rating: "7.2905",
    trakt_id: "6049",
    imdb_id: "tt0032910",
    tmdb_id: "10895",
    url: "https://trakt.tv/movies/pinocchio-1940",
    released: "1940-02-23",
    runtime: "88",
    genres: "animation,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-22T14:24:21Z": {
    type: "movie",
    title: "The Jungle Book",
    year: "1967",
    trakt_rating: "7.64089",
    trakt_id: "4622",
    imdb_id: "tt0061852",
    tmdb_id: "9325",
    url: "https://trakt.tv/movies/the-jungle-book-1967",
    released: "1967-10-18",
    runtime: "78",
    genres: "animation,family,adventure,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-22T14:19:10Z": {
    type: "movie",
    title: "Peter Pan",
    year: "1953",
    trakt_rating: "7.4164",
    trakt_id: "5864",
    imdb_id: "tt0046183",
    tmdb_id: "10693",
    url: "https://trakt.tv/movies/peter-pan-1953",
    released: "1953-02-05",
    runtime: "77",
    genres: "adventure,animation,fantasy,family,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-21T21:04:48Z": {
    type: "movie",
    title: "Sicario: Day of the Soldado",
    year: "2018",
    trakt_rating: "7.26923",
    trakt_id: "256522",
    imdb_id: "tt5052474",
    tmdb_id: "400535",
    url: "https://trakt.tv/movies/sicario-day-of-the-soldado-2018",
    released: "2018-06-29",
    runtime: "122",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-17T03:17:25Z": {
    type: "movie",
    title: "The Meg",
    year: "2018",
    trakt_rating: "6.42085",
    trakt_id: "224015",
    imdb_id: "tt4779682",
    tmdb_id: "345940",
    url: "https://trakt.tv/movies/the-meg-2018",
    released: "2018-08-10",
    runtime: "113",
    genres: "action,science-fiction,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-15T00:01:53Z": {
    type: "movie",
    title: "Mandy",
    year: "2018",
    trakt_rating: "6.44937",
    trakt_id: "308070",
    imdb_id: "tt6998518",
    tmdb_id: "460885",
    url: "https://trakt.tv/movies/mandy-2018",
    released: "2018-09-13",
    runtime: "122",
    genres: "horror,action,thriller,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-12T13:23:46Z": {
    type: "movie",
    title: "Robin Hood",
    year: "2010",
    trakt_rating: "6.78001",
    trakt_id: "12693",
    imdb_id: "tt0955308",
    tmdb_id: "20662",
    url: "https://trakt.tv/movies/robin-hood-2010",
    released: "2010-05-14",
    runtime: "140",
    genres: "adventure,action,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-11T23:41:56Z": {
    type: "movie",
    title: "Point Break",
    year: "1991",
    trakt_rating: "7.40052",
    trakt_id: "841",
    imdb_id: "tt0102685",
    tmdb_id: "1089",
    url: "https://trakt.tv/movies/point-break-1991",
    released: "1991-07-12",
    runtime: "122",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-11T07:55:40Z": {
    type: "movie",
    title: "Solo: A Star Wars Story",
    year: "2018",
    trakt_rating: "7.12606",
    trakt_id: "221890",
    imdb_id: "tt3778644",
    tmdb_id: "348350",
    url: "https://trakt.tv/movies/solo-a-star-wars-story-2018",
    released: "2018-05-25",
    runtime: "135",
    genres: "science-fiction,adventure,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-10T12:21:49Z": {
    type: "movie",
    title: "The Rocky Horror Picture Show",
    year: "1975",
    trakt_rating: "7.80581",
    trakt_id: "23323",
    imdb_id: "tt0073629",
    tmdb_id: "36685",
    url: "https://trakt.tv/movies/the-rocky-horror-picture-show-1975",
    released: "1975-09-29",
    runtime: "100",
    genres: "comedy,science-fiction,fantasy,horror,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-10T12:17:45Z": {
    type: "movie",
    title: "Rocky",
    year: "1976",
    trakt_rating: "7.93483",
    trakt_id: "905",
    imdb_id: "tt0075148",
    tmdb_id: "1366",
    url: "https://trakt.tv/movies/rocky-1976",
    released: "1976-11-21",
    runtime: "119",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-10T11:51:39Z": {
    type: "movie",
    title: "Nighthawks",
    year: "1981",
    trakt_rating: "6.59155",
    trakt_id: "13227",
    imdb_id: "tt0082817",
    tmdb_id: "21610",
    url: "https://trakt.tv/movies/nighthawks-1981",
    released: "1981-04-04",
    runtime: "99",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-09T03:05:31Z": {
    type: "movie",
    title: "Smokey and the Bandit",
    year: "1977",
    trakt_rating: "7.70984",
    trakt_id: "6144",
    imdb_id: "tt0076729",
    tmdb_id: "11006",
    url: "https://trakt.tv/movies/smokey-and-the-bandit-1977",
    released: "1977-05-27",
    runtime: "96",
    genres: "action,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-09T03:04:30Z": {
    type: "movie",
    title: "The Cannonball Run",
    year: "1981",
    trakt_rating: "7.20672",
    trakt_id: "6389",
    imdb_id: "tt0082136",
    tmdb_id: "11286",
    url: "https://trakt.tv/movies/the-cannonball-run-1981",
    released: "1981-06-19",
    runtime: "95",
    genres: "comedy,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-09T03:02:05Z": {
    type: "movie",
    title: "The Last Movie Star",
    year: "2018",
    trakt_rating: "6.86154",
    trakt_id: "297743",
    imdb_id: "tt5836316",
    tmdb_id: "451204",
    url: "https://trakt.tv/movies/the-last-movie-star-2018",
    released: "2018-03-30",
    runtime: "104",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-09T02:46:06Z": {
    type: "movie",
    title: "The Longest Yard",
    year: "1974",
    trakt_rating: "7.02395",
    trakt_id: "2916",
    imdb_id: "tt0071771",
    tmdb_id: "4985",
    url: "https://trakt.tv/movies/the-longest-yard-1974",
    released: "1974-08-21",
    runtime: "121",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-05T00:27:00Z": {
    type: "movie",
    title: "The Foreigner",
    year: "2017",
    trakt_rating: "7.43403",
    trakt_id: "225520",
    imdb_id: "tt1615160",
    tmdb_id: "379149",
    url: "https://trakt.tv/movies/the-foreigner-2017",
    released: "2017-10-13",
    runtime: "113",
    genres: "thriller,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-04T22:49:29Z": {
    type: "movie",
    title: "The Florida Project",
    year: "2017",
    trakt_rating: "7.49698",
    trakt_id: "264898",
    imdb_id: "tt5649144",
    tmdb_id: "394117",
    url: "https://trakt.tv/movies/the-florida-project-2017",
    released: "2017-10-06",
    runtime: "112",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-04T19:03:27Z": {
    type: "movie",
    title: "Jurassic World: Fallen Kingdom",
    year: "2018",
    trakt_rating: "6.8439",
    trakt_id: "222216",
    imdb_id: "tt4881806",
    tmdb_id: "351286",
    url: "https://trakt.tv/movies/jurassic-world-fallen-kingdom-2018",
    released: "2018-06-22",
    runtime: "129",
    genres: "action,adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-09-04T03:47:23Z": {
    type: "movie",
    title: "Brain on Fire",
    year: "2017",
    trakt_rating: "7.38318",
    trakt_id: "220489",
    imdb_id: "tt3704700",
    tmdb_id: "340027",
    url: "https://trakt.tv/movies/brain-on-fire-2017",
    released: "2017-02-22",
    runtime: "88",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-26T01:37:50Z": {
    type: "movie",
    title: "F/X",
    year: "1986",
    trakt_rating: "7.01779",
    trakt_id: "5110",
    imdb_id: "tt0089118",
    tmdb_id: "9873",
    url: "https://trakt.tv/movies/f-x-1986",
    released: "1986-02-07",
    runtime: "109",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-25T06:54:57Z": {
    type: "movie",
    title: "Ghost Stories",
    year: "2018",
    trakt_rating: "6.71924",
    trakt_id: "309646",
    imdb_id: "tt5516328",
    tmdb_id: "429417",
    url: "https://trakt.tv/movies/ghost-stories-2018",
    released: "2018-04-06",
    runtime: "98",
    genres: "drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-23T04:22:00Z": {
    type: "movie",
    title: "Won't You Be My Neighbor?",
    year: "2018",
    trakt_rating: "8.36003",
    trakt_id: "337204",
    imdb_id: "tt7681902",
    tmdb_id: "490003",
    url: "https://trakt.tv/movies/won-t-you-be-my-neighbor-2018",
    released: "2018-06-29",
    runtime: "95",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-21T23:29:19Z": {
    type: "movie",
    title: "Hereditary",
    year: "2018",
    trakt_rating: "7.18539",
    trakt_id: "340974",
    imdb_id: "tt7784604",
    tmdb_id: "493922",
    url: "https://trakt.tv/movies/hereditary-2018",
    released: "2018-06-08",
    runtime: "128",
    genres: "horror,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-16T00:57:50Z": {
    type: "movie",
    title: "Upgrade",
    year: "2018",
    trakt_rating: "7.60736",
    trakt_id: "347587",
    imdb_id: "tt6499752",
    tmdb_id: "500664",
    url: "https://trakt.tv/movies/upgrade-2018",
    released: "2018-06-01",
    runtime: "100",
    genres: "thriller,science-fiction,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-15T01:14:55Z": {
    type: "movie",
    title: "American Animals",
    year: "2018",
    trakt_rating: "7.11445",
    trakt_id: "337055",
    imdb_id: "tt6212478",
    tmdb_id: "489931",
    url: "https://trakt.tv/movies/american-animals-2018",
    released: "2018-06-01",
    runtime: "117",
    genres: "drama,crime,documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-09T03:06:33Z": {
    type: "movie",
    title: "The General",
    year: "1926",
    trakt_rating: "7.9276",
    trakt_id: "797",
    imdb_id: "tt0017925",
    tmdb_id: "961",
    url: "https://trakt.tv/movies/the-general-1926",
    released: "1926-12-25",
    runtime: "79",
    genres: "comedy,war,adventure,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-09T03:05:53Z": {
    type: "movie",
    title: "Safety Last!",
    year: "1923",
    trakt_rating: "7.9627",
    trakt_id: "13747",
    imdb_id: "tt0014429",
    tmdb_id: "22596",
    url: "https://trakt.tv/movies/safety-last-1923",
    released: "1923-04-01",
    runtime: "74",
    genres: "comedy,romance,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-08T00:03:07Z": {
    type: "movie",
    title: "Deadpool 2",
    year: "2018",
    trakt_rating: "7.92389",
    trakt_id: "226108",
    imdb_id: "tt5463162",
    tmdb_id: "383498",
    url: "https://trakt.tv/movies/deadpool-2-2018",
    released: "2018-05-10",
    runtime: "120",
    genres: "comedy,action,superhero,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:26:34Z": {
    type: "movie",
    title: "Bonanza Bunny",
    year: "1959",
    trakt_rating: "7.8",
    trakt_id: "143254",
    imdb_id: "tt0052641",
    tmdb_id: "234811",
    url: "https://trakt.tv/movies/bonanza-bunny-1959",
    released: "1959-09-05",
    runtime: "7",
    genres: "animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:26:17Z": {
    type: "movie",
    title: "Homeless Hare",
    year: "1950",
    trakt_rating: "7.26667",
    trakt_id: "72225",
    imdb_id: "tt0042573",
    tmdb_id: "100311",
    url: "https://trakt.tv/movies/homeless-hare-1950",
    released: "1950-03-11",
    runtime: "7",
    genres: "animation,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:25:56Z": {
    type: "movie",
    title: "Hyde and Hare",
    year: "1955",
    trakt_rating: "7.375",
    trakt_id: "63422",
    imdb_id: "tt0048186",
    tmdb_id: "84589",
    url: "https://trakt.tv/movies/hyde-and-hare-1955",
    released: "1955-08-27",
    runtime: "7",
    genres: "animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:25:54Z": {
    type: "movie",
    title: "Oily Hare",
    year: "1952",
    trakt_rating: "7.125",
    trakt_id: "143970",
    imdb_id: "tt0044984",
    tmdb_id: "235943",
    url: "https://trakt.tv/movies/oily-hare-1952",
    released: "1952-07-25",
    runtime: "7",
    genres: "animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:25:46Z": {
    type: "movie",
    title: "Baby Buggy Bunny",
    year: "1954",
    trakt_rating: "7.4",
    trakt_id: "55238",
    imdb_id: "tt0046739",
    tmdb_id: "74952",
    url: "https://trakt.tv/movies/baby-buggy-bunny-1954",
    released: "1954-12-18",
    runtime: "7",
    genres: "animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:25:45Z": {
    type: "movie",
    title: "The Windblown Hare",
    year: "1949",
    trakt_rating: "8.08333",
    trakt_id: "72283",
    imdb_id: "tt0042045",
    tmdb_id: "100385",
    url: "https://trakt.tv/movies/the-windblown-hare-1949",
    released: "1949-08-27",
    runtime: "7",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:25:12Z": {
    type: "movie",
    title: "Bugs Bunny and the Three Bears",
    year: "1944",
    trakt_rating: "6.57576",
    trakt_id: "97674",
    imdb_id: "tt0036680",
    tmdb_id: "147871",
    url: "https://trakt.tv/movies/bugs-bunny-and-the-three-bears-1944",
    released: "1944-02-26",
    runtime: "7",
    genres: "animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:25:06Z": {
    type: "movie",
    title: "Big Top Bunny",
    year: "1951",
    trakt_rating: "6.3913",
    trakt_id: "22279",
    imdb_id: "tt0043341",
    tmdb_id: "34932",
    url: "https://trakt.tv/movies/big-top-bunny-1951",
    released: "1951-12-01",
    runtime: "7",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:25:02Z": {
    type: "movie",
    title: "Hare Conditioned",
    year: "1945",
    trakt_rating: "7.86667",
    trakt_id: "72823",
    imdb_id: "tt0037763",
    tmdb_id: "101511",
    url: "https://trakt.tv/movies/hare-conditioned-1945",
    released: "1945-08-11",
    runtime: "7",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:24:27Z": {
    type: "movie",
    title: "French Rarebit",
    year: "1951",
    trakt_rating: "6.78571",
    trakt_id: "81855",
    imdb_id: "tt0043564",
    tmdb_id: "117423",
    url: "https://trakt.tv/movies/french-rarebit-1951",
    released: "1951-06-30",
    runtime: "7",
    genres: "animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:24:24Z": {
    type: "movie",
    title: "Hurdy-Gurdy Hare",
    year: "1950",
    trakt_rating: "7.2",
    trakt_id: "144085",
    imdb_id: "tt0042583",
    tmdb_id: "236112",
    url: "https://trakt.tv/movies/hurdy-gurdy-hare-1950",
    released: "1950-01-20",
    runtime: "7",
    genres: "animation,comedy,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:24:00Z": {
    type: "movie",
    title: "The Grey Hounded Hare",
    year: "1949",
    trakt_rating: "7.0",
    trakt_id: "132167",
    imdb_id: "tt0041434",
    tmdb_id: "213874",
    url: "https://trakt.tv/movies/the-grey-hounded-hare-1949",
    released: "1949-08-06",
    runtime: "7",
    genres: "animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:23:49Z": {
    type: "movie",
    title: "Water, Water Every Hare",
    year: "1952",
    trakt_rating: "7.14286",
    trakt_id: "37365",
    imdb_id: "tt0045315",
    tmdb_id: "52973",
    url: "https://trakt.tv/movies/water-water-every-hare-1952",
    released: "1952-04-19",
    runtime: "7",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:23:48Z": {
    type: "movie",
    title: "Ballot Box Bunny",
    year: "1951",
    trakt_rating: "7.17241",
    trakt_id: "22276",
    imdb_id: "tt0043315",
    tmdb_id: "34928",
    url: "https://trakt.tv/movies/ballot-box-bunny-1951",
    released: "1951-10-06",
    runtime: "8",
    genres: "animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:23:45Z": {
    type: "movie",
    title: "Rabbit Romeo",
    year: "1957",
    trakt_rating: "7.14286",
    trakt_id: "118296",
    imdb_id: "tt0050875",
    tmdb_id: "189193",
    url: "https://trakt.tv/movies/rabbit-romeo-1957",
    released: "1957-12-15",
    runtime: "7",
    genres: "animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:23:16Z": {
    type: "movie",
    title: "Forward March Hare",
    year: "1953",
    trakt_rating: "7.30769",
    trakt_id: "72822",
    imdb_id: "tt0045786",
    tmdb_id: "101510",
    url: "https://trakt.tv/movies/forward-march-hare-1953",
    released: "1953-02-14",
    runtime: "7",
    genres: "animation,comedy,family,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:23:06Z": {
    type: "movie",
    title: "Case of the Missing Hare",
    year: "1942",
    trakt_rating: "6.94444",
    trakt_id: "72821",
    imdb_id: "tt0034585",
    tmdb_id: "101509",
    url: "https://trakt.tv/movies/case-of-the-missing-hare-1942",
    released: "1942-12-12",
    runtime: "8",
    genres: "animation,family,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:22:53Z": {
    type: "movie",
    title: "14 Carrot Rabbit",
    year: "1952",
    trakt_rating: "7.1875",
    trakt_id: "108244",
    imdb_id: "tt0044313",
    tmdb_id: "169669",
    url: "https://trakt.tv/movies/14-carrot-rabbit-1952",
    released: "1952-03-15",
    runtime: "7",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:22:48Z": {
    type: "movie",
    title: "Transylvania 6-5000",
    year: "1963",
    trakt_rating: "7.68421",
    trakt_id: "63437",
    imdb_id: "tt0057600",
    tmdb_id: "84604",
    url: "https://trakt.tv/movies/transylvania-6-5000-1963",
    released: "1963-11-30",
    runtime: "7",
    genres: "animation,comedy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:22:22Z": {
    type: "movie",
    title: "Bushy Hare",
    year: "1950",
    trakt_rating: "6.75",
    trakt_id: "144082",
    imdb_id: "tt0042291",
    tmdb_id: "236109",
    url: "https://trakt.tv/movies/bushy-hare-1950",
    released: "1950-11-18",
    runtime: "7",
    genres: "comedy,animation",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:22:16Z": {
    type: "movie",
    title: "Little Red Riding Rabbit",
    year: "1944",
    trakt_rating: "7.1875",
    trakt_id: "72231",
    imdb_id: "tt0037022",
    tmdb_id: "100318",
    url: "https://trakt.tv/movies/little-red-riding-rabbit-1944",
    released: "1944-01-04",
    runtime: "7",
    genres: "animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:21:30Z": {
    type: "movie",
    title: "Hair-Raising Hare",
    year: "1946",
    trakt_rating: "7.51389",
    trakt_id: "90718",
    imdb_id: "tt0038586",
    tmdb_id: "134103",
    url: "https://trakt.tv/movies/hair-raising-hare-1946",
    released: "1946-05-25",
    runtime: "7",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:21:26Z": {
    type: "movie",
    title: "Feline Frame-Up",
    year: "1954",
    trakt_rating: "6.5",
    trakt_id: "62724",
    imdb_id: "tt0046973",
    tmdb_id: "83763",
    url: "https://trakt.tv/movies/feline-frame-up-1954",
    released: "1954-02-13",
    runtime: "6",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:20:56Z": {
    type: "movie",
    title: "Drip-Along Daffy",
    year: "1951",
    trakt_rating: "7.37143",
    trakt_id: "72217",
    imdb_id: "tt0043479",
    tmdb_id: "100299",
    url: "https://trakt.tv/movies/drip-along-daffy-1951",
    released: "1951-11-17",
    runtime: "7",
    genres: "action,family,western,animation",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:20:09Z": {
    type: "movie",
    title: "Ali Baba Bunny",
    year: "1957",
    trakt_rating: "7.53704",
    trakt_id: "72817",
    imdb_id: "tt0050111",
    tmdb_id: "101503",
    url: "https://trakt.tv/movies/ali-baba-bunny-1957",
    released: "1957-02-09",
    runtime: "7",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:20:06Z": {
    type: "movie",
    title: "Baseball Bugs",
    year: "1946",
    trakt_rating: "7.5",
    trakt_id: "22277",
    imdb_id: "tt0038333",
    tmdb_id: "34929",
    url: "https://trakt.tv/movies/baseball-bugs-1946",
    released: "1946-02-02",
    runtime: "7",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:20:00Z": {
    type: "movie",
    title: "Acrobatty Bunny",
    year: "1946",
    trakt_rating: "6.82609",
    trakt_id: "55237",
    imdb_id: "tt0038286",
    tmdb_id: "74951",
    url: "https://trakt.tv/movies/acrobatty-bunny-1946",
    released: "1946-06-29",
    runtime: "8",
    genres: "animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:19:43Z": {
    type: "movie",
    title: "The Scarlet Pumpernickel",
    year: "1950",
    trakt_rating: "7.08197",
    trakt_id: "66415",
    imdb_id: "tt0042928",
    tmdb_id: "88766",
    url: "https://trakt.tv/movies/the-scarlet-pumpernickel-1950",
    released: "1950-03-04",
    runtime: "7",
    genres: "adventure,animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:18:47Z": {
    type: "movie",
    title: "Robin Hood Daffy",
    year: "1958",
    trakt_rating: "7.36986",
    trakt_id: "66414",
    imdb_id: "tt0052139",
    tmdb_id: "88765",
    url: "https://trakt.tv/movies/robin-hood-daffy-1958",
    released: "1958-03-08",
    runtime: "7",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:18:34Z": {
    type: "movie",
    title: "Duck Dodgers in the 24½th Century",
    year: "1953",
    trakt_rating: "7.688",
    trakt_id: "49011",
    imdb_id: "tt0045709",
    tmdb_id: "67409",
    url: "https://trakt.tv/movies/duck-dodgers-in-the-24-th-century-1953",
    released: "1953-07-25",
    runtime: "7",
    genres: "animation,comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:18:16Z": {
    type: "movie",
    title: "Devil May Hare",
    year: "1954",
    trakt_rating: "7.42222",
    trakt_id: "62711",
    imdb_id: "tt0046908",
    tmdb_id: "83750",
    url: "https://trakt.tv/movies/devil-may-hare-1954",
    released: "1954-06-19",
    runtime: "7",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:18:09Z": {
    type: "movie",
    title: "Broom-Stick Bunny",
    year: "1956",
    trakt_rating: "7.40541",
    trakt_id: "62707",
    imdb_id: "tt0049032",
    tmdb_id: "83746",
    url: "https://trakt.tv/movies/broom-stick-bunny-1956",
    released: "1956-02-25",
    runtime: "7",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:17:42Z": {
    type: "movie",
    title: "Hillbilly Hare",
    year: "1950",
    trakt_rating: "7.56",
    trakt_id: "72222",
    imdb_id: "tt0042555",
    tmdb_id: "100307",
    url: "https://trakt.tv/movies/hillbilly-hare-1950",
    released: "1950-08-12",
    runtime: "7",
    genres: "animation,comedy,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:17:41Z": {
    type: "movie",
    title: "Operation: Rabbit",
    year: "1952",
    trakt_rating: "7.47826",
    trakt_id: "73178",
    imdb_id: "tt0045000",
    tmdb_id: "102161",
    url: "https://trakt.tv/movies/operation-rabbit-1952",
    released: "1952-01-19",
    runtime: "7",
    genres: "animation,action,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:17:30Z": {
    type: "movie",
    title: "Haredevil Hare",
    year: "1948",
    trakt_rating: "7.35294",
    trakt_id: "62750",
    imdb_id: "tt0040422",
    tmdb_id: "83790",
    url: "https://trakt.tv/movies/haredevil-hare-1948",
    released: "1948-07-24",
    runtime: "8",
    genres: "animation,comedy,science-fiction,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-07T13:17:02Z": {
    type: "movie",
    title: "Hare Tonic",
    year: "1945",
    trakt_rating: "7.73529",
    trakt_id: "62752",
    imdb_id: "tt0037764",
    tmdb_id: "83792",
    url: "https://trakt.tv/movies/hare-tonic-1945",
    released: "1945-11-10",
    runtime: "8",
    genres: "animation,comedy,family,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-05T23:15:30Z": {
    type: "movie",
    title: "Duck Amuck",
    year: "1953",
    trakt_rating: "8.19",
    trakt_id: "37565",
    imdb_id: "tt0045708",
    tmdb_id: "53210",
    url: "https://trakt.tv/movies/duck-amuck-1953",
    released: "1953-02-28",
    runtime: "7",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-05T23:15:02Z": {
    type: "movie",
    title: "One Froggy Evening",
    year: "1955",
    trakt_rating: "7.92623",
    trakt_id: "37566",
    imdb_id: "tt0048449",
    tmdb_id: "53211",
    url: "https://trakt.tv/movies/one-froggy-evening-1955",
    released: "1955-12-30",
    runtime: "7",
    genres: "animation,comedy,family,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-05T23:14:53Z": {
    type: "movie",
    title: "Rabbit of Seville",
    year: "1950",
    trakt_rating: "8.07812",
    trakt_id: "37363",
    imdb_id: "tt0042871",
    tmdb_id: "52971",
    url: "https://trakt.tv/movies/rabbit-of-seville-1950",
    released: "1950-12-16",
    runtime: "8",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-05T23:14:35Z": {
    type: "movie",
    title: "Rabbit Seasoning",
    year: "1952",
    trakt_rating: "7.92593",
    trakt_id: "37349",
    imdb_id: "tt0045062",
    tmdb_id: "52954",
    url: "https://trakt.tv/movies/rabbit-seasoning-1952",
    released: "1952-09-20",
    runtime: "7",
    genres: "animation,comedy,family,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-08-05T23:14:25Z": {
    type: "movie",
    title: "What's Opera, Doc?",
    year: "1957",
    trakt_rating: "7.76647",
    trakt_id: "37571",
    imdb_id: "tt0051189",
    tmdb_id: "53217",
    url: "https://trakt.tv/movies/what-s-opera-doc-1957",
    released: "1957-07-06",
    runtime: "7",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-07-31T03:20:13Z": {
    type: "movie",
    title: "Avengers: Infinity War",
    year: "2018",
    trakt_rating: "8.49891",
    trakt_id: "191797",
    imdb_id: "tt4154756",
    tmdb_id: "299536",
    url: "https://trakt.tv/movies/avengers-infinity-war-2018",
    released: "2018-04-27",
    runtime: "149",
    genres: "action,adventure,superhero,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-07-22T00:48:52Z": {
    type: "movie",
    title: "Tully",
    year: "2018",
    trakt_rating: "7.19453",
    trakt_id: "243711",
    imdb_id: "tt5610554",
    tmdb_id: "400579",
    url: "https://trakt.tv/movies/tully-2018",
    released: "2018-05-04",
    runtime: "96",
    genres: "drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-07-16T03:16:23Z": {
    type: "movie",
    title: "Batman & Robin",
    year: "1997",
    trakt_rating: "4.6994",
    trakt_id: "331",
    imdb_id: "tt0118688",
    tmdb_id: "415",
    url: "https://trakt.tv/movies/batman-robin-1997",
    released: "1997-06-20",
    runtime: "125",
    genres: "action,superhero,science-fiction,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-07-16T03:15:53Z": {
    type: "movie",
    title: "Batman Forever",
    year: "1995",
    trakt_rating: "5.70919",
    trakt_id: "330",
    imdb_id: "tt0112462",
    tmdb_id: "414",
    url: "https://trakt.tv/movies/batman-forever-1995",
    released: "1995-06-16",
    runtime: "121",
    genres: "fantasy,action,crime,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-07-16T03:12:34Z": {
    type: "movie",
    title: "Batman Returns",
    year: "1992",
    trakt_rating: "7.15548",
    trakt_id: "300",
    imdb_id: "tt0103776",
    tmdb_id: "364",
    url: "https://trakt.tv/movies/batman-returns-1992",
    released: "1992-06-19",
    runtime: "126",
    genres: "action,fantasy,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-07-16T03:06:49Z": {
    type: "movie",
    title: "Batman",
    year: "1989",
    trakt_rating: "7.50794",
    trakt_id: "224",
    imdb_id: "tt0096895",
    tmdb_id: "268",
    url: "https://trakt.tv/movies/batman-1989",
    released: "1989-06-23",
    runtime: "126",
    genres: "action,fantasy,superhero,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-07-16T03:01:44Z": {
    type: "movie",
    title: "Hellboy II: The Golden Army",
    year: "2008",
    trakt_rating: "7.13345",
    trakt_id: "6375",
    imdb_id: "tt0411477",
    tmdb_id: "11253",
    url: "https://trakt.tv/movies/hellboy-ii-the-golden-army-2008",
    released: "2008-07-11",
    runtime: "120",
    genres: "fantasy,superhero,action,horror,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-07-11T04:09:21Z": {
    type: "movie",
    title: "Traffik",
    year: "2018",
    trakt_rating: "6.86833",
    trakt_id: "320546",
    imdb_id: "tt5670152",
    tmdb_id: "473149",
    url: "https://trakt.tv/movies/traffik-2018",
    released: "2018-04-20",
    runtime: "96",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-07-06T03:42:26Z": {
    type: "movie",
    title: "Fantastic Mr. Fox",
    year: "2009",
    trakt_rating: "7.8498",
    trakt_id: "5518",
    imdb_id: "tt0432283",
    tmdb_id: "10315",
    url: "https://trakt.tv/movies/fantastic-mr-fox-2009",
    released: "2009-11-13",
    runtime: "87",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-07-06T03:10:59Z": {
    type: "movie",
    title: "Your Name.",
    year: "2016",
    trakt_rating: "8.722",
    trakt_id: "224301",
    imdb_id: "tt5311514",
    tmdb_id: "372058",
    url: "https://trakt.tv/movies/your-name-2016",
    released: "2016-08-26",
    runtime: "106",
    genres: "drama,romance,anime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-29T00:33:59Z": {
    type: "movie",
    title: "The Dark Half",
    year: "1993",
    trakt_rating: "6.36341",
    trakt_id: "5546",
    imdb_id: "tt0106664",
    tmdb_id: "10349",
    url: "https://trakt.tv/movies/the-dark-half-1993",
    released: "1993-04-23",
    runtime: "122",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-25T02:25:45Z": {
    type: "movie",
    title: "The Endless",
    year: "2017",
    trakt_rating: "6.78499",
    trakt_id: "273954",
    imdb_id: "tt3986820",
    tmdb_id: "430231",
    url: "https://trakt.tv/movies/the-endless-2017",
    released: "2018-04-06",
    runtime: "112",
    genres: "science-fiction,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-21T01:29:37Z": {
    type: "movie",
    title: "Isle of Dogs",
    year: "2018",
    trakt_rating: "7.84031",
    trakt_id: "268663",
    imdb_id: "tt5104604",
    tmdb_id: "399174",
    url: "https://trakt.tv/movies/isle-of-dogs-2018",
    released: "2018-03-23",
    runtime: "101",
    genres: "comedy,animation,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-18T18:17:01Z": {
    type: "movie",
    title: "You Were Never Really Here",
    year: "2017",
    trakt_rating: "6.63796",
    trakt_id: "241168",
    imdb_id: "tt5742374",
    tmdb_id: "398181",
    url: "https://trakt.tv/movies/you-were-never-really-here-2017",
    released: "2018-04-06",
    runtime: "89",
    genres: "thriller,drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-18T11:52:46Z": {
    type: "movie",
    title: "Ready Player One",
    year: "2018",
    trakt_rating: "7.66356",
    trakt_id: "214279",
    imdb_id: "tt1677720",
    tmdb_id: "333339",
    url: "https://trakt.tv/movies/ready-player-one-2018",
    released: "2018-03-29",
    runtime: "140",
    genres: "science-fiction,adventure,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-18T01:23:30Z": {
    type: "movie",
    title: "Trading Places",
    year: "1983",
    trakt_rating: "7.73584",
    trakt_id: "1068",
    imdb_id: "tt0086465",
    tmdb_id: "1621",
    url: "https://trakt.tv/movies/trading-places-1983",
    released: "1983-06-07",
    runtime: "116",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-17T01:09:29Z": {
    type: "movie",
    title: "There's Something About Mary",
    year: "1998",
    trakt_rating: "7.19676",
    trakt_id: "427",
    imdb_id: "tt0129387",
    tmdb_id: "544",
    url: "https://trakt.tv/movies/there-s-something-about-mary-1998",
    released: "1998-07-15",
    runtime: "119",
    genres: "comedy,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-14T02:30:33Z": {
    type: "movie",
    title: "Minions: Home Makeover",
    year: "2010",
    trakt_rating: "7.38596",
    trakt_id: "38653",
    imdb_id: "tt1830633",
    tmdb_id: "54553",
    url: "https://trakt.tv/movies/minions-home-makeover-2010",
    released: "2010-12-13",
    runtime: "5",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-14T02:30:06Z": {
    type: "movie",
    title: "Minions: Orientation Day",
    year: "2010",
    trakt_rating: "7.37535",
    trakt_id: "38659",
    imdb_id: "tt1830748",
    tmdb_id: "54559",
    url: "https://trakt.tv/movies/minions-orientation-day-2010",
    released: "2010-12-13",
    runtime: "4",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-14T02:29:54Z": {
    type: "movie",
    title: "Banana",
    year: "2010",
    trakt_rating: "7.66667",
    trakt_id: "38651",
    imdb_id: "tt1814643",
    tmdb_id: "54551",
    url: "https://trakt.tv/movies/banana-2010",
    released: "2010-12-13",
    runtime: "4",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-10T22:12:35Z": {
    type: "movie",
    title: "12 Angry Men",
    year: "1957",
    trakt_rating: "8.85454",
    trakt_id: "309",
    imdb_id: "tt0050083",
    tmdb_id: "389",
    url: "https://trakt.tv/movies/12-angry-men-1957",
    released: "1957-04-10",
    runtime: "97",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-10T04:02:03Z": {
    type: "movie",
    title: "The 12th Man",
    year: "2017",
    trakt_rating: "7.49571",
    trakt_id: "282279",
    imdb_id: "tt3300980",
    tmdb_id: "435577",
    url: "https://trakt.tv/movies/the-12th-man-2017",
    released: "2017-12-25",
    runtime: "136",
    genres: "drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-09T20:22:33Z": {
    type: "movie",
    title: "The Mercy",
    year: "2018",
    trakt_rating: "6.6375",
    trakt_id: "259164",
    imdb_id: "tt3319730",
    tmdb_id: "414001",
    url: "https://trakt.tv/movies/the-mercy-2018",
    released: "2018-02-09",
    runtime: "102",
    genres: "drama,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-07T00:30:02Z": {
    type: "movie",
    title: "Bill & Ted's Excellent Adventure",
    year: "1989",
    trakt_rating: "7.45605",
    trakt_id: "1093",
    imdb_id: "tt0096928",
    tmdb_id: "1648",
    url: "https://trakt.tv/movies/bill-ted-s-excellent-adventure-1989",
    released: "1989-02-17",
    runtime: "90",
    genres: "adventure,comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-07T00:25:15Z": {
    type: "movie",
    title: "Journey's End",
    year: "2017",
    trakt_rating: "6.71197",
    trakt_id: "284437",
    imdb_id: "tt3780500",
    tmdb_id: "438259",
    url: "https://trakt.tv/movies/journey-s-end-2017",
    released: "2018-02-02",
    runtime: "108",
    genres: "war,drama,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-06T11:16:41Z": {
    type: "movie",
    title: "Pacific Rim: Uprising",
    year: "2018",
    trakt_rating: "6.47477",
    trakt_id: "167163",
    imdb_id: "tt2557478",
    tmdb_id: "268896",
    url: "https://trakt.tv/movies/pacific-rim-uprising-2018",
    released: "2018-03-23",
    runtime: "111",
    genres: "action,science-fiction,fantasy,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-06T02:23:04Z": {
    type: "movie",
    title: "Get Shorty",
    year: "1995",
    trakt_rating: "7.05111",
    trakt_id: "4006",
    imdb_id: "tt0113161",
    tmdb_id: "8012",
    url: "https://trakt.tv/movies/get-shorty-1995",
    released: "1995-10-20",
    runtime: "105",
    genres: "comedy,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-06T00:02:03Z": {
    type: "movie",
    title: "Aftermath",
    year: "2017",
    trakt_rating: "6.4872",
    trakt_id: "232295",
    imdb_id: "tt4581576",
    tmdb_id: "390051",
    url: "https://trakt.tv/movies/aftermath-2017",
    released: "2017-04-07",
    runtime: "92",
    genres: "thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-05T00:41:07Z": {
    type: "movie",
    title: "High and Low",
    year: "1963",
    trakt_rating: "8.36257",
    trakt_id: "7266",
    imdb_id: "tt0057565",
    tmdb_id: "12493",
    url: "https://trakt.tv/movies/high-and-low-1963",
    released: "1963-03-01",
    runtime: "142",
    genres: "crime,drama,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-05T00:38:18Z": {
    type: "movie",
    title: "LEGO Marvel Super Heroes: Black Panther - Trouble in Wakanda",
    year: "2018",
    trakt_rating: "6.10465",
    trakt_id: "376263",
    imdb_id: "tt8205250",
    tmdb_id: "528222",
    url: "https://trakt.tv/movies/lego-marvel-super-heroes-black-panther-trouble-in-wakanda-2018",
    released: "2018-06-04",
    runtime: "22",
    genres: "animation,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-04T00:34:20Z": {
    type: "movie",
    title: "Battlestar Galactica: The Plan",
    year: "2009",
    trakt_rating: "7.46752",
    trakt_id: "74848",
    imdb_id: "tt1286130",
    tmdb_id: "105077",
    url: "https://trakt.tv/movies/battlestar-galactica-the-plan-2009",
    released: "2009-10-27",
    runtime: "112",
    genres: "action,drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-04T00:32:29Z": {
    type: "movie",
    title: "Battlestar Galactica: Razor",
    year: "2007",
    trakt_rating: "7.68812",
    trakt_id: "50626",
    imdb_id: "tt0991178",
    tmdb_id: "69315",
    url: "https://trakt.tv/movies/battlestar-galactica-razor-2007",
    released: "2007-11-12",
    runtime: "88",
    genres: "action,drama,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-03T02:27:04Z": {
    type: "movie",
    title: "Jurassic World",
    year: "2015",
    trakt_rating: "7.18872",
    trakt_id: "91374",
    imdb_id: "tt0369610",
    tmdb_id: "135397",
    url: "https://trakt.tv/movies/jurassic-world-2015",
    released: "2015-06-12",
    runtime: "124",
    genres: "action,adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-03T02:26:51Z": {
    type: "movie",
    title: "Jurassic Park III",
    year: "2001",
    trakt_rating: "6.52832",
    trakt_id: "278",
    imdb_id: "tt0163025",
    tmdb_id: "331",
    url: "https://trakt.tv/movies/jurassic-park-iii-2001",
    released: "2001-07-18",
    runtime: "92",
    genres: "action,adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-03T02:23:27Z": {
    type: "movie",
    title: "The Lost World: Jurassic Park",
    year: "1997",
    trakt_rating: "7.01023",
    trakt_id: "277",
    imdb_id: "tt0119567",
    tmdb_id: "330",
    url: "https://trakt.tv/movies/the-lost-world-jurassic-park-1997",
    released: "1997-05-23",
    runtime: "129",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-03T02:13:18Z": {
    type: "movie",
    title: "Unlocked",
    year: "2017",
    trakt_rating: "7.1572",
    trakt_id: "188120",
    imdb_id: "tt1734493",
    tmdb_id: "291276",
    url: "https://trakt.tv/movies/unlocked-2017",
    released: "2017-09-01",
    runtime: "98",
    genres: "thriller,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-06-02T02:33:11Z": {
    type: "movie",
    title: "Unsane",
    year: "2018",
    trakt_rating: "6.66837",
    trakt_id: "314894",
    imdb_id: "tt7153766",
    tmdb_id: "467660",
    url: "https://trakt.tv/movies/unsane-2018",
    released: "2018-03-23",
    runtime: "98",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-27T22:58:48Z": {
    type: "movie",
    title: "Gates of Heaven",
    year: "1978",
    trakt_rating: "7.20536",
    trakt_id: "15090",
    imdb_id: "tt0077598",
    tmdb_id: "24998",
    url: "https://trakt.tv/movies/gates-of-heaven-1978",
    released: "1978-10-01",
    runtime: "85",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-27T22:56:55Z": {
    type: "movie",
    title: "The Thin Blue Line",
    year: "1988",
    trakt_rating: "7.67377",
    trakt_id: "8577",
    imdb_id: "tt0096257",
    tmdb_id: "14285",
    url: "https://trakt.tv/movies/the-thin-blue-line-1988",
    released: "1988-08-28",
    runtime: "103",
    genres: "documentary,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-26T02:45:57Z": {
    type: "movie",
    title: "Thoroughbreds",
    year: "2018",
    trakt_rating: "6.8284",
    trakt_id: "240668",
    imdb_id: "tt5649108",
    tmdb_id: "397722",
    url: "https://trakt.tv/movies/thoroughbreds-2018",
    released: "2018-03-09",
    runtime: "92",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-22T20:40:21Z": {
    type: "movie",
    title: "Gone Girl",
    year: "2014",
    trakt_rating: "8.0349",
    trakt_id: "130355",
    imdb_id: "tt2267998",
    tmdb_id: "210577",
    url: "https://trakt.tv/movies/gone-girl-2014",
    released: "2014-10-03",
    runtime: "149",
    genres: "drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-22T14:35:05Z": {
    type: "movie",
    title: "A Quiet Place",
    year: "2018",
    trakt_rating: "7.64982",
    trakt_id: "293955",
    imdb_id: "tt6644200",
    tmdb_id: "447332",
    url: "https://trakt.tv/movies/a-quiet-place-2018",
    released: "2018-04-06",
    runtime: "91",
    genres: "horror,drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-21T13:08:38Z": {
    type: "movie",
    title: "The Kid Stays in the Picture",
    year: "2002",
    trakt_rating: "7.21154",
    trakt_id: "18202",
    imdb_id: "tt0303353",
    tmdb_id: "29047",
    url: "https://trakt.tv/movies/the-kid-stays-in-the-picture-2002",
    released: "2002-08-16",
    runtime: "93",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-21T12:46:35Z": {
    type: "movie",
    title: "The Quick and the Dead",
    year: "1995",
    trakt_rating: "6.81295",
    trakt_id: "7024",
    imdb_id: "tt0114214",
    tmdb_id: "12106",
    url: "https://trakt.tv/movies/the-quick-and-the-dead-1995",
    released: "1995-02-09",
    runtime: "107",
    genres: "western,action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-21T03:58:35Z": {
    type: "movie",
    title: "Eye of the Needle",
    year: "1981",
    trakt_rating: "7.34574",
    trakt_id: "6020",
    imdb_id: "tt0082351",
    tmdb_id: "10863",
    url: "https://trakt.tv/movies/eye-of-the-needle-1981",
    released: "1981-07-24",
    runtime: "108",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-21T03:41:59Z": {
    type: "movie",
    title: "Dirty Mary Crazy Larry",
    year: "1974",
    trakt_rating: "7.05769",
    trakt_id: "11700",
    imdb_id: "tt0071424",
    tmdb_id: "19017",
    url: "https://trakt.tv/movies/dirty-mary-crazy-larry-1974",
    released: "1974-05-17",
    runtime: "93",
    genres: "action,adventure,crime,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-21T03:40:21Z": {
    type: "movie",
    title: "Trees Lounge",
    year: "1996",
    trakt_rating: "7.24309",
    trakt_id: "17224",
    imdb_id: "tt0117958",
    tmdb_id: "27845",
    url: "https://trakt.tv/movies/trees-lounge-1996",
    released: "1996-10-11",
    runtime: "95",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-21T03:36:07Z": {
    type: "movie",
    title: "Lenny",
    year: "1974",
    trakt_rating: "7.53591",
    trakt_id: "16578",
    imdb_id: "tt0071746",
    tmdb_id: "27094",
    url: "https://trakt.tv/movies/lenny-1974",
    released: "1974-11-10",
    runtime: "111",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-21T03:31:29Z": {
    type: "movie",
    title: "Bob Roberts",
    year: "1992",
    trakt_rating: "7.01307",
    trakt_id: "5783",
    imdb_id: "tt0103850",
    tmdb_id: "10608",
    url: "https://trakt.tv/movies/bob-roberts-1992",
    released: "1992-09-04",
    runtime: "102",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-21T03:27:32Z": {
    type: "movie",
    title: "Purple Noon",
    year: "1960",
    trakt_rating: "7.63786",
    trakt_id: "5559",
    imdb_id: "tt0054189",
    tmdb_id: "10363",
    url: "https://trakt.tv/movies/purple-noon-1960",
    released: "1960-03-10",
    runtime: "115",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-21T03:16:52Z": {
    type: "movie",
    title: "The Picture of Dorian Gray",
    year: "1945",
    trakt_rating: "7.65741",
    trakt_id: "10189",
    imdb_id: "tt0037988",
    tmdb_id: "16559",
    url: "https://trakt.tv/movies/the-picture-of-dorian-gray-1945",
    released: "1945-03-01",
    runtime: "110",
    genres: "drama,fantasy,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-21T03:13:33Z": {
    type: "movie",
    title: "Martin",
    year: "1977",
    trakt_rating: "7.01299",
    trakt_id: "16139",
    imdb_id: "tt0077914",
    tmdb_id: "26517",
    url: "https://trakt.tv/movies/martin-1977",
    released: "1977-10-27",
    runtime: "96",
    genres: "horror,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-21T02:39:24Z": {
    type: "movie",
    title: "Until the Light Takes Us",
    year: "2008",
    trakt_rating: "7.19355",
    trakt_id: "22910",
    imdb_id: "tt1014809",
    tmdb_id: "36124",
    url: "https://trakt.tv/movies/until-the-light-takes-us-2008",
    released: "2008-10-31",
    runtime: "93",
    genres: "documentary,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-21T02:26:32Z": {
    type: "movie",
    title: "The Odessa File",
    year: "1974",
    trakt_rating: "7.32512",
    trakt_id: "13155",
    imdb_id: "tt0071935",
    tmdb_id: "21489",
    url: "https://trakt.tv/movies/the-odessa-file-1974",
    released: "1974-10-17",
    runtime: "130",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T23:09:08Z": {
    type: "movie",
    title: "Animal Crackers",
    year: "1930",
    trakt_rating: "7.49412",
    trakt_id: "8285",
    imdb_id: "tt0020640",
    tmdb_id: "13913",
    url: "https://trakt.tv/movies/animal-crackers-1930",
    released: "1930-08-08",
    runtime: "97",
    genres: "comedy,musical,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T09:48:25Z": {
    type: "movie",
    title: "A Day at the Races",
    year: "1937",
    trakt_rating: "7.57848",
    trakt_id: "6954",
    imdb_id: "tt0028772",
    tmdb_id: "11939",
    url: "https://trakt.tv/movies/a-day-at-the-races-1937",
    released: "1937-06-11",
    runtime: "111",
    genres: "comedy,musical,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T09:28:42Z": {
    type: "movie",
    title: "Transformers Prime Beast Hunters: Predacons Rising",
    year: "2013",
    trakt_rating: "7.28369",
    trakt_id: "166445",
    imdb_id: "tt2788526",
    tmdb_id: "268092",
    url: "https://trakt.tv/movies/transformers-prime-beast-hunters-predacons-rising-2013",
    released: "2013-10-04",
    runtime: "66",
    genres: "action,animation,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T09:25:07Z": {
    type: "movie",
    title: "Triumph of the Will",
    year: "1935",
    trakt_rating: "7.02564",
    trakt_id: "25493",
    imdb_id: "tt0025913",
    tmdb_id: "39266",
    url: "https://trakt.tv/movies/triumph-of-the-will-1935",
    released: "1935-03-28",
    runtime: "114",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T09:17:13Z": {
    type: "movie",
    title: "The Dam Busters",
    year: "1955",
    trakt_rating: "7.89568",
    trakt_id: "7755",
    imdb_id: "tt0046889",
    tmdb_id: "13210",
    url: "https://trakt.tv/movies/the-dam-busters-1955",
    released: "1955-05-16",
    runtime: "124",
    genres: "action,drama,history,thriller,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T09:08:21Z": {
    type: "movie",
    title: "The Spy Who Came in from the Cold",
    year: "1965",
    trakt_rating: "7.55452",
    trakt_id: "8029",
    imdb_id: "tt0059749",
    tmdb_id: "13580",
    url: "https://trakt.tv/movies/the-spy-who-came-in-from-the-cold-1965",
    released: "1965-12-16",
    runtime: "112",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T05:50:47Z": {
    type: "movie",
    title: "The Birds",
    year: "1963",
    trakt_rating: "7.59325",
    trakt_id: "450",
    imdb_id: "tt0056869",
    tmdb_id: "571",
    url: "https://trakt.tv/movies/the-birds-1963",
    released: "1963-03-28",
    runtime: "119",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T05:20:03Z": {
    type: "movie",
    title: "Shadow of a Doubt",
    year: "1943",
    trakt_rating: "7.58132",
    trakt_id: "13280",
    imdb_id: "tt0036342",
    tmdb_id: "21734",
    url: "https://trakt.tv/movies/shadow-of-a-doubt-1943",
    released: "1943-01-15",
    runtime: "108",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T05:18:10Z": {
    type: "movie",
    title: "Spellbound",
    year: "1945",
    trakt_rating: "7.41108",
    trakt_id: "2452",
    imdb_id: "tt0038109",
    tmdb_id: "4174",
    url: "https://trakt.tv/movies/spellbound-1945",
    released: "1945-11-08",
    runtime: "111",
    genres: "mystery,thriller,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T05:13:03Z": {
    type: "movie",
    title: "Notorious",
    year: "1946",
    trakt_rating: "7.68067",
    trakt_id: "256",
    imdb_id: "tt0038787",
    tmdb_id: "303",
    url: "https://trakt.tv/movies/notorious-1946",
    released: "1946-08-21",
    runtime: "103",
    genres: "romance,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T05:06:42Z": {
    type: "movie",
    title: "Rebecca",
    year: "1940",
    trakt_rating: "7.8366",
    trakt_id: "182",
    imdb_id: "tt0032976",
    tmdb_id: "223",
    url: "https://trakt.tv/movies/rebecca-1940",
    released: "1940-03-23",
    runtime: "130",
    genres: "drama,mystery,thriller,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T04:22:07Z": {
    type: "movie",
    title: "Men in Black",
    year: "1997",
    trakt_rating: "7.74868",
    trakt_id: "485",
    imdb_id: "tt0119654",
    tmdb_id: "607",
    url: "https://trakt.tv/movies/men-in-black-1997",
    released: "1997-07-02",
    runtime: "98",
    genres: "action,adventure,comedy,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T00:49:34Z": {
    type: "movie",
    title: "Cape Fear",
    year: "1962",
    trakt_rating: "7.67123",
    trakt_id: "6445",
    imdb_id: "tt0055824",
    tmdb_id: "11349",
    url: "https://trakt.tv/movies/cape-fear-1962",
    released: "1962-04-12",
    runtime: "105",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T00:07:35Z": {
    type: "movie",
    title: "A Night at the Opera",
    year: "1935",
    trakt_rating: "7.63027",
    trakt_id: "24216",
    imdb_id: "tt0026778",
    tmdb_id: "37719",
    url: "https://trakt.tv/movies/a-night-at-the-opera-1935",
    released: "1935-11-15",
    runtime: "96",
    genres: "comedy,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-20T00:07:09Z": {
    type: "movie",
    title: "Duck Soup",
    year: "1933",
    trakt_rating: "7.47018",
    trakt_id: "2039",
    imdb_id: "tt0023969",
    tmdb_id: "3063",
    url: "https://trakt.tv/movies/duck-soup-1933",
    released: "1933-11-12",
    runtime: "69",
    genres: "comedy,war,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T23:50:38Z": {
    type: "movie",
    title: "Shadow of the Vampire",
    year: "2000",
    trakt_rating: "6.89672",
    trakt_id: "6030",
    imdb_id: "tt0189998",
    tmdb_id: "10873",
    url: "https://trakt.tv/movies/shadow-of-the-vampire-2000",
    released: "2000-05-15",
    runtime: "92",
    genres: "drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T23:07:53Z": {
    type: "movie",
    title: "Frenzy",
    year: "1972",
    trakt_rating: "7.43593",
    trakt_id: "452",
    imdb_id: "tt0068611",
    tmdb_id: "573",
    url: "https://trakt.tv/movies/frenzy-1972",
    released: "1972-05-25",
    runtime: "116",
    genres: "crime,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T23:07:09Z": {
    type: "movie",
    title: "Marnie",
    year: "1964",
    trakt_rating: "7.15571",
    trakt_id: "398",
    imdb_id: "tt0058329",
    tmdb_id: "506",
    url: "https://trakt.tv/movies/marnie-1964",
    released: "1964-07-17",
    runtime: "130",
    genres: "mystery,thriller,crime,drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T18:40:14Z": {
    type: "movie",
    title: "Fist of Fury",
    year: "1972",
    trakt_rating: "7.21529",
    trakt_id: "6785",
    imdb_id: "tt0068767",
    tmdb_id: "11713",
    url: "https://trakt.tv/movies/fist-of-fury-1972",
    released: "1972-03-22",
    runtime: "108",
    genres: "action,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T14:34:00Z": {
    type: "movie",
    title: "Anatomy of a Murder",
    year: "1959",
    trakt_rating: "7.83285",
    trakt_id: "62",
    imdb_id: "tt0052561",
    tmdb_id: "93",
    url: "https://trakt.tv/movies/anatomy-of-a-murder-1959",
    released: "1959-07-01",
    runtime: "161",
    genres: "crime,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T13:31:57Z": {
    type: "movie",
    title: "Psycho",
    year: "1960",
    trakt_rating: "8.33655",
    trakt_id: "422",
    imdb_id: "tt0054215",
    tmdb_id: "539",
    url: "https://trakt.tv/movies/psycho-1960",
    released: "1960-06-22",
    runtime: "109",
    genres: "horror,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T13:22:19Z": {
    type: "movie",
    title: "Three Days of the Condor",
    year: "1975",
    trakt_rating: "7.45364",
    trakt_id: "6976",
    imdb_id: "tt0073802",
    tmdb_id: "11963",
    url: "https://trakt.tv/movies/three-days-of-the-condor-1975",
    released: "1975-09-24",
    runtime: "117",
    genres: "thriller,mystery,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T12:49:23Z": {
    type: "movie",
    title: "They Call Me Mister Tibbs!",
    year: "1970",
    trakt_rating: "6.59615",
    trakt_id: "22171",
    imdb_id: "tt0066450",
    tmdb_id: "34732",
    url: "https://trakt.tv/movies/they-call-me-mister-tibbs-1970",
    released: "1970-07-10",
    runtime: "108",
    genres: "action,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T12:48:50Z": {
    type: "movie",
    title: "In the Heat of the Night",
    year: "1967",
    trakt_rating: "7.85679",
    trakt_id: "5807",
    imdb_id: "tt0061811",
    tmdb_id: "10633",
    url: "https://trakt.tv/movies/in-the-heat-of-the-night-1967",
    released: "1967-08-02",
    runtime: "109",
    genres: "crime,drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T12:40:30Z": {
    type: "movie",
    title: "The Pink Panther",
    year: "1963",
    trakt_rating: "7.17353",
    trakt_id: "778",
    imdb_id: "tt0057413",
    tmdb_id: "936",
    url: "https://trakt.tv/movies/the-pink-panther-1963",
    released: "1964-03-18",
    runtime: "115",
    genres: "comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T12:31:22Z": {
    type: "movie",
    title: "LEGO DC Comics Super Heroes: Justice League - Gotham City Breakout",
    year: "2016",
    trakt_rating: "7.28308",
    trakt_id: "244485",
    imdb_id: "tt5612702",
    tmdb_id: "396330",
    url: "https://trakt.tv/movies/lego-dc-comics-super-heroes-justice-league-gotham-city-breakout-2016",
    released: "2016-06-21",
    runtime: "78",
    genres: "animation,adventure,action,family,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T12:28:36Z": {
    type: "movie",
    title: "U-571",
    year: "2000",
    trakt_rating: "7.1951",
    trakt_id: "2253",
    imdb_id: "tt0141926",
    tmdb_id: "3536",
    url: "https://trakt.tv/movies/u-571-2000",
    released: "2000-04-21",
    runtime: "116",
    genres: "action,drama,thriller,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T06:44:03Z": {
    type: "movie",
    title: "Rashomon",
    year: "1950",
    trakt_rating: "8.0",
    trakt_id: "430",
    imdb_id: "tt0042876",
    tmdb_id: "548",
    url: "https://trakt.tv/movies/rashomon-1950",
    released: "1950-08-26",
    runtime: "88",
    genres: "crime,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T06:43:49Z": {
    type: "movie",
    title: "Yojimbo",
    year: "1961",
    trakt_rating: "8.15713",
    trakt_id: "6899",
    imdb_id: "tt0055630",
    tmdb_id: "11878",
    url: "https://trakt.tv/movies/yojimbo-1961",
    released: "1961-04-25",
    runtime: "110",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T06:40:08Z": {
    type: "movie",
    title: "Man on Wire",
    year: "2008",
    trakt_rating: "7.72455",
    trakt_id: "8394",
    imdb_id: "tt1155592",
    tmdb_id: "14048",
    url: "https://trakt.tv/movies/man-on-wire-2008",
    released: "2008-08-01",
    runtime: "94",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T03:02:30Z": {
    type: "movie",
    title: "Young Frankenstein",
    year: "1974",
    trakt_rating: "8.02792",
    trakt_id: "2017",
    imdb_id: "tt0072431",
    tmdb_id: "3034",
    url: "https://trakt.tv/movies/young-frankenstein-1974",
    released: "1974-12-15",
    runtime: "106",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T02:18:43Z": {
    type: "movie",
    title: "Dark Crimes",
    year: "2016",
    trakt_rating: "5.30789",
    trakt_id: "221111",
    imdb_id: "tt1901024",
    tmdb_id: "340674",
    url: "https://trakt.tv/movies/dark-crimes-2016",
    released: "2018-05-18",
    runtime: "93",
    genres: "thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T01:24:22Z": {
    type: "movie",
    title: "Nineteen Eighty-Four",
    year: "1984",
    trakt_rating: "7.07812",
    trakt_id: "4612",
    imdb_id: "tt0087803",
    tmdb_id: "9314",
    url: "https://trakt.tv/movies/nineteen-eighty-four-1984",
    released: "1984-10-10",
    runtime: "113",
    genres: "drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T01:17:43Z": {
    type: "movie",
    title: "Dial M for Murder",
    year: "1954",
    trakt_rating: "8.10219",
    trakt_id: "406",
    imdb_id: "tt0046912",
    tmdb_id: "521",
    url: "https://trakt.tv/movies/dial-m-for-murder-1954",
    released: "1954-05-29",
    runtime: "105",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T00:59:57Z": {
    type: "movie",
    title: "[REC]",
    year: "2007",
    trakt_rating: "7.36634",
    trakt_id: "4126",
    imdb_id: "tt1038988",
    tmdb_id: "8329",
    url: "https://trakt.tv/movies/rec-2007",
    released: "2007-11-23",
    runtime: "78",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T00:59:46Z": {
    type: "movie",
    title: "[REC]³ Genesis",
    year: "2012",
    trakt_rating: "5.37822",
    trakt_id: "59759",
    imdb_id: "tt1649444",
    tmdb_id: "80280",
    url: "https://trakt.tv/movies/rec-genesis-2012",
    released: "2012-03-30",
    runtime: "80",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T00:59:45Z": {
    type: "movie",
    title: "[REC]²",
    year: "2009",
    trakt_rating: "6.69095",
    trakt_id: "5837",
    imdb_id: "tt1245112",
    tmdb_id: "10664",
    url: "https://trakt.tv/movies/rec-2009",
    released: "2009-10-01",
    runtime: "84",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T00:50:16Z": {
    type: "movie",
    title: "Secretary",
    year: "2002",
    trakt_rating: "7.07333",
    trakt_id: "6151",
    imdb_id: "tt0274812",
    tmdb_id: "11013",
    url: "https://trakt.tv/movies/secretary-2002",
    released: "2002-09-20",
    runtime: "107",
    genres: "comedy,drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T00:41:56Z": {
    type: "movie",
    title: "Metropolis",
    year: "1927",
    trakt_rating: "7.97843",
    trakt_id: "15",
    imdb_id: "tt0017136",
    tmdb_id: "19",
    url: "https://trakt.tv/movies/metropolis-1927",
    released: "1927-08-25",
    runtime: "149",
    genres: "drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-19T00:21:59Z": {
    type: "movie",
    title: "Elite Squad",
    year: "2007",
    trakt_rating: "8.28576",
    trakt_id: "3896",
    imdb_id: "tt0861739",
    tmdb_id: "7347",
    url: "https://trakt.tv/movies/elite-squad-2007",
    released: "2007-10-12",
    runtime: "115",
    genres: "action,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T23:44:22Z": {
    type: "movie",
    title: "Cruel Intentions",
    year: "1999",
    trakt_rating: "7.17442",
    trakt_id: "639",
    imdb_id: "tt0139134",
    tmdb_id: "796",
    url: "https://trakt.tv/movies/cruel-intentions-1999",
    released: "1999-03-05",
    runtime: "98",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T23:42:43Z": {
    type: "movie",
    title: "Valkyrie",
    year: "2008",
    trakt_rating: "7.20265",
    trakt_id: "1583",
    imdb_id: "tt0985699",
    tmdb_id: "2253",
    url: "https://trakt.tv/movies/valkyrie-2008",
    released: "2008-12-25",
    runtime: "121",
    genres: "drama,thriller,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T23:26:38Z": {
    type: "movie",
    title: "Downfall",
    year: "2004",
    trakt_rating: "8.10004",
    trakt_id: "489",
    imdb_id: "tt0363163",
    tmdb_id: "613",
    url: "https://trakt.tv/movies/downfall-2004",
    released: "2004-09-17",
    runtime: "156",
    genres: "drama,history,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T23:16:47Z": {
    type: "movie",
    title: "The Godfather: Part III",
    year: "1990",
    trakt_rating: "7.67243",
    trakt_id: "200",
    imdb_id: "tt0099674",
    tmdb_id: "242",
    url: "https://trakt.tv/movies/the-godfather-part-iii-1990",
    released: "1990-12-25",
    runtime: "170",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T23:13:04Z": {
    type: "movie",
    title: "Collateral",
    year: "2004",
    trakt_rating: "7.49567",
    trakt_id: "998",
    imdb_id: "tt0369339",
    tmdb_id: "1538",
    url: "https://trakt.tv/movies/collateral-2004",
    released: "2004-08-04",
    runtime: "120",
    genres: "crime,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T21:55:23Z": {
    type: "movie",
    title: "Sin City",
    year: "2005",
    trakt_rating: "7.79744",
    trakt_id: "151",
    imdb_id: "tt0401792",
    tmdb_id: "187",
    url: "https://trakt.tv/movies/sin-city-2005",
    released: "2005-04-01",
    runtime: "124",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T21:41:47Z": {
    type: "movie",
    title: "Sherlock Holmes: A Game of Shadows",
    year: "2011",
    trakt_rating: "7.59238",
    trakt_id: "41908",
    imdb_id: "tt1515091",
    tmdb_id: "58574",
    url: "https://trakt.tv/movies/sherlock-holmes-a-game-of-shadows-2011",
    released: "2011-12-16",
    runtime: "129",
    genres: "action,adventure,crime,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T21:41:28Z": {
    type: "movie",
    title: "Sherlock Holmes",
    year: "2009",
    trakt_rating: "7.70473",
    trakt_id: "5706",
    imdb_id: "tt0988045",
    tmdb_id: "10528",
    url: "https://trakt.tv/movies/sherlock-holmes-2009",
    released: "2009-12-25",
    runtime: "129",
    genres: "action,adventure,crime,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T20:42:50Z": {
    type: "movie",
    title: "The Crimson Rivers",
    year: "2000",
    trakt_rating: "7.22773",
    trakt_id: "43458",
    imdb_id: "tt0228786",
    tmdb_id: "60670",
    url: "https://trakt.tv/movies/the-crimson-rivers-2000",
    released: "2000-09-27",
    runtime: "106",
    genres: "crime,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T20:23:16Z": {
    type: "movie",
    title: "Mission: Impossible - Rogue Nation",
    year: "2015",
    trakt_rating: "7.50921",
    trakt_id: "112447",
    imdb_id: "tt2381249",
    tmdb_id: "177677",
    url: "https://trakt.tv/movies/mission-impossible-rogue-nation-2015",
    released: "2015-07-31",
    runtime: "131",
    genres: "action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T20:21:33Z": {
    type: "movie",
    title: "Mission: Impossible",
    year: "1996",
    trakt_rating: "7.43023",
    trakt_id: "793",
    imdb_id: "tt0117060",
    tmdb_id: "954",
    url: "https://trakt.tv/movies/mission-impossible-1996",
    released: "1996-05-22",
    runtime: "110",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T13:07:09Z": {
    type: "movie",
    title: "X2",
    year: "2003",
    trakt_rating: "7.41628",
    trakt_id: "23300",
    imdb_id: "tt0290334",
    tmdb_id: "36658",
    url: "https://trakt.tv/movies/x2-2003",
    released: "2003-05-02",
    runtime: "133",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T11:30:06Z": {
    type: "movie",
    title: "X-Men: Days of Future Past",
    year: "2014",
    trakt_rating: "7.82874",
    trakt_id: "87348",
    imdb_id: "tt1877832",
    tmdb_id: "127585",
    url: "https://trakt.tv/movies/x-men-days-of-future-past-2014",
    released: "2014-05-23",
    runtime: "132",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T10:23:46Z": {
    type: "movie",
    title: "The Wolf of Wall Street",
    year: "2013",
    trakt_rating: "8.10907",
    trakt_id: "75735",
    imdb_id: "tt0993846",
    tmdb_id: "106646",
    url: "https://trakt.tv/movies/the-wolf-of-wall-street-2013",
    released: "2013-12-25",
    runtime: "180",
    genres: "comedy,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T07:11:03Z": {
    type: "movie",
    title: "Mission: Impossible II",
    year: "2000",
    trakt_rating: "6.47476",
    trakt_id: "794",
    imdb_id: "tt0120755",
    tmdb_id: "955",
    url: "https://trakt.tv/movies/mission-impossible-ii-2000",
    released: "2000-05-24",
    runtime: "123",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T07:10:55Z": {
    type: "movie",
    title: "Mission: Impossible - Ghost Protocol",
    year: "2011",
    trakt_rating: "7.52877",
    trakt_id: "40108",
    imdb_id: "tt1229238",
    tmdb_id: "56292",
    url: "https://trakt.tv/movies/mission-impossible-ghost-protocol-2011",
    released: "2011-12-21",
    runtime: "133",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T07:10:50Z": {
    type: "movie",
    title: "Mission: Impossible III",
    year: "2006",
    trakt_rating: "7.14362",
    trakt_id: "795",
    imdb_id: "tt0317919",
    tmdb_id: "956",
    url: "https://trakt.tv/movies/mission-impossible-iii-2006",
    released: "2006-05-05",
    runtime: "126",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T07:04:33Z": {
    type: "movie",
    title: "X-Men: The Last Stand",
    year: "2006",
    trakt_rating: "6.85979",
    trakt_id: "23308",
    imdb_id: "tt0376994",
    tmdb_id: "36668",
    url: "https://trakt.tv/movies/x-men-the-last-stand-2006",
    released: "2006-05-26",
    runtime: "104",
    genres: "action,adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T07:02:28Z": {
    type: "movie",
    title: "Nightcrawler",
    year: "2014",
    trakt_rating: "7.75532",
    trakt_id: "147742",
    imdb_id: "tt2872718",
    tmdb_id: "242582",
    url: "https://trakt.tv/movies/nightcrawler-2014",
    released: "2014-10-31",
    runtime: "118",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:58:35Z": {
    type: "movie",
    title: "District 9",
    year: "2009",
    trakt_rating: "7.76789",
    trakt_id: "10803",
    imdb_id: "tt1136608",
    tmdb_id: "17654",
    url: "https://trakt.tv/movies/district-9-2009",
    released: "2009-08-14",
    runtime: "112",
    genres: "science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:56:53Z": {
    type: "movie",
    title: "The Wolverine",
    year: "2013",
    trakt_rating: "7.00134",
    trakt_id: "56230",
    imdb_id: "tt1430132",
    tmdb_id: "76170",
    url: "https://trakt.tv/movies/the-wolverine-2013",
    released: "2013-07-25",
    runtime: "126",
    genres: "action,adventure,fantasy,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:54:17Z": {
    type: "movie",
    title: "Kick-Ass",
    year: "2010",
    trakt_rating: "7.62919",
    trakt_id: "14160",
    imdb_id: "tt1250777",
    tmdb_id: "23483",
    url: "https://trakt.tv/movies/kick-ass-2010",
    released: "2010-04-16",
    runtime: "117",
    genres: "action,crime,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:52:39Z": {
    type: "movie",
    title: "The Fifth Element",
    year: "1997",
    trakt_rating: "8.1907",
    trakt_id: "14",
    imdb_id: "tt0119116",
    tmdb_id: "18",
    url: "https://trakt.tv/movies/the-fifth-element-1997",
    released: "1997-05-07",
    runtime: "126",
    genres: "action,adventure,fantasy,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:50:47Z": {
    type: "movie",
    title: "The Amazing Spider-Man",
    year: "2012",
    trakt_rating: "7.15806",
    trakt_id: "1302",
    imdb_id: "tt0948470",
    tmdb_id: "1930",
    url: "https://trakt.tv/movies/the-amazing-spider-man-2012",
    released: "2012-07-03",
    runtime: "136",
    genres: "action,adventure,fantasy,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:47:05Z": {
    type: "movie",
    title: "Star Trek Into Darkness",
    year: "2013",
    trakt_rating: "7.82077",
    trakt_id: "38322",
    imdb_id: "tt1408101",
    tmdb_id: "54138",
    url: "https://trakt.tv/movies/star-trek-into-darkness-2013",
    released: "2013-05-16",
    runtime: "132",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:43:06Z": {
    type: "movie",
    title: "Man of Steel",
    year: "2013",
    trakt_rating: "7.10935",
    trakt_id: "34507",
    imdb_id: "tt0770828",
    tmdb_id: "49521",
    url: "https://trakt.tv/movies/man-of-steel-2013",
    released: "2013-06-14",
    runtime: "143",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:37:20Z": {
    type: "movie",
    title: "Captain America: The First Avenger",
    year: "2011",
    trakt_rating: "7.44715",
    trakt_id: "1170",
    imdb_id: "tt0458339",
    tmdb_id: "1771",
    url: "https://trakt.tv/movies/captain-america-the-first-avenger-2011",
    released: "2011-07-22",
    runtime: "124",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:34:04Z": {
    type: "movie",
    title: "Ant-Man",
    year: "2015",
    trakt_rating: "7.50989",
    trakt_id: "73582",
    imdb_id: "tt0478970",
    tmdb_id: "102899",
    url: "https://trakt.tv/movies/ant-man-2015",
    released: "2015-07-17",
    runtime: "117",
    genres: "action,science-fiction,adventure,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:32:04Z": {
    type: "movie",
    title: "Iron Man",
    year: "2008",
    trakt_rating: "8.1196",
    trakt_id: "1157",
    imdb_id: "tt0371746",
    tmdb_id: "1726",
    url: "https://trakt.tv/movies/iron-man-2008",
    released: "2008-05-02",
    runtime: "126",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:15:21Z": {
    type: "movie",
    title: "Tron",
    year: "1982",
    trakt_rating: "7.29623",
    trakt_id: "66",
    imdb_id: "tt0084827",
    tmdb_id: "97",
    url: "https://trakt.tv/movies/tron-1982",
    released: "1982-07-09",
    runtime: "96",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:15:20Z": {
    type: "movie",
    title: "TRON: Legacy",
    year: "2010",
    trakt_rating: "7.19736",
    trakt_id: "12601",
    imdb_id: "tt1104001",
    tmdb_id: "20526",
    url: "https://trakt.tv/movies/tron-legacy-2010",
    released: "2010-12-17",
    runtime: "125",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:09:56Z": {
    type: "movie",
    title: "Suicide Kings",
    year: "1998",
    trakt_rating: "6.91743",
    trakt_id: "5840",
    imdb_id: "tt0120241",
    tmdb_id: "10668",
    url: "https://trakt.tv/movies/suicide-kings-1998",
    released: "1998-04-17",
    runtime: "106",
    genres: "action,comedy,crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-18T06:07:03Z": {
    type: "movie",
    title: "Fear and Loathing in Las Vegas",
    year: "1998",
    trakt_rating: "7.63719",
    trakt_id: "1254",
    imdb_id: "tt0120669",
    tmdb_id: "1878",
    url: "https://trakt.tv/movies/fear-and-loathing-in-las-vegas-1998",
    released: "1998-05-22",
    runtime: "118",
    genres: "adventure,comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-16T00:23:16Z": {
    type: "movie",
    title: "Ex Machina",
    year: "2015",
    trakt_rating: "7.71885",
    trakt_id: "163375",
    imdb_id: "tt0470752",
    tmdb_id: "264660",
    url: "https://trakt.tv/movies/ex-machina-2015",
    released: "2015-04-10",
    runtime: "108",
    genres: "drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-14T06:33:31Z": {
    type: "movie",
    title: "Early Man",
    year: "2018",
    trakt_rating: "6.8584",
    trakt_id: "229556",
    imdb_id: "tt4701724",
    tmdb_id: "387592",
    url: "https://trakt.tv/movies/early-man-2018",
    released: "2018-02-16",
    runtime: "89",
    genres: "comedy,animation,family,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-12T06:40:00Z": {
    type: "movie",
    title: "Escape from New York",
    year: "1981",
    trakt_rating: "7.2449",
    trakt_id: "846",
    imdb_id: "tt0082340",
    tmdb_id: "1103",
    url: "https://trakt.tv/movies/escape-from-new-york-1981",
    released: "1981-06-26",
    runtime: "99",
    genres: "action,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-12T02:21:44Z": {
    type: "movie",
    title: "Rush",
    year: "1991",
    trakt_rating: "7.14839",
    trakt_id: "12442",
    imdb_id: "tt0102820",
    tmdb_id: "20289",
    url: "https://trakt.tv/movies/rush-1991",
    released: "1991-12-22",
    runtime: "120",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-12T01:48:02Z": {
    type: "movie",
    title: "This Is England",
    year: "2006",
    trakt_rating: "7.82832",
    trakt_id: "6824",
    imdb_id: "tt0480025",
    tmdb_id: "11798",
    url: "https://trakt.tv/movies/this-is-england-2006",
    released: "2007-04-27",
    runtime: "101",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-11T02:49:51Z": {
    type: "movie",
    title: "Sid and Nancy",
    year: "1986",
    trakt_rating: "7.27903",
    trakt_id: "9052",
    imdb_id: "tt0091954",
    tmdb_id: "14924",
    url: "https://trakt.tv/movies/sid-and-nancy-1986",
    released: "1986-10-24",
    runtime: "114",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-11T02:37:19Z": {
    type: "movie",
    title: "Thirst",
    year: "2009",
    trakt_rating: "7.13365",
    trakt_id: "13714",
    imdb_id: "tt0762073",
    tmdb_id: "22536",
    url: "https://trakt.tv/movies/thirst-2009",
    released: "2009-04-30",
    runtime: "134",
    genres: "drama,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-11T02:30:19Z": {
    type: "movie",
    title: "Bubba Ho-tep",
    year: "2002",
    trakt_rating: "7.01994",
    trakt_id: "4979",
    imdb_id: "tt0281686",
    tmdb_id: "9707",
    url: "https://trakt.tv/movies/bubba-ho-tep-2002",
    released: "2002-06-09",
    runtime: "92",
    genres: "comedy,fantasy,horror,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-11T02:27:07Z": {
    type: "movie",
    title: "Cry-Baby",
    year: "1990",
    trakt_rating: "6.92259",
    trakt_id: "5029",
    imdb_id: "tt0099329",
    tmdb_id: "9768",
    url: "https://trakt.tv/movies/cry-baby-1990",
    released: "1990-04-05",
    runtime: "85",
    genres: "comedy,romance,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-10T01:56:19Z": {
    type: "movie",
    title: "The Titan",
    year: "2018",
    trakt_rating: "5.99077",
    trakt_id: "324153",
    imdb_id: "tt4986098",
    tmdb_id: "476926",
    url: "https://trakt.tv/movies/the-titan-2018",
    released: "2018-03-30",
    runtime: "97",
    genres: "science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-10T00:48:57Z": {
    type: "movie",
    title: "Exit Through the Gift Shop",
    year: "2010",
    trakt_rating: "7.90075",
    trakt_id: "25646",
    imdb_id: "tt1587707",
    tmdb_id: "39452",
    url: "https://trakt.tv/movies/exit-through-the-gift-shop-2010",
    released: "2010-01-24",
    runtime: "87",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-09T11:09:11Z": {
    type: "movie",
    title: "The Simpsons Movie",
    year: "2007",
    trakt_rating: "7.36756",
    trakt_id: "28",
    imdb_id: "tt0462538",
    tmdb_id: "35",
    url: "https://trakt.tv/movies/the-simpsons-movie-2007",
    released: "2007-07-27",
    runtime: "87",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-09T02:39:15Z": {
    type: "movie",
    title: "Boys Don't Cry",
    year: "1999",
    trakt_rating: "7.58959",
    trakt_id: "185",
    imdb_id: "tt0171804",
    tmdb_id: "226",
    url: "https://trakt.tv/movies/boys-don-t-cry-1999",
    released: "1999-10-22",
    runtime: "118",
    genres: "crime,drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-09T02:36:12Z": {
    type: "movie",
    title: "Alien Resurrection",
    year: "1997",
    trakt_rating: "6.62684",
    trakt_id: "4045",
    imdb_id: "tt0118583",
    tmdb_id: "8078",
    url: "https://trakt.tv/movies/alien-resurrection-1997",
    released: "1997-11-26",
    runtime: "109",
    genres: "action,horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-09T01:10:51Z": {
    type: "movie",
    title: "Red Sparrow",
    year: "2018",
    trakt_rating: "7.09547",
    trakt_id: "254340",
    imdb_id: "tt2873282",
    tmdb_id: "401981",
    url: "https://trakt.tv/movies/red-sparrow-2018",
    released: "2018-03-02",
    runtime: "140",
    genres: "thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-07T23:42:23Z": {
    type: "movie",
    title: "Fallen",
    year: "1998",
    trakt_rating: "7.31087",
    trakt_id: "4700",
    imdb_id: "tt0119099",
    tmdb_id: "9411",
    url: "https://trakt.tv/movies/fallen-1998",
    released: "1998-01-16",
    runtime: "124",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-07T00:32:06Z": {
    type: "movie",
    title: "Open Water",
    year: "2003",
    trakt_rating: "5.93879",
    trakt_id: "53",
    imdb_id: "tt0374102",
    tmdb_id: "83",
    url: "https://trakt.tv/movies/open-water-2003",
    released: "2003-10-26",
    runtime: "79",
    genres: "drama,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-07T00:04:32Z": {
    type: "movie",
    title: "When We Were Kings",
    year: "1996",
    trakt_rating: "7.82315",
    trakt_id: "5726",
    imdb_id: "tt0118147",
    tmdb_id: "10548",
    url: "https://trakt.tv/movies/when-we-were-kings-1996",
    released: "1996-10-25",
    runtime: "89",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-06T23:52:33Z": {
    type: "movie",
    title: "Hearts of Darkness: A Filmmaker's Apocalypse",
    year: "1991",
    trakt_rating: "8.08333",
    trakt_id: "2667",
    imdb_id: "tt0102015",
    tmdb_id: "4539",
    url: "https://trakt.tv/movies/hearts-of-darkness-a-filmmaker-s-apocalypse-1991",
    released: "1991-11-27",
    runtime: "96",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-06T16:13:28Z": {
    type: "movie",
    title: "Gomorrah",
    year: "2008",
    trakt_rating: "7.09882",
    trakt_id: "4366",
    imdb_id: "tt0929425",
    tmdb_id: "8882",
    url: "https://trakt.tv/movies/gomorrah-2008",
    released: "2008-05-16",
    runtime: "136",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-06T16:10:26Z": {
    type: "movie",
    title: "LEGO DC Comics Super Heroes: Justice League: Cosmic Clash",
    year: "2016",
    trakt_rating: "7.08276",
    trakt_id: "225714",
    imdb_id: "tt5251438",
    tmdb_id: "382512",
    url: "https://trakt.tv/movies/lego-dc-comics-super-heroes-justice-league-cosmic-clash-2016",
    released: "2016-02-16",
    runtime: "78",
    genres: "animation,family,action,superhero,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-05T14:24:58Z": {
    type: "movie",
    title: "Wreck-It Ralph",
    year: "2012",
    trakt_rating: "7.82071",
    trakt_id: "61864",
    imdb_id: "tt1772341",
    tmdb_id: "82690",
    url: "https://trakt.tv/movies/wreck-it-ralph-2012",
    released: "2012-11-02",
    runtime: "101",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-04T20:38:20Z": {
    type: "movie",
    title: "Rollerball",
    year: "1975",
    trakt_rating: "6.80769",
    trakt_id: "6570",
    imdb_id: "tt0073631",
    tmdb_id: "11484",
    url: "https://trakt.tv/movies/rollerball-1975",
    released: "1975-06-25",
    runtime: "125",
    genres: "action,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-04T15:46:41Z": {
    type: "movie",
    title: "The Gift",
    year: "2015",
    trakt_rating: "6.95487",
    trakt_id: "209322",
    imdb_id: "tt4178092",
    tmdb_id: "328425",
    url: "https://trakt.tv/movies/the-gift-2015-08-07",
    released: "2015-08-07",
    runtime: "108",
    genres: "thriller,mystery,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-04T13:50:58Z": {
    type: "movie",
    title: "The Firm",
    year: "1989",
    trakt_rating: "7.41096",
    trakt_id: "10587",
    imdb_id: "tt0095158",
    tmdb_id: "17300",
    url: "https://trakt.tv/movies/the-firm-1989",
    released: "1989-02-26",
    runtime: "70",
    genres: "drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-04T13:40:17Z": {
    type: "movie",
    title: "El Mariachi",
    year: "1992",
    trakt_rating: "7.08901",
    trakt_id: "4658",
    imdb_id: "tt0104815",
    tmdb_id: "9367",
    url: "https://trakt.tv/movies/el-mariachi-1992",
    released: "1992-09-04",
    runtime: "81",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-04T13:30:04Z": {
    type: "movie",
    title: "Black Swan",
    year: "2010",
    trakt_rating: "7.80219",
    trakt_id: "29936",
    imdb_id: "tt0947798",
    tmdb_id: "44214",
    url: "https://trakt.tv/movies/black-swan-2010",
    released: "2010-12-03",
    runtime: "108",
    genres: "drama,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-04T12:05:45Z": {
    type: "movie",
    title: "Batman Ninja",
    year: "2018",
    trakt_rating: "6.3933",
    trakt_id: "333008",
    imdb_id: "tt7451284",
    tmdb_id: "485942",
    url: "https://trakt.tv/movies/batman-ninja-2018",
    released: "2018-04-24",
    runtime: "85",
    genres: "anime,action,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-04T01:25:14Z": {
    type: "movie",
    title: "Darkman",
    year: "1990",
    trakt_rating: "6.63544",
    trakt_id: "4834",
    imdb_id: "tt0099365",
    tmdb_id: "9556",
    url: "https://trakt.tv/movies/darkman-1990",
    released: "1990-08-24",
    runtime: "95",
    genres: "action,science-fiction,superhero,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-04T00:25:42Z": {
    type: "movie",
    title: "Die Hard 2",
    year: "1990",
    trakt_rating: "7.55437",
    trakt_id: "1030",
    imdb_id: "tt0099423",
    tmdb_id: "1573",
    url: "https://trakt.tv/movies/die-hard-2-1990",
    released: "1990-07-03",
    runtime: "124",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-04T00:24:55Z": {
    type: "movie",
    title: "Die Hard: With a Vengeance",
    year: "1995",
    trakt_rating: "7.82119",
    trakt_id: "1029",
    imdb_id: "tt0112864",
    tmdb_id: "1572",
    url: "https://trakt.tv/movies/die-hard-with-a-vengeance-1995",
    released: "1995-05-19",
    runtime: "128",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T23:59:41Z": {
    type: "movie",
    title: "Quantum of Solace",
    year: "2008",
    trakt_rating: "6.89529",
    trakt_id: "5927",
    imdb_id: "tt0830515",
    tmdb_id: "10764",
    url: "https://trakt.tv/movies/quantum-of-solace-2008",
    released: "2008-11-14",
    runtime: "106",
    genres: "action,adventure,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T23:59:26Z": {
    type: "movie",
    title: "Die Another Day",
    year: "2002",
    trakt_rating: "6.62142",
    trakt_id: "23309",
    imdb_id: "tt0246460",
    tmdb_id: "36669",
    url: "https://trakt.tv/movies/die-another-day-2002",
    released: "2002-11-22",
    runtime: "133",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T23:49:50Z": {
    type: "movie",
    title: "GoldenEye",
    year: "1995",
    trakt_rating: "7.39916",
    trakt_id: "582",
    imdb_id: "tt0113189",
    tmdb_id: "710",
    url: "https://trakt.tv/movies/goldeneye-1995",
    released: "1995-11-16",
    runtime: "130",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T23:48:43Z": {
    type: "movie",
    title: "Tomorrow Never Dies",
    year: "1997",
    trakt_rating: "6.88006",
    trakt_id: "586",
    imdb_id: "tt0120347",
    tmdb_id: "714",
    url: "https://trakt.tv/movies/tomorrow-never-dies-1997",
    released: "1997-12-12",
    runtime: "119",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T23:48:36Z": {
    type: "movie",
    title: "The World Is Not Enough",
    year: "1999",
    trakt_rating: "6.84279",
    trakt_id: "23290",
    imdb_id: "tt0143145",
    tmdb_id: "36643",
    url: "https://trakt.tv/movies/the-world-is-not-enough-1999",
    released: "1999-11-19",
    runtime: "128",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T08:47:45Z": {
    type: "movie",
    title: "The Living Daylights",
    year: "1987",
    trakt_rating: "6.92978",
    trakt_id: "580",
    imdb_id: "tt0093428",
    tmdb_id: "708",
    url: "https://trakt.tv/movies/the-living-daylights-1987",
    released: "1987-06-29",
    runtime: "130",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T08:47:35Z": {
    type: "movie",
    title: "Licence to Kill",
    year: "1989",
    trakt_rating: "6.94357",
    trakt_id: "581",
    imdb_id: "tt0097742",
    tmdb_id: "709",
    url: "https://trakt.tv/movies/licence-to-kill-1989",
    released: "1989-07-14",
    runtime: "133",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T08:47:20Z": {
    type: "movie",
    title: "A View to a Kill",
    year: "1985",
    trakt_rating: "6.74069",
    trakt_id: "579",
    imdb_id: "tt0090264",
    tmdb_id: "707",
    url: "https://trakt.tv/movies/a-view-to-a-kill-1985",
    released: "1985-06-12",
    runtime: "131",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T08:47:10Z": {
    type: "movie",
    title: "Never Say Never Again",
    year: "1983",
    trakt_rating: "6.79649",
    trakt_id: "23310",
    imdb_id: "tt0086006",
    tmdb_id: "36670",
    url: "https://trakt.tv/movies/never-say-never-again-1983",
    released: "1983-10-07",
    runtime: "134",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T04:49:07Z": {
    type: "movie",
    title: "The Man with the Golden Gun",
    year: "1974",
    trakt_rating: "6.90806",
    trakt_id: "556",
    imdb_id: "tt0071807",
    tmdb_id: "682",
    url: "https://trakt.tv/movies/the-man-with-the-golden-gun-1974",
    released: "1974-12-20",
    runtime: "125",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T04:47:53Z": {
    type: "movie",
    title: "For Your Eyes Only",
    year: "1981",
    trakt_rating: "7.04724",
    trakt_id: "571",
    imdb_id: "tt0082398",
    tmdb_id: "699",
    url: "https://trakt.tv/movies/for-your-eyes-only-1981",
    released: "1981-06-26",
    runtime: "128",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T04:47:08Z": {
    type: "movie",
    title: "The Spy Who Loved Me",
    year: "1977",
    trakt_rating: "7.20758",
    trakt_id: "563",
    imdb_id: "tt0076752",
    tmdb_id: "691",
    url: "https://trakt.tv/movies/the-spy-who-loved-me-1977",
    released: "1977-08-03",
    runtime: "125",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T04:46:59Z": {
    type: "movie",
    title: "Moonraker",
    year: "1979",
    trakt_rating: "6.71802",
    trakt_id: "570",
    imdb_id: "tt0079574",
    tmdb_id: "698",
    url: "https://trakt.tv/movies/moonraker-1979",
    released: "1979-09-11",
    runtime: "126",
    genres: "action,adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T04:46:44Z": {
    type: "movie",
    title: "Octopussy",
    year: "1983",
    trakt_rating: "6.83369",
    trakt_id: "572",
    imdb_id: "tt0086034",
    tmdb_id: "700",
    url: "https://trakt.tv/movies/octopussy-1983",
    released: "1983-06-05",
    runtime: "131",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T02:39:26Z": {
    type: "movie",
    title: "Live and Let Die",
    year: "1973",
    trakt_rating: "6.99618",
    trakt_id: "211",
    imdb_id: "tt0070328",
    tmdb_id: "253",
    url: "https://trakt.tv/movies/live-and-let-die-1973",
    released: "1973-07-06",
    runtime: "121",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T02:38:42Z": {
    type: "movie",
    title: "On Her Majesty's Secret Service",
    year: "1969",
    trakt_rating: "6.84837",
    trakt_id: "542",
    imdb_id: "tt0064757",
    tmdb_id: "668",
    url: "https://trakt.tv/movies/on-her-majesty-s-secret-service-1969",
    released: "1969-12-18",
    runtime: "142",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T02:38:41Z": {
    type: "movie",
    title: "Diamonds Are Forever",
    year: "1971",
    trakt_rating: "6.92612",
    trakt_id: "555",
    imdb_id: "tt0066995",
    tmdb_id: "681",
    url: "https://trakt.tv/movies/diamonds-are-forever-1971",
    released: "1971-12-17",
    runtime: "120",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T02:37:04Z": {
    type: "movie",
    title: "You Only Live Twice",
    year: "1967",
    trakt_rating: "7.08873",
    trakt_id: "541",
    imdb_id: "tt0062512",
    tmdb_id: "667",
    url: "https://trakt.tv/movies/you-only-live-twice-1967",
    released: "1967-06-13",
    runtime: "117",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T01:19:50Z": {
    type: "movie",
    title: "Dr. No",
    year: "1962",
    trakt_rating: "7.36422",
    trakt_id: "521",
    imdb_id: "tt0055928",
    tmdb_id: "646",
    url: "https://trakt.tv/movies/dr-no-1962",
    released: "1962-10-07",
    runtime: "110",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T01:19:38Z": {
    type: "movie",
    title: "From Russia with Love",
    year: "1963",
    trakt_rating: "7.38361",
    trakt_id: "531",
    imdb_id: "tt0057076",
    tmdb_id: "657",
    url: "https://trakt.tv/movies/from-russia-with-love-1963",
    released: "1964-04-08",
    runtime: "115",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T01:18:59Z": {
    type: "movie",
    title: "Thunderball",
    year: "1965",
    trakt_rating: "7.13067",
    trakt_id: "534",
    imdb_id: "tt0059800",
    tmdb_id: "660",
    url: "https://trakt.tv/movies/thunderball-1965",
    released: "1965-12-28",
    runtime: "130",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-03T00:44:40Z": {
    type: "movie",
    title: "The Royal Tenenbaums",
    year: "2001",
    trakt_rating: "7.60292",
    trakt_id: "4717",
    imdb_id: "tt0265666",
    tmdb_id: "9428",
    url: "https://trakt.tv/movies/the-royal-tenenbaums-2001",
    released: "2001-12-14",
    runtime: "110",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-05-02T09:48:57Z": {
    type: "movie",
    title: "Black Panther",
    year: "2018",
    trakt_rating: "7.65966",
    trakt_id: "181313",
    imdb_id: "tt1825683",
    tmdb_id: "284054",
    url: "https://trakt.tv/movies/black-panther-2018",
    released: "2018-02-16",
    runtime: "135",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-30T05:22:53Z": {
    type: "movie",
    title: "Pi",
    year: "1998",
    trakt_rating: "7.29639",
    trakt_id: "374",
    imdb_id: "tt0138704",
    tmdb_id: "473",
    url: "https://trakt.tv/movies/pi-1998",
    released: "1998-07-10",
    runtime: "84",
    genres: "drama,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-28T00:31:56Z": {
    type: "movie",
    title: "Psychokinesis",
    year: "2018",
    trakt_rating: "6.45276",
    trakt_id: "298672",
    imdb_id: "tt6890582",
    tmdb_id: "452015",
    url: "https://trakt.tv/movies/psychokinesis-2018",
    released: "2018-01-31",
    runtime: "101",
    genres: "fantasy,action,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-28T00:27:47Z": {
    type: "movie",
    title: "The Incredibles",
    year: "2004",
    trakt_rating: "8.05341",
    trakt_id: "5065",
    imdb_id: "tt0317705",
    tmdb_id: "9806",
    url: "https://trakt.tv/movies/the-incredibles-2004",
    released: "2004-11-05",
    runtime: "115",
    genres: "animation,family,adventure,action,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-26T11:52:05Z": {
    type: "movie",
    title: "Ju-on: The Grudge",
    year: "2002",
    trakt_rating: "6.7952",
    trakt_id: "6861",
    imdb_id: "tt0364385",
    tmdb_id: "11838",
    url: "https://trakt.tv/movies/ju-on-the-grudge-2002",
    released: "2003-01-25",
    runtime: "92",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-26T11:14:38Z": {
    type: "movie",
    title: "Ring",
    year: "1998",
    trakt_rating: "7.23077",
    trakt_id: "1802",
    imdb_id: "tt0178868",
    tmdb_id: "2671",
    url: "https://trakt.tv/movies/ring-1998",
    released: "1998-01-30",
    runtime: "96",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-26T11:14:21Z": {
    type: "movie",
    title: "Dark Water",
    year: "2002",
    trakt_rating: "6.79167",
    trakt_id: "7110",
    imdb_id: "tt0308379",
    tmdb_id: "12205",
    url: "https://trakt.tv/movies/dark-water-2002",
    released: "2002-01-19",
    runtime: "101",
    genres: "horror,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-25T12:47:09Z": {
    type: "movie",
    title: "The Post",
    year: "2017",
    trakt_rating: "7.41356",
    trakt_id: "292956",
    imdb_id: "tt6294822",
    tmdb_id: "446354",
    url: "https://trakt.tv/movies/the-post-2017",
    released: "2017-12-22",
    runtime: "116",
    genres: "history,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-24T12:28:31Z": {
    type: "movie",
    title: "Superman II",
    year: "1980",
    trakt_rating: "7.06321",
    trakt_id: "4231",
    imdb_id: "tt0081573",
    tmdb_id: "8536",
    url: "https://trakt.tv/movies/superman-ii-1980",
    released: "1981-06-01",
    runtime: "127",
    genres: "science-fiction,superhero,action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-24T12:28:20Z": {
    type: "movie",
    title: "Superman",
    year: "1978",
    trakt_rating: "7.46158",
    trakt_id: "1298",
    imdb_id: "tt0078346",
    tmdb_id: "1924",
    url: "https://trakt.tv/movies/superman-1978",
    released: "1978-12-14",
    runtime: "143",
    genres: "science-fiction,superhero,action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-24T07:13:30Z": {
    type: "movie",
    title: "Ferris Bueller's Day Off",
    year: "1986",
    trakt_rating: "8.05236",
    trakt_id: "4667",
    imdb_id: "tt0091042",
    tmdb_id: "9377",
    url: "https://trakt.tv/movies/ferris-bueller-s-day-off-1986",
    released: "1986-06-11",
    runtime: "103",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-23T04:44:31Z": {
    type: "movie",
    title: "Genesis",
    year: "2018",
    trakt_rating: "5.25926",
    trakt_id: "310525",
    imdb_id: "tt4469518",
    tmdb_id: "463325",
    url: "https://trakt.tv/movies/genesis-2018-04-21",
    released: "2018-04-21",
    runtime: "114",
    genres: "science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-22T06:51:48Z": {
    type: "movie",
    title: "Source Code",
    year: "2011",
    trakt_rating: "7.55416",
    trakt_id: "31151",
    imdb_id: "tt0945513",
    tmdb_id: "45612",
    url: "https://trakt.tv/movies/source-code-2011",
    released: "2011-04-01",
    runtime: "94",
    genres: "mystery,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-22T02:52:05Z": {
    type: "movie",
    title: "Contagion",
    year: "2011",
    trakt_rating: "7.06105",
    trakt_id: "25724",
    imdb_id: "tt1598778",
    tmdb_id: "39538",
    url: "https://trakt.tv/movies/contagion-2011",
    released: "2011-09-09",
    runtime: "106",
    genres: "drama,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-19T01:57:01Z": {
    type: "movie",
    title: "Peter Rabbit",
    year: "2018",
    trakt_rating: "7.3315",
    trakt_id: "268305",
    imdb_id: "tt5117670",
    tmdb_id: "381719",
    url: "https://trakt.tv/movies/peter-rabbit-2018",
    released: "2018-02-09",
    runtime: "93",
    genres: "animation,adventure,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-19T01:53:51Z": {
    type: "movie",
    title: "Bomb City",
    year: "2017",
    trakt_rating: "7.39692",
    trakt_id: "328742",
    imdb_id: "tt4351548",
    tmdb_id: "396774",
    url: "https://trakt.tv/movies/bomb-city-2017",
    released: "2017-03-31",
    runtime: "95",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-14T20:02:38Z": {
    type: "movie",
    title: "My Own Private Idaho",
    year: "1991",
    trakt_rating: "7.16652",
    trakt_id: "370",
    imdb_id: "tt0102494",
    tmdb_id: "468",
    url: "https://trakt.tv/movies/my-own-private-idaho-1991",
    released: "1991-09-29",
    runtime: "104",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-13T02:15:48Z": {
    type: "movie",
    title: "Deep Red",
    year: "1975",
    trakt_rating: "7.62454",
    trakt_id: "12352",
    imdb_id: "tt0073582",
    tmdb_id: "20126",
    url: "https://trakt.tv/movies/deep-red-1975",
    released: "1975-03-07",
    runtime: "127",
    genres: "horror,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-13T02:11:28Z": {
    type: "movie",
    title: "Fright Night",
    year: "1985",
    trakt_rating: "7.20515",
    trakt_id: "6823",
    imdb_id: "tt0089175",
    tmdb_id: "11797",
    url: "https://trakt.tv/movies/fright-night-1985",
    released: "1985-08-02",
    runtime: "107",
    genres: "horror,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-12T11:43:26Z": {
    type: "movie",
    title: "The Adventures of Buckaroo Banzai Across the 8th Dimension",
    year: "1984",
    trakt_rating: "6.71798",
    trakt_id: "6475",
    imdb_id: "tt0086856",
    tmdb_id: "11379",
    url: "https://trakt.tv/movies/the-adventures-of-buckaroo-banzai-across-the-8th-dimension-1984",
    released: "1984-08-15",
    runtime: "103",
    genres: "comedy,science-fiction,adventure,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-11T09:40:35Z": {
    type: "movie",
    title: "Runaway Train",
    year: "1985",
    trakt_rating: "7.19176",
    trakt_id: "6911",
    imdb_id: "tt0089941",
    tmdb_id: "11893",
    url: "https://trakt.tv/movies/runaway-train-1985",
    released: "1985-11-15",
    runtime: "111",
    genres: "action,thriller,drama,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-11T00:07:34Z": {
    type: "movie",
    title: "Detroit",
    year: "2017",
    trakt_rating: "7.5391",
    trakt_id: "260330",
    imdb_id: "tt5390504",
    tmdb_id: "407448",
    url: "https://trakt.tv/movies/detroit-2017",
    released: "2017-07-28",
    runtime: "143",
    genres: "crime,drama,history,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-10T01:56:08Z": {
    type: "movie",
    title: "Rosemary's Baby",
    year: "1968",
    trakt_rating: "7.82699",
    trakt_id: "648",
    imdb_id: "tt0063522",
    tmdb_id: "805",
    url: "https://trakt.tv/movies/rosemary-s-baby-1968",
    released: "1968-06-12",
    runtime: "138",
    genres: "drama,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-10T01:50:08Z": {
    type: "movie",
    title: "The Orphanage",
    year: "2007",
    trakt_rating: "7.34333",
    trakt_id: "3674",
    imdb_id: "tt0464141",
    tmdb_id: "6537",
    url: "https://trakt.tv/movies/the-orphanage-2007",
    released: "2007-10-11",
    runtime: "105",
    genres: "drama,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-10T01:41:19Z": {
    type: "movie",
    title: "The Great Wall",
    year: "2016",
    trakt_rating: "6.82998",
    trakt_id: "191328",
    imdb_id: "tt2034800",
    tmdb_id: "311324",
    url: "https://trakt.tv/movies/the-great-wall-2016",
    released: "2017-02-17",
    runtime: "103",
    genres: "adventure,action,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-10T01:30:20Z": {
    type: "movie",
    title: "The Italian Job",
    year: "1969",
    trakt_rating: "7.51494",
    trakt_id: "5714",
    imdb_id: "tt0064505",
    tmdb_id: "10536",
    url: "https://trakt.tv/movies/the-italian-job-1969",
    released: "1969-06-02",
    runtime: "99",
    genres: "action,comedy,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-08T23:47:45Z": {
    type: "movie",
    title: "Small Town Crime",
    year: "2018",
    trakt_rating: "7.07926",
    trakt_id: "257505",
    imdb_id: "tt5751998",
    tmdb_id: "412000",
    url: "https://trakt.tv/movies/small-town-crime-2018",
    released: "2018-01-19",
    runtime: "91",
    genres: "thriller,crime,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-08T22:49:12Z": {
    type: "movie",
    title: "Black Hawk Down",
    year: "2001",
    trakt_rating: "7.86514",
    trakt_id: "698",
    imdb_id: "tt0265086",
    tmdb_id: "855",
    url: "https://trakt.tv/movies/black-hawk-down-2001",
    released: "2001-12-28",
    runtime: "145",
    genres: "action,history,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-08T21:44:28Z": {
    type: "movie",
    title: "The Third Man",
    year: "1949",
    trakt_rating: "7.88599",
    trakt_id: "844",
    imdb_id: "tt0041959",
    tmdb_id: "1092",
    url: "https://trakt.tv/movies/the-third-man-1949",
    released: "1949-09-02",
    runtime: "104",
    genres: "mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-08T19:35:20Z": {
    type: "movie",
    title: "The Wizard of Oz",
    year: "1939",
    trakt_rating: "7.84148",
    trakt_id: "506",
    imdb_id: "tt0032138",
    tmdb_id: "630",
    url: "https://trakt.tv/movies/the-wizard-of-oz-1939",
    released: "1939-08-25",
    runtime: "102",
    genres: "adventure,fantasy,family,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-08T16:29:12Z": {
    type: "movie",
    title: "Enemy at the Gates",
    year: "2001",
    trakt_rating: "7.82347",
    trakt_id: "696",
    imdb_id: "tt0215750",
    tmdb_id: "853",
    url: "https://trakt.tv/movies/enemy-at-the-gates-2001",
    released: "2001-03-16",
    runtime: "131",
    genres: "war,history,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-08T02:13:39Z": {
    type: "movie",
    title: "Dave Allen at Peace",
    year: "2018",
    trakt_rating: "6.18182",
    trakt_id: "363682",
    imdb_id: "tt7912316",
    tmdb_id: "515169",
    url: "https://trakt.tv/movies/dave-allen-at-peace-2018",
    released: "2018-04-02",
    runtime: "60",
    genres: "drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-06T23:40:48Z": {
    type: "movie",
    title: "Bram Stoker's Dracula",
    year: "1992",
    trakt_rating: "7.56248",
    trakt_id: "3489",
    imdb_id: "tt0103874",
    tmdb_id: "6114",
    url: "https://trakt.tv/movies/bram-stoker-s-dracula-1992",
    released: "1992-11-13",
    runtime: "128",
    genres: "horror,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-06T23:38:39Z": {
    type: "movie",
    title: "Interview with the Vampire",
    year: "1994",
    trakt_rating: "7.7019",
    trakt_id: "504",
    imdb_id: "tt0110148",
    tmdb_id: "628",
    url: "https://trakt.tv/movies/interview-with-the-vampire-1994",
    released: "1994-11-11",
    runtime: "123",
    genres: "horror,drama,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-05T01:45:05Z": {
    type: "movie",
    title: "To Kill a Mockingbird",
    year: "1962",
    trakt_rating: "7.99632",
    trakt_id: "473",
    imdb_id: "tt0056592",
    tmdb_id: "595",
    url: "https://trakt.tv/movies/to-kill-a-mockingbird-1962",
    released: "1962-12-25",
    runtime: "129",
    genres: "drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-05T01:44:39Z": {
    type: "movie",
    title: "High Noon",
    year: "1952",
    trakt_rating: "7.613",
    trakt_id: "242",
    imdb_id: "tt0044706",
    tmdb_id: "288",
    url: "https://trakt.tv/movies/high-noon-1952",
    released: "1952-07-30",
    runtime: "85",
    genres: "western,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-04-02T23:39:00Z": {
    type: "movie",
    title: "The Bridge",
    year: "2006",
    trakt_rating: "7.34072",
    trakt_id: "1109",
    imdb_id: "tt0799954",
    tmdb_id: "1666",
    url: "https://trakt.tv/movies/the-bridge-2006",
    released: "2006-10-27",
    runtime: "93",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-30T08:53:00Z": {
    type: "movie",
    title: "A Perfect Getaway",
    year: "2009",
    trakt_rating: "6.74794",
    trakt_id: "7196",
    imdb_id: "tt0971209",
    tmdb_id: "12403",
    url: "https://trakt.tv/movies/a-perfect-getaway-2009",
    released: "2009-06-08",
    runtime: "98",
    genres: "adventure,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-26T20:07:38Z": {
    type: "movie",
    title: "Austin Powers: International Man of Mystery",
    year: "1997",
    trakt_rating: "7.19688",
    trakt_id: "659",
    imdb_id: "tt0118655",
    tmdb_id: "816",
    url: "https://trakt.tv/movies/austin-powers-international-man-of-mystery-1997",
    released: "1997-05-02",
    runtime: "94",
    genres: "comedy,crime,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-26T20:07:33Z": {
    type: "movie",
    title: "Austin Powers in Goldmember",
    year: "2002",
    trakt_rating: "6.65991",
    trakt_id: "661",
    imdb_id: "tt0295178",
    tmdb_id: "818",
    url: "https://trakt.tv/movies/austin-powers-in-goldmember-2002",
    released: "2002-07-26",
    runtime: "93",
    genres: "comedy,crime,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-26T20:03:30Z": {
    type: "movie",
    title: "Night of the Living Dead",
    year: "1968",
    trakt_rating: "7.67703",
    trakt_id: "5531",
    imdb_id: "tt0063350",
    tmdb_id: "10331",
    url: "https://trakt.tv/movies/night-of-the-living-dead-1968",
    released: "1968-10-04",
    runtime: "96",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-26T19:50:17Z": {
    type: "movie",
    title: "Run Lola Run",
    year: "1998",
    trakt_rating: "7.54474",
    trakt_id: "73",
    imdb_id: "tt0130827",
    tmdb_id: "104",
    url: "https://trakt.tv/movies/run-lola-run-1998",
    released: "1998-08-20",
    runtime: "81",
    genres: "action,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-25T23:22:52Z": {
    type: "movie",
    title: "Ichi the Killer",
    year: "2001",
    trakt_rating: "7.26731",
    trakt_id: "4968",
    imdb_id: "tt0296042",
    tmdb_id: "9696",
    url: "https://trakt.tv/movies/ichi-the-killer-2001",
    released: "2001-12-22",
    runtime: "129",
    genres: "action,crime,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-24T14:45:57Z": {
    type: "movie",
    title: "Man Bites Dog",
    year: "1992",
    trakt_rating: "7.45362",
    trakt_id: "5306",
    imdb_id: "tt0103905",
    tmdb_id: "10086",
    url: "https://trakt.tv/movies/man-bites-dog-1992",
    released: "1992-08-20",
    runtime: "96",
    genres: "crime,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-24T14:27:35Z": {
    type: "movie",
    title: "Time Lapse",
    year: "2014",
    trakt_rating: "6.73709",
    trakt_id: "171172",
    imdb_id: "tt2669336",
    tmdb_id: "273271",
    url: "https://trakt.tv/movies/time-lapse-2014",
    released: "2014-05-24",
    runtime: "104",
    genres: "science-fiction,thriller,mystery,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-24T14:04:09Z": {
    type: "movie",
    title: "Lego DC Comics Super Heroes: The Flash",
    year: "2018",
    trakt_rating: "7.30918",
    trakt_id: "352198",
    imdb_id: "tt7877382",
    tmdb_id: "504997",
    url: "https://trakt.tv/movies/lego-dc-comics-super-heroes-the-flash-2018",
    released: "2018-02-13",
    runtime: "78",
    genres: "animation,family,adventure,comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-24T09:08:15Z": {
    type: "movie",
    title: "All the Money in the World",
    year: "2017",
    trakt_rating: "7.08623",
    trakt_id: "293456",
    imdb_id: "tt5294550",
    tmdb_id: "446791",
    url: "https://trakt.tv/movies/all-the-money-in-the-world-2017",
    released: "2017-12-25",
    runtime: "132",
    genres: "drama,thriller,crime,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-23T22:41:57Z": {
    type: "movie",
    title: "The Transformers: The Movie",
    year: "1986",
    trakt_rating: "7.76451",
    trakt_id: "1236",
    imdb_id: "tt0092106",
    tmdb_id: "1857",
    url: "https://trakt.tv/movies/the-transformers-the-movie-1986",
    released: "1986-08-08",
    runtime: "84",
    genres: "animation,science-fiction,action,adventure,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-23T22:40:48Z": {
    type: "movie",
    title: "Wristcutters: A Love Story",
    year: "2007",
    trakt_rating: "7.36807",
    trakt_id: "7744",
    imdb_id: "tt0477139",
    tmdb_id: "13198",
    url: "https://trakt.tv/movies/wristcutters-a-love-story-2007",
    released: "2007-10-19",
    runtime: "88",
    genres: "comedy,drama,romance,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-22T14:21:03Z": {
    type: "movie",
    title: "Borg vs McEnroe",
    year: "2017",
    trakt_rating: "7.09221",
    trakt_id: "240435",
    imdb_id: "tt5727282",
    tmdb_id: "397538",
    url: "https://trakt.tv/movies/borg-vs-mcenroe-2017",
    released: "2017-09-14",
    runtime: "108",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-22T08:24:40Z": {
    type: "movie",
    title: "Jackie Brown",
    year: "1997",
    trakt_rating: "7.57562",
    trakt_id: "148",
    imdb_id: "tt0119396",
    tmdb_id: "184",
    url: "https://trakt.tv/movies/jackie-brown-1997",
    released: "1997-12-25",
    runtime: "154",
    genres: "crime,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-22T01:56:16Z": {
    type: "movie",
    title: "The Greatest Showman",
    year: "2017",
    trakt_rating: "7.97045",
    trakt_id: "196835",
    imdb_id: "tt1485796",
    tmdb_id: "316029",
    url: "https://trakt.tv/movies/the-greatest-showman-2017",
    released: "2017-12-20",
    runtime: "105",
    genres: "drama,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-22T01:52:16Z": {
    type: "movie",
    title: "The Man Who Wasn't There",
    year: "2001",
    trakt_rating: "7.49417",
    trakt_id: "5939",
    imdb_id: "tt0243133",
    tmdb_id: "10778",
    url: "https://trakt.tv/movies/the-man-who-wasn-t-there-2001",
    released: "2001-11-16",
    runtime: "116",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-20T00:54:51Z": {
    type: "movie",
    title: "Insidious: The Last Key",
    year: "2018",
    trakt_rating: "6.58847",
    trakt_id: "250712",
    imdb_id: "tt5726086",
    tmdb_id: "406563",
    url: "https://trakt.tv/movies/insidious-the-last-key-2018",
    released: "2018-01-05",
    runtime: "103",
    genres: "thriller,mystery,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-17T06:07:54Z": {
    type: "movie",
    title: "Thelma",
    year: "2017",
    trakt_rating: "7.12465",
    trakt_id: "277979",
    imdb_id: "tt6304046",
    tmdb_id: "401898",
    url: "https://trakt.tv/movies/thelma-2017",
    released: "2017-11-30",
    runtime: "116",
    genres: "drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-16T20:30:40Z": {
    type: "movie",
    title: "The Snowman",
    year: "2017",
    trakt_rating: "6.05481",
    trakt_id: "226001",
    imdb_id: "tt1758810",
    tmdb_id: "372343",
    url: "https://trakt.tv/movies/the-snowman-2017",
    released: "2017-10-20",
    runtime: "119",
    genres: "thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-16T14:12:53Z": {
    type: "movie",
    title: "Annihilation",
    year: "2018",
    trakt_rating: "6.82865",
    trakt_id: "220563",
    imdb_id: "tt2798920",
    tmdb_id: "300668",
    url: "https://trakt.tv/movies/annihilation-2018",
    released: "2018-02-23",
    runtime: "115",
    genres: "science-fiction,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-11T03:06:53Z": {
    type: "movie",
    title: "Star Wars: The Last Jedi",
    year: "2017",
    trakt_rating: "7.27124",
    trakt_id: "114333",
    imdb_id: "tt2527336",
    tmdb_id: "181808",
    url: "https://trakt.tv/movies/star-wars-the-last-jedi-2017",
    released: "2017-12-15",
    runtime: "152",
    genres: "adventure,science-fiction,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-06T19:23:33Z": {
    type: "movie",
    title: "I, Tonya",
    year: "2017",
    trakt_rating: "7.5944",
    trakt_id: "231303",
    imdb_id: "tt5580036",
    tmdb_id: "389015",
    url: "https://trakt.tv/movies/i-tonya-2017",
    released: "2017-12-08",
    runtime: "120",
    genres: "drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-01T08:42:57Z": {
    type: "movie",
    title: "The Shape of Water",
    year: "2017",
    trakt_rating: "7.38462",
    trakt_id: "242257",
    imdb_id: "tt5580390",
    tmdb_id: "399055",
    url: "https://trakt.tv/movies/the-shape-of-water-2017",
    released: "2017-12-01",
    runtime: "123",
    genres: "fantasy,drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-01T01:21:20Z": {
    type: "movie",
    title: "Lady Bird",
    year: "2017",
    trakt_rating: "7.36692",
    trakt_id: "259514",
    imdb_id: "tt4925292",
    tmdb_id: "391713",
    url: "https://trakt.tv/movies/lady-bird-2017",
    released: "2017-11-03",
    runtime: "94",
    genres: "drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-03-01T01:20:47Z": {
    type: "movie",
    title: "Ferdinand",
    year: "2017",
    trakt_rating: "7.47186",
    trakt_id: "242107",
    imdb_id: "tt3411444",
    tmdb_id: "364689",
    url: "https://trakt.tv/movies/ferdinand-2017",
    released: "2017-12-15",
    runtime: "108",
    genres: "animation,family,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-02-24T17:36:54Z": {
    type: "movie",
    title: "Wonder",
    year: "2017",
    trakt_rating: "8.20569",
    trakt_id: "254235",
    imdb_id: "tt2543472",
    tmdb_id: "406997",
    url: "https://trakt.tv/movies/wonder-2017",
    released: "2017-11-17",
    runtime: "113",
    genres: "drama,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-02-24T13:42:09Z": {
    type: "movie",
    title: "The Farthest",
    year: "2018",
    trakt_rating: "8.10099",
    trakt_id: "304264",
    imdb_id: "tt6223974",
    tmdb_id: "436305",
    url: "https://trakt.tv/movies/the-farthest-2018",
    released: "2017-04-20",
    runtime: "121",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-02-21T19:53:02Z": {
    type: "movie",
    title: "The Disaster Artist",
    year: "2017",
    trakt_rating: "7.33207",
    trakt_id: "224277",
    imdb_id: "tt3521126",
    tmdb_id: "371638",
    url: "https://trakt.tv/movies/the-disaster-artist-2017",
    released: "2017-12-01",
    runtime: "104",
    genres: "drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-02-21T01:31:12Z": {
    type: "movie",
    title: "Hellraiser: Judgment",
    year: "2018",
    trakt_rating: "5.32248",
    trakt_id: "289517",
    imdb_id: "tt5476182",
    tmdb_id: "444149",
    url: "https://trakt.tv/movies/hellraiser-judgment-2018",
    released: "2018-02-13",
    runtime: "81",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-02-18T07:51:21Z": {
    type: "movie",
    title: "Thor: Ragnarok",
    year: "2017",
    trakt_rating: "8.00878",
    trakt_id: "181312",
    imdb_id: "tt3501632",
    tmdb_id: "284053",
    url: "https://trakt.tv/movies/thor-ragnarok-2017",
    released: "2017-11-03",
    runtime: "131",
    genres: "action,adventure,superhero,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-02-17T11:21:34Z": {
    type: "movie",
    title: "Remo Williams: The Adventure Begins",
    year: "1985",
    trakt_rating: "7.12598",
    trakt_id: "5731",
    imdb_id: "tt0089901",
    tmdb_id: "10553",
    url: "https://trakt.tv/movies/remo-williams-the-adventure-begins-1985",
    released: "1985-10-11",
    runtime: "121",
    genres: "action,adventure,comedy,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-02-17T03:54:44Z": {
    type: "movie",
    title: "Justice League",
    year: "2017",
    trakt_rating: "6.69786",
    trakt_id: "94232",
    imdb_id: "tt0974015",
    tmdb_id: "141052",
    url: "https://trakt.tv/movies/justice-league-2017",
    released: "2017-11-17",
    runtime: "120",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-02-15T17:17:18Z": {
    type: "movie",
    title: "Murder on the Orient Express",
    year: "2017",
    trakt_rating: "6.97549",
    trakt_id: "234398",
    imdb_id: "tt3402236",
    tmdb_id: "392044",
    url: "https://trakt.tv/movies/murder-on-the-orient-express-2017",
    released: "2017-11-10",
    runtime: "114",
    genres: "drama,mystery,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-02-15T08:05:07Z": {
    type: "movie",
    title: "Three Billboards Outside Ebbing, Missouri",
    year: "2017",
    trakt_rating: "8.00391",
    trakt_id: "266092",
    imdb_id: "tt5027774",
    tmdb_id: "359940",
    url: "https://trakt.tv/movies/three-billboards-outside-ebbing-missouri-2017",
    released: "2017-12-01",
    runtime: "115",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-02-14T17:11:36Z": {
    type: "movie",
    title: "Darkest Hour",
    year: "2017",
    trakt_rating: "7.66677",
    trakt_id: "242684",
    imdb_id: "tt4555426",
    tmdb_id: "399404",
    url: "https://trakt.tv/movies/darkest-hour-2017",
    released: "2017-11-22",
    runtime: "125",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-02-11T10:49:08Z": {
    type: "movie",
    title: "Coco",
    year: "2017",
    trakt_rating: "8.44139",
    trakt_id: "222605",
    imdb_id: "tt2380307",
    tmdb_id: "354912",
    url: "https://trakt.tv/movies/coco-2017",
    released: "2017-11-22",
    runtime: "105",
    genres: "animation,family,comedy,adventure,fantasy,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-02-08T19:12:04Z": {
    type: "movie",
    title: "The People vs. Larry Flynt",
    year: "1996",
    trakt_rating: "7.36053",
    trakt_id: "1076",
    imdb_id: "tt0117318",
    tmdb_id: "1630",
    url: "https://trakt.tv/movies/the-people-vs-larry-flynt-1996",
    released: "1996-12-25",
    runtime: "130",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-02-08T15:05:12Z": {
    type: "movie",
    title: "25th Hour",
    year: "2002",
    trakt_rating: "7.51337",
    trakt_id: "958",
    imdb_id: "tt0307901",
    tmdb_id: "1429",
    url: "https://trakt.tv/movies/25th-hour-2002",
    released: "2002-12-19",
    runtime: "135",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-01-31T05:24:09Z": {
    type: "movie",
    title: "The Killing of a Sacred Deer",
    year: "2017",
    trakt_rating: "6.83698",
    trakt_id: "242259",
    imdb_id: "tt5715874",
    tmdb_id: "399057",
    url: "https://trakt.tv/movies/the-killing-of-a-sacred-deer-2017",
    released: "2017-10-20",
    runtime: "121",
    genres: "drama,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-01-16T15:20:32Z": {
    type: "movie",
    title: "Better Living Through Circuitry",
    year: "1999",
    trakt_rating: "6.78571",
    trakt_id: "32625",
    imdb_id: "tt0207998",
    tmdb_id: "47295",
    url: "https://trakt.tv/movies/better-living-through-circuitry-1999",
    released: "1999-01-01",
    runtime: "85",
    genres: "documentary,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-01-07T23:07:55Z": {
    type: "movie",
    title: "Hellboy",
    year: "2004",
    trakt_rating: "7.199",
    trakt_id: "981",
    imdb_id: "tt0167190",
    tmdb_id: "1487",
    url: "https://trakt.tv/movies/hellboy-2004",
    released: "2004-04-02",
    runtime: "122",
    genres: "action,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2018-01-04T02:29:36Z": {
    type: "movie",
    title: "Blade Runner 2049",
    year: "2017",
    trakt_rating: "7.74256",
    trakt_id: "216716",
    imdb_id: "tt1856101",
    tmdb_id: "335984",
    url: "https://trakt.tv/movies/blade-runner-2049-2017",
    released: "2017-10-06",
    runtime: "164",
    genres: "science-fiction,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-12-31T19:23:50Z": {
    type: "movie",
    title: "Sinister 2",
    year: "2015",
    trakt_rating: "5.98725",
    trakt_id: "180733",
    imdb_id: "tt2752772",
    tmdb_id: "283445",
    url: "https://trakt.tv/movies/sinister-2-2015",
    released: "2015-08-21",
    runtime: "97",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-12-25T03:43:06Z": {
    type: "movie",
    title: "From Dusk Till Dawn",
    year: "1996",
    trakt_rating: "7.49127",
    trakt_id: "598",
    imdb_id: "tt0116367",
    tmdb_id: "755",
    url: "https://trakt.tv/movies/from-dusk-till-dawn-1996",
    released: "1996-01-19",
    runtime: "108",
    genres: "action,crime,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-12-19T01:35:13Z": {
    type: "movie",
    title: "It",
    year: "2017",
    trakt_rating: "7.47176",
    trakt_id: "242226",
    imdb_id: "tt1396484",
    tmdb_id: "346364",
    url: "https://trakt.tv/movies/it-2017",
    released: "2017-09-08",
    runtime: "135",
    genres: "horror,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-12-18T00:21:20Z": {
    type: "movie",
    title: "The Mountain Between Us",
    year: "2017",
    trakt_rating: "7.15975",
    trakt_id: "187425",
    imdb_id: "tt2226597",
    tmdb_id: "290512",
    url: "https://trakt.tv/movies/the-mountain-between-us-2017",
    released: "2017-10-06",
    runtime: "109",
    genres: "drama,adventure,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-12-16T15:50:44Z": {
    type: "movie",
    title: "The Polar Express",
    year: "2004",
    trakt_rating: "7.18105",
    trakt_id: "3051",
    imdb_id: "tt0338348",
    tmdb_id: "5255",
    url: "https://trakt.tv/movies/the-polar-express-2004",
    released: "2004-11-10",
    runtime: "100",
    genres: "adventure,animation,fantasy,family,comedy,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-12-16T15:46:16Z": {
    type: "movie",
    title: "The Lego Ninjago Movie",
    year: "2017",
    trakt_rating: "7.22567",
    trakt_id: "172692",
    imdb_id: "tt3014284",
    tmdb_id: "274862",
    url: "https://trakt.tv/movies/the-lego-ninjago-movie-2017",
    released: "2017-09-22",
    runtime: "101",
    genres: "action,animation,adventure,family,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-12-12T23:43:21Z": {
    type: "movie",
    title: "Leatherface",
    year: "2017",
    trakt_rating: "6.2618",
    trakt_id: "225198",
    imdb_id: "tt2620590",
    tmdb_id: "300665",
    url: "https://trakt.tv/movies/leatherface-2017",
    released: "2017-09-21",
    runtime: "88",
    genres: "horror,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-12-10T16:24:11Z": {
    type: "movie",
    title: "mother!",
    year: "2017",
    trakt_rating: "6.78463",
    trakt_id: "225490",
    imdb_id: "tt5109784",
    tmdb_id: "381283",
    url: "https://trakt.tv/movies/mother-2017",
    released: "2017-09-15",
    runtime: "121",
    genres: "drama,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-12-06T05:23:15Z": {
    type: "movie",
    title: "Dunkirk",
    year: "2017",
    trakt_rating: "7.51264",
    trakt_id: "224641",
    imdb_id: "tt5013056",
    tmdb_id: "374720",
    url: "https://trakt.tv/movies/dunkirk-2017",
    released: "2017-07-19",
    runtime: "107",
    genres: "war,action,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-12-02T15:20:06Z": {
    type: "movie",
    title: "Kingsman: The Golden Circle",
    year: "2017",
    trakt_rating: "7.17903",
    trakt_id: "223444",
    imdb_id: "tt4649466",
    tmdb_id: "343668",
    url: "https://trakt.tv/movies/kingsman-the-golden-circle-2017",
    released: "2017-09-22",
    runtime: "141",
    genres: "action,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-23T06:34:39Z": {
    type: "movie",
    title: "American Assassin",
    year: "2017",
    trakt_rating: "7.004",
    trakt_id: "260759",
    imdb_id: "tt1961175",
    tmdb_id: "415842",
    url: "https://trakt.tv/movies/american-assassin-2017",
    released: "2017-09-15",
    runtime: "112",
    genres: "thriller,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-13T01:00:23Z": {
    type: "movie",
    title: "Ed Wood",
    year: "1994",
    trakt_rating: "7.48422",
    trakt_id: "407",
    imdb_id: "tt0109707",
    tmdb_id: "522",
    url: "https://trakt.tv/movies/ed-wood-1994",
    released: "1994-09-28",
    runtime: "127",
    genres: "comedy,drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-13T00:21:03Z": {
    type: "movie",
    title: "Dog Day Afternoon",
    year: "1975",
    trakt_rating: "7.91566",
    trakt_id: "804",
    imdb_id: "tt0072890",
    tmdb_id: "968",
    url: "https://trakt.tv/movies/dog-day-afternoon-1975",
    released: "1975-09-21",
    runtime: "125",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-13T00:14:02Z": {
    type: "movie",
    title: "The Conversation",
    year: "1974",
    trakt_rating: "7.53707",
    trakt_id: "470",
    imdb_id: "tt0071360",
    tmdb_id: "592",
    url: "https://trakt.tv/movies/the-conversation-1974",
    released: "1974-04-07",
    runtime: "113",
    genres: "crime,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-11T05:56:33Z": {
    type: "movie",
    title: "The Dead Pool",
    year: "1988",
    trakt_rating: "6.75646",
    trakt_id: "5825",
    imdb_id: "tt0094963",
    tmdb_id: "10651",
    url: "https://trakt.tv/movies/the-dead-pool-1988",
    released: "1988-07-13",
    runtime: "91",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-11T05:50:56Z": {
    type: "movie",
    title: "The Final Countdown",
    year: "1980",
    trakt_rating: "7.12694",
    trakt_id: "4285",
    imdb_id: "tt0080736",
    tmdb_id: "8738",
    url: "https://trakt.tv/movies/the-final-countdown-1980",
    released: "1980-08-01",
    runtime: "103",
    genres: "action,science-fiction,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-11T05:45:22Z": {
    type: "movie",
    title: "Jacob's Ladder",
    year: "1990",
    trakt_rating: "7.56162",
    trakt_id: "1612",
    imdb_id: "tt0099871",
    tmdb_id: "2291",
    url: "https://trakt.tv/movies/jacob-s-ladder-1990",
    released: "1990-11-02",
    runtime: "113",
    genres: "drama,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-09T23:11:26Z": {
    type: "movie",
    title: "Me Before You",
    year: "2016",
    trakt_rating: "7.90978",
    trakt_id: "204300",
    imdb_id: "tt2674426",
    tmdb_id: "296096",
    url: "https://trakt.tv/movies/me-before-you-2016",
    released: "2016-06-03",
    runtime: "110",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-09T04:08:14Z": {
    type: "movie",
    title: "Confessions of a Dangerous Mind",
    year: "2002",
    trakt_rating: "6.9855",
    trakt_id: "2851",
    imdb_id: "tt0270288",
    tmdb_id: "4912",
    url: "https://trakt.tv/movies/confessions-of-a-dangerous-mind-2002",
    released: "2002-12-31",
    runtime: "113",
    genres: "comedy,drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-09T03:39:45Z": {
    type: "movie",
    title: "Microcosmos",
    year: "1996",
    trakt_rating: "8.22222",
    trakt_id: "4605",
    imdb_id: "tt0117040",
    tmdb_id: "9305",
    url: "https://trakt.tv/movies/microcosmos-1996",
    released: "1996-05-20",
    runtime: "80",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-09T02:06:02Z": {
    type: "movie",
    title: "Dirty Rotten Scoundrels",
    year: "1988",
    trakt_rating: "7.68557",
    trakt_id: "5358",
    imdb_id: "tt0095031",
    tmdb_id: "10141",
    url: "https://trakt.tv/movies/dirty-rotten-scoundrels-1988",
    released: "1988-12-14",
    runtime: "110",
    genres: "comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-08T02:18:23Z": {
    type: "movie",
    title: "The Enforcer",
    year: "1976",
    trakt_rating: "7.07539",
    trakt_id: "5823",
    imdb_id: "tt0074483",
    tmdb_id: "10649",
    url: "https://trakt.tv/movies/the-enforcer-1976",
    released: "1976-12-21",
    runtime: "96",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-08T01:56:35Z": {
    type: "movie",
    title: "Hitchcock",
    year: "2012",
    trakt_rating: "6.97399",
    trakt_id: "78984",
    imdb_id: "tt0975645",
    tmdb_id: "112336",
    url: "https://trakt.tv/movies/hitchcock-2012",
    released: "2012-11-23",
    runtime: "98",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-08T00:20:45Z": {
    type: "movie",
    title: "Sudden Impact",
    year: "1983",
    trakt_rating: "6.9904",
    trakt_id: "5824",
    imdb_id: "tt0086383",
    tmdb_id: "10650",
    url: "https://trakt.tv/movies/sudden-impact-1983",
    released: "1983-12-08",
    runtime: "117",
    genres: "crime,action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-07T23:16:53Z": {
    type: "movie",
    title: "Kill the Irishman",
    year: "2011",
    trakt_rating: "7.30546",
    trakt_id: "35906",
    imdb_id: "tt1416801",
    tmdb_id: "51209",
    url: "https://trakt.tv/movies/kill-the-irishman-2011",
    released: "2011-03-11",
    runtime: "106",
    genres: "action,crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-07T23:10:26Z": {
    type: "movie",
    title: "Magnum Force",
    year: "1973",
    trakt_rating: "7.41964",
    trakt_id: "5822",
    imdb_id: "tt0070355",
    tmdb_id: "10648",
    url: "https://trakt.tv/movies/magnum-force-1973",
    released: "1973-12-24",
    runtime: "124",
    genres: "action,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-05T23:27:50Z": {
    type: "movie",
    title: "Fantasia",
    year: "1940",
    trakt_rating: "7.54936",
    trakt_id: "599",
    imdb_id: "tt0032455",
    tmdb_id: "756",
    url: "https://trakt.tv/movies/fantasia-1940",
    released: "1940-11-13",
    runtime: "124",
    genres: "animation,family,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-05T19:25:12Z": {
    type: "movie",
    title: "Rabid",
    year: "1977",
    trakt_rating: "6.5303",
    trakt_id: "18519",
    imdb_id: "tt0076590",
    tmdb_id: "29437",
    url: "https://trakt.tv/movies/rabid-1977",
    released: "1977-04-08",
    runtime: "91",
    genres: "horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-05T17:08:44Z": {
    type: "movie",
    title: "Henry & June",
    year: "1990",
    trakt_rating: "6.5922",
    trakt_id: "11004",
    imdb_id: "tt0099762",
    tmdb_id: "17993",
    url: "https://trakt.tv/movies/henry-june-1990",
    released: "1990-10-05",
    runtime: "136",
    genres: "romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-05T15:12:16Z": {
    type: "movie",
    title: "Rush",
    year: "2013",
    trakt_rating: "8.03918",
    trakt_id: "70421",
    imdb_id: "tt1979320",
    tmdb_id: "96721",
    url: "https://trakt.tv/movies/rush-2013",
    released: "2013-09-27",
    runtime: "123",
    genres: "action,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-04T14:13:39Z": {
    type: "movie",
    title: "The Gray Man",
    year: "2007",
    trakt_rating: "6.17647",
    trakt_id: "11066",
    imdb_id: "tt0478329",
    tmdb_id: "18096",
    url: "https://trakt.tv/movies/the-gray-man-2007",
    released: "2007-08-31",
    runtime: "97",
    genres: "crime,drama,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-04T02:50:25Z": {
    type: "movie",
    title: "The Right Stuff",
    year: "1983",
    trakt_rating: "7.83848",
    trakt_id: "4827",
    imdb_id: "tt0086197",
    tmdb_id: "9549",
    url: "https://trakt.tv/movies/the-right-stuff-1983",
    released: "1983-10-21",
    runtime: "193",
    genres: "drama,history,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-04T00:49:16Z": {
    type: "movie",
    title: "American Movie",
    year: "1999",
    trakt_rating: "7.69455",
    trakt_id: "8542",
    imdb_id: "tt0181288",
    tmdb_id: "14242",
    url: "https://trakt.tv/movies/american-movie-1999",
    released: "1999-11-05",
    runtime: "107",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-04T00:48:00Z": {
    type: "movie",
    title: "Roger & Me",
    year: "1989",
    trakt_rating: "7.43089",
    trakt_id: "1178",
    imdb_id: "tt0098213",
    tmdb_id: "1779",
    url: "https://trakt.tv/movies/roger-me-1989",
    released: "1989-09-01",
    runtime: "91",
    genres: "documentary,history,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-03T11:27:35Z": {
    type: "movie",
    title: "Kung Fu Hustle",
    year: "2004",
    trakt_rating: "7.92998",
    trakt_id: "4756",
    imdb_id: "tt0373074",
    tmdb_id: "9470",
    url: "https://trakt.tv/movies/kung-fu-hustle-2004",
    released: "2005-04-08",
    runtime: "99",
    genres: "action,comedy,crime,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-02T07:12:18Z": {
    type: "movie",
    title: "Yellow Submarine",
    year: "1968",
    trakt_rating: "7.66769",
    trakt_id: "7023",
    imdb_id: "tt0063823",
    tmdb_id: "12105",
    url: "https://trakt.tv/movies/yellow-submarine-1968",
    released: "1968-11-13",
    runtime: "89",
    genres: "fantasy,music,animation,adventure,comedy,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-02T07:10:50Z": {
    type: "movie",
    title: "The Party",
    year: "1968",
    trakt_rating: "7.64029",
    trakt_id: "5954",
    imdb_id: "tt0063415",
    tmdb_id: "10794",
    url: "https://trakt.tv/movies/the-party-1968",
    released: "1968-04-04",
    runtime: "99",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-02T02:19:19Z": {
    type: "movie",
    title: "Top Secret!",
    year: "1984",
    trakt_rating: "7.4561",
    trakt_id: "4295",
    imdb_id: "tt0088286",
    tmdb_id: "8764",
    url: "https://trakt.tv/movies/top-secret-1984",
    released: "1984-06-08",
    runtime: "90",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-01T23:12:08Z": {
    type: "movie",
    title: "True Believer",
    year: "1989",
    trakt_rating: "6.88462",
    trakt_id: "20439",
    imdb_id: "tt0098524",
    tmdb_id: "31863",
    url: "https://trakt.tv/movies/true-believer-1989",
    released: "1989-02-17",
    runtime: "105",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-01T23:06:48Z": {
    type: "movie",
    title: "Six Degrees of Separation",
    year: "1993",
    trakt_rating: "6.85586",
    trakt_id: "14049",
    imdb_id: "tt0108149",
    tmdb_id: "23210",
    url: "https://trakt.tv/movies/six-degrees-of-separation-1993",
    released: "1993-12-08",
    runtime: "112",
    genres: "comedy,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-01T22:06:45Z": {
    type: "movie",
    title: "Munich",
    year: "2005",
    trakt_rating: "7.34721",
    trakt_id: "488",
    imdb_id: "tt0408306",
    tmdb_id: "612",
    url: "https://trakt.tv/movies/munich-2005",
    released: "2005-12-23",
    runtime: "164",
    genres: "action,drama,history,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-11-01T22:02:57Z": {
    type: "movie",
    title: "High Fidelity",
    year: "2000",
    trakt_rating: "7.69947",
    trakt_id: "201",
    imdb_id: "tt0146882",
    tmdb_id: "243",
    url: "https://trakt.tv/movies/high-fidelity-2000",
    released: "2000-03-17",
    runtime: "113",
    genres: "comedy,drama,music,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-29T23:58:09Z": {
    type: "movie",
    title: "Dirty Harry",
    year: "1971",
    trakt_rating: "7.62462",
    trakt_id: "814",
    imdb_id: "tt0066999",
    tmdb_id: "984",
    url: "https://trakt.tv/movies/dirty-harry-1971",
    released: "1971-12-23",
    runtime: "102",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-29T21:03:49Z": {
    type: "movie",
    title: "Westworld",
    year: "1973",
    trakt_rating: "6.99173",
    trakt_id: "1660",
    imdb_id: "tt0070909",
    tmdb_id: "2362",
    url: "https://trakt.tv/movies/westworld-1973",
    released: "1973-08-15",
    runtime: "89",
    genres: "adventure,science-fiction,western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-29T20:25:05Z": {
    type: "movie",
    title: "The Nightmare Before Christmas",
    year: "1993",
    trakt_rating: "8.0823",
    trakt_id: "4765",
    imdb_id: "tt0107688",
    tmdb_id: "9479",
    url: "https://trakt.tv/movies/the-nightmare-before-christmas-1993",
    released: "1993-10-29",
    runtime: "76",
    genres: "animation,fantasy,family,holiday,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-29T16:19:52Z": {
    type: "movie",
    title: "The Sting",
    year: "1973",
    trakt_rating: "8.25564",
    trakt_id: "4577",
    imdb_id: "tt0070735",
    tmdb_id: "9277",
    url: "https://trakt.tv/movies/the-sting-1973",
    released: "1973-12-25",
    runtime: "129",
    genres: "comedy,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-28T22:27:51Z": {
    type: "movie",
    title: "The Getaway",
    year: "1972",
    trakt_rating: "7.29126",
    trakt_id: "3374",
    imdb_id: "tt0068638",
    tmdb_id: "5916",
    url: "https://trakt.tv/movies/the-getaway-1972",
    released: "1972-12-13",
    runtime: "123",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-28T13:00:29Z": {
    type: "movie",
    title: "Cars 3",
    year: "2017",
    trakt_rating: "7.26064",
    trakt_id: "159627",
    imdb_id: "tt3606752",
    tmdb_id: "260514",
    url: "https://trakt.tv/movies/cars-3-2017",
    released: "2017-06-16",
    runtime: "102",
    genres: "comedy,adventure,animation,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-28T03:59:03Z": {
    type: "movie",
    title: "Dressed to Kill",
    year: "1980",
    trakt_rating: "6.96288",
    trakt_id: "6170",
    imdb_id: "tt0080661",
    tmdb_id: "11033",
    url: "https://trakt.tv/movies/dressed-to-kill-1980",
    released: "1980-07-25",
    runtime: "105",
    genres: "mystery,thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-27T16:09:58Z": {
    type: "movie",
    title: "Get Carter",
    year: "1971",
    trakt_rating: "7.25949",
    trakt_id: "980",
    imdb_id: "tt0067128",
    tmdb_id: "1485",
    url: "https://trakt.tv/movies/get-carter-1971",
    released: "1971-03-12",
    runtime: "112",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-26T00:35:39Z": {
    type: "movie",
    title: "Michael Clayton",
    year: "2007",
    trakt_rating: "7.06324",
    trakt_id: "2691",
    imdb_id: "tt0465538",
    tmdb_id: "4566",
    url: "https://trakt.tv/movies/michael-clayton-2007",
    released: "2007-10-12",
    runtime: "120",
    genres: "drama,thriller,mystery,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-25T22:44:03Z": {
    type: "movie",
    title: "Atomic Blonde",
    year: "2017",
    trakt_rating: "7.13311",
    trakt_id: "224157",
    imdb_id: "tt2406566",
    tmdb_id: "341013",
    url: "https://trakt.tv/movies/atomic-blonde-2017",
    released: "2017-07-28",
    runtime: "115",
    genres: "thriller,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-25T05:17:17Z": {
    type: "movie",
    title: "The Way Back",
    year: "2010",
    trakt_rating: "7.39711",
    trakt_id: "34060",
    imdb_id: "tt1023114",
    tmdb_id: "49009",
    url: "https://trakt.tv/movies/the-way-back-2010",
    released: "2011-01-21",
    runtime: "133",
    genres: "adventure,drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-25T02:43:20Z": {
    type: "movie",
    title: "Rounders",
    year: "1998",
    trakt_rating: "7.48319",
    trakt_id: "5432",
    imdb_id: "tt0128442",
    tmdb_id: "10220",
    url: "https://trakt.tv/movies/rounders-1998",
    released: "1998-09-11",
    runtime: "121",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-24T05:40:14Z": {
    type: "movie",
    title: "The Big Sleep",
    year: "1946",
    trakt_rating: "7.65963",
    trakt_id: "753",
    imdb_id: "tt0038355",
    tmdb_id: "910",
    url: "https://trakt.tv/movies/the-big-sleep-1946",
    released: "1946-08-23",
    runtime: "114",
    genres: "crime,mystery,romance,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-24T05:37:30Z": {
    type: "movie",
    title: "The Boys from Brazil",
    year: "1978",
    trakt_rating: "7.19037",
    trakt_id: "9984",
    imdb_id: "tt0077269",
    tmdb_id: "16241",
    url: "https://trakt.tv/movies/the-boys-from-brazil-1978",
    released: "1978-10-05",
    runtime: "125",
    genres: "drama,science-fiction,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-24T05:30:58Z": {
    type: "movie",
    title: "The Caine Mutiny",
    year: "1954",
    trakt_rating: "7.48239",
    trakt_id: "5394",
    imdb_id: "tt0046816",
    tmdb_id: "10178",
    url: "https://trakt.tv/movies/the-caine-mutiny-1954",
    released: "1954-06-24",
    runtime: "124",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-24T01:20:01Z": {
    type: "movie",
    title: "Where Eagles Dare",
    year: "1968",
    trakt_rating: "7.85588",
    trakt_id: "6183",
    imdb_id: "tt0065207",
    tmdb_id: "11046",
    url: "https://trakt.tv/movies/where-eagles-dare-1968",
    released: "1969-01-22",
    runtime: "155",
    genres: "action,adventure,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-24T01:10:40Z": {
    type: "movie",
    title: "The Eiger Sanction",
    year: "1975",
    trakt_rating: "6.65546",
    trakt_id: "12394",
    imdb_id: "tt0072926",
    tmdb_id: "20187",
    url: "https://trakt.tv/movies/the-eiger-sanction-1975",
    released: "1975-05-21",
    runtime: "129",
    genres: "action,adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-23T01:18:10Z": {
    type: "movie",
    title: "The Good Shepherd",
    year: "2006",
    trakt_rating: "6.75853",
    trakt_id: "863",
    imdb_id: "tt0343737",
    tmdb_id: "1247",
    url: "https://trakt.tv/movies/the-good-shepherd-2006",
    released: "2006-12-11",
    runtime: "167",
    genres: "drama,history,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-23T01:16:34Z": {
    type: "movie",
    title: "The Grifters",
    year: "1990",
    trakt_rating: "6.8627",
    trakt_id: "11084",
    imdb_id: "tt0099703",
    tmdb_id: "18129",
    url: "https://trakt.tv/movies/the-grifters-1990",
    released: "1990-12-05",
    runtime: "110",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-22T00:41:17Z": {
    type: "movie",
    title: "Capote",
    year: "2005",
    trakt_rating: "7.17316",
    trakt_id: "317",
    imdb_id: "tt0379725",
    tmdb_id: "398",
    url: "https://trakt.tv/movies/capote-2005",
    released: "2005-09-30",
    runtime: "114",
    genres: "drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-22T00:21:54Z": {
    type: "movie",
    title: "The Basketball Diaries",
    year: "1995",
    trakt_rating: "7.41791",
    trakt_id: "5657",
    imdb_id: "tt0112461",
    tmdb_id: "10474",
    url: "https://trakt.tv/movies/the-basketball-diaries-1995",
    released: "1995-04-21",
    runtime: "102",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-20T00:18:05Z": {
    type: "movie",
    title: "Deep Water",
    year: "2006",
    trakt_rating: "7.86458",
    trakt_id: "9104",
    imdb_id: "tt0460766",
    tmdb_id: "15030",
    url: "https://trakt.tv/movies/deep-water-2006",
    released: "2006-12-15",
    runtime: "92",
    genres: "action,adventure,drama,documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-20T00:12:53Z": {
    type: "movie",
    title: "There Will Be Blood",
    year: "2007",
    trakt_rating: "8.02352",
    trakt_id: "3894",
    imdb_id: "tt0469494",
    tmdb_id: "7345",
    url: "https://trakt.tv/movies/there-will-be-blood-2007",
    released: "2007-12-26",
    runtime: "158",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-20T00:10:22Z": {
    type: "movie",
    title: "Gangs of New York",
    year: "2002",
    trakt_rating: "7.58756",
    trakt_id: "2100",
    imdb_id: "tt0217505",
    tmdb_id: "3131",
    url: "https://trakt.tv/movies/gangs-of-new-york-2002",
    released: "2002-12-19",
    runtime: "168",
    genres: "crime,drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-20T00:02:24Z": {
    type: "movie",
    title:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: "1964",
    trakt_rating: "8.16773",
    trakt_id: "777",
    imdb_id: "tt0057012",
    tmdb_id: "935",
    url: "https://trakt.tv/movies/dr-strangelove-or-how-i-learned-to-stop-worrying-and-love-the-bomb-1964",
    released: "1964-01-29",
    runtime: "95",
    genres: "comedy,drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-19T23:54:07Z": {
    type: "movie",
    title: "Gerald's Game",
    year: "2017",
    trakt_rating: "6.75321",
    trakt_id: "266285",
    imdb_id: "tt3748172",
    tmdb_id: "343674",
    url: "https://trakt.tv/movies/gerald-s-game-2017",
    released: "2017-09-29",
    runtime: "104",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T22:36:56Z": {
    type: "movie",
    title: "The Host",
    year: "2006",
    trakt_rating: "7.10296",
    trakt_id: "871",
    imdb_id: "tt0468492",
    tmdb_id: "1255",
    url: "https://trakt.tv/movies/the-host-2006",
    released: "2006-07-27",
    runtime: "119",
    genres: "drama,horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T16:22:43Z": {
    type: "movie",
    title: "Babel",
    year: "2006",
    trakt_rating: "7.31287",
    trakt_id: "857",
    imdb_id: "tt0449467",
    tmdb_id: "1164",
    url: "https://trakt.tv/movies/babel-2006",
    released: "2006-10-27",
    runtime: "143",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T12:03:58Z": {
    type: "movie",
    title: "All Is Lost",
    year: "2013",
    trakt_rating: "6.82316",
    trakt_id: "99975",
    imdb_id: "tt2017038",
    tmdb_id: "152747",
    url: "https://trakt.tv/movies/all-is-lost-2013",
    released: "2013-10-18",
    runtime: "106",
    genres: "action,adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T04:05:17Z": {
    type: "movie",
    title: "Quadrophenia",
    year: "1979",
    trakt_rating: "7.67085",
    trakt_id: "5568",
    imdb_id: "tt0079766",
    tmdb_id: "10373",
    url: "https://trakt.tv/movies/quadrophenia-1979",
    released: "1979-11-02",
    runtime: "120",
    genres: "drama,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T04:00:33Z": {
    type: "movie",
    title: "The Treasure of the Sierra Madre",
    year: "1948",
    trakt_rating: "7.96225",
    trakt_id: "2066",
    imdb_id: "tt0040897",
    tmdb_id: "3090",
    url: "https://trakt.tv/movies/the-treasure-of-the-sierra-madre-1948",
    released: "1948-01-15",
    runtime: "126",
    genres: "adventure,drama,western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T03:55:15Z": {
    type: "movie",
    title: "Labyrinth",
    year: "1986",
    trakt_rating: "7.71344",
    trakt_id: "8043",
    imdb_id: "tt0091369",
    tmdb_id: "13597",
    url: "https://trakt.tv/movies/labyrinth-1986",
    released: "1986-06-27",
    runtime: "102",
    genres: "fantasy,adventure,family,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T03:54:42Z": {
    type: "movie",
    title: "Lethal Weapon",
    year: "1987",
    trakt_rating: "7.85124",
    trakt_id: "782",
    imdb_id: "tt0093409",
    tmdb_id: "941",
    url: "https://trakt.tv/movies/lethal-weapon-1987",
    released: "1987-03-06",
    runtime: "110",
    genres: "action,adventure,comedy,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T03:53:17Z": {
    type: "movie",
    title: "Planes, Trains and Automobiles",
    year: "1987",
    trakt_rating: "7.77345",
    trakt_id: "1765",
    imdb_id: "tt0093748",
    tmdb_id: "2609",
    url: "https://trakt.tv/movies/planes-trains-and-automobiles-1987",
    released: "1987-11-26",
    runtime: "93",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T03:42:56Z": {
    type: "movie",
    title: "The Dead Zone",
    year: "1983",
    trakt_rating: "7.19118",
    trakt_id: "6433",
    imdb_id: "tt0085407",
    tmdb_id: "11336",
    url: "https://trakt.tv/movies/the-dead-zone-1983",
    released: "1983-10-21",
    runtime: "103",
    genres: "thriller,horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T03:35:38Z": {
    type: "movie",
    title: "Cold Mountain",
    year: "2003",
    trakt_rating: "7.13763",
    trakt_id: "1610",
    imdb_id: "tt0159365",
    tmdb_id: "2289",
    url: "https://trakt.tv/movies/cold-mountain-2003",
    released: "2003-12-24",
    runtime: "154",
    genres: "drama,history,adventure,romance,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T03:30:11Z": {
    type: "movie",
    title: "Invaluable: The True Story of an Epic Artist",
    year: "2014",
    trakt_rating: "6.75",
    trakt_id: "270504",
    imdb_id: "tt4934990",
    tmdb_id: "310945",
    url: "https://trakt.tv/movies/invaluable-the-true-story-of-an-epic-artist-2014",
    released: "2014-01-31",
    runtime: "105",
    genres: "documentary,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T02:13:05Z": {
    type: "movie",
    title: "Quills",
    year: "2000",
    trakt_rating: "7.12609",
    trakt_id: "6033",
    imdb_id: "tt0180073",
    tmdb_id: "10876",
    url: "https://trakt.tv/movies/quills-2000",
    released: "2000-11-22",
    runtime: "124",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T01:51:04Z": {
    type: "movie",
    title: "3:10 to Yuma",
    year: "2007",
    trakt_rating: "7.61119",
    trakt_id: "3015",
    imdb_id: "tt0381849",
    tmdb_id: "5176",
    url: "https://trakt.tv/movies/3-10-to-yuma-2007",
    released: "2007-09-07",
    runtime: "122",
    genres: "western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T01:38:38Z": {
    type: "movie",
    title: "Escape From Alcatraz",
    year: "1979",
    trakt_rating: "7.67961",
    trakt_id: "5901",
    imdb_id: "tt0079116",
    tmdb_id: "10734",
    url: "https://trakt.tv/movies/escape-from-alcatraz-1979",
    released: "1979-06-22",
    runtime: "113",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T01:32:41Z": {
    type: "movie",
    title: "Life of Pi",
    year: "2012",
    trakt_rating: "7.78365",
    trakt_id: "65936",
    imdb_id: "tt0454876",
    tmdb_id: "87827",
    url: "https://trakt.tv/movies/life-of-pi-2012",
    released: "2012-11-21",
    runtime: "127",
    genres: "adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T01:26:18Z": {
    type: "movie",
    title: "Bonnie and Clyde",
    year: "1967",
    trakt_rating: "7.55654",
    trakt_id: "375",
    imdb_id: "tt0061418",
    tmdb_id: "475",
    url: "https://trakt.tv/movies/bonnie-and-clyde-1967",
    released: "1967-08-14",
    runtime: "111",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T01:19:58Z": {
    type: "movie",
    title: "The Ninth Gate",
    year: "1999",
    trakt_rating: "6.81301",
    trakt_id: "498",
    imdb_id: "tt0142688",
    tmdb_id: "622",
    url: "https://trakt.tv/movies/the-ninth-gate-1999",
    released: "1999-08-27",
    runtime: "133",
    genres: "mystery,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T01:07:57Z": {
    type: "movie",
    title: "I, Robot",
    year: "2004",
    trakt_rating: "7.331",
    trakt_id: "1406",
    imdb_id: "tt0343818",
    tmdb_id: "2048",
    url: "https://trakt.tv/movies/i-robot-2004",
    released: "2004-07-16",
    runtime: "115",
    genres: "action,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T01:05:29Z": {
    type: "movie",
    title: "Burn After Reading",
    year: "2008",
    trakt_rating: "6.94895",
    trakt_id: "2878",
    imdb_id: "tt0887883",
    tmdb_id: "4944",
    url: "https://trakt.tv/movies/burn-after-reading-2008",
    released: "2008-09-12",
    runtime: "96",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T01:04:45Z": {
    type: "movie",
    title: "Colors",
    year: "1988",
    trakt_rating: "7.29915",
    trakt_id: "5343",
    imdb_id: "tt0094894",
    tmdb_id: "10126",
    url: "https://trakt.tv/movies/colors-1988",
    released: "1988-04-15",
    runtime: "120",
    genres: "action,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T00:57:56Z": {
    type: "movie",
    title: "The Blues Brothers",
    year: "1980",
    trakt_rating: "8.08392",
    trakt_id: "410",
    imdb_id: "tt0080455",
    tmdb_id: "525",
    url: "https://trakt.tv/movies/the-blues-brothers-1980",
    released: "1980-06-16",
    runtime: "133",
    genres: "comedy,action,crime,music,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-17T00:19:13Z": {
    type: "movie",
    title: "The Parallax View",
    year: "1974",
    trakt_rating: "7.16164",
    trakt_id: "10626",
    imdb_id: "tt0071970",
    tmdb_id: "17365",
    url: "https://trakt.tv/movies/the-parallax-view-1974",
    released: "1974-06-14",
    runtime: "102",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T21:58:08Z": {
    type: "movie",
    title: "Very Bad Things",
    year: "1998",
    trakt_rating: "6.86102",
    trakt_id: "5254",
    imdb_id: "tt0124198",
    tmdb_id: "10029",
    url: "https://trakt.tv/movies/very-bad-things-1998",
    released: "1998-11-25",
    runtime: "100",
    genres: "comedy,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T21:50:27Z": {
    type: "movie",
    title: "The Big Short",
    year: "2015",
    trakt_rating: "7.68368",
    trakt_id: "199563",
    imdb_id: "tt1596363",
    tmdb_id: "318846",
    url: "https://trakt.tv/movies/the-big-short-2015",
    released: "2015-12-11",
    runtime: "131",
    genres: "drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T21:46:04Z": {
    type: "movie",
    title: "All the President's Men",
    year: "1976",
    trakt_rating: "7.91259",
    trakt_id: "734",
    imdb_id: "tt0074119",
    tmdb_id: "891",
    url: "https://trakt.tv/movies/all-the-president-s-men-1976",
    released: "1976-04-09",
    runtime: "138",
    genres: "drama,mystery,thriller,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T21:44:29Z": {
    type: "movie",
    title: "All Quiet on the Western Front",
    year: "1930",
    trakt_rating: "7.77343",
    trakt_id: "109",
    imdb_id: "tt0020629",
    tmdb_id: "143",
    url: "https://trakt.tv/movies/all-quiet-on-the-western-front-1930",
    released: "1930-04-29",
    runtime: "152",
    genres: "drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T21:38:09Z": {
    type: "movie",
    title: "House",
    year: "1986",
    trakt_rating: "6.70963",
    trakt_id: "6510",
    imdb_id: "tt0091223",
    tmdb_id: "11415",
    url: "https://trakt.tv/movies/house-1986",
    released: "1986-02-28",
    runtime: "93",
    genres: "comedy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T20:53:14Z": {
    type: "movie",
    title: "Bullitt",
    year: "1968",
    trakt_rating: "7.49748",
    trakt_id: "758",
    imdb_id: "tt0062765",
    tmdb_id: "916",
    url: "https://trakt.tv/movies/bullitt-1968",
    released: "1968-10-17",
    runtime: "113",
    genres: "action,crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T20:29:31Z": {
    type: "movie",
    title: "Highlander",
    year: "1986",
    trakt_rating: "7.42829",
    trakt_id: "4003",
    imdb_id: "tt0091203",
    tmdb_id: "8009",
    url: "https://trakt.tv/movies/highlander-1986",
    released: "1986-08-29",
    runtime: "116",
    genres: "action,fantasy,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T20:11:16Z": {
    type: "movie",
    title: "The Lion King",
    year: "1994",
    trakt_rating: "8.48129",
    trakt_id: "4242",
    imdb_id: "tt0110357",
    tmdb_id: "8587",
    url: "https://trakt.tv/movies/the-lion-king-1994",
    released: "1994-06-24",
    runtime: "89",
    genres: "animation,family,drama,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T20:03:41Z": {
    type: "movie",
    title: "The Lion King 1½",
    year: "2004",
    trakt_rating: "7.03938",
    trakt_id: "6525",
    imdb_id: "tt0318403",
    tmdb_id: "11430",
    url: "https://trakt.tv/movies/the-lion-king-1-2004",
    released: "2004-02-09",
    runtime: "77",
    genres: "animation,family,comedy,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T19:42:41Z": {
    type: "movie",
    title: "Slow West",
    year: "2015",
    trakt_rating: "6.72674",
    trakt_id: "137163",
    imdb_id: "tt3205376",
    tmdb_id: "223485",
    url: "https://trakt.tv/movies/slow-west-2015",
    released: "2015-04-16",
    runtime: "84",
    genres: "western,thriller,drama,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T19:39:31Z": {
    type: "movie",
    title: "X-Men: First Class",
    year: "2011",
    trakt_rating: "7.742",
    trakt_id: "34517",
    imdb_id: "tt1270798",
    tmdb_id: "49538",
    url: "https://trakt.tv/movies/x-men-first-class-2011",
    released: "2011-06-03",
    runtime: "132",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T03:39:56Z": {
    type: "movie",
    title: "The Eagle Has Landed",
    year: "1976",
    trakt_rating: "7.11739",
    trakt_id: "6468",
    imdb_id: "tt0074452",
    tmdb_id: "11372",
    url: "https://trakt.tv/movies/the-eagle-has-landed-1976",
    released: "1977-03-31",
    runtime: "135",
    genres: "action,adventure,drama,thriller,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T03:30:03Z": {
    type: "movie",
    title: "The Evil Dead: Treasures from the Cutting Room Floor",
    year: "2007",
    trakt_rating: "5.0",
    trakt_id: "297321",
    imdb_id: "tt1869718",
    tmdb_id: "450290",
    url: "https://trakt.tv/movies/the-evil-dead-treasures-from-the-cutting-room-floor-2007",
    released: "2007-12-18",
    runtime: "59",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T03:17:00Z": {
    type: "movie",
    title: "The Sum of All Fears",
    year: "2002",
    trakt_rating: "7.01033",
    trakt_id: "2715",
    imdb_id: "tt0164184",
    tmdb_id: "4614",
    url: "https://trakt.tv/movies/the-sum-of-all-fears-2002",
    released: "2002-05-31",
    runtime: "124",
    genres: "action,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T03:13:20Z": {
    type: "movie",
    title: "Assault on Precinct 13",
    year: "2005",
    trakt_rating: "6.52673",
    trakt_id: "4444",
    imdb_id: "tt0398712",
    tmdb_id: "8978",
    url: "https://trakt.tv/movies/assault-on-precinct-13-2005",
    released: "2005-01-19",
    runtime: "109",
    genres: "thriller,action,adventure,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T03:05:35Z": {
    type: "movie",
    title: "Mississippi Burning",
    year: "1988",
    trakt_rating: "7.87426",
    trakt_id: "1078",
    imdb_id: "tt0095647",
    tmdb_id: "1632",
    url: "https://trakt.tv/movies/mississippi-burning-1988",
    released: "1988-12-08",
    runtime: "128",
    genres: "crime,drama,thriller,history,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T03:03:19Z": {
    type: "movie",
    title: "Commando",
    year: "1985",
    trakt_rating: "7.12554",
    trakt_id: "6137",
    imdb_id: "tt0088944",
    tmdb_id: "10999",
    url: "https://trakt.tv/movies/commando-1985",
    released: "1985-10-03",
    runtime: "90",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T02:57:34Z": {
    type: "movie",
    title: "Midnight Cowboy",
    year: "1969",
    trakt_rating: "7.5941",
    trakt_id: "2090",
    imdb_id: "tt0064665",
    tmdb_id: "3116",
    url: "https://trakt.tv/movies/midnight-cowboy-1969",
    released: "1969-07-30",
    runtime: "113",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-16T02:54:17Z": {
    type: "movie",
    title: "Shrek",
    year: "2001",
    trakt_rating: "7.95968",
    trakt_id: "651",
    imdb_id: "tt0126029",
    tmdb_id: "808",
    url: "https://trakt.tv/movies/shrek-2001",
    released: "2001-05-18",
    runtime: "90",
    genres: "adventure,animation,comedy,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T23:58:18Z": {
    type: "movie",
    title: "The Pianist",
    year: "2002",
    trakt_rating: "8.45004",
    trakt_id: "335",
    imdb_id: "tt0253474",
    tmdb_id: "423",
    url: "https://trakt.tv/movies/the-pianist-2002",
    released: "2002-09-17",
    runtime: "150",
    genres: "drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T23:55:42Z": {
    type: "movie",
    title: "The Omen",
    year: "1976",
    trakt_rating: "7.47098",
    trakt_id: "637",
    imdb_id: "tt0075005",
    tmdb_id: "794",
    url: "https://trakt.tv/movies/the-omen-1976",
    released: "1976-06-25",
    runtime: "111",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T23:06:17Z": {
    type: "movie",
    title: "Donnie Brasco",
    year: "1997",
    trakt_rating: "7.77669",
    trakt_id: "4657",
    imdb_id: "tt0119008",
    tmdb_id: "9366",
    url: "https://trakt.tv/movies/donnie-brasco-1997",
    released: "1997-02-27",
    runtime: "127",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T22:16:09Z": {
    type: "movie",
    title: "Things to Do in Denver When You're Dead",
    year: "1995",
    trakt_rating: "6.99628",
    trakt_id: "318",
    imdb_id: "tt0114660",
    tmdb_id: "400",
    url: "https://trakt.tv/movies/things-to-do-in-denver-when-you-re-dead-1995",
    released: "1995-12-01",
    runtime: "116",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T22:15:52Z": {
    type: "movie",
    title: "Shoot 'Em Up",
    year: "2007",
    trakt_rating: "6.93248",
    trakt_id: "2437",
    imdb_id: "tt0465602",
    tmdb_id: "4141",
    url: "https://trakt.tv/movies/shoot-em-up-2007",
    released: "2007-07-26",
    runtime: "86",
    genres: "action,comedy,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T22:09:31Z": {
    type: "movie",
    title: "The Crying Game",
    year: "1992",
    trakt_rating: "7.26022",
    trakt_id: "6482",
    imdb_id: "tt0104036",
    tmdb_id: "11386",
    url: "https://trakt.tv/movies/the-crying-game-1992",
    released: "1992-11-13",
    runtime: "112",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T22:04:17Z": {
    type: "movie",
    title: "Suspiria",
    year: "1977",
    trakt_rating: "7.3542",
    trakt_id: "6924",
    imdb_id: "tt0076786",
    tmdb_id: "11906",
    url: "https://trakt.tv/movies/suspiria-1977",
    released: "1977-02-01",
    runtime: "99",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T22:00:49Z": {
    type: "movie",
    title: "Lone Survivor",
    year: "2013",
    trakt_rating: "7.66752",
    trakt_id: "120953",
    imdb_id: "tt1091191",
    tmdb_id: "193756",
    url: "https://trakt.tv/movies/lone-survivor-2013",
    released: "2013-12-24",
    runtime: "121",
    genres: "action,drama,war,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T21:52:03Z": {
    type: "movie",
    title: "The Taking of Pelham One Two Three",
    year: "1974",
    trakt_rating: "7.72111",
    trakt_id: "4129",
    imdb_id: "tt0072251",
    tmdb_id: "8333",
    url: "https://trakt.tv/movies/the-taking-of-pelham-one-two-three-1974",
    released: "1974-10-02",
    runtime: "104",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T21:50:26Z": {
    type: "movie",
    title: "Zombieland",
    year: "2009",
    trakt_rating: "7.79228",
    trakt_id: "12222",
    imdb_id: "tt1156398",
    tmdb_id: "19908",
    url: "https://trakt.tv/movies/zombieland-2009",
    released: "2009-10-08",
    runtime: "88",
    genres: "comedy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T21:50:07Z": {
    type: "movie",
    title: "The City of Lost Children",
    year: "1995",
    trakt_rating: "7.4821",
    trakt_id: "745",
    imdb_id: "tt0112682",
    tmdb_id: "902",
    url: "https://trakt.tv/movies/the-city-of-lost-children-1995",
    released: "1995-08-17",
    runtime: "108",
    genres: "adventure,fantasy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T21:49:28Z": {
    type: "movie",
    title: "Delicatessen",
    year: "1991",
    trakt_rating: "7.67532",
    trakt_id: "735",
    imdb_id: "tt0101700",
    tmdb_id: "892",
    url: "https://trakt.tv/movies/delicatessen-1991",
    released: "1991-04-17",
    runtime: "99",
    genres: "comedy,fantasy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T21:40:21Z": {
    type: "movie",
    title: "The Fisher King",
    year: "1991",
    trakt_rating: "7.49419",
    trakt_id: "142",
    imdb_id: "tt0101889",
    tmdb_id: "177",
    url: "https://trakt.tv/movies/the-fisher-king-1991",
    released: "1991-09-20",
    runtime: "138",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T21:05:35Z": {
    type: "movie",
    title: "The Magnificent Seven",
    year: "1960",
    trakt_rating: "7.7597",
    trakt_id: "802",
    imdb_id: "tt0054047",
    tmdb_id: "966",
    url: "https://trakt.tv/movies/the-magnificent-seven-1960",
    released: "1960-10-12",
    runtime: "127",
    genres: "western,action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T21:05:20Z": {
    type: "movie",
    title: "The Illusionist",
    year: "2006",
    trakt_rating: "7.66176",
    trakt_id: "985",
    imdb_id: "tt0443543",
    tmdb_id: "1491",
    url: "https://trakt.tv/movies/the-illusionist-2006",
    released: "2006-08-18",
    runtime: "110",
    genres: "drama,fantasy,thriller,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T20:18:58Z": {
    type: "movie",
    title: "The Fly",
    year: "1986",
    trakt_rating: "7.58063",
    trakt_id: "4715",
    imdb_id: "tt0091064",
    tmdb_id: "9426",
    url: "https://trakt.tv/movies/the-fly-1986",
    released: "1986-08-15",
    runtime: "96",
    genres: "horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T20:08:30Z": {
    type: "movie",
    title: "Dead Ringers",
    year: "1988",
    trakt_rating: "7.23323",
    trakt_id: "4818",
    imdb_id: "tt0094964",
    tmdb_id: "9540",
    url: "https://trakt.tv/movies/dead-ringers-1988",
    released: "1988-09-23",
    runtime: "115",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T19:33:13Z": {
    type: "movie",
    title: "Shaun of the Dead",
    year: "2004",
    trakt_rating: "7.99477",
    trakt_id: "592",
    imdb_id: "tt0365748",
    tmdb_id: "747",
    url: "https://trakt.tv/movies/shaun-of-the-dead-2004",
    released: "2004-09-24",
    runtime: "99",
    genres: "comedy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T18:55:40Z": {
    type: "movie",
    title: "The Serpent and the Rainbow",
    year: "1988",
    trakt_rating: "6.54433",
    trakt_id: "6585",
    imdb_id: "tt0096071",
    tmdb_id: "11503",
    url: "https://trakt.tv/movies/the-serpent-and-the-rainbow-1988",
    released: "1988-02-05",
    runtime: "98",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T18:43:57Z": {
    type: "movie",
    title: "Memento",
    year: "2000",
    trakt_rating: "8.39657",
    trakt_id: "47",
    imdb_id: "tt0209144",
    tmdb_id: "77",
    url: "https://trakt.tv/movies/memento-2000",
    released: "2001-03-16",
    runtime: "113",
    genres: "mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T18:42:51Z": {
    type: "movie",
    title: "Game of Death",
    year: "1978",
    trakt_rating: "6.76751",
    trakt_id: "7822",
    imdb_id: "tt0077594",
    tmdb_id: "13333",
    url: "https://trakt.tv/movies/game-of-death-1978",
    released: "1979-06-08",
    runtime: "101",
    genres: "action,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T18:40:40Z": {
    type: "movie",
    title: "The Way of the Dragon",
    year: "1972",
    trakt_rating: "7.50174",
    trakt_id: "4749",
    imdb_id: "tt0068935",
    tmdb_id: "9462",
    url: "https://trakt.tv/movies/the-way-of-the-dragon-1972",
    released: "1972-06-01",
    runtime: "100",
    genres: "action,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T18:38:24Z": {
    type: "movie",
    title: "The Town",
    year: "2010",
    trakt_rating: "7.54846",
    trakt_id: "14027",
    imdb_id: "tt0840361",
    tmdb_id: "23168",
    url: "https://trakt.tv/movies/the-town-2010",
    released: "2010-09-17",
    runtime: "125",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T18:36:26Z": {
    type: "movie",
    title: "The Book of Eli",
    year: "2010",
    trakt_rating: "7.21213",
    trakt_id: "12589",
    imdb_id: "tt1037705",
    tmdb_id: "20504",
    url: "https://trakt.tv/movies/the-book-of-eli-2010",
    released: "2010-01-11",
    runtime: "118",
    genres: "action,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T18:15:07Z": {
    type: "movie",
    title: "Caligula",
    year: "1979",
    trakt_rating: "5.92948",
    trakt_id: "4740",
    imdb_id: "tt0080491",
    tmdb_id: "9453",
    url: "https://trakt.tv/movies/caligula-1979",
    released: "1980-02-01",
    runtime: "156",
    genres: "drama,history,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T18:05:23Z": {
    type: "movie",
    title: "Apocalypto",
    year: "2006",
    trakt_rating: "7.64789",
    trakt_id: "1035",
    imdb_id: "tt0472043",
    tmdb_id: "1579",
    url: "https://trakt.tv/movies/apocalypto-2006",
    released: "2006-12-08",
    runtime: "139",
    genres: "action,drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T17:32:41Z": {
    type: "movie",
    title: "Zodiac",
    year: "2007",
    trakt_rating: "7.63775",
    trakt_id: "1320",
    imdb_id: "tt0443706",
    tmdb_id: "1949",
    url: "https://trakt.tv/movies/zodiac-2007",
    released: "2007-03-02",
    runtime: "162",
    genres: "crime,drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T17:30:42Z": {
    type: "movie",
    title: "The Elephant Man",
    year: "1980",
    trakt_rating: "7.99332",
    trakt_id: "1324",
    imdb_id: "tt0080678",
    tmdb_id: "1955",
    url: "https://trakt.tv/movies/the-elephant-man-1980",
    released: "1980-10-10",
    runtime: "124",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T15:37:58Z": {
    type: "movie",
    title: "Desierto",
    year: "2015",
    trakt_rating: "6.75752",
    trakt_id: "157911",
    imdb_id: "tt3147312",
    tmdb_id: "258363",
    url: "https://trakt.tv/movies/desierto-2015",
    released: "2016-04-13",
    runtime: "88",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T15:36:49Z": {
    type: "movie",
    title: "The Green Mile",
    year: "1999",
    trakt_rating: "8.67014",
    trakt_id: "390",
    imdb_id: "tt0120689",
    tmdb_id: "497",
    url: "https://trakt.tv/movies/the-green-mile-1999",
    released: "1999-12-10",
    runtime: "189",
    genres: "crime,drama,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T15:35:31Z": {
    type: "movie",
    title: "Three Kings",
    year: "1999",
    trakt_rating: "7.10922",
    trakt_id: "3611",
    imdb_id: "tt0120188",
    tmdb_id: "6415",
    url: "https://trakt.tv/movies/three-kings-1999",
    released: "1999-09-27",
    runtime: "114",
    genres: "action,comedy,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T15:33:17Z": {
    type: "movie",
    title: "Troy",
    year: "2004",
    trakt_rating: "7.35719",
    trakt_id: "527",
    imdb_id: "tt0332452",
    tmdb_id: "652",
    url: "https://trakt.tv/movies/troy-2004",
    released: "2004-05-14",
    runtime: "163",
    genres: "adventure,history,war,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T15:31:40Z": {
    type: "movie",
    title: "Drowning by Numbers",
    year: "1988",
    trakt_rating: "7.46154",
    trakt_id: "16792",
    imdb_id: "tt0092929",
    tmdb_id: "27362",
    url: "https://trakt.tv/movies/drowning-by-numbers-1988",
    released: "1988-09-22",
    runtime: "119",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T15:30:43Z": {
    type: "movie",
    title: "A Zed & Two Noughts",
    year: "1985",
    trakt_rating: "7.55102",
    trakt_id: "21186",
    imdb_id: "tt0090366",
    tmdb_id: "33078",
    url: "https://trakt.tv/movies/a-zed-two-noughts-1985",
    released: "1986-01-23",
    runtime: "115",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T15:29:06Z": {
    type: "movie",
    title: "The Cook, the Thief, His Wife & Her Lover",
    year: "1989",
    trakt_rating: "7.30219",
    trakt_id: "3913",
    imdb_id: "tt0097108",
    tmdb_id: "7452",
    url: "https://trakt.tv/movies/the-cook-the-thief-his-wife-her-lover-1989",
    released: "1989-11-01",
    runtime: "124",
    genres: "drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T15:27:58Z": {
    type: "movie",
    title: "Heathers",
    year: "1989",
    trakt_rating: "7.40599",
    trakt_id: "1781",
    imdb_id: "tt0097493",
    tmdb_id: "2640",
    url: "https://trakt.tv/movies/heathers-1989",
    released: "1989-03-31",
    runtime: "103",
    genres: "comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T15:15:12Z": {
    type: "movie",
    title: "Dangerous Liaisons",
    year: "1988",
    trakt_rating: "7.52941",
    trakt_id: "702",
    imdb_id: "tt0094947",
    tmdb_id: "859",
    url: "https://trakt.tv/movies/dangerous-liaisons-1988",
    released: "1988-12-21",
    runtime: "119",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T15:07:39Z": {
    type: "movie",
    title: "Ghost World",
    year: "2001",
    trakt_rating: "7.35514",
    trakt_id: "1007",
    imdb_id: "tt0162346",
    tmdb_id: "1548",
    url: "https://trakt.tv/movies/ghost-world-2001",
    released: "2001-07-20",
    runtime: "111",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T15:03:41Z": {
    type: "movie",
    title: "Unbreakable",
    year: "2000",
    trakt_rating: "7.44961",
    trakt_id: "5007",
    imdb_id: "tt0217869",
    tmdb_id: "9741",
    url: "https://trakt.tv/movies/unbreakable-2000",
    released: "2000-11-22",
    runtime: "106",
    genres: "thriller,superhero,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T15:01:17Z": {
    type: "movie",
    title: "Harry Brown",
    year: "2009",
    trakt_rating: "7.21207",
    trakt_id: "15733",
    imdb_id: "tt1289406",
    tmdb_id: "25941",
    url: "https://trakt.tv/movies/harry-brown-2009",
    released: "2009-11-11",
    runtime: "103",
    genres: "action,crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T14:54:50Z": {
    type: "movie",
    title: "The Beach",
    year: "2000",
    trakt_rating: "6.89436",
    trakt_id: "1281",
    imdb_id: "tt0163978",
    tmdb_id: "1907",
    url: "https://trakt.tv/movies/the-beach-2000",
    released: "2000-02-11",
    runtime: "119",
    genres: "adventure,drama,thriller,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T14:48:56Z": {
    type: "movie",
    title: "Super 8",
    year: "2011",
    trakt_rating: "7.19904",
    trakt_id: "24185",
    imdb_id: "tt1650062",
    tmdb_id: "37686",
    url: "https://trakt.tv/movies/super-8-2011",
    released: "2011-06-09",
    runtime: "112",
    genres: "science-fiction,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T14:38:29Z": {
    type: "movie",
    title: "The Wicker Man",
    year: "1973",
    trakt_rating: "7.49156",
    trakt_id: "10031",
    imdb_id: "tt0070917",
    tmdb_id: "16307",
    url: "https://trakt.tv/movies/the-wicker-man-1973",
    released: "1973-12-01",
    runtime: "94",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T14:34:04Z": {
    type: "movie",
    title: "Straw Dogs",
    year: "1971",
    trakt_rating: "7.31798",
    trakt_id: "824",
    imdb_id: "tt0067800",
    tmdb_id: "994",
    url: "https://trakt.tv/movies/straw-dogs-1971",
    released: "1971-12-29",
    runtime: "116",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T14:30:17Z": {
    type: "movie",
    title: "A.I. Artificial Intelligence",
    year: "2001",
    trakt_rating: "7.28708",
    trakt_id: "520",
    imdb_id: "tt0212720",
    tmdb_id: "644",
    url: "https://trakt.tv/movies/a-i-artificial-intelligence-2001",
    released: "2001-06-29",
    runtime: "146",
    genres: "adventure,drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T14:16:53Z": {
    type: "movie",
    title: "The Longest Day",
    year: "1962",
    trakt_rating: "7.92301",
    trakt_id: "4589",
    imdb_id: "tt0056197",
    tmdb_id: "9289",
    url: "https://trakt.tv/movies/the-longest-day-1962",
    released: "1962-10-04",
    runtime: "178",
    genres: "war,action,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T13:55:50Z": {
    type: "movie",
    title: "Twelve Monkeys",
    year: "1995",
    trakt_rating: "7.99195",
    trakt_id: "34",
    imdb_id: "tt0114746",
    tmdb_id: "63",
    url: "https://trakt.tv/movies/twelve-monkeys-1995",
    released: "1995-12-29",
    runtime: "129",
    genres: "mystery,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T13:51:49Z": {
    type: "movie",
    title: "Koyaanisqatsi",
    year: "1983",
    trakt_rating: "8.10336",
    trakt_id: "6413",
    imdb_id: "tt0085809",
    tmdb_id: "11314",
    url: "https://trakt.tv/movies/koyaanisqatsi-1983",
    released: "1982-04-27",
    runtime: "86",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T13:51:37Z": {
    type: "movie",
    title: "Powaqqatsi",
    year: "1988",
    trakt_rating: "7.48947",
    trakt_id: "14646",
    imdb_id: "tt0095895",
    tmdb_id: "24348",
    url: "https://trakt.tv/movies/powaqqatsi-1988",
    released: "1988-04-29",
    runtime: "99",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T13:43:46Z": {
    type: "movie",
    title: "Near Dark",
    year: "1987",
    trakt_rating: "6.89427",
    trakt_id: "6900",
    imdb_id: "tt0093605",
    tmdb_id: "11879",
    url: "https://trakt.tv/movies/near-dark-1987",
    released: "1987-10-02",
    runtime: "94",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T13:41:52Z": {
    type: "movie",
    title: "The Abyss",
    year: "1989",
    trakt_rating: "7.77148",
    trakt_id: "1840",
    imdb_id: "tt0096754",
    tmdb_id: "2756",
    url: "https://trakt.tv/movies/the-abyss-1989",
    released: "1989-08-09",
    runtime: "140",
    genres: "action,adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T13:37:38Z": {
    type: "movie",
    title: "Team America: World Police",
    year: "2004",
    trakt_rating: "7.24344",
    trakt_id: "2407",
    imdb_id: "tt0372588",
    tmdb_id: "3989",
    url: "https://trakt.tv/movies/team-america-world-police-2004",
    released: "2004-10-11",
    runtime: "98",
    genres: "action,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T13:34:58Z": {
    type: "movie",
    title: "Alpha Dog",
    year: "2006",
    trakt_rating: "7.1063",
    trakt_id: "3917",
    imdb_id: "tt0426883",
    tmdb_id: "7457",
    url: "https://trakt.tv/movies/alpha-dog-2006",
    released: "2006-01-27",
    runtime: "122",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T13:09:44Z": {
    type: "movie",
    title: "The Dirty Dozen",
    year: "1967",
    trakt_rating: "7.71581",
    trakt_id: "1099",
    imdb_id: "tt0061578",
    tmdb_id: "1654",
    url: "https://trakt.tv/movies/the-dirty-dozen-1967",
    released: "1967-06-15",
    runtime: "150",
    genres: "action,adventure,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T13:06:12Z": {
    type: "movie",
    title: "The NeverEnding Story",
    year: "1984",
    trakt_rating: "7.68406",
    trakt_id: "22078",
    imdb_id: "tt0088323",
    tmdb_id: "34584",
    url: "https://trakt.tv/movies/the-neverending-story-1984",
    released: "1984-07-20",
    runtime: "102",
    genres: "fantasy,family,drama,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T13:04:34Z": {
    type: "movie",
    title: "Big Trouble in Little China",
    year: "1986",
    trakt_rating: "7.63108",
    trakt_id: "3799",
    imdb_id: "tt0090728",
    tmdb_id: "6978",
    url: "https://trakt.tv/movies/big-trouble-in-little-china-1986",
    released: "1986-05-30",
    runtime: "99",
    genres: "action,adventure,comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T13:01:04Z": {
    type: "movie",
    title: "Beavis and Butt-Head Do America",
    year: "1996",
    trakt_rating: "7.09735",
    trakt_id: "2137",
    imdb_id: "tt0115641",
    tmdb_id: "3179",
    url: "https://trakt.tv/movies/beavis-and-butt-head-do-america-1996",
    released: "1996-12-15",
    runtime: "80",
    genres: "animation,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T12:56:57Z": {
    type: "movie",
    title: "Videodrome",
    year: "1983",
    trakt_rating: "7.21105",
    trakt_id: "680",
    imdb_id: "tt0086541",
    tmdb_id: "837",
    url: "https://trakt.tv/movies/videodrome-1983",
    released: "1983-02-04",
    runtime: "88",
    genres: "horror,mystery,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T06:27:41Z": {
    type: "movie",
    title: "Pan's Labyrinth",
    year: "2006",
    trakt_rating: "8.03543",
    trakt_id: "947",
    imdb_id: "tt0457430",
    tmdb_id: "1417",
    url: "https://trakt.tv/movies/pan-s-labyrinth-2006",
    released: "2006-10-20",
    runtime: "118",
    genres: "drama,fantasy,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T06:27:18Z": {
    type: "movie",
    title: "Zero Dark Thirty",
    year: "2012",
    trakt_rating: "7.43181",
    trakt_id: "70890",
    imdb_id: "tt1790885",
    tmdb_id: "97630",
    url: "https://trakt.tv/movies/zero-dark-thirty-2012",
    released: "2012-12-19",
    runtime: "157",
    genres: "drama,history,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T06:26:38Z": {
    type: "movie",
    title: "Good Night, and Good Luck.",
    year: "2005",
    trakt_rating: "7.337",
    trakt_id: "2161",
    imdb_id: "tt0433383",
    tmdb_id: "3291",
    url: "https://trakt.tv/movies/good-night-and-good-luck-2005",
    released: "2005-10-07",
    runtime: "92",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T05:02:36Z": {
    type: "movie",
    title: "This Is Spinal Tap",
    year: "1984",
    trakt_rating: "7.68822",
    trakt_id: "6168",
    imdb_id: "tt0088258",
    tmdb_id: "11031",
    url: "https://trakt.tv/movies/this-is-spinal-tap-1984",
    released: "1984-09-08",
    runtime: "82",
    genres: "comedy,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T04:47:11Z": {
    type: "movie",
    title: "Butch Cassidy and the Sundance Kid",
    year: "1969",
    trakt_rating: "7.81457",
    trakt_id: "518",
    imdb_id: "tt0064115",
    tmdb_id: "642",
    url: "https://trakt.tv/movies/butch-cassidy-and-the-sundance-kid-1969",
    released: "1969-09-24",
    runtime: "111",
    genres: "western,drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T04:20:32Z": {
    type: "movie",
    title: "Death Race 2000",
    year: "1975",
    trakt_rating: "6.58254",
    trakt_id: "7789",
    imdb_id: "tt0072856",
    tmdb_id: "13282",
    url: "https://trakt.tv/movies/death-race-2000-1975",
    released: "1975-04-30",
    runtime: "80",
    genres: "action,comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T04:19:43Z": {
    type: "movie",
    title: "Cool Hand Luke",
    year: "1967",
    trakt_rating: "8.00828",
    trakt_id: "746",
    imdb_id: "tt0061512",
    tmdb_id: "903",
    url: "https://trakt.tv/movies/cool-hand-luke-1967",
    released: "1967-11-01",
    runtime: "127",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T04:15:57Z": {
    type: "movie",
    title: "Copycat",
    year: "1995",
    trakt_rating: "7.03199",
    trakt_id: "1141",
    imdb_id: "tt0112722",
    tmdb_id: "1710",
    url: "https://trakt.tv/movies/copycat-1995",
    released: "1995-10-27",
    runtime: "124",
    genres: "thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T04:15:37Z": {
    type: "movie",
    title: "Crash",
    year: "2005",
    trakt_rating: "7.56817",
    trakt_id: "1085",
    imdb_id: "tt0375679",
    tmdb_id: "1640",
    url: "https://trakt.tv/movies/crash-2005",
    released: "2005-05-06",
    runtime: "112",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T04:08:34Z": {
    type: "movie",
    title: "Kill Bill: Vol. 2",
    year: "2004",
    trakt_rating: "8.02965",
    trakt_id: "313",
    imdb_id: "tt0378194",
    tmdb_id: "393",
    url: "https://trakt.tv/movies/kill-bill-vol-2-2004",
    released: "2004-04-16",
    runtime: "136",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T04:08:33Z": {
    type: "movie",
    title: "Kill Bill: Vol. 1",
    year: "2003",
    trakt_rating: "8.13081",
    trakt_id: "19",
    imdb_id: "tt0266697",
    tmdb_id: "24",
    url: "https://trakt.tv/movies/kill-bill-vol-1-2003",
    released: "2003-10-10",
    runtime: "111",
    genres: "action,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T03:51:13Z": {
    type: "movie",
    title: "Drive",
    year: "2011",
    trakt_rating: "7.70771",
    trakt_id: "46736",
    imdb_id: "tt0780504",
    tmdb_id: "64690",
    url: "https://trakt.tv/movies/drive-2011",
    released: "2011-09-16",
    runtime: "100",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T03:13:28Z": {
    type: "movie",
    title: "Eyes Wide Shut",
    year: "1999",
    trakt_rating: "7.26171",
    trakt_id: "292",
    imdb_id: "tt0120663",
    tmdb_id: "345",
    url: "https://trakt.tv/movies/eyes-wide-shut-1999",
    released: "1999-07-16",
    runtime: "159",
    genres: "mystery,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T03:11:54Z": {
    type: "movie",
    title: "Fair Game",
    year: "2010",
    trakt_rating: "6.79032",
    trakt_id: "24770",
    imdb_id: "tt0977855",
    tmdb_id: "38363",
    url: "https://trakt.tv/movies/fair-game-2010",
    released: "2010-05-20",
    runtime: "108",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T03:03:14Z": {
    type: "movie",
    title: "For a Few Dollars More",
    year: "1965",
    trakt_rating: "8.13059",
    trakt_id: "779",
    imdb_id: "tt0059578",
    tmdb_id: "938",
    url: "https://trakt.tv/movies/for-a-few-dollars-more-1965",
    released: "1966-03-25",
    runtime: "132",
    genres: "western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T03:02:09Z": {
    type: "movie",
    title: "For All Mankind",
    year: "1989",
    trakt_rating: "8.21739",
    trakt_id: "12541",
    imdb_id: "tt0097372",
    tmdb_id: "20423",
    url: "https://trakt.tv/movies/for-all-mankind-1989",
    released: "1989-11-01",
    runtime: "80",
    genres: "documentary,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T02:50:51Z": {
    type: "movie",
    title: "Godzilla",
    year: "2014",
    trakt_rating: "6.63304",
    trakt_id: "85943",
    imdb_id: "tt0831387",
    tmdb_id: "124905",
    url: "https://trakt.tv/movies/godzilla-2014",
    released: "2014-05-16",
    runtime: "123",
    genres: "action,science-fiction,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T02:45:51Z": {
    type: "movie",
    title: "13 Assassins",
    year: "2010",
    trakt_rating: "7.62454",
    trakt_id: "42136",
    imdb_id: "tt1436045",
    tmdb_id: "58857",
    url: "https://trakt.tv/movies/13-assassins-2010",
    released: "2010-09-25",
    runtime: "141",
    genres: "action,adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T02:43:36Z": {
    type: "movie",
    title: "Good People",
    year: "2014",
    trakt_rating: "6.14216",
    trakt_id: "161255",
    imdb_id: "tt1361318",
    tmdb_id: "262338",
    url: "https://trakt.tv/movies/good-people-2014",
    released: "2014-09-26",
    runtime: "90",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T02:40:38Z": {
    type: "movie",
    title: "Gravity",
    year: "2013",
    trakt_rating: "7.52286",
    trakt_id: "34093",
    imdb_id: "tt1454468",
    tmdb_id: "49047",
    url: "https://trakt.tv/movies/gravity-2013",
    released: "2013-10-04",
    runtime: "91",
    genres: "drama,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T02:38:48Z": {
    type: "movie",
    title: "Halloween",
    year: "1978",
    trakt_rating: "7.5271",
    trakt_id: "788",
    imdb_id: "tt0077651",
    tmdb_id: "948",
    url: "https://trakt.tv/movies/halloween-1978",
    released: "1978-10-25",
    runtime: "91",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T02:36:47Z": {
    type: "movie",
    title: "Harvey",
    year: "1950",
    trakt_rating: "8.00292",
    trakt_id: "6816",
    imdb_id: "tt0042546",
    tmdb_id: "11787",
    url: "https://trakt.tv/movies/harvey-1950",
    released: "1950-12-21",
    runtime: "104",
    genres: "comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T02:33:18Z": {
    type: "movie",
    title: "Running Scared",
    year: "2006",
    trakt_rating: "7.21166",
    trakt_id: "3884",
    imdb_id: "tt0404390",
    tmdb_id: "7304",
    url: "https://trakt.tv/movies/running-scared-2006",
    released: "2006-02-24",
    runtime: "122",
    genres: "action,crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T02:28:15Z": {
    type: "movie",
    title: "A Fistful of Dollars",
    year: "1964",
    trakt_rating: "7.9161",
    trakt_id: "311",
    imdb_id: "tt0058461",
    tmdb_id: "391",
    url: "https://trakt.tv/movies/a-fistful-of-dollars-1964",
    released: "1965-03-05",
    runtime: "99",
    genres: "western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T02:24:33Z": {
    type: "movie",
    title: "How to Train Your Dragon",
    year: "2010",
    trakt_rating: "8.22274",
    trakt_id: "5405",
    imdb_id: "tt0892769",
    tmdb_id: "10191",
    url: "https://trakt.tv/movies/how-to-train-your-dragon-2010",
    released: "2010-03-26",
    runtime: "98",
    genres: "adventure,animation,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T02:21:15Z": {
    type: "movie",
    title: "I Am Legend",
    year: "2007",
    trakt_rating: "7.46503",
    trakt_id: "3642",
    imdb_id: "tt0480249",
    tmdb_id: "6479",
    url: "https://trakt.tv/movies/i-am-legend-2007",
    released: "2007-12-14",
    runtime: "101",
    genres: "drama,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T01:27:42Z": {
    type: "movie",
    title: "The Ring",
    year: "2002",
    trakt_rating: "7.0773",
    trakt_id: "446",
    imdb_id: "tt0298130",
    tmdb_id: "565",
    url: "https://trakt.tv/movies/the-ring-2002",
    released: "2002-10-18",
    runtime: "115",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T01:26:41Z": {
    type: "movie",
    title: "The Rock",
    year: "1996",
    trakt_rating: "7.72783",
    trakt_id: "5061",
    imdb_id: "tt0117500",
    tmdb_id: "9802",
    url: "https://trakt.tv/movies/the-rock-1996",
    released: "1996-06-07",
    runtime: "137",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T01:12:08Z": {
    type: "movie",
    title: "Brokedown Palace",
    year: "1999",
    trakt_rating: "6.86702",
    trakt_id: "10845",
    imdb_id: "tt0120620",
    tmdb_id: "17707",
    url: "https://trakt.tv/movies/brokedown-palace-1999",
    released: "1999-08-13",
    runtime: "100",
    genres: "drama,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T01:08:23Z": {
    type: "movie",
    title: "Kalifornia",
    year: "1993",
    trakt_rating: "6.90733",
    trakt_id: "6062",
    imdb_id: "tt0107302",
    tmdb_id: "10909",
    url: "https://trakt.tv/movies/kalifornia-1993",
    released: "1993-09-03",
    runtime: "117",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T00:56:27Z": {
    type: "movie",
    title: "The Jackal",
    year: "1997",
    trakt_rating: "6.88712",
    trakt_id: "2813",
    imdb_id: "tt0119395",
    tmdb_id: "4824",
    url: "https://trakt.tv/movies/the-jackal-1997",
    released: "1997-11-14",
    runtime: "124",
    genres: "action,adventure,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-15T00:01:43Z": {
    type: "movie",
    title: "Reversal of Fortune",
    year: "1990",
    trakt_rating: "7.39759",
    trakt_id: "25052",
    imdb_id: "tt0100486",
    tmdb_id: "38718",
    url: "https://trakt.tv/movies/reversal-of-fortune-1990",
    released: "1990-10-05",
    runtime: "111",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T23:34:12Z": {
    type: "movie",
    title: "Battle Royale",
    year: "2000",
    trakt_rating: "7.54054",
    trakt_id: "2134",
    imdb_id: "tt0266308",
    tmdb_id: "3176",
    url: "https://trakt.tv/movies/battle-royale-2000",
    released: "2000-12-16",
    runtime: "114",
    genres: "drama,thriller,action,suspense",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T22:49:58Z": {
    type: "movie",
    title: "Marathon Man",
    year: "1976",
    trakt_rating: "7.29844",
    trakt_id: "5697",
    imdb_id: "tt0074860",
    tmdb_id: "10518",
    url: "https://trakt.tv/movies/marathon-man-1976",
    released: "1976-10-08",
    runtime: "125",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T22:42:54Z": {
    type: "movie",
    title: "Mars Attacks!",
    year: "1996",
    trakt_rating: "6.68792",
    trakt_id: "45",
    imdb_id: "tt0116996",
    tmdb_id: "75",
    url: "https://trakt.tv/movies/mars-attacks-1996",
    released: "1996-12-12",
    runtime: "106",
    genres: "comedy,fantasy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T18:36:59Z": {
    type: "movie",
    title: "I Saw the Devil",
    year: "2010",
    trakt_rating: "7.8608",
    trakt_id: "34728",
    imdb_id: "tt1588170",
    tmdb_id: "49797",
    url: "https://trakt.tv/movies/i-saw-the-devil-2010",
    released: "2010-08-12",
    runtime: "144",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T17:15:25Z": {
    type: "movie",
    title: "Network",
    year: "1976",
    trakt_rating: "7.92804",
    trakt_id: "5936",
    imdb_id: "tt0074958",
    tmdb_id: "10774",
    url: "https://trakt.tv/movies/network-1976",
    released: "1976-11-14",
    runtime: "122",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T16:20:25Z": {
    type: "movie",
    title: "Once Were Warriors",
    year: "1994",
    trakt_rating: "7.68815",
    trakt_id: "412",
    imdb_id: "tt0110729",
    tmdb_id: "527",
    url: "https://trakt.tv/movies/once-were-warriors-1994",
    released: "1994-09-02",
    runtime: "99",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T16:08:00Z": {
    type: "movie",
    title: "Ghost Dog: The Way of the Samurai",
    year: "1999",
    trakt_rating: "7.43073",
    trakt_id: "2809",
    imdb_id: "tt0165798",
    tmdb_id: "4816",
    url: "https://trakt.tv/movies/ghost-dog-the-way-of-the-samurai-1999",
    released: "2000-03-03",
    runtime: "116",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T15:54:25Z": {
    type: "movie",
    title: "Dead Presidents",
    year: "1995",
    trakt_rating: "7.27103",
    trakt_id: "6531",
    imdb_id: "tt0112819",
    tmdb_id: "11443",
    url: "https://trakt.tv/movies/dead-presidents-1995",
    released: "1995-10-06",
    runtime: "119",
    genres: "action,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T15:39:44Z": {
    type: "movie",
    title: "Gallipoli",
    year: "1981",
    trakt_rating: "7.46046",
    trakt_id: "6720",
    imdb_id: "tt0082432",
    tmdb_id: "11646",
    url: "https://trakt.tv/movies/gallipoli-1981",
    released: "1981-08-13",
    runtime: "112",
    genres: "drama,history,war,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T15:22:07Z": {
    type: "movie",
    title: "Raging Bull",
    year: "1980",
    trakt_rating: "7.91599",
    trakt_id: "1034",
    imdb_id: "tt0081398",
    tmdb_id: "1578",
    url: "https://trakt.tv/movies/raging-bull-1980",
    released: "1980-11-14",
    runtime: "129",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T13:45:25Z": {
    type: "movie",
    title: "In Cold Blood",
    year: "1967",
    trakt_rating: "7.52229",
    trakt_id: "11614",
    imdb_id: "tt0061809",
    tmdb_id: "18900",
    url: "https://trakt.tv/movies/in-cold-blood-1967",
    released: "1967-12-15",
    runtime: "134",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T13:42:24Z": {
    type: "movie",
    title: "Flight",
    year: "2012",
    trakt_rating: "7.30688",
    trakt_id: "65754",
    imdb_id: "tt1907668",
    tmdb_id: "87502",
    url: "https://trakt.tv/movies/flight-2012",
    released: "2012-11-02",
    runtime: "138",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T02:51:31Z": {
    type: "movie",
    title: "Sleepers",
    year: "1996",
    trakt_rating: "7.73291",
    trakt_id: "662",
    imdb_id: "tt0117665",
    tmdb_id: "819",
    url: "https://trakt.tv/movies/sleepers-1996",
    released: "1996-10-18",
    runtime: "147",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T02:34:13Z": {
    type: "movie",
    title: "Before the Devil Knows You're Dead",
    year: "2007",
    trakt_rating: "7.11332",
    trakt_id: "3986",
    imdb_id: "tt0292963",
    tmdb_id: "7972",
    url: "https://trakt.tv/movies/before-the-devil-knows-you-re-dead-2007",
    released: "2007-10-26",
    runtime: "117",
    genres: "drama,thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T02:22:47Z": {
    type: "movie",
    title: "Syriana",
    year: "2005",
    trakt_rating: "6.74811",
    trakt_id: "189",
    imdb_id: "tt0365737",
    tmdb_id: "231",
    url: "https://trakt.tv/movies/syriana-2005",
    released: "2005-11-23",
    runtime: "128",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T00:22:18Z": {
    type: "movie",
    title: "Poltergeist",
    year: "1982",
    trakt_rating: "7.45607",
    trakt_id: "487",
    imdb_id: "tt0084516",
    tmdb_id: "609",
    url: "https://trakt.tv/movies/poltergeist-1982",
    released: "1982-06-04",
    runtime: "114",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T00:18:02Z": {
    type: "movie",
    title: "Apollo 13",
    year: "1995",
    trakt_rating: "7.97058",
    trakt_id: "448",
    imdb_id: "tt0112384",
    tmdb_id: "568",
    url: "https://trakt.tv/movies/apollo-13-1995",
    released: "1995-06-30",
    runtime: "140",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-14T00:06:54Z": {
    type: "movie",
    title: "Close Encounters of the Third Kind",
    year: "1977",
    trakt_rating: "7.64642",
    trakt_id: "683",
    imdb_id: "tt0075860",
    tmdb_id: "840",
    url: "https://trakt.tv/movies/close-encounters-of-the-third-kind-1977",
    released: "1977-11-18",
    runtime: "135",
    genres: "drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-13T22:30:57Z": {
    type: "movie",
    title: "Sexy Beast",
    year: "2001",
    trakt_rating: "7.2269",
    trakt_id: "6850",
    imdb_id: "tt0203119",
    tmdb_id: "11826",
    url: "https://trakt.tv/movies/sexy-beast-2001",
    released: "2001-06-16",
    runtime: "89",
    genres: "crime,drama,thriller,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-13T22:19:57Z": {
    type: "movie",
    title: "Once Upon a Time in America",
    year: "1984",
    trakt_rating: "8.17451",
    trakt_id: "262",
    imdb_id: "tt0087843",
    tmdb_id: "311",
    url: "https://trakt.tv/movies/once-upon-a-time-in-america-1984",
    released: "1984-06-01",
    runtime: "229",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-13T03:20:03Z": {
    type: "movie",
    title: "Predator",
    year: "1987",
    trakt_rating: "7.84896",
    trakt_id: "75",
    imdb_id: "tt0093773",
    tmdb_id: "106",
    url: "https://trakt.tv/movies/predator-1987",
    released: "1987-06-12",
    runtime: "107",
    genres: "action,adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-13T03:11:01Z": {
    type: "movie",
    title: "Akira",
    year: "1988",
    trakt_rating: "8.15127",
    trakt_id: "115",
    imdb_id: "tt0094625",
    tmdb_id: "149",
    url: "https://trakt.tv/movies/akira-1988",
    released: "1988-07-16",
    runtime: "124",
    genres: "science-fiction,anime,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-13T02:43:50Z": {
    type: "movie",
    title: "Midnight Express",
    year: "1978",
    trakt_rating: "7.5696",
    trakt_id: "6424",
    imdb_id: "tt0077928",
    tmdb_id: "11327",
    url: "https://trakt.tv/movies/midnight-express-1978",
    released: "1978-10-06",
    runtime: "121",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-13T01:14:56Z": {
    type: "movie",
    title: "My Life as a Dog",
    year: "1985",
    trakt_rating: "7.23846",
    trakt_id: "4317",
    imdb_id: "tt0089606",
    tmdb_id: "8816",
    url: "https://trakt.tv/movies/my-life-as-a-dog-1985",
    released: "1985-12-12",
    runtime: "102",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-13T01:12:52Z": {
    type: "movie",
    title: "Scanners",
    year: "1981",
    trakt_rating: "6.86538",
    trakt_id: "4816",
    imdb_id: "tt0081455",
    tmdb_id: "9538",
    url: "https://trakt.tv/movies/scanners-1981",
    released: "1981-01-16",
    runtime: "103",
    genres: "science-fiction,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-13T01:11:00Z": {
    type: "movie",
    title: "The Place Beyond the Pines",
    year: "2013",
    trakt_rating: "7.25451",
    trakt_id: "70754",
    imdb_id: "tt1817273",
    tmdb_id: "97367",
    url: "https://trakt.tv/movies/the-place-beyond-the-pines-2013",
    released: "2013-03-27",
    runtime: "140",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-12T04:45:51Z": {
    type: "movie",
    title: "Raising Arizona",
    year: "1987",
    trakt_rating: "7.40537",
    trakt_id: "302",
    imdb_id: "tt0093822",
    tmdb_id: "378",
    url: "https://trakt.tv/movies/raising-arizona-1987",
    released: "1987-03-01",
    runtime: "94",
    genres: "comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-12T04:40:16Z": {
    type: "movie",
    title: "The Manchurian Candidate",
    year: "1962",
    trakt_rating: "7.58128",
    trakt_id: "812",
    imdb_id: "tt0056218",
    tmdb_id: "982",
    url: "https://trakt.tv/movies/the-manchurian-candidate-1962",
    released: "1962-10-24",
    runtime: "126",
    genres: "thriller,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-12T04:34:59Z": {
    type: "movie",
    title: "The Prestige",
    year: "2006",
    trakt_rating: "8.40423",
    trakt_id: "851",
    imdb_id: "tt0482571",
    tmdb_id: "1124",
    url: "https://trakt.tv/movies/the-prestige-2006",
    released: "2006-10-19",
    runtime: "130",
    genres: "drama,mystery,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-12T01:41:56Z": {
    type: "movie",
    title: "C.H.U.D.",
    year: "1984",
    trakt_rating: "6.04504",
    trakt_id: "14274",
    imdb_id: "tt0087015",
    tmdb_id: "23730",
    url: "https://trakt.tv/movies/c-h-u-d-1984",
    released: "1984-08-31",
    runtime: "97",
    genres: "horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-11T01:56:02Z": {
    type: "movie",
    title: "The Talented Mr. Ripley",
    year: "1999",
    trakt_rating: "7.30144",
    trakt_id: "859",
    imdb_id: "tt0134119",
    tmdb_id: "1213",
    url: "https://trakt.tv/movies/the-talented-mr-ripley-1999",
    released: "1999-12-25",
    runtime: "139",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-10T15:54:06Z": {
    type: "movie",
    title: "Killing Zoe",
    year: "1993",
    trakt_rating: "6.85674",
    trakt_id: "399",
    imdb_id: "tt0110265",
    tmdb_id: "507",
    url: "https://trakt.tv/movies/killing-zoe-1993",
    released: "1994-08-19",
    runtime: "96",
    genres: "action,crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-10T15:45:13Z": {
    type: "movie",
    title: "War for the Planet of the Apes",
    year: "2017",
    trakt_rating: "7.44923",
    trakt_id: "178749",
    imdb_id: "tt3450958",
    tmdb_id: "281338",
    url: "https://trakt.tv/movies/war-for-the-planet-of-the-apes-2017",
    released: "2017-07-14",
    runtime: "140",
    genres: "science-fiction,drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-10T15:42:38Z": {
    type: "movie",
    title: "The Cabin in the Woods",
    year: "2012",
    trakt_rating: "7.23057",
    trakt_id: "13937",
    imdb_id: "tt1259521",
    tmdb_id: "22970",
    url: "https://trakt.tv/movies/the-cabin-in-the-woods-2012",
    released: "2012-04-12",
    runtime: "95",
    genres: "horror,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-10T05:50:15Z": {
    type: "movie",
    title: "The Emoji Movie",
    year: "2017",
    trakt_rating: "6.30298",
    trakt_id: "239730",
    imdb_id: "tt4877122",
    tmdb_id: "378236",
    url: "https://trakt.tv/movies/the-emoji-movie-2017",
    released: "2017-07-23",
    runtime: "86",
    genres: "animation,family,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-09T15:00:18Z": {
    type: "movie",
    title: "The Day",
    year: "2011",
    trakt_rating: "5.75188",
    trakt_id: "63117",
    imdb_id: "tt1756799",
    tmdb_id: "84226",
    url: "https://trakt.tv/movies/the-day-2011",
    released: "2012-08-29",
    runtime: "85",
    genres: "drama,horror,science-fiction,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-08T02:20:33Z": {
    type: "movie",
    title: "The Drop",
    year: "2014",
    trakt_rating: "7.03248",
    trakt_id: "100732",
    imdb_id: "tt1600196",
    tmdb_id: "154400",
    url: "https://trakt.tv/movies/the-drop-2014",
    released: "2014-09-12",
    runtime: "106",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-07T18:40:07Z": {
    type: "movie",
    title: "Contact",
    year: "1997",
    trakt_rating: "7.79026",
    trakt_id: "558",
    imdb_id: "tt0118884",
    tmdb_id: "686",
    url: "https://trakt.tv/movies/contact-1997",
    released: "1997-07-11",
    runtime: "150",
    genres: "drama,mystery,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-06T20:36:18Z": {
    type: "movie",
    title: "TimeScapes",
    year: "2012",
    trakt_rating: "7.37903",
    trakt_id: "78892",
    imdb_id: "tt2297031",
    tmdb_id: "112162",
    url: "https://trakt.tv/movies/timescapes-2012",
    released: "2012-06-01",
    runtime: "52",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-04T04:15:49Z": {
    type: "movie",
    title: "The Great Escape",
    year: "1963",
    trakt_rating: "8.11517",
    trakt_id: "3382",
    imdb_id: "tt0057115",
    tmdb_id: "5925",
    url: "https://trakt.tv/movies/the-great-escape-1963",
    released: "1963-07-04",
    runtime: "173",
    genres: "adventure,drama,history,thriller,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-04T04:15:25Z": {
    type: "movie",
    title: "The Bridge on the River Kwai",
    year: "1957",
    trakt_rating: "7.91121",
    trakt_id: "669",
    imdb_id: "tt0050212",
    tmdb_id: "826",
    url: "https://trakt.tv/movies/the-bridge-on-the-river-kwai-1957",
    released: "1957-12-14",
    runtime: "161",
    genres: "drama,history,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-04T01:32:07Z": {
    type: "movie",
    title: "Mysterious Skin",
    year: "2005",
    trakt_rating: "7.47045",
    trakt_id: "6299",
    imdb_id: "tt0370986",
    tmdb_id: "11171",
    url: "https://trakt.tv/movies/mysterious-skin-2005",
    released: "2005-05-06",
    runtime: "105",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-03T06:53:38Z": {
    type: "movie",
    title: "In the Company of Men",
    year: "1997",
    trakt_rating: "6.95122",
    trakt_id: "8796",
    imdb_id: "tt0119361",
    tmdb_id: "14585",
    url: "https://trakt.tv/movies/in-the-company-of-men-1997",
    released: "1997-03-28",
    runtime: "97",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-03T06:44:58Z": {
    type: "movie",
    title: "A Simple Plan",
    year: "1998",
    trakt_rating: "7.408",
    trakt_id: "5435",
    imdb_id: "tt0120324",
    tmdb_id: "10223",
    url: "https://trakt.tv/movies/a-simple-plan-1998",
    released: "1998-12-11",
    runtime: "121",
    genres: "thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-03T01:45:30Z": {
    type: "movie",
    title: "Airplane!",
    year: "1980",
    trakt_rating: "7.76131",
    trakt_id: "656",
    imdb_id: "tt0080339",
    tmdb_id: "813",
    url: "https://trakt.tv/movies/airplane-1980",
    released: "1980-07-02",
    runtime: "88",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-02T00:39:43Z": {
    type: "movie",
    title: "Snowpiercer",
    year: "2013",
    trakt_rating: "7.07777",
    trakt_id: "77887",
    imdb_id: "tt1706620",
    tmdb_id: "110415",
    url: "https://trakt.tv/movies/snowpiercer-2013",
    released: "2014-06-27",
    runtime: "127",
    genres: "action,drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-02T00:34:32Z": {
    type: "movie",
    title: "Captain Underpants: The First Epic Movie",
    year: "2017",
    trakt_rating: "7.16744",
    trakt_id: "166828",
    imdb_id: "tt2091256",
    tmdb_id: "268531",
    url: "https://trakt.tv/movies/captain-underpants-the-first-epic-movie-2017",
    released: "2017-06-02",
    runtime: "89",
    genres: "animation,family,comedy,action,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-02T00:33:09Z": {
    type: "movie",
    title: "The Hunger Games",
    year: "2012",
    trakt_rating: "7.52652",
    trakt_id: "51342",
    imdb_id: "tt1392170",
    tmdb_id: "70160",
    url: "https://trakt.tv/movies/the-hunger-games-2012",
    released: "2012-03-23",
    runtime: "142",
    genres: "adventure,fantasy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-02T00:32:49Z": {
    type: "movie",
    title: "The Resurrected",
    year: "1991",
    trakt_rating: "6.41085",
    trakt_id: "19497",
    imdb_id: "tt0105242",
    tmdb_id: "30694",
    url: "https://trakt.tv/movies/the-resurrected-1991",
    released: "1992-04-15",
    runtime: "108",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-02T00:32:35Z": {
    type: "movie",
    title: "The Hunger Games: Catching Fire",
    year: "2013",
    trakt_rating: "7.63393",
    trakt_id: "72719",
    imdb_id: "tt1951264",
    tmdb_id: "101299",
    url: "https://trakt.tv/movies/the-hunger-games-catching-fire-2013",
    released: "2013-11-22",
    runtime: "146",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-02T00:20:38Z": {
    type: "movie",
    title: "The Hunger Games: Mockingjay - Part 2",
    year: "2015",
    trakt_rating: "7.02493",
    trakt_id: "89543",
    imdb_id: "tt1951266",
    tmdb_id: "131634",
    url: "https://trakt.tv/movies/the-hunger-games-mockingjay-part-2-2015",
    released: "2015-11-18",
    runtime: "137",
    genres: "adventure,science-fiction,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-10-01T01:34:58Z": {
    type: "movie",
    title: "Watchmen",
    year: "2009",
    trakt_rating: "7.67522",
    trakt_id: "7729",
    imdb_id: "tt0409459",
    tmdb_id: "13183",
    url: "https://trakt.tv/movies/watchmen-2009",
    released: "2009-03-06",
    runtime: "163",
    genres: "mystery,science-fiction,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-30T00:45:06Z": {
    type: "movie",
    title: "Mad Max 2",
    year: "1981",
    trakt_rating: "7.56767",
    trakt_id: "4314",
    imdb_id: "tt0082694",
    tmdb_id: "8810",
    url: "https://trakt.tv/movies/mad-max-2-1981",
    released: "1981-12-24",
    runtime: "96",
    genres: "action,adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-30T00:44:36Z": {
    type: "movie",
    title: "Mad Max",
    year: "1979",
    trakt_rating: "7.01421",
    trakt_id: "4934",
    imdb_id: "tt0079501",
    tmdb_id: "9659",
    url: "https://trakt.tv/movies/mad-max-1979",
    released: "1979-04-12",
    runtime: "91",
    genres: "action,adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-30T00:42:42Z": {
    type: "movie",
    title: "The Warriors",
    year: "1979",
    trakt_rating: "7.70011",
    trakt_id: "6560",
    imdb_id: "tt0080120",
    tmdb_id: "11474",
    url: "https://trakt.tv/movies/the-warriors-1979",
    released: "1979-02-09",
    runtime: "94",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-30T00:40:42Z": {
    type: "movie",
    title: "The Return of the Living Dead",
    year: "1985",
    trakt_rating: "7.56964",
    trakt_id: "6075",
    imdb_id: "tt0089907",
    tmdb_id: "10925",
    url: "https://trakt.tv/movies/the-return-of-the-living-dead-1985",
    released: "1985-08-16",
    runtime: "91",
    genres: "comedy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-30T00:38:41Z": {
    type: "movie",
    title: "Only Lovers Left Alive",
    year: "2013",
    trakt_rating: "7.29985",
    trakt_id: "99920",
    imdb_id: "tt1714915",
    tmdb_id: "152603",
    url: "https://trakt.tv/movies/only-lovers-left-alive-2013",
    released: "2014-02-19",
    runtime: "123",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-29T04:32:43Z": {
    type: "movie",
    title: "Drunken Master",
    year: "1978",
    trakt_rating: "7.66242",
    trakt_id: "6356",
    imdb_id: "tt0080179",
    tmdb_id: "11230",
    url: "https://trakt.tv/movies/drunken-master-1978",
    released: "1978-10-05",
    runtime: "111",
    genres: "action,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-29T04:30:45Z": {
    type: "movie",
    title: "True Grit",
    year: "2010",
    trakt_rating: "7.61505",
    trakt_id: "29981",
    imdb_id: "tt1403865",
    tmdb_id: "44264",
    url: "https://trakt.tv/movies/true-grit-2010",
    released: "2010-12-22",
    runtime: "110",
    genres: "western,drama,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-29T04:25:31Z": {
    type: "movie",
    title: "Message from the King",
    year: "2017",
    trakt_rating: "7.01554",
    trakt_id: "214316",
    imdb_id: "tt1712192",
    tmdb_id: "301355",
    url: "https://trakt.tv/movies/message-from-the-king-2017",
    released: "2017-08-04",
    runtime: "102",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-28T23:12:36Z": {
    type: "movie",
    title: "When the Wind Blows",
    year: "1986",
    trakt_rating: "7.76488",
    trakt_id: "6014",
    imdb_id: "tt0090315",
    tmdb_id: "10857",
    url: "https://trakt.tv/movies/when-the-wind-blows-1986",
    released: "1986-10-24",
    runtime: "81",
    genres: "animation,war,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-28T22:46:43Z": {
    type: "movie",
    title: "Watership Down",
    year: "1978",
    trakt_rating: "7.50057",
    trakt_id: "6860",
    imdb_id: "tt0078480",
    tmdb_id: "11837",
    url: "https://trakt.tv/movies/watership-down-1978",
    released: "1978-10-19",
    runtime: "88",
    genres: "animation,drama,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-28T13:07:04Z": {
    type: "movie",
    title: "Edward Scissorhands",
    year: "1990",
    trakt_rating: "7.84985",
    trakt_id: "127",
    imdb_id: "tt0099487",
    tmdb_id: "162",
    url: "https://trakt.tv/movies/edward-scissorhands-1990",
    released: "1990-12-07",
    runtime: "105",
    genres: "drama,fantasy,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-28T02:16:13Z": {
    type: "movie",
    title: "The Lego Movie",
    year: "2014",
    trakt_rating: "7.7794",
    trakt_id: "92226",
    imdb_id: "tt1490017",
    tmdb_id: "137106",
    url: "https://trakt.tv/movies/the-lego-movie-2014",
    released: "2014-02-07",
    runtime: "100",
    genres: "adventure,animation,comedy,fantasy,family,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-28T00:47:40Z": {
    type: "movie",
    title: "Speed",
    year: "1994",
    trakt_rating: "7.4597",
    trakt_id: "1083",
    imdb_id: "tt0111257",
    tmdb_id: "1637",
    url: "https://trakt.tv/movies/speed-1994",
    released: "1994-06-09",
    runtime: "116",
    genres: "action,adventure,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-28T00:41:47Z": {
    type: "movie",
    title: "These Final Hours",
    year: "2014",
    trakt_rating: "6.65823",
    trakt_id: "124566",
    imdb_id: "tt2268458",
    tmdb_id: "199575",
    url: "https://trakt.tv/movies/these-final-hours-2014",
    released: "2014-07-31",
    runtime: "87",
    genres: "drama,thriller,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-27T12:14:03Z": {
    type: "movie",
    title: "Spider-Man: Homecoming",
    year: "2017",
    trakt_rating: "7.64868",
    trakt_id: "196213",
    imdb_id: "tt2250912",
    tmdb_id: "315635",
    url: "https://trakt.tv/movies/spider-man-homecoming-2017",
    released: "2017-07-07",
    runtime: "133",
    genres: "action,adventure,science-fiction,superhero,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-27T03:29:43Z": {
    type: "movie",
    title: "Cargo",
    year: "2017",
    trakt_rating: "6.63402",
    trakt_id: "287098",
    imdb_id: "tt3860916",
    tmdb_id: "425972",
    url: "https://trakt.tv/movies/cargo-2017",
    released: "2017-10-06",
    runtime: "105",
    genres: "drama,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-26T02:01:24Z": {
    type: "movie",
    title: "Monsters, Inc.",
    year: "2001",
    trakt_rating: "8.19912",
    trakt_id: "464",
    imdb_id: "tt0198781",
    tmdb_id: "585",
    url: "https://trakt.tv/movies/monsters-inc-2001",
    released: "2001-11-02",
    runtime: "92",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-25T00:59:08Z": {
    type: "movie",
    title: "It Stains the Sands Red",
    year: "2016",
    trakt_rating: "6.26355",
    trakt_id: "281700",
    imdb_id: "tt5735280",
    tmdb_id: "434873",
    url: "https://trakt.tv/movies/it-stains-the-sands-red-2016",
    released: "2017-07-28",
    runtime: "92",
    genres: "horror,drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-24T12:11:07Z": {
    type: "movie",
    title: "Johnny Dangerously",
    year: "1984",
    trakt_rating: "7.25329",
    trakt_id: "10291",
    imdb_id: "tt0087507",
    tmdb_id: "16806",
    url: "https://trakt.tv/movies/johnny-dangerously-1984",
    released: "1984-12-21",
    runtime: "90",
    genres: "comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-24T03:09:23Z": {
    type: "movie",
    title: "Vengeance",
    year: "2009",
    trakt_rating: "6.71429",
    trakt_id: "11613",
    imdb_id: "tt1329454",
    tmdb_id: "18899",
    url: "https://trakt.tv/movies/vengeance-2009",
    released: "2009-05-20",
    runtime: "108",
    genres: "action,thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-24T03:05:23Z": {
    type: "movie",
    title: "Body Heat",
    year: "1981",
    trakt_rating: "7.27687",
    trakt_id: "8660",
    imdb_id: "tt0082089",
    tmdb_id: "14412",
    url: "https://trakt.tv/movies/body-heat-1981",
    released: "1981-08-28",
    runtime: "113",
    genres: "crime,thriller,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-24T02:52:42Z": {
    type: "movie",
    title: "Serpico",
    year: "1973",
    trakt_rating: "7.70829",
    trakt_id: "4493",
    imdb_id: "tt0070666",
    tmdb_id: "9040",
    url: "https://trakt.tv/movies/serpico-1973",
    released: "1973-12-18",
    runtime: "130",
    genres: "crime,drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-24T02:39:11Z": {
    type: "movie",
    title: "Changeling",
    year: "2008",
    trakt_rating: "7.58821",
    trakt_id: "2287",
    imdb_id: "tt0824747",
    tmdb_id: "3580",
    url: "https://trakt.tv/movies/changeling-2008",
    released: "2008-10-24",
    runtime: "141",
    genres: "drama,mystery,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-23T18:52:32Z": {
    type: "movie",
    title: "The Road",
    year: "2009",
    trakt_rating: "7.26152",
    trakt_id: "12752",
    imdb_id: "tt0898367",
    tmdb_id: "20766",
    url: "https://trakt.tv/movies/the-road-2009",
    released: "2009-11-25",
    runtime: "111",
    genres: "adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-23T00:26:53Z": {
    type: "movie",
    title: "Bad Boys",
    year: "1983",
    trakt_rating: "7.33333",
    trakt_id: "8065",
    imdb_id: "tt0085210",
    tmdb_id: "13633",
    url: "https://trakt.tv/movies/bad-boys-1983",
    released: "1983-03-25",
    runtime: "123",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-23T00:26:32Z": {
    type: "movie",
    title: "Sleeper",
    year: "1973",
    trakt_rating: "7.09131",
    trakt_id: "6641",
    imdb_id: "tt0070707",
    tmdb_id: "11561",
    url: "https://trakt.tv/movies/sleeper-1973",
    released: "1973-12-17",
    runtime: "87",
    genres: "comedy,science-fiction,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-21T21:40:30Z": {
    type: "movie",
    title: "Saving Private Ryan",
    year: "1998",
    trakt_rating: "8.5459",
    trakt_id: "700",
    imdb_id: "tt0120815",
    tmdb_id: "857",
    url: "https://trakt.tv/movies/saving-private-ryan-1998",
    released: "1998-07-24",
    runtime: "169",
    genres: "drama,history,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-21T11:34:28Z": {
    type: "movie",
    title: "Amadeus",
    year: "1984",
    trakt_rating: "8.16255",
    trakt_id: "235",
    imdb_id: "tt0086879",
    tmdb_id: "279",
    url: "https://trakt.tv/movies/amadeus-1984",
    released: "1984-09-19",
    runtime: "161",
    genres: "drama,history,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-21T11:32:59Z": {
    type: "movie",
    title: "E.T. the Extra-Terrestrial",
    year: "1982",
    trakt_rating: "7.82603",
    trakt_id: "479",
    imdb_id: "tt0083866",
    tmdb_id: "601",
    url: "https://trakt.tv/movies/e-t-the-extra-terrestrial-1982",
    released: "1982-06-11",
    runtime: "115",
    genres: "adventure,fantasy,science-fiction,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-21T04:38:50Z": {
    type: "movie",
    title: "Das Boot",
    year: "1981",
    trakt_rating: "8.26688",
    trakt_id: "307",
    imdb_id: "tt0082096",
    tmdb_id: "387",
    url: "https://trakt.tv/movies/das-boot-1981",
    released: "1981-09-17",
    runtime: "150",
    genres: "drama,history,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-21T02:51:46Z": {
    type: "movie",
    title: "North by Northwest",
    year: "1959",
    trakt_rating: "8.11926",
    trakt_id: "172",
    imdb_id: "tt0053125",
    tmdb_id: "213",
    url: "https://trakt.tv/movies/north-by-northwest-1959",
    released: "1959-07-17",
    runtime: "136",
    genres: "thriller,mystery,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-21T02:51:14Z": {
    type: "movie",
    title: "Donnie Darko",
    year: "2001",
    trakt_rating: "8.01298",
    trakt_id: "107",
    imdb_id: "tt0246578",
    tmdb_id: "141",
    url: "https://trakt.tv/movies/donnie-darko-2001",
    released: "2001-10-26",
    runtime: "114",
    genres: "drama,mystery,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-21T02:48:14Z": {
    type: "movie",
    title: "Rear Window",
    year: "1954",
    trakt_rating: "8.30557",
    trakt_id: "447",
    imdb_id: "tt0047396",
    tmdb_id: "567",
    url: "https://trakt.tv/movies/rear-window-1954",
    released: "1954-09-01",
    runtime: "112",
    genres: "mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-21T02:47:44Z": {
    type: "movie",
    title: "Strangers on a Train",
    year: "1951",
    trakt_rating: "7.68824",
    trakt_id: "688",
    imdb_id: "tt0044079",
    tmdb_id: "845",
    url: "https://trakt.tv/movies/strangers-on-a-train-1951",
    released: "1951-06-27",
    runtime: "101",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-21T02:47:15Z": {
    type: "movie",
    title: "Rope",
    year: "1948",
    trakt_rating: "7.91505",
    trakt_id: "1036",
    imdb_id: "tt0040746",
    tmdb_id: "1580",
    url: "https://trakt.tv/movies/rope-1948",
    released: "1948-09-25",
    runtime: "81",
    genres: "crime,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-21T02:32:06Z": {
    type: "movie",
    title: "Baraka",
    year: "1992",
    trakt_rating: "8.54263",
    trakt_id: "8363",
    imdb_id: "tt0103767",
    tmdb_id: "14002",
    url: "https://trakt.tv/movies/baraka-1992",
    released: "1993-11-19",
    runtime: "97",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-21T02:24:42Z": {
    type: "movie",
    title: "Vertigo",
    year: "1958",
    trakt_rating: "8.04939",
    trakt_id: "338",
    imdb_id: "tt0052357",
    tmdb_id: "426",
    url: "https://trakt.tv/movies/vertigo-1958",
    released: "1958-05-28",
    runtime: "128",
    genres: "mystery,thriller,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-21T02:16:32Z": {
    type: "movie",
    title: "Super Troopers",
    year: "2001",
    trakt_rating: "7.50274",
    trakt_id: "26096",
    imdb_id: "tt0247745",
    tmdb_id: "39939",
    url: "https://trakt.tv/movies/super-troopers-2001",
    released: "2001-02-15",
    runtime: "100",
    genres: "comedy,crime,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-20T11:29:21Z": {
    type: "movie",
    title: "A Ghost Story",
    year: "2017",
    trakt_rating: "6.81167",
    trakt_id: "272554",
    imdb_id: "tt6265828",
    tmdb_id: "428449",
    url: "https://trakt.tv/movies/a-ghost-story-2017",
    released: "2017-07-07",
    runtime: "93",
    genres: "drama,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-19T12:32:33Z": {
    type: "movie",
    title: "Insidious",
    year: "2010",
    trakt_rating: "7.11419",
    trakt_id: "34068",
    imdb_id: "tt1591095",
    tmdb_id: "49018",
    url: "https://trakt.tv/movies/insidious-2010",
    released: "2011-04-01",
    runtime: "103",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-19T11:40:02Z": {
    type: "movie",
    title: "Citizen Kane",
    year: "1941",
    trakt_rating: "7.93985",
    trakt_id: "11",
    imdb_id: "tt0033467",
    tmdb_id: "15",
    url: "https://trakt.tv/movies/citizen-kane-1941",
    released: "1941-05-06",
    runtime: "119",
    genres: "drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-19T03:51:44Z": {
    type: "movie",
    title: "First Blood",
    year: "1982",
    trakt_rating: "7.73374",
    trakt_id: "907",
    imdb_id: "tt0083944",
    tmdb_id: "1368",
    url: "https://trakt.tv/movies/first-blood-1982",
    released: "1982-10-22",
    runtime: "93",
    genres: "action,adventure,thriller,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-19T03:29:39Z": {
    type: "movie",
    title: "The African Queen",
    year: "1952",
    trakt_rating: "7.60586",
    trakt_id: "382",
    imdb_id: "tt0043265",
    tmdb_id: "488",
    url: "https://trakt.tv/movies/the-african-queen-1952",
    released: "1952-02-20",
    runtime: "105",
    genres: "adventure,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-19T03:29:23Z": {
    type: "movie",
    title: "Casablanca",
    year: "1942",
    trakt_rating: "8.26425",
    trakt_id: "243",
    imdb_id: "tt0034583",
    tmdb_id: "289",
    url: "https://trakt.tv/movies/casablanca-1942",
    released: "1942-11-26",
    runtime: "102",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-19T03:25:22Z": {
    type: "movie",
    title: "History of the World: Part I",
    year: "1981",
    trakt_rating: "7.37039",
    trakt_id: "5372",
    imdb_id: "tt0082517",
    tmdb_id: "10156",
    url: "https://trakt.tv/movies/history-of-the-world-part-i-1981",
    released: "1981-06-12",
    runtime: "92",
    genres: "comedy,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-19T03:24:32Z": {
    type: "movie",
    title: "Nosferatu",
    year: "1922",
    trakt_rating: "7.5985",
    trakt_id: "528",
    imdb_id: "tt0013442",
    tmdb_id: "653",
    url: "https://trakt.tv/movies/nosferatu-1922",
    released: "1922-03-15",
    runtime: "94",
    genres: "fantasy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-19T03:20:51Z": {
    type: "movie",
    title: "Blazing Saddles",
    year: "1974",
    trakt_rating: "7.82054",
    trakt_id: "6209",
    imdb_id: "tt0071230",
    tmdb_id: "11072",
    url: "https://trakt.tv/movies/blazing-saddles-1974",
    released: "1974-02-07",
    runtime: "93",
    genres: "comedy,western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-19T03:18:21Z": {
    type: "movie",
    title: "The Five Venoms",
    year: "1978",
    trakt_rating: "7.48523",
    trakt_id: "7940",
    imdb_id: "tt0077559",
    tmdb_id: "13481",
    url: "https://trakt.tv/movies/the-five-venoms-1978",
    released: "1978-08-12",
    runtime: "102",
    genres: "action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-19T03:17:05Z": {
    type: "movie",
    title: "Chinatown",
    year: "1974",
    trakt_rating: "7.92382",
    trakt_id: "672",
    imdb_id: "tt0071315",
    tmdb_id: "829",
    url: "https://trakt.tv/movies/chinatown-1974",
    released: "1974-06-20",
    runtime: "130",
    genres: "crime,drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-19T03:16:38Z": {
    type: "movie",
    title: "One Flew Over the Cuckoo's Nest",
    year: "1975",
    trakt_rating: "8.46095",
    trakt_id: "402",
    imdb_id: "tt0073486",
    tmdb_id: "510",
    url: "https://trakt.tv/movies/one-flew-over-the-cuckoo-s-nest-1975",
    released: "1975-11-19",
    runtime: "133",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-19T03:16:19Z": {
    type: "movie",
    title: "The Maltese Falcon",
    year: "1941",
    trakt_rating: "7.76701",
    trakt_id: "799",
    imdb_id: "tt0033870",
    tmdb_id: "963",
    url: "https://trakt.tv/movies/the-maltese-falcon-1941",
    released: "1941-10-18",
    runtime: "100",
    genres: "crime,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-19T00:47:58Z": {
    type: "movie",
    title: "Batman v Superman: Dawn of Justice",
    year: "2016",
    trakt_rating: "6.64523",
    trakt_id: "129583",
    imdb_id: "tt2975590",
    tmdb_id: "209112",
    url: "https://trakt.tv/movies/batman-v-superman-dawn-of-justice-2016",
    released: "2016-03-25",
    runtime: "152",
    genres: "action,adventure,fantasy,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-18T04:11:57Z": {
    type: "movie",
    title: "Carrie",
    year: "1976",
    trakt_rating: "7.41035",
    trakt_id: "3889",
    imdb_id: "tt0074285",
    tmdb_id: "7340",
    url: "https://trakt.tv/movies/carrie-1976",
    released: "1976-11-03",
    runtime: "98",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-18T02:18:11Z": {
    type: "movie",
    title: "Do the Right Thing",
    year: "1989",
    trakt_rating: "7.89607",
    trakt_id: "767",
    imdb_id: "tt0097216",
    tmdb_id: "925",
    url: "https://trakt.tv/movies/do-the-right-thing-1989",
    released: "1989-06-30",
    runtime: "120",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-18T02:17:41Z": {
    type: "movie",
    title: "Malcolm X",
    year: "1992",
    trakt_rating: "7.78523",
    trakt_id: "1259",
    imdb_id: "tt0104797",
    tmdb_id: "1883",
    url: "https://trakt.tv/movies/malcolm-x-1992",
    released: "1992-11-18",
    runtime: "202",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-18T02:05:31Z": {
    type: "movie",
    title: "The Vault",
    year: "2017",
    trakt_rating: "6.17664",
    trakt_id: "260973",
    imdb_id: "tt1785635",
    tmdb_id: "416160",
    url: "https://trakt.tv/movies/the-vault-2017",
    released: "2017-09-01",
    runtime: "91",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-18T02:04:11Z": {
    type: "movie",
    title: "Phantom of the Paradise",
    year: "1974",
    trakt_rating: "7.61154",
    trakt_id: "16763",
    imdb_id: "tt0071994",
    tmdb_id: "27327",
    url: "https://trakt.tv/movies/phantom-of-the-paradise-1974",
    released: "1974-10-31",
    runtime: "92",
    genres: "comedy,fantasy,horror,thriller,music,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T23:58:43Z": {
    type: "movie",
    title: "Colossal",
    year: "2017",
    trakt_rating: "6.58322",
    trakt_id: "220426",
    imdb_id: "tt4680182",
    tmdb_id: "339967",
    url: "https://trakt.tv/movies/colossal-2017",
    released: "2017-04-07",
    runtime: "109",
    genres: "science-fiction,fantasy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T23:57:41Z": {
    type: "movie",
    title: "Creative Control",
    year: "2016",
    trakt_rating: "5.54438",
    trakt_id: "200801",
    imdb_id: "tt3277624",
    tmdb_id: "320146",
    url: "https://trakt.tv/movies/creative-control-2016",
    released: "2016-03-11",
    runtime: "97",
    genres: "drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T23:56:18Z": {
    type: "movie",
    title: "The Conspirator",
    year: "2010",
    trakt_rating: "6.91587",
    trakt_id: "43155",
    imdb_id: "tt0968264",
    tmdb_id: "60309",
    url: "https://trakt.tv/movies/the-conspirator-2010",
    released: "2011-04-15",
    runtime: "122",
    genres: "drama,history,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T03:10:47Z": {
    type: "movie",
    title: "Our Kind of Traitor",
    year: "2016",
    trakt_rating: "7.10597",
    trakt_id: "127825",
    imdb_id: "tt1995390",
    tmdb_id: "205588",
    url: "https://trakt.tv/movies/our-kind-of-traitor-2016",
    released: "2016-06-15",
    runtime: "108",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T03:01:55Z": {
    type: "movie",
    title: "Assassin's Creed",
    year: "2016",
    trakt_rating: "6.35966",
    trakt_id: "84393",
    imdb_id: "tt2094766",
    tmdb_id: "121856",
    url: "https://trakt.tv/movies/assassin-s-creed-2016",
    released: "2016-12-21",
    runtime: "116",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T02:59:18Z": {
    type: "movie",
    title: "Traders",
    year: "2016",
    trakt_rating: "7.16667",
    trakt_id: "222995",
    imdb_id: "tt3531578",
    tmdb_id: "347647",
    url: "https://trakt.tv/movies/traders-2016",
    released: "2016-03-11",
    runtime: "90",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T02:57:00Z": {
    type: "movie",
    title: "Misery",
    year: "1990",
    trakt_rating: "7.88278",
    trakt_id: "1137",
    imdb_id: "tt0100157",
    tmdb_id: "1700",
    url: "https://trakt.tv/movies/misery-1990",
    released: "1990-11-30",
    runtime: "107",
    genres: "thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T02:50:47Z": {
    type: "movie",
    title: "Margin Call",
    year: "2011",
    trakt_rating: "7.17313",
    trakt_id: "35595",
    imdb_id: "tt1615147",
    tmdb_id: "50839",
    url: "https://trakt.tv/movies/margin-call-2011",
    released: "2011-10-21",
    runtime: "107",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T02:25:19Z": {
    type: "movie",
    title: "Crimson Rivers II: Angels of the Apocalypse",
    year: "2004",
    trakt_rating: "6.38534",
    trakt_id: "43460",
    imdb_id: "tt0337103",
    tmdb_id: "60672",
    url: "https://trakt.tv/movies/crimson-rivers-ii-angels-of-the-apocalypse-2004",
    released: "2004-02-18",
    runtime: "97",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T02:24:36Z": {
    type: "movie",
    title: "Crimson Tide",
    year: "1995",
    trakt_rating: "7.64591",
    trakt_id: "4430",
    imdb_id: "tt0112740",
    tmdb_id: "8963",
    url: "https://trakt.tv/movies/crimson-tide-1995",
    released: "1995-05-12",
    runtime: "116",
    genres: "thriller,action,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T02:24:21Z": {
    type: "movie",
    title: "Out of Time",
    year: "2003",
    trakt_rating: "6.69721",
    trakt_id: "1464",
    imdb_id: "tt0313443",
    tmdb_id: "2116",
    url: "https://trakt.tv/movies/out-of-time-2003",
    released: "2003-10-03",
    runtime: "105",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T02:10:00Z": {
    type: "movie",
    title: "The Killer",
    year: "1989",
    trakt_rating: "7.83223",
    trakt_id: "5992",
    imdb_id: "tt0097202",
    tmdb_id: "10835",
    url: "https://trakt.tv/movies/the-killer-1989",
    released: "1989-07-06",
    runtime: "110",
    genres: "action,crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T02:05:59Z": {
    type: "movie",
    title: "Election",
    year: "1999",
    trakt_rating: "7.06671",
    trakt_id: "4738",
    imdb_id: "tt0126886",
    tmdb_id: "9451",
    url: "https://trakt.tv/movies/election-1999",
    released: "1999-04-23",
    runtime: "103",
    genres: "comedy,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T00:26:38Z": {
    type: "movie",
    title: "Schindler's List",
    year: "1993",
    trakt_rating: "8.83159",
    trakt_id: "336",
    imdb_id: "tt0108052",
    tmdb_id: "424",
    url: "https://trakt.tv/movies/schindler-s-list-1993",
    released: "1993-12-15",
    runtime: "195",
    genres: "drama,history,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T00:20:34Z": {
    type: "movie",
    title: "Repo Man",
    year: "1984",
    trakt_rating: "7.03428",
    trakt_id: "8215",
    imdb_id: "tt0087995",
    tmdb_id: "13820",
    url: "https://trakt.tv/movies/repo-man-1984",
    released: "1984-03-02",
    runtime: "92",
    genres: "comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T00:20:12Z": {
    type: "movie",
    title: "Eternal Sunshine of the Spotless Mind",
    year: "2004",
    trakt_rating: "8.2567",
    trakt_id: "29",
    imdb_id: "tt0338013",
    tmdb_id: "38",
    url: "https://trakt.tv/movies/eternal-sunshine-of-the-spotless-mind-2004",
    released: "2004-03-19",
    runtime: "108",
    genres: "drama,science-fiction,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-17T00:20:00Z": {
    type: "movie",
    title: "Being John Malkovich",
    year: "1999",
    trakt_rating: "7.64137",
    trakt_id: "386",
    imdb_id: "tt0120601",
    tmdb_id: "492",
    url: "https://trakt.tv/movies/being-john-malkovich-1999",
    released: "1999-10-29",
    runtime: "113",
    genres: "comedy,drama,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T08:23:03Z": {
    type: "movie",
    title: "Shallow Grave",
    year: "1994",
    trakt_rating: "7.37786",
    trakt_id: "5139",
    imdb_id: "tt0111149",
    tmdb_id: "9905",
    url: "https://trakt.tv/movies/shallow-grave-1994",
    released: "1995-01-06",
    runtime: "93",
    genres: "thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T08:20:26Z": {
    type: "movie",
    title: "Lock, Stock and Two Smoking Barrels",
    year: "1998",
    trakt_rating: "8.23589",
    trakt_id: "69",
    imdb_id: "tt0120735",
    tmdb_id: "100",
    url: "https://trakt.tv/movies/lock-stock-and-two-smoking-barrels-1998",
    released: "1999-03-05",
    runtime: "105",
    genres: "comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T08:19:23Z": {
    type: "movie",
    title: "Snatch",
    year: "2000",
    trakt_rating: "8.2995",
    trakt_id: "76",
    imdb_id: "tt0208092",
    tmdb_id: "107",
    url: "https://trakt.tv/movies/snatch-2000",
    released: "2001-01-19",
    runtime: "103",
    genres: "crime,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T08:14:38Z": {
    type: "movie",
    title: "Match Point",
    year: "2005",
    trakt_rating: "7.39777",
    trakt_id: "85",
    imdb_id: "tt0416320",
    tmdb_id: "116",
    url: "https://trakt.tv/movies/match-point-2005",
    released: "2005-11-02",
    runtime: "124",
    genres: "crime,drama,thriller,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T01:50:05Z": {
    type: "movie",
    title: "Skyfall",
    year: "2012",
    trakt_rating: "7.7639",
    trakt_id: "24221",
    imdb_id: "tt1074638",
    tmdb_id: "37724",
    url: "https://trakt.tv/movies/skyfall-2012",
    released: "2012-11-09",
    runtime: "143",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T01:48:06Z": {
    type: "movie",
    title: "The Lost Boys",
    year: "1987",
    trakt_rating: "7.61655",
    trakt_id: "1006",
    imdb_id: "tt0093437",
    tmdb_id: "1547",
    url: "https://trakt.tv/movies/the-lost-boys-1987",
    released: "1987-07-31",
    runtime: "97",
    genres: "comedy,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T01:45:57Z": {
    type: "movie",
    title: "Blade",
    year: "1998",
    trakt_rating: "7.34768",
    trakt_id: "23293",
    imdb_id: "tt0120611",
    tmdb_id: "36647",
    url: "https://trakt.tv/movies/blade-1998",
    released: "1998-08-21",
    runtime: "121",
    genres: "action,horror,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T01:38:34Z": {
    type: "movie",
    title: "Stand by Me",
    year: "1986",
    trakt_rating: "8.13681",
    trakt_id: "193",
    imdb_id: "tt0092005",
    tmdb_id: "235",
    url: "https://trakt.tv/movies/stand-by-me-1986",
    released: "1986-08-08",
    runtime: "89",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T01:37:46Z": {
    type: "movie",
    title: "A History of Violence",
    year: "2005",
    trakt_rating: "7.36666",
    trakt_id: "32",
    imdb_id: "tt0399146",
    tmdb_id: "59",
    url: "https://trakt.tv/movies/a-history-of-violence-2005",
    released: "2005-09-23",
    runtime: "96",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T01:37:24Z": {
    type: "movie",
    title: "Eastern Promises",
    year: "2007",
    trakt_rating: "7.56026",
    trakt_id: "1582",
    imdb_id: "tt0765443",
    tmdb_id: "2252",
    url: "https://trakt.tv/movies/eastern-promises-2007",
    released: "2007-09-14",
    runtime: "101",
    genres: "crime,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T01:27:00Z": {
    type: "movie",
    title: "2001: A Space Odyssey",
    year: "1968",
    trakt_rating: "8.03695",
    trakt_id: "33",
    imdb_id: "tt0062622",
    tmdb_id: "62",
    url: "https://trakt.tv/movies/2001-a-space-odyssey-1968",
    released: "1968-04-10",
    runtime: "149",
    genres: "adventure,mystery,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T01:25:38Z": {
    type: "movie",
    title: "The Cell",
    year: "2000",
    trakt_rating: "6.59768",
    trakt_id: "4334",
    imdb_id: "tt0209958",
    tmdb_id: "8843",
    url: "https://trakt.tv/movies/the-cell-2000",
    released: "2000-08-17",
    runtime: "107",
    genres: "science-fiction,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T01:20:11Z": {
    type: "movie",
    title: "The Social Network",
    year: "2010",
    trakt_rating: "7.58078",
    trakt_id: "24279",
    imdb_id: "tt1285016",
    tmdb_id: "37799",
    url: "https://trakt.tv/movies/the-social-network-2010",
    released: "2010-10-01",
    runtime: "121",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T01:15:56Z": {
    type: "movie",
    title: "The Crow",
    year: "1994",
    trakt_rating: "7.78914",
    trakt_id: "4778",
    imdb_id: "tt0109506",
    tmdb_id: "9495",
    url: "https://trakt.tv/movies/the-crow-1994",
    released: "1994-05-11",
    runtime: "102",
    genres: "action,fantasy,thriller,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T01:02:46Z": {
    type: "movie",
    title: "Face/Off",
    year: "1997",
    trakt_rating: "7.35234",
    trakt_id: "597",
    imdb_id: "tt0119094",
    tmdb_id: "754",
    url: "https://trakt.tv/movies/face-off-1997",
    released: "1997-06-27",
    runtime: "138",
    genres: "action,crime,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-16T00:55:44Z": {
    type: "movie",
    title: "Drugstore Cowboy",
    year: "1989",
    trakt_rating: "7.42173",
    trakt_id: "376",
    imdb_id: "tt0097240",
    tmdb_id: "476",
    url: "https://trakt.tv/movies/drugstore-cowboy-1989",
    released: "1989-10-20",
    runtime: "98",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-15T12:24:30Z": {
    type: "movie",
    title: "Criminal",
    year: "2016",
    trakt_rating: "7.16991",
    trakt_id: "204299",
    imdb_id: "tt3014866",
    tmdb_id: "302156",
    url: "https://trakt.tv/movies/criminal-2016",
    released: "2016-04-15",
    runtime: "113",
    genres: "action,crime,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-15T01:49:42Z": {
    type: "movie",
    title: "Animal Farm",
    year: "1954",
    trakt_rating: "7.25968",
    trakt_id: "6871",
    imdb_id: "tt0047834",
    tmdb_id: "11848",
    url: "https://trakt.tv/movies/animal-farm-1954",
    released: "1954-12-29",
    runtime: "72",
    genres: "animation,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-15T01:49:16Z": {
    type: "movie",
    title: "Heavy Metal",
    year: "1981",
    trakt_rating: "7.14042",
    trakt_id: "6851",
    imdb_id: "tt0082509",
    tmdb_id: "11827",
    url: "https://trakt.tv/movies/heavy-metal-1981",
    released: "1981-08-07",
    runtime: "90",
    genres: "animation,science-fiction,adventure,fantasy,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-14T13:18:05Z": {
    type: "movie",
    title: "Dredd",
    year: "2012",
    trakt_rating: "7.24408",
    trakt_id: "34095",
    imdb_id: "tt1343727",
    tmdb_id: "49049",
    url: "https://trakt.tv/movies/dredd-2012",
    released: "2012-09-21",
    runtime: "95",
    genres: "action,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-14T13:17:08Z": {
    type: "movie",
    title: "Audition",
    year: "1999",
    trakt_rating: "7.14693",
    trakt_id: "6212",
    imdb_id: "tt0235198",
    tmdb_id: "11075",
    url: "https://trakt.tv/movies/audition-1999",
    released: "2000-03-03",
    runtime: "115",
    genres: "drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-14T01:52:08Z": {
    type: "movie",
    title: "City of God",
    year: "2002",
    trakt_rating: "8.58864",
    trakt_id: "476",
    imdb_id: "tt0317248",
    tmdb_id: "598",
    url: "https://trakt.tv/movies/city-of-god-2002",
    released: "2002-08-30",
    runtime: "130",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-14T01:45:02Z": {
    type: "movie",
    title: "Amores Perros",
    year: "2000",
    trakt_rating: "7.89893",
    trakt_id: "30",
    imdb_id: "tt0245712",
    tmdb_id: "55",
    url: "https://trakt.tv/movies/amores-perros-2000",
    released: "2000-06-16",
    runtime: "154",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-14T01:37:18Z": {
    type: "movie",
    title: "RoboCop",
    year: "1987",
    trakt_rating: "7.62711",
    trakt_id: "3138",
    imdb_id: "tt0093870",
    tmdb_id: "5548",
    url: "https://trakt.tv/movies/robocop-1987",
    released: "1987-07-17",
    runtime: "102",
    genres: "action,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-14T01:36:54Z": {
    type: "movie",
    title: "Down by Law",
    year: "1986",
    trakt_rating: "7.64242",
    trakt_id: "1013",
    imdb_id: "tt0090967",
    tmdb_id: "1554",
    url: "https://trakt.tv/movies/down-by-law-1986",
    released: "1986-09-20",
    runtime: "106",
    genres: "comedy,drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-14T01:36:06Z": {
    type: "movie",
    title: "Dead Man",
    year: "1995",
    trakt_rating: "7.3354",
    trakt_id: "764",
    imdb_id: "tt0112817",
    tmdb_id: "922",
    url: "https://trakt.tv/movies/dead-man-1995",
    released: "1996-05-10",
    runtime: "121",
    genres: "drama,fantasy,western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-14T01:15:28Z": {
    type: "movie",
    title: "Baby Driver",
    year: "2017",
    trakt_rating: "7.66815",
    trakt_id: "219867",
    imdb_id: "tt3890160",
    tmdb_id: "339403",
    url: "https://trakt.tv/movies/baby-driver-2017",
    released: "2017-06-28",
    runtime: "113",
    genres: "action,crime,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T18:18:31Z": {
    type: "movie",
    title: "The Gracefield Incident",
    year: "2017",
    trakt_rating: "5.96207",
    trakt_id: "208156",
    imdb_id: "tt4464394",
    tmdb_id: "327253",
    url: "https://trakt.tv/movies/the-gracefield-incident-2017",
    released: "2017-07-21",
    runtime: "95",
    genres: "action,horror,science-fiction,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T18:18:14Z": {
    type: "movie",
    title: "The Goonies",
    year: "1985",
    trakt_rating: "8.08052",
    trakt_id: "4633",
    imdb_id: "tt0089218",
    tmdb_id: "9340",
    url: "https://trakt.tv/movies/the-goonies-1985",
    released: "1985-06-07",
    runtime: "114",
    genres: "adventure,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T18:13:57Z": {
    type: "movie",
    title: "Valhalla Rising",
    year: "2009",
    trakt_rating: "6.03079",
    trakt_id: "24332",
    imdb_id: "tt0862467",
    tmdb_id: "37861",
    url: "https://trakt.tv/movies/valhalla-rising-2009",
    released: "2010-03-31",
    runtime: "93",
    genres: "action,adventure,drama,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T18:13:46Z": {
    type: "movie",
    title: "Erik the Viking",
    year: "1989",
    trakt_rating: "6.61468",
    trakt_id: "6852",
    imdb_id: "tt0097289",
    tmdb_id: "11828",
    url: "https://trakt.tv/movies/erik-the-viking-1989",
    released: "1989-09-01",
    runtime: "107",
    genres: "adventure,comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T05:07:50Z": {
    type: "movie",
    title: "A Nightmare on Elm Street",
    year: "1984",
    trakt_rating: "7.50752",
    trakt_id: "301",
    imdb_id: "tt0087800",
    tmdb_id: "377",
    url: "https://trakt.tv/movies/a-nightmare-on-elm-street-1984",
    released: "1984-11-09",
    runtime: "91",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T05:03:05Z": {
    type: "movie",
    title: "Groundhog Day",
    year: "1993",
    trakt_rating: "8.04367",
    trakt_id: "103",
    imdb_id: "tt0107048",
    tmdb_id: "137",
    url: "https://trakt.tv/movies/groundhog-day-1993",
    released: "1993-02-11",
    runtime: "101",
    genres: "comedy,drama,fantasy,romance,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T05:00:51Z": {
    type: "movie",
    title: "Transformers: Age of Extinction",
    year: "2014",
    trakt_rating: "6.36809",
    trakt_id: "67751",
    imdb_id: "tt2109248",
    tmdb_id: "91314",
    url: "https://trakt.tv/movies/transformers-age-of-extinction-2014",
    released: "2014-06-27",
    runtime: "165",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T05:00:41Z": {
    type: "movie",
    title: "Transformers: Revenge of the Fallen",
    year: "2009",
    trakt_rating: "6.58184",
    trakt_id: "4158",
    imdb_id: "tt1055369",
    tmdb_id: "8373",
    url: "https://trakt.tv/movies/transformers-revenge-of-the-fallen-2009",
    released: "2009-06-23",
    runtime: "149",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T02:42:30Z": {
    type: "movie",
    title: "A Christmas Story",
    year: "1983",
    trakt_rating: "7.95075",
    trakt_id: "693",
    imdb_id: "tt0085334",
    tmdb_id: "850",
    url: "https://trakt.tv/movies/a-christmas-story-1983",
    released: "1983-11-18",
    runtime: "93",
    genres: "comedy,family,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T02:42:19Z": {
    type: "movie",
    title: "Scrooged",
    year: "1988",
    trakt_rating: "7.43347",
    trakt_id: "4923",
    imdb_id: "tt0096061",
    tmdb_id: "9647",
    url: "https://trakt.tv/movies/scrooged-1988",
    released: "1988-11-22",
    runtime: "101",
    genres: "comedy,drama,fantasy,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T02:40:00Z": {
    type: "movie",
    title: "Kids",
    year: "1995",
    trakt_rating: "7.07673",
    trakt_id: "4637",
    imdb_id: "tt0113540",
    tmdb_id: "9344",
    url: "https://trakt.tv/movies/kids-1995",
    released: "1995-07-28",
    runtime: "91",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T02:37:27Z": {
    type: "movie",
    title: "The Player",
    year: "1992",
    trakt_rating: "7.3411",
    trakt_id: "5597",
    imdb_id: "tt0105151",
    tmdb_id: "10403",
    url: "https://trakt.tv/movies/the-player-1992",
    released: "1992-04-03",
    runtime: "124",
    genres: "comedy,crime,mystery,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T02:35:43Z": {
    type: "movie",
    title: "Starship Troopers",
    year: "1997",
    trakt_rating: "7.52395",
    trakt_id: "444",
    imdb_id: "tt0120201",
    tmdb_id: "563",
    url: "https://trakt.tv/movies/starship-troopers-1997",
    released: "1997-11-07",
    runtime: "129",
    genres: "action,adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T02:34:26Z": {
    type: "movie",
    title: "Unforgiven",
    year: "1992",
    trakt_rating: "8.07252",
    trakt_id: "27",
    imdb_id: "tt0105695",
    tmdb_id: "33",
    url: "https://trakt.tv/movies/unforgiven-1992",
    released: "1992-08-07",
    runtime: "130",
    genres: "western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T02:31:02Z": {
    type: "movie",
    title: "JFK",
    year: "1991",
    trakt_rating: "7.76596",
    trakt_id: "663",
    imdb_id: "tt0102138",
    tmdb_id: "820",
    url: "https://trakt.tv/movies/jfk-1991",
    released: "1991-12-20",
    runtime: "189",
    genres: "drama,history,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T02:11:28Z": {
    type: "movie",
    title: "Baise-moi",
    year: "2000",
    trakt_rating: "5.02249",
    trakt_id: "2103",
    imdb_id: "tt0249380",
    tmdb_id: "3134",
    url: "https://trakt.tv/movies/baise-moi-2000",
    released: "2000-06-28",
    runtime: "77",
    genres: "drama,thriller,romance,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T02:08:46Z": {
    type: "movie",
    title: "Bad Lieutenant",
    year: "1992",
    trakt_rating: "6.86028",
    trakt_id: "7059",
    imdb_id: "tt0103759",
    tmdb_id: "12143",
    url: "https://trakt.tv/movies/bad-lieutenant-1992",
    released: "1992-11-20",
    runtime: "96",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T02:01:01Z": {
    type: "movie",
    title: "Casino",
    year: "1995",
    trakt_rating: "8.17049",
    trakt_id: "409",
    imdb_id: "tt0112641",
    tmdb_id: "524",
    url: "https://trakt.tv/movies/casino-1995",
    released: "1995-11-22",
    runtime: "179",
    genres: "crime,drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T01:59:42Z": {
    type: "movie",
    title: "Almost Famous",
    year: "2000",
    trakt_rating: "7.90092",
    trakt_id: "629",
    imdb_id: "tt0181875",
    tmdb_id: "786",
    url: "https://trakt.tv/movies/almost-famous-2000",
    released: "2000-09-15",
    runtime: "124",
    genres: "drama,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T01:58:39Z": {
    type: "movie",
    title: "The Usual Suspects",
    year: "1995",
    trakt_rating: "8.47549",
    trakt_id: "505",
    imdb_id: "tt0114814",
    tmdb_id: "629",
    url: "https://trakt.tv/movies/the-usual-suspects-1995",
    released: "1995-08-16",
    runtime: "106",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T01:44:26Z": {
    type: "movie",
    title: "French Connection II",
    year: "1975",
    trakt_rating: "6.91909",
    trakt_id: "5880",
    imdb_id: "tt0073018",
    tmdb_id: "10711",
    url: "https://trakt.tv/movies/french-connection-ii-1975",
    released: "1975-05-18",
    runtime: "119",
    genres: "action,crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T01:44:25Z": {
    type: "movie",
    title: "The French Connection",
    year: "1971",
    trakt_rating: "7.51563",
    trakt_id: "834",
    imdb_id: "tt0067116",
    tmdb_id: "1051",
    url: "https://trakt.tv/movies/the-french-connection-1971",
    released: "1971-10-09",
    runtime: "104",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T01:38:06Z": {
    type: "movie",
    title: "Jurassic Park",
    year: "1993",
    trakt_rating: "8.27048",
    trakt_id: "276",
    imdb_id: "tt0107290",
    tmdb_id: "329",
    url: "https://trakt.tv/movies/jurassic-park-1993",
    released: "1993-06-11",
    runtime: "127",
    genres: "adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T01:35:50Z": {
    type: "movie",
    title: "Platoon",
    year: "1986",
    trakt_rating: "8.01173",
    trakt_id: "635",
    imdb_id: "tt0091763",
    tmdb_id: "792",
    url: "https://trakt.tv/movies/platoon-1986",
    released: "1986-12-19",
    runtime: "120",
    genres: "action,drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T01:33:43Z": {
    type: "movie",
    title: "The Shawshank Redemption",
    year: "1994",
    trakt_rating: "9.14133",
    trakt_id: "234",
    imdb_id: "tt0111161",
    tmdb_id: "278",
    url: "https://trakt.tv/movies/the-shawshank-redemption-1994",
    released: "1994-09-23",
    runtime: "142",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T01:29:51Z": {
    type: "movie",
    title: "Harold and Maude",
    year: "1971",
    trakt_rating: "7.77328",
    trakt_id: "290",
    imdb_id: "tt0067185",
    tmdb_id: "343",
    url: "https://trakt.tv/movies/harold-and-maude-1971",
    released: "1971-12-20",
    runtime: "91",
    genres: "comedy,drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T01:23:40Z": {
    type: "movie",
    title: "Mean Streets",
    year: "1973",
    trakt_rating: "7.05792",
    trakt_id: "165",
    imdb_id: "tt0070379",
    tmdb_id: "203",
    url: "https://trakt.tv/movies/mean-streets-1973",
    released: "1973-10-14",
    runtime: "111",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T01:22:11Z": {
    type: "movie",
    title: "American Beauty",
    year: "1999",
    trakt_rating: "8.11161",
    trakt_id: "10",
    imdb_id: "tt0169547",
    tmdb_id: "14",
    url: "https://trakt.tv/movies/american-beauty-1999",
    released: "1999-09-15",
    runtime: "122",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T01:21:00Z": {
    type: "movie",
    title: "The Lives of Others",
    year: "2006",
    trakt_rating: "8.24545",
    trakt_id: "461",
    imdb_id: "tt0405094",
    tmdb_id: "582",
    url: "https://trakt.tv/movies/the-lives-of-others-2006",
    released: "2007-01-31",
    runtime: "137",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T01:20:44Z": {
    type: "movie",
    title: "La Haine",
    year: "1995",
    trakt_rating: "8.18698",
    trakt_id: "324",
    imdb_id: "tt0113247",
    tmdb_id: "406",
    url: "https://trakt.tv/movies/la-haine-1995",
    released: "1995-05-31",
    runtime: "98",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T00:55:20Z": {
    type: "movie",
    title: "Road to Perdition",
    year: "2002",
    trakt_rating: "7.60372",
    trakt_id: "2439",
    imdb_id: "tt0257044",
    tmdb_id: "4147",
    url: "https://trakt.tv/movies/road-to-perdition-2002",
    released: "2002-07-12",
    runtime: "117",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T00:46:41Z": {
    type: "movie",
    title: "Despicable Me 3",
    year: "2017",
    trakt_rating: "7.11405",
    trakt_id: "205401",
    imdb_id: "tt3469046",
    tmdb_id: "324852",
    url: "https://trakt.tv/movies/despicable-me-3-2017",
    released: "2017-06-30",
    runtime: "96",
    genres: "animation,comedy,action,family,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T00:45:24Z": {
    type: "movie",
    title: "Transformers: The Last Knight",
    year: "2017",
    trakt_rating: "6.64851",
    trakt_id: "216718",
    imdb_id: "tt3371366",
    tmdb_id: "335988",
    url: "https://trakt.tv/movies/transformers-the-last-knight-2017",
    released: "2017-06-21",
    runtime: "154",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-13T00:36:33Z": {
    type: "movie",
    title: "Drag Me to Hell",
    year: "2009",
    trakt_rating: "6.5883",
    trakt_id: "10323",
    imdb_id: "tt1127180",
    tmdb_id: "16871",
    url: "https://trakt.tv/movies/drag-me-to-hell-2009",
    released: "2009-03-15",
    runtime: "99",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-12T03:23:05Z": {
    type: "movie",
    title: "47 Meters Down",
    year: "2017",
    trakt_rating: "6.4199",
    trakt_id: "249662",
    imdb_id: "tt2932536",
    tmdb_id: "403119",
    url: "https://trakt.tv/movies/47-meters-down-2017",
    released: "2017-06-16",
    runtime: "89",
    genres: "thriller,horror,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-11T16:50:02Z": {
    type: "movie",
    title: "The Raid 2",
    year: "2014",
    trakt_rating: "7.8115",
    trakt_id: "113656",
    imdb_id: "tt2265171",
    tmdb_id: "180299",
    url: "https://trakt.tv/movies/the-raid-2-2014",
    released: "2014-03-28",
    runtime: "150",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-11T16:45:10Z": {
    type: "movie",
    title: "The Raid",
    year: "2012",
    trakt_rating: "7.67766",
    trakt_id: "69197",
    imdb_id: "tt1899353",
    tmdb_id: "94329",
    url: "https://trakt.tv/movies/the-raid-2012",
    released: "2012-04-13",
    runtime: "101",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-11T15:52:54Z": {
    type: "movie",
    title: "John Wick: Chapter 2",
    year: "2017",
    trakt_rating: "7.70937",
    trakt_id: "205127",
    imdb_id: "tt4425200",
    tmdb_id: "324552",
    url: "https://trakt.tv/movies/john-wick-chapter-2-2017",
    released: "2017-02-10",
    runtime: "122",
    genres: "action,thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-11T15:50:38Z": {
    type: "movie",
    title: "Trainspotting",
    year: "1996",
    trakt_rating: "8.13463",
    trakt_id: "503",
    imdb_id: "tt0117951",
    tmdb_id: "627",
    url: "https://trakt.tv/movies/trainspotting-1996",
    released: "1996-02-23",
    runtime: "94",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-11T01:16:26Z": {
    type: "movie",
    title: "30 Days of Night",
    year: "2007",
    trakt_rating: "6.77256",
    trakt_id: "2648",
    imdb_id: "tt0389722",
    tmdb_id: "4513",
    url: "https://trakt.tv/movies/30-days-of-night-2007",
    released: "2007-10-19",
    runtime: "113",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-11T01:13:41Z": {
    type: "movie",
    title: "Life of Brian",
    year: "1979",
    trakt_rating: "8.1624",
    trakt_id: "462",
    imdb_id: "tt0079470",
    tmdb_id: "583",
    url: "https://trakt.tv/movies/life-of-brian-1979",
    released: "1979-11-08",
    runtime: "94",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-11T01:10:29Z": {
    type: "movie",
    title: "Back to the Future Part III",
    year: "1990",
    trakt_rating: "7.87914",
    trakt_id: "159",
    imdb_id: "tt0099088",
    tmdb_id: "196",
    url: "https://trakt.tv/movies/back-to-the-future-part-iii-1990",
    released: "1990-05-25",
    runtime: "119",
    genres: "adventure,comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-11T01:10:27Z": {
    type: "movie",
    title: "Back to the Future",
    year: "1985",
    trakt_rating: "8.61011",
    trakt_id: "74",
    imdb_id: "tt0088763",
    tmdb_id: "105",
    url: "https://trakt.tv/movies/back-to-the-future-1985",
    released: "1985-07-03",
    runtime: "116",
    genres: "adventure,comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-11T01:10:26Z": {
    type: "movie",
    title: "Back to the Future Part II",
    year: "1989",
    trakt_rating: "8.20723",
    trakt_id: "130",
    imdb_id: "tt0096874",
    tmdb_id: "165",
    url: "https://trakt.tv/movies/back-to-the-future-part-ii-1989",
    released: "1989-11-22",
    runtime: "108",
    genres: "adventure,comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-09T23:07:02Z": {
    type: "movie",
    title: "Enter the Dragon",
    year: "1973",
    trakt_rating: "7.81679",
    trakt_id: "4748",
    imdb_id: "tt0070034",
    tmdb_id: "9461",
    url: "https://trakt.tv/movies/enter-the-dragon-1973",
    released: "1973-08-17",
    runtime: "102",
    genres: "action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-08T02:26:52Z": {
    type: "movie",
    title: "Hellbound: Hellraiser II",
    year: "1988",
    trakt_rating: "6.72564",
    trakt_id: "4514",
    imdb_id: "tt0095294",
    tmdb_id: "9064",
    url: "https://trakt.tv/movies/hellbound-hellraiser-ii-1988",
    released: "1988-12-23",
    runtime: "97",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-07T02:51:52Z": {
    type: "movie",
    title: "Natural Born Killers",
    year: "1994",
    trakt_rating: "7.41505",
    trakt_id: "199",
    imdb_id: "tt0110632",
    tmdb_id: "241",
    url: "https://trakt.tv/movies/natural-born-killers-1994",
    released: "1994-08-25",
    runtime: "118",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-07T02:49:26Z": {
    type: "movie",
    title: "True Romance",
    year: "1993",
    trakt_rating: "7.91445",
    trakt_id: "270",
    imdb_id: "tt0108399",
    tmdb_id: "319",
    url: "https://trakt.tv/movies/true-romance-1993",
    released: "1993-09-09",
    runtime: "120",
    genres: "action,crime,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-07T02:47:07Z": {
    type: "movie",
    title: "The Thing",
    year: "1982",
    trakt_rating: "8.15049",
    trakt_id: "843",
    imdb_id: "tt0084787",
    tmdb_id: "1091",
    url: "https://trakt.tv/movies/the-thing-1982",
    released: "1982-06-25",
    runtime: "109",
    genres: "horror,mystery,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-07T02:46:50Z": {
    type: "movie",
    title: "The Thing",
    year: "2011",
    trakt_rating: "6.67303",
    trakt_id: "43676",
    imdb_id: "tt0905372",
    tmdb_id: "60935",
    url: "https://trakt.tv/movies/the-thing-2011",
    released: "2011-10-14",
    runtime: "103",
    genres: "horror,mystery,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-05T03:41:19Z": {
    type: "movie",
    title: "Magnolia",
    year: "1999",
    trakt_rating: "7.83884",
    trakt_id: "281",
    imdb_id: "tt0175880",
    tmdb_id: "334",
    url: "https://trakt.tv/movies/magnolia-1999",
    released: "1999-12-17",
    runtime: "189",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-05T03:40:46Z": {
    type: "movie",
    title: "Boogie Nights",
    year: "1997",
    trakt_rating: "7.73076",
    trakt_id: "2925",
    imdb_id: "tt0118749",
    tmdb_id: "4995",
    url: "https://trakt.tv/movies/boogie-nights-1997",
    released: "1997-10-10",
    runtime: "156",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-05T03:40:42Z": {
    type: "movie",
    title: "Adaptation.",
    year: "2002",
    trakt_rating: "7.53419",
    trakt_id: "1841",
    imdb_id: "tt0268126",
    tmdb_id: "2757",
    url: "https://trakt.tv/movies/adaptation-2002",
    released: "2002-12-06",
    runtime: "115",
    genres: "comedy,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-03T15:39:22Z": {
    type: "movie",
    title: "Spring",
    year: "2014",
    trakt_rating: "6.85953",
    trakt_id: "147370",
    imdb_id: "tt3395184",
    tmdb_id: "241855",
    url: "https://trakt.tv/movies/spring-2014",
    released: "2015-03-20",
    runtime: "109",
    genres: "horror,science-fiction,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-03T06:23:28Z": {
    type: "movie",
    title: "10 Rillington Place",
    year: "1971",
    trakt_rating: "7.37245",
    trakt_id: "15922",
    imdb_id: "tt0066730",
    tmdb_id: "26234",
    url: "https://trakt.tv/movies/10-rillington-place-1971",
    released: "1971-02-10",
    runtime: "106",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-03T02:59:00Z": {
    type: "movie",
    title: "Hard Boiled",
    year: "1992",
    trakt_rating: "7.71531",
    trakt_id: "6812",
    imdb_id: "tt0104684",
    tmdb_id: "11782",
    url: "https://trakt.tv/movies/hard-boiled-1992",
    released: "1992-04-16",
    runtime: "126",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-03T02:55:10Z": {
    type: "movie",
    title: "Birdman or (The Unexpected Virtue of Ignorance)",
    year: "2014",
    trakt_rating: "7.356",
    trakt_id: "121489",
    imdb_id: "tt2562232",
    tmdb_id: "194662",
    url: "https://trakt.tv/movies/birdman-or-the-unexpected-virtue-of-ignorance-2014",
    released: "2014-10-17",
    runtime: "120",
    genres: "comedy,drama,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-02T03:25:56Z": {
    type: "movie",
    title: "Winter's Bone",
    year: "2010",
    trakt_rating: "6.97679",
    trakt_id: "25290",
    imdb_id: "tt1399683",
    tmdb_id: "39013",
    url: "https://trakt.tv/movies/winter-s-bone-2010",
    released: "2010-06-11",
    runtime: "101",
    genres: "drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-01T01:28:27Z": {
    type: "movie",
    title: "King of New York",
    year: "1990",
    trakt_rating: "7.18363",
    trakt_id: "4836",
    imdb_id: "tt0099939",
    tmdb_id: "9558",
    url: "https://trakt.tv/movies/king-of-new-york-1990",
    released: "1990-07-18",
    runtime: "103",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-01T01:24:36Z": {
    type: "movie",
    title: "Rememory",
    year: "2017",
    trakt_rating: "6.9362",
    trakt_id: "276412",
    imdb_id: "tt2331047",
    tmdb_id: "395814",
    url: "https://trakt.tv/movies/rememory-2017",
    released: "2017-09-08",
    runtime: "111",
    genres: "science-fiction,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-01T01:21:38Z": {
    type: "movie",
    title: "Withnail & I",
    year: "1987",
    trakt_rating: "7.60709",
    trakt_id: "7915",
    imdb_id: "tt0094336",
    tmdb_id: "13446",
    url: "https://trakt.tv/movies/withnail-i-1987",
    released: "1987-06-19",
    runtime: "108",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-01T00:46:43Z": {
    type: "movie",
    title: "Wonder Woman",
    year: "2017",
    trakt_rating: "7.60992",
    trakt_id: "192487",
    imdb_id: "tt0451279",
    tmdb_id: "297762",
    url: "https://trakt.tv/movies/wonder-woman-2017",
    released: "2017-06-02",
    runtime: "141",
    genres: "adventure,superhero,fantasy,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-09-01T00:46:26Z": {
    type: "movie",
    title: "It Comes at Night",
    year: "2017",
    trakt_rating: "6.01351",
    trakt_id: "262061",
    imdb_id: "tt4695012",
    tmdb_id: "418078",
    url: "https://trakt.tv/movies/it-comes-at-night-2017",
    released: "2017-06-09",
    runtime: "91",
    genres: "mystery,drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-31T20:00:31Z": {
    type: "movie",
    title: "Goldfinger",
    year: "1964",
    trakt_rating: "7.64894",
    trakt_id: "532",
    imdb_id: "tt0058150",
    tmdb_id: "658",
    url: "https://trakt.tv/movies/goldfinger-1964",
    released: "1964-09-20",
    runtime: "110",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-31T20:00:09Z": {
    type: "movie",
    title: "Taxi Driver",
    year: "1976",
    trakt_rating: "8.00945",
    trakt_id: "72",
    imdb_id: "tt0075314",
    tmdb_id: "103",
    url: "https://trakt.tv/movies/taxi-driver-1976",
    released: "1976-02-09",
    runtime: "114",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-31T19:59:52Z": {
    type: "movie",
    title: "Deep",
    year: "2017",
    trakt_rating: "6.24058",
    trakt_id: "276438",
    imdb_id: "tt4105584",
    tmdb_id: "430474",
    url: "https://trakt.tv/movies/deep-2017",
    released: "2017-11-25",
    runtime: "92",
    genres: "animation,family,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-31T19:59:33Z": {
    type: "movie",
    title: "The Hitman's Bodyguard",
    year: "2017",
    trakt_rating: "7.4797",
    trakt_id: "232291",
    imdb_id: "tt1959563",
    tmdb_id: "390043",
    url: "https://trakt.tv/movies/the-hitman-s-bodyguard-2017",
    released: "2017-08-18",
    runtime: "118",
    genres: "comedy,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-27T16:25:31Z": {
    type: "movie",
    title: "Papillon",
    year: "1973",
    trakt_rating: "7.92257",
    trakt_id: "3381",
    imdb_id: "tt0070511",
    tmdb_id: "5924",
    url: "https://trakt.tv/movies/papillon-1973",
    released: "1973-12-16",
    runtime: "151",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-26T23:39:40Z": {
    type: "movie",
    title: "Barton Fink",
    year: "1991",
    trakt_rating: "7.55942",
    trakt_id: "244",
    imdb_id: "tt0101410",
    tmdb_id: "290",
    url: "https://trakt.tv/movies/barton-fink-1991",
    released: "1991-08-01",
    runtime: "117",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-26T20:45:54Z": {
    type: "movie",
    title: "Conan the Barbarian",
    year: "1982",
    trakt_rating: "7.13313",
    trakt_id: "4676",
    imdb_id: "tt0082198",
    tmdb_id: "9387",
    url: "https://trakt.tv/movies/conan-the-barbarian-1982",
    released: "1982-05-14",
    runtime: "129",
    genres: "action,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-26T12:27:18Z": {
    type: "movie",
    title: "X-Men Origins: Wolverine",
    year: "2009",
    trakt_rating: "6.75694",
    trakt_id: "1430",
    imdb_id: "tt0458525",
    tmdb_id: "2080",
    url: "https://trakt.tv/movies/x-men-origins-wolverine-2009",
    released: "2009-04-30",
    runtime: "107",
    genres: "action,adventure,science-fiction,thriller,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-26T04:37:23Z": {
    type: "movie",
    title: "X-Men",
    year: "2000",
    trakt_rating: "7.52599",
    trakt_id: "23299",
    imdb_id: "tt0120903",
    tmdb_id: "36657",
    url: "https://trakt.tv/movies/x-men-2000",
    released: "2000-07-14",
    runtime: "104",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-26T04:32:49Z": {
    type: "movie",
    title: "The Good, the Bad and the Ugly",
    year: "1966",
    trakt_rating: "8.54771",
    trakt_id: "341",
    imdb_id: "tt0060196",
    tmdb_id: "429",
    url: "https://trakt.tv/movies/the-good-the-bad-and-the-ugly-1966",
    released: "1967-12-29",
    runtime: "161",
    genres: "western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-26T04:32:11Z": {
    type: "movie",
    title: "High Plains Drifter",
    year: "1973",
    trakt_rating: "7.46295",
    trakt_id: "6919",
    imdb_id: "tt0068699",
    tmdb_id: "11901",
    url: "https://trakt.tv/movies/high-plains-drifter-1973",
    released: "1973-04-19",
    runtime: "105",
    genres: "western,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-26T04:27:22Z": {
    type: "movie",
    title: "The Departed",
    year: "2006",
    trakt_rating: "8.36033",
    trakt_id: "952",
    imdb_id: "tt0407887",
    tmdb_id: "1422",
    url: "https://trakt.tv/movies/the-departed-2006",
    released: "2006-10-05",
    runtime: "151",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-26T04:24:03Z": {
    type: "movie",
    title: "The Untouchables",
    year: "1987",
    trakt_rating: "7.88731",
    trakt_id: "86",
    imdb_id: "tt0094226",
    tmdb_id: "117",
    url: "https://trakt.tv/movies/the-untouchables-1987",
    released: "1987-06-03",
    runtime: "119",
    genres: "crime,drama,history,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-26T02:20:58Z": {
    type: "movie",
    title: "Up",
    year: "2009",
    trakt_rating: "8.26494",
    trakt_id: "8476",
    imdb_id: "tt1049413",
    tmdb_id: "14160",
    url: "https://trakt.tv/movies/up-2009",
    released: "2009-05-29",
    runtime: "96",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-26T01:40:00Z": {
    type: "movie",
    title: "A Dark Song",
    year: "2016",
    trakt_rating: "6.51982",
    trakt_id: "255901",
    imdb_id: "tt4805316",
    tmdb_id: "409297",
    url: "https://trakt.tv/movies/a-dark-song-2016",
    released: "2017-04-07",
    runtime: "100",
    genres: "horror,fantasy,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-26T01:38:33Z": {
    type: "movie",
    title: "They Live",
    year: "1988",
    trakt_rating: "7.43988",
    trakt_id: "4131",
    imdb_id: "tt0096256",
    tmdb_id: "8337",
    url: "https://trakt.tv/movies/they-live-1988",
    released: "1988-11-23",
    runtime: "94",
    genres: "science-fiction,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-25T01:19:56Z": {
    type: "movie",
    title: "Closed Circuit",
    year: "2013",
    trakt_rating: "6.60094",
    trakt_id: "96792",
    imdb_id: "tt2218003",
    tmdb_id: "146223",
    url: "https://trakt.tv/movies/closed-circuit-2013",
    released: "2013-08-28",
    runtime: "96",
    genres: "crime,drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-23T21:38:04Z": {
    type: "movie",
    title: "Terminator 3: Rise of the Machines",
    year: "2003",
    trakt_rating: "6.60361",
    trakt_id: "249",
    imdb_id: "tt0181852",
    tmdb_id: "296",
    url: "https://trakt.tv/movies/terminator-3-rise-of-the-machines-2003",
    released: "2003-07-02",
    runtime: "109",
    genres: "action,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-23T21:37:55Z": {
    type: "movie",
    title: "Terminator Salvation",
    year: "2009",
    trakt_rating: "6.62763",
    trakt_id: "417",
    imdb_id: "tt0438488",
    tmdb_id: "534",
    url: "https://trakt.tv/movies/terminator-salvation-2009",
    released: "2009-05-20",
    runtime: "115",
    genres: "action,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-23T21:37:49Z": {
    type: "movie",
    title: "Terminator 2: Judgment Day",
    year: "1991",
    trakt_rating: "8.5076",
    trakt_id: "236",
    imdb_id: "tt0103064",
    tmdb_id: "280",
    url: "https://trakt.tv/movies/terminator-2-judgment-day-1991",
    released: "1991-07-03",
    runtime: "137",
    genres: "action,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-23T21:37:47Z": {
    type: "movie",
    title: "Terminator Genisys",
    year: "2015",
    trakt_rating: "6.7729",
    trakt_id: "65508",
    imdb_id: "tt1340138",
    tmdb_id: "87101",
    url: "https://trakt.tv/movies/terminator-genisys-2015",
    released: "2015-07-01",
    runtime: "126",
    genres: "action,adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-23T14:26:32Z": {
    type: "movie",
    title: "The Terminator",
    year: "1984",
    trakt_rating: "8.04159",
    trakt_id: "177",
    imdb_id: "tt0088247",
    tmdb_id: "218",
    url: "https://trakt.tv/movies/the-terminator-1984",
    released: "1984-10-26",
    runtime: "108",
    genres: "action,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-23T14:01:49Z": {
    type: "movie",
    title: "Seven Samurai",
    year: "1954",
    trakt_rating: "8.49732",
    trakt_id: "293",
    imdb_id: "tt0047478",
    tmdb_id: "346",
    url: "https://trakt.tv/movies/seven-samurai-1954",
    released: "1954-04-26",
    runtime: "207",
    genres: "action,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-23T13:54:49Z": {
    type: "movie",
    title: "The Last Castle",
    year: "2001",
    trakt_rating: "7.50254",
    trakt_id: "1449",
    imdb_id: "tt0272020",
    tmdb_id: "2100",
    url: "https://trakt.tv/movies/the-last-castle-2001",
    released: "2001-10-19",
    runtime: "131",
    genres: "action,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-23T03:38:47Z": {
    type: "movie",
    title: "The Last Samurai",
    year: "2003",
    trakt_rating: "7.78906",
    trakt_id: "492",
    imdb_id: "tt0325710",
    tmdb_id: "616",
    url: "https://trakt.tv/movies/the-last-samurai-2003",
    released: "2003-12-05",
    runtime: "154",
    genres: "action,drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-23T03:37:01Z": {
    type: "movie",
    title: "What Happened to Monday",
    year: "2017",
    trakt_rating: "7.27992",
    trakt_id: "256749",
    imdb_id: "tt1536537",
    tmdb_id: "406990",
    url: "https://trakt.tv/movies/what-happened-to-monday-2017",
    released: "2017-08-18",
    runtime: "123",
    genres: "thriller,science-fiction,drama,mystery,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-23T03:34:47Z": {
    type: "movie",
    title: "Miller's Crossing",
    year: "1990",
    trakt_rating: "7.66389",
    trakt_id: "303",
    imdb_id: "tt0100150",
    tmdb_id: "379",
    url: "https://trakt.tv/movies/miller-s-crossing-1990",
    released: "1990-09-21",
    runtime: "115",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-23T03:34:27Z": {
    type: "movie",
    title: "The Limey",
    year: "1999",
    trakt_rating: "6.76793",
    trakt_id: "5582",
    imdb_id: "tt0165854",
    tmdb_id: "10388",
    url: "https://trakt.tv/movies/the-limey-1999",
    released: "1999-10-08",
    runtime: "89",
    genres: "crime,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-23T01:05:51Z": {
    type: "movie",
    title: "War Witch",
    year: "2012",
    trakt_rating: "7.30612",
    trakt_id: "71197",
    imdb_id: "tt1820488",
    tmdb_id: "98205",
    url: "https://trakt.tv/movies/war-witch-2012",
    released: "2012-02-17",
    runtime: "90",
    genres: "drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:18:16Z": {
    type: "movie",
    title: "Zoolander 2",
    year: "2016",
    trakt_rating: "5.77445",
    trakt_id: "210774",
    imdb_id: "tt1608290",
    tmdb_id: "329833",
    url: "https://trakt.tv/movies/zoolander-2-2016",
    released: "2016-02-12",
    runtime: "100",
    genres: "comedy,adventure,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:17:53Z": {
    type: "movie",
    title: "World War Z",
    year: "2013",
    trakt_rating: "7.13842",
    trakt_id: "53002",
    imdb_id: "tt0816711",
    tmdb_id: "72190",
    url: "https://trakt.tv/movies/world-war-z-2013",
    released: "2013-06-21",
    runtime: "116",
    genres: "action,drama,horror,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:17:46Z": {
    type: "movie",
    title: "Womb",
    year: "2010",
    trakt_rating: "6.7381",
    trakt_id: "42612",
    imdb_id: "tt1216520",
    tmdb_id: "59457",
    url: "https://trakt.tv/movies/womb-2010",
    released: "2010-07-15",
    runtime: "111",
    genres: "science-fiction,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:17:44Z": {
    type: "movie",
    title: "The Wolfpack",
    year: "2015",
    trakt_rating: "6.84267",
    trakt_id: "201894",
    imdb_id: "tt2415458",
    tmdb_id: "307931",
    url: "https://trakt.tv/movies/the-wolfpack-2015",
    released: "2015-01-25",
    runtime: "89",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:17:23Z": {
    type: "movie",
    title: "Whole Lotta Sole",
    year: "2011",
    trakt_rating: "4.44304",
    trakt_id: "106194",
    imdb_id: "tt1545328",
    tmdb_id: "165477",
    url: "https://trakt.tv/movies/whole-lotta-sole-2011",
    released: "2012-06-10",
    runtime: "94",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:17:20Z": {
    type: "movie",
    title: "The Whistleblower",
    year: "2010",
    trakt_rating: "7.16524",
    trakt_id: "47602",
    imdb_id: "tt0896872",
    tmdb_id: "65760",
    url: "https://trakt.tv/movies/the-whistleblower-2010",
    released: "2010-09-13",
    runtime: "112",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:17:13Z": {
    type: "movie",
    title: "Whiplash",
    year: "2014",
    trakt_rating: "8.44042",
    trakt_id: "149047",
    imdb_id: "tt2582802",
    tmdb_id: "244786",
    url: "https://trakt.tv/movies/whiplash-2014",
    released: "2014-10-10",
    runtime: "107",
    genres: "drama,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:17:09Z": {
    type: "movie",
    title: "Where to Invade Next",
    year: "2015",
    trakt_rating: "7.70599",
    trakt_id: "222842",
    imdb_id: "tt4897822",
    tmdb_id: "352208",
    url: "https://trakt.tv/movies/where-to-invade-next-2015",
    released: "2015-12-23",
    runtime: "121",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:16:59Z": {
    type: "movie",
    title: "What Maisie Knew",
    year: "2013",
    trakt_rating: "7.54198",
    trakt_id: "87250",
    imdb_id: "tt1932767",
    tmdb_id: "127373",
    url: "https://trakt.tv/movies/what-maisie-knew-2013",
    released: "2013-05-02",
    runtime: "93",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:16:53Z": {
    type: "movie",
    title: "West of Memphis",
    year: "2012",
    trakt_rating: "7.91163",
    trakt_id: "63231",
    imdb_id: "tt2130321",
    tmdb_id: "84351",
    url: "https://trakt.tv/movies/west-of-memphis-2012",
    released: "2012-12-25",
    runtime: "150",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:16:47Z": {
    type: "movie",
    title: "Welcome to New York",
    year: "2014",
    trakt_rating: "5.71512",
    trakt_id: "123412",
    imdb_id: "tt2758890",
    tmdb_id: "197696",
    url: "https://trakt.tv/movies/welcome-to-new-york-2014",
    released: "2015-03-27",
    runtime: "125",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:16:42Z": {
    type: "movie",
    title: "We Steal Secrets: The Story of WikiLeaks",
    year: "2013",
    trakt_rating: "7.2109",
    trakt_id: "103084",
    imdb_id: "tt1824254",
    tmdb_id: "159154",
    url: "https://trakt.tv/movies/we-steal-secrets-the-story-of-wikileaks-2013",
    released: "2013-05-24",
    runtime: "127",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:16:38Z": {
    type: "movie",
    title: "We Need to Talk About Kevin",
    year: "2011",
    trakt_rating: "7.56189",
    trakt_id: "52738",
    imdb_id: "tt1242460",
    tmdb_id: "71859",
    url: "https://trakt.tv/movies/we-need-to-talk-about-kevin-2011",
    released: "2011-12-09",
    runtime: "113",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:16:13Z": {
    type: "movie",
    title: "WALL·E",
    year: "2008",
    trakt_rating: "8.46614",
    trakt_id: "5853",
    imdb_id: "tt0910970",
    tmdb_id: "10681",
    url: "https://trakt.tv/movies/wall-e-2008",
    released: "2008-06-22",
    runtime: "98",
    genres: "animation,family,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:15:58Z": {
    type: "movie",
    title: "The Wailing",
    year: "2016",
    trakt_rating: "7.42719",
    trakt_id: "190438",
    imdb_id: "tt5215952",
    tmdb_id: "293670",
    url: "https://trakt.tv/movies/the-wailing-2016",
    released: "2016-06-03",
    runtime: "156",
    genres: "mystery,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:15:03Z": {
    type: "movie",
    title: "V/H/S/2",
    year: "2013",
    trakt_rating: "6.36832",
    trakt_id: "103060",
    imdb_id: "tt2450186",
    tmdb_id: "159117",
    url: "https://trakt.tv/movies/v-h-s-2-2013",
    released: "2013-06-06",
    runtime: "96",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:15:02Z": {
    type: "movie",
    title: "V/H/S",
    year: "2012",
    trakt_rating: "6.20709",
    trakt_id: "63228",
    imdb_id: "tt2105044",
    tmdb_id: "84348",
    url: "https://trakt.tv/movies/v-h-s-2012",
    released: "2012-10-05",
    runtime: "116",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:14:57Z": {
    type: "movie",
    title: "Unfriended",
    year: "2014",
    trakt_rating: "5.77397",
    trakt_id: "175333",
    imdb_id: "tt3713166",
    tmdb_id: "277685",
    url: "https://trakt.tv/movies/unfriended-2014",
    released: "2015-04-17",
    runtime: "82",
    genres: "horror,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:14:47Z": {
    type: "movie",
    title: "Unforgiven",
    year: "2013",
    trakt_rating: "7.09649",
    trakt_id: "94581",
    imdb_id: "tt2347134",
    tmdb_id: "141819",
    url: "https://trakt.tv/movies/unforgiven-2013",
    released: "2013-09-13",
    runtime: "135",
    genres: "action,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:14:37Z": {
    type: "movie",
    title: "Twixt",
    year: "2011",
    trakt_rating: "5.44218",
    trakt_id: "58034",
    imdb_id: "tt1756851",
    tmdb_id: "78381",
    url: "https://trakt.tv/movies/twixt-2011",
    released: "2011-09-30",
    runtime: "89",
    genres: "horror,mystery,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:14:35Z": {
    type: "movie",
    title: "Truth",
    year: "2015",
    trakt_rating: "7.0586",
    trakt_id: "196811",
    imdb_id: "tt3859076",
    tmdb_id: "300693",
    url: "https://trakt.tv/movies/truth-2015",
    released: "2015-10-16",
    runtime: "125",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:14:29Z": {
    type: "movie",
    title: "Trumbo",
    year: "2015",
    trakt_rating: "7.38667",
    trakt_id: "190767",
    imdb_id: "tt3203606",
    tmdb_id: "294016",
    url: "https://trakt.tv/movies/trumbo-2015",
    released: "2015-10-27",
    runtime: "124",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:14:24Z": {
    type: "movie",
    title: "True Story",
    year: "2015",
    trakt_rating: "6.56915",
    trakt_id: "149558",
    imdb_id: "tt2273657",
    tmdb_id: "245706",
    url: "https://trakt.tv/movies/true-story-2015",
    released: "2015-04-17",
    runtime: "99",
    genres: "drama,crime,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:14:09Z": {
    type: "movie",
    title: "Train to Busan",
    year: "2016",
    trakt_rating: "7.85802",
    trakt_id: "239425",
    imdb_id: "tt5700672",
    tmdb_id: "396535",
    url: "https://trakt.tv/movies/train-to-busan-2016",
    released: "2016-07-20",
    runtime: "118",
    genres: "thriller,action,horror,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:14:00Z": {
    type: "movie",
    title: "Toy Story 3",
    year: "2010",
    trakt_rating: "8.12261",
    trakt_id: "5407",
    imdb_id: "tt0435761",
    tmdb_id: "10193",
    url: "https://trakt.tv/movies/toy-story-3-2010",
    released: "2010-06-18",
    runtime: "103",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:13:28Z": {
    type: "movie",
    title: "Timecrimes",
    year: "2007",
    trakt_rating: "7.34957",
    trakt_id: "8460",
    imdb_id: "tt0480669",
    tmdb_id: "14139",
    url: "https://trakt.tv/movies/timecrimes-2007",
    released: "2007-10-05",
    runtime: "92",
    genres: "science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:11:00Z": {
    type: "movie",
    title: "Time Bandits",
    year: "1981",
    trakt_rating: "7.172",
    trakt_id: "23432",
    imdb_id: "tt0081633",
    tmdb_id: "36819",
    url: "https://trakt.tv/movies/time-bandits-1981",
    released: "1981-07-13",
    runtime: "116",
    genres: "adventure,comedy,fantasy,science-fiction,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:10:56Z": {
    type: "movie",
    title: "Thor: The Dark World",
    year: "2013",
    trakt_rating: "7.03784",
    trakt_id: "56358",
    imdb_id: "tt1981115",
    tmdb_id: "76338",
    url: "https://trakt.tv/movies/thor-the-dark-world-2013",
    released: "2013-11-08",
    runtime: "112",
    genres: "action,adventure,fantasy,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:10:48Z": {
    type: "movie",
    title: "The Tale of The Princess Kaguya",
    year: "2013",
    trakt_rating: "8.01127",
    trakt_id: "98670",
    imdb_id: "tt2576852",
    tmdb_id: "149871",
    url: "https://trakt.tv/movies/the-tale-of-the-princess-kaguya-2013",
    released: "2013-11-23",
    runtime: "137",
    genres: "drama,fantasy,anime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:10:22Z": {
    type: "movie",
    title: "The Taking of Deborah Logan",
    year: "2014",
    trakt_rating: "6.53571",
    trakt_id: "193288",
    imdb_id: "tt3387648",
    tmdb_id: "297608",
    url: "https://trakt.tv/movies/the-taking-of-deborah-logan-2014",
    released: "2014-10-21",
    runtime: "90",
    genres: "horror,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:10:07Z": {
    type: "movie",
    title: "The Survivalist",
    year: "2015",
    trakt_rating: "6.60141",
    trakt_id: "209916",
    imdb_id: "tt2580382",
    tmdb_id: "329004",
    url: "https://trakt.tv/movies/the-survivalist-2015",
    released: "2016-04-18",
    runtime: "104",
    genres: "drama,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:09:54Z": {
    type: "movie",
    title: "Stretch",
    year: "2014",
    trakt_rating: "6.62878",
    trakt_id: "138997",
    imdb_id: "tt2494280",
    tmdb_id: "227300",
    url: "https://trakt.tv/movies/stretch-2014",
    released: "2014-10-14",
    runtime: "94",
    genres: "action,comedy,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:09:07Z": {
    type: "movie",
    title: "Stoker",
    year: "2013",
    trakt_rating: "6.92878",
    trakt_id: "65312",
    imdb_id: "tt1682180",
    tmdb_id: "86825",
    url: "https://trakt.tv/movies/stoker-2013",
    released: "2013-03-01",
    runtime: "99",
    genres: "drama,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:08:59Z": {
    type: "movie",
    title: "Steve Jobs",
    year: "2015",
    trakt_rating: "6.88363",
    trakt_id: "202637",
    imdb_id: "tt2080374",
    tmdb_id: "321697",
    url: "https://trakt.tv/movies/steve-jobs-2015",
    released: "2015-10-09",
    runtime: "122",
    genres: "history,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:08:51Z": {
    type: "movie",
    title: "Stash House",
    year: "2012",
    trakt_rating: "5.175",
    trakt_id: "73411",
    imdb_id: "tt1919090",
    tmdb_id: "102630",
    url: "https://trakt.tv/movies/stash-house-2012",
    released: "2012-05-11",
    runtime: "95",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:08:11Z": {
    type: "movie",
    title: "Star Wars",
    year: "1977",
    trakt_rating: "8.5201",
    trakt_id: "7",
    imdb_id: "tt0076759",
    tmdb_id: "11",
    url: "https://trakt.tv/movies/star-wars-1977",
    released: "1977-05-25",
    runtime: "121",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:07:59Z": {
    type: "movie",
    title: "Star Trek",
    year: "2009",
    trakt_rating: "8.04524",
    trakt_id: "7935",
    imdb_id: "tt0796366",
    tmdb_id: "13475",
    url: "https://trakt.tv/movies/star-trek-2009",
    released: "2009-05-07",
    runtime: "127",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:07:24Z": {
    type: "movie",
    title: "Southbound",
    year: "2015",
    trakt_rating: "6.12967",
    trakt_id: "223788",
    imdb_id: "tt4935334",
    tmdb_id: "354251",
    url: "https://trakt.tv/movies/southbound-2015",
    released: "2016-02-05",
    runtime: "89",
    genres: "horror,science-fiction,thriller,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:07:19Z": {
    type: "movie",
    title: "Sound of My Voice",
    year: "2011",
    trakt_rating: "6.82621",
    trakt_id: "65299",
    imdb_id: "tt1748207",
    tmdb_id: "86812",
    url: "https://trakt.tv/movies/sound-of-my-voice-2011",
    released: "2011-01-22",
    runtime: "85",
    genres: "drama,mystery,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:07:14Z": {
    type: "movie",
    title: "Son of Saul",
    year: "2015",
    trakt_rating: "7.27419",
    trakt_id: "216774",
    imdb_id: "tt3808342",
    tmdb_id: "336050",
    url: "https://trakt.tv/movies/son-of-saul-2015",
    released: "2015-06-11",
    runtime: "107",
    genres: "drama,war,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:07:08Z": {
    type: "movie",
    title: "Solo",
    year: "2013",
    trakt_rating: "5.14634",
    trakt_id: "137857",
    imdb_id: "tt2374196",
    tmdb_id: "224950",
    url: "https://trakt.tv/movies/solo-2013",
    released: "2013-08-29",
    runtime: "83",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:06:51Z": {
    type: "movie",
    title: "Snowtown",
    year: "2011",
    trakt_rating: "6.81712",
    trakt_id: "49292",
    imdb_id: "tt1680114",
    tmdb_id: "67748",
    url: "https://trakt.tv/movies/snowtown-2011",
    released: "2011-05-19",
    runtime: "120",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:05:59Z": {
    type: "movie",
    title: "Shin Godzilla",
    year: "2016",
    trakt_rating: "6.91874",
    trakt_id: "195027",
    imdb_id: "tt4262980",
    tmdb_id: "315011",
    url: "https://trakt.tv/movies/shin-godzilla-2016",
    released: "2016-07-29",
    runtime: "120",
    genres: "action,science-fiction,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:05:46Z": {
    type: "movie",
    title: "A Serious Man",
    year: "2009",
    trakt_rating: "6.94035",
    trakt_id: "7341",
    imdb_id: "tt1019452",
    tmdb_id: "12573",
    url: "https://trakt.tv/movies/a-serious-man-2009",
    released: "2009-10-02",
    runtime: "106",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:05:27Z": {
    type: "movie",
    title: "Scarface",
    year: "1983",
    trakt_rating: "8.34817",
    trakt_id: "80",
    imdb_id: "tt0086250",
    tmdb_id: "111",
    url: "https://trakt.tv/movies/scarface-1983",
    released: "1983-12-09",
    runtime: "169",
    genres: "action,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:05:00Z": {
    type: "movie",
    title: "Room 237",
    year: "2012",
    trakt_rating: "6.42072",
    trakt_id: "63211",
    imdb_id: "tt2085910",
    tmdb_id: "84330",
    url: "https://trakt.tv/movies/room-237-2012",
    released: "2013-03-29",
    runtime: "102",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:04:36Z": {
    type: "movie",
    title: "Road to Paloma",
    year: "2014",
    trakt_rating: "6.22184",
    trakt_id: "166414",
    imdb_id: "tt2386285",
    tmdb_id: "268060",
    url: "https://trakt.tv/movies/road-to-paloma-2014",
    released: "2014-04-12",
    runtime: "91",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:03:00Z": {
    type: "movie",
    title: "Road to Nowhere",
    year: "2010",
    trakt_rating: "6.875",
    trakt_id: "28572",
    imdb_id: "tt1504319",
    tmdb_id: "42707",
    url: "https://trakt.tv/movies/road-to-nowhere-2010",
    released: "2011-06-10",
    runtime: "121",
    genres: "mystery,thriller,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:02:06Z": {
    type: "movie",
    title: "Return of the Jedi",
    year: "1983",
    trakt_rating: "8.42145",
    trakt_id: "1267",
    imdb_id: "tt0086190",
    tmdb_id: "1892",
    url: "https://trakt.tv/movies/return-of-the-jedi-1983",
    released: "1983-05-25",
    runtime: "135",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:02:01Z": {
    type: "movie",
    title: "Resident Evil: Vendetta",
    year: "2017",
    trakt_rating: "6.99031",
    trakt_id: "244489",
    imdb_id: "tt5776208",
    tmdb_id: "400136",
    url: "https://trakt.tv/movies/resident-evil-vendetta-2017",
    released: "2017-06-19",
    runtime: "97",
    genres: "horror,anime,action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:01:53Z": {
    type: "movie",
    title: "Reservoir Dogs",
    year: "1992",
    trakt_rating: "8.27314",
    trakt_id: "392",
    imdb_id: "tt0105236",
    tmdb_id: "500",
    url: "https://trakt.tv/movies/reservoir-dogs-1992",
    released: "1992-10-23",
    runtime: "99",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:01:48Z": {
    type: "movie",
    title: "Requiem for a Dream",
    year: "2000",
    trakt_rating: "8.1764",
    trakt_id: "517",
    imdb_id: "tt0180093",
    tmdb_id: "641",
    url: "https://trakt.tv/movies/requiem-for-a-dream-2000",
    released: "2000-10-06",
    runtime: "102",
    genres: "drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:01:43Z": {
    type: "movie",
    title: "Red State",
    year: "2011",
    trakt_rating: "6.451",
    trakt_id: "33678",
    imdb_id: "tt0873886",
    tmdb_id: "48572",
    url: "https://trakt.tv/movies/red-state-2011",
    released: "2011-09-23",
    runtime: "88",
    genres: "action,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:01:29Z": {
    type: "movie",
    title: "Re-Animator",
    year: "1985",
    trakt_rating: "7.31115",
    trakt_id: "1132",
    imdb_id: "tt0089885",
    tmdb_id: "1694",
    url: "https://trakt.tv/movies/re-animator-1985",
    released: "1985-10-18",
    runtime: "86",
    genres: "comedy,horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:01:21Z": {
    type: "movie",
    title: "While We're Young",
    year: "2015",
    trakt_rating: "6.23917",
    trakt_id: "153167",
    imdb_id: "tt1791682",
    tmdb_id: "252512",
    url: "https://trakt.tv/movies/while-we-re-young-2015",
    released: "2014-09-28",
    runtime: "97",
    genres: "comedy,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:00:55Z": {
    type: "movie",
    title: "Rapture-Palooza",
    year: "2013",
    trakt_rating: "6.00634",
    trakt_id: "110597",
    imdb_id: "tt1879032",
    tmdb_id: "174188",
    url: "https://trakt.tv/movies/rapture-palooza-2013",
    released: "2013-06-07",
    runtime: "85",
    genres: "comedy,fantasy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:00:26Z": {
    type: "movie",
    title: "Ran",
    year: "1985",
    trakt_rating: "8.05236",
    trakt_id: "6719",
    imdb_id: "tt0089881",
    tmdb_id: "11645",
    url: "https://trakt.tv/movies/ran-1985",
    released: "1985-06-01",
    runtime: "160",
    genres: "action,drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:00:21Z": {
    type: "movie",
    title: "Raiders of the Lost Ark",
    year: "1981",
    trakt_rating: "8.33719",
    trakt_id: "54",
    imdb_id: "tt0082971",
    tmdb_id: "85",
    url: "https://trakt.tv/movies/raiders-of-the-lost-ark-1981",
    released: "1981-06-12",
    runtime: "115",
    genres: "action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:00:17Z": {
    type: "movie",
    title: "Rabbit Hole",
    year: "2010",
    trakt_rating: "6.78763",
    trakt_id: "16988",
    imdb_id: "tt0935075",
    tmdb_id: "27585",
    url: "https://trakt.tv/movies/rabbit-hole-2010",
    released: "2010-12-16",
    runtime: "91",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T13:00:07Z": {
    type: "movie",
    title: "Pulp Fiction",
    year: "1994",
    trakt_rating: "8.69739",
    trakt_id: "554",
    imdb_id: "tt0110912",
    tmdb_id: "680",
    url: "https://trakt.tv/movies/pulp-fiction-1994",
    released: "1994-10-14",
    runtime: "154",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:59:50Z": {
    type: "movie",
    title: "Project Almanac",
    year: "2015",
    trakt_rating: "6.85134",
    trakt_id: "139232",
    imdb_id: "tt2436386",
    tmdb_id: "227719",
    url: "https://trakt.tv/movies/project-almanac-2015",
    released: "2015-01-28",
    runtime: "106",
    genres: "science-fiction,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:59:47Z": {
    type: "movie",
    title: "Prisoners",
    year: "2013",
    trakt_rating: "8.05939",
    trakt_id: "96797",
    imdb_id: "tt1392214",
    tmdb_id: "146233",
    url: "https://trakt.tv/movies/prisoners-2013",
    released: "2013-09-20",
    runtime: "153",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:59:41Z": {
    type: "movie",
    title: "The Poughkeepsie Tapes",
    year: "2007",
    trakt_rating: "6.3939",
    trakt_id: "24813",
    imdb_id: "tt1010271",
    tmdb_id: "38410",
    url: "https://trakt.tv/movies/the-poughkeepsie-tapes-2007",
    released: "2007-04-27",
    runtime: "81",
    genres: "horror,mystery,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:59:35Z": {
    type: "movie",
    title: "Pink Floyd: The Wall",
    year: "1982",
    trakt_rating: "8.25837",
    trakt_id: "7022",
    imdb_id: "tt0084503",
    tmdb_id: "12104",
    url: "https://trakt.tv/movies/pink-floyd-the-wall-1982",
    released: "1982-07-14",
    runtime: "95",
    genres: "music,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:59:30Z": {
    type: "movie",
    title: "Pink Floyd : The Story of Wish You Were Here",
    year: "2012",
    trakt_rating: "7.9854",
    trakt_id: "81483",
    imdb_id: "tt2296909",
    tmdb_id: "116835",
    url: "https://trakt.tv/movies/pink-floyd-the-story-of-wish-you-were-here-2012",
    released: "2012-06-26",
    runtime: "85",
    genres: "documentary,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:59:23Z": {
    type: "movie",
    title: "Phantom",
    year: "2013",
    trakt_rating: "6.5126",
    trakt_id: "99774",
    imdb_id: "tt1922685",
    tmdb_id: "152259",
    url: "https://trakt.tv/movies/phantom-2013",
    released: "2013-03-01",
    runtime: "99",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:59:19Z": {
    type: "movie",
    title: "Pete's Dragon",
    year: "2016",
    trakt_rating: "7.4993",
    trakt_id: "191003",
    imdb_id: "tt2788732",
    tmdb_id: "294272",
    url: "https://trakt.tv/movies/pete-s-dragon-2016",
    released: "2016-08-12",
    runtime: "103",
    genres: "adventure,family,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:59:14Z": {
    type: "movie",
    title: "A Perfect Day",
    year: "2015",
    trakt_rating: "6.88",
    trakt_id: "202690",
    imdb_id: "tt3577624",
    tmdb_id: "321751",
    url: "https://trakt.tv/movies/a-perfect-day-2015",
    released: "2015-08-28",
    runtime: "106",
    genres: "drama,comedy,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:58:49Z": {
    type: "movie",
    title: "ParaNorman",
    year: "2012",
    trakt_rating: "7.22411",
    trakt_id: "57060",
    imdb_id: "tt1623288",
    tmdb_id: "77174",
    url: "https://trakt.tv/movies/paranorman-2012",
    released: "2012-08-17",
    runtime: "90",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:58:43Z": {
    type: "movie",
    title: "Paranormal Activity: The Ghost Dimension",
    year: "2015",
    trakt_rating: "5.61259",
    trakt_id: "96832",
    imdb_id: "tt2473510",
    tmdb_id: "146301",
    url: "https://trakt.tv/movies/paranormal-activity-the-ghost-dimension-2015",
    released: "2015-10-23",
    runtime: "88",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:58:33Z": {
    type: "movie",
    title: "Page One: Inside the New York Times",
    year: "2011",
    trakt_rating: "7.32692",
    trakt_id: "45420",
    imdb_id: "tt1787777",
    tmdb_id: "63144",
    url: "https://trakt.tv/movies/page-one-inside-the-new-york-times-2011",
    released: "2011-04-29",
    runtime: "88",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:58:28Z": {
    type: "movie",
    title: "Paddington",
    year: "2014",
    trakt_rating: "7.46424",
    trakt_id: "81077",
    imdb_id: "tt1109624",
    tmdb_id: "116149",
    url: "https://trakt.tv/movies/paddington-2014",
    released: "2015-01-16",
    runtime: "96",
    genres: "comedy,adventure,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:58:25Z": {
    type: "movie",
    title: "Once Upon a Time in Anatolia",
    year: "2011",
    trakt_rating: "7.47092",
    trakt_id: "55176",
    imdb_id: "tt1827487",
    tmdb_id: "74879",
    url: "https://trakt.tv/movies/once-upon-a-time-in-anatolia-2011",
    released: "2011-09-23",
    runtime: "157",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:58:10Z": {
    type: "movie",
    title: "Oldboy",
    year: "2003",
    trakt_rating: "8.36547",
    trakt_id: "544",
    imdb_id: "tt0364569",
    tmdb_id: "670",
    url: "https://trakt.tv/movies/oldboy-2003",
    released: "2003-11-21",
    runtime: "120",
    genres: "action,drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:56:39Z": {
    type: "movie",
    title: "Oldboy",
    year: "2013",
    trakt_rating: "6.47633",
    trakt_id: "65762",
    imdb_id: "tt1321511",
    tmdb_id: "87516",
    url: "https://trakt.tv/movies/oldboy-2013",
    released: "2013-11-27",
    runtime: "104",
    genres: "action,drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:56:30Z": {
    type: "movie",
    title: "Of Gods and Men",
    year: "2010",
    trakt_rating: "6.99608",
    trakt_id: "31761",
    imdb_id: "tt1588337",
    tmdb_id: "46332",
    url: "https://trakt.tv/movies/of-gods-and-men-2010",
    released: "2010-09-08",
    runtime: "120",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:56:21Z": {
    type: "movie",
    title: "No One Lives",
    year: "2013",
    trakt_rating: "6.67332",
    trakt_id: "84998",
    imdb_id: "tt1763264",
    tmdb_id: "123109",
    url: "https://trakt.tv/movies/no-one-lives-2013",
    released: "2013-05-10",
    runtime: "86",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:56:17Z": {
    type: "movie",
    title: "No Country for Old Men",
    year: "2007",
    trakt_rating: "7.96289",
    trakt_id: "3798",
    imdb_id: "tt0477348",
    tmdb_id: "6977",
    url: "https://trakt.tv/movies/no-country-for-old-men-2007",
    released: "2007-11-08",
    runtime: "122",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:56:13Z": {
    type: "movie",
    title: "The Name of the Rose",
    year: "1986",
    trakt_rating: "7.83728",
    trakt_id: "155",
    imdb_id: "tt0091605",
    tmdb_id: "192",
    url: "https://trakt.tv/movies/the-name-of-the-rose-1986",
    released: "1986-12-17",
    runtime: "130",
    genres: "drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:56:08Z": {
    type: "movie",
    title: "My Neighbor Totoro",
    year: "1988",
    trakt_rating: "8.31258",
    trakt_id: "4169",
    imdb_id: "tt0096283",
    tmdb_id: "8392",
    url: "https://trakt.tv/movies/my-neighbor-totoro-1988",
    released: "1988-04-16",
    runtime: "86",
    genres: "fantasy,family,anime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:55:59Z": {
    type: "movie",
    title: "Mulan",
    year: "1998",
    trakt_rating: "8.01843",
    trakt_id: "5846",
    imdb_id: "tt0120762",
    tmdb_id: "10674",
    url: "https://trakt.tv/movies/mulan-1998",
    released: "1998-06-19",
    runtime: "88",
    genres: "adventure,animation,family,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:55:54Z": {
    type: "movie",
    title: "Mud",
    year: "2013",
    trakt_rating: "7.33221",
    trakt_id: "74024",
    imdb_id: "tt1935179",
    tmdb_id: "103731",
    url: "https://trakt.tv/movies/mud-2013",
    released: "2013-04-26",
    runtime: "130",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:55:49Z": {
    type: "movie",
    title: "Mr. Holmes",
    year: "2015",
    trakt_rating: "6.98057",
    trakt_id: "178423",
    imdb_id: "tt3168230",
    tmdb_id: "280996",
    url: "https://trakt.tv/movies/mr-holmes-2015",
    released: "2015-07-17",
    runtime: "104",
    genres: "drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:55:33Z": {
    type: "movie",
    title: "A Most Wanted Man",
    year: "2014",
    trakt_rating: "6.72334",
    trakt_id: "102444",
    imdb_id: "tt1972571",
    tmdb_id: "157849",
    url: "https://trakt.tv/movies/a-most-wanted-man-2014",
    released: "2014-07-25",
    runtime: "121",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:55:23Z": {
    type: "movie",
    title: "Moonlight",
    year: "2016",
    trakt_rating: "7.35137",
    trakt_id: "240880",
    imdb_id: "tt4975722",
    tmdb_id: "376867",
    url: "https://trakt.tv/movies/moonlight-2016",
    released: "2016-10-21",
    runtime: "111",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:55:17Z": {
    type: "movie",
    title: "Monty Python and the Holy Grail",
    year: "1975",
    trakt_rating: "8.25443",
    trakt_id: "605",
    imdb_id: "tt0071853",
    tmdb_id: "762",
    url: "https://trakt.tv/movies/monty-python-and-the-holy-grail-1975",
    released: "1975-05-25",
    runtime: "91",
    genres: "adventure,comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:55:12Z": {
    type: "movie",
    title: "Monsieur Lazhar",
    year: "2011",
    trakt_rating: "7.46005",
    trakt_id: "58125",
    imdb_id: "tt2011971",
    tmdb_id: "78480",
    url: "https://trakt.tv/movies/monsieur-lazhar-2011",
    released: "2011-09-10",
    runtime: "94",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:55:02Z": {
    type: "movie",
    title: "Moneyball",
    year: "2011",
    trakt_rating: "7.66599",
    trakt_id: "43154",
    imdb_id: "tt1210166",
    tmdb_id: "60308",
    url: "https://trakt.tv/movies/moneyball-2011",
    released: "2011-09-23",
    runtime: "134",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:53:18Z": {
    type: "movie",
    title: "Midnight in Paris",
    year: "2011",
    trakt_rating: "7.56968",
    trakt_id: "42597",
    imdb_id: "tt1605783",
    tmdb_id: "59436",
    url: "https://trakt.tv/movies/midnight-in-paris-2011",
    released: "2011-05-20",
    runtime: "94",
    genres: "comedy,fantasy,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:53:10Z": {
    type: "movie",
    title: "Melancholia",
    year: "2011",
    trakt_rating: "7.08248",
    trakt_id: "44682",
    imdb_id: "tt1527186",
    tmdb_id: "62215",
    url: "https://trakt.tv/movies/melancholia-2011",
    released: "2011-05-26",
    runtime: "135",
    genres: "drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:53:06Z": {
    type: "movie",
    title: "The Maze Runner",
    year: "2014",
    trakt_rating: "7.21326",
    trakt_id: "124008",
    imdb_id: "tt1790864",
    tmdb_id: "198663",
    url: "https://trakt.tv/movies/the-maze-runner-2014",
    released: "2014-09-19",
    runtime: "113",
    genres: "action,mystery,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:53:00Z": {
    type: "movie",
    title: "The Matrix",
    year: "1999",
    trakt_rating: "8.77194",
    trakt_id: "481",
    imdb_id: "tt0133093",
    tmdb_id: "603",
    url: "https://trakt.tv/movies/the-matrix-1999",
    released: "1999-03-30",
    runtime: "136",
    genres: "action,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:52:56Z": {
    type: "movie",
    title: "Matewan",
    year: "1987",
    trakt_rating: "7.61321",
    trakt_id: "14598",
    imdb_id: "tt0093509",
    tmdb_id: "24276",
    url: "https://trakt.tv/movies/matewan-1987",
    released: "1987-08-28",
    runtime: "132",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:52:45Z": {
    type: "movie",
    title: "Martha Marcy May Marlene",
    year: "2011",
    trakt_rating: "6.84069",
    trakt_id: "35593",
    imdb_id: "tt1441326",
    tmdb_id: "50837",
    url: "https://trakt.tv/movies/martha-marcy-may-marlene-2011",
    released: "2011-10-21",
    runtime: "101",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:52:41Z": {
    type: "movie",
    title: "Man on a Ledge",
    year: "2012",
    trakt_rating: "7.01689",
    trakt_id: "34513",
    imdb_id: "tt1568338",
    tmdb_id: "49527",
    url: "https://trakt.tv/movies/man-on-a-ledge-2012",
    released: "2012-01-27",
    runtime: "102",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:52:20Z": {
    type: "movie",
    title: "Love, Rosie",
    year: "2014",
    trakt_rating: "7.62653",
    trakt_id: "125205",
    imdb_id: "tt1638002",
    tmdb_id: "200727",
    url: "https://trakt.tv/movies/love-rosie-2014",
    released: "2014-10-16",
    runtime: "102",
    genres: "comedy,romance,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:51:51Z": {
    type: "movie",
    title: "The Lord of the Rings: The Return of the King",
    year: "2003",
    trakt_rating: "8.88334",
    trakt_id: "90",
    imdb_id: "tt0167260",
    tmdb_id: "122",
    url: "https://trakt.tv/movies/the-lord-of-the-rings-the-return-of-the-king-2003",
    released: "2003-12-17",
    runtime: "201",
    genres: "action,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:51:46Z": {
    type: "movie",
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: "2001",
    trakt_rating: "8.75141",
    trakt_id: "88",
    imdb_id: "tt0120737",
    tmdb_id: "120",
    url: "https://trakt.tv/movies/the-lord-of-the-rings-the-fellowship-of-the-ring-2001",
    released: "2001-12-19",
    runtime: "179",
    genres: "action,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:51:42Z": {
    type: "movie",
    title: "Looper",
    year: "2012",
    trakt_rating: "7.37288",
    trakt_id: "42897",
    imdb_id: "tt1276104",
    tmdb_id: "59967",
    url: "https://trakt.tv/movies/looper-2012",
    released: "2012-09-28",
    runtime: "118",
    genres: "action,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:51:36Z": {
    type: "movie",
    title: "The Longest Ride",
    year: "2015",
    trakt_rating: "7.55442",
    trakt_id: "139461",
    imdb_id: "tt2726560",
    tmdb_id: "228205",
    url: "https://trakt.tv/movies/the-longest-ride-2015",
    released: "2015-04-10",
    runtime: "128",
    genres: "drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:51:31Z": {
    type: "movie",
    title: "London Has Fallen",
    year: "2016",
    trakt_rating: "6.95025",
    trakt_id: "166247",
    imdb_id: "tt3300542",
    tmdb_id: "267860",
    url: "https://trakt.tv/movies/london-has-fallen-2016",
    released: "2016-03-04",
    runtime: "99",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:51:10Z": {
    type: "movie",
    title: "Locke",
    year: "2014",
    trakt_rating: "6.89413",
    trakt_id: "130301",
    imdb_id: "tt2692904",
    tmdb_id: "210479",
    url: "https://trakt.tv/movies/locke-2014",
    released: "2014-04-25",
    runtime: "85",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:50:55Z": {
    type: "movie",
    title: "Lincoln",
    year: "2012",
    trakt_rating: "7.26019",
    trakt_id: "53638",
    imdb_id: "tt0443272",
    tmdb_id: "72976",
    url: "https://trakt.tv/movies/lincoln-2012",
    released: "2012-11-16",
    runtime: "149",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:50:48Z": {
    type: "movie",
    title: "Let the Right One In",
    year: "2008",
    trakt_rating: "7.75654",
    trakt_id: "7812",
    imdb_id: "tt1139797",
    tmdb_id: "13310",
    url: "https://trakt.tv/movies/let-the-right-one-in-2008",
    released: "2009-02-04",
    runtime: "114",
    genres: "drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:50:43Z": {
    type: "movie",
    title: "Let the Bullets Fly",
    year: "2010",
    trakt_rating: "7.66319",
    trakt_id: "36164",
    imdb_id: "tt1533117",
    tmdb_id: "51533",
    url: "https://trakt.tv/movies/let-the-bullets-fly-2010",
    released: "2010-12-20",
    runtime: "132",
    genres: "action,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:50:38Z": {
    type: "movie",
    title: "Léon: The Professional",
    year: "1994",
    trakt_rating: "8.52113",
    trakt_id: "70",
    imdb_id: "tt0110413",
    tmdb_id: "101",
    url: "https://trakt.tv/movies/leon-the-professional-1994",
    released: "1994-11-18",
    runtime: "111",
    genres: "crime,drama,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:50:15Z": {
    type: "movie",
    title: "Legend",
    year: "2015",
    trakt_rating: "7.01374",
    trakt_id: "174599",
    imdb_id: "tt3569230",
    tmdb_id: "276907",
    url: "https://trakt.tv/movies/legend-2015",
    released: "2015-11-20",
    runtime: "132",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:50:07Z": {
    type: "movie",
    title: "Le Havre",
    year: "2011",
    trakt_rating: "7.24791",
    trakt_id: "54036",
    imdb_id: "tt1508675",
    tmdb_id: "73532",
    url: "https://trakt.tv/movies/le-havre-2011",
    released: "2011-09-09",
    runtime: "94",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:49:00Z": {
    type: "movie",
    title: "Lawless",
    year: "2012",
    trakt_rating: "7.35234",
    trakt_id: "61815",
    imdb_id: "tt1212450",
    tmdb_id: "82633",
    url: "https://trakt.tv/movies/lawless-2012",
    released: "2012-08-29",
    runtime: "115",
    genres: "crime,drama,western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:48:53Z": {
    type: "movie",
    title: "Law Abiding Citizen",
    year: "2009",
    trakt_rating: "7.81668",
    trakt_id: "13852",
    imdb_id: "tt1197624",
    tmdb_id: "22803",
    url: "https://trakt.tv/movies/law-abiding-citizen-2009",
    released: "2009-10-15",
    runtime: "109",
    genres: "crime,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:48:48Z": {
    type: "movie",
    title: "Last Ride",
    year: "2009",
    trakt_rating: "6.85246",
    trakt_id: "16896",
    imdb_id: "tt1235142",
    tmdb_id: "27480",
    url: "https://trakt.tv/movies/last-ride-2009",
    released: "2009-07-02",
    runtime: "101",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:48:43Z": {
    type: "movie",
    title: "The Last Circus",
    year: "2010",
    trakt_rating: "6.69974",
    trakt_id: "40539",
    imdb_id: "tt1572491",
    tmdb_id: "56812",
    url: "https://trakt.tv/movies/the-last-circus-2010",
    released: "2010-12-17",
    runtime: "106",
    genres: "drama,horror,comedy,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:48:07Z": {
    type: "movie",
    title: "Kumiko, the Treasure Hunter",
    year: "2014",
    trakt_rating: "6.37015",
    trakt_id: "148911",
    imdb_id: "tt3263614",
    tmdb_id: "244563",
    url: "https://trakt.tv/movies/kumiko-the-treasure-hunter-2014",
    released: "2015-03-18",
    runtime: "100",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:47:56Z": {
    type: "movie",
    title: "Knuckle",
    year: "2011",
    trakt_rating: "6.7931",
    trakt_id: "52662",
    imdb_id: "tt1606259",
    tmdb_id: "71771",
    url: "https://trakt.tv/movies/knuckle-2011",
    released: "2011-12-09",
    runtime: "97",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:47:50Z": {
    type: "movie",
    title: "Knock Knock",
    year: "2015",
    trakt_rating: "5.57214",
    trakt_id: "162293",
    imdb_id: "tt3605418",
    tmdb_id: "263472",
    url: "https://trakt.tv/movies/knock-knock-2015",
    released: "2015-10-09",
    runtime: "99",
    genres: "thriller,drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:47:27Z": {
    type: "movie",
    title: "The King's Speech",
    year: "2010",
    trakt_rating: "8.05316",
    trakt_id: "30866",
    imdb_id: "tt1504320",
    tmdb_id: "45269",
    url: "https://trakt.tv/movies/the-king-s-speech-2010",
    released: "2010-11-26",
    runtime: "118",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:47:20Z": {
    type: "movie",
    title: "Killing Them Softly",
    year: "2012",
    trakt_rating: "6.24502",
    trakt_id: "46735",
    imdb_id: "tt1764234",
    tmdb_id: "64689",
    url: "https://trakt.tv/movies/killing-them-softly-2012",
    released: "2012-11-30",
    runtime: "97",
    genres: "crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:47:16Z": {
    type: "movie",
    title: "Kill the Messenger",
    year: "2014",
    trakt_rating: "6.99949",
    trakt_id: "149650",
    imdb_id: "tt1216491",
    tmdb_id: "245916",
    url: "https://trakt.tv/movies/kill-the-messenger-2014",
    released: "2014-10-10",
    runtime: "112",
    genres: "crime,drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:47:02Z": {
    type: "movie",
    title: "The Jungle Book",
    year: "2016",
    trakt_rating: "7.50477",
    trakt_id: "176503",
    imdb_id: "tt3040964",
    tmdb_id: "278927",
    url: "https://trakt.tv/movies/the-jungle-book-2016",
    released: "2016-04-15",
    runtime: "106",
    genres: "adventure,drama,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:46:51Z": {
    type: "movie",
    title: "Jodorowsky's Dune",
    year: "2013",
    trakt_rating: "7.97619",
    trakt_id: "119793",
    imdb_id: "tt1935156",
    tmdb_id: "191720",
    url: "https://trakt.tv/movies/jodorowsky-s-dune-2013",
    released: "2013-08-30",
    runtime: "90",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:46:44Z": {
    type: "movie",
    title: "Jiro Dreams of Sushi",
    year: "2011",
    trakt_rating: "8.03156",
    trakt_id: "60185",
    imdb_id: "tt1772925",
    tmdb_id: "80767",
    url: "https://trakt.tv/movies/jiro-dreams-of-sushi-2011",
    released: "2012-03-09",
    runtime: "82",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:46:39Z": {
    type: "movie",
    title: "Jaws",
    year: "1975",
    trakt_rating: "7.83551",
    trakt_id: "457",
    imdb_id: "tt0073195",
    tmdb_id: "578",
    url: "https://trakt.tv/movies/jaws-1975",
    released: "1975-06-20",
    runtime: "124",
    genres: "adventure,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:46:25Z": {
    type: "movie",
    title: "Jack Goes Boating",
    year: "2010",
    trakt_rating: "6.48366",
    trakt_id: "29689",
    imdb_id: "tt1278379",
    tmdb_id: "43943",
    url: "https://trakt.tv/movies/jack-goes-boating-2010",
    released: "2010-09-17",
    runtime: "91",
    genres: "comedy,drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:46:04Z": {
    type: "movie",
    title: "The Island President",
    year: "2012",
    trakt_rating: "7.21622",
    trakt_id: "65329",
    imdb_id: "tt1990352",
    tmdb_id: "86850",
    url: "https://trakt.tv/movies/the-island-president-2012",
    released: "2012-03-28",
    runtime: "101",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:45:20Z": {
    type: "movie",
    title: "Irreversible",
    year: "2002",
    trakt_rating: "7.13506",
    trakt_id: "809",
    imdb_id: "tt0290673",
    tmdb_id: "979",
    url: "https://trakt.tv/movies/irreversible-2002",
    released: "2002-05-22",
    runtime: "98",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:45:14Z": {
    type: "movie",
    title: "The Iron Giant",
    year: "1999",
    trakt_rating: "8.18655",
    trakt_id: "5580",
    imdb_id: "tt0129167",
    tmdb_id: "10386",
    url: "https://trakt.tv/movies/the-iron-giant-1999",
    released: "1999-08-06",
    runtime: "86",
    genres: "animation,science-fiction,family,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:45:08Z": {
    type: "movie",
    title: "Ip Man",
    year: "2008",
    trakt_rating: "8.17736",
    trakt_id: "8923",
    imdb_id: "tt1220719",
    tmdb_id: "14756",
    url: "https://trakt.tv/movies/ip-man-2008",
    released: "2008-12-12",
    runtime: "106",
    genres: "action,drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:44:13Z": {
    type: "movie",
    title: "Inside Man",
    year: "2006",
    trakt_rating: "7.74412",
    trakt_id: "308",
    imdb_id: "tt0454848",
    tmdb_id: "388",
    url: "https://trakt.tv/movies/inside-man-2006",
    released: "2006-03-24",
    runtime: "129",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:44:07Z": {
    type: "movie",
    title: "Inside Llewyn Davis",
    year: "2013",
    trakt_rating: "7.28743",
    trakt_id: "65314",
    imdb_id: "tt2042568",
    tmdb_id: "86829",
    url: "https://trakt.tv/movies/inside-llewyn-davis-2013",
    released: "2013-12-06",
    runtime: "104",
    genres: "drama,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:44:01Z": {
    type: "movie",
    title: "Inside Job",
    year: "2010",
    trakt_rating: "8.00218",
    trakt_id: "30322",
    imdb_id: "tt1645089",
    tmdb_id: "44639",
    url: "https://trakt.tv/movies/inside-job-2010",
    released: "2010-10-08",
    runtime: "109",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:43:55Z": {
    type: "movie",
    title: "Inside",
    year: "2007",
    trakt_rating: "6.83979",
    trakt_id: "7814",
    imdb_id: "tt0856288",
    tmdb_id: "13312",
    url: "https://trakt.tv/movies/inside-2007",
    released: "2007-06-13",
    runtime: "83",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:43:41Z": {
    type: "movie",
    title: "The Infiltrator",
    year: "2016",
    trakt_rating: "7.50168",
    trakt_id: "214309",
    imdb_id: "tt1355631",
    tmdb_id: "325789",
    url: "https://trakt.tv/movies/the-infiltrator-2016",
    released: "2016-07-13",
    runtime: "127",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:43:24Z": {
    type: "movie",
    title: "Incendies",
    year: "2010",
    trakt_rating: "8.27419",
    trakt_id: "32118",
    imdb_id: "tt1255953",
    tmdb_id: "46738",
    url: "https://trakt.tv/movies/incendies-2010",
    released: "2010-09-17",
    runtime: "131",
    genres: "drama,mystery,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:42:53Z": {
    type: "movie",
    title: "In Darkness",
    year: "2011",
    trakt_rating: "7.42601",
    trakt_id: "54162",
    imdb_id: "tt1417075",
    tmdb_id: "73686",
    url: "https://trakt.tv/movies/in-darkness-2011",
    released: "2012-02-17",
    runtime: "145",
    genres: "drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:42:24Z": {
    type: "movie",
    title: "The Imposter",
    year: "2012",
    trakt_rating: "7.38376",
    trakt_id: "63169",
    imdb_id: "tt1966604",
    tmdb_id: "84287",
    url: "https://trakt.tv/movies/the-imposter-2012",
    released: "2012-08-24",
    runtime: "99",
    genres: "documentary,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:42:05Z": {
    type: "movie",
    title: "Ice Age: Collision Course",
    year: "2016",
    trakt_rating: "6.99195",
    trakt_id: "175781",
    imdb_id: "tt3416828",
    tmdb_id: "278154",
    url: "https://trakt.tv/movies/ice-age-collision-course-2016",
    released: "2016-07-22",
    runtime: "95",
    genres: "animation,adventure,family,comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T12:41:56Z": {
    type: "movie",
    title: "I Origins",
    year: "2014",
    trakt_rating: "7.37023",
    trakt_id: "148745",
    imdb_id: "tt2884206",
    tmdb_id: "244267",
    url: "https://trakt.tv/movies/i-origins-2014",
    released: "2014-07-18",
    runtime: "106",
    genres: "drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:36:09Z": {
    type: "movie",
    title: "I Am the Pretty Thing That Lives in the House",
    year: "2016",
    trakt_rating: "4.88759",
    trakt_id: "253420",
    imdb_id: "tt5059406",
    tmdb_id: "407559",
    url: "https://trakt.tv/movies/i-am-the-pretty-thing-that-lives-in-the-house-2016",
    released: "2016-10-28",
    runtime: "89",
    genres: "horror,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:36:00Z": {
    type: "movie",
    title: "I Am Not a Serial Killer",
    year: "2016",
    trakt_rating: "6.66113",
    trakt_id: "247517",
    imdb_id: "tt4303340",
    tmdb_id: "381034",
    url: "https://trakt.tv/movies/i-am-not-a-serial-killer-2016",
    released: "2017-02-20",
    runtime: "104",
    genres: "thriller,horror,mystery,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:35:53Z": {
    type: "movie",
    title: "The Hunt for Red October",
    year: "1990",
    trakt_rating: "7.97813",
    trakt_id: "1111",
    imdb_id: "tt0099810",
    tmdb_id: "1669",
    url: "https://trakt.tv/movies/the-hunt-for-red-october-1990",
    released: "1990-03-02",
    runtime: "135",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:35:39Z": {
    type: "movie",
    title: "Hugo",
    year: "2011",
    trakt_rating: "7.40488",
    trakt_id: "30474",
    imdb_id: "tt0970179",
    tmdb_id: "44826",
    url: "https://trakt.tv/movies/hugo-2011",
    released: "2011-11-23",
    runtime: "126",
    genres: "adventure,drama,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:35:23Z": {
    type: "movie",
    title: "Hope Springs",
    year: "2012",
    trakt_rating: "6.70994",
    trakt_id: "61869",
    imdb_id: "tt1535438",
    tmdb_id: "82696",
    url: "https://trakt.tv/movies/hope-springs-2012",
    released: "2012-08-07",
    runtime: "100",
    genres: "comedy,drama,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:35:17Z": {
    type: "movie",
    title: "Holy Motors",
    year: "2012",
    trakt_rating: "7.00091",
    trakt_id: "73815",
    imdb_id: "tt2076220",
    tmdb_id: "103328",
    url: "https://trakt.tv/movies/holy-motors-2012",
    released: "2012-07-02",
    runtime: "115",
    genres: "drama,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:35:08Z": {
    type: "movie",
    title: "The Hobbit: The Desolation of Smaug",
    year: "2013",
    trakt_rating: "7.8507",
    trakt_id: "40808",
    imdb_id: "tt1170358",
    tmdb_id: "57158",
    url: "https://trakt.tv/movies/the-hobbit-the-desolation-of-smaug-2013",
    released: "2013-12-13",
    runtime: "161",
    genres: "fantasy,adventure,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:34:42Z": {
    type: "movie",
    title: "Hitman: Agent 47",
    year: "2015",
    trakt_rating: "6.57516",
    trakt_id: "151163",
    imdb_id: "tt2679042",
    tmdb_id: "249070",
    url: "https://trakt.tv/movies/hitman-agent-47-2015",
    released: "2015-08-21",
    runtime: "96",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:34:05Z": {
    type: "movie",
    title: "Hitler's Children",
    year: "2011",
    trakt_rating: "8.04545",
    trakt_id: "79537",
    imdb_id: "tt2359085",
    tmdb_id: "113362",
    url: "https://trakt.tv/movies/hitler-s-children-2011",
    released: "2011-11-21",
    runtime: "83",
    genres: "documentary,history,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:33:36Z": {
    type: "movie",
    title: "Henry: Portrait of a Serial Killer",
    year: "1986",
    trakt_rating: "6.87413",
    trakt_id: "5863",
    imdb_id: "tt0099763",
    tmdb_id: "10692",
    url: "https://trakt.tv/movies/henry-portrait-of-a-serial-killer-1986",
    released: "1986-09-24",
    runtime: "83",
    genres: "crime,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:33:28Z": {
    type: "movie",
    title: "Hellraiser",
    year: "1987",
    trakt_rating: "7.07958",
    trakt_id: "4463",
    imdb_id: "tt0093177",
    tmdb_id: "9003",
    url: "https://trakt.tv/movies/hellraiser-1987",
    released: "1987-09-11",
    runtime: "93",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:29:18Z": {
    type: "movie",
    title: "Headhunters",
    year: "2011",
    trakt_rating: "7.62888",
    trakt_id: "51768",
    imdb_id: "tt1614989",
    tmdb_id: "70670",
    url: "https://trakt.tv/movies/headhunters-2011",
    released: "2012-03-15",
    runtime: "100",
    genres: "crime,thriller,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:29:04Z": {
    type: "movie",
    title: "Haywire",
    year: "2011",
    trakt_rating: "6.07403",
    trakt_id: "51562",
    imdb_id: "tt1506999",
    tmdb_id: "70435",
    url: "https://trakt.tv/movies/haywire-2011",
    released: "2011-11-06",
    runtime: "93",
    genres: "thriller,action,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:28:51Z": {
    type: "movie",
    title: "The Harvest",
    year: "2015",
    trakt_rating: "6.56015",
    trakt_id: "139966",
    imdb_id: "tt2543336",
    tmdb_id: "229182",
    url: "https://trakt.tv/movies/the-harvest-2015-04-10",
    released: "2015-04-10",
    runtime: "104",
    genres: "thriller,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:28:43Z": {
    type: "movie",
    title: "Hardcore Henry",
    year: "2015",
    trakt_rating: "6.68122",
    trakt_id: "221309",
    imdb_id: "tt3072482",
    tmdb_id: "325348",
    url: "https://trakt.tv/movies/hardcore-henry-2015",
    released: "2016-04-06",
    runtime: "96",
    genres: "science-fiction,action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:28:36Z": {
    type: "movie",
    title: "A Hard Day",
    year: "2014",
    trakt_rating: "7.3357",
    trakt_id: "167706",
    imdb_id: "tt3697626",
    tmdb_id: "269494",
    url: "https://trakt.tv/movies/a-hard-day-2014",
    released: "2014-05-29",
    runtime: "111",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:28:23Z": {
    type: "movie",
    title: "Hannibal Rising",
    year: "2007",
    trakt_rating: "6.6174",
    trakt_id: "864",
    imdb_id: "tt0367959",
    tmdb_id: "1248",
    url: "https://trakt.tv/movies/hannibal-rising-2007",
    released: "2007-02-06",
    runtime: "121",
    genres: "crime,drama,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:28:07Z": {
    type: "movie",
    title: "The Hallow",
    year: "2015",
    trakt_rating: "6.01354",
    trakt_id: "192416",
    imdb_id: "tt2474976",
    tmdb_id: "312831",
    url: "https://trakt.tv/movies/the-hallow-2015",
    released: "2015-11-13",
    runtime: "97",
    genres: "horror,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:28:03Z": {
    type: "movie",
    title: "Hail, Caesar!",
    year: "2016",
    trakt_rating: "6.24901",
    trakt_id: "168510",
    imdb_id: "tt0475290",
    tmdb_id: "270487",
    url: "https://trakt.tv/movies/hail-caesar-2016",
    released: "2016-02-05",
    runtime: "106",
    genres: "comedy,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:27:29Z": {
    type: "movie",
    title: "Groove",
    year: "2000",
    trakt_rating: "6.70588",
    trakt_id: "14241",
    imdb_id: "tt0212974",
    tmdb_id: "23655",
    url: "https://trakt.tv/movies/groove-2000",
    released: "2000-06-08",
    runtime: "86",
    genres: "drama,music,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:27:22Z": {
    type: "movie",
    title: "Gremlins",
    year: "1984",
    trakt_rating: "7.5747",
    trakt_id: "769",
    imdb_id: "tt0087363",
    tmdb_id: "927",
    url: "https://trakt.tv/movies/gremlins-1984",
    released: "1984-06-07",
    runtime: "106",
    genres: "comedy,fantasy,horror,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:25:53Z": {
    type: "movie",
    title: "The God Who Wasn't There",
    year: "2005",
    trakt_rating: "7.09278",
    trakt_id: "7584",
    imdb_id: "tt0455507",
    tmdb_id: "13014",
    url: "https://trakt.tv/movies/the-god-who-wasn-t-there-2005",
    released: "2005-05-21",
    runtime: "62",
    genres: "documentary,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:25:47Z": {
    type: "movie",
    title: "Glengarry Glen Ross",
    year: "1992",
    trakt_rating: "7.50614",
    trakt_id: "4785",
    imdb_id: "tt0104348",
    tmdb_id: "9504",
    url: "https://trakt.tv/movies/glengarry-glen-ross-1992",
    released: "1992-09-28",
    runtime: "100",
    genres: "crime,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:25:40Z": {
    type: "movie",
    title: "Gladiator",
    year: "2000",
    trakt_rating: "8.50977",
    trakt_id: "67",
    imdb_id: "tt0172495",
    tmdb_id: "98",
    url: "https://trakt.tv/movies/gladiator-2000",
    released: "2000-05-05",
    runtime: "155",
    genres: "action,adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:25:25Z": {
    type: "movie",
    title: "A Girl Walks Home Alone at Night",
    year: "2014",
    trakt_rating: "7.09275",
    trakt_id: "152884",
    imdb_id: "tt2326554",
    tmdb_id: "252171",
    url: "https://trakt.tv/movies/a-girl-walks-home-alone-at-night-2014",
    released: "2014-11-21",
    runtime: "101",
    genres: "horror,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:25:09Z": {
    type: "movie",
    title: "Ghost in the Shell",
    year: "2017",
    trakt_rating: "6.86478",
    trakt_id: "196549",
    imdb_id: "tt1219827",
    tmdb_id: "315837",
    url: "https://trakt.tv/movies/ghost-in-the-shell-2017",
    released: "2017-03-31",
    runtime: "107",
    genres: "science-fiction,action,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:24:54Z": {
    type: "movie",
    title: "Get Out",
    year: "2017",
    trakt_rating: "7.78191",
    trakt_id: "264623",
    imdb_id: "tt5052448",
    tmdb_id: "419430",
    url: "https://trakt.tv/movies/get-out-2017",
    released: "2017-02-24",
    runtime: "104",
    genres: "thriller,mystery,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:24:45Z": {
    type: "movie",
    title: "Full Metal Jacket",
    year: "1987",
    trakt_rating: "8.29554",
    trakt_id: "478",
    imdb_id: "tt0093058",
    tmdb_id: "600",
    url: "https://trakt.tv/movies/full-metal-jacket-1987",
    released: "1987-06-26",
    runtime: "117",
    genres: "drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:24:25Z": {
    type: "movie",
    title: "Free Fire",
    year: "2017",
    trakt_rating: "6.59981",
    trakt_id: "215401",
    imdb_id: "tt4158096",
    tmdb_id: "334521",
    url: "https://trakt.tv/movies/free-fire-2017",
    released: "2017-03-31",
    runtime: "90",
    genres: "action,crime,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:24:20Z": {
    type: "movie",
    title: "Frankenweenie",
    year: "2012",
    trakt_rating: "7.147",
    trakt_id: "44681",
    imdb_id: "tt1142977",
    tmdb_id: "62214",
    url: "https://trakt.tv/movies/frankenweenie-2012",
    released: "2012-10-05",
    runtime: "87",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:24:15Z": {
    type: "movie",
    title: "The Forbidden Room",
    year: "2015",
    trakt_rating: "6.28947",
    trakt_id: "196570",
    imdb_id: "tt3066630",
    tmdb_id: "315855",
    url: "https://trakt.tv/movies/the-forbidden-room-2015",
    released: "2015-10-09",
    runtime: "128",
    genres: "comedy,drama,mystery,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:24:09Z": {
    type: "movie",
    title: "For the Love of Spock",
    year: "2016",
    trakt_rating: "7.96835",
    trakt_id: "219064",
    imdb_id: "tt4572820",
    tmdb_id: "338544",
    url: "https://trakt.tv/movies/for-the-love-of-spock-2016",
    released: "2016-09-09",
    runtime: "105",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:22:41Z": {
    type: "movie",
    title: "Footnote",
    year: "2011",
    trakt_rating: "7.15854",
    trakt_id: "53288",
    imdb_id: "tt1445520",
    tmdb_id: "72551",
    url: "https://trakt.tv/movies/footnote-2011",
    released: "2011-06-02",
    runtime: "102",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:22:33Z": {
    type: "movie",
    title: "Flypaper",
    year: "2011",
    trakt_rating: "6.84511",
    trakt_id: "44558",
    imdb_id: "tt1541160",
    tmdb_id: "62046",
    url: "https://trakt.tv/movies/flypaper-2011",
    released: "2011-08-19",
    runtime: "87",
    genres: "comedy,crime,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:22:19Z": {
    type: "movie",
    title: "Flight from Death: The Quest for Immortality",
    year: "2003",
    trakt_rating: "5.5",
    trakt_id: "104143",
    imdb_id: "tt0365215",
    tmdb_id: "161277",
    url: "https://trakt.tv/movies/flight-from-death-the-quest-for-immortality-2003",
    released: "2003-01-01",
    runtime: "90",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:22:08Z": {
    type: "movie",
    title: "Finding Nemo",
    year: "2003",
    trakt_rating: "8.15456",
    trakt_id: "8",
    imdb_id: "tt0266543",
    tmdb_id: "12",
    url: "https://trakt.tv/movies/finding-nemo-2003",
    released: "2003-05-30",
    runtime: "100",
    genres: "animation,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:21:55Z": {
    type: "movie",
    title: "Fight Club",
    year: "1999",
    trakt_rating: "8.76962",
    trakt_id: "432",
    imdb_id: "tt0137523",
    tmdb_id: "550",
    url: "https://trakt.tv/movies/fight-club-1999",
    released: "1999-10-15",
    runtime: "139",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:21:41Z": {
    type: "movie",
    title: "The Evil Dead",
    year: "1981",
    trakt_rating: "7.35252",
    trakt_id: "607",
    imdb_id: "tt0083907",
    tmdb_id: "764",
    url: "https://trakt.tv/movies/the-evil-dead-1981",
    released: "1983-04-15",
    runtime: "85",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:21:25Z": {
    type: "movie",
    title: "Everest",
    year: "2015",
    trakt_rating: "7.18356",
    trakt_id: "153969",
    imdb_id: "tt2719848",
    tmdb_id: "253412",
    url: "https://trakt.tv/movies/everest-2015",
    released: "2015-09-18",
    runtime: "121",
    genres: "drama,adventure,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:21:17Z": {
    type: "movie",
    title: "The Equalizer",
    year: "2014",
    trakt_rating: "7.64839",
    trakt_id: "101542",
    imdb_id: "tt0455944",
    tmdb_id: "156022",
    url: "https://trakt.tv/movies/the-equalizer-2014",
    released: "2014-09-26",
    runtime: "132",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:19:58Z": {
    type: "movie",
    title: "Faults",
    year: "2014",
    trakt_rating: "6.90361",
    trakt_id: "148301",
    imdb_id: "tt3021360",
    tmdb_id: "243526",
    url: "https://trakt.tv/movies/faults-2014",
    released: "2014-03-06",
    runtime: "90",
    genres: "drama,comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:19:48Z": {
    type: "movie",
    title: "Farewell, My Queen",
    year: "2012",
    trakt_rating: "6.31416",
    trakt_id: "71777",
    imdb_id: "tt1753813",
    tmdb_id: "99579",
    url: "https://trakt.tv/movies/farewell-my-queen-2012",
    released: "2012-03-21",
    runtime: "100",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:19:41Z": {
    type: "movie",
    title: "The Fall",
    year: "2006",
    trakt_rating: "7.90151",
    trakt_id: "8941",
    imdb_id: "tt0460791",
    tmdb_id: "14784",
    url: "https://trakt.tv/movies/the-fall-2006",
    released: "2008-05-30",
    runtime: "117",
    genres: "adventure,drama,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:19:32Z": {
    type: "movie",
    title: "Eye in the Sky",
    year: "2015",
    trakt_rating: "7.48719",
    trakt_id: "214292",
    imdb_id: "tt2057392",
    tmdb_id: "333352",
    url: "https://trakt.tv/movies/eye-in-the-sky-2015",
    released: "2016-04-15",
    runtime: "102",
    genres: "drama,thriller,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:19:17Z": {
    type: "movie",
    title: "The Empire Strikes Back",
    year: "1980",
    trakt_rating: "8.68321",
    trakt_id: "1266",
    imdb_id: "tt0080684",
    tmdb_id: "1891",
    url: "https://trakt.tv/movies/the-empire-strikes-back-1980",
    released: "1980-05-21",
    runtime: "124",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:19:10Z": {
    type: "movie",
    title: "Emperor",
    year: "2012",
    trakt_rating: "6.74674",
    trakt_id: "87249",
    imdb_id: "tt2103264",
    tmdb_id: "127372",
    url: "https://trakt.tv/movies/emperor-2012",
    released: "2013-03-08",
    runtime: "105",
    genres: "drama,history,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:19:03Z": {
    type: "movie",
    title: "Elite Squad: The Enemy Within",
    year: "2010",
    trakt_rating: "8.18274",
    trakt_id: "33177",
    imdb_id: "tt1555149",
    tmdb_id: "47931",
    url: "https://trakt.tv/movies/elite-squad-the-enemy-within-2010",
    released: "2010-10-08",
    runtime: "115",
    genres: "action,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:18:59Z": {
    type: "movie",
    title: "Edwin Boyd: Citizen Gangster",
    year: "2011",
    trakt_rating: "6.37383",
    trakt_id: "68349",
    imdb_id: "tt1709654",
    tmdb_id: "92493",
    url: "https://trakt.tv/movies/edwin-boyd-citizen-gangster-2011",
    released: "2011-09-10",
    runtime: "105",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:17:53Z": {
    type: "movie",
    title: "Eden",
    year: "2012",
    trakt_rating: "7.08906",
    trakt_id: "70372",
    imdb_id: "tt1734433",
    tmdb_id: "96599",
    url: "https://trakt.tv/movies/eden-2012",
    released: "2012-03-11",
    runtime: "98",
    genres: "drama,thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:16:51Z": {
    type: "movie",
    title: "Disorder",
    year: "2015",
    trakt_rating: "6.24521",
    trakt_id: "210753",
    imdb_id: "tt4085084",
    tmdb_id: "329815",
    url: "https://trakt.tv/movies/disorder-2015",
    released: "2015-09-30",
    runtime: "100",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:16:42Z": {
    type: "movie",
    title: "The Dictator",
    year: "2012",
    trakt_rating: "6.56266",
    trakt_id: "56495",
    imdb_id: "tt1645170",
    tmdb_id: "76493",
    url: "https://trakt.tv/movies/the-dictator-2012",
    released: "2012-05-16",
    runtime: "83",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:16:35Z": {
    type: "movie",
    title: "The Devil's Rejects",
    year: "2005",
    trakt_rating: "7.08219",
    trakt_id: "1133",
    imdb_id: "tt0395584",
    tmdb_id: "1696",
    url: "https://trakt.tv/movies/the-devil-s-rejects-2005",
    released: "2005-07-22",
    runtime: "107",
    genres: "horror,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:16:32Z": {
    type: "movie",
    title: "Detention",
    year: "2011",
    trakt_rating: "6.2381",
    trakt_id: "50104",
    imdb_id: "tt1701990",
    tmdb_id: "68684",
    url: "https://trakt.tv/movies/detention-2011",
    released: "2011-03-16",
    runtime: "93",
    genres: "comedy,horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:14:48Z": {
    type: "movie",
    title: "Dope",
    year: "2015",
    trakt_rating: "7.43216",
    trakt_id: "195280",
    imdb_id: "tt3850214",
    tmdb_id: "308639",
    url: "https://trakt.tv/movies/dope-2015",
    released: "2015-06-19",
    runtime: "103",
    genres: "comedy,drama,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:14:28Z": {
    type: "movie",
    title: "The Diving Bell and the Butterfly",
    year: "2007",
    trakt_rating: "7.83655",
    trakt_id: "1374",
    imdb_id: "tt0401383",
    tmdb_id: "2013",
    url: "https://trakt.tv/movies/the-diving-bell-and-the-butterfly-2007",
    released: "2007-11-30",
    runtime: "112",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:14:23Z": {
    type: "movie",
    title: "Deliverance",
    year: "1972",
    trakt_rating: "7.53715",
    trakt_id: "5841",
    imdb_id: "tt0068473",
    tmdb_id: "10669",
    url: "https://trakt.tv/movies/deliverance-1972",
    released: "1972-08-18",
    runtime: "109",
    genres: "adventure,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:14:18Z": {
    type: "movie",
    title: "The Deer Hunter",
    year: "1978",
    trakt_rating: "7.87664",
    trakt_id: "6808",
    imdb_id: "tt0077416",
    tmdb_id: "11778",
    url: "https://trakt.tv/movies/the-deer-hunter-1978",
    released: "1978-12-08",
    runtime: "183",
    genres: "drama,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:12:04Z": {
    type: "movie",
    title: "The Dark Tapes",
    year: "2017",
    trakt_rating: "5.78959",
    trakt_id: "233075",
    imdb_id: "tt5068650",
    tmdb_id: "390883",
    url: "https://trakt.tv/movies/the-dark-tapes-2017",
    released: "2017-04-17",
    runtime: "98",
    genres: "thriller,horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:11:49Z": {
    type: "movie",
    title: "A Dangerous Method",
    year: "2011",
    trakt_rating: "6.51153",
    trakt_id: "33369",
    imdb_id: "tt1571222",
    tmdb_id: "48231",
    url: "https://trakt.tv/movies/a-dangerous-method-2011",
    released: "2011-09-30",
    runtime: "99",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:11:39Z": {
    type: "movie",
    title: "Concussion",
    year: "2015",
    trakt_rating: "7.48881",
    trakt_id: "202681",
    imdb_id: "tt3322364",
    tmdb_id: "321741",
    url: "https://trakt.tv/movies/concussion-2015",
    released: "2015-12-25",
    runtime: "123",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:11:33Z": {
    type: "movie",
    title: "The Company You Keep",
    year: "2012",
    trakt_rating: "6.83781",
    trakt_id: "65750",
    imdb_id: "tt1381404",
    tmdb_id: "87496",
    url: "https://trakt.tv/movies/the-company-you-keep-2012",
    released: "2013-04-04",
    runtime: "121",
    genres: "thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:11:30Z": {
    type: "movie",
    title: "The Company Men",
    year: "2010",
    trakt_rating: "6.98806",
    trakt_id: "29863",
    imdb_id: "tt1172991",
    tmdb_id: "44129",
    url: "https://trakt.tv/movies/the-company-men-2010",
    released: "2010-10-21",
    runtime: "104",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:11:25Z": {
    type: "movie",
    title: "Comet",
    year: "2014",
    trakt_rating: "7.03043",
    trakt_id: "174600",
    imdb_id: "tt2965412",
    tmdb_id: "276908",
    url: "https://trakt.tv/movies/comet-2014",
    released: "2014-12-05",
    runtime: "91",
    genres: "comedy,science-fiction,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:09:11Z": {
    type: "movie",
    title: "Cloud Atlas",
    year: "2012",
    trakt_rating: "7.29346",
    trakt_id: "62551",
    imdb_id: "tt1371111",
    tmdb_id: "83542",
    url: "https://trakt.tv/movies/cloud-atlas-2012",
    released: "2012-10-26",
    runtime: "172",
    genres: "drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:09:01Z": {
    type: "movie",
    title: "A Clockwork Orange",
    year: "1971",
    trakt_rating: "8.06309",
    trakt_id: "149",
    imdb_id: "tt0066921",
    tmdb_id: "185",
    url: "https://trakt.tv/movies/a-clockwork-orange-1971",
    released: "1971-12-19",
    runtime: "137",
    genres: "drama,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:08:55Z": {
    type: "movie",
    title: "Cleanskin",
    year: "2012",
    trakt_rating: "6.40857",
    trakt_id: "69798",
    imdb_id: "tt1598873",
    tmdb_id: "95516",
    url: "https://trakt.tv/movies/cleanskin-2012",
    released: "2012-03-09",
    runtime: "108",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:08:45Z": {
    type: "movie",
    title: "Clash of the Titans",
    year: "2010",
    trakt_rating: "6.25032",
    trakt_id: "11558",
    imdb_id: "tt0800320",
    tmdb_id: "18823",
    url: "https://trakt.tv/movies/clash-of-the-titans-2010",
    released: "2010-04-02",
    runtime: "106",
    genres: "action,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:08:25Z": {
    type: "movie",
    title: "Chronicle",
    year: "2012",
    trakt_rating: "7.22766",
    trakt_id: "56686",
    imdb_id: "tt1706593",
    tmdb_id: "76726",
    url: "https://trakt.tv/movies/chronicle-2012",
    released: "2012-02-02",
    runtime: "84",
    genres: "drama,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:08:19Z": {
    type: "movie",
    title: "Children of Men",
    year: "2006",
    trakt_rating: "7.89483",
    trakt_id: "4965",
    imdb_id: "tt0206634",
    tmdb_id: "9693",
    url: "https://trakt.tv/movies/children-of-men-2006",
    released: "2006-12-25",
    runtime: "109",
    genres: "action,drama,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:08:13Z": {
    type: "movie",
    title: "The Childhood of a Leader",
    year: "2016",
    trakt_rating: "6.36667",
    trakt_id: "211875",
    imdb_id: "tt2815902",
    tmdb_id: "330982",
    url: "https://trakt.tv/movies/the-childhood-of-a-leader-2016",
    released: "2016-07-22",
    runtime: "115",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:08:06Z": {
    type: "movie",
    title: "Chappie",
    year: "2015",
    trakt_rating: "7.14609",
    trakt_id: "123707",
    imdb_id: "tt1823672",
    tmdb_id: "198184",
    url: "https://trakt.tv/movies/chappie-2015",
    released: "2015-03-06",
    runtime: "120",
    genres: "science-fiction,action,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:08:01Z": {
    type: "movie",
    title: "Cave of Forgotten Dreams",
    year: "2010",
    trakt_rating: "7.37255",
    trakt_id: "42639",
    imdb_id: "tt1664894",
    tmdb_id: "59490",
    url: "https://trakt.tv/movies/cave-of-forgotten-dreams-2010",
    released: "2010-11-03",
    runtime: "90",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:07:56Z": {
    type: "movie",
    title: "Bronson",
    year: "2008",
    trakt_rating: "7.09872",
    trakt_id: "11360",
    imdb_id: "tt1172570",
    tmdb_id: "18533",
    url: "https://trakt.tv/movies/bronson-2008",
    released: "2008-10-09",
    runtime: "92",
    genres: "action,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:07:39Z": {
    type: "movie",
    title: "Brazil",
    year: "1985",
    trakt_rating: "7.87964",
    trakt_id: "39",
    imdb_id: "tt0088846",
    tmdb_id: "68",
    url: "https://trakt.tv/movies/brazil-1985",
    released: "1985-12-18",
    runtime: "143",
    genres: "comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:07:33Z": {
    type: "movie",
    title: "Braveheart",
    year: "1995",
    trakt_rating: "8.26539",
    trakt_id: "160",
    imdb_id: "tt0112573",
    tmdb_id: "197",
    url: "https://trakt.tv/movies/braveheart-1995",
    released: "1995-05-24",
    runtime: "178",
    genres: "action,drama,history,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:07:27Z": {
    type: "movie",
    title: "Brave",
    year: "2012",
    trakt_rating: "7.48772",
    trakt_id: "44653",
    imdb_id: "tt1217209",
    tmdb_id: "62177",
    url: "https://trakt.tv/movies/brave-2012",
    released: "2012-06-22",
    runtime: "93",
    genres: "action,adventure,animation,comedy,fantasy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:07:21Z": {
    type: "movie",
    title: "The Boy Next Door",
    year: "2015",
    trakt_rating: "5.76352",
    trakt_id: "147087",
    imdb_id: "tt3181822",
    tmdb_id: "241251",
    url: "https://trakt.tv/movies/the-boy-next-door-2015",
    released: "2015-01-23",
    runtime: "91",
    genres: "thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:07:11Z": {
    type: "movie",
    title: "Cargo",
    year: "2009",
    trakt_rating: "6.20626",
    trakt_id: "21807",
    imdb_id: "tt0381940",
    tmdb_id: "34069",
    url: "https://trakt.tv/movies/cargo-2009",
    released: "2009-09-24",
    runtime: "120",
    genres: "mystery,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:06:55Z": {
    type: "movie",
    title: "Capitalism: A Love Story",
    year: "2009",
    trakt_rating: "7.19591",
    trakt_id: "13475",
    imdb_id: "tt1232207",
    tmdb_id: "22074",
    url: "https://trakt.tv/movies/capitalism-a-love-story-2009",
    released: "2009-10-02",
    runtime: "128",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:06:52Z": {
    type: "movie",
    title: "Bully",
    year: "2011",
    trakt_rating: "7.24229",
    trakt_id: "63278",
    imdb_id: "tt1682181",
    tmdb_id: "84404",
    url: "https://trakt.tv/movies/bully-2011",
    released: "2011-04-23",
    runtime: "92",
    genres: "documentary,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:06:24Z": {
    type: "movie",
    title: "The Bourne Legacy",
    year: "2012",
    trakt_rating: "6.91901",
    trakt_id: "34086",
    imdb_id: "tt1194173",
    tmdb_id: "49040",
    url: "https://trakt.tv/movies/the-bourne-legacy-2012",
    released: "2012-08-10",
    runtime: "135",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:06:15Z": {
    type: "movie",
    title: "Bound",
    year: "1996",
    trakt_rating: "7.3049",
    trakt_id: "4603",
    imdb_id: "tt0115736",
    tmdb_id: "9303",
    url: "https://trakt.tv/movies/bound-1996",
    released: "1996-10-04",
    runtime: "105",
    genres: "thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:05:34Z": {
    type: "movie",
    title: "Blade Runner",
    year: "1982",
    trakt_rating: "8.05519",
    trakt_id: "48",
    imdb_id: "tt0083658",
    tmdb_id: "78",
    url: "https://trakt.tv/movies/blade-runner-1982",
    released: "1982-06-25",
    runtime: "118",
    genres: "drama,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:05:28Z": {
    type: "movie",
    title: "Blackhat",
    year: "2015",
    trakt_rating: "5.97101",
    trakt_id: "125408",
    imdb_id: "tt2717822",
    tmdb_id: "201088",
    url: "https://trakt.tv/movies/blackhat-2015",
    released: "2015-01-16",
    runtime: "133",
    genres: "crime,thriller,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:05:21Z": {
    type: "movie",
    title: "Blackfish",
    year: "2013",
    trakt_rating: "8.03102",
    trakt_id: "102987",
    imdb_id: "tt2545118",
    tmdb_id: "158999",
    url: "https://trakt.tv/movies/blackfish-2013",
    released: "2013-07-19",
    runtime: "83",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:05:14Z": {
    type: "movie",
    title: "The Blackcoat's Daughter",
    year: "2017",
    trakt_rating: "6.05989",
    trakt_id: "221106",
    imdb_id: "tt3286052",
    tmdb_id: "334536",
    url: "https://trakt.tv/movies/the-blackcoat-s-daughter-2017",
    released: "2017-02-16",
    runtime: "93",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:05:13Z": {
    type: "movie",
    title: "Black Rock",
    year: "2012",
    trakt_rating: "5.39943",
    trakt_id: "63076",
    imdb_id: "tt1930294",
    tmdb_id: "84178",
    url: "https://trakt.tv/movies/black-rock-2012",
    released: "2012-01-21",
    runtime: "79",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:04:57Z": {
    type: "movie",
    title: "Biutiful",
    year: "2010",
    trakt_rating: "7.38848",
    trakt_id: "31435",
    imdb_id: "tt1164999",
    tmdb_id: "45958",
    url: "https://trakt.tv/movies/biutiful-2010",
    released: "2010-12-28",
    runtime: "148",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:04:52Z": {
    type: "movie",
    title: "Bindlestiffs",
    year: "2012",
    trakt_rating: "2.84211",
    trakt_id: "73946",
    imdb_id: "tt2091243",
    tmdb_id: "103609",
    url: "https://trakt.tv/movies/bindlestiffs-2012",
    released: "2012-01-22",
    runtime: "80",
    genres: "action,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:04:47Z": {
    type: "movie",
    title: "The Big Wedding",
    year: "2013",
    trakt_rating: "6.30557",
    trakt_id: "65788",
    imdb_id: "tt1931435",
    tmdb_id: "87567",
    url: "https://trakt.tv/movies/the-big-wedding-2013",
    released: "2013-04-25",
    runtime: "90",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:04:40Z": {
    type: "movie",
    title: "The Big Lebowski",
    year: "1998",
    trakt_rating: "8.07655",
    trakt_id: "84",
    imdb_id: "tt0118715",
    tmdb_id: "115",
    url: "https://trakt.tv/movies/the-big-lebowski-1998",
    released: "1998-03-06",
    runtime: "117",
    genres: "comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:04:29Z": {
    type: "movie",
    title: "The Best Exotic Marigold Hotel",
    year: "2012",
    trakt_rating: "7.50508",
    trakt_id: "54878",
    imdb_id: "tt1412386",
    tmdb_id: "74534",
    url: "https://trakt.tv/movies/the-best-exotic-marigold-hotel-2012",
    released: "2012-05-25",
    runtime: "124",
    genres: "comedy,drama,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:04:23Z": {
    type: "movie",
    title: "Being Flynn",
    year: "2012",
    trakt_rating: "6.34729",
    trakt_id: "58213",
    imdb_id: "tt0455323",
    tmdb_id: "78571",
    url: "https://trakt.tv/movies/being-flynn-2012",
    released: "2012-03-02",
    runtime: "102",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:04:10Z": {
    type: "movie",
    title: "Beautiful Creatures",
    year: "2013",
    trakt_rating: "6.50787",
    trakt_id: "77379",
    imdb_id: "tt1559547",
    tmdb_id: "109491",
    url: "https://trakt.tv/movies/beautiful-creatures-2013",
    released: "2013-02-14",
    runtime: "124",
    genres: "drama,fantasy,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:03:54Z": {
    type: "movie",
    title: "Barney's Version",
    year: "2010",
    trakt_rating: "7.08798",
    trakt_id: "32205",
    imdb_id: "tt1423894",
    tmdb_id: "46829",
    url: "https://trakt.tv/movies/barney-s-version-2010",
    released: "2011-01-14",
    runtime: "134",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:03:49Z": {
    type: "movie",
    title: "A Band Called Death",
    year: "2013",
    trakt_rating: "8.0",
    trakt_id: "92506",
    imdb_id: "tt2064713",
    tmdb_id: "137563",
    url: "https://trakt.tv/movies/a-band-called-death-2013",
    released: "2013-06-27",
    runtime: "96",
    genres: "documentary,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-22T02:03:42Z": {
    type: "movie",
    title: "Backcountry",
    year: "2015",
    trakt_rating: "6.32261",
    trakt_id: "139867",
    imdb_id: "tt2944198",
    tmdb_id: "228973",
    url: "https://trakt.tv/movies/backcountry-2015",
    released: "2015-08-28",
    runtime: "92",
    genres: "horror,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T11:02:32Z": {
    type: "movie",
    title: "The Babadook",
    year: "2014",
    trakt_rating: "6.76761",
    trakt_id: "147549",
    imdb_id: "tt2321549",
    tmdb_id: "242224",
    url: "https://trakt.tv/movies/the-babadook-2014",
    released: "2014-05-22",
    runtime: "94",
    genres: "horror,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:29:36Z": {
    type: "movie",
    title: "X-Men: Apocalypse",
    year: "2016",
    trakt_rating: "7.21351",
    trakt_id: "149999",
    imdb_id: "tt3385516",
    tmdb_id: "246655",
    url: "https://trakt.tv/movies/x-men-apocalypse-2016",
    released: "2016-05-27",
    runtime: "144",
    genres: "science-fiction,superhero,action,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:29:34Z": {
    type: "movie",
    title: "[REC]⁴ Apocalypse",
    year: "2014",
    trakt_rating: "5.73322",
    trakt_id: "116231",
    imdb_id: "tt1649443",
    tmdb_id: "185341",
    url: "https://trakt.tv/movies/rec-apocalypse-2014",
    released: "2014-10-31",
    runtime: "95",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:29:14Z": {
    type: "movie",
    title: "Apocalypse Now",
    year: "1979",
    trakt_rating: "8.23611",
    trakt_id: "23",
    imdb_id: "tt0078788",
    tmdb_id: "28",
    url: "https://trakt.tv/movies/apocalypse-now-1979",
    released: "1979-08-15",
    runtime: "147",
    genres: "war,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:28:58Z": {
    type: "movie",
    title: "Antiviral",
    year: "2012",
    trakt_rating: "6.05396",
    trakt_id: "86265",
    imdb_id: "tt2099556",
    tmdb_id: "125490",
    url: "https://trakt.tv/movies/antiviral-2012",
    released: "2012-09-10",
    runtime: "110",
    genres: "horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:28:43Z": {
    type: "movie",
    title: "Anthropoid",
    year: "2016",
    trakt_rating: "7.54357",
    trakt_id: "222071",
    imdb_id: "tt4190530",
    tmdb_id: "351339",
    url: "https://trakt.tv/movies/anthropoid-2016",
    released: "2016-08-12",
    runtime: "120",
    genres: "war,history,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:28:23Z": {
    type: "movie",
    title: "American History X",
    year: "1998",
    trakt_rating: "8.51066",
    trakt_id: "43",
    imdb_id: "tt0120586",
    tmdb_id: "73",
    url: "https://trakt.tv/movies/american-history-x-1998",
    released: "1998-10-30",
    runtime: "119",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:28:10Z": {
    type: "movie",
    title: "Anonymous",
    year: "2011",
    trakt_rating: "6.9846",
    trakt_id: "44425",
    imdb_id: "tt1521197",
    tmdb_id: "61891",
    url: "https://trakt.tv/movies/anonymous-2011",
    released: "2011-10-28",
    runtime: "130",
    genres: "drama,history,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:26:31Z": {
    type: "movie",
    title: "American Sniper",
    year: "2014",
    trakt_rating: "7.46021",
    trakt_id: "119348",
    imdb_id: "tt2179136",
    tmdb_id: "190859",
    url: "https://trakt.tv/movies/american-sniper-2014",
    released: "2014-12-25",
    runtime: "133",
    genres: "action,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:26:10Z": {
    type: "movie",
    title: "An American Werewolf in London",
    year: "1981",
    trakt_rating: "7.48596",
    trakt_id: "657",
    imdb_id: "tt0082010",
    tmdb_id: "814",
    url: "https://trakt.tv/movies/an-american-werewolf-in-london-1981",
    released: "1981-08-21",
    runtime: "97",
    genres: "horror,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:25:42Z": {
    type: "movie",
    title: "American Poltergeist",
    year: "2015",
    trakt_rating: "3.7971",
    trakt_id: "221627",
    imdb_id: "tt3108604",
    tmdb_id: "345931",
    url: "https://trakt.tv/movies/american-poltergeist-2015",
    released: "2015-10-06",
    runtime: "77",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:23:43Z": {
    type: "movie",
    title: "American Psycho",
    year: "2000",
    trakt_rating: "7.63789",
    trakt_id: "898",
    imdb_id: "tt0144084",
    tmdb_id: "1359",
    url: "https://trakt.tv/movies/american-psycho-2000",
    released: "2000-04-14",
    runtime: "102",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:22:56Z": {
    type: "movie",
    title: "Alien: Covenant",
    year: "2017",
    trakt_rating: "6.78655",
    trakt_id: "86997",
    imdb_id: "tt2316204",
    tmdb_id: "126889",
    url: "https://trakt.tv/movies/alien-covenant-2017",
    released: "2017-05-19",
    runtime: "122",
    genres: "science-fiction,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:22:51Z": {
    type: "movie",
    title: "Aliens",
    year: "1986",
    trakt_rating: "8.33094",
    trakt_id: "553",
    imdb_id: "tt0090605",
    tmdb_id: "679",
    url: "https://trakt.tv/movies/aliens-1986",
    released: "1986-07-18",
    runtime: "137",
    genres: "action,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:22:31Z": {
    type: "movie",
    title: "Against the Sun",
    year: "2014",
    trakt_rating: "6.80808",
    trakt_id: "193242",
    imdb_id: "tt2557276",
    tmdb_id: "302828",
    url: "https://trakt.tv/movies/against-the-sun-2014",
    released: "2014-11-22",
    runtime: "99",
    genres: "adventure,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:22:02Z": {
    type: "movie",
    title: "99 Homes",
    year: "2015",
    trakt_rating: "7.00142",
    trakt_id: "180535",
    imdb_id: "tt2891174",
    tmdb_id: "283235",
    url: "https://trakt.tv/movies/99-homes-2015",
    released: "2015-09-25",
    runtime: "112",
    genres: "drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:21:49Z": {
    type: "movie",
    title: "The 5th Wave",
    year: "2016",
    trakt_rating: "6.21266",
    trakt_id: "195238",
    imdb_id: "tt2304933",
    tmdb_id: "299687",
    url: "https://trakt.tv/movies/the-5th-wave-2016",
    released: "2016-01-15",
    runtime: "112",
    genres: "science-fiction,adventure,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:21:31Z": {
    type: "movie",
    title: "388 Arletta Avenue",
    year: "2011",
    trakt_rating: "5.61905",
    trakt_id: "54396",
    imdb_id: "tt1767272",
    tmdb_id: "73943",
    url: "https://trakt.tv/movies/388-arletta-avenue-2011",
    released: "2011-09-11",
    runtime: "87",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:21:14Z": {
    type: "movie",
    title: "300",
    year: "2006",
    trakt_rating: "7.71798",
    trakt_id: "884",
    imdb_id: "tt0416449",
    tmdb_id: "1271",
    url: "https://trakt.tv/movies/300-2006",
    released: "2007-03-09",
    runtime: "117",
    genres: "action,adventure,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:20:51Z": {
    type: "movie",
    title: "28 Weeks Later",
    year: "2007",
    trakt_rating: "7.05031",
    trakt_id: "1021",
    imdb_id: "tt0463854",
    tmdb_id: "1562",
    url: "https://trakt.tv/movies/28-weeks-later-2007",
    released: "2007-06-28",
    runtime: "100",
    genres: "horror,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:20:50Z": {
    type: "movie",
    title: "28 Days Later",
    year: "2002",
    trakt_rating: "7.63962",
    trakt_id: "135",
    imdb_id: "tt0289043",
    tmdb_id: "170",
    url: "https://trakt.tv/movies/28-days-later-2002",
    released: "2002-11-01",
    runtime: "113",
    genres: "horror,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:20:12Z": {
    type: "movie",
    title: "13 Tzameti",
    year: "2005",
    trakt_rating: "7.12442",
    trakt_id: "3472",
    imdb_id: "tt0475169",
    tmdb_id: "6077",
    url: "https://trakt.tv/movies/13-tzameti-2005",
    released: "2006-02-08",
    runtime: "93",
    genres: "action,thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T04:19:44Z": {
    type: "movie",
    title: "12 Years a Slave",
    year: "2013",
    trakt_rating: "8.10975",
    trakt_id: "56258",
    imdb_id: "tt2024544",
    tmdb_id: "76203",
    url: "https://trakt.tv/movies/12-years-a-slave-2013",
    released: "2013-10-18",
    runtime: "134",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-20T01:04:37Z": {
    type: "movie",
    title: "Ghostbusters",
    year: "1984",
    trakt_rating: "7.97418",
    trakt_id: "496",
    imdb_id: "tt0087332",
    tmdb_id: "620",
    url: "https://trakt.tv/movies/ghostbusters-1984",
    released: "1984-06-08",
    runtime: "107",
    genres: "comedy,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-19T14:34:44Z": {
    type: "movie",
    title: "Martyrs",
    year: "2008",
    trakt_rating: "7.18643",
    trakt_id: "4817",
    imdb_id: "tt1029234",
    tmdb_id: "9539",
    url: "https://trakt.tv/movies/martyrs-2008",
    released: "2008-09-10",
    runtime: "99",
    genres: "drama,horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-19T14:34:27Z": {
    type: "movie",
    title: "High Tension",
    year: "2003",
    trakt_rating: "6.95232",
    trakt_id: "5438",
    imdb_id: "tt0338095",
    tmdb_id: "10226",
    url: "https://trakt.tv/movies/high-tension-2003",
    released: "2003-06-18",
    runtime: "90",
    genres: "horror,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-19T14:34:18Z": {
    type: "movie",
    title: "Stake Land",
    year: "2010",
    trakt_rating: "6.65539",
    trakt_id: "36567",
    imdb_id: "tt1464580",
    tmdb_id: "52015",
    url: "https://trakt.tv/movies/stake-land-2010",
    released: "2010-10-01",
    runtime: "98",
    genres: "drama,horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-19T14:14:22Z": {
    type: "movie",
    title: "Blood Simple",
    year: "1984",
    trakt_rating: "7.41249",
    trakt_id: "6464",
    imdb_id: "tt0086979",
    tmdb_id: "11368",
    url: "https://trakt.tv/movies/blood-simple-1984",
    released: "1985-01-18",
    runtime: "97",
    genres: "drama,thriller,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-19T14:14:07Z": {
    type: "movie",
    title: "End of Watch",
    year: "2012",
    trakt_rating: "7.6683",
    trakt_id: "56931",
    imdb_id: "tt1855199",
    tmdb_id: "77016",
    url: "https://trakt.tv/movies/end-of-watch-2012",
    released: "2012-09-21",
    runtime: "109",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-18T01:51:59Z": {
    type: "movie",
    title: "Dawn of the Dead",
    year: "1978",
    trakt_rating: "7.65192",
    trakt_id: "765",
    imdb_id: "tt0077402",
    tmdb_id: "923",
    url: "https://trakt.tv/movies/dawn-of-the-dead-1978",
    released: "1979-04-20",
    runtime: "127",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-18T01:51:25Z": {
    type: "movie",
    title: "O Brother, Where Art Thou?",
    year: "2000",
    trakt_rating: "7.81817",
    trakt_id: "100",
    imdb_id: "tt0190590",
    tmdb_id: "134",
    url: "https://trakt.tv/movies/o-brother-where-art-thou-2000",
    released: "2000-12-21",
    runtime: "107",
    genres: "adventure,comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-18T01:44:46Z": {
    type: "movie",
    title: "Heat",
    year: "1995",
    trakt_rating: "8.20743",
    trakt_id: "789",
    imdb_id: "tt0113277",
    tmdb_id: "949",
    url: "https://trakt.tv/movies/heat-1995",
    released: "1995-12-15",
    runtime: "170",
    genres: "action,crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-18T01:43:07Z": {
    type: "movie",
    title: "The Day of the Jackal",
    year: "1973",
    trakt_rating: "7.76",
    trakt_id: "2848",
    imdb_id: "tt0069947",
    tmdb_id: "4909",
    url: "https://trakt.tv/movies/the-day-of-the-jackal-1973",
    released: "1973-09-13",
    runtime: "143",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-18T01:34:34Z": {
    type: "movie",
    title: "Short Cuts",
    year: "1993",
    trakt_rating: "7.52874",
    trakt_id: "567",
    imdb_id: "tt0108122",
    tmdb_id: "695",
    url: "https://trakt.tv/movies/short-cuts-1993",
    released: "1993-10-01",
    runtime: "187",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-18T01:34:17Z": {
    type: "movie",
    title: "The Exorcist",
    year: "1973",
    trakt_rating: "7.84902",
    trakt_id: "4830",
    imdb_id: "tt0070047",
    tmdb_id: "9552",
    url: "https://trakt.tv/movies/the-exorcist-1973",
    released: "1973-12-26",
    runtime: "122",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-18T01:33:31Z": {
    type: "movie",
    title: "Gattaca",
    year: "1997",
    trakt_rating: "7.98653",
    trakt_id: "625",
    imdb_id: "tt0119177",
    tmdb_id: "782",
    url: "https://trakt.tv/movies/gattaca-1997",
    released: "1997-10-24",
    runtime: "106",
    genres: "mystery,science-fiction,thriller,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-17T14:07:22Z": {
    type: "movie",
    title: "Transformers: Dark of the Moon",
    year: "2011",
    trakt_rating: "6.67335",
    trakt_id: "24763",
    imdb_id: "tt1399103",
    tmdb_id: "38356",
    url: "https://trakt.tv/movies/transformers-dark-of-the-moon-2011",
    released: "2011-06-28",
    runtime: "154",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-16T02:58:59Z": {
    type: "movie",
    title: "Fargo",
    year: "1996",
    trakt_rating: "8.02047",
    trakt_id: "231",
    imdb_id: "tt0116282",
    tmdb_id: "275",
    url: "https://trakt.tv/movies/fargo-1996",
    released: "1996-03-08",
    runtime: "98",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-16T02:51:54Z": {
    type: "movie",
    title: "The Godfather",
    year: "1972",
    trakt_rating: "8.90435",
    trakt_id: "196",
    imdb_id: "tt0068646",
    tmdb_id: "238",
    url: "https://trakt.tv/movies/the-godfather-1972",
    released: "1972-03-14",
    runtime: "175",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-16T02:51:49Z": {
    type: "movie",
    title: "The Godfather: Part II",
    year: "1974",
    trakt_rating: "8.78399",
    trakt_id: "198",
    imdb_id: "tt0071562",
    tmdb_id: "240",
    url: "https://trakt.tv/movies/the-godfather-part-ii-1974",
    released: "1974-12-20",
    runtime: "202",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-16T02:51:35Z": {
    type: "movie",
    title: "GoodFellas",
    year: "1990",
    trakt_rating: "8.59234",
    trakt_id: "612",
    imdb_id: "tt0099685",
    tmdb_id: "769",
    url: "https://trakt.tv/movies/goodfellas-1990",
    released: "1990-09-19",
    runtime: "145",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-13T12:24:01Z": {
    type: "movie",
    title: "Kill Switch",
    year: "2017",
    trakt_rating: "5.86808",
    trakt_id: "299055",
    imdb_id: "tt5464234",
    tmdb_id: "423988",
    url: "https://trakt.tv/movies/kill-switch-2017",
    released: "2017-05-19",
    runtime: "91",
    genres: "science-fiction,action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-13T05:35:20Z": {
    type: "movie",
    title: "Rushmore",
    year: "1998",
    trakt_rating: "7.62328",
    trakt_id: "6626",
    imdb_id: "tt0128445",
    tmdb_id: "11545",
    url: "https://trakt.tv/movies/rushmore-1998",
    released: "1998-12-11",
    runtime: "93",
    genres: "comedy,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-13T05:22:06Z": {
    type: "movie",
    title: "Side Effects",
    year: "2013",
    trakt_rating: "7.14311",
    trakt_id: "77331",
    imdb_id: "tt2053463",
    tmdb_id: "109421",
    url: "https://trakt.tv/movies/side-effects-2013",
    released: "2013-02-08",
    runtime: "106",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-13T05:15:04Z": {
    type: "movie",
    title: "The Circle",
    year: "2017",
    trakt_rating: "6.27568",
    trakt_id: "220448",
    imdb_id: "tt4287320",
    tmdb_id: "339988",
    url: "https://trakt.tv/movies/the-circle-2017",
    released: "2017-04-28",
    runtime: "110",
    genres: "science-fiction,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-13T05:14:28Z": {
    type: "movie",
    title: "Army of Darkness",
    year: "1992",
    trakt_rating: "7.6752",
    trakt_id: "609",
    imdb_id: "tt0106308",
    tmdb_id: "766",
    url: "https://trakt.tv/movies/army-of-darkness-1992",
    released: "1993-02-19",
    runtime: "81",
    genres: "comedy,fantasy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-13T05:14:10Z": {
    type: "movie",
    title: "Evil Dead II",
    year: "1987",
    trakt_rating: "7.62248",
    trakt_id: "608",
    imdb_id: "tt0092991",
    tmdb_id: "765",
    url: "https://trakt.tv/movies/evil-dead-ii-1987",
    released: "1987-03-13",
    runtime: "84",
    genres: "comedy,fantasy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-13T05:13:51Z": {
    type: "movie",
    title: "Guardians of the Galaxy Vol. 2",
    year: "2017",
    trakt_rating: "7.91385",
    trakt_id: "181256",
    imdb_id: "tt3896198",
    tmdb_id: "283995",
    url: "https://trakt.tv/movies/guardians-of-the-galaxy-vol-2-2017",
    released: "2017-05-05",
    runtime: "137",
    genres: "action,superhero,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-13T05:13:40Z": {
    type: "movie",
    title: "The Void",
    year: "2016",
    trakt_rating: "5.66667",
    trakt_id: "287586",
    imdb_id: "",
    tmdb_id: "442076",
    url: "https://trakt.tv/movies/the-void-2016-09-22",
    released: "2016-09-22",
    runtime: "90",
    genres: "horror,short",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-13T05:13:18Z": {
    type: "movie",
    title: "Ronin",
    year: "1998",
    trakt_rating: "7.6154",
    trakt_id: "4065",
    imdb_id: "tt0122690",
    tmdb_id: "8195",
    url: "https://trakt.tv/movies/ronin-1998",
    released: "1998-09-25",
    runtime: "122",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-13T05:13:12Z": {
    type: "movie",
    title: "Training Day",
    year: "2001",
    trakt_rating: "7.84646",
    trakt_id: "1394",
    imdb_id: "tt0139654",
    tmdb_id: "2034",
    url: "https://trakt.tv/movies/training-day-2001",
    released: "2001-10-05",
    runtime: "122",
    genres: "action,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-13T05:13:00Z": {
    type: "movie",
    title: "And Now for Something Completely Different",
    year: "1971",
    trakt_rating: "7.74831",
    trakt_id: "4567",
    imdb_id: "tt0066765",
    tmdb_id: "9267",
    url: "https://trakt.tv/movies/and-now-for-something-completely-different-1971",
    released: "1972-08-22",
    runtime: "88",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-13T05:12:40Z": {
    type: "movie",
    title: "La Femme Nikita",
    year: "1990",
    trakt_rating: "7.30351",
    trakt_id: "4620",
    imdb_id: "tt0100263",
    tmdb_id: "9322",
    url: "https://trakt.tv/movies/la-femme-nikita-1990",
    released: "1990-02-21",
    runtime: "117",
    genres: "action,thriller,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-13T05:12:20Z": {
    type: "movie",
    title: "The Box",
    year: "2009",
    trakt_rating: "6.00639",
    trakt_id: "13869",
    imdb_id: "tt0362478",
    tmdb_id: "22825",
    url: "https://trakt.tv/movies/the-box-2009",
    released: "2009-11-06",
    runtime: "115",
    genres: "science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T16:10:17Z": {
    type: "movie",
    title: "The Bourne Supremacy",
    year: "2004",
    trakt_rating: "7.8975",
    trakt_id: "1736",
    imdb_id: "tt0372183",
    tmdb_id: "2502",
    url: "https://trakt.tv/movies/the-bourne-supremacy-2004",
    released: "2004-07-23",
    runtime: "108",
    genres: "action,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T16:10:16Z": {
    type: "movie",
    title: "The Bourne Ultimatum",
    year: "2007",
    trakt_rating: "7.98489",
    trakt_id: "1737",
    imdb_id: "tt0440963",
    tmdb_id: "2503",
    url: "https://trakt.tv/movies/the-bourne-ultimatum-2007",
    released: "2007-08-03",
    runtime: "115",
    genres: "action,drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T16:10:11Z": {
    type: "movie",
    title: "The Bourne Identity",
    year: "2002",
    trakt_rating: "8.04945",
    trakt_id: "1735",
    imdb_id: "tt0258463",
    tmdb_id: "2501",
    url: "https://trakt.tv/movies/the-bourne-identity-2002",
    released: "2002-06-14",
    runtime: "119",
    genres: "action,drama,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:30:30Z": {
    type: "movie",
    title: "Insidious: Chapter 3",
    year: "2015",
    trakt_rating: "6.55498",
    trakt_id: "177585",
    imdb_id: "tt3195644",
    tmdb_id: "280092",
    url: "https://trakt.tv/movies/insidious-chapter-3-2015",
    released: "2015-05-28",
    runtime: "97",
    genres: "horror,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:24:16Z": {
    type: "movie",
    title: "Casino Royale",
    year: "2006",
    trakt_rating: "7.93126",
    trakt_id: "23217",
    imdb_id: "tt0381061",
    tmdb_id: "36557",
    url: "https://trakt.tv/movies/casino-royale-2006",
    released: "2006-11-17",
    runtime: "144",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:24:01Z": {
    type: "movie",
    title: "Spectre",
    year: "2015",
    trakt_rating: "6.99691",
    trakt_id: "128378",
    imdb_id: "tt2379713",
    tmdb_id: "206647",
    url: "https://trakt.tv/movies/spectre-2015",
    released: "2015-11-06",
    runtime: "148",
    genres: "action,adventure,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:20:40Z": {
    type: "movie",
    title: "The Rise of the Krays",
    year: "2015",
    trakt_rating: "6.21212",
    trakt_id: "222526",
    imdb_id: "tt2945796",
    tmdb_id: "356216",
    url: "https://trakt.tv/movies/the-rise-of-the-krays-2015",
    released: "2015-08-29",
    runtime: "110",
    genres: "crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:20:39Z": {
    type: "movie",
    title: "Deathgasm",
    year: "2015",
    trakt_rating: "6.72993",
    trakt_id: "203995",
    imdb_id: "tt3705412",
    tmdb_id: "323373",
    url: "https://trakt.tv/movies/deathgasm-2015",
    released: "2015-08-28",
    runtime: "86",
    genres: "comedy,horror,music",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:20:35Z": {
    type: "movie",
    title: "The 33",
    year: "2015",
    trakt_rating: "7.20756",
    trakt_id: "190418",
    imdb_id: "tt2006295",
    tmdb_id: "293646",
    url: "https://trakt.tv/movies/the-33-2015",
    released: "2015-11-09",
    runtime: "120",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:19:16Z": {
    type: "movie",
    title: "1971",
    year: "2014",
    trakt_rating: "7.04082",
    trakt_id: "160822",
    imdb_id: "tt3478510",
    tmdb_id: "261855",
    url: "https://trakt.tv/movies/1971-2014",
    released: "2014-04-18",
    runtime: "80",
    genres: "documentary,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:19:15Z": {
    type: "movie",
    title: "Scary Movie 5",
    year: "2013",
    trakt_rating: "4.71521",
    trakt_id: "2505",
    imdb_id: "tt0795461",
    tmdb_id: "4258",
    url: "https://trakt.tv/movies/scary-movie-5-2013",
    released: "2013-04-12",
    runtime: "86",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:19:11Z": {
    type: "movie",
    title: "Happy Valley",
    year: "2014",
    trakt_rating: "6.80303",
    trakt_id: "152114",
    imdb_id: "tt2171902",
    tmdb_id: "250745",
    url: "https://trakt.tv/movies/happy-valley-2014",
    released: "2014-11-14",
    runtime: "98",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:19:10Z": {
    type: "movie",
    title: "The Conjuring 2",
    year: "2016",
    trakt_rating: "7.48711",
    trakt_id: "158947",
    imdb_id: "tt3065204",
    tmdb_id: "259693",
    url: "https://trakt.tv/movies/the-conjuring-2-2016",
    released: "2016-06-10",
    runtime: "134",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:19:09Z": {
    type: "movie",
    title: "The Witch",
    year: "2015",
    trakt_rating: "6.72043",
    trakt_id: "196130",
    imdb_id: "tt4263482",
    tmdb_id: "310131",
    url: "https://trakt.tv/movies/the-witch-2015",
    released: "2016-02-19",
    runtime: "92",
    genres: "horror,mystery,drama,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:19:05Z": {
    type: "movie",
    title: "Planes",
    year: "2013",
    trakt_rating: "6.68389",
    trakt_id: "99663",
    imdb_id: "tt1691917",
    tmdb_id: "151960",
    url: "https://trakt.tv/movies/planes-2013",
    released: "2013-08-09",
    runtime: "91",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:19:01Z": {
    type: "movie",
    title: "The Hobbit: The Battle of the Five Armies",
    year: "2014",
    trakt_rating: "7.61317",
    trakt_id: "84900",
    imdb_id: "tt2310332",
    tmdb_id: "122917",
    url: "https://trakt.tv/movies/the-hobbit-the-battle-of-the-five-armies-2014",
    released: "2014-12-17",
    runtime: "144",
    genres: "adventure,fantasy,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:19:00Z": {
    type: "movie",
    title: "What We Do in the Shadows",
    year: "2014",
    trakt_rating: "7.75362",
    trakt_id: "150045",
    imdb_id: "tt3416742",
    tmdb_id: "246741",
    url: "https://trakt.tv/movies/what-we-do-in-the-shadows-2014",
    released: "2015-02-13",
    runtime: "86",
    genres: "comedy,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:59Z": {
    type: "movie",
    title: "Penguins of Madagascar",
    year: "2014",
    trakt_rating: "7.05798",
    trakt_id: "168946",
    imdb_id: "tt1911658",
    tmdb_id: "270946",
    url: "https://trakt.tv/movies/penguins-of-madagascar-2014",
    released: "2014-11-26",
    runtime: "92",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:57Z": {
    type: "movie",
    title: "Crimson Peak",
    year: "2015",
    trakt_rating: "6.7082",
    trakt_id: "125406",
    imdb_id: "tt2554274",
    tmdb_id: "201085",
    url: "https://trakt.tv/movies/crimson-peak-2015",
    released: "2015-10-16",
    runtime: "119",
    genres: "horror,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:55Z": {
    type: "movie",
    title: "Zero Days",
    year: "2016",
    trakt_rating: "7.88819",
    trakt_id: "225863",
    imdb_id: "tt5446858",
    tmdb_id: "380808",
    url: "https://trakt.tv/movies/zero-days-2016",
    released: "2016-07-08",
    runtime: "116",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:54Z": {
    type: "movie",
    title: "Madagascar 3D",
    year: "2013",
    trakt_rating: "6.26316",
    trakt_id: "133907",
    imdb_id: "tt2633166",
    tmdb_id: "217117",
    url: "https://trakt.tv/movies/madagascar-3d-2013",
    released: "2013-08-26",
    runtime: "47",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:52Z": {
    type: "movie",
    title: "Citizenfour",
    year: "2014",
    trakt_rating: "8.06043",
    trakt_id: "190089",
    imdb_id: "tt4044364",
    tmdb_id: "293310",
    url: "https://trakt.tv/movies/citizenfour-2014",
    released: "2014-10-10",
    runtime: "114",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:47Z": {
    type: "movie",
    title: "Baskin",
    year: "2015",
    trakt_rating: "6.27609",
    trakt_id: "215279",
    imdb_id: "tt4935418",
    tmdb_id: "334394",
    url: "https://trakt.tv/movies/baskin-2015",
    released: "2016-01-01",
    runtime: "97",
    genres: "horror,crime,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:45Z": {
    type: "movie",
    title: "The Hobbit: An Unexpected Journey",
    year: "2012",
    trakt_rating: "7.88483",
    trakt_id: "34097",
    imdb_id: "tt0903624",
    tmdb_id: "49051",
    url: "https://trakt.tv/movies/the-hobbit-an-unexpected-journey-2012",
    released: "2012-12-14",
    runtime: "169",
    genres: "action,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:44Z": {
    type: "movie",
    title: "Deep Web",
    year: "2015",
    trakt_rating: "7.45327",
    trakt_id: "202707",
    imdb_id: "tt3312868",
    tmdb_id: "321769",
    url: "https://trakt.tv/movies/deep-web-2015",
    released: "2015-03-15",
    runtime: "89",
    genres: "documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:43Z": {
    type: "movie",
    title: "Kiss of the Damned",
    year: "2012",
    trakt_rating: "5.98864",
    trakt_id: "193835",
    imdb_id: "tt1959438",
    tmdb_id: "139933",
    url: "https://trakt.tv/movies/kiss-of-the-damned-2012",
    released: "2013-05-03",
    runtime: "95",
    genres: "horror,romance,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:39Z": {
    type: "movie",
    title: "The Muppet Christmas Carol",
    year: "1992",
    trakt_rating: "7.87794",
    trakt_id: "5629",
    imdb_id: "tt0104940",
    tmdb_id: "10437",
    url: "https://trakt.tv/movies/the-muppet-christmas-carol-1992",
    released: "1992-12-10",
    runtime: "85",
    genres: "comedy,drama,fantasy,family,music,holiday,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:38Z": {
    type: "movie",
    title: "The Watch",
    year: "2012",
    trakt_rating: "6.08983",
    trakt_id: "59534",
    imdb_id: "tt1298649",
    tmdb_id: "80035",
    url: "https://trakt.tv/movies/the-watch-2012",
    released: "2012-07-27",
    runtime: "102",
    genres: "action,comedy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:37Z": {
    type: "movie",
    title: "Animal Kingdom",
    year: "2010",
    trakt_rating: "7.1723",
    trakt_id: "30313",
    imdb_id: "tt1313092",
    tmdb_id: "44629",
    url: "https://trakt.tv/movies/animal-kingdom-2010",
    released: "2010-06-03",
    runtime: "113",
    genres: "crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:34Z": {
    type: "movie",
    title: "How the Grinch Stole Christmas",
    year: "2000",
    trakt_rating: "7.05706",
    trakt_id: "4355",
    imdb_id: "tt0170016",
    tmdb_id: "8871",
    url: "https://trakt.tv/movies/how-the-grinch-stole-christmas-2000",
    released: "2000-11-17",
    runtime: "104",
    genres: "comedy,fantasy,family,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:33Z": {
    type: "movie",
    title: "Ice Age: Dawn of the Dinosaurs",
    year: "2009",
    trakt_rating: "7.18324",
    trakt_id: "4146",
    imdb_id: "tt1080016",
    tmdb_id: "8355",
    url: "https://trakt.tv/movies/ice-age-dawn-of-the-dinosaurs-2009",
    released: "2009-07-01",
    runtime: "94",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:30Z": {
    type: "movie",
    title: "Paradise Lost 3: Purgatory",
    year: "2011",
    trakt_rating: "8.05491",
    trakt_id: "62630",
    imdb_id: "tt2028530",
    tmdb_id: "83660",
    url: "https://trakt.tv/movies/paradise-lost-3-purgatory-2011",
    released: "2011-10-10",
    runtime: "121",
    genres: "documentary,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:27Z": {
    type: "movie",
    title: "Into the Abyss",
    year: "2011",
    trakt_rating: "7.22276",
    trakt_id: "57205",
    imdb_id: "tt1972663",
    tmdb_id: "77365",
    url: "https://trakt.tv/movies/into-the-abyss-2011",
    released: "2011-11-11",
    runtime: "105",
    genres: "crime,documentary",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:22Z": {
    type: "movie",
    title: "Machine Gun Preacher",
    year: "2011",
    trakt_rating: "7.02988",
    trakt_id: "31149",
    imdb_id: "tt1586752",
    tmdb_id: "45610",
    url: "https://trakt.tv/movies/machine-gun-preacher-2011",
    released: "2011-09-23",
    runtime: "129",
    genres: "action,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:20Z": {
    type: "movie",
    title: "Ice Age: Continental Drift",
    year: "2012",
    trakt_rating: "6.909",
    trakt_id: "41309",
    imdb_id: "tt1667889",
    tmdb_id: "57800",
    url: "https://trakt.tv/movies/ice-age-continental-drift-2012",
    released: "2012-07-13",
    runtime: "88",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:19Z": {
    type: "movie",
    title: "Madagascar 3: Europe's Most Wanted",
    year: "2012",
    trakt_rating: "7.08957",
    trakt_id: "59799",
    imdb_id: "tt1277953",
    tmdb_id: "80321",
    url: "https://trakt.tv/movies/madagascar-3-europe-s-most-wanted-2012",
    released: "2012-06-08",
    runtime: "93",
    genres: "animation,family,comedy,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:15Z": {
    type: "movie",
    title: "Puss in Boots",
    year: "2011",
    trakt_rating: "6.94974",
    trakt_id: "41789",
    imdb_id: "tt0448694",
    tmdb_id: "417859",
    url: "https://trakt.tv/movies/puss-in-boots-2011",
    released: "2011-10-28",
    runtime: "90",
    genres: "animation,family,adventure,comedy,fantasy,western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:12Z": {
    type: "movie",
    title: "How the Grinch Stole Christmas!",
    year: "1966",
    trakt_rating: "8.07319",
    trakt_id: "7860",
    imdb_id: "tt0060345",
    tmdb_id: "13377",
    url: "https://trakt.tv/movies/how-the-grinch-stole-christmas-1966",
    released: "1966-12-18",
    runtime: "26",
    genres: "animation,comedy,family,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:11Z": {
    type: "movie",
    title: "Frosty the Snowman",
    year: "1969",
    trakt_rating: "7.64934",
    trakt_id: "8099",
    imdb_id: "tt0064349",
    tmdb_id: "13675",
    url: "https://trakt.tv/movies/frosty-the-snowman-1969",
    released: "1969-12-07",
    runtime: "25",
    genres: "animation,family,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:07Z": {
    type: "movie",
    title: "Mickey's Christmas Carol",
    year: "1983",
    trakt_rating: "7.97285",
    trakt_id: "8965",
    imdb_id: "tt0085936",
    tmdb_id: "14813",
    url: "https://trakt.tv/movies/mickey-s-christmas-carol-1983",
    released: "1983-12-16",
    runtime: "25",
    genres: "animation,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:18:03Z": {
    type: "movie",
    title: "Rudolph the Red-Nosed Reindeer",
    year: "1964",
    trakt_rating: "7.88285",
    trakt_id: "7864",
    imdb_id: "tt0058536",
    tmdb_id: "13382",
    url: "https://trakt.tv/movies/rudolph-the-red-nosed-reindeer-1964",
    released: "1964-12-06",
    runtime: "52",
    genres: "animation,family,holiday",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:14:46Z": {
    type: "movie",
    title: "Wild at Heart",
    year: "1990",
    trakt_rating: "7.21862",
    trakt_id: "381",
    imdb_id: "tt0100935",
    tmdb_id: "483",
    url: "https://trakt.tv/movies/wild-at-heart-1990",
    released: "1990-08-17",
    runtime: "125",
    genres: "crime,thriller,romance",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-08-11T15:14:24Z": {
    type: "movie",
    title: "Blue Velvet",
    year: "1986",
    trakt_rating: "7.63845",
    trakt_id: "636",
    imdb_id: "tt0090756",
    tmdb_id: "793",
    url: "https://trakt.tv/movies/blue-velvet-1986",
    released: "1986-09-19",
    runtime: "120",
    genres: "crime,mystery,thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:24:14Z": {
    type: "movie",
    title: "It Follows",
    year: "2014",
    trakt_rating: "6.7516",
    trakt_id: "168349",
    imdb_id: "tt3235888",
    tmdb_id: "270303",
    url: "https://trakt.tv/movies/it-follows-2014",
    released: "2014-09-24",
    runtime: "101",
    genres: "horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:23:36Z": {
    type: "movie",
    title: "The Walk",
    year: "2015",
    trakt_rating: "7.25109",
    trakt_id: "182970",
    imdb_id: "tt3488710",
    tmdb_id: "285783",
    url: "https://trakt.tv/movies/the-walk-2015",
    released: "2015-10-02",
    runtime: "123",
    genres: "drama,adventure,thriller,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:23:22Z": {
    type: "movie",
    title: "Spotlight",
    year: "2015",
    trakt_rating: "7.98103",
    trakt_id: "194119",
    imdb_id: "tt1895587",
    tmdb_id: "314365",
    url: "https://trakt.tv/movies/spotlight-2015",
    released: "2015-11-06",
    runtime: "129",
    genres: "drama,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:23:21Z": {
    type: "movie",
    title: "The Revenant",
    year: "2015",
    trakt_rating: "7.70714",
    trakt_id: "179334",
    imdb_id: "tt1663202",
    tmdb_id: "281957",
    url: "https://trakt.tv/movies/the-revenant-2015",
    released: "2015-12-25",
    runtime: "157",
    genres: "adventure,drama,western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:22:52Z": {
    type: "movie",
    title: "Midnight Special",
    year: "2016",
    trakt_rating: "6.882",
    trakt_id: "149556",
    imdb_id: "tt2649554",
    tmdb_id: "245703",
    url: "https://trakt.tv/movies/midnight-special-2016",
    released: "2016-03-18",
    runtime: "112",
    genres: "drama,science-fiction,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:22:47Z": {
    type: "movie",
    title: "Black Mass",
    year: "2015",
    trakt_rating: "6.91047",
    trakt_id: "160067",
    imdb_id: "tt1355683",
    tmdb_id: "261023",
    url: "https://trakt.tv/movies/black-mass-2015",
    released: "2015-09-18",
    runtime: "123",
    genres: "drama,crime,history",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:22:42Z": {
    type: "movie",
    title: "Money Monster",
    year: "2016",
    trakt_rating: "7.19501",
    trakt_id: "209935",
    imdb_id: "tt2241351",
    tmdb_id: "303858",
    url: "https://trakt.tv/movies/money-monster-2016",
    released: "2016-05-13",
    runtime: "98",
    genres: "thriller,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:22:33Z": {
    type: "movie",
    title: "The Conjuring",
    year: "2013",
    trakt_rating: "7.6565",
    trakt_id: "93164",
    imdb_id: "tt1457767",
    tmdb_id: "138843",
    url: "https://trakt.tv/movies/the-conjuring-2013",
    released: "2013-07-19",
    runtime: "112",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:22:31Z": {
    type: "movie",
    title: "The Shallows",
    year: "2016",
    trakt_rating: "6.89019",
    trakt_id: "213447",
    imdb_id: "tt4052882",
    tmdb_id: "332567",
    url: "https://trakt.tv/movies/the-shallows-2016",
    released: "2016-06-24",
    runtime: "86",
    genres: "thriller,drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:22:25Z": {
    type: "movie",
    title: "Bridge of Spies",
    year: "2015",
    trakt_rating: "7.60027",
    trakt_id: "193081",
    imdb_id: "tt3682448",
    tmdb_id: "296098",
    url: "https://trakt.tv/movies/bridge-of-spies-2015",
    released: "2015-10-16",
    runtime: "141",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:22:13Z": {
    type: "movie",
    title: "Don't Breathe",
    year: "2016",
    trakt_rating: "7.28471",
    trakt_id: "224812",
    imdb_id: "tt4160708",
    tmdb_id: "300669",
    url: "https://trakt.tv/movies/don-t-breathe-2016",
    released: "2016-08-26",
    runtime: "89",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:22:06Z": {
    type: "movie",
    title: "The Silence of the Lambs",
    year: "1991",
    trakt_rating: "8.54714",
    trakt_id: "230",
    imdb_id: "tt0102926",
    tmdb_id: "274",
    url: "https://trakt.tv/movies/the-silence-of-the-lambs-1991",
    released: "1991-02-14",
    runtime: "119",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:22:02Z": {
    type: "movie",
    title: "Red Dragon",
    year: "2002",
    trakt_rating: "7.34777",
    trakt_id: "4811",
    imdb_id: "tt0289765",
    tmdb_id: "9533",
    url: "https://trakt.tv/movies/red-dragon-2002",
    released: "2002-10-04",
    runtime: "124",
    genres: "thriller,crime,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-18T01:21:49Z": {
    type: "movie",
    title: "Hannibal",
    year: "2001",
    trakt_rating: "7.29909",
    trakt_id: "5006",
    imdb_id: "tt0212985",
    tmdb_id: "9740",
    url: "https://trakt.tv/movies/hannibal-2001",
    released: "2001-02-08",
    runtime: "131",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:31:20Z": {
    type: "movie",
    title: "Blair Witch",
    year: "2016",
    trakt_rating: "5.70017",
    trakt_id: "224229",
    imdb_id: "tt1540011",
    tmdb_id: "351211",
    url: "https://trakt.tv/movies/blair-witch-2016",
    released: "2016-09-16",
    runtime: "89",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:30:55Z": {
    type: "movie",
    title: "10 Cloverfield Lane",
    year: "2016",
    trakt_rating: "7.23896",
    trakt_id: "214312",
    imdb_id: "tt1179933",
    tmdb_id: "333371",
    url: "https://trakt.tv/movies/10-cloverfield-lane-2016",
    released: "2016-03-11",
    runtime: "104",
    genres: "science-fiction,thriller,drama,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:30:41Z": {
    type: "movie",
    title: "Sleepless",
    year: "2017",
    trakt_rating: "6.95108",
    trakt_id: "205116",
    imdb_id: "tt2072233",
    tmdb_id: "324542",
    url: "https://trakt.tv/movies/sleepless-2017",
    released: "2017-01-13",
    runtime: "95",
    genres: "thriller,action,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:30:37Z": {
    type: "movie",
    title: "Snowden",
    year: "2016",
    trakt_rating: "7.65135",
    trakt_id: "195472",
    imdb_id: "tt3774114",
    tmdb_id: "302401",
    url: "https://trakt.tv/movies/snowden-2016",
    released: "2016-09-16",
    runtime: "134",
    genres: "drama,history,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:30:26Z": {
    type: "movie",
    title: "Room",
    year: "2015",
    trakt_rating: "8.00698",
    trakt_id: "163360",
    imdb_id: "tt3170832",
    tmdb_id: "264644",
    url: "https://trakt.tv/movies/room-2015",
    released: "2015-10-16",
    runtime: "118",
    genres: "drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:30:18Z": {
    type: "movie",
    title: "Sausage Party",
    year: "2016",
    trakt_rating: "6.39103",
    trakt_id: "137261",
    imdb_id: "tt1700841",
    tmdb_id: "223702",
    url: "https://trakt.tv/movies/sausage-party-2016",
    released: "2016-08-12",
    runtime: "89",
    genres: "animation,comedy,adventure,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:29:54Z": {
    type: "movie",
    title: "Evil Dead",
    year: "2013",
    trakt_rating: "6.88581",
    trakt_id: "77335",
    imdb_id: "tt1288558",
    tmdb_id: "109428",
    url: "https://trakt.tv/movies/evil-dead-2013",
    released: "2013-04-05",
    runtime: "91",
    genres: "horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:29:42Z": {
    type: "movie",
    title: "The Lincoln Lawyer",
    year: "2011",
    trakt_rating: "7.59725",
    trakt_id: "35197",
    imdb_id: "tt1189340",
    tmdb_id: "50348",
    url: "https://trakt.tv/movies/the-lincoln-lawyer-2011",
    released: "2011-03-18",
    runtime: "119",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:28:53Z": {
    type: "movie",
    title: "Star Wars: Episode I - The Phantom Menace",
    year: "1999",
    trakt_rating: "6.9275",
    trakt_id: "1268",
    imdb_id: "tt0120915",
    tmdb_id: "1893",
    url: "https://trakt.tv/movies/star-wars-episode-i-the-phantom-menace-1999",
    released: "1999-05-19",
    runtime: "136",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:27:27Z": {
    type: "movie",
    title: "Madagascar",
    year: "2005",
    trakt_rating: "7.32088",
    trakt_id: "792",
    imdb_id: "tt0351283",
    tmdb_id: "953",
    url: "https://trakt.tv/movies/madagascar-2005",
    released: "2005-05-27",
    runtime: "86",
    genres: "animation,family,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:27:15Z": {
    type: "movie",
    title: "Se7en",
    year: "1995",
    trakt_rating: "8.55768",
    trakt_id: "650",
    imdb_id: "tt0114369",
    tmdb_id: "807",
    url: "https://trakt.tv/movies/se7en-1995",
    released: "1995-09-22",
    runtime: "127",
    genres: "crime,mystery,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:27:11Z": {
    type: "movie",
    title: "Total Recall",
    year: "1990",
    trakt_rating: "7.72146",
    trakt_id: "704",
    imdb_id: "tt0100802",
    tmdb_id: "861",
    url: "https://trakt.tv/movies/total-recall-1990",
    released: "1990-06-01",
    runtime: "113",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:27:06Z": {
    type: "movie",
    title: "The Shining",
    year: "1980",
    trakt_rating: "8.19674",
    trakt_id: "566",
    imdb_id: "tt0081505",
    tmdb_id: "694",
    url: "https://trakt.tv/movies/the-shining-1980",
    released: "1980-05-23",
    runtime: "144",
    genres: "horror,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:26:51Z": {
    type: "movie",
    title: "Ice Age",
    year: "2002",
    trakt_rating: "7.68327",
    trakt_id: "337",
    imdb_id: "tt0268380",
    tmdb_id: "425",
    url: "https://trakt.tv/movies/ice-age-2002",
    released: "2002-03-15",
    runtime: "81",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:26:49Z": {
    type: "movie",
    title: "Alien",
    year: "1979",
    trakt_rating: "8.43488",
    trakt_id: "295",
    imdb_id: "tt0078748",
    tmdb_id: "348",
    url: "https://trakt.tv/movies/alien-1979",
    released: "1979-05-25",
    runtime: "117",
    genres: "horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:26:45Z": {
    type: "movie",
    title: "Batman Begins",
    year: "2005",
    trakt_rating: "8.17212",
    trakt_id: "228",
    imdb_id: "tt0372784",
    tmdb_id: "272",
    url: "https://trakt.tv/movies/batman-begins-2005",
    released: "2005-06-15",
    runtime: "140",
    genres: "action,crime,drama,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:26:25Z": {
    type: "movie",
    title: "Deepwater Horizon",
    year: "2016",
    trakt_rating: "7.59469",
    trakt_id: "205222",
    imdb_id: "tt1860357",
    tmdb_id: "296524",
    url: "https://trakt.tv/movies/deepwater-horizon-2016",
    released: "2016-09-30",
    runtime: "107",
    genres: "action,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:26:23Z": {
    type: "movie",
    title: "The Martian",
    year: "2015",
    trakt_rating: "8.13147",
    trakt_id: "183371",
    imdb_id: "tt3659388",
    tmdb_id: "286217",
    url: "https://trakt.tv/movies/the-martian-2015",
    released: "2015-10-02",
    runtime: "141",
    genres: "science-fiction,adventure,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:26:20Z": {
    type: "movie",
    title: "The Girl with All the Gifts",
    year: "2016",
    trakt_rating: "6.97189",
    trakt_id: "225983",
    imdb_id: "tt4547056",
    tmdb_id: "375366",
    url: "https://trakt.tv/movies/the-girl-with-all-the-gifts-2016",
    released: "2016-09-23",
    runtime: "110",
    genres: "horror,science-fiction,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:26:09Z": {
    type: "movie",
    title: "Live by Night",
    year: "2016",
    trakt_rating: "7.09613",
    trakt_id: "158949",
    imdb_id: "tt2361317",
    tmdb_id: "259695",
    url: "https://trakt.tv/movies/live-by-night-2016",
    released: "2016-12-25",
    runtime: "129",
    genres: "crime,drama,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:26:03Z": {
    type: "movie",
    title: "Edge of Tomorrow",
    year: "2014",
    trakt_rating: "7.93401",
    trakt_id: "92230",
    imdb_id: "tt1631867",
    tmdb_id: "137113",
    url: "https://trakt.tv/movies/edge-of-tomorrow-2014",
    released: "2014-06-06",
    runtime: "114",
    genres: "action,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:26:01Z": {
    type: "movie",
    title: "Finding Dory",
    year: "2016",
    trakt_rating: "7.60479",
    trakt_id: "87254",
    imdb_id: "tt2277860",
    tmdb_id: "127380",
    url: "https://trakt.tv/movies/finding-dory-2016",
    released: "2016-06-17",
    runtime: "97",
    genres: "animation,family,adventure,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:57Z": {
    type: "movie",
    title: "Jupiter Ascending",
    year: "2015",
    trakt_rating: "6.02038",
    trakt_id: "56709",
    imdb_id: "tt1617661",
    tmdb_id: "76757",
    url: "https://trakt.tv/movies/jupiter-ascending-2015",
    released: "2015-02-27",
    runtime: "127",
    genres: "action,fantasy,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:56Z": {
    type: "movie",
    title: "Inside Out",
    year: "2015",
    trakt_rating: "8.06967",
    trakt_id: "98998",
    imdb_id: "tt2096673",
    tmdb_id: "150540",
    url: "https://trakt.tv/movies/inside-out-2015",
    released: "2015-06-19",
    runtime: "95",
    genres: "animation,comedy,family,drama,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:55Z": {
    type: "movie",
    title: "Prometheus",
    year: "2012",
    trakt_rating: "7.07032",
    trakt_id: "52036",
    imdb_id: "tt1446714",
    tmdb_id: "70981",
    url: "https://trakt.tv/movies/prometheus-2012",
    released: "2012-06-08",
    runtime: "124",
    genres: "adventure,mystery,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:54Z": {
    type: "movie",
    title: "Pacific Rim",
    year: "2013",
    trakt_rating: "7.29218",
    trakt_id: "50139",
    imdb_id: "tt1663662",
    tmdb_id: "68726",
    url: "https://trakt.tv/movies/pacific-rim-2013",
    released: "2013-07-12",
    runtime: "131",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:53Z": {
    type: "movie",
    title: "The Good Dinosaur",
    year: "2015",
    trakt_rating: "7.21926",
    trakt_id: "75285",
    imdb_id: "tt1979388",
    tmdb_id: "105864",
    url: "https://trakt.tv/movies/the-good-dinosaur-2015",
    released: "2015-11-25",
    runtime: "93",
    genres: "animation,adventure,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:50Z": {
    type: "movie",
    title: "Independence Day: Resurgence",
    year: "2016",
    trakt_rating: "6.43282",
    trakt_id: "33179",
    imdb_id: "tt1628841",
    tmdb_id: "47933",
    url: "https://trakt.tv/movies/independence-day-resurgence-2016",
    released: "2016-06-24",
    runtime: "120",
    genres: "action,science-fiction,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:49Z": {
    type: "movie",
    title: "Captain America: The Winter Soldier",
    year: "2014",
    trakt_rating: "7.9288",
    trakt_id: "72297",
    imdb_id: "tt1843866",
    tmdb_id: "100402",
    url: "https://trakt.tv/movies/captain-america-the-winter-soldier-2014",
    released: "2014-04-04",
    runtime: "136",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:47Z": {
    type: "movie",
    title: "Mad Max: Fury Road",
    year: "2015",
    trakt_rating: "7.96911",
    trakt_id: "56360",
    imdb_id: "tt1392190",
    tmdb_id: "76341",
    url: "https://trakt.tv/movies/mad-max-fury-road-2015",
    released: "2015-05-15",
    runtime: "121",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:37Z": {
    type: "movie",
    title: "Inception",
    year: "2010",
    trakt_rating: "8.65106",
    trakt_id: "16662",
    imdb_id: "tt1375666",
    tmdb_id: "27205",
    url: "https://trakt.tv/movies/inception-2010",
    released: "2010-07-16",
    runtime: "148",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:36Z": {
    type: "movie",
    title: "Star Wars: Episode II - Attack of the Clones",
    year: "2002",
    trakt_rating: "7.00738",
    trakt_id: "1269",
    imdb_id: "tt0121765",
    tmdb_id: "1894",
    url: "https://trakt.tv/movies/star-wars-episode-ii-attack-of-the-clones-2002",
    released: "2002-05-16",
    runtime: "142",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:32Z": {
    type: "movie",
    title: "Office Space",
    year: "1999",
    trakt_rating: "8.08409",
    trakt_id: "1002",
    imdb_id: "tt0151804",
    tmdb_id: "1542",
    url: "https://trakt.tv/movies/office-space-1999",
    released: "1999-02-19",
    runtime: "90",
    genres: "comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:24Z": {
    type: "movie",
    title: "Toy Story 2",
    year: "1999",
    trakt_rating: "7.88385",
    trakt_id: "706",
    imdb_id: "tt0120363",
    tmdb_id: "863",
    url: "https://trakt.tv/movies/toy-story-2-1999",
    released: "1999-11-24",
    runtime: "92",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:21Z": {
    type: "movie",
    title: "V for Vendetta",
    year: "2006",
    trakt_rating: "8.26533",
    trakt_id: "595",
    imdb_id: "tt0434409",
    tmdb_id: "752",
    url: "https://trakt.tv/movies/v-for-vendetta-2006",
    released: "2006-03-17",
    runtime: "132",
    genres: "action,thriller,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:07Z": {
    type: "movie",
    title: "Die Hard",
    year: "1988",
    trakt_rating: "8.30494",
    trakt_id: "443",
    imdb_id: "tt0095016",
    tmdb_id: "562",
    url: "https://trakt.tv/movies/die-hard-1988",
    released: "1988-07-15",
    runtime: "131",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:25:05Z": {
    type: "movie",
    title: "Spirited Away",
    year: "2001",
    trakt_rating: "8.7016",
    trakt_id: "97",
    imdb_id: "tt0245429",
    tmdb_id: "129",
    url: "https://trakt.tv/movies/spirited-away-2001",
    released: "2001-07-20",
    runtime: "125",
    genres: "fantasy,family,anime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:57Z": {
    type: "movie",
    title: "Patriots Day",
    year: "2016",
    trakt_rating: "7.72178",
    trakt_id: "230554",
    imdb_id: "tt4572514",
    tmdb_id: "388399",
    url: "https://trakt.tv/movies/patriots-day-2016",
    released: "2016-12-21",
    runtime: "133",
    genres: "drama,crime,action,history,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:54Z": {
    type: "movie",
    title: "Morgan",
    year: "2016",
    trakt_rating: "6.7892",
    trakt_id: "240058",
    imdb_id: "tt4520364",
    tmdb_id: "377264",
    url: "https://trakt.tv/movies/morgan-2016",
    released: "2016-09-02",
    runtime: "92",
    genres: "thriller,science-fiction,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:51Z": {
    type: "movie",
    title: "Jason Bourne",
    year: "2016",
    trakt_rating: "7.30206",
    trakt_id: "205219",
    imdb_id: "tt4196776",
    tmdb_id: "324668",
    url: "https://trakt.tv/movies/jason-bourne-2016",
    released: "2016-07-29",
    runtime: "123",
    genres: "action,thriller,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:47Z": {
    type: "movie",
    title: "Arrival",
    year: "2016",
    trakt_rating: "7.76739",
    trakt_id: "210803",
    imdb_id: "tt2543164",
    tmdb_id: "329865",
    url: "https://trakt.tv/movies/arrival-2016",
    released: "2016-11-11",
    runtime: "116",
    genres: "science-fiction,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:41Z": {
    type: "movie",
    title: "The Hunger Games: Mockingjay - Part 1",
    year: "2014",
    trakt_rating: "7.10586",
    trakt_id: "89542",
    imdb_id: "tt1951265",
    tmdb_id: "131631",
    url: "https://trakt.tv/movies/the-hunger-games-mockingjay-part-1-2014",
    released: "2014-11-21",
    runtime: "123",
    genres: "adventure,science-fiction,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:36Z": {
    type: "movie",
    title: "Cars 2",
    year: "2011",
    trakt_rating: "6.64021",
    trakt_id: "34064",
    imdb_id: "tt1216475",
    tmdb_id: "49013",
    url: "https://trakt.tv/movies/cars-2-2011",
    released: "2011-06-16",
    runtime: "106",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:33Z": {
    type: "movie",
    title: "Thor",
    year: "2011",
    trakt_rating: "7.23717",
    trakt_id: "5408",
    imdb_id: "tt0800369",
    tmdb_id: "10195",
    url: "https://trakt.tv/movies/thor-2011",
    released: "2011-05-06",
    runtime: "115",
    genres: "action,adventure,fantasy,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:28Z": {
    type: "movie",
    title: "The Princess Bride",
    year: "1987",
    trakt_rating: "8.28678",
    trakt_id: "1734",
    imdb_id: "tt0093779",
    tmdb_id: "2493",
    url: "https://trakt.tv/movies/the-princess-bride-1987",
    released: "1987-09-25",
    runtime: "99",
    genres: "adventure,comedy,fantasy,romance,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:26Z": {
    type: "movie",
    title: "Inglourious Basterds",
    year: "2009",
    trakt_rating: "8.29405",
    trakt_id: "10322",
    imdb_id: "tt0361748",
    tmdb_id: "16869",
    url: "https://trakt.tv/movies/inglourious-basterds-2009",
    released: "2009-08-21",
    runtime: "153",
    genres: "drama,thriller,war",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:25Z": {
    type: "movie",
    title: "Toy Story",
    year: "1995",
    trakt_rating: "8.28823",
    trakt_id: "705",
    imdb_id: "tt0114709",
    tmdb_id: "862",
    url: "https://trakt.tv/movies/toy-story-1995",
    released: "1995-11-22",
    runtime: "81",
    genres: "animation,comedy,family,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:24Z": {
    type: "movie",
    title: "The Dark Knight Rises",
    year: "2012",
    trakt_rating: "8.22157",
    trakt_id: "34073",
    imdb_id: "tt1345836",
    tmdb_id: "49026",
    url: "https://trakt.tv/movies/the-dark-knight-rises-2012",
    released: "2012-07-20",
    runtime: "165",
    genres: "action,crime,drama,thriller,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:20Z": {
    type: "movie",
    title: "Ghostbusters",
    year: "2016",
    trakt_rating: "6.29279",
    trakt_id: "28880",
    imdb_id: "tt1289401",
    tmdb_id: "43074",
    url: "https://trakt.tv/movies/ghostbusters-2016",
    released: "2016-07-15",
    runtime: "117",
    genres: "fantasy,action,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:04Z": {
    type: "movie",
    title: "The Girl on the Train",
    year: "2016",
    trakt_rating: "7.01044",
    trakt_id: "224010",
    imdb_id: "tt3631112",
    tmdb_id: "346685",
    url: "https://trakt.tv/movies/the-girl-on-the-train-2016",
    released: "2016-10-07",
    runtime: "112",
    genres: "thriller,mystery,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:24:01Z": {
    type: "movie",
    title: "The Magnificent Seven",
    year: "2016",
    trakt_rating: "7.47171",
    trakt_id: "214415",
    imdb_id: "tt2404435",
    tmdb_id: "333484",
    url: "https://trakt.tv/movies/the-magnificent-seven-2016",
    released: "2016-09-23",
    runtime: "132",
    genres: "action,western,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:23:32Z": {
    type: "movie",
    title: "Django Unchained",
    year: "2012",
    trakt_rating: "8.42383",
    trakt_id: "50131",
    imdb_id: "tt1853728",
    tmdb_id: "68718",
    url: "https://trakt.tv/movies/django-unchained-2012",
    released: "2012-12-25",
    runtime: "165",
    genres: "drama,western",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:23:21Z": {
    type: "movie",
    title: "The Hateful Eight",
    year: "2015",
    trakt_rating: "7.70567",
    trakt_id: "171149",
    imdb_id: "tt3460252",
    tmdb_id: "273248",
    url: "https://trakt.tv/movies/the-hateful-eight-2015",
    released: "2015-12-25",
    runtime: "188",
    genres: "western,drama,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:23:11Z": {
    type: "movie",
    title: "The Avengers",
    year: "2012",
    trakt_rating: "8.18082",
    trakt_id: "14701",
    imdb_id: "tt0848228",
    tmdb_id: "24428",
    url: "https://trakt.tv/movies/the-avengers-2012",
    released: "2012-05-04",
    runtime: "143",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:23:06Z": {
    type: "movie",
    title: "Despicable Me 2",
    year: "2013",
    trakt_rating: "7.56303",
    trakt_id: "68817",
    imdb_id: "tt1690953",
    tmdb_id: "93456",
    url: "https://trakt.tv/movies/despicable-me-2-2013",
    released: "2013-07-03",
    runtime: "98",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:23:00Z": {
    type: "movie",
    title: "Cars",
    year: "2006",
    trakt_rating: "7.36824",
    trakt_id: "762",
    imdb_id: "tt0317219",
    tmdb_id: "920",
    url: "https://trakt.tv/movies/cars-2006",
    released: "2006-06-09",
    runtime: "117",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:59Z": {
    type: "movie",
    title: "Frozen",
    year: "2013",
    trakt_rating: "7.57088",
    trakt_id: "77349",
    imdb_id: "tt2294629",
    tmdb_id: "109445",
    url: "https://trakt.tv/movies/frozen-2013",
    released: "2013-11-27",
    runtime: "102",
    genres: "adventure,animation,family,musical,fantasy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:55Z": {
    type: "movie",
    title: "The Dark Knight",
    year: "2008",
    trakt_rating: "8.88618",
    trakt_id: "120",
    imdb_id: "tt0468569",
    tmdb_id: "155",
    url: "https://trakt.tv/movies/the-dark-knight-2008",
    released: "2008-07-18",
    runtime: "152",
    genres: "action,crime,drama,thriller,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:52Z": {
    type: "movie",
    title: "T2 Trainspotting",
    year: "2017",
    trakt_rating: "7.32011",
    trakt_id: "113917",
    imdb_id: "tt2763304",
    tmdb_id: "180863",
    url: "https://trakt.tv/movies/t2-trainspotting-2017",
    released: "2017-01-27",
    runtime: "117",
    genres: "drama,comedy",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:50Z": {
    type: "movie",
    title: "Sicario",
    year: "2015",
    trakt_rating: "7.57396",
    trakt_id: "171369",
    imdb_id: "tt3397884",
    tmdb_id: "273481",
    url: "https://trakt.tv/movies/sicario-2015",
    released: "2015-09-18",
    runtime: "122",
    genres: "crime,thriller,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:48Z": {
    type: "movie",
    title: "Interstellar",
    year: "2014",
    trakt_rating: "8.54601",
    trakt_id: "102156",
    imdb_id: "tt0816692",
    tmdb_id: "157336",
    url: "https://trakt.tv/movies/interstellar-2014",
    released: "2014-11-05",
    runtime: "169",
    genres: "science-fiction,drama,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:47Z": {
    type: "movie",
    title: "Big Hero 6",
    year: "2014",
    trakt_rating: "8.05147",
    trakt_id: "112404",
    imdb_id: "tt2245084",
    tmdb_id: "177572",
    url: "https://trakt.tv/movies/big-hero-6-2014",
    released: "2014-11-07",
    runtime: "102",
    genres: "action,animation,comedy,adventure,family,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:40Z": {
    type: "movie",
    title: "The Accountant",
    year: "2016",
    trakt_rating: "7.73922",
    trakt_id: "203309",
    imdb_id: "tt2140479",
    tmdb_id: "302946",
    url: "https://trakt.tv/movies/the-accountant-2016",
    released: "2016-10-14",
    runtime: "128",
    genres: "drama,crime,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:37Z": {
    type: "movie",
    title: "Resident Evil: The Final Chapter",
    year: "2016",
    trakt_rating: "6.71208",
    trakt_id: "110398",
    imdb_id: "tt2592614",
    tmdb_id: "173897",
    url: "https://trakt.tv/movies/resident-evil-the-final-chapter-2016",
    released: "2017-01-27",
    runtime: "107",
    genres: "action,horror,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:30Z": {
    type: "movie",
    title: "John Wick",
    year: "2014",
    trakt_rating: "7.93532",
    trakt_id: "149640",
    imdb_id: "tt2911666",
    tmdb_id: "245891",
    url: "https://trakt.tv/movies/john-wick-2014",
    released: "2014-10-24",
    runtime: "101",
    genres: "action,thriller",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:29Z": {
    type: "movie",
    title: "Zootopia",
    year: "2016",
    trakt_rating: "8.0161",
    trakt_id: "167397",
    imdb_id: "tt2948356",
    tmdb_id: "269149",
    url: "https://trakt.tv/movies/zootopia-2016",
    released: "2016-03-04",
    runtime: "109",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:28Z": {
    type: "movie",
    title: "Star Trek Beyond",
    year: "2016",
    trakt_rating: "7.54635",
    trakt_id: "118174",
    imdb_id: "tt2660888",
    tmdb_id: "188927",
    url: "https://trakt.tv/movies/star-trek-beyond-2016",
    released: "2016-07-22",
    runtime: "122",
    genres: "science-fiction,action,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:24Z": {
    type: "movie",
    title: "Star Wars: The Force Awakens",
    year: "2015",
    trakt_rating: "7.85684",
    trakt_id: "94024",
    imdb_id: "tt2488496",
    tmdb_id: "140607",
    url: "https://trakt.tv/movies/star-wars-the-force-awakens-2015",
    released: "2015-12-18",
    runtime: "136",
    genres: "action,adventure,science-fiction",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:20Z": {
    type: "movie",
    title: "Despicable Me",
    year: "2010",
    trakt_rating: "7.83029",
    trakt_id: "12496",
    imdb_id: "tt1323594",
    tmdb_id: "20352",
    url: "https://trakt.tv/movies/despicable-me-2010",
    released: "2010-07-09",
    runtime: "95",
    genres: "animation,family,comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:18Z": {
    type: "movie",
    title: "Suicide Squad",
    year: "2016",
    trakt_rating: "6.53673",
    trakt_id: "193079",
    imdb_id: "tt1386697",
    tmdb_id: "297761",
    url: "https://trakt.tv/movies/suicide-squad-2016",
    released: "2016-08-05",
    runtime: "123",
    genres: "action,fantasy,superhero,adventure",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:17Z": {
    type: "movie",
    title: "Deadpool",
    year: "2016",
    trakt_rating: "8.29972",
    trakt_id: "190430",
    imdb_id: "tt1431045",
    tmdb_id: "293660",
    url: "https://trakt.tv/movies/deadpool-2016",
    released: "2016-02-12",
    runtime: "108",
    genres: "action,adventure,comedy,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:16Z": {
    type: "movie",
    title: "Minions",
    year: "2015",
    trakt_rating: "7.05576",
    trakt_id: "130970",
    imdb_id: "tt2293640",
    tmdb_id: "211672",
    url: "https://trakt.tv/movies/minions-2015",
    released: "2015-07-10",
    runtime: "91",
    genres: "adventure,animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:15Z": {
    type: "movie",
    title: "Kingsman: The Secret Service",
    year: "2014",
    trakt_rating: "7.88668",
    trakt_id: "128888",
    imdb_id: "tt2802144",
    tmdb_id: "207703",
    url: "https://trakt.tv/movies/kingsman-the-secret-service-2014",
    released: "2014-12-13",
    runtime: "129",
    genres: "action,adventure,comedy,crime",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:14Z": {
    type: "movie",
    title: "Avengers: Age of Ultron",
    year: "2015",
    trakt_rating: "7.54843",
    trakt_id: "71938",
    imdb_id: "tt2395427",
    tmdb_id: "99861",
    url: "https://trakt.tv/movies/avengers-age-of-ultron-2015",
    released: "2015-05-01",
    runtime: "141",
    genres: "action,adventure,science-fiction,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:08Z": {
    type: "movie",
    title: "Split",
    year: "2017",
    trakt_rating: "7.50018",
    trakt_id: "254263",
    imdb_id: "tt4972582",
    tmdb_id: "381288",
    url: "https://trakt.tv/movies/split-2017",
    released: "2017-01-20",
    runtime: "117",
    genres: "thriller,horror",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:22:02Z": {
    type: "movie",
    title: "Rogue One: A Star Wars Story",
    year: "2016",
    trakt_rating: "7.95084",
    trakt_id: "211396",
    imdb_id: "tt3748528",
    tmdb_id: "330459",
    url: "https://trakt.tv/movies/rogue-one-a-star-wars-story-2016",
    released: "2016-12-16",
    runtime: "133",
    genres: "science-fiction,adventure,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:21:55Z": {
    type: "movie",
    title: "The Lego Batman Movie",
    year: "2017",
    trakt_rating: "7.60216",
    trakt_id: "205398",
    imdb_id: "tt4116284",
    tmdb_id: "324849",
    url: "https://trakt.tv/movies/the-lego-batman-movie-2017",
    released: "2017-02-10",
    runtime: "104",
    genres: "comedy,animation,action,superhero,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:21:53Z": {
    type: "movie",
    title: "Guardians of the Galaxy",
    year: "2014",
    trakt_rating: "8.32022",
    trakt_id: "82405",
    imdb_id: "tt2015381",
    tmdb_id: "118340",
    url: "https://trakt.tv/movies/guardians-of-the-galaxy-2014",
    released: "2014-08-01",
    runtime: "121",
    genres: "adventure,science-fiction,action",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:21:42Z": {
    type: "movie",
    title: "Doctor Strange",
    year: "2016",
    trakt_rating: "7.82851",
    trakt_id: "181311",
    imdb_id: "tt1211837",
    tmdb_id: "284052",
    url: "https://trakt.tv/movies/doctor-strange-2016",
    released: "2016-11-04",
    runtime: "115",
    genres: "action,adventure,fantasy,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:21:39Z": {
    type: "movie",
    title: "Moana",
    year: "2016",
    trakt_rating: "8.00186",
    trakt_id: "175475",
    imdb_id: "tt3521164",
    tmdb_id: "277834",
    url: "https://trakt.tv/movies/moana-2016",
    released: "2016-11-23",
    runtime: "107",
    genres: "family,adventure,comedy,animation,musical",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:21:27Z": {
    type: "movie",
    title: "Life",
    year: "2017",
    trakt_rating: "6.95402",
    trakt_id: "238825",
    imdb_id: "tt5442430",
    tmdb_id: "395992",
    url: "https://trakt.tv/movies/life-2017",
    released: "2017-03-24",
    runtime: "104",
    genres: "thriller,science-fiction,horror,mystery",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:21:24Z": {
    type: "movie",
    title: "Logan",
    year: "2017",
    trakt_rating: "8.02784",
    trakt_id: "161972",
    imdb_id: "tt3315342",
    tmdb_id: "263115",
    url: "https://trakt.tv/movies/logan-2017",
    released: "2017-03-03",
    runtime: "137",
    genres: "action,science-fiction,drama,superhero",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:21:21Z": {
    type: "movie",
    title: "The Lost City of Z",
    year: "2017",
    trakt_rating: "6.76898",
    trakt_id: "193776",
    imdb_id: "tt1212428",
    tmdb_id: "314095",
    url: "https://trakt.tv/movies/the-lost-city-of-z-2017",
    released: "2017-04-21",
    runtime: "141",
    genres: "adventure,history,drama",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
  "2017-07-16T00:21:09Z": {
    type: "movie",
    title: "The Boss Baby",
    year: "2017",
    trakt_rating: "7.20376",
    trakt_id: "195864",
    imdb_id: "tt3874544",
    tmdb_id: "295693",
    url: "https://trakt.tv/movies/the-boss-baby-2017",
    released: "2017-03-31",
    runtime: "97",
    genres: "animation,comedy,family",
    media_type: "",
    resolution: "",
    "3d": "false",
    hdr: "",
    audio: "",
    audio_channels: "",
  },
};

export default movies;
