/** @format */

import React from 'react';
import Header from './Header';
import Person from './Person';
import Stars from './Stars';
import Footer from './Footer';
import SeriesSocial from './SeriesSocial';
import Review from './Review';
import SimilarSeries from './SimilarSeries';
import movies from '../movies';
import $ from 'jquery';

class SeriesSingle extends React.Component {
	state = {
		movies: {},
		pagedata: [],
		pageseriesdata: [],
		pagecastdata: [],
		pagecrewdata: [],
		pageseriessocialdata: [],
		pageserieskeywordsdata: [],
		pageseriescontentratingsdata: [],
		pageseriesreviewsdata: [],
		bgimgdata: [],

		pageaddtldata: [],
		pagetrailerdata: [],
		pagesimilarseriesdata: [],
		pageseriesimagesdata: [],
	};

	// take person name, return URI friendly string.
	getPersonUriName = (name) => {
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = name
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		return tt;
	};
	// reduce decimal to one place
	roundDecimal = (float) => {
		const rounded = Math.round(float * 10) / 10;
		return rounded;
	};

	// return the year fragment from a tmdb date node
	getReleaseYear = (date) => {
		const year = date.split('-')[0];
		return year;
	};

	// make a random color for the placeholder link
	// for movies with no poster available.
	getRandomBgColor = (size) => {
		var somecolors = [
			'c238c2',
			'c23838',
			'614875',
			'2548a1',
			'25a14c',
			'9ba125',
			'a17a25',
			'a12525',
			'f0820c',
			'd61773',
			'ce17d4',
			'601ea6',
			'2a1fa6',
			'0bb8db',
			'0dd91e',
			'388c3f',
			'378a73',
			'd45137',
		];

		const rand = Math.floor(Math.random() * somecolors.length);
		const uriSegA = 'https://dummyimage.com/' + size + '/';
		const uriSegB = '/ffffff.png&text=Poster+Not+Available';
		return uriSegA + somecolors[rand] + uriSegB;
	};

	colorKeyword(keyword) {
		var somecolors = ['6199d1', '4d8ccb', '3980c6', '3473b2', '2e669e', '28598a', '224d77', '1d4063', '17334f'];
		const rand = Math.floor(Math.random() * somecolors.length);
		const hex = somecolors[rand];

		var cssobj = {
			backgroundColor: '#' + hex,
			margin: '5px 5px 5px 0',
			display: 'inline-block',
			border: '1px solid #fff',
			color: 'yellow',
		};
		return cssobj;
	}

	stripLeadZero(int) {
		if (int < 10) {
			return int.split('')[1];
		} else {
			return int;
		}
	}
	getFormattedDate(utcDate) {
		let months = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		if (utcDate !== null) {
			let dateFrags = utcDate.split('-');
			return months[parseInt(dateFrags[1] - 1)] + ' ' + this.stripLeadZero(dateFrags[2]) + ', ' + dateFrags[0];
		} else return;
	}

	getRandomBgImage = () => {
		var somecolors = [
			'c238c2',
			'c23838',
			'614875',
			'2548a1',
			'25a14c',
			'9ba125',
			'a17a25',
			'a12525',
			'f0820c',
			'd61773',
			'ce17d4',
			'601ea6',
			'2a1fa6',
			'0bb8db',
			'0dd91e',
			'388c3f',
			'378a73',
			'd45137',
		];

		const rand = Math.floor(Math.random() * somecolors.length);
		const uriSegA = 'https://dummyimage.com/1920x800/';
		const uriSegB = '/ffffff.png&text=+';
		return uriSegA + somecolors[rand] + uriSegB;
	};

	loadSeriesData(showId, count) {
		//console.log(movieid);
		var status = $('#status');
		var preloader = $('#preloader');
		var body = $('body');
		body.delay(0).css({ overflow: 'visible' });
		fetch(
			'https://api.themoviedb.org/3/tv/' +
				showId +
				'?api_key=83747b00d208337448cd133b2110d874&language=en-US&append_to_response=release_dates'
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((seriesdata) => {
				this.setState((prevState) => ({
					pageseriesdata: prevState.pageseriesdata.concat(seriesdata),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
		status.fadeOut(); // will first fade out the loading animation
		body.delay(1000).css({ overflow: 'visible' });
		preloader.delay(1000).fadeOut('fast'); // will fade out the white DIV that covers the website.
	}

	loadCastData(showId, count) {
		fetch('https://api.themoviedb.org/3/tv/' + showId + '/credits?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((data) => {
				this.setState((prevState) => ({
					pagecastdata: prevState.pagecastdata.concat(data.cast),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	loadCrewData(showId, count) {
		fetch('https://api.themoviedb.org/3/tv/' + showId + '/credits?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => response.json())
			.then((cdata) => {
				this.setState((prevState) => ({
					pagecrewdata: prevState.pagecrewdata.concat(cdata.crew),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	loadSeriesSocialData(showId, count) {
		fetch('https://api.themoviedb.org/3/tv/' + showId + '/external_ids?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((socdata) => {
				this.setState((prevState) => ({
					pageseriessocialdata: prevState.pageseriessocialdata.concat(socdata),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	loadSeriesKeywordsData(showId, count) {
		fetch('https://api.themoviedb.org/3/tv/' + showId + '/keywords?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((kwdata) => {
				this.setState((prevState) => ({
					pageserieskeywordsdata: prevState.pageserieskeywordsdata.concat(kwdata.results),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	loadSeriesContentRatingsData(showId, count) {
		fetch('https://api.themoviedb.org/3/tv/' + showId + '/content_ratings?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((kwdata) => {
				this.setState((prevState) => ({
					pageseriescontentratingsdata: prevState.pageseriescontentratingsdata.concat(kwdata.results),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	loadSeriesReviewsData(showId, count) {
		fetch(
			'https://api.themoviedb.org/3/tv/' + showId + '/reviews?api_key=83747b00d208337448cd133b2110d874&language=en-US'
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((rvdata) => {
				this.setState((prevState) => ({
					pageseriesreviewsdata: prevState.pageseriesreviewsdata.concat(rvdata.results),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	loadSeriesTrailerData(showId, count) {
		//console.log(movieid);
		fetch(
			'https://api.themoviedb.org/3/tv/' + showId + '/videos?api_key=83747b00d208337448cd133b2110d874&language=en-US'
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((tdata) => {
				this.setState((prevState) => ({
					pagetrailerdata: prevState.pagetrailerdata.concat(tdata.results),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	loadSimilarSeriesData(showId, count) {
		fetch(
			'https://api.themoviedb.org/3/tv/' + showId + '/similar?api_key=83747b00d208337448cd133b2110d874&language=en-US'
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((smdata) => {
				this.setState((prevState) => ({
					pagesimilarseriesdata: prevState.pagesimilarseriesdata.concat(smdata.results),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	loadSeriesImagesData(showId, count) {
		fetch('https://api.themoviedb.org/3/tv/' + showId + '/images?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((midata) => {
				this.setState((prevState) => ({
					pageseriesimagesdata: prevState.pageseriesimagesdata.concat(midata.posters),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	getPageData() {
		this.loadSeriesData(this.props.match.params.showId);
		this.loadCastData(this.props.match.params.showId);
		this.loadCrewData(this.props.match.params.showId);
		this.loadSeriesSocialData(this.props.match.params.showId);
		this.loadSeriesKeywordsData(this.props.match.params.showId);
		this.loadSeriesContentRatingsData(this.props.match.params.showId);
		this.loadSeriesReviewsData(this.props.match.params.showId);
		this.loadSeriesTrailerData(this.props.match.params.showId);
		this.loadSimilarSeriesData(this.props.match.params.showId);
		this.loadSeriesImagesData(this.props.match.params.showId);
	}

	applyUserRating = (event) => {
		var x = event.target.getAttribute('rel');
		let ratingStars = document.querySelectorAll('.ion-ios-star-outline');
		let ratingStarsLit = document.querySelectorAll('.ion-ios-star');
		for (var index = 0; index < ratingStars.length; index++) {
			if (parseInt(ratingStars[index].getAttribute('rel')) <= parseInt(x)) {
				ratingStars[index].classList.add('ion-ios-star');
				ratingStars[index].classList.remove('ion-ios-star-outline');
			}
		}
		for (var index = 0; index < ratingStarsLit.length; index++) {
			if (parseInt(ratingStarsLit[index].getAttribute('rel')) > parseInt(x)) {
				ratingStarsLit[index].classList.remove('ion-ios-star');
				ratingStarsLit[index].classList.add('ion-ios-star-outline');
			}
		}

		const userrating = document.getElementById('userRating');
		const userratingtext = document.getElementById('userRatingText');

		if (parseInt(x) > 0) {
			userrating.classList.add('show-rating');
			userrating.classList.remove('hide-rating');
			userratingtext.innerHTML = x / 10 + '/10';
		} else {
			userrating.classList.remove('show-rating');
			userrating.classList.add('hide-rating');
		}
		event.preventDefault();
	};

	toggleTabs = (event) => {
		var currentAttrValue = $(event.target).attr('rel');
		var tabsCurrent = $('.tabs #' + currentAttrValue);
		tabsCurrent.show().siblings().hide();
		// Change/remove current tab to active
		$(event.target).parent('li').addClass('active').siblings().removeClass('active');
		event.preventDefault();
	};

	toggleTabsFromPage = (event) => {
		var currentAttrValue = $(event.target).attr('rel');
		var tabsCurrent = $('.tabs #' + currentAttrValue);
		tabsCurrent.show().siblings().hide();

		$('.tab-links.tabs-mv li a[rel="' + currentAttrValue + '"]')
			.parent('li')
			.addClass('active')
			.siblings()
			.removeClass('active');
		event.preventDefault();
	};

	getDirector(id) {
		let theDirector;
		this.state.pagecrewdata.map((currElement, index) => {
			if (currElement.job === 'Director') {
				theDirector = currElement.name;
			}
		});
		return theDirector;
	}

	getDirectorLink(id) {
		let theDirectorId;
		let theDirectorName;
		this.state.pagecrewdata.map((currElement, index) => {
			if (currElement.job === 'Director') {
				theDirectorId = currElement.id;
				theDirectorName = this.getPersonUriName(currElement.name);
			}
		});
		return '/people/' + theDirectorId + '/' + theDirectorName;
	}

	getWriter(id) {
		let theWriter;
		this.state.pagecrewdata.map((currElement, index) => {
			if (currElement.job === 'Screenplay' || currElement.job === 'Writer') {
				theWriter = currElement.name;
			}
		});
		return theWriter;
	}

	getWriterLink(id) {
		let theWriterId;
		let theWriterName;
		this.state.pagecrewdata.map((currElement, index) => {
			if (currElement.job === 'Screenplay') {
				theWriterId = currElement.id;
				theWriterName = this.getPersonUriName(currElement.name);
			}
		});
		return '/people/' + theWriterId + '/' + theWriterName;
	}

	getDirectorPic(id) {
		let theDirectorImage;
		this.state.pagecrewdata.map((currElement, index) => {
			if (currElement.job === 'Director') {
				theDirectorImage = currElement.profile_path;
			}
		});
		return 'https://image.tmdb.org/t/p/w45/' + theDirectorImage;
	}

	getYouTubeTrailer(id) {
		let thelink;
		this.state.pagetrailerdata.map((currElement, index) => {
			if (currElement.type === 'Trailer') {
				thelink = currElement.key;
			}
		});
		return 'https://www.youtube.com/watch?v=' + thelink;
	}

	goToMoviePage = (id, titleString, year) => {
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		//const rooturi = '';
		const page = '/movies/' + id + '/' + tt + '-' + year;
		//this.props.history.push(`${page}`);
		document.location = page;
	};

	getRandomMovie = () => {
		const movieCount = Object.keys(this.state.movies).length;
		const randNum = Math.floor(Math.random() * movieCount);
		const randMovie = Object.keys(this.state.movies).slice(
			// prettier-ignore
			randNum,
			randNum + 1
		);

		const myid = this.state.movies[randMovie].tmdb_id;
		const mytitle = this.state.movies[randMovie].title;
		const myyear = this.state.movies[randMovie].year;
		this.goToMoviePage(myid, mytitle, myyear);
	};

	applyCss(path) {
		$('.ht-footer').css('background', 'url(' + path + ')  no-repeat');
		$('.ht-footer').css('background-position', '100px 50%');
		$('.ht-footer').css('background-size', ' cover');
	}

	loadBgimageData(showId) {
		this.setState({ bgimgdata: [] });
		fetch('https://api.themoviedb.org/3/tv/' + showId + '?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((rdata) => {
				this.setState((prevState) => ({
					bgimgdata: prevState.bgimgdata.concat(rdata),
				}));
				const myimgpath = 'https://image.tmdb.org/t/p/w1920_and_h800_multi_faces' + rdata.backdrop_path;

				if (myimgpath !== null && myimgpath !== undefined) {
					this.applyCss(myimgpath);
				}
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	getFooterBgImage = () => {
		setTimeout(() => {
			this.loadBgimageData(this.props.match.params.showId);
		}, 250);
	};

	componentDidMount() {
		this.setState({ movies: movies });
		this.getPageData();
		setTimeout(() => {
			this.getFooterBgImage();
		}, 1000);
	}

	render() {
		if (this.state.pageseriesdata === null || this.state.pageseriesdata === undefined) {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='movie-item-style-2 movie-item-style-1'>loading</div>
				</React.Fragment>
			);
		} else {
			
			return (
				<React.Fragment>
					<Header getRandomMovie={this.getRandomMovie} />
					{this.state.pageseriesdata.map((i, index) => {
						return (
							<React.Fragment key={`card-frag-${i.name}`}>
								{/* prettier-ignore */}

								{i.backdrop_path == null && (
									<div
										key={`rand-card-${i.name}`}
										className='hero mv-single-hero'
										style={{
											background: 'url("' + this.getRandomBgImage() + '") no-repeat',
										}}>
										<div className='container'>
											<div className='row'>
												<div className='col-md-12'></div>
											</div>
										</div>
									</div>
								)}
								{i.backdrop_path !== null && (
									<div
										key={`card-${i.name}`}
										className='hero mv-single-hero'
										style={{
											background:
												'url("https://image.tmdb.org/t/p/w1920_and_h800_multi_faces/' +
												i.backdrop_path +
												'") no-repeat',
										}}>
										<div className='container'>
											<div className='row'>
												<div className='col-md-12'></div>
											</div>
										</div>
									</div>
								)}
								<div className='page-single movie-single movie_single' key={`card-single-${i.name}`}>
									<div className='container'>
										<div className='row ipad-width2'>
											<div className='col-md-4 col-sm-12 col-xs-12'>
												<div className='movie-img sticky-sb'>
													{i.poster_path == null && <img src={this.getRandomBgColor('300x450')} alt={i.name} />}
													{i.poster_path !== null && (
														<img src={'https://image.tmdb.org/t/p/w300' + i.poster_path} alt={i.name} />
													)}
													{this.state.pagetrailerdata.length > 0 && (
														<div className='movie-btn'>
															<div className='btn-transform transform-vertical red'>
																<div>
																	<a href='#' className='item item-1 redbtn'>
																		{' '}
																		<i className='ion-play'></i> Watch Trailer
																	</a>
																</div>
																<div>
																	<a
																		href={this.getYouTubeTrailer(i.id)}
																		className='item item-2 redbtn fancybox-media hvr-grow'>
																		<i className='ion-play'></i>
																	</a>
																</div>
															</div>
														</div>
													)}
												</div>
											</div>
											<div className='col-md-8 col-sm-12 col-xs-12'>
												<div className='movie-single-ct main-content'>
													<h1 className='bd-hd'>
														{i.name} &nbsp;
														{i.first_air_date !== null && i.last_air_date !== null && (
															<span>
																{' '}
																{this.getReleaseYear(i.first_air_date)} - {this.getReleaseYear(i.last_air_date)}
															</span>
														)}
													</h1>
													{Object.keys(this.state.pageseriessocialdata).map((key, index) => (
														<SeriesSocial key={key} details={this.state.pageseriessocialdata[key]} index={index}>
															{key}
														</SeriesSocial>
													))}

													<div className='movie-rate'>
														<div className='rate'>
															<i className='ion-android-star'></i>
															<p>
																<span>{this.roundDecimal(`${i.vote_average}`)}</span> /10
																<br />
																{this.state.pageseriesreviewsdata.length > 0 && (
																	<span className='rv'>{this.state.pageseriesreviewsdata.length} Reviews</span>
																)}
															</p>
														</div>
														<div className='rate-star'>
															<p style={{ fontSize: '14px' }}>Rate This Show: </p>
															<i
																style={{ width: '10px', height: '30px' }}
																rel='0'
																onMouseOver={(e) => this.applyUserRating(e)}></i>
															<i
																className='ion-ios-star-outline'
																rel='10'
																onMouseOver={(e) => this.applyUserRating(e)}></i>
															<i
																className='ion-ios-star-outline'
																rel='20'
																onMouseOver={(e) => this.applyUserRating(e)}></i>
															<i
																className='ion-ios-star-outline'
																rel='30'
																onMouseOver={(e) => this.applyUserRating(e)}></i>
															<i
																className='ion-ios-star-outline'
																rel='40'
																onMouseOver={(e) => this.applyUserRating(e)}></i>
															<i
																className='ion-ios-star-outline'
																rel='50'
																onMouseOver={(e) => this.applyUserRating(e)}></i>
															<i
																className='ion-ios-star-outline'
																rel='60'
																onMouseOver={(e) => this.applyUserRating(e)}></i>
															<i
																className='ion-ios-star-outline'
																rel='70'
																onMouseOver={(e) => this.applyUserRating(e)}></i>
															<i
																className='ion-ios-star-outline'
																rel='80'
																onMouseOver={(e) => this.applyUserRating(e)}></i>
															<i
																className='ion-ios-star-outline'
																rel='90'
																onMouseOver={(e) => this.applyUserRating(e)}></i>
															<i
																className='ion-ios-star-outline'
																rel='100'
																onMouseOver={(e) => this.applyUserRating(e)}></i>
															<p
																id='userRating'
																className='hide-rating'
																style={{ fontSize: '14px', paddingLeft: '15px' }}>
																Your Rating : <span id='userRatingText' style={{ color: '#f5b50a' }}></span>
															</p>
														</div>
													</div>
													<div className='movie-tabs'>
														<div className='tabs'>
															<ul className='tab-links tabs-mv tabs-series'>
																<li className='active'>
																	<a style={{ cursor: 'pointer' }} rel='overview' onClick={(e) => this.toggleTabs(e)}>
																		Overview
																	</a>
																</li>
																{this.state.pageseriesreviewsdata.length > 0 && (
																	<li>
																		<a style={{ cursor: 'pointer' }} rel='reviews' onClick={(e) => this.toggleTabs(e)}>
																			{' '}
																			Reviews
																		</a>
																	</li>
																)}
																<li>
																	<a style={{ cursor: 'pointer' }} rel='cast' onClick={(e) => this.toggleTabs(e)}>
																		{' '}
																		Cast{' '}
																	</a>
																</li>
																<li>
																	<a style={{ cursor: 'pointer' }} rel='media' onClick={(e) => this.toggleTabs(e)}>
																		{' '}
																		Posters
																	</a>
																</li>
																<li>
																	<a style={{ cursor: 'pointer' }} rel='season' onClick={(e) => this.toggleTabs(e)}>
																		{' '}
																		Seasons
																	</a>
																</li>
																{this.state.pagesimilarseriesdata.length > 0 && (
																	<li>
																		<a
																			style={{ cursor: 'pointer' }}
																			rel='moviesrelated'
																			onClick={(e) => this.toggleTabs(e)}>
																			{' '}
																			Similar Shows
																		</a>
																	</li>
																)}
															</ul>
															<div className='tab-content'>
																<div id='overview' className='tab active'>
																	<div className='row'>
																		<div className='col-md-8 col-sm-12 col-xs-12'>
																			<p>{i.overview}</p>
																			<div className='title-hd-sm'>
																				<h4>Current Season</h4>
																				<a
																					style={{ cursor: 'pointer' }}
																					rel='season'
																					onClick={(e) => this.toggleTabsFromPage(e)}>
																					View All {i.seasons.length} Seasons <i className='ion-ios-arrow-right'></i>
																				</a>
																			</div>

																			<div className='mvcast-item'>
																				<div className='cast-it'>
																					<div className='cast-left series-it'>
																						{i.poster_path == null && (
																							<img src={this.getRandomBgColor('92x138')} alt={i.name} />
																						)}
																						{i.poster_path !== null && (
																							<img
																								src={'https://image.tmdb.org/t/p/w92' + i.poster_path}
																								alt={i.name}
																							/>
																						)}
																						<div>
																							<a
																								href={
																									'/tv/' +
																									i.id +
																									'/' +
																									this.getPersonUriName(i.name) +
																									'-' +
																									this.getReleaseYear(i.first_air_date) +
																									'/season-' +
																									i.number_of_seasons
																								}>
																								Season {i.number_of_seasons}
																							</a>

																							{i.seasons.map((x, index) => (
																								<React.Fragment key={`seasons-${x.name}`}>
																									<p key={`season-${x.name}`}>
																										{x.season_number === i.number_of_seasons && (
																											<span> {x.episode_count} Episodes </span>
																										)}
																									</p>
																									{x.season_number === i.number_of_seasons && (
																										<p key={`season2-${x.name}`} style={{ width: '100%' }}>
																											<span className='time'>
																												Premiered on {this.getFormattedDate(x.air_date)}
																											</span>
																											<br></br>
																											<span>{x.overview}</span>
																										</p>
																									)}
																								</React.Fragment>
																							))}
																						</div>
																					</div>
																				</div>
																			</div>
																			{this.state.pageseriesimagesdata.length > 0 && (
																				<div className='title-hd-sm'>
																					<h4>Posters</h4>

																					<a
																						style={{ cursor: 'pointer' }}
																						rel='media'
																						onClick={(e) => this.toggleTabsFromPage(e)}
																						className='time'>
																						All {this.state.pageseriesimagesdata.length} Posters{' '}
																						<i className='ion-ios-arrow-right'></i>
																					</a>
																				</div>
																			)}
																			<div className='mvsingle-item ov-item'>
																				{this.state.pageseriesimagesdata.slice(0, 3).map((x, index) => (
																					<a
																						key={`photo-${index}`}
																						className='img-lightbox'
																						data-fancybox-group='gallery'
																						href={'https://image.tmdb.org/t/p/w500/' + x.file_path}>
																						<img
																							key={i.poster_path}
																							src={'https://image.tmdb.org/t/p/w154/' + x.file_path}
																						/>
																					</a>
																				))}
																			</div>
																			<div className='title-hd-sm'>
																				<h4>cast</h4>
																				<a
																					style={{ cursor: 'pointer' }}
																					rel='cast'
																					onClick={(e) => this.toggleTabsFromPage(e)}>
																					Full Cast <i className='ion-ios-arrow-right'></i>
																				</a>
																			</div>
																			<div className='mvcast-item'>
																				{Object.keys(this.state.pagecastdata)
																					.slice(
																						// prettier-ignore
																						0,
																						10
																					)
																					.map((key, index) => (
																						<Person
																							key={`person1-${index}`}
																							details={this.state.pagecastdata[key]}
																							index={index}>
																							{key}
																						</Person>
																					))}
																			</div>

																			{this.state.pageseriesreviewsdata.length > 0 && (
																				<>
																					{this.state.pageseriesreviewsdata.length > 0 && (
																						<div className='title-hd-sm'>
																							<h4>Reviews</h4>

																							<a
																								className='time toggleButt'
																								style={{ cursor: 'pointer' }}
																								rel='reviews'
																								onClick={(e) => this.toggleTabsFromPage(e)}>
																								See All {this.state.pageseriesreviewsdata.length} Reviews{' '}
																								<i className='ion-ios-arrow-right'></i>
																							</a>
																						</div>
																					)}

																					{this.state.pageseriesreviewsdata.length > 0 && (
																						<div>
																							{Object.keys(this.state.pageseriesreviewsdata)
																								.slice(0, 2)
																								.map((key, index) => (
																									<Review
																										key={`review-${index}`}
																										details={this.state.pageseriesreviewsdata[key]}
																										index={index}
																										count={this.state.pageseriesreviewsdata.length}>
																										{key}
																									</Review>
																								))}
																						</div>
																					)}
																				</>
																			)}
																		</div>
																		{/*  */}
																		<div className='col-md-4 col-xs-12 col-sm-12'>
																			<div
																				className='sb-it'
																				style={{
																					backgroundColor: '#ccc',
																					borderRadius: '10px',
																					padding: '10px',
																				}}>
																				<p className='no_bottom_pad'>
																					<strong>
																						<span style={{ color: 'black' }}>Airing on:</span>
																					</strong>
																				</p>
																				{i.networks.map((x) => (
																					<p key={`networks-${x.name}`}>
																						<a href='/network/6?language=en-US'>
																							<img
																								className='logo'
																								alt={x.name}
																								title={x.name}
																								src={'https://image.tmdb.org/t/p/h30/' + x.logo_path}
																							/>
																						</a>
																					</p>
																				))}
																			</div>
																		</div>
																		{/*  */}

																		<div className='col-md-4 col-xs-12 col-sm-12'>
																			<div className='sb-it'>
																				<h6>Stars: </h6>
																				<p>
																					{Object.keys(this.state.pagecastdata)
																						.slice(0, 5)
																						.map((key, index) => (
																							<Stars
																								key={`stars-${index}`}
																								details={this.state.pagecastdata[key]}
																								index={index}>
																								{key}
																							</Stars>
																						))}
																				</p>
																			</div>
																			{i.genres.length > 0 && (
																				<div className='sb-it'>
																					<h6>Genres:</h6>
																					<p className='tags'>
																						{i.genres.map((x) => (
																							<span
																								key={`genres-${x.name}`}
																								className='time'
																								style={{
																									display: 'inlineBlock',
																								}}>
																								<a style={this.colorKeyword()} href='#'>
																									{x.name}
																								</a>
																							</span>
																						))}
																					</p>
																				</div>
																			)}
																			{i.first_air_date !== null && (
																				<div className='sb-it'>
																					<h6>First Air Date:</h6>
																					<p>{this.getFormattedDate(i.first_air_date)} </p>
																				</div>
																			)}
																			<div className='sb-it'>
																				<h6>Run Time:</h6>
																				{i.episode_run_time.map((x, index) => (
																					<p key={`runtime-${x}`}>{x} min</p>
																				))}
																			</div>
																			<div className='sb-it'>
																				{this.state.pageseriescontentratingsdata.map(
																					(x, index) =>
																						x.iso_3166_1 == 'US' && (
																							<React.Fragment key={`mpaa-${x.index}`}>
																								<h6>MMPA Rating:</h6>
																								<p>{x.rating}</p>
																							</React.Fragment>
																						)
																				)}
																			</div>
																			{this.state.pageserieskeywordsdata.length > 0 && (
																				<div className='sb-it'>
																					<h6>Plot Keywords:</h6>
																					<p className='tags'>
																						{this.state.pageserieskeywordsdata.map((x, index) => (
																							<span
																								key={`keyword-${x.name}`}
																								className='time'
																								style={{
																									display: 'inlineBlock',
																								}}>
																								<a style={this.colorKeyword()} href='#'>
																									{x.name}
																								</a>
																							</span>
																						))}
																					</p>
																				</div>
																			)}
																		</div>
																	</div>
																</div>
																<div id='reviews' className='tab review'>
																	<div className='row'>
																		<div className='rv-hd'>
																			<div className='div'>
																				<h3>Reviews of</h3>
																				<h2>{i.name}</h2>
																			</div>
																		</div>
																		<div className='topbar-filter'>
																			<p>
																				Found <span>{this.state.pageseriesreviewsdata.length} reviews</span> in total
																			</p>
																			<label>Filter by:</label>
																			<select>
																				<option value='popularity'>Popularity Descending</option>
																				<option value='popularity'>Popularity Ascending</option>
																				<option value='rating'>Rating Descending</option>
																				<option value='rating'>Rating Ascending</option>
																				<option value='date'>Release date Descending</option>
																				<option value='date'>Release date Ascending</option>
																			</select>
																		</div>
																		{this.state.pageseriesreviewsdata.length > 0 && (
																			<div>
																				{Object.keys(this.state.pageseriesreviewsdata).map((key, index) => (
																					<Review
																						key={`review-${index}`}
																						details={this.state.pageseriesreviewsdata[key]}
																						index={index}
																						count={this.state.pageseriesreviewsdata.length}>
																						{key}
																					</Review>
																				))}
																			</div>
																		)}
																		<div className='topbar-filter'>
																			<label>Reviews per page:</label>
																			<select>
																				<option value='range'>5 Reviews</option>
																				<option value='saab'>10 Reviews</option>
																			</select>
																			<div className='pagination2'>
																				<span>Page 1 of 6:</span>
																				<a className='active' href='#'>
																					1
																				</a>
																				<a href='#'>2</a>
																				<a href='#'>3</a>
																				<a href='#'>4</a>
																				<a href='#'>5</a>
																				<a href='#'>6</a>
																				<a href='#'>
																					<i className='ion-arrow-right-b'></i>
																				</a>
																			</div>
																		</div>
																	</div>
																</div>
																<div id='cast' className='tab'>
																	<div className='row'>
																		<h3>Cast of</h3>
																		<h2>{i.name}</h2>
																		<div className='title-hd-sm'>
																			<h4>Cast</h4>
																		</div>
																		<div className='mvcast-item'>
																			{Object.keys(this.state.pagecastdata).map((key, index) => (
																				<Person
																					key={`Person-${index}`}
																					details={this.state.pagecastdata[key]}
																					index={index}>
																					{key}
																				</Person>
																			))}
																		</div>

																		{this.state.pagecrewdata.length > 0 && (
																			<>
																				<div className='title-hd-sm'>
																					<h4>Directors & Credit Writers</h4>
																				</div>
																				<div className='mvcast-item'>
																					{Object.keys(this.state.pagecrewdata).map((key, index) => (
																						<Person
																							key={`crew-${index}`}
																							details={this.state.pagecrewdata[key]}
																							index={index}>
																							{key}
																						</Person>
																					))}
																				</div>
																			</>
																		)}
																	</div>
																</div>
																<div id='media' className='tab'>
																	<div className='row'>
																		<div className='rv-hd'>
																			<div>
																				<h3>Posters of</h3>
																				<h2>{i.name}</h2>
																			</div>
																		</div>

																		<div className='title-hd-sm'>
																			<h4>
																				Photos <span> ({this.state.pageseriesimagesdata.length})</span>
																			</h4>
																		</div>
																		<div className='mvsingle-item' style={{ display: 'block' }}>
																			{this.state.pageseriesimagesdata.map((x, index) => (
																				<a
																					key={`photo2-${index}`}
																					className='img-lightbox'
																					data-fancybox-group='gallery'
																					href={'https://image.tmdb.org/t/p/w500/' + x.file_path}>
																					<img
																						style={{
																							padding: '5px 10px 5px 10px',
																						}}
																						key={i.poster_path}
																						src={'https://image.tmdb.org/t/p/w92/' + x.file_path}
																					/>
																				</a>
																			))}
																		</div>
																	</div>
																</div>
																<div id='season' className='tab'>
																	<div className='row'>
																		{i.seasons.map((x, index) => (
																			<React.Fragment key={`seasons-${x.name}`}>
																				<div className='mvcast-item'>
																					<div className='cast-it'>
																						<div className='cast-left series-it'>
																							{i.seasons[index].poster_path == null && (
																								<img src={this.getRandomBgColor('92x138')} alt={i.name} />
																							)}
																							{i.seasons[index].poster_path !== null && (
																								<img
																									style={{ verticalAlign: 'top' }}
																									src={'https://image.tmdb.org/t/p/w92' + i.seasons[index].poster_path}
																									alt={i.name}
																								/>
																							)}
																							<div>
																								{x.season_number == 0 && <a href='#'>Season Specials</a>}
																								{x.season_number > 0 && (
																									<a
																										href={
																											'/tv/' +
																											i.id +
																											'/' +
																											this.getPersonUriName(i.name) +
																											'-' +
																											this.getReleaseYear(i.first_air_date) +
																											'/season-' +
																											x.season_number
																										}>
																										Season {x.season_number}
																									</a>
																								)}
																								{x.season_number > 0 && (
																									<p key={`season-${x.name}`}>
																										<span> {x.episode_count} Episodes </span>
																									</p>
																								)}

																								<p key={`season2-${x.name}`} style={{ width: '100%' }}>
																									{x.season_number > 0 && x.air_date !== null && (
																										<>
																											<span className='time'>
																												Season {x.season_number} of {i.name} premiered on{' '}
																												{this.getFormattedDate(x.air_date)}
																											</span>
																											<br></br>
																											<span>{x.overview}</span>
																											{x.overview == '' && <span>No season summay available.</span>}
																										</>
																									)}
																								</p>
																							</div>
																						</div>
																					</div>
																				</div>
																			</React.Fragment>
																		))}
																	</div>
																</div>
																<div id='moviesrelated' className='tab'>
																	<div className='row'>
																		<h3>Shows similar to</h3>
																		<h2>{i.name}</h2>
																		<div className='topbar-filter'>
																			<p>
																				Found <span>{this.state.pagesimilarseriesdata.length} shows</span> in total
																			</p>
																			<label>Sort by:</label>
																			<select>
																				<option value='popularity'>Popularity Descending</option>
																				<option value='popularity'>Popularity Ascending</option>
																				<option value='rating'>Rating Descending</option>
																				<option value='rating'>Rating Ascending</option>
																				<option value='date'>Release date Descending</option>
																				<option value='date'>Release date Ascending</option>
																			</select>
																		</div>
																		{/*  */}
																		{Object.keys(this.state.pagesimilarseriesdata).map((key, index) => (
																			<SimilarSeries
																				key={`similar-${index}`}
																				details={this.state.pagesimilarseriesdata[key]}
																				pagedetails={this.state.pagedata}
																				castdetails={this.state.pagecastdata}
																				crewdetails={this.state.pagecrewdata}
																				index={index}>
																				{key}
																			</SimilarSeries>
																		))}
																		{/*  */}
																		<div className='topbar-filter'>
																			<label>Movies per page:</label>
																			<select>
																				<option value='range'>5 Movies</option>
																				<option value='saab'>10 Movies</option>
																			</select>
																			<div className='pagination2'>
																				<span>Page 1 of 2:</span>
																				<a className='active' href='#'>
																					1
																				</a>
																				<a href='#'>2</a>
																				<a href='#'>
																					<i className='ion-arrow-right-b'></i>
																				</a>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</React.Fragment>
						);
					})}
					<Footer getRandomMovie={this.getRandomMovie} />
				</React.Fragment>
			);
		}
	}
}

export default SeriesSingle;
