/** @format */

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import movies from "../movies";
import RecentlyAdded from "./RecentlyAdded";
import InTheatres from "./InTheatres";
import Trailers from "./Trailers";
import OnTv from "./OnTv";
import HomeSidebar from "./HomeSidebar";
import $ from "jquery";

class Home extends React.Component {
  state = {
    movies: {},
    pagedata: [],
    moviesintheatresdata: [],
    pagetrailerdata: [],
    popularpeopledata: [],
    tvairingtodaydata: [],
    randImageData: [],
    authData: [],
    listViewType: "grid",
  };

  createSessionId(token) {
    const data = { request_token: token };

    //POST request with body equal on data in JSON format
    fetch(
      "https://api.themoviedb.org/3/authentication/session/new?api_key=83747b00d208337448cd133b2110d874",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log("Success:", data);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  getTmdbAuthentication() {
    fetch(
      "https://api.themoviedb.org/3/authentication/token/new?api_key=83747b00d208337448cd133b2110d874"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((authdata) => {
        this.setState((prevState) => ({
          authData: prevState.authData.concat(authdata),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }
  loadMoviesData(movieid, count) {
    //console.log(movieid);
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        movieid +
        "?api_key=83747b00d208337448cd133b2110d874"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((data) => {
        this.setState((prevState) => ({
          pagedata: prevState.pagedata.concat(data),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  loadMoviesTrailerData(movieid, count) {
    //console.log(movieid);
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        movieid +
        "/videos?api_key=83747b00d208337448cd133b2110d874&language=en-US"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((tdata) => {
        this.setState((prevState) => ({
          pagetrailerdata: prevState.pagetrailerdata.concat(tdata.results[0]),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  loadMoviesInTheatresData() {
    //console.log(movieid);
    fetch(
      "https://api.themoviedb.org/3/movie/now_playing?api_key=83747b00d208337448cd133b2110d874&language=en-US&page=1&region=US"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((npdata) => {
        this.setState((prevState) => ({
          moviesintheatresdata: prevState.moviesintheatresdata.concat(
            npdata.results
          ),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  loadPopularPeopleData() {
    fetch(
      "https://api.themoviedb.org/3/person/popular?api_key=83747b00d208337448cd133b2110d874&language=en-US&page=1"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((ppdata) => {
        this.setState((prevState) => ({
          popularpeopledata: prevState.popularpeopledata.concat(ppdata.results),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

    //
  loadTvAiringTodayData() {
    fetch(
      "https://api.themoviedb.org/3/tv/airing_today?api_key=83747b00d208337448cd133b2110d874&language=en-US&page=1"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((tvdata) => {
        this.setState((prevState) => ({
          tvairingtodaydata: prevState.tvairingtodaydata.concat(tvdata.results),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  getPageData() {
    this.loadMoviesInTheatresData();
    this.loadPopularPeopleData();
    this.loadTvAiringTodayData();
  }

  getPagination() {
    Object.keys(this.state.movies)
      .slice(
        // prettier-ignore
        0,
        20
      )
      .map((key) => {
        this.loadMoviesData(this.state.movies[key].tmdb_id);
        this.loadMoviesTrailerData(this.state.movies[key].tmdb_id);
      });
  }

  // return the year fragment from a tmdb date node
  getReleaseYear = (date) => {
    const year = date.split("-")[0];
    return year;
  };
  getUriSafeTitle = (titleString) => {
    //alert('!');
    const regex = / /gi;
    const regexDash = /---/gi;
    const regexspecial = /[:'()…,'!.?\][]/gi;
    const regexAmp = /&/gi;
    const regexSlash = /[/]/gi;
    const regexMisc = /½/gi;
    const regexSup2 = /[²]/gi;
    const regexSup3 = /[³]/gi;

    const tt = titleString
      .replace(regex, "-")
      .replace(regexDash, "-")
      .replace(regexspecial, "")
      .replace(regexAmp, "and")
      .replace(regexSlash, "-")
      .replace(regexMisc, "-and-a-half")
      .replace(regexSup2, "-2")
      .replace(regexSup3, "-3")
      .toLocaleLowerCase();

    return tt;
    //const page = '/movies/' + id + '/' + tt + '-' + year;
    //document.location = page;
  };

  goToMoviePage = (id, titleString, year) => {
    const regex = / /gi;
    const regexDash = /---/gi;
    const regexspecial = /[:'()…,'!.?\][]/gi;
    const regexAmp = /&/gi;
    const regexSlash = /[/]/gi;
    const regexMisc = /½/gi;
    const regexSup2 = /[²]/gi;
    const regexSup3 = /[³]/gi;

    const tt = titleString
      .replace(regex, "-")
      .replace(regexDash, "-")
      .replace(regexspecial, "")
      .replace(regexAmp, "and")
      .replace(regexSlash, "-")
      .replace(regexMisc, "-and-a-half")
      .replace(regexSup2, "-2")
      .replace(regexSup3, "-3")
      .toLocaleLowerCase();

    const page = "/movies/" + id + "/" + tt + "-" + year;
    document.location = page;
  };

  getRandomMovie = () => {
    const movieCount = Object.keys(this.state.movies).length;
    const randNum = Math.floor(Math.random() * movieCount);
    const randMovie = Object.keys(this.state.movies).slice(
      // prettier-ignore
      randNum,
      randNum + 1
    );

    const myid = this.state.movies[randMovie].tmdb_id;
    const mytitle = this.state.movies[randMovie].title;
    const myyear = this.state.movies[randMovie].year;
    this.goToMoviePage(myid, mytitle, myyear);
  };

  applyCss(path) {
    var status = $("#status");
    var preloader = $("#preloader");
    var body = $("body");
    preloader.css("display", "block");
    status.css("display", "block");
    $(".slider").css("background", "url(" + path + ")  no-repeat");
    $(".slider").css("background-position", "100px top");
    $(".slider").css("background-size", " cover");

    $(".ht-footer").css("background", "url(" + path + ")  no-repeat");
    $(".ht-footer").css("background-position", "100px 50%");
    $(".ht-footer").css("background-size", " cover");

    body.delay(1000).css({ overflow: "visible" });
    preloader.delay(1000).fadeOut("fast"); // will fade out the white DIV that covers the website.
    status.fadeIn(); // will first fade 69out the loading animation
  }

  loadBgimageData(randommovieid, count) {
    this.setState({ randImageData: [] });
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        randommovieid +
        "?api_key=83747b00d208337448cd133b2110d874"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((rdata) => {
        this.setState((prevState) => ({
          randImageData: prevState.randImageData.concat(rdata),
        }));

        const myimgpath =
          "https://image.tmdb.org/t/p/w1920_and_h800_multi_faces" +
          rdata.backdrop_path;

        if (myimgpath !== null && myimgpath !== undefined) {
          this.applyCss(myimgpath);
          const footInfo = document.getElementById("randMovieInfo");
          const title = this.getUriSafeTitle(rdata.title);
          const year = this.getReleaseYear(rdata.release_date);
          footInfo.innerHTML = `The random background image is from <a href='/movies/${rdata.id}/${title}-${year}'>${rdata.title}</a>`;
        }
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  getRandomBackgroundImage = () => {
    setTimeout(() => {
      const movieCount = Object.keys(this.state.movies).length;
      const randNum = Math.floor(Math.random() * movieCount);
      const randMovie = Object.keys(this.state.movies).slice(
        // prettier-ignore
        randNum,
        randNum + 1
      );
      const myid = this.state.movies[randMovie].tmdb_id;
      this.loadBgimageData(myid);
    }, 250);
  };

  tmdbLogin = (e) => {
    e.preventDefault();

    if (this.state.authData[0] !== undefined) {
      var exp_date = this.state.authData[0].expires_at; // get the expiry date
      var rt = this.state.authData[0].request_token; // get the request token

      var newdate = Date.now(); // get the date now
      var exd = new Date(exp_date); // convert to milliseconds
      var expiredate = exd.getTime(); //convert to milliseconds

      this.createSessionId(rt);
    }

    console.log(
      "you clicked me!!" +
        " " +
        rt +
        " " +
        exp_date +
        " " +
        newdate +
        " " +
        expiredate
    );
  };

  componentWillMount() {
    var status = $("#status");
    var preloader = $("#preloader");
    preloader.css("display", "block");
    status.css("display", "block");
  }

  componentDidMount() {
    this.setState({ movies: movies });
    this.getPageData();

    setTimeout(() => {
      this.getPagination();
      this.getTmdbAuthentication();
    }, 0);
    setTimeout(() => {
      this.getRandomBackgroundImage();
    }, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Header
          getRandomMovie={this.getRandomMovie}
          tmdbLogin={this.tmdbLogin}
        />

        <div className="slider movie-items trailers">
          <div className="container">
            {/* <div className='social-link'>
							<p>Follow us: </p>
							<a href='#'>
								<i className='ion-social-facebook'></i>
							</a>
							<a href='#'>
								<i className='ion-social-twitter'></i>
							</a>
							<a href='#'>
								<i className='ion-social-googleplus'></i>
							</a>
							<a href='#'>
								<i className='ion-social-youtube'></i>
							</a>
						</div> */}

            <div
              className="row ipad-width"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              <div className="col-md-12">
                <div className="title-hd" style={{ marginBottom: "0px" }}>
                  <h2>Recently Added</h2>
                  <a href="/movies/pages/1/" className="viewall">
                    View all <i className="ion-ios-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            {/* recently added SLICK */}
            <RecentlyAdded pagedata={this.state.pagedata} />
            {/* END recently added SLICK */}
          </div>
        </div>

        {/* IN THEATRES / ON TV SECTION  */}
        <div className="movie-items">
          <div className="container">
            <div className="row ipad-width">
              <div className="col-md-8">
                <InTheatres pagedata={this.state.moviesintheatresdata} />
                <OnTv
                  pagedata={this.state.pagedata}
                  movies={this.state.movies}
                  tvdata={this.state.tvairingtodaydata}
                />
              </div>
              <div className="col-md-4">
                {/* homepage sidebar */}
                <HomeSidebar pagedata={this.state.popularpeopledata} />
                {/* end of homepage sidebar */}
              </div>
            </div>
          </div>
        </div>
        {/* END IN THEATRES / ON TV SECTION  */}

        {/* TRAILERS SECTION */}
        <Trailers pagedata={this.state.pagetrailerdata} />
        {/* END TRAILERS SECTION */}

        {/* LATEST NEWS */}
        {/* <LatestNews pagedata={this.state.pagedata} /> */}
        {/* END LATEST NEWS */}

        <Footer getRandomMovie={this.getRandomMovie} />
      </React.Fragment>
    );
  }
}

export default Home;
