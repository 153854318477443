/** @format */

import React from 'react';

class RecentlyAdded extends React.Component {
	// reduce decimal to one place
	roundDecimal = (float) => {
		const rounded = Math.round(float * 10) / 10;
		return rounded;
	};

	// event delegation for the Movies links.
	handleClick(e, id, titleString, year) {
		e.preventDefault();
		this.goToMoviePage(id, titleString, year);
	}
	// make a random color for the placeholder link
	// for movies with no poster available.
	getRandomBgColor = () => {
		var somecolors = [
			'c238c2',
			'c23838',
			'614875',
			'2548a1',
			'25a14c',
			'9ba125',
			'a17a25',
			'a12525',
			'f0820c',
			'd61773',
			'ce17d4',
			'601ea6',
			'2a1fa6',
			'0bb8db',
			'0dd91e',
			'388c3f',
			'378a73',
			'd45137',
		];

		const rand = Math.floor(Math.random() * somecolors.length);
		const uriSegA = 'https://dummyimage.com/285x437/';
		const uriSegB = '/ffffff.png&text=Poster+Not+Available';
		return uriSegA + somecolors[rand] + uriSegB;
	};

	// take movie title, return URI friendly string.
	goToMoviePage = (id, titleString, year) => {
		//alert('!');
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		const rooturi = '';
		const page = '/movies/' + id + '/' + tt + '-' + year;
		//this.props.history.push(`${page}`);
		document.location = rooturi + page;
	};
	render() {
		if (this.props.pagedata === null || this.props.pagedata === undefined) {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='movie-item-style-2 movie-item-style-1'>loading</div>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<div className='slick-multiItemSlider'>
						{this.props.pagedata.map((i) => {
							return (
								<div className='movie-item' key={i.id}>
									<div className='mv-img'>
										<a href='#'>
											{i.poster_path == null && (
												<img src={this.getRandomBgColor()} alt={i.title} />
											)}
											{i.poster_path !== null && (
												<img
													src={
														'https://image.tmdb.org/t/p/w300/' + i.poster_path
													}
													alt={i.title}
													style={{ minHeight: '450px' }}
												/>
											)}
										</a>
									</div>
									<div className='hvr-inner'>
										<a
											style={{ cursor: 'pointer' }}
											onClick={(e) =>
												this.handleClick(
													e,
													`${i.id}`,
													`${i.title}`,
													`${i.release_date.split('-')[0]}`
												)
											}>
											{' '}
											Read more <i className='ion-android-arrow-dropright'></i>{' '}
										</a>
									</div>
									<div className='title-in'>
										<div className='cate'>
											{i.genres.slice(0, 3).map((j, index) => (
												<React.Fragment key={`x-${j.name}`}>
													<span
														style={{
															border: '1px solid #fff',
															marginRight: '6px',
														}}
														className='blue'>
														<a href='#'>{j.name}</a>
													</span>
												</React.Fragment>
											))}
										</div>
										<h6>
											<a
												style={{ cursor: 'pointer' }}
												onClick={(e) =>
													this.handleClick(
														e,
														`${i.id}`,
														`${i.title}`,
														`${i.release_date.split('-')[0]}`
													)
												}>
												{i.title}
											</a>
										</h6>
										<p>
											<i className='ion-android-star'></i>
											<span>{this.roundDecimal(`${i.vote_average}`)}</span> /10
										</p>
									</div>
								</div>
							);
						})}
					</div>
				</React.Fragment>
			);
		}
	}
}

export default RecentlyAdded;
