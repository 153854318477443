/** @format */

import React from 'react';
import { withRouter } from 'react-router';

class SimilarSeries extends React.Component {
	// event delegation for the Movies links.

	handleClick(e, id, titleString, year) {
		e.preventDefault();
		this.goToMoviePage(id, titleString, year);
	}

	// make a random color for the placeholder link
	// for movies with no poster available.
	getRandomBgColor = () => {
		var somecolors = [
			'c238c2',
			'c23838',
			'614875',
			'2548a1',
			'25a14c',
			'9ba125',
			'a17a25',
			'a12525',
			'f0820c',
			'd61773',
			'ce17d4',
			'601ea6',
			'2a1fa6',
			'0bb8db',
			'0dd91e',
			'388c3f',
			'378a73',
			'd45137',
		];

		const rand = Math.floor(Math.random() * somecolors.length);
		const uriSegA = 'https://dummyimage.com/185x278/';
		const uriSegB = '/ffffff.png&text=Poster+Not+Available';
		return uriSegA + somecolors[rand] + uriSegB;
	};

	getDirector(id) {
		let theDirector;
		this.props.crewdetails.map((currElement) => {
			if (currElement.job === 'Director') {
				theDirector = currElement.name;
			}
		});
		return theDirector;
	}

	stripLeadZero(int) {
		if (int < 10) {
			return int.split('')[1];
		} else {
			return int;
		}
	}

	handleClick(e, id, titleString, year) {
		e.preventDefault();
		this.goToMoviePage(id, titleString, year);
	}

	getReleaseYear = (date) => {
		if (date !== undefined) {
			const year = date.split('-')[0];
			return year;
		} else {
			return '';
		}
	};

	goToMoviePage = (id, titleString, year) => {
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		const page = tt + '-' + year;
		return page;
	};

	getFormattedDate(utcDate) {
		let months = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];
		let dateFrags = utcDate.split('-');
		return months[parseInt(dateFrags[1] - 1)] + ' ' + this.stripLeadZero(dateFrags[2]) + ', ' + dateFrags[0];
	}

	render() {
		if (this.props.details === null || this.props.details === undefined) {
			// Render loading state ...
			return <React.Fragment></React.Fragment>;
		} else {
			const { id, overview, name, vote_average, first_air_date, poster_path } = this.props.details;
			return (
				<React.Fragment key={`sim-frag-${name}`}>
					<div className='movie-item-style-2'>
						{poster_path == null && <img src={this.getRandomBgColor()} alt={name} />}
						{poster_path !== null && <img src={'https://image.tmdb.org/t/p/w185/' + poster_path} alt={name} />}
						<div className='mv-item-infor'>
							<h6>
								{/* prettier-ignore */}
								<a
									href={
										'/tv/' +
										id +
										'/' +
										this.goToMoviePage(
											id,
                                            name,
                                            this.getReleaseYear(first_air_date)
										)
									}>
                                    {name}
                                    <span>({this.getReleaseYear(first_air_date)})</span></a>{' '}
							</h6>
							<p className='rate'>
								<i className='ion-android-star'></i>
								<span>{vote_average}</span> /10
							</p>
							<p className='describe'>{overview}</p>
						</div>
					</div>
				</React.Fragment>
			);
		}
	}
}

export default withRouter(SimilarSeries);
