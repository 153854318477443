/** @format */

import React from 'react';
import FavouriteMovies from './FavouriteMovies';
import Header from './Header';
import Footer from './Footer';
import movies from '../movies';
import $ from 'jquery';
import base from '../base';

class Favourites extends React.Component {
	state = {
		movies: {},
		pagedata: [],
		chunkSize: 60,
		startPage: 1,
		pageNum: 1,
		pageIndex: 0,
		cardCount: 0,
		listViewType: 'grid',
		favouriteMovies: [],
	};

	loadMoviesData(movieid, count) {
		fetch('https://api.themoviedb.org/3/movie/' + movieid + '?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((data) => {
				this.setState((prevState) => ({
					pagedata: prevState.pagedata.concat(data),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	getPagination() {
		var status = $('#status');
		var preloader = $('#preloader');
		var body = $('body');
		preloader.css('display', 'block');
		status.css('display', 'block');

		Object.keys(this.state.favouriteMovies).map((key) => this.loadMoviesData(this.state.movies[key].tmdb_id));
		body.delay(1000).css({ overflow: 'visible' });
		preloader.delay(1000).fadeOut('fast'); // will fade out the white DIV that covers the website.
		status.fadeIn(); // will first fade out the loading animation
	}

	setChunkSize = (amt) => {
		this.setState({ chunkSize: parseInt(amt) });
		this.getPagination();
	};

	setPageView = (viewtype) => {
		this.setState({ listViewType: viewtype });
		//alert(viewtype);
	};

	//prettier-ignore
	increasePageNum = (num) => {
		console.log('current page is: ' + this.state.pageNum);
		console.log('target page is: ' + (this.state.pageNum + 1));
		console.log();
		this.setState({ pageNum: (this.state.pageNum + 1) });
		this.setState({ pagedata: [] });
		document.location = '/favourites/pages/' + num + '/'
	};

	//prettier-ignore
	decreasePageNum = (num) => {
		console.log('current page is: ' + this.state.pageNum);
		console.log('target page is: ' + (this.state.pageNum - 1));
		this.setState({ pageNum: (this.state.pageNum - 1) });
		this.setState({ pagedata: [] });
		document.location = '/favourites/pages/' + num + '/'
	};

	goToMoviePage = (id, titleString, year) => {
		//alert('!');
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		const page = '/movies/' + id + '/' + tt + '-' + year;
		document.location = page;
	};

	getRandomMovie = () => {
		const movieCount = Object.keys(this.state.movies).length;

		const randNum = Math.floor(Math.random() * movieCount);
		const randMovie = Object.keys(this.state.movies).slice(
			// prettier-ignore
			randNum,
			randNum + 1
		);

		const myid = this.state.movies[randMovie].tmdb_id;
		const mytitle = this.state.movies[randMovie].title;
		const myyear = this.state.movies[randMovie].year;
		this.goToMoviePage(myid, mytitle, myyear);
	};

	applyCss(path) {
		var status = $('#status');
		var preloader = $('#preloader');
		var body = $('body');
		preloader.css('display', 'block');
		status.css('display', 'block');
		$('.common-hero').css('background', 'url(' + path + ')  no-repeat');
		$('.common-hero').css('background-position', '100px 50%');
		$('.common-hero').css('background-size', ' cover');

		$('.ht-footer').css('background', 'url(' + path + ')  no-repeat');
		$('.ht-footer').css('background-position', '100px 50%');
		$('.ht-footer').css('background-size', ' cover');

		body.delay(1000).css({ overflow: 'visible' });
		preloader.delay(1000).fadeOut('fast'); // will fade out the white DIV that covers the website.
		status.fadeIn(); // will first fade 69out the loading animation
	}

	// return the year fragment from a tmdb date node
	getReleaseYear = (date) => {
		const year = date.split('-')[0];
		return year;
	};
	getUriSafeTitle = (titleString) => {
		//alert('!');
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		return tt;
		//const page = '/movies/' + id + '/' + tt + '-' + year;
		//document.location = page;
	};

	loadBgimageData(randommovieid, count) {
		this.setState({ randImageData: [] });
		fetch('https://api.themoviedb.org/3/movie/' + randommovieid + '?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((rdata) => {
				this.setState((prevState) => ({
					randImageData: prevState.randImageData.concat(rdata),
				}));

				const myimgpath = 'https://image.tmdb.org/t/p/w1920_and_h800_multi_faces' + rdata.backdrop_path;

				if (myimgpath !== null && myimgpath !== undefined) {
					this.applyCss(myimgpath);
					const footInfo = document.getElementById('randMovieInfo');
					const title = this.getUriSafeTitle(rdata.title);
					const year = this.getReleaseYear(rdata.release_date);
					footInfo.innerHTML = `The random background image is from <a href='/movies/${rdata.id}/${title}-${year}'>${rdata.title}</a>`;
				}
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	getRandomBackgroundImage = () => {
		setTimeout(() => {
			const movieCount = Object.keys(this.state.movies).length;
			const randNum = Math.floor(Math.random() * movieCount);
			const randMovie = Object.keys(this.state.movies).slice(
				// prettier-ignore
				randNum,
				randNum + 1
			);
			const myid = this.state.movies[randMovie].tmdb_id;
			this.loadBgimageData(myid);
		}, 250);
	};

	componentWillMount() {
		var status = $('#status');
		var preloader = $('#preloader');
		preloader.css('display', 'block');
		status.css('display', 'block');
	}

	getFavourites = () => {
		base.fetch(`favouritemovies`, {
			context: this,
			asArray: true,
			then(data) {
				console.log(data);
				this.setState({
					favouriteMovies: data,
				});
				Object.keys(data).map((key, index) => {
					console.log('key: ' + key + ' data: ' + data[key].movieId);
					this.loadMoviesData(data[key].movieId);
				});
			},
		});
	};

	componentDidMount() {
		this.setState({ movies: movies });
		base.fetch(`favouritemovies`, {
			context: this,
			asArray: true,
			then(data) {
				console.log(data);
				this.setState({
					favouriteMovies: data,
				});
			},
		});
		//

		setTimeout(() => {
			this.getFavourites();
		}, 200);
		setTimeout(() => {
			this.getRandomBackgroundImage();
		}, 0);
	}

	componentWillUnmount() {
		//base.removeBinding(this.ref);
	}

	render() {
		return (
			<React.Fragment>
				<Header getRandomMovie={this.getRandomMovie} />
				<div className='hero common-hero'>
					<div className='container'>
						<div className='row'>
							<div className='col-md-12'>
								<div className='hero-ct'>
									<ul className='breadcumb'>
										<li className='active'>
											<a href='/'>Home</a>
										</li>
										<li>
											<span className='ion-ios-arrow-right'></span> my favourites
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='page-single'>
					<div className='container'>
						<div className='row'>
							<FavouriteMovies
								movies={this.state.movies}
								pagedata={this.state.pagedata}
								chunkSize={this.state.chunkSize}
								setChunkSize={this.setChunkSize}
								startPage={this.state.startPage}
								pageNum={this.state.pageNum}
								increasePageNum={this.increasePageNum}
								decreasePageNum={this.decreasePageNum}
								listViewType={this.state.listViewType}
								setPageView={this.setPageView}
								pn={this.props.match.params.pageNum}
								getRandomMovie={this.getRandomMovie}
								faves={this.state.favouriteMovies}
								getFavourites={this.getFavourites}
							/>
						</div>
					</div>
				</div>
				<Footer getRandomMovie={this.getRandomMovie} />
			</React.Fragment>
		);
	}
}

export default Favourites;
