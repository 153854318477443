/** @format */

import React from 'react';
import Favourite from './Favourite';
import FavouriteListView from './FavouriteListView';
import $ from 'jquery';
import { string } from 'prop-types';

class FavouriteMovies extends React.Component {
	countRef = React.createRef();

	state = {
		pageNum: 0,
	};

	// event delegation for the Movies amt select options.
	setChunkSize = (event) => {
		const amt = this.countRef.current.value;
		this.props.setChunkSize(amt);
	};

	increasePageNum = (event, int) => {
		//alert('!');
		var status = $('#status');
		var preloader = $('#preloader');
		preloader.css('display', 'block');
		status.css('display', 'block');
		this.props.increasePageNum(int, string);
	};

	decreasePageNum = (event, int) => {
		var status = $('#status');
		var preloader = $('#preloader');
		preloader.css('display', 'block');
		status.css('display', 'block');
		this.props.decreasePageNum(int, string);
	};

	setPageView = (event, type) => {
		this.props.setPageView(type);
		setTimeout(() => {
			this.props.getFavourites();
		}, 500);
	};

	componentDidMount() {
		
	}

	render() {
		// get movie count from data.
		const favs = Object.keys(this.props.faves);
		const favsTotal = favs.length;

		return (
			<div className='col-md-12 col-sm-12 col-xs-12'>
				<div className='topbar-filter fw'>
					<p>
						Displaying {/* prettier-ignore */}
						<span>
							{(' ' + this.props.chunkSize * this.props.pn) - this.props.chunkSize + 1}{' - '}
							{(this.props.pn * this.props.chunkSize) + ' '}
                            {'of ' + favsTotal}
							</span>{' '}
						total favourites
					</p>
					<label>Sort by:</label>
					<select>
						<option value='popularity'>Popularity Descending</option>
						<option value='popularity'>Popularity Ascending</option>
						<option value='rating'>Rating Descending</option>
						<option value='rating'>Rating Ascending</option>
						<option value='date'>Release date Descending</option>
						<option value='date'>Release date Ascending</option>
					</select>

					{this.props.listViewType === 'list' && (
						<>
							<a className='list'>
								<i className='ion-ios-list-outline active'></i>
							</a>
							<a className='grid' onClick={(e) => this.setPageView(e, 'grid')}>
								<i className='ion-grid'></i>
							</a>
						</>
					)}

					{this.props.listViewType === 'grid' && (
						<>
							<a className='list' onClick={(e) => this.setPageView(e, 'list')}>
								<i className='ion-ios-list-outline'></i>
							</a>
							<a className='grid'>
								<i className='ion-grid active'></i>
							</a>
						</>
					)}
				</div>
				{this.props.faves.length === 0 && (
					<div className='flex-wrap-movielist mv-grid-fw'>
						<p style={{ paddingBottom: '30px', textAlign: 'center', width: '100%' }}>
							You have not added any favourite movies yet.
						</p>
					</div>
				)}
				{/* prettier-ignore */}
				{this.props.listViewType === 'grid' && (
					<div className='flex-wrap-movielist mv-grid-fw'>
						{Object.keys(this.props.faves).map((key, index) => (
							<Favourite
								key={key}
								details={this.props.movies[key]}
								mov={this.props.pagedata[index]}
								index={index}
								fsid={this.props.faves[key[0]]}
								getFavourites={this.props.getFavourites}>
								{key}
							</Favourite>
						))}
					</div>
				)}
				{this.props.listViewType === 'list' && (
					<div className='flex-wrap-movielist mv-grid-fw'>
						{Object.keys(this.props.faves).map((key, index) => (
							<FavouriteListView
								key={key}
								details={this.props.movies[key]}
								mov={this.props.pagedata[index]}
								index={index}
								fsid={this.props.faves[key[0]]}
								getFavourites={this.props.getFavourites}>
								{key}
							</FavouriteListView>
						))}
					</div>
				)}
				<div className='topbar-filter'>
					<label>Movies per page:</label>
					<select id='movsPerPage' ref={this.countRef} onChange={this.setChunkSize}>
						<option defaultValue value='20'>
							20 Movies
						</option>
						<option value='30'>30 Movies</option>
						<option value='40'>40 Movies</option>
						<option value='80'>80 Movies</option>
					</select>

					<div className='pagination2'>
						<span>
							Page {this.props.pn} of {Math.ceil(favsTotal / this.props.chunkSize)}:
						</span>

						{this.props.pn > this.props.startPage + 3 && (
							<>
								<a href={'/favourites/pages/' + this.props.startPage}>{this.props.startPage}</a>
								<a href={'/favourites/pages/' + (this.props.startPage + 1)}>{this.props.startPage + 1}</a>
								<a href={'/favourites/pages/' + (this.props.startPage + 2)}>{this.props.startPage + 2}</a>
								<a href='#'>...</a>
							</>
						)}
						{this.props.pn < favsTotal / this.props.chunkSize + 2 && (
							<>
								<a href={'/favourites/pages/' + Math.ceil(favsTotal / this.props.chunkSize - 2)}>
									{Math.ceil(favsTotal / this.props.chunkSize - 2)}
								</a>
								<a href={'/favourites/pages/' + Math.ceil(favsTotal / this.props.chunkSize - 1)}>
									{Math.ceil(favsTotal / this.props.chunkSize - 1)}
								</a>
								<a href={'/favourites/pages/' + Math.ceil(favsTotal / this.props.chunkSize)}>
									{Math.ceil(favsTotal / this.props.chunkSize)}
								</a>
							</>
						)}

						<a>
							<i
								style={{ cursor: 'pointer' }}
								id='prev-page-arrow'
								className='ion-arrow-left-b'
								onClick={(e) => this.decreasePageNum(e, parseInt(this.props.pn) - 1, 'favourites')}></i>
						</a>
						{this.props.pn <= Math.ceil(favsTotal / this.props.chunkSize - 1) && (
							<a>
								<i
									style={{ cursor: 'pointer' }}
									id='next-page-arrow'
									className='ion-arrow-right-b'
									onClick={(e) => this.increasePageNum(e, parseInt(this.props.pn) + 1, 'favourites')}></i>
							</a>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default FavouriteMovies;
