/** @format */

import React from 'react';

// Header is now a refactored version of the class below
// it is now a 'stateless functional component'
// which is passed as a function rather than a class.
// this can be done this way ONLY when the Class contains just
// a render Method. Below example is an ES6 arrow function with 'props'
// passed as an arg, so when accessing props 'this.' is not required.
// Also, 'return' keyword in now inplicit, so only the markup needs to be passed.
class Footer extends React.Component {
	render() {
		return (
			<React.Fragment>
				{/* <React.Fragment used now instead of an empty div to contain the markup */}
				<footer className='ht-footer'>
					<div className='container' style={{ position: 'relative' }}>
						<div className='flex-parent-ft'>
							<div className='flex-child-ft item1'>
								<a href='https://www.themoviedb.org/'>
									<img
										src='https://www.themoviedb.org/assets/2/v4/logos/v2/blue_square_2-d537fb228cf3ded904ef09b136fe3fec72548ebc1fea3fbbd1ad9e36364db38b.svg'
										alt='The Movie Database (TMDb)'
										width='130'
										height='94'
									/>
								</a>
								<p>
									This product uses the <a href='https://www.themoviedb.org/'>TMDb</a> API but is not endorsed or
									certified by <a href='https://www.themoviedb.org/'>TMDb</a>.
								</p>
								<p id='randMovieInfo'></p>
							</div>
							<div className='flex-child-ft item2'>
								<h4>SECTIONS</h4>
								<ul>
									<li>
										<a href='/' className='btn btn-default dropdown-toggle lv1'>
											Home
										</a>
									</li>
									<li>
										<a href='/movies/pages/1/' className='btn btn-default dropdown-toggle lv1'>
											movies
										</a>
									</li>
									<li>
										<a href='/tv/pages/1/' className='btn btn-default dropdown-toggle lv1'>
											shows
										</a>
									</li>
									<li>
										<a onClick={(e) => this.props.getRandomMovie(e)} className='btn btn-default dropdown-toggle lv1'>
											Random Movie
										</a>
									</li>
									<li>
										<a href='/favourites/pages/1/' className='btn btn-default dropdown-toggle lv1'>
											favourites
										</a>
									</li>
									<li>
										<a href='/search/' className='btn btn-default dropdown-toggle lv1'>
											search
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className='ft-copyright'>
						<div className='ft-left'>
							<p>
								&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;
							</p>
						</div>
						<div className='backtotop'>
							<p>
								<a href='#' id='back-to-top'>
									Back to top <i className='ion-ios-arrow-thin-up'></i>
								</a>
							</p>
						</div>
					</div>
				</footer>
			</React.Fragment>
		);
	}
}

export default Footer;
