/** @format */

import React from 'react';
import { withRouter } from 'react-router';

class TelevisionItem extends React.Component {
	// event delegation for the Movies links.
	handleClick(e, id, titleString, year) {
		e.preventDefault();
		this.goToMoviePage(id, titleString, year);
	}

	// make a random color for the placeholder link
	// for movies with no poster available.
	getRandomBgColor = () => {
		var somecolors = [
			'c238c2',
			'c23838',
			'614875',
			'2548a1',
			'25a14c',
			'9ba125',
			'a17a25',
			'a12525',
			'f0820c',
			'd61773',
			'ce17d4',
			'601ea6',
			'2a1fa6',
			'0bb8db',
			'0dd91e',
			'388c3f',
			'378a73',
			'd45137',
		];

		const rand = Math.floor(Math.random() * somecolors.length);
		const uriSegA = 'https://dummyimage.com/45x67/';
		const uriSegB = '/ffffff.png&text=Poster+Not+Available';
		return uriSegA + somecolors[rand] + uriSegB;
	};

	// return the year fragment from a tmdb date node
	getReleaseYear = (date) => {
		if (date !== undefined) {
			const year = date.split('-')[0];
			return year;
		} else {
			return '';
		}
	};

	
	goToMoviePage = (id, titleString, year) => {
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		
		const page = tt + '-' + year;
		return page;
	};

	render() {
		if (this.props.tvdetails === null || this.props.tvdetails === undefined) {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='movie-item-style-2 movie-item-style-1'>loading</div>
				</React.Fragment>
			);
		} else {
			const { character, id, name, poster_path, title, first_air_date } = this.props.tvdetails;

			return (
				<React.Fragment key={`film-frag-${name}`}>
					<div className='cast-it'>
						<div className='cast-left cebleb-film'>
							{poster_path == null && <img src={this.getRandomBgColor()} alt={title} />}
							{poster_path !== null && <img src={'https://image.tmdb.org/t/p/w45/' + poster_path} alt='{title}' />}
							<div>
								<a href={'/tv/' + id + '/' + this.goToMoviePage(id, name, this.getReleaseYear(first_air_date))}>
									{name}
								</a>

								<p className='time' style={{ width: '100%' }}>
									{character}
								</p>
							</div>
						</div>
						{first_air_date !== undefined && <p>... {this.getReleaseYear(`${first_air_date}`)}</p>}
					</div>
				</React.Fragment>
			);
		}
	}
}

export default withRouter(TelevisionItem);
