/** @format */

import React from 'react';
import Header from './Header';
import Footer from './Footer';
import movies from '../movies';
import $ from 'jquery';
import SearchResults from './SearchResults';

class App extends React.Component {
	state = {
		movies: {},
		pagedata: [],
		chunkSize: 60,
		startPage: 1,
		pageNum: 1,
		pageIndex: 0,
		cardCount: 0,
		listViewType: 'grid',
		searchType: 'string',
		searchResultsData: [{ default: 'val' }],
		keywordsSearchData: [{ default: 'val' }],
		keywordValue: 'default word',
	};

	searchRef = React.createRef();
	searchType = React.createRef();

	nullEvent = () => {};

	// return the year fragment from a tmdb date node
	getReleaseYear = (date) => {
		const year = date.split('-')[0];
		return year;
	};
	getUriSafeTitle = (titleString) => {
		//alert('!');
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		return tt;
		//const page = '/movies/' + id + '/' + tt + '-' + year;
		//document.location = page;
	};
	getKeywordValue() {
		fetch(
			'https://api.themoviedb.org/3/keyword/' +
				this.props.match.params.type +
				'?api_key=83747b00d208337448cd133b2110d874&language=en-US&include_adult=false'
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((data) => {
				this.setState((prevState) => ({
					keywordValue: data,
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	loadSearchResultsByKeywordData() {
		this.setState({
			searchResultsData: [],
		});

		var status = $('#status');
		var preloader = $('#preloader');
		var body = $('body');
		body.delay(0).css({ overflow: 'visible' });
		fetch(
			'https://api.themoviedb.org/3/keyword/' +
				this.props.match.params.type +
				'/movies?api_key=83747b00d208337448cd133b2110d874&language=en-US&include_adult=false'
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((data) => {
				this.setState((prevState) => ({
					searchResultsData: prevState.searchResultsData.concat(data.results),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
		status.fadeOut(); // will first fade out the loading animation
		body.delay(1000).css({ overflow: 'visible' });
		preloader.delay(1000).fadeOut('fast'); // will fade out the white DIV that covers the website.
	}

	loadSearchResultsData(type, query) {
		this.setState({
			searchResultsData: [],
		});
		this.setState({
			searchType: type,
		});
		//console.log(movieid);
		var status = $('#status');
		var preloader = $('#preloader');
		var body = $('body');
		body.delay(0).css({ overflow: 'visible' });
		preloader.css('display', 'block');
		status.css('display', 'block');

		if (type === 'movie' || type === 'tv' || type === 'person') {
			fetch(
				'https://api.themoviedb.org/3/search/' +
					type +
					'?api_key=83747b00d208337448cd133b2110d874&language=en-US&query=' +
					query +
					'&include_adult=false'
			)
				.then((response) => {
					if (!response.ok) throw new Error(response.status);
					else return response.json();
				})
				.then((data) => {
					this.setState((prevState) => ({
						searchResultsData: prevState.searchResultsData.concat(data.results),
					}));
				})
				.catch((err) => console.error(this.props.url, err.toString()));
		}

		if (type === 'keyword') {
			fetch(
				'https://api.themoviedb.org/3/search/keyword?api_key=83747b00d208337448cd133b2110d874&query=' +
					this.searchRef.current.value +
					'&page=1'
			)
				.then((response) => {
					if (!response.ok) throw new Error(response.status);
					else return response.json();
				})
				.then((data) => {
					this.setState((prevState) => ({
						keywordsSearchData: prevState.keywordsSearchData.concat(data.results),
					}));
				})
				.catch((err) => console.error(this.props.url, err.toString()));
		}

		status.fadeOut(); // will first fade out the loading animation
		body.delay(1000).css({ overflow: 'visible' });
		preloader.delay(1000).fadeOut('fast'); // will fade out the white DIV that covers the website.
	}

	checkInput = (e) => {
		//alert(this.searchRef.current.value);
		//const myCurrentValue = this.searchRef.current.value;
		//this.searchRef.current.placeholder = '';
	};

	repopInput = (e) => {
		alert(this.searchRef.current.value);
		//const myCurrentValue = this.searchRef.current.value;
		//this.searchRef.current.placeholder = '';
	};
	searchFor() {
		var status = $('#status');
		//var preloader = $('#preloader');
		var body = $('body');
		body.delay(0).css({ overflow: 'visible' });

		status.css('display', 'block');
		this.setState({ keywordsSearchData: [] });
		this.setState({ keywordValue: 'default word' });
		this.loadSearchResultsData(this.searchType.current.value, this.searchRef.current.value);
	}

	keydetect = () => {
		window.addEventListener('keydown', (e) => {
			if (e.keyCode === 13) {
				console.log('fire away!');
				this.searchFor();
			}
		});
	};

	loadMoviesData(movieid, count) {
		fetch('https://api.themoviedb.org/3/movie/' + movieid + '?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((data) => {
				this.setState((prevState) => ({
					pagedata: prevState.pagedata.concat(data),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	goToMoviePage = (id, titleString, year) => {
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		const page = '/movies/' + id + '/' + tt + '-' + year;
		document.location = page;
	};

	getRandomMovie = () => {
		const movieCount = Object.keys(this.state.movies).length;

		const randNum = Math.floor(Math.random() * movieCount);
		const randMovie = Object.keys(this.state.movies).slice(
			// prettier-ignore
			randNum,
			randNum + 1
		);

		const myid = this.state.movies[randMovie].tmdb_id;
		const mytitle = this.state.movies[randMovie].title;
		const myyear = this.state.movies[randMovie].year;
		this.goToMoviePage(myid, mytitle, myyear);
	};

	applyCss(path) {
		var status = $('#status');
		var preloader = $('#preloader');
		var body = $('body');
		preloader.css('display', 'block');
		status.css('display', 'block');
		$('.common-hero').css('background', 'url(' + path + ')  no-repeat');
		$('.common-hero').css('background-position', '100px 50%');
		$('.common-hero').css('background-size', ' cover');

		$('.ht-footer').css('background', 'url(' + path + ')  no-repeat');
		$('.ht-footer').css('background-position', '100px 50%');
		$('.ht-footer').css('background-size', ' cover');

		body.delay(1000).css({ overflow: 'visible' });
		preloader.delay(1000).fadeOut('fast'); // will fade out the white DIV that covers the website.
		status.fadeIn(); // will first fade 69out the loading animation
	}

	loadBgimageData(randommovieid, count) {
		this.setState({ randImageData: [] });
		fetch('https://api.themoviedb.org/3/movie/' + randommovieid + '?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((rdata) => {
				this.setState((prevState) => ({
					randImageData: prevState.randImageData.concat(rdata),
				}));

				const myimgpath = 'https://image.tmdb.org/t/p/w1920_and_h800_multi_faces' + rdata.backdrop_path;

				if (myimgpath !== null && myimgpath !== undefined) {
					this.applyCss(myimgpath);
					const footInfo = document.getElementById('randMovieInfo');
					const title = this.getUriSafeTitle(rdata.title);
					const year = this.getReleaseYear(rdata.release_date);
					footInfo.innerHTML = `The random background image is from <a href='/movies/${rdata.id}/${title}-${year}'>${rdata.title}</a>`;
				}
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	getRandomBackgroundImage = () => {
		setTimeout(() => {
			const movieCount = Object.keys(this.state.movies).length;
			const randNum = Math.floor(Math.random() * movieCount);
			const randMovie = Object.keys(this.state.movies).slice(
				// prettier-ignore
				randNum,
				randNum + 1
			);
			const myid = this.state.movies[randMovie].tmdb_id;
			this.loadBgimageData(myid);
		}, 250);
	};

	componentWillMount() {
		var status = $('#status');
		var preloader = $('#preloader');
		preloader.css('display', 'block');
		status.css('display', 'block');
	}

	componentDidMount() {
		this.setState({ movies: movies });
		this.keydetect();
		this.getKeywordValue();

		setTimeout(() => {
			if (this.searchRef.current.value !== '') {
				this.loadSearchResultsData(this.searchType.current.value, this.searchRef.current.value);
			}

			if (this.props.match.params.searchType === 'keyword' && this.props.match.params.type !== '') {
				this.loadSearchResultsByKeywordData();
			}
		}, 1000);
		setTimeout(() => {
			this.getRandomBackgroundImage();
		}, 0);
	}

	render() {
		return (
			<React.Fragment>
				<Header getRandomMovie={this.getRandomMovie} />

				<div className='hero common-hero'>
					{/* search */}
					<div>
						<header className='ht-header'>
							<div className='container'>
								<div className='top-search' style={{ marginTop: '-80px' }}>
									<select ref={this.searchType}>
										<option value='movie'>Movies</option>
										<option value='tv'>Shows</option>
										<option value='person'>People</option>
										<option value='keyword'>Keyword</option>
									</select>
									<input
										ref={this.searchRef}
										type='text'
										placeholder='Search for a movie, show, or celebrity that you are looking for'
										onChange={this.nullEvent}
										defaultValue={this.state.keywordValue.name}
										onClick={(e) => this.checkInput(e)}
									/>
								</div>
							</div>
						</header>
					</div>
					{/* search */}
					<div className='container'></div>
				</div>

				<div className='page-single'>
					<div className='container'>
						{/* search */}
						<SearchResults
							keywordresults={this.state.keywordsSearchData}
							results={this.state.searchResultsData}
							listViewType='grid'
							searchType={this.state.searchType}
							keyword={this.state.keywordValue}
						/>
						{/* search */}
					</div>
				</div>
				<Footer getRandomMovie={this.getRandomMovie} />
			</React.Fragment>
		);
	}
}

export default App;
