/** @format */

import Rebase from 're-base';
import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
	apiKey: 'AIzaSyBfPJGq0G35ZDG2OirBOwRxhoOKbh1h3HA',
	authDomain: 'rods-movie-library.firebaseapp.com',
	databaseURL: 'https://rods-movie-library.firebaseio.com',
});

const base = Rebase.createClass(firebaseApp.database());

export { firebaseApp };
export default base;
