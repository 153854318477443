/** @format */

const series = {
  "2024-08-05T16:42:22Z": {
    type: "show",
    title: "Time Bandits",
    year: 2024,
    trakt_rating: 5.48193,
    trakt_id: 155535,
    imdb_id: "tt1928307",
    tmdb_id: 114924,
    tvdb_id: 425453,
    url: "https://trakt.tv/shows/time-bandits",
    released: "2024-07-24",
    runtime: 42,
    episode_count: 4,
    genres: "comedy,fantasy,science-fiction,action,adventure",
  },
  "2024-07-17T00:08:33Z": {
    type: "show",
    title: "American Rust",
    year: 2021,
    trakt_rating: 6.81525,
    trakt_id: 159003,
    imdb_id: "tt1532495",
    tmdb_id: 91746,
    tvdb_id: 367660,
    url: "https://trakt.tv/shows/american-rust",
    released: "2021-09-12",
    runtime: 56,
    episode_count: 19,
    genres: "drama,crime",
  },
  "2024-07-11T17:55:46Z": {
    type: "show",
    title: "Supacell",
    year: 2024,
    trakt_rating: 6.9451,
    trakt_id: 193507,
    imdb_id: "tt16283758",
    tmdb_id: 158141,
    tvdb_id: 427116,
    url: "https://trakt.tv/shows/supacell",
    released: "2024-06-27",
    runtime: 42,
    episode_count: 6,
    genres: "action,adventure,superhero,fantasy,science-fiction,drama",
  },
  "2024-06-20T15:43:09Z": {
    type: "show",
    title: "The Acolyte",
    year: 2024,
    trakt_rating: 4.53667,
    trakt_id: 170568,
    imdb_id: "tt12262202",
    tmdb_id: 114479,
    tvdb_id: 393197,
    url: "https://trakt.tv/shows/the-acolyte",
    released: "2024-06-05",
    runtime: 42,
    episode_count: 4,
    genres: "fantasy,science-fiction,mystery",
  },
  "2024-06-06T01:43:45Z": {
    type: "show",
    title: "After Life",
    year: 2019,
    trakt_rating: 8.0654,
    trakt_id: 130511,
    imdb_id: "tt8398600",
    tmdb_id: 79410,
    tvdb_id: 347507,
    url: "https://trakt.tv/shows/after-life",
    released: "2019-03-08",
    runtime: 30,
    episode_count: 18,
    genres: "comedy,drama",
  },
  "2024-06-06T01:43:11Z": {
    type: "show",
    title: "Maid",
    year: 2021,
    trakt_rating: 8.19289,
    trakt_id: 166129,
    imdb_id: "tt11337908",
    tmdb_id: 111141,
    tvdb_id: 382936,
    url: "https://trakt.tv/shows/maid",
    released: "2021-10-01",
    runtime: 42,
    episode_count: 10,
    genres: "drama",
  },
  "2024-06-04T11:03:33Z": {
    type: "show",
    title: "Sins of Our Mother",
    year: 2022,
    trakt_rating: 6.97952,
    trakt_id: 197679,
    imdb_id: "tt21867596",
    tmdb_id: 208720,
    tvdb_id: 423782,
    url: "https://trakt.tv/shows/sins-of-our-mother",
    released: "2022-09-14",
    runtime: 42,
    episode_count: 3,
    genres: "documentary,crime",
  },
  "2024-06-04T01:53:13Z": {
    type: "show",
    title: "Heartbreak High",
    year: 2022,
    trakt_rating: 7.79061,
    trakt_id: 173071,
    imdb_id: "tt15054962",
    tmdb_id: 158154,
    tvdb_id: 396115,
    url: "https://trakt.tv/shows/heartbreak-high-2022",
    released: "2022-09-14",
    runtime: 48,
    episode_count: 16,
    genres: "drama",
  },
  "2024-05-31T12:22:19Z": {
    type: "show",
    title: "Eric",
    year: 2024,
    trakt_rating: 5.63158,
    trakt_id: 71636,
    imdb_id: "tt16283824",
    tmdb_id: 217667,
    tvdb_id: 421652,
    url: "https://trakt.tv/shows/eric",
    released: "2024-05-30",
    runtime: 42,
    episode_count: 6,
    genres: "drama,mystery,thriller",
  },
  "2024-05-08T02:05:22Z": {
    type: "show",
    title: "Dark Matter",
    year: 2024,
    trakt_rating: 4.33333,
    trakt_id: 170597,
    imdb_id: "tt19231492",
    tmdb_id: 196322,
    tvdb_id: 393159,
    url: "https://trakt.tv/shows/dark-matter-2024",
    released: "2024-05-08",
    runtime: 42,
    episode_count: 2,
    genres: "fantasy,science-fiction,drama,mystery",
  },
  "2024-04-28T12:55:53Z": {
    type: "show",
    title: "THEM",
    year: 2021,
    trakt_rating: 7.57572,
    trakt_id: 155692,
    imdb_id: "tt9064858",
    tmdb_id: 120462,
    tvdb_id: 372974,
    url: "https://trakt.tv/shows/them",
    released: "2021-04-09",
    runtime: 43,
    episode_count: 18,
    genres: "drama,mystery,horror",
  },
  "2024-04-21T13:56:04Z": {
    type: "show",
    title: "Baby Reindeer",
    year: 2024,
    trakt_rating: 7.67094,
    trakt_id: 217121,
    imdb_id: "tt13649112",
    tmdb_id: 241259,
    tvdb_id: 417223,
    url: "https://trakt.tv/shows/baby-reindeer",
    released: "2024-04-11",
    runtime: 30,
    episode_count: 7,
    genres: "drama",
  },
  "2024-04-15T21:50:15Z": {
    type: "show",
    title: "RIPLEY",
    year: 2024,
    trakt_rating: 7.5023,
    trakt_id: 155523,
    imdb_id: "tt11016042",
    tmdb_id: 94028,
    tvdb_id: 372727,
    url: "https://trakt.tv/shows/ripley",
    released: "2024-04-04",
    runtime: 42,
    episode_count: 8,
    genres: "drama",
  },
  "2024-04-15T21:37:04Z": {
    type: "show",
    title: "Shōgun",
    year: 2024,
    trakt_rating: 8.79512,
    trakt_id: 170054,
    imdb_id: "tt2788316",
    tmdb_id: 126308,
    tvdb_id: 392573,
    url: "https://trakt.tv/shows/shogun-2024",
    released: "2024-02-27",
    runtime: 62,
    episode_count: 8,
    genres: "drama,war",
  },
  "2024-04-12T11:39:30Z": {
    type: "show",
    title: "Katla",
    year: 2021,
    trakt_rating: 6.65416,
    trakt_id: 169443,
    imdb_id: "tt11102190",
    tmdb_id: 104157,
    tvdb_id: 383321,
    url: "https://trakt.tv/shows/katla",
    released: "2021-06-17",
    runtime: 45,
    episode_count: 8,
    genres: "drama,mystery,fantasy,science-fiction",
  },
  "2024-04-11T12:05:04Z": {
    type: "show",
    title: "Fallout",
    year: 2024,
    trakt_rating: 7.90164,
    trakt_id: 163965,
    imdb_id: "tt12637874",
    tmdb_id: 106379,
    tvdb_id: 416744,
    url: "https://trakt.tv/shows/fallout",
    released: "2024-04-11",
    runtime: 42,
    episode_count: 8,
    genres: "fantasy,science-fiction,comedy,action,adventure",
  },
  "2024-04-05T12:22:58Z": {
    type: "show",
    title: "Parasyte: The Grey",
    year: 2024,
    trakt_rating: 3.6,
    trakt_id: 199140,
    imdb_id: "tt21874396",
    tmdb_id: 208825,
    tvdb_id: 424566,
    url: "https://trakt.tv/shows/parasyte-the-grey",
    released: "2024-04-04",
    runtime: 42,
    episode_count: 6,
    genres: "drama,horror,fantasy,science-fiction,action,adventure",
  },
  "2024-03-30T15:27:04Z": {
    type: "show",
    title: "Cyberpunk: Edgerunners",
    year: 2022,
    trakt_rating: 8.2736,
    trakt_id: 163705,
    imdb_id: "tt12590266",
    tmdb_id: 105248,
    tvdb_id: 384541,
    url: "https://trakt.tv/shows/cyberpunk-edgerunners",
    released: "2022-09-13",
    runtime: 25,
    episode_count: 10,
    genres: "anime,action,adventure,fantasy,science-fiction",
  },
  "2024-03-21T11:07:50Z": {
    type: "show",
    title: "3 Body Problem",
    year: 2024,
    trakt_rating: 7.52174,
    trakt_id: 169972,
    imdb_id: "tt13016388",
    tmdb_id: 108545,
    tvdb_id: 411959,
    url: "https://trakt.tv/shows/3-body-problem",
    released: "2024-03-21",
    runtime: 42,
    episode_count: 8,
    genres: "drama,mystery,fantasy,science-fiction",
  },
  "2024-03-16T12:59:44Z": {
    type: "show",
    title: "The Program: Cons, Cults and Kidnapping",
    year: 2024,
    trakt_rating: 7.31081,
    trakt_id: 226771,
    imdb_id: "tt31183637",
    tmdb_id: 246698,
    tvdb_id: 445941,
    url: "https://trakt.tv/shows/the-program-cons-cults-and-kidnapping",
    released: "2024-03-05",
    runtime: 42,
    episode_count: 3,
    genres: "documentary",
  },
  "2024-03-11T23:13:19Z": {
    type: "show",
    title: "Raising Dion",
    year: 2019,
    trakt_rating: 7.35364,
    trakt_id: 124235,
    imdb_id: "tt7826108",
    tmdb_id: 93392,
    tvdb_id: 335985,
    url: "https://trakt.tv/shows/raising-dion",
    released: "2019-10-04",
    runtime: 50,
    episode_count: 17,
    genres: "drama,fantasy,superhero,science-fiction",
  },
  "2024-03-08T14:41:53Z": {
    type: "show",
    title: "The Gentlemen",
    year: 2024,
    trakt_rating: 7.5,
    trakt_id: 211407,
    imdb_id: "tt13210838",
    tmdb_id: 236235,
    tvdb_id: 426845,
    url: "https://trakt.tv/shows/the-gentlemen",
    released: "2024-03-07",
    runtime: 42,
    episode_count: 8,
    genres: "comedy,action,adventure,crime,drama",
  },
  "2024-03-07T13:55:22Z": {
    type: "show",
    title: "The Signal",
    year: 2024,
    trakt_rating: 3.25,
    trakt_id: 217757,
    imdb_id: "tt25375256",
    tmdb_id: 241473,
    tvdb_id: 443458,
    url: "https://trakt.tv/shows/the-signal-2024",
    released: "2024-03-06",
    runtime: 42,
    episode_count: 2,
    genres: "fantasy,science-fiction,drama,mystery",
  },
  "2024-03-01T22:28:30Z": {
    type: "show",
    title: "Halo",
    year: "2022",
    trakt_rating: "7.31979",
    trakt_id: "150109",
    imdb_id: "tt2934286",
    tmdb_id: "52814",
    tvdb_id: "366524",
    url: "https://trakt.tv/shows/halo",
    released: "2022-03-24",
    runtime: "42",
    episode_count: "14",
    genres: "action,science-fiction,adventure,fantasy",
  },
  "2024-02-23T21:32:52Z": {
    type: "show",
    title: "Avatar: The Last Airbender",
    year: "2024",
    trakt_rating: "7.38288",
    trakt_id: "136561",
    imdb_id: "tt9018736",
    tmdb_id: "82452",
    tvdb_id: "385925",
    url: "https://trakt.tv/shows/avatar-the-last-airbender-2024",
    released: "2024-02-22",
    runtime: "42",
    episode_count: "8",
    genres: "action,adventure,drama,fantasy,science-fiction",
  },
  "2024-02-21T02:32:04Z": {
    type: "show",
    title: "Constellation",
    year: "2024",
    trakt_rating: "3.375",
    trakt_id: "194662",
    imdb_id: "tt19395018",
    tmdb_id: "197125",
    tvdb_id: "418727",
    url: "https://trakt.tv/shows/constellation",
    released: "2024-02-20",
    runtime: "42",
    episode_count: "3",
    genres: "drama,fantasy,science-fiction,mystery",
  },
  "2024-02-17T00:03:36Z": {
    type: "show",
    title: "Mr. & Mrs. Smith",
    year: "2024",
    trakt_rating: "6.78454",
    trakt_id: "173650",
    imdb_id: "tt14044212",
    tmdb_id: "118642",
    tvdb_id: "396773",
    url: "https://trakt.tv/shows/mr-mrs-smith-2024",
    released: "2024-02-02",
    runtime: "42",
    episode_count: "8",
    genres: "action,adventure,drama,comedy",
  },
  "2024-02-11T22:38:49Z": {
    type: "show",
    title: "One Day",
    year: "2024",
    trakt_rating: "7.43182",
    trakt_id: "216038",
    imdb_id: "tt16283804",
    tmdb_id: "240667",
    tvdb_id: "421649",
    url: "https://trakt.tv/shows/one-day-2024",
    released: "2024-02-08",
    runtime: "42",
    episode_count: "14",
    genres: "romance,drama",
  },
  "2024-02-02T19:14:55Z": {
    type: "show",
    title: "Griselda",
    year: "2024",
    trakt_rating: "7.20364",
    trakt_id: "190918",
    imdb_id: "tt15837600",
    tmdb_id: "137893",
    tvdb_id: "412111",
    url: "https://trakt.tv/shows/griselda",
    released: "2024-01-25",
    runtime: "42",
    episode_count: "6",
    genres: "drama,crime",
  },
  "2024-01-11T18:56:59Z": {
    type: "show",
    title: "Percy Jackson and the Olympians",
    year: "2023",
    trakt_rating: "7.38889",
    trakt_id: "194518",
    imdb_id: "tt12324366",
    tmdb_id: "103540",
    tvdb_id: "415151",
    url: "https://trakt.tv/shows/percy-jackson-and-the-olympians",
    released: "2023-12-20",
    runtime: "53",
    episode_count: "5",
    genres: "action,adventure,drama,fantasy,family",
  },
  "2024-01-06T23:06:30Z": {
    type: "show",
    title: "Harlan Coben's Shelter",
    year: "2023",
    trakt_rating: "6.92913",
    trakt_id: "199407",
    imdb_id: "tt15483276",
    tmdb_id: "210787",
    tvdb_id: "417857",
    url: "https://trakt.tv/shows/harlan-coben-s-shelter",
    released: "2023-08-17",
    runtime: "42",
    episode_count: "8",
    genres: "drama,crime,mystery",
  },
  "2023-12-16T19:08:12Z": {
    type: "show",
    title: "Yu Yu Hakusho",
    year: "2023",
    trakt_rating: "6.69767",
    trakt_id: "197000",
    imdb_id: "tt13664452",
    tmdb_id: "121659",
    tvdb_id: "413737",
    url: "https://trakt.tv/shows/yu-yu-hakusho-2023",
    released: "2023-12-14",
    runtime: "42",
    episode_count: "5",
    genres: "drama,fantasy,science-fiction",
  },
  "2023-12-04T12:49:22Z": {
    type: "show",
    title: "Cobra Kai",
    year: "2018",
    trakt_rating: "8.11325",
    trakt_id: "122553",
    imdb_id: "tt7221388",
    tmdb_id: "77169",
    tvdb_id: "332858",
    url: "https://trakt.tv/shows/cobra-kai",
    released: "2018-05-02",
    runtime: "30",
    episode_count: "10",
    genres: "action,drama,adventure,comedy",
  },
  "2023-12-02T23:33:41Z": {
    type: "show",
    title: "Monarch: Legacy of Monsters",
    year: "2023",
    trakt_rating: "7.3876",
    trakt_id: "195644",
    imdb_id: "tt17220216",
    tmdb_id: "202411",
    tvdb_id: "422598",
    url: "https://trakt.tv/shows/monarch-legacy-of-monsters",
    released: "2023-11-17",
    runtime: "42",
    episode_count: "4",
    genres: "drama,fantasy,science-fiction,action,adventure",
  },
  "2023-11-30T16:58:22Z": {
    type: "show",
    title: "Life on Our Planet",
    year: "2023",
    trakt_rating: "7.59259",
    trakt_id: "199490",
    imdb_id: "tt23181388",
    tmdb_id: "213609",
    tvdb_id: "426715",
    url: "https://trakt.tv/shows/life-on-our-planet",
    released: "2023-10-25",
    runtime: "42",
    episode_count: "8",
    genres: "documentary",
  },
  "2023-11-22T21:50:35Z": {
    type: "show",
    title: "Squid Game: The Challenge",
    year: "2023",
    trakt_rating: "5.84375",
    trakt_id: "196265",
    imdb_id: "tt28104766",
    tmdb_id: "204082",
    tvdb_id: "421260",
    url: "https://trakt.tv/shows/squid-game-the-challenge",
    released: "2023-11-22",
    runtime: "42",
    episode_count: "5",
    genres: "reality",
  },
  "2023-11-15T20:33:55Z": {
    type: "show",
    title: "Animaniacs",
    year: "1993",
    trakt_rating: "7.93228",
    trakt_id: "81",
    imdb_id: "tt0105941",
    tmdb_id: "82",
    tvdb_id: "72879",
    url: "https://trakt.tv/shows/animaniacs",
    released: "1993-09-13",
    runtime: "42",
    episode_count: "184",
    genres: "animation,comedy,family,children",
  },
  "2023-11-14T13:32:51Z": {
    type: "show",
    title: "Soap",
    year: "1977",
    trakt_rating: "7.69767",
    trakt_id: "3274",
    imdb_id: "tt0075584",
    tmdb_id: "3295",
    tvdb_id: "77370",
    url: "https://trakt.tv/shows/soap",
    released: "1977-09-13",
    runtime: "25",
    episode_count: "92",
    genres: "comedy,soap,drama",
  },
  "2023-10-30T01:27:36Z": {
    type: "show",
    title: "Bodies",
    year: "2023",
    trakt_rating: "7.36943",
    trakt_id: "208663",
    imdb_id: "tt18347622",
    tmdb_id: "233629",
    tvdb_id: "417257",
    url: "https://trakt.tv/shows/bodies-2023",
    released: "2023-10-19",
    runtime: "42",
    episode_count: "8",
    genres: "crime,mystery,fantasy,science-fiction,drama",
  },
  "2023-10-26T01:33:03Z": {
    type: "show",
    title: "Duckman",
    year: "1994",
    trakt_rating: "7.76087",
    trakt_id: "1254",
    imdb_id: "tt0108755",
    tmdb_id: "1260",
    tvdb_id: "72056",
    url: "https://trakt.tv/shows/duckman",
    released: "1994-03-06",
    runtime: "22",
    episode_count: "22",
    genres: "animation,comedy",
  },
  "2023-10-14T14:49:08Z": {
    type: "show",
    title: "30 Coins",
    year: "2020",
    trakt_rating: "7.24219",
    trakt_id: "152990",
    imdb_id: "tt9764386",
    tmdb_id: "89844",
    tvdb_id: "369414",
    url: "https://trakt.tv/shows/30-coins",
    released: "2020-11-28",
    runtime: "2",
    episode_count: "8",
    genres: "crime,mystery,drama",
  },
  "2023-10-12T20:49:41Z": {
    type: "show",
    title: "The Fall of the House of Usher",
    year: "2023",
    trakt_rating: "7.375",
    trakt_id: "189388",
    imdb_id: "tt15567174",
    tmdb_id: "157065",
    tvdb_id: "411021",
    url: "https://trakt.tv/shows/the-fall-of-the-house-of-usher",
    released: "2023-10-12",
    runtime: "62",
    episode_count: "8",
    genres: "drama,horror",
  },
  "2023-10-12T01:16:08Z": {
    type: "show",
    title: "Midnight Mass",
    year: "2021",
    trakt_rating: "7.6239",
    trakt_id: "157085",
    imdb_id: "tt10574558",
    tmdb_id: "97400",
    tvdb_id: "367226",
    url: "https://trakt.tv/shows/midnight-mass",
    released: "2021-09-24",
    runtime: "64",
    episode_count: "7",
    genres: "drama,mystery,fantasy,horror",
  },
  "2023-09-29T01:20:00Z": {
    type: "show",
    title: "Gen V",
    year: "2023",
    trakt_rating: "5.875",
    trakt_id: "196842",
    imdb_id: "tt13159924",
    tmdb_id: "205715",
    tvdb_id: "417909",
    url: "https://trakt.tv/shows/gen-v",
    released: "2023-09-29",
    runtime: "42",
    episode_count: "3",
    genres: "superhero,fantasy,science-fiction,action,adventure",
  },
  "2023-09-24T01:13:15Z": {
    type: "show",
    title: "Invasion",
    year: "2021",
    trakt_rating: "6.38391",
    trakt_id: "181803",
    imdb_id: "tt9737326",
    tmdb_id: "127235",
    tvdb_id: "404492",
    url: "https://trakt.tv/shows/invasion-2021",
    released: "2021-10-22",
    runtime: "42",
    episode_count: "15",
    genres: "drama,fantasy,science-fiction",
  },
  "2023-09-15T19:50:30Z": {
    type: "show",
    title: "Ahsoka",
    year: "2023",
    trakt_rating: "7.48552",
    trakt_id: "170559",
    imdb_id: "tt13622776",
    tmdb_id: "114461",
    tvdb_id: "393187",
    url: "https://trakt.tv/shows/ahsoka",
    released: "2023-08-23",
    runtime: "42",
    episode_count: "5",
    genres: "fantasy,science-fiction,action,adventure",
  },
  "2023-09-10T13:26:16Z": {
    type: "show",
    title: "The Walking Dead: Daryl Dixon",
    year: "2023",
    trakt_rating: "6.80952",
    trakt_id: "198729",
    imdb_id: "tt13062500",
    tmdb_id: "211684",
    tvdb_id: "427464",
    url: "https://trakt.tv/shows/the-walking-dead-daryl-dixon",
    released: "2023-09-11",
    runtime: "42",
    episode_count: "1",
    genres: "fantasy,science-fiction,action,adventure",
  },
  "2023-09-06T21:09:46Z": {
    type: "show",
    title: "The Imperfects",
    year: "2022",
    trakt_rating: "6.90423",
    trakt_id: "180327",
    imdb_id: "tt14502728",
    tmdb_id: "203555",
    tvdb_id: "401169",
    url: "https://trakt.tv/shows/the-imperfects",
    released: "2022-09-08",
    runtime: "42",
    episode_count: "10",
    genres: "action,adventure,drama,fantasy,science-fiction",
  },
  "2023-09-05T16:28:58Z": {
    type: "show",
    title: "The Walking Dead: Dead City",
    year: "2023",
    trakt_rating: "6.72107",
    trakt_id: "193872",
    imdb_id: "tt18546730",
    tmdb_id: "194583",
    tvdb_id: "417549",
    url: "https://trakt.tv/shows/the-walking-dead-dead-city",
    released: "2023-06-19",
    runtime: "42",
    episode_count: "6",
    genres: "action,adventure,drama,fantasy,science-fiction",
  },
  "2023-08-31T13:13:04Z": {
    type: "show",
    title: "ONE PIECE",
    year: "2023",
    trakt_rating: "7.52381",
    trakt_id: "184618",
    imdb_id: "tt11737520",
    tmdb_id: "111110",
    tvdb_id: "392276",
    url: "https://trakt.tv/shows/one-piece-2023",
    released: "2023-08-31",
    runtime: "42",
    episode_count: "8",
    genres: "action,adventure,fantasy,science-fiction",
  },
  "2023-08-25T23:12:33Z": {
    type: "show",
    title: "Hellier",
    year: "2019",
    trakt_rating: "5.91667",
    trakt_id: "142683",
    imdb_id: "tt9640354",
    tmdb_id: "86259",
    tvdb_id: "357966",
    url: "https://trakt.tv/shows/hellier",
    released: "2019-01-18",
    runtime: "50",
    episode_count: "15",
    genres: "documentary,mystery,reality",
  },
  "2023-08-22T01:12:32Z": {
    type: "show",
    title: "Painkiller",
    year: "2023",
    trakt_rating: "7.02041",
    trakt_id: "184898",
    imdb_id: "tt11816814",
    tmdb_id: "158083",
    tvdb_id: "403756",
    url: "https://trakt.tv/shows/painkiller",
    released: "2023-08-10",
    runtime: "42",
    episode_count: "6",
    genres: "drama,crime",
  },
  "2023-07-03T01:29:45Z": {
    type: "show",
    title: "Waco: The Aftermath",
    year: "2023",
    trakt_rating: "6.74026",
    trakt_id: "195018",
    imdb_id: "tt19842834",
    tmdb_id: "200701",
    tvdb_id: "418489",
    url: "https://trakt.tv/shows/waco-the-aftermath",
    released: "2023-04-17",
    runtime: "42",
    episode_count: "5",
    genres: "drama",
  },
  "2023-06-16T00:13:13Z": {
    type: "show",
    title: "The Chemistry of Death",
    year: "2023",
    trakt_rating: "6.18987",
    trakt_id: "201537",
    imdb_id: "tt22015782",
    tmdb_id: "217180",
    tvdb_id: "417646",
    url: "https://trakt.tv/shows/the-chemistry-of-death",
    released: "2023-01-12",
    runtime: "46",
    episode_count: "6",
    genres: "crime",
  },
  "2023-06-09T12:22:43Z": {
    type: "show",
    title: "The Crowded Room",
    year: "2023",
    trakt_rating: "6.22222",
    trakt_id: "178915",
    imdb_id: "tt14417718",
    tmdb_id: "123192",
    tvdb_id: "400723",
    url: "https://trakt.tv/shows/the-crowded-room",
    released: "2023-06-09",
    runtime: "60",
    episode_count: "3",
    genres: "drama",
  },
  "2023-05-28T23:38:47Z": {
    type: "show",
    title: "LEGO Dreamzzz - Trials of the Dream Chasers",
    year: "2023",
    trakt_rating: "5.0",
    trakt_id: "204992",
    imdb_id: "tt27613744",
    tmdb_id: "225320",
    tvdb_id: "",
    url: "https://trakt.tv/shows/lego-dreamzzz-trials-of-the-dream-chasers",
    released: "2023-05-15",
    runtime: "22",
    episode_count: "10",
    genres: "animation,family",
  },
  "2023-05-09T23:00:42Z": {
    type: "show",
    title: "Fatal Attraction",
    year: "2023",
    trakt_rating: "5.32258",
    trakt_id: "174315",
    imdb_id: "tt14351748",
    tmdb_id: "138455",
    tvdb_id: "397426",
    url: "https://trakt.tv/shows/fatal-attraction-2023",
    released: "2023-04-30",
    runtime: "58",
    episode_count: "4",
    genres: "drama",
  },
  "2023-05-05T01:44:29Z": {
    type: "show",
    title: "Silo",
    year: "2023",
    trakt_rating: "6.14286",
    trakt_id: "180770",
    imdb_id: "tt14688458",
    tmdb_id: "125988",
    tvdb_id: "403245",
    url: "https://trakt.tv/shows/silo",
    released: "2023-05-05",
    runtime: "42",
    episode_count: "1",
    genres: "drama,fantasy,science-fiction",
  },
  "2023-05-04T01:48:49Z": {
    type: "show",
    title: "Rabbit Hole",
    year: "2023",
    trakt_rating: "7.51341",
    trakt_id: "193078",
    imdb_id: "tt5822112",
    tmdb_id: "156819",
    tvdb_id: "415584",
    url: "https://trakt.tv/shows/rabbit-hole",
    released: "2023-03-26",
    runtime: "42",
    episode_count: "7",
    genres: "drama",
  },
  "2023-05-04T01:10:08Z": {
    type: "show",
    title: "Sweet Tooth",
    year: "2021",
    trakt_rating: "7.75906",
    trakt_id: "162098",
    imdb_id: "tt12809988",
    tmdb_id: "103768",
    tvdb_id: "355837",
    url: "https://trakt.tv/shows/sweet-tooth",
    released: "2021-06-04",
    runtime: "42",
    episode_count: "16",
    genres: "drama,fantasy,science-fiction",
  },
  "2023-04-21T03:16:35Z": {
    type: "show",
    title: "Dead Ringers",
    year: "2023",
    trakt_rating: "4.0",
    trakt_id: "198208",
    imdb_id: "tt12923632",
    tmdb_id: "130987",
    tvdb_id: "387113",
    url: "https://trakt.tv/shows/dead-ringers-2023",
    released: "2023-04-21",
    runtime: "42",
    episode_count: "1",
    genres: "drama,mystery",
  },
  "2023-04-15T01:55:55Z": {
    type: "show",
    title: "Three-Body",
    year: "2023",
    trakt_rating: "7.91667",
    trakt_id: "196528",
    imdb_id: "tt20242042",
    tmdb_id: "204541",
    tvdb_id: "421555",
    url: "https://trakt.tv/shows/three-body",
    released: "2023-01-15",
    runtime: "43",
    episode_count: "30",
    genres: "drama,fantasy,science-fiction,mystery",
  },
  "2023-04-09T22:28:35Z": {
    type: "show",
    title: "BEEF",
    year: "2023",
    trakt_rating: "7.57364",
    trakt_id: "197667",
    imdb_id: "tt14403178",
    tmdb_id: "154385",
    tvdb_id: "399838",
    url: "https://trakt.tv/shows/beef",
    released: "2023-04-06",
    runtime: "42",
    episode_count: "10",
    genres: "comedy,drama",
  },
  "2023-04-06T15:07:49Z": {
    type: "show",
    title: "A Town Called Malice",
    year: "2023",
    trakt_rating: "5.61905",
    trakt_id: "188899",
    imdb_id: "tt16946130",
    tmdb_id: "194632",
    tvdb_id: "410364",
    url: "https://trakt.tv/shows/a-town-called-malice",
    released: "2023-03-16",
    runtime: "42",
    episode_count: "8",
    genres: "crime,drama",
  },
  "2023-02-22T18:26:18Z": {
    type: "show",
    title: "Inside Our Autistic Minds",
    year: "2023",
    trakt_rating: "0.0",
    trakt_id: "202561",
    imdb_id: "",
    tmdb_id: "220520",
    tvdb_id: "431066",
    url: "https://trakt.tv/shows/inside-our-autistic-minds",
    released: "2023-02-14",
    runtime: "42",
    episode_count: "2",
    genres: "documentary",
  },
  "2023-02-13T00:14:28Z": {
    type: "show",
    title: "Cunk on Earth",
    year: "2022",
    trakt_rating: "8.04762",
    trakt_id: "202343",
    imdb_id: "tt16867040",
    tmdb_id: "219881",
    tvdb_id: "414217",
    url: "https://trakt.tv/shows/cunk-on-earth",
    released: "2022-09-19",
    runtime: "30",
    episode_count: "5",
    genres: "",
  },
  "2023-01-16T15:00:32Z": {
    type: "show",
    title: "The Last of Us",
    year: "2023",
    trakt_rating: "8.77202",
    trakt_id: "158947",
    imdb_id: "tt3581920",
    tmdb_id: "100088",
    tvdb_id: "392256",
    url: "https://trakt.tv/shows/the-last-of-us",
    released: "2023-01-16",
    runtime: "18",
    episode_count: "1",
    genres: "adventure,action,drama,horror,fantasy,science-fiction",
  },
  "2023-01-06T13:40:51Z": {
    type: "show",
    title: "Anne Rice's Mayfair Witches",
    year: "2023",
    trakt_rating: "5.22222",
    trakt_id: "197457",
    imdb_id: "tt15428778",
    tmdb_id: "207863",
    tvdb_id: "413074",
    url: "https://trakt.tv/shows/anne-rice-s-mayfair-witches",
    released: "2023-01-09",
    runtime: "42",
    episode_count: "1",
    genres: "fantasy,science-fiction,drama",
  },
  "2022-12-02T12:25:43Z": {
    type: "show",
    title: "Wednesday",
    year: "2022",
    trakt_rating: "8.35957",
    trakt_id: "173996",
    imdb_id: "tt13443470",
    tmdb_id: "119051",
    tvdb_id: "397060",
    url: "https://trakt.tv/shows/wednesday",
    released: "2022-11-23",
    runtime: "42",
    episode_count: "8",
    genres: "fantasy,science-fiction,mystery,comedy",
  },
  "2022-11-18T02:09:43Z": {
    type: "show",
    title: "1899",
    year: "2022",
    trakt_rating: "7.76271",
    trakt_id: "163643",
    imdb_id: "tt9319668",
    tmdb_id: "90669",
    tvdb_id: "384429",
    url: "https://trakt.tv/shows/1899",
    released: "2022-11-17",
    runtime: "50",
    episode_count: "8",
    genres: "mystery,drama,horror",
  },
  "2022-11-13T13:01:46Z": {
    type: "show",
    title: "Tulsa King",
    year: "2022",
    trakt_rating: "6.83333",
    trakt_id: "191758",
    imdb_id: "tt16358384",
    tmdb_id: "153312",
    tvdb_id: "413215",
    url: "https://trakt.tv/shows/tulsa-king",
    released: "2022-11-13",
    runtime: "42",
    episode_count: "1",
    genres: "crime,drama",
  },
  "2022-11-12T22:46:10Z": {
    type: "show",
    title: "Mayor of Kingstown",
    year: "2021",
    trakt_rating: "8.04143",
    trakt_id: "165410",
    imdb_id: "tt11712058",
    tmdb_id: "97951",
    tvdb_id: "386630",
    url: "https://trakt.tv/shows/mayor-of-kingstown",
    released: "2021-11-14",
    runtime: "50",
    episode_count: "10",
    genres: "drama,crime",
  },
  "2022-11-08T00:49:00Z": {
    type: "show",
    title: "Interview with the Vampire",
    year: "2022",
    trakt_rating: "7.61009",
    trakt_id: "183243",
    imdb_id: "tt14921986",
    tmdb_id: "128098",
    tvdb_id: "405535",
    url: "https://trakt.tv/shows/interview-with-the-vampire",
    released: "2022-10-03",
    runtime: "42",
    episode_count: "6",
    genres: "drama",
  },
  "2022-11-04T11:14:45Z": {
    type: "show",
    title: "The Peripheral",
    year: "2022",
    trakt_rating: "8.29651",
    trakt_id: "156110",
    imdb_id: "tt8291284",
    tmdb_id: "95403",
    tvdb_id: "349187",
    url: "https://trakt.tv/shows/the-peripheral",
    released: "2022-10-21",
    runtime: "60",
    episode_count: "4",
    genres: "science-fiction,fantasy,thriller,drama",
  },
  "2022-10-31T11:34:51Z": {
    type: "show",
    title: "Inside Man",
    year: "2022",
    trakt_rating: "6.7033",
    trakt_id: "187030",
    imdb_id: "tt10948716",
    tmdb_id: "121710",
    tvdb_id: "399877",
    url: "https://trakt.tv/shows/inside-man-2022",
    released: "2022-09-26",
    runtime: "60",
    episode_count: "4",
    genres: "drama,mystery,crime",
  },
  "2022-10-25T12:46:44Z": {
    type: "show",
    title: "Guillermo del Toro's Cabinet of Curiosities",
    year: "2022",
    trakt_rating: "6.8",
    trakt_id: "190673",
    imdb_id: "tt8415836",
    tmdb_id: "83659",
    tvdb_id: "409559",
    url: "https://trakt.tv/shows/guillermo-del-toro-s-cabinet-of-curiosities",
    released: "2022-10-25",
    runtime: "1",
    episode_count: "2",
    genres: "drama,mystery",
  },
  "2022-10-10T13:51:49Z": {
    type: "show",
    title: "The Midnight Club",
    year: "2022",
    trakt_rating: "6.77215",
    trakt_id: "161830",
    imdb_id: "tt13444912",
    tmdb_id: "126254",
    tvdb_id: "381909",
    url: "https://trakt.tv/shows/the-midnight-club",
    released: "2022-10-07",
    runtime: "54",
    episode_count: "10",
    genres: "horror,mystery,drama",
  },
  "2022-09-28T01:21:56Z": {
    type: "show",
    title: "Star Wars: Andor",
    year: "2022",
    trakt_rating: "7.5754",
    trakt_id: "170649",
    imdb_id: "tt9253284",
    tmdb_id: "83867",
    tvdb_id: "393189",
    url: "https://trakt.tv/shows/star-wars-andor",
    released: "2022-09-21",
    runtime: "40",
    episode_count: "3",
    genres: "action,adventure,fantasy,science-fiction,war",
  },
  "2022-09-23T14:53:24Z": {
    type: "show",
    title: "Joe vs Carole",
    year: "2022",
    trakt_rating: "5.55357",
    trakt_id: "183032",
    imdb_id: "tt11280716",
    tmdb_id: "123810",
    tvdb_id: "405727",
    url: "https://trakt.tv/shows/joe-vs-carole",
    released: "2022-03-03",
    runtime: "48",
    episode_count: "8",
    genres: "comedy",
  },
  "2022-09-23T14:38:37Z": {
    type: "show",
    title: "Cosmos",
    year: "1980",
    trakt_rating: "9.18373",
    trakt_id: "1421",
    imdb_id: "tt0081846",
    tmdb_id: "1430",
    tvdb_id: "74995",
    url: "https://trakt.tv/shows/cosmos",
    released: "1980-09-29",
    runtime: "60",
    episode_count: "13",
    genres: "documentary",
  },
  "2022-09-23T11:21:59Z": {
    type: "show",
    title: "The Lord of the Rings: The Rings of Power",
    year: "2022",
    trakt_rating: "7.2462",
    trakt_id: "150900",
    imdb_id: "tt7631058",
    tmdb_id: "84773",
    tvdb_id: "367506",
    url: "https://trakt.tv/shows/the-lord-of-the-rings-the-rings-of-power",
    released: "2022-09-02",
    runtime: "60",
    episode_count: "5",
    genres: "fantasy,science-fiction,action,adventure,drama",
  },
  "2022-09-23T11:21:37Z": {
    type: "show",
    title: "Dahmer - Monster: The Jeffrey Dahmer Story",
    year: "2022",
    trakt_rating: "7.71429",
    trakt_id: "167797",
    imdb_id: "tt13207736",
    tmdb_id: "113988",
    tvdb_id: "389492",
    url: "https://trakt.tv/shows/dahmer-monster-the-jeffrey-dahmer-story",
    released: "2022-09-21",
    runtime: "54",
    episode_count: "10",
    genres: "drama,crime",
  },
  "2022-09-23T02:01:20Z": {
    type: "show",
    title: "See",
    year: "2019",
    trakt_rating: "7.73327",
    trakt_id: "145781",
    imdb_id: "tt7949218",
    tmdb_id: "80752",
    tvdb_id: "361565",
    url: "https://trakt.tv/shows/see",
    released: "2019-11-02",
    runtime: "60",
    episode_count: "21",
    genres: "drama,fantasy,science-fiction,action,adventure",
  },
  "2022-09-22T12:00:06Z": {
    type: "show",
    title: "Stargirl",
    year: "2020",
    trakt_rating: "7.28807",
    trakt_id: "138096",
    imdb_id: "tt8722888",
    tmdb_id: "80986",
    tvdb_id: "361868",
    url: "https://trakt.tv/shows/stargirl",
    released: "2020-05-19",
    runtime: "43",
    episode_count: "30",
    genres: "action,adventure,drama,fantasy,science-fiction,superhero",
  },
  "2022-09-22T11:59:52Z": {
    type: "show",
    title: "Archer",
    year: "2009",
    trakt_rating: "8.64071",
    trakt_id: "10234",
    imdb_id: "tt1486217",
    tmdb_id: "10283",
    tvdb_id: "110381",
    url: "https://trakt.tv/shows/archer",
    released: "2009-09-18",
    runtime: "22",
    episode_count: "128",
    genres: "animation,comedy,action,adventure",
  },
  "2022-09-22T11:59:40Z": {
    type: "show",
    title: "Resident Alien",
    year: "2021",
    trakt_rating: "7.91331",
    trakt_id: "153575",
    imdb_id: "tt8690918",
    tmdb_id: "96580",
    tvdb_id: "368166",
    url: "https://trakt.tv/shows/resident-alien",
    released: "2021-01-28",
    runtime: "45",
    episode_count: "25",
    genres: "fantasy,science-fiction",
  },
  "2022-09-22T01:32:21Z": {
    type: "show",
    title: "I, Claudius",
    year: "1976",
    trakt_rating: "8.48347",
    trakt_id: "5460",
    imdb_id: "tt0074006",
    tmdb_id: "5487",
    tvdb_id: "71046",
    url: "https://trakt.tv/shows/i-claudius",
    released: "1976-09-20",
    runtime: "55",
    episode_count: "12",
    genres: "drama,war",
  },
  "2022-09-21T16:41:04Z": {
    type: "show",
    title: "Shadowland",
    year: "2022",
    trakt_rating: "0.0",
    trakt_id: "198155",
    imdb_id: "tt13476324",
    tmdb_id: "209738",
    tvdb_id: "424000",
    url: "https://trakt.tv/shows/shadowland",
    released: "2022-09-21",
    runtime: "1",
    episode_count: "6",
    genres: "documentary",
  },
  "2022-09-21T16:40:06Z": {
    type: "show",
    title: "Reservation Dogs",
    year: "2021",
    trakt_rating: "7.76796",
    trakt_id: "154900",
    imdb_id: "tt13623580",
    tmdb_id: "95215",
    tvdb_id: "371884",
    url: "https://trakt.tv/shows/reservation-dogs",
    released: "2021-08-09",
    runtime: "29",
    episode_count: "17",
    genres: "comedy,crime,drama",
  },
  "2022-09-21T16:39:54Z": {
    type: "show",
    title: "The Handmaid's Tale",
    year: "2017",
    trakt_rating: "8.3679",
    trakt_id: "113938",
    imdb_id: "tt5834204",
    tmdb_id: "69478",
    tvdb_id: "321239",
    url: "https://trakt.tv/shows/the-handmaid-s-tale",
    released: "2017-04-26",
    runtime: "50",
    episode_count: "49",
    genres: "drama,fantasy,science-fiction,thriller",
  },
  "2022-09-20T11:26:51Z": {
    type: "show",
    title: "The Patient",
    year: "2022",
    trakt_rating: "7.09859",
    trakt_id: "189420",
    imdb_id: "tt15574312",
    tmdb_id: "135868",
    tvdb_id: "411118",
    url: "https://trakt.tv/shows/the-patient",
    released: "2022-08-30",
    runtime: "22",
    episode_count: "5",
    genres: "drama,crime",
  },
  "2022-09-19T19:40:32Z": {
    type: "show",
    title: "War of the Worlds",
    year: "2019",
    trakt_rating: "6.79683",
    trakt_id: "153304",
    imdb_id: "tt9686194",
    tmdb_id: "86374",
    tvdb_id: "370139",
    url: "https://trakt.tv/shows/war-of-the-worlds-2019",
    released: "2019-10-28",
    runtime: "47",
    episode_count: "22",
    genres: "drama,fantasy,science-fiction",
  },
  "2022-09-19T11:15:17Z": {
    type: "show",
    title: "Bloodlands",
    year: "2021",
    trakt_rating: "6.83756",
    trakt_id: "158260",
    imdb_id: "tt11285548",
    tmdb_id: "119191",
    tvdb_id: "377045",
    url: "https://trakt.tv/shows/bloodlands-2021",
    released: "2021-02-21",
    runtime: "56",
    episode_count: "5",
    genres: "thriller,crime,drama",
  },
  "2022-09-19T11:14:37Z": {
    type: "show",
    title: "Rick and Morty",
    year: "2013",
    trakt_rating: "8.96435",
    trakt_id: "69829",
    imdb_id: "tt2861424",
    tmdb_id: "60625",
    tvdb_id: "275274",
    url: "https://trakt.tv/shows/rick-and-morty",
    released: "2013-12-03",
    runtime: "22",
    episode_count: "54",
    genres: "animation,comedy,fantasy,science-fiction,action,adventure",
  },
  "2022-09-19T01:34:26Z": {
    type: "show",
    title: "House of the Dragon",
    year: "2022",
    trakt_rating: "8.26619",
    trakt_id: "154574",
    imdb_id: "tt11198330",
    tmdb_id: "94997",
    tvdb_id: "371572",
    url: "https://trakt.tv/shows/house-of-the-dragon",
    released: "2022-08-22",
    runtime: "42",
    episode_count: "5",
    genres: "drama,action,adventure,fantasy,science-fiction",
  },
  "2022-09-19T01:21:48Z": {
    type: "show",
    title: "Santo",
    year: "2022",
    trakt_rating: "5.66667",
    trakt_id: "197571",
    imdb_id: "tt14278524",
    tmdb_id: "128013",
    tvdb_id: "399138",
    url: "https://trakt.tv/shows/santo",
    released: "2022-09-16",
    runtime: "50",
    episode_count: "6",
    genres: "crime,drama",
  },
  "2022-09-08T11:50:27Z": {
    type: "show",
    title: "American Horror Stories",
    year: "2021",
    trakt_rating: "6.39807",
    trakt_id: "162066",
    imdb_id: "tt12306692",
    tmdb_id: "113036",
    tvdb_id: "382187",
    url: "https://trakt.tv/shows/american-horror-stories",
    released: "2021-07-15",
    runtime: "42",
    episode_count: "15",
    genres: "horror,drama,mystery,fantasy,science-fiction",
  },
  "2022-09-07T12:14:33Z": {
    type: "show",
    title: "What We Do in the Shadows",
    year: "2019",
    trakt_rating: "8.3584",
    trakt_id: "142927",
    imdb_id: "tt7908628",
    tmdb_id: "83631",
    tvdb_id: "358211",
    url: "https://trakt.tv/shows/what-we-do-in-the-shadows",
    released: "2019-03-28",
    runtime: "30",
    episode_count: "40",
    genres: "comedy",
  },
  "2022-09-03T14:05:11Z": {
    type: "show",
    title: "Wellington Paranormal",
    year: "2018",
    trakt_rating: "7.3188",
    trakt_id: "128831",
    imdb_id: "tt6109562",
    tmdb_id: "80475",
    tvdb_id: "344280",
    url: "https://trakt.tv/shows/wellington-paranormal",
    released: "2018-07-11",
    runtime: "21",
    episode_count: "25",
    genres: "comedy",
  },
  "2022-09-02T20:02:16Z": {
    type: "show",
    title: "Surface",
    year: "2022",
    trakt_rating: "6.36893",
    trakt_id: "170227",
    imdb_id: "tt13694702",
    tmdb_id: "127641",
    tvdb_id: "392740",
    url: "https://trakt.tv/shows/surface-2022",
    released: "2022-07-29",
    runtime: "31",
    episode_count: "8",
    genres: "drama",
  },
  "2022-08-28T14:47:13Z": {
    type: "show",
    title: "The Beatles: Get Back",
    year: "2021",
    trakt_rating: "8.62022",
    trakt_id: "182510",
    imdb_id: "tt9735318",
    tmdb_id: "128876",
    tvdb_id: "405144",
    url: "https://trakt.tv/shows/the-beatles-get-back",
    released: "2021-11-25",
    runtime: "138",
    episode_count: "3",
    genres: "documentary",
  },
  "2022-08-19T12:23:59Z": {
    type: "show",
    title: "The Sandman",
    year: "2022",
    trakt_rating: "7.84802",
    trakt_id: "149786",
    imdb_id: "tt1751634",
    tmdb_id: "90802",
    tvdb_id: "366211",
    url: "https://trakt.tv/shows/the-sandman",
    released: "2022-08-05",
    runtime: "48",
    episode_count: "11",
    genres: "fantasy,science-fiction,drama,superhero",
  },
  "2022-08-16T14:57:59Z": {
    type: "show",
    title: "Better Call Saul",
    year: "2015",
    trakt_rating: "8.67373",
    trakt_id: "59660",
    imdb_id: "tt3032476",
    tmdb_id: "60059",
    tvdb_id: "273181",
    url: "https://trakt.tv/shows/better-call-saul",
    released: "2015-02-09",
    runtime: "45",
    episode_count: "63",
    genres: "drama,crime",
  },
  "2022-08-15T02:19:53Z": {
    type: "show",
    title: "Westworld",
    year: "2016",
    trakt_rating: "8.51258",
    trakt_id: "99718",
    imdb_id: "tt0475784",
    tmdb_id: "63247",
    tvdb_id: "296762",
    url: "https://trakt.tv/shows/westworld",
    released: "2016-10-03",
    runtime: "60",
    episode_count: "36",
    genres: "western,science-fiction,fantasy",
  },
  "2022-08-14T13:03:38Z": {
    type: "show",
    title: "Evil",
    year: "2019",
    trakt_rating: "7.75075",
    trakt_id: "147814",
    imdb_id: "tt9055008",
    tmdb_id: "86848",
    tvdb_id: "363955",
    url: "https://trakt.tv/shows/evil",
    released: "2019-09-26",
    runtime: "43",
    episode_count: "25",
    genres: "drama",
  },
  "2022-08-12T03:42:21Z": {
    type: "show",
    title: "Loot",
    year: "2022",
    trakt_rating: "6.88462",
    trakt_id: "194722",
    imdb_id: "tt14271498",
    tmdb_id: "197449",
    tvdb_id: "417559",
    url: "https://trakt.tv/shows/loot",
    released: "2022-06-24",
    runtime: "30",
    episode_count: "10",
    genres: "comedy",
  },
  "2022-08-12T01:54:36Z": {
    type: "show",
    title: "For All Mankind",
    year: "2019",
    trakt_rating: "8.13955",
    trakt_id: "140481",
    imdb_id: "tt7772588",
    tmdb_id: "87917",
    tvdb_id: "356202",
    url: "https://trakt.tv/shows/for-all-mankind",
    released: "2019-11-02",
    runtime: "60",
    episode_count: "30",
    genres: "fantasy,science-fiction,drama,war",
  },
  "2022-08-05T11:52:34Z": {
    type: "show",
    title: "Black Bird",
    year: "2022",
    trakt_rating: "8.12362",
    trakt_id: "180817",
    imdb_id: "tt4301160",
    tmdb_id: "155537",
    tvdb_id: "401998",
    url: "https://trakt.tv/shows/black-bird",
    released: "2022-07-08",
    runtime: "60",
    episode_count: "6",
    genres: "crime,drama",
  },
  "2022-07-30T01:25:28Z": {
    type: "show",
    title: "Paper Girls",
    year: "2022",
    trakt_rating: "7.23724",
    trakt_id: "164810",
    imdb_id: "tt10623646",
    tmdb_id: "106431",
    tvdb_id: "368187",
    url: "https://trakt.tv/shows/paper-girls",
    released: "2022-07-29",
    runtime: "45",
    episode_count: "8",
    genres: "drama,mystery,fantasy,science-fiction",
  },
  "2022-07-26T12:21:00Z": {
    type: "show",
    title: "Irma Vep",
    year: "2022",
    trakt_rating: "6.73154",
    trakt_id: "170810",
    imdb_id: "tt13649314",
    tmdb_id: "115626",
    tvdb_id: "393404",
    url: "https://trakt.tv/shows/irma-vep",
    released: "2022-06-07",
    runtime: "60",
    episode_count: "8",
    genres: "comedy,drama",
  },
  "2022-07-22T12:20:59Z": {
    type: "show",
    title: "The Old Man",
    year: "2022",
    trakt_rating: "7.65232",
    trakt_id: "150805",
    imdb_id: "tt5645432",
    tmdb_id: "111800",
    tvdb_id: "367392",
    url: "https://trakt.tv/shows/the-old-man",
    released: "2022-06-17",
    runtime: "62",
    episode_count: "7",
    genres: "action,drama,adventure,thriller",
  },
  "2022-07-15T00:03:42Z": {
    type: "show",
    title: "Dark Winds",
    year: "2022",
    trakt_rating: "7.44574",
    trakt_id: "183528",
    imdb_id: "tt15017118",
    tmdb_id: "128904",
    tvdb_id: "406179",
    url: "https://trakt.tv/shows/dark-winds",
    released: "2022-06-13",
    runtime: "47",
    episode_count: "6",
    genres: "crime,drama",
  },
  "2022-07-14T15:07:50Z": {
    type: "show",
    title: "Resident Evil",
    year: "2022",
    trakt_rating: "5.53833",
    trakt_id: "166099",
    imdb_id: "tt9660182",
    tmdb_id: "108296",
    tvdb_id: "387525",
    url: "https://trakt.tv/shows/resident-evil-2022",
    released: "2022-07-14",
    runtime: "55",
    episode_count: "8",
    genres: "fantasy,science-fiction,drama,horror",
  },
  "2022-07-09T13:30:01Z": {
    type: "show",
    title: "The Boys",
    year: "2019",
    trakt_rating: "8.50409",
    trakt_id: "139960",
    imdb_id: "tt1190634",
    tmdb_id: "76479",
    tvdb_id: "355567",
    url: "https://trakt.tv/shows/the-boys-2019",
    released: "2019-07-26",
    runtime: "60",
    episode_count: "24",
    genres: "action,superhero,fantasy,science-fiction,adventure",
  },
  "2022-07-01T14:27:29Z": {
    type: "show",
    title: "Stranger Things",
    year: "2016",
    trakt_rating: "8.62526",
    trakt_id: "104439",
    imdb_id: "tt4574334",
    tmdb_id: "66732",
    tvdb_id: "305288",
    url: "https://trakt.tv/shows/stranger-things",
    released: "2016-07-15",
    runtime: "50",
    episode_count: "34",
    genres: "drama,fantasy,science-fiction,mystery,horror",
  },
  "2022-07-01T14:27:13Z": {
    type: "show",
    title: "The Undeclared War",
    year: "2022",
    trakt_rating: "7.10092",
    trakt_id: "189984",
    imdb_id: "tt7939800",
    tmdb_id: "156988",
    tvdb_id: "401831",
    url: "https://trakt.tv/shows/the-undeclared-war",
    released: "2022-06-30",
    runtime: "47",
    episode_count: "6",
    genres: "drama,war,fantasy,science-fiction",
  },
  "2022-06-25T02:18:38Z": {
    type: "show",
    title: "Love, Death & Robots",
    year: "2019",
    trakt_rating: "8.24082",
    trakt_id: "142611",
    imdb_id: "tt9561862",
    tmdb_id: "86831",
    tvdb_id: "357888",
    url: "https://trakt.tv/shows/love-death-robots",
    released: "2019-03-15",
    runtime: "15",
    episode_count: "35",
    genres: "animation,fantasy,science-fiction",
  },
  "2022-06-25T01:38:02Z": {
    type: "show",
    title: "You Don't Know Me",
    year: "2021",
    trakt_rating: "7.08759",
    trakt_id: "191538",
    imdb_id: "tt12005828",
    tmdb_id: "139686",
    tvdb_id: "408708",
    url: "https://trakt.tv/shows/you-don-t-know-me",
    released: "2021-12-05",
    runtime: "57",
    episode_count: "4",
    genres: "drama",
  },
  "2022-06-22T13:26:39Z": {
    type: "show",
    title: "Obi-Wan Kenobi",
    year: "2022",
    trakt_rating: "7.05758",
    trakt_id: "169967",
    imdb_id: "tt8466564",
    tmdb_id: "92830",
    tvdb_id: "393199",
    url: "https://trakt.tv/shows/obi-wan-kenobi",
    released: "2022-05-26",
    runtime: "47",
    episode_count: "6",
    genres: "fantasy,science-fiction,action,adventure,drama",
  },
  "2022-06-16T11:03:05Z": {
    type: "show",
    title: "The Offer",
    year: "2022",
    trakt_rating: "8.09667",
    trakt_id: "170252",
    imdb_id: "tt13111040",
    tmdb_id: "114068",
    tvdb_id: "392770",
    url: "https://trakt.tv/shows/the-offer",
    released: "2022-04-28",
    runtime: "60",
    episode_count: "10",
    genres: "drama",
  },
  "2022-06-16T01:52:24Z": {
    type: "show",
    title: "Tehran",
    year: "2020",
    trakt_rating: "7.49827",
    trakt_id: "160368",
    imdb_id: "tt10577736",
    tmdb_id: "103913",
    tvdb_id: "380010",
    url: "https://trakt.tv/shows/tehran",
    released: "2020-06-22",
    runtime: "45",
    episode_count: "16",
    genres: "drama",
  },
  "2022-06-15T16:06:19Z": {
    type: "show",
    title: "Mayans M.C.",
    year: "2018",
    trakt_rating: "7.69865",
    trakt_id: "133615",
    imdb_id: "tt5715524",
    tmdb_id: "76231",
    tvdb_id: "349659",
    url: "https://trakt.tv/shows/mayans-m-c",
    released: "2018-09-05",
    runtime: "52",
    episode_count: "40",
    genres: "crime,drama",
  },
  "2022-06-13T12:57:39Z": {
    type: "show",
    title: "Barry",
    year: "2018",
    trakt_rating: "8.23853",
    trakt_id: "122709",
    imdb_id: "tt5348176",
    tmdb_id: "73107",
    tvdb_id: "333072",
    url: "https://trakt.tv/shows/barry",
    released: "2018-03-26",
    runtime: "30",
    episode_count: "24",
    genres: "comedy,crime,drama",
  },
  "2022-06-12T01:48:17Z": {
    type: "show",
    title: "The Staircase",
    year: "2022",
    trakt_rating: "7.00208",
    trakt_id: "176969",
    imdb_id: "tt11324406",
    tmdb_id: "122196",
    tvdb_id: "400269",
    url: "https://trakt.tv/shows/the-staircase-2022",
    released: "2022-05-05",
    runtime: "65",
    episode_count: "8",
    genres: "drama,crime",
  },
  "2022-06-11T22:50:53Z": {
    type: "show",
    title: "Gaslit",
    year: "2022",
    trakt_rating: "7.06875",
    trakt_id: "158611",
    imdb_id: "tt11834592",
    tmdb_id: "107407",
    tvdb_id: "415658",
    url: "https://trakt.tv/shows/gaslit",
    released: "2022-04-25",
    runtime: "60",
    episode_count: "8",
    genres: "drama",
  },
  "2022-06-03T13:15:34Z": {
    type: "show",
    title: "Shining Girls",
    year: "2022",
    trakt_rating: "7.59778",
    trakt_id: "187254",
    imdb_id: "tt2953250",
    tmdb_id: "106388",
    tvdb_id: "385810",
    url: "https://trakt.tv/shows/shining-girls",
    released: "2022-04-29",
    runtime: "53",
    episode_count: "8",
    genres: "drama,thriller",
  },
  "2022-05-31T15:22:17Z": {
    type: "show",
    title: "Pistol",
    year: "2022",
    trakt_rating: "7.00769",
    trakt_id: "176615",
    imdb_id: "tt13823494",
    tmdb_id: "132159",
    tvdb_id: "394966",
    url: "https://trakt.tv/shows/pistol",
    released: "2022-05-31",
    runtime: "45",
    episode_count: "6",
    genres: "drama",
  },
  "2022-05-30T18:29:06Z": {
    type: "show",
    title: "We Own This City",
    year: "2022",
    trakt_rating: "7.52913",
    trakt_id: "175013",
    imdb_id: "tt14192504",
    tmdb_id: "125949",
    tvdb_id: "398140",
    url: "https://trakt.tv/shows/we-own-this-city",
    released: "2022-04-26",
    runtime: "60",
    episode_count: "6",
    genres: "crime,drama",
  },
  "2022-05-29T10:31:50Z": {
    type: "show",
    title: "Fear the Walking Dead",
    year: "2015",
    trakt_rating: "7.12766",
    trakt_id: "94961",
    imdb_id: "tt3743822",
    tmdb_id: "62286",
    tvdb_id: "290853",
    url: "https://trakt.tv/shows/fear-the-walking-dead",
    released: "2015-08-24",
    runtime: "43",
    episode_count: "101",
    genres: "drama,action,adventure,horror",
  },
  "2022-05-27T01:33:26Z": {
    type: "show",
    title: "Bosch: Legacy",
    year: "2022",
    trakt_rating: "8.23133",
    trakt_id: "191869",
    imdb_id: "tt14168162",
    tmdb_id: "153657",
    tvdb_id: "414553",
    url: "https://trakt.tv/shows/bosch-legacy",
    released: "2022-05-05",
    runtime: "50",
    episode_count: "10",
    genres: "crime,drama",
  },
  "2022-05-26T12:14:56Z": {
    type: "show",
    title: "The Flight Attendant",
    year: "2020",
    trakt_rating: "7.11397",
    trakt_id: "153823",
    imdb_id: "tt7569576",
    tmdb_id: "93287",
    tvdb_id: "366625",
    url: "https://trakt.tv/shows/the-flight-attendant",
    released: "2020-11-26",
    runtime: "48",
    episode_count: "16",
    genres: "drama,mystery,thriller,comedy",
  },
  "2022-05-25T11:34:12Z": {
    type: "show",
    title: "Young Rock",
    year: "2021",
    trakt_rating: "7.10442",
    trakt_id: "157540",
    imdb_id: "tt11639952",
    tmdb_id: "97754",
    tvdb_id: "375995",
    url: "https://trakt.tv/shows/young-rock",
    released: "2021-02-17",
    runtime: "22",
    episode_count: "23",
    genres: "comedy",
  },
  "2022-05-23T12:36:29Z": {
    type: "show",
    title: "Family Guy",
    year: "1999",
    trakt_rating: "7.98076",
    trakt_id: "1425",
    imdb_id: "tt0182576",
    tmdb_id: "1434",
    tvdb_id: "75978",
    url: "https://trakt.tv/shows/family-guy",
    released: "1999-02-01",
    runtime: "22",
    episode_count: "389",
    genres: "animation,comedy",
  },
  "2022-05-23T12:34:01Z": {
    type: "show",
    title: "The Simpsons",
    year: "1989",
    trakt_rating: "8.4391",
    trakt_id: "455",
    imdb_id: "tt0096697",
    tmdb_id: "456",
    tvdb_id: "71663",
    url: "https://trakt.tv/shows/the-simpsons",
    released: "1989-12-17",
    runtime: "22",
    episode_count: "728",
    genres: "animation,comedy,family",
  },
  "2022-05-19T13:26:49Z": {
    type: "show",
    title: "Made For Love",
    year: "2021",
    trakt_rating: "6.76127",
    trakt_id: "154279",
    imdb_id: "tt7808566",
    tmdb_id: "90970",
    tvdb_id: "371158",
    url: "https://trakt.tv/shows/made-for-love",
    released: "2021-04-01",
    runtime: "30",
    episode_count: "16",
    genres: "fantasy,science-fiction,comedy,drama",
  },
  "2022-05-08T13:42:51Z": {
    type: "show",
    title: "From",
    year: "2022",
    trakt_rating: "7.72291",
    trakt_id: "188205",
    imdb_id: "tt9813792",
    tmdb_id: "124364",
    tvdb_id: "401003",
    url: "https://trakt.tv/shows/from",
    released: "2022-02-21",
    runtime: "52",
    episode_count: "10",
    genres: "horror,drama,mystery,fantasy,science-fiction",
  },
  "2022-05-06T15:45:53Z": {
    type: "show",
    title: "Tokyo Vice",
    year: "2022",
    trakt_rating: "7.81435",
    trakt_id: "156576",
    imdb_id: "tt2887954",
    tmdb_id: "90296",
    tvdb_id: "366624",
    url: "https://trakt.tv/shows/tokyo-vice",
    released: "2022-04-07",
    runtime: "57",
    episode_count: "8",
    genres: "drama,crime,thriller",
  },
  "2022-05-06T15:44:50Z": {
    type: "show",
    title: "Outer Range",
    year: "2022",
    trakt_rating: "7.03782",
    trakt_id: "170118",
    imdb_id: "tt11685912",
    tmdb_id: "113985",
    tvdb_id: "391101",
    url: "https://trakt.tv/shows/outer-range",
    released: "2022-04-15",
    runtime: "50",
    episode_count: "8",
    genres: "thriller,western,mystery",
  },
  "2022-05-05T13:24:42Z": {
    type: "show",
    title: "Star Trek: Picard",
    year: "2020",
    trakt_rating: "7.54228",
    trakt_id: "147922",
    imdb_id: "tt8806524",
    tmdb_id: "85949",
    tvdb_id: "364093",
    url: "https://trakt.tv/shows/star-trek-picard",
    released: "2020-01-23",
    runtime: "44",
    episode_count: "20",
    genres: "fantasy,science-fiction,action,adventure",
  },
  "2022-05-04T13:30:23Z": {
    type: "show",
    title: "Moon Knight",
    year: "2022",
    trakt_rating: "7.48373",
    trakt_id: "151840",
    imdb_id: "tt10234724",
    tmdb_id: "92749",
    tvdb_id: "368611",
    url: "https://trakt.tv/shows/moon-knight",
    released: "2022-03-30",
    runtime: "47",
    episode_count: "6",
    genres: "fantasy,science-fiction,action,adventure,superhero,mystery",
  },
  "2022-05-01T11:44:12Z": {
    type: "show",
    title: "DMZ",
    year: "2022",
    trakt_rating: "5.52402",
    trakt_id: "153498",
    imdb_id: "tt11057226",
    tmdb_id: "113566",
    tvdb_id: "414938",
    url: "https://trakt.tv/shows/dmz",
    released: "2022-03-16",
    runtime: "59",
    episode_count: "4",
    genres: "drama,fantasy,science-fiction",
  },
  "2022-04-29T13:21:07Z": {
    type: "show",
    title: "Slow Horses",
    year: "2022",
    trakt_rating: "7.71775",
    trakt_id: "155534",
    imdb_id: "tt5875444",
    tmdb_id: "95480",
    tvdb_id: "372264",
    url: "https://trakt.tv/shows/slow-horses",
    released: "2022-03-30",
    runtime: "50",
    episode_count: "6",
    genres: "drama,thriller,crime",
  },
  "2022-04-29T13:19:40Z": {
    type: "show",
    title: "Ozark",
    year: "2017",
    trakt_rating: "8.32362",
    trakt_id: "119913",
    imdb_id: "tt5071412",
    tmdb_id: "69740",
    tvdb_id: "329089",
    url: "https://trakt.tv/shows/ozark",
    released: "2017-07-21",
    runtime: "56",
    episode_count: "44",
    genres: "drama,crime",
  },
  "2022-04-24T12:57:47Z": {
    type: "show",
    title: "WeCrashed",
    year: "2022",
    trakt_rating: "7.20639",
    trakt_id: "170891",
    imdb_id: "tt12005128",
    tmdb_id: "117821",
    tvdb_id: "393499",
    url: "https://trakt.tv/shows/wecrashed",
    released: "2022-03-18",
    runtime: "53",
    episode_count: "8",
    genres: "drama",
  },
  "2022-04-13T03:56:22Z": {
    type: "show",
    title: "1883",
    year: "2021",
    trakt_rating: "8.6159",
    trakt_id: "173337",
    imdb_id: "tt13991232",
    tmdb_id: "118357",
    tvdb_id: "396390",
    url: "https://trakt.tv/shows/1883",
    released: "2021-12-19",
    runtime: "59",
    episode_count: "10",
    genres: "drama,western",
  },
  "2022-04-11T19:33:26Z": {
    type: "show",
    title: "Lost in Space",
    year: "2018",
    trakt_rating: "7.58574",
    trakt_id: "128262",
    imdb_id: "tt5232792",
    tmdb_id: "75758",
    tvdb_id: "343253",
    url: "https://trakt.tv/shows/lost-in-space-2018",
    released: "2018-04-13",
    runtime: "56",
    episode_count: "10",
    genres: "fantasy,science-fiction,action,adventure,drama",
  },
  "2022-04-11T19:33:09Z": {
    type: "show",
    title: "Station Eleven",
    year: "2021",
    trakt_rating: "7.4164",
    trakt_id: "153862",
    imdb_id: "tt10574236",
    tmdb_id: "90972",
    tvdb_id: "366529",
    url: "https://trakt.tv/shows/station-eleven",
    released: "2021-12-16",
    runtime: "46",
    episode_count: "10",
    genres: "drama,fantasy,science-fiction",
  },
  "2022-04-11T19:32:35Z": {
    type: "show",
    title: "Beforeigners",
    year: "2019",
    trakt_rating: "7.60526",
    trakt_id: "150390",
    imdb_id: "tt8332130",
    tmdb_id: "91520",
    tvdb_id: "366854",
    url: "https://trakt.tv/shows/beforeigners",
    released: "2019-08-20",
    runtime: "50",
    episode_count: "12",
    genres: "drama,fantasy,science-fiction",
  },
  "2022-04-08T15:57:19Z": {
    type: "show",
    title: "Severance",
    year: "2022",
    trakt_rating: "8.63829",
    trakt_id: "154997",
    imdb_id: "tt11280740",
    tmdb_id: "95396",
    tvdb_id: "371980",
    url: "https://trakt.tv/shows/severance",
    released: "2022-02-17",
    runtime: "50",
    episode_count: "9",
    genres: "fantasy,science-fiction,thriller,mystery,drama",
  },
  "2022-04-03T14:38:21Z": {
    type: "show",
    title: "The Walking Dead",
    year: "2010",
    trakt_rating: "8.10677",
    trakt_id: "1393",
    imdb_id: "tt1520211",
    tmdb_id: "1402",
    tvdb_id: "153021",
    url: "https://trakt.tv/shows/the-walking-dead",
    released: "2010-11-01",
    runtime: "42",
    episode_count: "169",
    genres: "drama,action,adventure,fantasy,science-fiction,horror",
  },
  "2022-03-29T16:44:47Z": {
    type: "show",
    title: "Wolfboy and The Everything Factory",
    year: "2021",
    trakt_rating: "7.33333",
    trakt_id: "187629",
    imdb_id: "tt15389676",
    tmdb_id: "133106",
    tvdb_id: "409758",
    url: "https://trakt.tv/shows/wolfboy-and-the-everything-factory",
    released: "2021-09-23",
    runtime: "12",
    episode_count: "10",
    genres: "animation,children",
  },
  "2022-03-25T22:27:09Z": {
    type: "show",
    title: "Servant",
    year: "2019",
    trakt_rating: "7.52676",
    trakt_id: "152779",
    imdb_id: "tt8068860",
    tmdb_id: "88055",
    tvdb_id: "368126",
    url: "https://trakt.tv/shows/servant",
    released: "2019-11-29",
    runtime: "30",
    episode_count: "30",
    genres: "drama,horror",
  },
  "2022-03-21T11:20:12Z": {
    type: "show",
    title: "Raised by Wolves",
    year: "2020",
    trakt_rating: "7.33644",
    trakt_id: "152958",
    imdb_id: "tt9170108",
    tmdb_id: "85723",
    tvdb_id: "368643",
    url: "https://trakt.tv/shows/raised-by-wolves-2020",
    released: "2020-09-03",
    runtime: "50",
    episode_count: "18",
    genres: "fantasy,science-fiction,drama",
  },
  "2022-03-21T11:18:11Z": {
    type: "show",
    title: "The Me You Can't See",
    year: "2021",
    trakt_rating: "6.83333",
    trakt_id: "180306",
    imdb_id: "tt14584186",
    tmdb_id: "125301",
    tvdb_id: "402511",
    url: "https://trakt.tv/shows/the-me-you-can-t-see",
    released: "2021-05-21",
    runtime: "57",
    episode_count: "6",
    genres: "documentary",
  },
  "2022-03-19T15:53:25Z": {
    type: "show",
    title: "Cracow Monsters",
    year: "2022",
    trakt_rating: "6.18966",
    trakt_id: "193599",
    imdb_id: "tt15096836",
    tmdb_id: "158396",
    tvdb_id: "414616",
    url: "https://trakt.tv/shows/cracow-monsters",
    released: "2022-03-18",
    runtime: "53",
    episode_count: "8",
    genres: "horror,fantasy,science-fiction",
  },
  "2022-03-19T15:53:05Z": {
    type: "show",
    title: "Bad Vegan: Fame. Fraud. Fugitives.",
    year: "2022",
    trakt_rating: "6.34802",
    trakt_id: "188553",
    imdb_id: "tt18260538",
    tmdb_id: "157709",
    tvdb_id: "410441",
    url: "https://trakt.tv/shows/bad-vegan-fame-fraud-fugitives",
    released: "2022-03-16",
    runtime: "47",
    episode_count: "4",
    genres: "documentary,crime",
  },
  "2022-03-18T16:46:47Z": {
    type: "show",
    title: "South Park",
    year: "1997",
    trakt_rating: "8.54353",
    trakt_id: "2177",
    imdb_id: "tt0121955",
    tmdb_id: "2190",
    tvdb_id: "75897",
    url: "https://trakt.tv/shows/south-park",
    released: "1997-08-14",
    runtime: "22",
    episode_count: "315",
    genres: "animation,comedy",
  },
  "2022-02-28T14:13:34Z": {
    type: "show",
    title: "Euphoria",
    year: "2019",
    trakt_rating: "8.33158",
    trakt_id: "144629",
    imdb_id: "tt8772296",
    tmdb_id: "85552",
    tvdb_id: "360261",
    url: "https://trakt.tv/shows/euphoria-2019",
    released: "2019-06-17",
    runtime: "60",
    episode_count: "15",
    genres: "drama,crime",
  },
  "2022-02-09T14:50:39Z": {
    type: "show",
    title: "The Book of Boba Fett",
    year: "2021",
    trakt_rating: "7.22964",
    trakt_id: "171129",
    imdb_id: "tt13668894",
    tmdb_id: "115036",
    tvdb_id: "393589",
    url: "https://trakt.tv/shows/the-book-of-boba-fett",
    released: "2021-12-29",
    runtime: "39",
    episode_count: "7",
    genres: "fantasy,science-fiction,action,adventure",
  },
  "2022-02-01T18:23:47Z": {
    type: "show",
    title: "Pure",
    year: "2019",
    trakt_rating: "7.16418",
    trakt_id: "143206",
    imdb_id: "tt8147076",
    tmdb_id: "86401",
    tvdb_id: "358508",
    url: "https://trakt.tv/shows/pure-2019",
    released: "2019-01-30",
    runtime: "35",
    episode_count: "6",
    genres: "comedy,drama",
  },
  "2022-01-20T23:08:28Z": {
    type: "show",
    title: "Foundation",
    year: "2021",
    trakt_rating: "7.6228",
    trakt_id: "150469",
    imdb_id: "tt0804484",
    tmdb_id: "93740",
    tvdb_id: "366972",
    url: "https://trakt.tv/shows/foundation-2021",
    released: "2021-09-23",
    runtime: "55",
    episode_count: "10",
    genres: "science-fiction,drama,fantasy",
  },
  "2022-01-16T17:04:18Z": {
    type: "show",
    title: "Yellowjackets",
    year: "2021",
    trakt_rating: "7.69533",
    trakt_id: "176276",
    imdb_id: "tt11041332",
    tmdb_id: "117488",
    tvdb_id: "399731",
    url: "https://trakt.tv/shows/yellowjackets",
    released: "2021-11-15",
    runtime: "57",
    episode_count: "10",
    genres: "drama,mystery",
  },
  "2022-01-14T19:31:01Z": {
    type: "show",
    title: "The Expanse",
    year: "2015",
    trakt_rating: "8.57909",
    trakt_id: "77199",
    imdb_id: "tt3230854",
    tmdb_id: "63639",
    tvdb_id: "280619",
    url: "https://trakt.tv/shows/the-expanse",
    released: "2015-12-15",
    runtime: "43",
    episode_count: "62",
    genres: "fantasy,science-fiction,mystery,drama",
  },
  "2022-01-09T15:05:27Z": {
    type: "show",
    title: "Dexter: New Blood",
    year: "2021",
    trakt_rating: "7.85809",
    trakt_id: "187026",
    imdb_id: "tt14164730",
    tmdb_id: "131927",
    tvdb_id: "412366",
    url: "https://trakt.tv/shows/dexter-new-blood",
    released: "2021-11-08",
    runtime: "51",
    episode_count: "10",
    genres: "drama,crime",
  },
  "2022-01-03T18:35:30Z": {
    type: "show",
    title: "Yellowstone",
    year: "2018",
    trakt_rating: "8.61715",
    trakt_id: "126995",
    imdb_id: "tt4236770",
    tmdb_id: "73586",
    tvdb_id: "341164",
    url: "https://trakt.tv/shows/yellowstone-2018",
    released: "2018-06-21",
    runtime: "42",
    episode_count: "39",
    genres: "drama,western",
  },
  "2021-12-31T18:49:55Z": {
    type: "show",
    title: "Ted Lasso",
    year: "2020",
    trakt_rating: "8.6683",
    trakt_id: "162639",
    imdb_id: "tt10986410",
    tmdb_id: "97546",
    tvdb_id: "383203",
    url: "https://trakt.tv/shows/ted-lasso",
    released: "2020-08-14",
    runtime: "30",
    episode_count: "22",
    genres: "comedy,drama",
  },
  "2021-12-27T13:41:12Z": {
    type: "show",
    title: "Curb Your Enthusiasm",
    year: "2000",
    trakt_rating: "8.64149",
    trakt_id: "4522",
    imdb_id: "tt0264235",
    tmdb_id: "4546",
    tvdb_id: "76203",
    url: "https://trakt.tv/shows/curb-your-enthusiasm",
    released: "2000-10-16",
    runtime: "30",
    episode_count: "110",
    genres: "comedy",
  },
  "2021-12-18T16:16:20Z": {
    type: "show",
    title: "Gomorra",
    year: "2014",
    trakt_rating: "8.54229",
    trakt_id: "77641",
    imdb_id: "tt2049116",
    tmdb_id: "61068",
    tvdb_id: "281342",
    url: "https://trakt.tv/shows/gomorra",
    released: "2014-05-06",
    runtime: "50",
    episode_count: "58",
    genres: "crime,drama",
  },
  "2021-12-17T20:31:50Z": {
    type: "show",
    title: "Succession",
    year: "2018",
    trakt_rating: "8.64207",
    trakt_id: "125479",
    imdb_id: "tt7660850",
    tmdb_id: "76331",
    tvdb_id: "338186",
    url: "https://trakt.tv/shows/succession",
    released: "2018-06-04",
    runtime: "65",
    episode_count: "29",
    genres: "drama,comedy",
  },
  "2021-12-04T03:37:32Z": {
    type: "show",
    title: "Arcane",
    year: "2021",
    trakt_rating: "9.05428",
    trakt_id: "154164",
    imdb_id: "tt11126994",
    tmdb_id: "94605",
    tvdb_id: "371028",
    url: "https://trakt.tv/shows/arcane",
    released: "2021-11-06",
    runtime: "39",
    episode_count: "9",
    genres: "adventure,animation,fantasy,action,science-fiction,drama",
  },
  "2021-11-28T13:07:29Z": {
    type: "show",
    title: "The Walking Dead: World Beyond",
    year: "2020",
    trakt_rating: "5.56374",
    trakt_id: "155566",
    imdb_id: "tt10148174",
    tmdb_id: "94305",
    tvdb_id: "372787",
    url: "https://trakt.tv/shows/the-walking-dead-world-beyond",
    released: "2020-10-05",
    runtime: "43",
    episode_count: "20",
    genres: "drama,science-fiction,fantasy,mystery,horror",
  },
  "2021-11-19T22:11:10Z": {
    type: "show",
    title: "Dopesick",
    year: "2021",
    trakt_rating: "8.34223",
    trakt_id: "168682",
    imdb_id: "tt9174558",
    tmdb_id: "110695",
    tvdb_id: "390716",
    url: "https://trakt.tv/shows/dopesick",
    released: "2021-10-13",
    runtime: "62",
    episode_count: "8",
    genres: "drama,crime",
  },
  "2021-11-12T14:48:59Z": {
    type: "show",
    title: "Doom Patrol",
    year: "2019",
    trakt_rating: "7.70135",
    trakt_id: "140008",
    imdb_id: "tt8416494",
    tmdb_id: "79501",
    tvdb_id: "355622",
    url: "https://trakt.tv/shows/doom-patrol",
    released: "2019-02-15",
    runtime: "49",
    episode_count: "34",
    genres: "fantasy,science-fiction,superhero,drama",
  },
  "2021-11-10T08:08:24Z": {
    type: "show",
    title: "American Crime Story",
    year: "2016",
    trakt_rating: "8.09447",
    trakt_id: "93939",
    imdb_id: "tt2788432",
    tmdb_id: "64513",
    tvdb_id: "289108",
    url: "https://trakt.tv/shows/american-crime-story",
    released: "2016-02-03",
    runtime: "42",
    episode_count: "29",
    genres: "crime,drama",
  },
  "2021-10-28T05:32:08Z": {
    type: "show",
    title: "Ghosts",
    year: "2019",
    trakt_rating: "7.97759",
    trakt_id: "145905",
    imdb_id: "tt8594324",
    tmdb_id: "17174",
    tvdb_id: "361701",
    url: "https://trakt.tv/shows/ghosts-2019",
    released: "2019-04-15",
    runtime: "30",
    episode_count: "18",
    genres: "comedy,fantasy,science-fiction",
  },
  "2021-10-21T08:24:49Z": {
    type: "show",
    title: "American Horror Story",
    year: "2011",
    trakt_rating: "8.04325",
    trakt_id: "1404",
    imdb_id: "tt1844624",
    tmdb_id: "1413",
    tvdb_id: "250487",
    url: "https://trakt.tv/shows/american-horror-story",
    released: "2011-10-06",
    runtime: "43",
    episode_count: "113",
    genres: "drama,mystery,fantasy,science-fiction,horror",
  },
  "2021-10-15T13:43:04Z": {
    type: "show",
    title: "You",
    year: "2018",
    trakt_rating: "7.7429",
    trakt_id: "124764",
    imdb_id: "tt7335184",
    tmdb_id: "78191",
    tvdb_id: "336924",
    url: "https://trakt.tv/shows/you",
    released: "2018-09-09",
    runtime: "45",
    episode_count: "30",
    genres: "crime,drama,mystery",
  },
  "2021-10-05T00:59:27Z": {
    type: "show",
    title: "The Hitchhiker's Guide to the Galaxy",
    year: "1981",
    trakt_rating: "8.1973",
    trakt_id: "2035",
    imdb_id: "tt0081874",
    tmdb_id: "2048",
    tvdb_id: "78142",
    url: "https://trakt.tv/shows/the-hitchhiker-s-guide-to-the-galaxy",
    released: "1981-01-05",
    runtime: "33",
    episode_count: "6",
    genres: "comedy,science-fiction,fantasy",
  },
  "2021-10-04T02:31:17Z": {
    type: "show",
    title: "Animal Kingdom",
    year: "2016",
    trakt_rating: "8.28337",
    trakt_id: "103963",
    imdb_id: "tt5574490",
    tmdb_id: "66025",
    tvdb_id: "304262",
    url: "https://trakt.tv/shows/animal-kingdom-2016",
    released: "2016-06-15",
    runtime: "60",
    episode_count: "13",
    genres: "drama,crime",
  },
  "2021-09-29T15:02:40Z": {
    type: "show",
    title: "Squid Game",
    year: "2021",
    trakt_rating: "7.86916",
    trakt_id: "162712",
    imdb_id: "tt10919420",
    tmdb_id: "93405",
    tvdb_id: "383275",
    url: "https://trakt.tv/shows/squid-game",
    released: "2021-09-17",
    runtime: "54",
    episode_count: "9",
    genres: "drama,action,adventure,mystery",
  },
  "2021-08-21T21:13:48Z": {
    type: "show",
    title: "Gravity Falls",
    year: "2012",
    trakt_rating: "8.89507",
    trakt_id: "39892",
    imdb_id: "tt1865718",
    tmdb_id: "40075",
    tvdb_id: "259972",
    url: "https://trakt.tv/shows/gravity-falls",
    released: "2012-06-16",
    runtime: "22",
    episode_count: "40",
    genres: "animation,comedy,mystery,fantasy,science-fiction,children",
  },
  "2021-08-16T02:02:08Z": {
    type: "show",
    title: "The White Lotus",
    year: "2021",
    trakt_rating: "7.43341",
    trakt_id: "168471",
    imdb_id: "tt13406094",
    tmdb_id: "111803",
    tvdb_id: "390430",
    url: "https://trakt.tv/shows/the-white-lotus",
    released: "2021-07-12",
    runtime: "54",
    episode_count: "6",
    genres: "comedy,drama,thriller,mystery",
  },
  "2021-08-01T12:05:47Z": {
    type: "show",
    title: "Star vs. the Forces of Evil",
    year: "2015",
    trakt_rating: "8.11085",
    trakt_id: "80083",
    imdb_id: "tt2758770",
    tmdb_id: "61923",
    tvdb_id: "282994",
    url: "https://trakt.tv/shows/star-vs-the-forces-of-evil",
    released: "2015-01-18",
    runtime: "11",
    episode_count: "140",
    genres: "animation,fantasy,science-fiction,action,adventure,comedy",
  },
  "2021-07-14T08:58:51Z": {
    type: "show",
    title: "Loki",
    year: "2021",
    trakt_rating: "8.07545",
    trakt_id: "146535",
    imdb_id: "tt9140554",
    tmdb_id: "84958",
    tvdb_id: "362472",
    url: "https://trakt.tv/shows/loki",
    released: "2021-06-09",
    runtime: "52",
    episode_count: "6",
    genres: "fantasy,science-fiction,drama,superhero",
  },
  "2021-06-25T13:07:50Z": {
    type: "show",
    title: "Clarice",
    year: "2021",
    trakt_rating: "7.06972",
    trakt_id: "157783",
    imdb_id: "tt2177268",
    tmdb_id: "103302",
    tvdb_id: "376395",
    url: "https://trakt.tv/shows/clarice",
    released: "2021-02-12",
    runtime: "43",
    episode_count: "13",
    genres: "crime,drama",
  },
  "2021-06-25T01:25:08Z": {
    type: "show",
    title: "Bosch",
    year: "2015",
    trakt_rating: "8.31898",
    trakt_id: "60169",
    imdb_id: "tt3502248",
    tmdb_id: "60585",
    tvdb_id: "277928",
    url: "https://trakt.tv/shows/bosch",
    released: "2015-01-14",
    runtime: "45",
    episode_count: "68",
    genres: "crime,drama,mystery",
  },
  "2021-05-30T19:42:56Z": {
    type: "show",
    title: "Gangs of London",
    year: "2020",
    trakt_rating: "8.061",
    trakt_id: "148150",
    imdb_id: "tt7661390",
    tmdb_id: "85021",
    tvdb_id: "364387",
    url: "https://trakt.tv/shows/gangs-of-london",
    released: "2020-04-23",
    runtime: "60",
    episode_count: "9",
    genres: "drama,crime",
  },
  "2021-05-28T17:22:55Z": {
    type: "show",
    title: "Manhunt",
    year: "2017",
    trakt_rating: "7.95693",
    trakt_id: "117934",
    imdb_id: "tt5618256",
    tmdb_id: "72597",
    tvdb_id: "326288",
    url: "https://trakt.tv/shows/manhunt-2017",
    released: "2017-08-01",
    runtime: "42",
    episode_count: "18",
    genres: "crime,mystery,documentary",
  },
  "2021-05-11T18:51:54Z": {
    type: "show",
    title: "Invincible",
    year: "2021",
    trakt_rating: "8.52938",
    trakt_id: "172648",
    imdb_id: "tt6741278",
    tmdb_id: "95557",
    tvdb_id: "368207",
    url: "https://trakt.tv/shows/invincible-2021",
    released: "2021-03-25",
    runtime: "45",
    episode_count: "8",
    genres:
      "superhero,animation,action,adventure,drama,fantasy,science-fiction",
  },
  "2021-05-03T05:05:07Z": {
    type: "show",
    title: "Line of Duty",
    year: "2012",
    trakt_rating: "8.53674",
    trakt_id: "43740",
    imdb_id: "tt2303687",
    tmdb_id: "43982",
    tvdb_id: "260092",
    url: "https://trakt.tv/shows/line-of-duty",
    released: "2012-06-26",
    runtime: "60",
    episode_count: "36",
    genres: "drama,mystery,crime",
  },
  "2021-04-07T01:54:52Z": {
    type: "show",
    title: "Q: Into the Storm",
    year: "2021",
    trakt_rating: "7.4505",
    trakt_id: "174425",
    imdb_id: "tt14215442",
    tmdb_id: "119761",
    tvdb_id: "397561",
    url: "https://trakt.tv/shows/q-into-the-storm",
    released: "2021-03-22",
    runtime: "58",
    episode_count: "6",
    genres: "documentary",
  },
  "2021-02-22T01:28:35Z": {
    type: "show",
    title: "Big Hero 6: The Series",
    year: "2017",
    trakt_rating: "7.60952",
    trakt_id: "115714",
    imdb_id: "tt5515212",
    tmdb_id: "68665",
    tvdb_id: "322241",
    url: "https://trakt.tv/shows/big-hero-6-the-series",
    released: "2017-11-21",
    runtime: "11",
    episode_count: "65",
    genres: "action,animation,comedy,adventure,family",
  },
  "2021-02-11T14:09:29Z": {
    type: "show",
    title: "The Stand",
    year: "2020",
    trakt_rating: "6.21597",
    trakt_id: "144126",
    imdb_id: "tt1831804",
    tmdb_id: "86382",
    tvdb_id: "359583",
    url: "https://trakt.tv/shows/the-stand-2020",
    released: "2020-12-17",
    runtime: "60",
    episode_count: "9",
    genres: "fantasy,science-fiction,drama,horror",
  },
  "2021-01-15T20:22:09Z": {
    type: "show",
    title: "Vikings",
    year: "2013",
    trakt_rating: "8.52432",
    trakt_id: "43973",
    imdb_id: "tt2306299",
    tmdb_id: "44217",
    tvdb_id: "260449",
    url: "https://trakt.tv/shows/vikings",
    released: "2013-03-04",
    runtime: "44",
    episode_count: "89",
    genres: "drama,action,adventure,war",
  },
  "2021-01-09T01:49:13Z": {
    type: "show",
    title: "The 100",
    year: "2014",
    trakt_rating: "7.85698",
    trakt_id: "48562",
    imdb_id: "tt2661044",
    tmdb_id: "48866",
    tvdb_id: "268592",
    url: "https://trakt.tv/shows/the-100",
    released: "2014-03-20",
    runtime: "43",
    episode_count: "100",
    genres: "drama,fantasy,science-fiction,action,adventure",
  },
  "2020-12-18T19:43:50Z": {
    type: "show",
    title: "The Mandalorian",
    year: "2019",
    trakt_rating: "8.58423",
    trakt_id: "137178",
    imdb_id: "tt8111088",
    tmdb_id: "82856",
    tvdb_id: "361753",
    url: "https://trakt.tv/shows/the-mandalorian",
    released: "2019-11-12",
    runtime: "40",
    episode_count: "16",
    genres: "fantasy,science-fiction,action,adventure,drama",
  },
  "2020-12-12T02:38:18Z": {
    type: "show",
    title: "The Tudors",
    year: "2007",
    trakt_rating: "7.99536",
    trakt_id: "2922",
    imdb_id: "tt0758790",
    tmdb_id: "2942",
    tvdb_id: "79925",
    url: "https://trakt.tv/shows/the-tudors",
    released: "2007-04-02",
    runtime: "55",
    episode_count: "38",
    genres: "drama,romance",
  },
  "2020-11-30T13:51:03Z": {
    type: "show",
    title: "Fargo",
    year: "2014",
    trakt_rating: "8.69661",
    trakt_id: "60203",
    imdb_id: "tt2802850",
    tmdb_id: "60622",
    tvdb_id: "269613",
    url: "https://trakt.tv/shows/fargo",
    released: "2014-04-16",
    runtime: "50",
    episode_count: "41",
    genres: "drama,crime",
  },
  "2020-11-30T03:32:44Z": {
    type: "show",
    title: "The Undoing",
    year: "2020",
    trakt_rating: "7.2981",
    trakt_id: "152473",
    imdb_id: "tt8134470",
    tmdb_id: "83851",
    tvdb_id: "369307",
    url: "https://trakt.tv/shows/the-undoing",
    released: "2020-10-26",
    runtime: "60",
    episode_count: "6",
    genres: "drama,crime,mystery",
  },
  "2020-11-09T15:21:40Z": {
    type: "show",
    title: "Our Cartoon President",
    year: "2018",
    trakt_rating: "5.70513",
    trakt_id: "126187",
    imdb_id: "tt7689460",
    tmdb_id: "75804",
    tvdb_id: "339467",
    url: "https://trakt.tv/shows/our-cartoon-president",
    released: "2018-02-12",
    runtime: "30",
    episode_count: "45",
    genres: "animation,comedy",
  },
  "2020-11-08T02:28:36Z": {
    type: "show",
    title: "Brave New World",
    year: "2020",
    trakt_rating: "7.06132",
    trakt_id: "144155",
    imdb_id: "tt9814116",
    tmdb_id: "93289",
    tvdb_id: "359606",
    url: "https://trakt.tv/shows/brave-new-world-2020",
    released: "2020-07-14",
    runtime: "48",
    episode_count: "9",
    genres: "fantasy,science-fiction,drama",
  },
  "2020-10-30T00:14:55Z": {
    type: "show",
    title: "Treadstone",
    year: "2019",
    trakt_rating: "7.07201",
    trakt_id: "149713",
    imdb_id: "tt8289480",
    tmdb_id: "83949",
    tvdb_id: "366090",
    url: "https://trakt.tv/shows/treadstone",
    released: "2019-10-16",
    runtime: "43",
    episode_count: "10",
    genres: "action,adventure",
  },
  "2020-10-23T00:57:31Z": {
    type: "show",
    title: "Ratched",
    year: "2020",
    trakt_rating: "7.41437",
    trakt_id: "158029",
    imdb_id: "tt7423538",
    tmdb_id: "81354",
    tvdb_id: "364870",
    url: "https://trakt.tv/shows/ratched",
    released: "2020-09-18",
    runtime: "50",
    episode_count: "8",
    genres: "drama,crime,mystery,thriller",
  },
  "2020-10-19T02:25:18Z": {
    type: "show",
    title: "Lovecraft Country",
    year: "2020",
    trakt_rating: "7.22839",
    trakt_id: "142592",
    imdb_id: "tt6905686",
    tmdb_id: "82816",
    tvdb_id: "357864",
    url: "https://trakt.tv/shows/lovecraft-country",
    released: "2020-08-17",
    runtime: "60",
    episode_count: "10",
    genres: "drama,mystery,fantasy,science-fiction,thriller",
  },
  "2020-10-12T02:14:50Z": {
    type: "show",
    title: "The Haunting of Bly Manor",
    year: "2020",
    trakt_rating: "7.57981",
    trakt_id: "169891",
    imdb_id: "tt10970552",
    tmdb_id: "109958",
    tvdb_id: "",
    url: "https://trakt.tv/shows/the-haunting-of-bly-manor",
    released: "2020-10-09",
    runtime: "54",
    episode_count: "9",
    genres: "mystery,drama",
  },
  "2020-10-08T05:54:13Z": {
    type: "show",
    title: "Metalocalypse",
    year: "2006",
    trakt_rating: "8.14876",
    trakt_id: "651",
    imdb_id: "tt0839188",
    tmdb_id: "653",
    tvdb_id: "79563",
    url: "https://trakt.tv/shows/metalocalypse",
    released: "2006-08-06",
    runtime: "11",
    episode_count: "62",
    genres: "animation,comedy",
  },
  "2020-09-02T01:39:36Z": {
    type: "show",
    title: "NOS4A2",
    year: "2019",
    trakt_rating: "6.76108",
    trakt_id: "144182",
    imdb_id: "tt5194792",
    tmdb_id: "85273",
    tvdb_id: "359637",
    url: "https://trakt.tv/shows/nos4a2",
    released: "2019-06-03",
    runtime: "43",
    episode_count: "20",
    genres: "drama,fantasy,science-fiction",
  },
  "2020-08-24T02:35:29Z": {
    type: "show",
    title: "Unidentified: Inside America's UFO Investigation",
    year: "2019",
    trakt_rating: "7.15217",
    trakt_id: "145840",
    imdb_id: "tt10016814",
    tmdb_id: "87983",
    tvdb_id: "361639",
    url: "https://trakt.tv/shows/unidentified-inside-america-s-ufo-investigation",
    released: "2019-06-01",
    runtime: "30",
    episode_count: "14",
    genres: "documentary",
  },
  "2020-07-17T20:19:35Z": {
    type: "show",
    title: "Devs",
    year: "2020",
    trakt_rating: "7.553",
    trakt_id: "147971",
    imdb_id: "tt8134186",
    tmdb_id: "81349",
    tvdb_id: "364149",
    url: "https://trakt.tv/shows/devs",
    released: "2020-03-05",
    runtime: "52",
    episode_count: "8",
    genres: "drama,fantasy,science-fiction,mystery,thriller",
  },
  "2020-06-29T03:01:00Z": {
    type: "show",
    title: "Dark",
    year: "2017",
    trakt_rating: "8.64307",
    trakt_id: "123775",
    imdb_id: "tt5753856",
    tmdb_id: "70523",
    tvdb_id: "334824",
    url: "https://trakt.tv/shows/dark",
    released: "2017-12-01",
    runtime: "53",
    episode_count: "26",
    genres: "drama,mystery,crime,fantasy,science-fiction",
  },
  "2020-06-09T01:48:15Z": {
    type: "show",
    title: "Transformers: Cyberverse",
    year: "2018",
    trakt_rating: "6.0",
    trakt_id: "135705",
    imdb_id: "tt8891990",
    tmdb_id: "83002",
    tvdb_id: "352228",
    url: "https://trakt.tv/shows/transformers-cyberverse",
    released: "2018-09-01",
    runtime: "10",
    episode_count: "44",
    genres: "animation,action,adventure",
  },
  "2020-05-20T13:32:12Z": {
    type: "show",
    title: "The Plot Against America",
    year: "2020",
    trakt_rating: "7.10394",
    trakt_id: "152956",
    imdb_id: "tt9308346",
    tmdb_id: "83870",
    tvdb_id: "367559",
    url: "https://trakt.tv/shows/the-plot-against-america",
    released: "2020-03-17",
    runtime: "61",
    episode_count: "6",
    genres: "drama,fantasy,science-fiction",
  },
  "2020-05-12T17:56:19Z": {
    type: "show",
    title: "Cardinal",
    year: "2017",
    trakt_rating: "7.59699",
    trakt_id: "113928",
    imdb_id: "tt5583512",
    tmdb_id: "67743",
    tvdb_id: "321231",
    url: "https://trakt.tv/shows/cardinal",
    released: "2017-01-26",
    runtime: "43",
    episode_count: "24",
    genres: "drama,crime,thriller",
  },
  "2020-04-27T01:34:19Z": {
    type: "show",
    title: "Homeland",
    year: "2011",
    trakt_rating: "8.31766",
    trakt_id: "1398",
    imdb_id: "tt1796960",
    tmdb_id: "1407",
    tvdb_id: "247897",
    url: "https://trakt.tv/shows/homeland",
    released: "2011-10-03",
    runtime: "45",
    episode_count: "96",
    genres: "drama,action,crime,adventure,war,thriller",
  },
  "2020-04-23T16:35:34Z": {
    type: "show",
    title: "V",
    year: "2009",
    trakt_rating: "7.06681",
    trakt_id: "21394",
    imdb_id: "tt1307824",
    tmdb_id: "21494",
    tvdb_id: "94971",
    url: "https://trakt.tv/shows/v-2009",
    released: "2009-11-04",
    runtime: "42",
    episode_count: "22",
    genres: "drama,fantasy,science-fiction",
  },
  "2020-04-14T03:07:32Z": {
    type: "show",
    title: "Tiger King: Murder, Mayhem and Madness",
    year: "2020",
    trakt_rating: "7.11666",
    trakt_id: "158420",
    imdb_id: "tt11823076",
    tmdb_id: "100698",
    tvdb_id: "377269",
    url: "https://trakt.tv/shows/tiger-king-murder-mayhem-and-madness",
    released: "2020-03-20",
    runtime: "43",
    episode_count: "8",
    genres: "documentary,crime",
  },
  "2020-03-25T11:59:30Z": {
    type: "show",
    title: "Project Blue Book",
    year: "2019",
    trakt_rating: "7.58824",
    trakt_id: "134197",
    imdb_id: "tt6632666",
    tmdb_id: "79649",
    tvdb_id: "350401",
    url: "https://trakt.tv/shows/project-blue-book",
    released: "2019-01-09",
    runtime: "44",
    episode_count: "20",
    genres: "fantasy,science-fiction,mystery,drama",
  },
  "2020-03-19T11:43:38Z": {
    type: "show",
    title: "Seinfeld",
    year: "1989",
    trakt_rating: "8.66664",
    trakt_id: "1391",
    imdb_id: "tt0098904",
    tmdb_id: "1400",
    tvdb_id: "79169",
    url: "https://trakt.tv/shows/seinfeld",
    released: "1989-07-06",
    runtime: "22",
    episode_count: "180",
    genres: "comedy",
  },
  "2020-03-10T05:23:54Z": {
    type: "show",
    title: "The Outsider",
    year: "2020",
    trakt_rating: "7.458",
    trakt_id: "154221",
    imdb_id: "tt8550800",
    tmdb_id: "84661",
    tvdb_id: "365480",
    url: "https://trakt.tv/shows/the-outsider-2020",
    released: "2020-01-13",
    runtime: "60",
    episode_count: "10",
    genres: "crime,mystery,drama",
  },
  "2020-02-04T07:10:58Z": {
    type: "show",
    title: "The Young Pope",
    year: "2016",
    trakt_rating: "8.05965",
    trakt_id: "108927",
    imdb_id: "tt3655448",
    tmdb_id: "62699",
    tvdb_id: "313969",
    url: "https://trakt.tv/shows/the-young-pope",
    released: "2016-10-21",
    runtime: "55",
    episode_count: "10",
    genres: "drama",
  },
  "2020-01-19T13:53:10Z": {
    type: "show",
    title: "Ray Donovan",
    year: "2013",
    trakt_rating: "8.14348",
    trakt_id: "1414",
    imdb_id: "tt2249007",
    tmdb_id: "1423",
    tvdb_id: "259866",
    url: "https://trakt.tv/shows/ray-donovan",
    released: "2013-07-01",
    runtime: "50",
    episode_count: "82",
    genres: "drama",
  },
  "2019-12-24T07:45:45Z": {
    type: "show",
    title: "Transformers: Rescue Bots Academy",
    year: "2018",
    trakt_rating: "4.5",
    trakt_id: "141519",
    imdb_id: "tt9557812",
    tmdb_id: "85521",
    tvdb_id: "356622",
    url: "https://trakt.tv/shows/transformers-rescue-bots-academy",
    released: "2018-12-08",
    runtime: "15",
    episode_count: "52",
    genres: "action,adventure,animation,comedy,family,fantasy,science-fiction",
  },
  "2019-12-23T06:09:29Z": {
    type: "show",
    title: "Mr. Robot",
    year: "2015",
    trakt_rating: "8.56111",
    trakt_id: "93720",
    imdb_id: "tt4158110",
    tmdb_id: "62560",
    tvdb_id: "289590",
    url: "https://trakt.tv/shows/mr-robot",
    released: "2015-06-25",
    runtime: "43",
    episode_count: "45",
    genres: "drama,crime,thriller",
  },
  "2019-12-16T04:41:46Z": {
    type: "show",
    title: "Watchmen",
    year: "2019",
    trakt_rating: "8.12327",
    trakt_id: "145026",
    imdb_id: "tt7049682",
    tmdb_id: "79788",
    tvdb_id: "360733",
    url: "https://trakt.tv/shows/watchmen",
    released: "2019-10-21",
    runtime: "60",
    episode_count: "9",
    genres: "drama,crime,action,adventure,fantasy,science-fiction,superhero",
  },
  "2019-12-11T09:57:38Z": {
    type: "show",
    title: "Castle Rock",
    year: "2018",
    trakt_rating: "7.45989",
    trakt_id: "123489",
    imdb_id: "tt6548228",
    tmdb_id: "71116",
    tvdb_id: "331600",
    url: "https://trakt.tv/shows/castle-rock",
    released: "2018-07-25",
    runtime: "60",
    episode_count: "20",
    genres: "mystery,drama,horror",
  },
  "2019-12-09T04:56:30Z": {
    type: "show",
    title: "Silicon Valley",
    year: "2014",
    trakt_rating: "8.438",
    trakt_id: "60157",
    imdb_id: "tt2575988",
    tmdb_id: "60573",
    tvdb_id: "277165",
    url: "https://trakt.tv/shows/silicon-valley",
    released: "2014-04-07",
    runtime: "28",
    episode_count: "53",
    genres: "comedy",
  },
  "2019-11-16T00:46:06Z": {
    type: "show",
    title: "The Man in the High Castle",
    year: "2015",
    trakt_rating: "7.87946",
    trakt_id: "68319",
    imdb_id: "tt1740299",
    tmdb_id: "62017",
    tvdb_id: "295829",
    url: "https://trakt.tv/shows/the-man-in-the-high-castle",
    released: "2015-01-15",
    runtime: "60",
    episode_count: "40",
    genres: "drama,fantasy,science-fiction",
  },
  "2019-11-14T03:06:11Z": {
    type: "show",
    title: "Mr. Mercedes",
    year: "2017",
    trakt_rating: "7.65225",
    trakt_id: "118343",
    imdb_id: "tt4354880",
    tmdb_id: "70485",
    tvdb_id: "327109",
    url: "https://trakt.tv/shows/mr-mercedes",
    released: "2017-08-10",
    runtime: "55",
    episode_count: "30",
    genres: "drama,mystery,fantasy,science-fiction",
  },
  "2019-11-04T04:24:04Z": {
    type: "show",
    title: "The Affair",
    year: "2014",
    trakt_rating: "7.7223",
    trakt_id: "71022",
    imdb_id: "tt2699110",
    tmdb_id: "61463",
    tvdb_id: "270439",
    url: "https://trakt.tv/shows/the-affair",
    released: "2014-10-13",
    runtime: "50",
    episode_count: "53",
    genres: "drama",
  },
  "2019-11-02T02:10:03Z": {
    type: "show",
    title: "World Without End",
    year: "2012",
    trakt_rating: "7.13747",
    trakt_id: "40020",
    imdb_id: "tt1878805",
    tmdb_id: "40204",
    tvdb_id: "261656",
    url: "https://trakt.tv/shows/world-without-end",
    released: "2012-09-04",
    runtime: "45",
    episode_count: "8",
    genres: "drama",
  },
  "2019-11-02T01:29:54Z": {
    type: "show",
    title: "The Pillars of the Earth",
    year: "2010",
    trakt_rating: "7.84774",
    trakt_id: "33089",
    imdb_id: "tt1453159",
    tmdb_id: "33234",
    tvdb_id: "159431",
    url: "https://trakt.tv/shows/the-pillars-of-the-earth",
    released: "2010-07-24",
    runtime: "54",
    episode_count: "8",
    genres: "drama,thriller",
  },
  "2019-11-01T23:34:51Z": {
    type: "show",
    title: "Tom Clancy's Jack Ryan",
    year: "2018",
    trakt_rating: "7.93547",
    trakt_id: "124381",
    imdb_id: "tt5057054",
    tmdb_id: "73375",
    tvdb_id: "336261",
    url: "https://trakt.tv/shows/tom-clancy-s-jack-ryan",
    released: "2018-08-30",
    runtime: "60",
    episode_count: "16",
    genres: "action,drama,adventure,war,thriller",
  },
  "2019-10-14T06:38:31Z": {
    type: "show",
    title: "The Terror",
    year: "2018",
    trakt_rating: "7.55985",
    trakt_id: "115673",
    imdb_id: "tt2708480",
    tmdb_id: "75191",
    tvdb_id: "322191",
    url: "https://trakt.tv/shows/the-terror",
    released: "2018-03-27",
    runtime: "44",
    episode_count: "20",
    genres: "drama,mystery,fantasy,science-fiction",
  },
  "2019-09-30T12:06:48Z": {
    type: "show",
    title: "Preacher",
    year: "2016",
    trakt_rating: "7.77839",
    trakt_id: "102034",
    imdb_id: "tt5016504",
    tmdb_id: "64230",
    tvdb_id: "300472",
    url: "https://trakt.tv/shows/preacher",
    released: "2016-05-23",
    runtime: "45",
    episode_count: "43",
    genres: "fantasy,science-fiction,drama,mystery",
  },
  "2019-08-16T23:03:46Z": {
    type: "show",
    title: "Mindhunter",
    year: "2017",
    trakt_rating: "8.44747",
    trakt_id: "116965",
    imdb_id: "tt5290382",
    tmdb_id: "67744",
    tvdb_id: "328708",
    url: "https://trakt.tv/shows/mindhunter",
    released: "2017-10-13",
    runtime: "50",
    episode_count: "19",
    genres: "drama,crime,thriller",
  },
  "2019-08-10T01:24:53Z": {
    type: "show",
    title: "GLOW",
    year: "2017",
    trakt_rating: "7.71316",
    trakt_id: "116959",
    imdb_id: "tt5770786",
    tmdb_id: "70573",
    tvdb_id: "324835",
    url: "https://trakt.tv/shows/glow",
    released: "2017-06-23",
    runtime: "30",
    episode_count: "30",
    genres: "comedy,drama",
  },
  "2019-07-22T07:06:26Z": {
    type: "show",
    title: "Big Little Lies",
    year: "2017",
    trakt_rating: "8.32236",
    trakt_id: "104648",
    imdb_id: "tt3920596",
    tmdb_id: "66292",
    tvdb_id: "305719",
    url: "https://trakt.tv/shows/big-little-lies",
    released: "2017-02-20",
    runtime: "50",
    episode_count: "14",
    genres: "drama",
  },
  "2019-06-14T18:25:36Z": {
    type: "show",
    title: "Marvel's Jessica Jones",
    year: "2015",
    trakt_rating: "7.8802",
    trakt_id: "79385",
    imdb_id: "tt2357547",
    tmdb_id: "38472",
    tvdb_id: "284190",
    url: "https://trakt.tv/shows/marvel-s-jessica-jones",
    released: "2015-11-20",
    runtime: "52",
    episode_count: "39",
    genres: "drama,fantasy,science-fiction,superhero",
  },
  "2019-06-14T01:18:11Z": {
    type: "show",
    title: "Happy!",
    year: "2017",
    trakt_rating: "7.83972",
    trakt_id: "118962",
    imdb_id: "tt2452242",
    tmdb_id: "72339",
    tvdb_id: "328361",
    url: "https://trakt.tv/shows/happy-2017",
    released: "2017-12-07",
    runtime: "42",
    episode_count: "18",
    genres: "fantasy,science-fiction,comedy,action,adventure",
  },
  "2019-06-09T23:37:06Z": {
    type: "show",
    title: "Black Mirror",
    year: "2011",
    trakt_rating: "8.63859",
    trakt_id: "41793",
    imdb_id: "tt2085059",
    tmdb_id: "42009",
    tvdb_id: "253463",
    url: "https://trakt.tv/shows/black-mirror",
    released: "2011-12-04",
    runtime: "60",
    episode_count: "21",
    genres: "drama,fantasy,science-fiction,mystery",
  },
  "2019-06-04T03:54:14Z": {
    type: "show",
    title: "Chernobyl",
    year: "2019",
    trakt_rating: "9.15432",
    trakt_id: "145186",
    imdb_id: "tt7366338",
    tmdb_id: "87108",
    tvdb_id: "360893",
    url: "https://trakt.tv/shows/chernobyl",
    released: "2019-05-07",
    runtime: "60",
    episode_count: "5",
    genres: "drama",
  },
  "2019-06-01T01:51:08Z": {
    type: "show",
    title: "Deadwood",
    year: "2004",
    trakt_rating: "8.72981",
    trakt_id: "1397",
    imdb_id: "tt0348914",
    tmdb_id: "1406",
    tvdb_id: "72023",
    url: "https://trakt.tv/shows/deadwood",
    released: "2004-03-22",
    runtime: "55",
    episode_count: "37",
    genres: "western,crime,drama",
  },
  "2019-05-31T00:26:31Z": {
    type: "show",
    title: "The Hot Zone",
    year: "2019",
    trakt_rating: "7.3633",
    trakt_id: "144004",
    imdb_id: "tt4131818",
    tmdb_id: "86799",
    tvdb_id: "359477",
    url: "https://trakt.tv/shows/the-hot-zone",
    released: "2019-05-28",
    runtime: "50",
    episode_count: "6",
    genres: "drama",
  },
  "2019-05-20T02:28:50Z": {
    type: "show",
    title: "Game of Thrones",
    year: "2011",
    trakt_rating: "9.02762",
    trakt_id: "1390",
    imdb_id: "tt0944947",
    tmdb_id: "1399",
    tvdb_id: "121361",
    url: "https://trakt.tv/shows/game-of-thrones",
    released: "2011-04-18",
    runtime: "60",
    episode_count: "73",
    genres: "drama,fantasy,science-fiction,action,adventure",
  },
  "2019-04-03T05:06:15Z": {
    type: "show",
    title: "This Is Us",
    year: "2016",
    trakt_rating: "8.55135",
    trakt_id: "107690",
    imdb_id: "tt5555260",
    tmdb_id: "67136",
    tvdb_id: "311714",
    url: "https://trakt.tv/shows/this-is-us",
    released: "2016-09-21",
    runtime: "43",
    episode_count: "54",
    genres: "drama,comedy,romance",
  },
  "2019-02-25T03:03:55Z": {
    type: "show",
    title: "True Detective",
    year: "2014",
    trakt_rating: "8.63393",
    trakt_id: "46375",
    imdb_id: "tt2356777",
    tmdb_id: "46648",
    tvdb_id: "270633",
    url: "https://trakt.tv/shows/true-detective",
    released: "2014-01-13",
    runtime: "60",
    episode_count: "24",
    genres: "drama",
  },
  "2019-01-06T01:32:01Z": {
    type: "show",
    title: "Perfume",
    year: "2018",
    trakt_rating: "6.89506",
    trakt_id: "135636",
    imdb_id: "tt6811236",
    tmdb_id: "84052",
    tvdb_id: "352150",
    url: "https://trakt.tv/shows/perfume-2018",
    released: "2018-11-14",
    runtime: "55",
    episode_count: "6",
    genres: "crime",
  },
  "2019-01-05T05:41:53Z": {
    type: "show",
    title: "Luther",
    year: "2010",
    trakt_rating: "8.48595",
    trakt_id: "1417",
    imdb_id: "tt1474684",
    tmdb_id: "1426",
    tvdb_id: "159591",
    url: "https://trakt.tv/shows/luther",
    released: "2010-05-04",
    runtime: "60",
    episode_count: "20",
    genres: "drama,crime,mystery",
  },
  "2018-12-30T02:01:23Z": {
    type: "show",
    title: "Z Nation",
    year: "2014",
    trakt_rating: "6.98697",
    trakt_id: "77694",
    imdb_id: "tt3843168",
    tmdb_id: "61345",
    tvdb_id: "280494",
    url: "https://trakt.tv/shows/z-nation",
    released: "2014-09-13",
    runtime: "44",
    episode_count: "68",
    genres: "action,drama,adventure,mystery",
  },
  "2018-12-26T00:33:57Z": {
    type: "show",
    title: "Patriot",
    year: "2015",
    trakt_rating: "8.23201",
    trakt_id: "116184",
    imdb_id: "tt4687882",
    tmdb_id: "64396",
    tvdb_id: "323267",
    url: "https://trakt.tv/shows/patriot-2015",
    released: "2015-11-03",
    runtime: "50",
    episode_count: "18",
    genres: "comedy,drama",
  },
  "2018-12-06T02:27:43Z": {
    type: "show",
    title: "The Larry Sanders Show",
    year: "1992",
    trakt_rating: "8.04096",
    trakt_id: "1902",
    imdb_id: "tt0103466",
    tmdb_id: "1915",
    tvdb_id: "73007",
    url: "https://trakt.tv/shows/the-larry-sanders-show",
    released: "1992-08-15",
    runtime: "30",
    episode_count: "90",
    genres: "comedy",
  },
  "2018-12-01T03:05:48Z": {
    type: "show",
    title: "LEGO Jurassic World: The Secret Exhibit",
    year: "",
    trakt_rating: "5.88889",
    trakt_id: "140458",
    imdb_id: "tt9322614",
    tmdb_id: "",
    tvdb_id: "356179",
    url: "https://trakt.tv/shows/lego-jurassic-world-the-secret-exhibit",
    released: "",
    runtime: "22",
    episode_count: "2",
    genres: "adventure,animation,children",
  },
  "2018-11-15T02:04:18Z": {
    type: "show",
    title: "The Last Ship",
    year: "2014",
    trakt_rating: "7.59098",
    trakt_id: "60360",
    imdb_id: "tt2402207",
    tmdb_id: "60802",
    tvdb_id: "269533",
    url: "https://trakt.tv/shows/the-last-ship",
    released: "2014-06-23",
    runtime: "41",
    episode_count: "56",
    genres: "action,drama,science-fiction,adventure,fantasy",
  },
  "2018-11-11T09:47:27Z": {
    type: "show",
    title: "Band of Brothers",
    year: "2001",
    trakt_rating: "9.24291",
    trakt_id: "4589",
    imdb_id: "tt0185906",
    tmdb_id: "4613",
    tvdb_id: "74205",
    url: "https://trakt.tv/shows/band-of-brothers",
    released: "2001-09-10",
    runtime: "60",
    episode_count: "10",
    genres: "drama,war",
  },
  "2018-10-23T02:47:14Z": {
    type: "show",
    title: "Six Feet Under",
    year: "2001",
    trakt_rating: "8.73877",
    trakt_id: "1267",
    imdb_id: "tt0248654",
    tmdb_id: "1274",
    tvdb_id: "75450",
    url: "https://trakt.tv/shows/six-feet-under",
    released: "2001-06-04",
    runtime: "50",
    episode_count: "63",
    genres: "drama",
  },
  "2018-10-22T01:49:39Z": {
    type: "show",
    title: "Making a Murderer",
    year: "2015",
    trakt_rating: "8.16167",
    trakt_id: "103423",
    imdb_id: "tt5189670",
    tmdb_id: "64439",
    tvdb_id: "303210",
    url: "https://trakt.tv/shows/making-a-murderer",
    released: "2015-12-18",
    runtime: "60",
    episode_count: "20",
    genres: "documentary,crime",
  },
  "2018-10-19T21:39:28Z": {
    type: "show",
    title: "The Haunting of Hill House",
    year: "2018",
    trakt_rating: "8.37204",
    trakt_id: "134526",
    imdb_id: "tt6763664",
    tmdb_id: "72844",
    tvdb_id: "345246",
    url: "https://trakt.tv/shows/the-haunting-of-hill-house",
    released: "2018-10-12",
    runtime: "50",
    episode_count: "10",
    genres: "mystery,drama,horror,thriller",
  },
  "2018-10-16T01:20:56Z": {
    type: "show",
    title: "El Chapo",
    year: "2017",
    trakt_rating: "7.62733",
    trakt_id: "117802",
    imdb_id: "tt6692188",
    tmdb_id: "71411",
    tvdb_id: "326027",
    url: "https://trakt.tv/shows/el-chapo",
    released: "2017-04-24",
    runtime: "55",
    episode_count: "34",
    genres: "drama",
  },
  "2018-09-26T04:15:58Z": {
    type: "show",
    title: "The Staircase",
    year: "2004",
    trakt_rating: "7.65064",
    trakt_id: "21323",
    imdb_id: "tt0388644",
    tmdb_id: "21421",
    tvdb_id: "92011",
    url: "https://trakt.tv/shows/the-staircase",
    released: "2004-10-07",
    runtime: "46",
    episode_count: "13",
    genres: "documentary,crime",
  },
  "2018-09-14T10:20:23Z": {
    type: "show",
    title: "Shooter",
    year: "2016",
    trakt_rating: "7.61663",
    trakt_id: "107790",
    imdb_id: "tt4181172",
    tmdb_id: "66857",
    tvdb_id: "311900",
    url: "https://trakt.tv/shows/shooter",
    released: "2016-11-16",
    runtime: "42",
    episode_count: "31",
    genres: "action,adventure,war,drama",
  },
  "2018-08-05T02:32:49Z": {
    type: "show",
    title: "Colony",
    year: "2016",
    trakt_rating: "7.4196",
    trakt_id: "95966",
    imdb_id: "tt4209256",
    tmdb_id: "62858",
    tvdb_id: "284210",
    url: "https://trakt.tv/shows/colony",
    released: "2016-01-15",
    runtime: "43",
    episode_count: "36",
    genres: "drama,science-fiction,fantasy",
  },
  "2018-07-05T01:30:45Z": {
    type: "show",
    title: "Transformers: Prime Wars Trilogy",
    year: "2016",
    trakt_rating: "5.0",
    trakt_id: "109116",
    imdb_id: "tt5831646",
    tmdb_id: "67316",
    tvdb_id: "314265",
    url: "https://trakt.tv/shows/transformers-prime-wars-trilogy",
    released: "2016-08-02",
    runtime: "10",
    episode_count: "33",
    genres: "animation",
  },
  "2018-06-23T12:21:33Z": {
    type: "show",
    title: "Marvel's Luke Cage",
    year: "2016",
    trakt_rating: "7.20732",
    trakt_id: "79382",
    imdb_id: "tt3322314",
    tmdb_id: "62126",
    tvdb_id: "304219",
    url: "https://trakt.tv/shows/marvel-s-luke-cage",
    released: "2016-09-30",
    runtime: "46",
    episode_count: "26",
    genres: "drama,fantasy,science-fiction,superhero,action,adventure,crime",
  },
  "2018-06-11T05:40:27Z": {
    type: "show",
    title: "The Crossing",
    year: "2018",
    trakt_rating: "6.86615",
    trakt_id: "119094",
    imdb_id: "tt6461824",
    tmdb_id: "71383",
    tvdb_id: "328631",
    url: "https://trakt.tv/shows/the-crossing",
    released: "2018-04-03",
    runtime: "44",
    episode_count: "11",
    genres: "fantasy,science-fiction,action,adventure",
  },
  "2018-06-05T10:09:55Z": {
    type: "show",
    title: "The Americans",
    year: "2013",
    trakt_rating: "8.36023",
    trakt_id: "46263",
    imdb_id: "tt2149175",
    tmdb_id: "46533",
    tvdb_id: "261690",
    url: "https://trakt.tv/shows/the-americans-2013",
    released: "2013-01-31",
    runtime: "45",
    episode_count: "75",
    genres: "drama,crime",
  },
  "2018-06-04T02:57:27Z": {
    type: "show",
    title: "Caprica",
    year: "2010",
    trakt_rating: "7.48377",
    trakt_id: "872",
    imdb_id: "tt0799862",
    tmdb_id: "877",
    tvdb_id: "85040",
    url: "https://trakt.tv/shows/caprica",
    released: "2010-01-23",
    runtime: "41",
    episode_count: "18",
    genres: "drama,fantasy,science-fiction",
  },
  "2018-06-04T00:32:49Z": {
    type: "show",
    title: "Battlestar Galactica: Blood & Chrome",
    year: "2012",
    trakt_rating: "7.78302",
    trakt_id: "33095",
    imdb_id: "tt1704292",
    tmdb_id: "33240",
    tvdb_id: "204781",
    url: "https://trakt.tv/shows/battlestar-galactica-blood-chrome",
    released: "2012-11-10",
    runtime: "94",
    episode_count: "10",
    genres: "fantasy,science-fiction",
  },
  "2018-06-01T01:39:17Z": {
    type: "show",
    title: "Arrested Development",
    year: "2003",
    trakt_rating: "8.55451",
    trakt_id: "4565",
    imdb_id: "tt0367279",
    tmdb_id: "4589",
    tvdb_id: "72173",
    url: "https://trakt.tv/shows/arrested-development",
    released: "2003-11-02",
    runtime: "22",
    episode_count: "76",
    genres: "comedy",
  },
  "2018-05-01T10:08:09Z": {
    type: "show",
    title: "Under the Dome",
    year: "2013",
    trakt_rating: "6.73047",
    trakt_id: "46073",
    imdb_id: "tt1553656",
    tmdb_id: "46331",
    tvdb_id: "264492",
    url: "https://trakt.tv/shows/under-the-dome",
    released: "2013-06-25",
    runtime: "42",
    episode_count: "39",
    genres: "drama,mystery,fantasy,science-fiction",
  },
  "2018-04-30T05:45:45Z": {
    type: "show",
    title: "That Mitchell and Webb Look",
    year: "2006",
    trakt_rating: "8.2",
    trakt_id: "2833",
    imdb_id: "tt0499410",
    tmdb_id: "2852",
    tvdb_id: "80165",
    url: "https://trakt.tv/shows/that-mitchell-and-webb-look",
    released: "2006-09-14",
    runtime: "30",
    episode_count: "24",
    genres: "comedy",
  },
  "2018-04-30T05:23:46Z": {
    type: "show",
    title: "Ash vs Evil Dead",
    year: "2015",
    trakt_rating: "8.1242",
    trakt_id: "99460",
    imdb_id: "tt4189022",
    tmdb_id: "62264",
    tvdb_id: "296295",
    url: "https://trakt.tv/shows/ash-vs-evil-dead",
    released: "2015-10-31",
    runtime: "30",
    episode_count: "30",
    genres: "comedy,horror,fantasy,action,adventure,science-fiction",
  },
  "2018-04-27T18:52:59Z": {
    type: "show",
    title: "Misfits",
    year: "2009",
    trakt_rating: "7.98281",
    trakt_id: "31159",
    imdb_id: "tt1548850",
    tmdb_id: "31295",
    tvdb_id: "124051",
    url: "https://trakt.tv/shows/misfits",
    released: "2009-11-12",
    runtime: "45",
    episode_count: "37",
    genres: "comedy,drama,fantasy,science-fiction,superhero",
  },
  "2018-04-27T18:32:33Z": {
    type: "show",
    title: "Boss",
    year: "2011",
    trakt_rating: "8.01789",
    trakt_id: "38759",
    imdb_id: "tt1833285",
    tmdb_id: "38922",
    tvdb_id: "249361",
    url: "https://trakt.tv/shows/boss",
    released: "2011-10-22",
    runtime: "55",
    episode_count: "18",
    genres: "drama,war",
  },
  "2018-04-27T15:09:43Z": {
    type: "show",
    title: "Call Me Fitz",
    year: "2010",
    trakt_rating: "7.1875",
    trakt_id: "32914",
    imdb_id: "tt1558182",
    tmdb_id: "33059",
    tvdb_id: "191101",
    url: "https://trakt.tv/shows/call-me-fitz",
    released: "2010-09-20",
    runtime: "30",
    episode_count: "48",
    genres: "comedy",
  },
  "2018-04-27T13:48:17Z": {
    type: "show",
    title: "Utopia",
    year: "2013",
    trakt_rating: "8.49666",
    trakt_id: "46241",
    imdb_id: "tt2384811",
    tmdb_id: "46511",
    tvdb_id: "264991",
    url: "https://trakt.tv/shows/utopia",
    released: "2013-01-15",
    runtime: "50",
    episode_count: "12",
    genres: "drama,mystery,action,adventure,fantasy,science-fiction,comedy",
  },
  "2018-04-27T10:13:37Z": {
    type: "show",
    title: "Rebus",
    year: "2000",
    trakt_rating: "7.81579",
    trakt_id: "1160",
    imdb_id: "tt0867015",
    tmdb_id: "1165",
    tvdb_id: "72823",
    url: "https://trakt.tv/shows/rebus",
    released: "2000-04-25",
    runtime: "70",
    episode_count: "14",
    genres: "drama",
  },
  "2018-04-27T09:06:38Z": {
    type: "show",
    title: "TURN: Washington's Spies",
    year: "2014",
    trakt_rating: "7.79272",
    trakt_id: "56905",
    imdb_id: "tt2543328",
    tmdb_id: "57276",
    tvdb_id: "272135",
    url: "https://trakt.tv/shows/turn-washington-s-spies",
    released: "2014-04-07",
    runtime: "42",
    episode_count: "40",
    genres: "drama,war",
  },
  "2018-04-27T03:20:04Z": {
    type: "show",
    title: "Orphan Black",
    year: "2013",
    trakt_rating: "8.43168",
    trakt_id: "55943",
    imdb_id: "tt2234222",
    tmdb_id: "56296",
    tvdb_id: "260315",
    url: "https://trakt.tv/shows/orphan-black",
    released: "2013-03-31",
    runtime: "43",
    episode_count: "50",
    genres: "drama,fantasy,science-fiction",
  },
  "2018-04-27T00:04:44Z": {
    type: "show",
    title: "Jodi Arias: An American Murder Mystery",
    year: "2018",
    trakt_rating: "6.5",
    trakt_id: "126547",
    imdb_id: "tt7856156",
    tmdb_id: "76402",
    tvdb_id: "340204",
    url: "https://trakt.tv/shows/jodi-arias-an-american-murder-mystery",
    released: "2018-01-15",
    runtime: "42",
    episode_count: "3",
    genres: "crime,reality",
  },
  "2018-04-26T13:54:21Z": {
    type: "show",
    title: "Marco Polo",
    year: "2014",
    trakt_rating: "7.86247",
    trakt_id: "60272",
    imdb_id: "tt2189461",
    tmdb_id: "60699",
    tvdb_id: "266091",
    url: "https://trakt.tv/shows/marco-polo-2014",
    released: "2014-12-12",
    runtime: "60",
    episode_count: "20",
    genres: "drama,action,adventure",
  },
  "2018-04-26T13:31:26Z": {
    type: "show",
    title: "The Killing",
    year: "2007",
    trakt_rating: "8.47339",
    trakt_id: "32228",
    imdb_id: "tt0826760",
    tmdb_id: "32368",
    tvdb_id: "79689",
    url: "https://trakt.tv/shows/the-killing",
    released: "2007-01-07",
    runtime: "60",
    episode_count: "40",
    genres: "crime,drama,mystery",
  },
  "2018-04-26T13:31:25Z": {
    type: "show",
    title: "The Killing",
    year: "2011",
    trakt_rating: "8.26189",
    trakt_id: "34268",
    imdb_id: "tt1637727",
    tmdb_id: "34415",
    tvdb_id: "210171",
    url: "https://trakt.tv/shows/the-killing-2011",
    released: "2011-04-04",
    runtime: "42",
    episode_count: "44",
    genres: "drama,crime",
  },
  "2018-04-26T05:00:39Z": {
    type: "show",
    title: "Peep Show",
    year: "2003",
    trakt_rating: "8.65217",
    trakt_id: "812",
    imdb_id: "tt0387764",
    tmdb_id: "815",
    tvdb_id: "71656",
    url: "https://trakt.tv/shows/peep-show",
    released: "2003-09-19",
    runtime: "24",
    episode_count: "54",
    genres: "comedy",
  },
  "2018-04-23T00:05:40Z": {
    type: "show",
    title: "The Prisoner",
    year: "1967",
    trakt_rating: "8.3556",
    trakt_id: "889",
    imdb_id: "tt0061287",
    tmdb_id: "894",
    tvdb_id: "74805",
    url: "https://trakt.tv/shows/the-prisoner",
    released: "1967-09-29",
    runtime: "48",
    episode_count: "17",
    genres: "drama,fantasy,science-fiction,mystery",
  },
  "2018-04-12T00:06:13Z": {
    type: "show",
    title: "American Crime",
    year: "2015",
    trakt_rating: "7.78889",
    trakt_id: "60352",
    imdb_id: "tt3488298",
    tmdb_id: "60791",
    tvdb_id: "281613",
    url: "https://trakt.tv/shows/american-crime",
    released: "2015-03-06",
    runtime: "45",
    episode_count: "29",
    genres: "drama,crime,mystery",
  },
  "2018-04-06T02:22:01Z": {
    type: "show",
    title: "The X-Files",
    year: "1993",
    trakt_rating: "8.5951",
    trakt_id: "4063",
    imdb_id: "tt0106179",
    tmdb_id: "4087",
    tvdb_id: "77398",
    url: "https://trakt.tv/shows/the-x-files",
    released: "1993-09-11",
    runtime: "45",
    episode_count: "218",
    genres: "mystery,fantasy,science-fiction,crime",
  },
  "2018-04-02T23:37:52Z": {
    type: "show",
    title: "The Bridge",
    year: "2011",
    trakt_rating: "8.62707",
    trakt_id: "44768",
    imdb_id: "tt1733785",
    tmdb_id: "45016",
    tvdb_id: "252019",
    url: "https://trakt.tv/shows/the-bridge",
    released: "2011-09-21",
    runtime: "60",
    episode_count: "38",
    genres: "crime,mystery",
  },
  "2018-03-28T12:05:43Z": {
    type: "show",
    title: "The Path",
    year: "2016",
    trakt_rating: "7.0905",
    trakt_id: "103936",
    imdb_id: "tt4789576",
    tmdb_id: "65227",
    tvdb_id: "304196",
    url: "https://trakt.tv/shows/the-path",
    released: "2016-03-30",
    runtime: "60",
    episode_count: "36",
    genres: "drama",
  },
  "2018-03-27T15:49:20Z": {
    type: "show",
    title: "Strike Back",
    year: "2010",
    trakt_rating: "8.34116",
    trakt_id: "32433",
    imdb_id: "tt1492179",
    tmdb_id: "32573",
    tvdb_id: "148581",
    url: "https://trakt.tv/shows/strike-back",
    released: "2010-05-05",
    runtime: "45",
    episode_count: "16",
    genres: "drama,action,adventure",
  },
  "2018-03-27T15:47:48Z": {
    type: "show",
    title: "Brave New World with Stephen Hawking",
    year: "2011",
    trakt_rating: "8.73333",
    trakt_id: "42958",
    imdb_id: "tt2379721",
    tmdb_id: "43187",
    tvdb_id: "252882",
    url: "https://trakt.tv/shows/brave-new-world-with-stephen-hawking",
    released: "2011-10-17",
    runtime: "46",
    episode_count: "5",
    genres: "documentary",
  },
  "2018-03-27T15:47:34Z": {
    type: "show",
    title: "Spartacus",
    year: "2010",
    trakt_rating: "8.51104",
    trakt_id: "46038",
    imdb_id: "tt1442449",
    tmdb_id: "46296",
    tvdb_id: "129261",
    url: "https://trakt.tv/shows/spartacus",
    released: "2010-01-23",
    runtime: "53",
    episode_count: "39",
    genres: "drama",
  },
  "2018-03-27T00:57:10Z": {
    type: "show",
    title: "Monty Python's Flying Circus",
    year: "1969",
    trakt_rating: "8.81637",
    trakt_id: "846",
    imdb_id: "tt0063929",
    tmdb_id: "849",
    tvdb_id: "75853",
    url: "https://trakt.tv/shows/monty-python-s-flying-circus",
    released: "1969-10-05",
    runtime: "30",
    episode_count: "45",
    genres: "comedy",
  },
  "2018-02-17T22:34:21Z": {
    type: "show",
    title: "LEGO Legends of Chima",
    year: "2013",
    trakt_rating: "6.5",
    trakt_id: "69137",
    imdb_id: "tt2356791",
    tmdb_id: "57780",
    tvdb_id: "265393",
    url: "https://trakt.tv/shows/lego-legends-of-chima",
    released: "2013-01-17",
    runtime: "25",
    episode_count: "41",
    genres: "animation",
  },
  "2017-11-20T06:25:14Z": {
    type: "show",
    title: "Transformers: Energon",
    year: "2004",
    trakt_rating: "6.28125",
    trakt_id: "10417",
    imdb_id: "tt0388650",
    tmdb_id: "75911",
    tvdb_id: "72776",
    url: "https://trakt.tv/shows/transformers-energon",
    released: "2004-01-08",
    runtime: "25",
    episode_count: "52",
    genres: "action,adventure,anime",
  },
  "2017-11-12T21:19:46Z": {
    type: "show",
    title: "Transformers: Prime",
    year: "2010",
    trakt_rating: "7.9494",
    trakt_id: "32768",
    imdb_id: "tt1659175",
    tmdb_id: "32910",
    tvdb_id: "205901",
    url: "https://trakt.tv/shows/transformers-prime",
    released: "2010-11-26",
    runtime: "25",
    episode_count: "65",
    genres: "animation,action,adventure,children",
  },
  "2017-11-12T15:07:23Z": {
    type: "show",
    title: "Transformers: Robots In Disguise",
    year: "2015",
    trakt_rating: "6.41176",
    trakt_id: "20376",
    imdb_id: "tt3604232",
    tmdb_id: "63090",
    tvdb_id: "292171",
    url: "https://trakt.tv/shows/transformers-robots-in-disguise",
    released: "2015-02-21",
    runtime: "25",
    episode_count: "71",
    genres: "animation,children",
  },
  "2017-11-11T17:09:58Z": {
    type: "show",
    title: "Sherlock",
    year: "2010",
    trakt_rating: "8.99357",
    trakt_id: "19792",
    imdb_id: "tt1475582",
    tmdb_id: "19885",
    tvdb_id: "176941",
    url: "https://trakt.tv/shows/sherlock",
    released: "2010-07-25",
    runtime: "90",
    episode_count: "12",
    genres: "drama,crime,mystery",
  },
  "2017-10-28T12:59:24Z": {
    type: "show",
    title: "Halt and Catch Fire",
    year: "2014",
    trakt_rating: "8.34621",
    trakt_id: "59262",
    imdb_id: "tt2543312",
    tmdb_id: "59659",
    tvdb_id: "271910",
    url: "https://trakt.tv/shows/halt-and-catch-fire",
    released: "2014-06-02",
    runtime: "43",
    episode_count: "40",
    genres: "drama",
  },
  "2017-10-26T00:33:45Z": {
    type: "show",
    title: "Stephen King's It",
    year: "1990",
    trakt_rating: "7.1207",
    trakt_id: "142446",
    imdb_id: "tt0099864",
    tmdb_id: "19614",
    tvdb_id: "113981",
    url: "https://trakt.tv/shows/stephen-king-s-it",
    released: "1990-11-18",
    runtime: "90",
    episode_count: "2",
    genres: "mystery,drama",
  },
  "2017-10-03T01:09:35Z": {
    type: "show",
    title: "Narcos",
    year: "2015",
    trakt_rating: "8.60026",
    trakt_id: "94630",
    imdb_id: "tt2707408",
    tmdb_id: "63351",
    tvdb_id: "282670",
    url: "https://trakt.tv/shows/narcos",
    released: "2015-08-28",
    runtime: "57",
    episode_count: "30",
    genres: "drama,crime",
  },
  "2017-09-22T02:09:43Z": {
    type: "show",
    title: "Alpha House",
    year: "2013",
    trakt_rating: "7.3486",
    trakt_id: "60285",
    imdb_id: "tt3012160",
    tmdb_id: "60719",
    tvdb_id: "269008",
    url: "https://trakt.tv/shows/alpha-house",
    released: "2013-04-19",
    runtime: "30",
    episode_count: "21",
    genres: "comedy,drama",
  },
  "2017-09-16T01:40:33Z": {
    type: "show",
    title: "Twin Peaks",
    year: "1990",
    trakt_rating: "8.61924",
    trakt_id: "1907",
    imdb_id: "tt0098936",
    tmdb_id: "1920",
    tvdb_id: "70533",
    url: "https://trakt.tv/shows/twin-peaks",
    released: "1990-04-09",
    runtime: "46",
    episode_count: "48",
    genres: "drama,mystery",
  },
  "2017-08-24T00:06:39Z": {
    type: "show",
    title: "Chappelle's Show",
    year: "2003",
    trakt_rating: "8.85094",
    trakt_id: "711",
    imdb_id: "tt0353049",
    tmdb_id: "713",
    tvdb_id: "71862",
    url: "https://trakt.tv/shows/chappelle-s-show",
    released: "2003-01-23",
    runtime: "22",
    episode_count: "28",
    genres: "comedy",
  },
  "2017-08-13T16:04:35Z": {
    type: "show",
    title: "Training Day",
    year: "2017",
    trakt_rating: "6.43952",
    trakt_id: "107821",
    imdb_id: "tt4946972",
    tmdb_id: "68000",
    tvdb_id: "311949",
    url: "https://trakt.tv/shows/training-day",
    released: "2017-02-03",
    runtime: "42",
    episode_count: "13",
    genres: "crime,drama",
  },
  "2017-08-11T15:14:12Z": {
    type: "show",
    title: "Banshee",
    year: "2013",
    trakt_rating: "8.41313",
    trakt_id: "41522",
    imdb_id: "tt2017109",
    tmdb_id: "41727",
    tvdb_id: "259765",
    url: "https://trakt.tv/shows/banshee",
    released: "2013-01-12",
    runtime: "50",
    episode_count: "38",
    genres: "drama,crime",
  },
  "2017-08-11T14:44:09Z": {
    type: "show",
    title: "Black Sails",
    year: "2014",
    trakt_rating: "8.23344",
    trakt_id: "47374",
    imdb_id: "tt2375692",
    tmdb_id: "47665",
    tvdb_id: "262407",
    url: "https://trakt.tv/shows/black-sails",
    released: "2014-01-26",
    runtime: "60",
    episode_count: "38",
    genres: "drama,adventure,action",
  },
  "2017-08-11T02:05:22Z": {
    type: "show",
    title: "Skins",
    year: "2007",
    trakt_rating: "8.27402",
    trakt_id: "895",
    imdb_id: "tt0840196",
    tmdb_id: "900",
    tvdb_id: "79773",
    url: "https://trakt.tv/shows/skins",
    released: "2007-01-25",
    runtime: "44",
    episode_count: "9",
    genres: "comedy,drama",
  },
  "2017-08-11T01:19:58Z": {
    type: "show",
    title: "Extras",
    year: "2005",
    trakt_rating: "8.05949",
    trakt_id: "2675",
    imdb_id: "tt0445114",
    tmdb_id: "2693",
    tvdb_id: "75660",
    url: "https://trakt.tv/shows/extras",
    released: "2005-07-21",
    runtime: "30",
    episode_count: "12",
    genres: "comedy",
  },
  "2017-08-10T19:47:59Z": {
    type: "show",
    title: "The Wire",
    year: "2002",
    trakt_rating: "9.25622",
    trakt_id: "1429",
    imdb_id: "tt0306414",
    tmdb_id: "1438",
    tvdb_id: "79126",
    url: "https://trakt.tv/shows/the-wire",
    released: "2002-06-03",
    runtime: "60",
    episode_count: "60",
    genres: "drama,crime",
  },
  "2017-08-10T19:47:12Z": {
    type: "show",
    title: "Vinyl",
    year: "2016",
    trakt_rating: "7.51876",
    trakt_id: "100983",
    imdb_id: "tt3186130",
    tmdb_id: "63535",
    tvdb_id: "299400",
    url: "https://trakt.tv/shows/vinyl",
    released: "2016-02-15",
    runtime: "60",
    episode_count: "10",
    genres: "drama",
  },
  "2017-08-10T19:45:51Z": {
    type: "show",
    title: "Underbelly",
    year: "2008",
    trakt_rating: "8.09901",
    trakt_id: "13736",
    imdb_id: "tt1119176",
    tmdb_id: "13796",
    tvdb_id: "81346",
    url: "https://trakt.tv/shows/underbelly",
    released: "2008-02-13",
    runtime: "40",
    episode_count: "68",
    genres: "drama",
  },
  "2017-08-10T19:44:52Z": {
    type: "show",
    title: "Tyrant",
    year: "2014",
    trakt_rating: "7.65623",
    trakt_id: "60506",
    imdb_id: "tt2568204",
    tmdb_id: "60972",
    tvdb_id: "268094",
    url: "https://trakt.tv/shows/tyrant",
    released: "2014-06-25",
    runtime: "45",
    episode_count: "32",
    genres: "drama,war",
  },
  "2017-08-10T19:32:09Z": {
    type: "show",
    title: "Spirited",
    year: "2010",
    trakt_rating: "7.44828",
    trakt_id: "33690",
    imdb_id: "tt1524415",
    tmdb_id: "33836",
    tvdb_id: "184951",
    url: "https://trakt.tv/shows/spirited",
    released: "2010-08-25",
    runtime: "60",
    episode_count: "8",
    genres: "drama,fantasy,science-fiction",
  },
  "2017-08-10T19:30:24Z": {
    type: "show",
    title: "Sons of Anarchy",
    year: "2008",
    trakt_rating: "8.62819",
    trakt_id: "1400",
    imdb_id: "tt1124373",
    tmdb_id: "1409",
    tvdb_id: "82696",
    url: "https://trakt.tv/shows/sons-of-anarchy",
    released: "2008-09-04",
    runtime: "45",
    episode_count: "92",
    genres: "drama,crime",
  },
  "2017-08-10T19:27:30Z": {
    type: "show",
    title: "The Shield",
    year: "2002",
    trakt_rating: "8.775",
    trakt_id: "1405",
    imdb_id: "tt0286486",
    tmdb_id: "1414",
    tvdb_id: "78261",
    url: "https://trakt.tv/shows/the-shield",
    released: "2002-03-13",
    runtime: "45",
    episode_count: "88",
    genres: "drama,action,adventure,crime",
  },
  "2017-08-10T19:14:52Z": {
    type: "show",
    title: "Shameless",
    year: "2004",
    trakt_rating: "7.77762",
    trakt_id: "1893",
    imdb_id: "tt0377260",
    tmdb_id: "1906",
    tvdb_id: "78846",
    url: "https://trakt.tv/shows/shameless",
    released: "2004-01-13",
    runtime: "50",
    episode_count: "103",
    genres: "comedy,drama",
  },
  "2017-08-10T19:09:55Z": {
    type: "show",
    title: "River",
    year: "2015",
    trakt_rating: "7.71562",
    trakt_id: "102675",
    imdb_id: "tt4258440",
    tmdb_id: "64158",
    tvdb_id: "301608",
    url: "https://trakt.tv/shows/river",
    released: "2015-10-13",
    runtime: "60",
    episode_count: "6",
    genres: "crime,drama",
  },
  "2017-08-10T19:06:36Z": {
    type: "show",
    title: "Planet Earth II",
    year: "2016",
    trakt_rating: "9.1502",
    trakt_id: "112332",
    imdb_id: "tt5491994",
    tmdb_id: "68595",
    tvdb_id: "318408",
    url: "https://trakt.tv/shows/planet-earth-ii",
    released: "2016-11-06",
    runtime: "50",
    episode_count: "6",
    genres: "documentary",
  },
  "2017-08-10T19:05:00Z": {
    type: "show",
    title: "Oz",
    year: "1997",
    trakt_rating: "8.6724",
    trakt_id: "3300",
    imdb_id: "tt0118421",
    tmdb_id: "3322",
    tvdb_id: "70682",
    url: "https://trakt.tv/shows/oz",
    released: "1997-07-12",
    runtime: "55",
    episode_count: "56",
    genres: "drama,crime,thriller",
  },
  "2017-08-10T19:03:39Z": {
    type: "show",
    title: "Nighty Night",
    year: "2004",
    trakt_rating: "8.25373",
    trakt_id: "201",
    imdb_id: "tt0395404",
    tmdb_id: "202",
    tvdb_id: "78893",
    url: "https://trakt.tv/shows/nighty-night",
    released: "2004-01-06",
    runtime: "30",
    episode_count: "6",
    genres: "comedy",
  },
  "2017-08-10T19:02:59Z": {
    type: "show",
    title: "The Newsroom",
    year: "2012",
    trakt_rating: "8.54324",
    trakt_id: "15556",
    imdb_id: "tt1870479",
    tmdb_id: "15621",
    tvdb_id: "256227",
    url: "https://trakt.tv/shows/the-newsroom",
    released: "2012-06-25",
    runtime: "60",
    episode_count: "25",
    genres: "drama",
  },
  "2017-08-10T19:02:34Z": {
    type: "show",
    title: "New Girl",
    year: "2011",
    trakt_rating: "7.77508",
    trakt_id: "1411",
    imdb_id: "tt1826940",
    tmdb_id: "1420",
    tvdb_id: "248682",
    url: "https://trakt.tv/shows/new-girl",
    released: "2011-09-21",
    runtime: "22",
    episode_count: "23",
    genres: "comedy",
  },
  "2017-08-10T19:00:05Z": {
    type: "show",
    title: "Minority Report",
    year: "2015",
    trakt_rating: "6.45143",
    trakt_id: "98991",
    imdb_id: "tt4450826",
    tmdb_id: "63175",
    tvdb_id: "295680",
    url: "https://trakt.tv/shows/minority-report",
    released: "2015-09-22",
    runtime: "43",
    episode_count: "10",
    genres: "drama,crime,fantasy,science-fiction",
  },
  "2017-08-10T18:57:11Z": {
    type: "show",
    title: "Louie",
    year: "2010",
    trakt_rating: "8.40617",
    trakt_id: "32820",
    imdb_id: "tt1492966",
    tmdb_id: "32962",
    tvdb_id: "155201",
    url: "https://trakt.tv/shows/louie",
    released: "2010-06-30",
    runtime: "23",
    episode_count: "61",
    genres: "comedy,drama",
  },
  "2017-08-10T18:56:47Z": {
    type: "show",
    title: "Lost",
    year: "2004",
    trakt_rating: "8.19752",
    trakt_id: "4583",
    imdb_id: "tt0411008",
    tmdb_id: "4607",
    tvdb_id: "73739",
    url: "https://trakt.tv/shows/lost-2004",
    released: "2004-09-23",
    runtime: "42",
    episode_count: "120",
    genres: "mystery,adventure,action",
  },
  "2017-08-10T18:55:35Z": {
    type: "show",
    title: "The Leftovers",
    year: "2014",
    trakt_rating: "8.19853",
    trakt_id: "54001",
    imdb_id: "tt2699128",
    tmdb_id: "54344",
    tvdb_id: "269689",
    url: "https://trakt.tv/shows/the-leftovers",
    released: "2014-06-30",
    runtime: "60",
    episode_count: "28",
    genres: "drama,fantasy,science-fiction",
  },
  "2017-08-10T18:49:25Z": {
    type: "show",
    title: "In the Flesh",
    year: "2013",
    trakt_rating: "8.11222",
    trakt_id: "46471",
    imdb_id: "tt2480514",
    tmdb_id: "46746",
    tvdb_id: "261742",
    url: "https://trakt.tv/shows/in-the-flesh",
    released: "2013-03-17",
    runtime: "60",
    episode_count: "9",
    genres: "drama,horror",
  },
  "2017-08-10T18:48:48Z": {
    type: "show",
    title: "House of Lies",
    year: "2012",
    trakt_rating: "7.66924",
    trakt_id: "39527",
    imdb_id: "tt1797404",
    tmdb_id: "39702",
    tvdb_id: "247909",
    url: "https://trakt.tv/shows/house-of-lies",
    released: "2012-01-09",
    runtime: "30",
    episode_count: "24",
    genres: "comedy,drama",
  },
  "2017-08-10T18:48:14Z": {
    type: "show",
    title: "The Hour",
    year: "2011",
    trakt_rating: "8.27287",
    trakt_id: "39543",
    imdb_id: "tt1778108",
    tmdb_id: "39718",
    tvdb_id: "248503",
    url: "https://trakt.tv/shows/the-hour-2011",
    released: "2011-07-19",
    runtime: "60",
    episode_count: "12",
    genres: "drama",
  },
  "2017-08-10T18:47:53Z": {
    type: "show",
    title: "The Honourable Woman",
    year: "2014",
    trakt_rating: "7.86713",
    trakt_id: "60640",
    imdb_id: "tt3021686",
    tmdb_id: "61123",
    tvdb_id: "272318",
    url: "https://trakt.tv/shows/the-honourable-woman",
    released: "2014-07-04",
    runtime: "60",
    episode_count: "8",
    genres: "drama",
  },
  "2017-08-10T18:45:42Z": {
    type: "show",
    title: "Fresh Meat",
    year: "2011",
    trakt_rating: "7.7214",
    trakt_id: "40445",
    imdb_id: "tt2058303",
    tmdb_id: "40632",
    tvdb_id: "251889",
    url: "https://trakt.tv/shows/fresh-meat",
    released: "2011-09-21",
    runtime: "50",
    episode_count: "16",
    genres: "comedy,drama",
  },
  "2017-08-10T18:45:06Z": {
    type: "show",
    title: "Freaks and Geeks",
    year: "1999",
    trakt_rating: "8.54796",
    trakt_id: "2368",
    imdb_id: "tt0193676",
    tmdb_id: "2382",
    tvdb_id: "76321",
    url: "https://trakt.tv/shows/freaks-and-geeks",
    released: "1999-09-26",
    runtime: "44",
    episode_count: "18",
    genres: "comedy,drama",
  },
  "2017-08-10T18:44:44Z": {
    type: "show",
    title: "Flight of the Conchords",
    year: "2007",
    trakt_rating: "8.40703",
    trakt_id: "2724",
    imdb_id: "tt0863046",
    tmdb_id: "2742",
    tvdb_id: "80252",
    url: "https://trakt.tv/shows/flight-of-the-conchords",
    released: "2007-06-18",
    runtime: "26",
    episode_count: "22",
    genres: "comedy",
  },
  "2017-08-10T18:43:53Z": {
    type: "show",
    title: "Fawlty Towers",
    year: "1975",
    trakt_rating: "8.7661",
    trakt_id: "2194",
    imdb_id: "tt0072500",
    tmdb_id: "2207",
    tvdb_id: "75932",
    url: "https://trakt.tv/shows/fawlty-towers",
    released: "1975-09-19",
    runtime: "30",
    episode_count: "12",
    genres: "comedy",
  },
  "2017-08-10T18:42:23Z": {
    type: "show",
    title: "The Fall",
    year: "2013",
    trakt_rating: "8.09579",
    trakt_id: "48703",
    imdb_id: "tt2294189",
    tmdb_id: "49010",
    tvdb_id: "258107",
    url: "https://trakt.tv/shows/the-fall",
    released: "2013-05-12",
    runtime: "57",
    episode_count: "17",
    genres: "drama,crime",
  },
  "2017-08-10T18:41:58Z": {
    type: "show",
    title: "The Fades",
    year: "2011",
    trakt_rating: "7.63023",
    trakt_id: "41010",
    imdb_id: "tt1772379",
    tmdb_id: "41211",
    tvdb_id: "251539",
    url: "https://trakt.tv/shows/the-fades",
    released: "2011-09-21",
    runtime: "55",
    episode_count: "6",
    genres: "drama,fantasy,science-fiction",
  },
  "2017-08-10T15:29:35Z": {
    type: "show",
    title: "Downton Abbey",
    year: "2010",
    trakt_rating: "8.53194",
    trakt_id: "33760",
    imdb_id: "tt1606375",
    tmdb_id: "33907",
    tvdb_id: "193131",
    url: "https://trakt.tv/shows/downton-abbey",
    released: "2010-09-26",
    runtime: "40",
    episode_count: "23",
    genres: "drama",
  },
  "2017-08-10T15:28:56Z": {
    type: "show",
    title: "Dexter",
    year: "2006",
    trakt_rating: "8.53062",
    trakt_id: "1396",
    imdb_id: "tt0773262",
    tmdb_id: "1405",
    tvdb_id: "79349",
    url: "https://trakt.tv/shows/dexter",
    released: "2006-10-02",
    runtime: "55",
    episode_count: "96",
    genres: "drama,mystery,crime",
  },
  "2017-08-10T15:28:27Z": {
    type: "show",
    title: "Dark Net",
    year: "2016",
    trakt_rating: "6.47907",
    trakt_id: "103680",
    imdb_id: "tt5397520",
    tmdb_id: "64607",
    tvdb_id: "303598",
    url: "https://trakt.tv/shows/dark-net",
    released: "2016-01-22",
    runtime: "30",
    episode_count: "16",
    genres: "documentary",
  },
  "2017-08-10T15:27:58Z": {
    type: "show",
    title: "Covert Affairs",
    year: "2010",
    trakt_rating: "7.69031",
    trakt_id: "31494",
    imdb_id: "tt1495708",
    tmdb_id: "31631",
    tvdb_id: "104281",
    url: "https://trakt.tv/shows/covert-affairs",
    released: "2010-07-14",
    runtime: "42",
    episode_count: "11",
    genres: "drama,action,adventure",
  },
  "2017-08-10T15:27:19Z": {
    type: "show",
    title: "Cosmos",
    year: "2014",
    trakt_rating: "9.03618",
    trakt_id: "71041",
    imdb_id: "tt2395695",
    tmdb_id: "58474",
    tvdb_id: "260586",
    url: "https://trakt.tv/shows/cosmos-2014",
    released: "2014-03-09",
    runtime: "60",
    episode_count: "13",
    genres: "documentary",
  },
  "2017-08-10T15:26:39Z": {
    type: "show",
    title: "Copper",
    year: "2012",
    trakt_rating: "7.17771",
    trakt_id: "44735",
    imdb_id: "tt2006374",
    tmdb_id: "44983",
    tvdb_id: "257939",
    url: "https://trakt.tv/shows/copper",
    released: "2012-10-12",
    runtime: "45",
    episode_count: "23",
    genres: "drama,action,adventure",
  },
  "2017-08-10T15:23:50Z": {
    type: "show",
    title: "Brotherhood",
    year: "2006",
    trakt_rating: "7.5",
    trakt_id: "2191",
    imdb_id: "tt0457229",
    tmdb_id: "2204",
    tvdb_id: "79356",
    url: "https://trakt.tv/shows/brotherhood",
    released: "2006-07-10",
    runtime: "60",
    episode_count: "29",
    genres: "drama,action,adventure,crime",
  },
  "2017-08-10T15:22:10Z": {
    type: "show",
    title: "Breaking Bad",
    year: "2008",
    trakt_rating: "9.27557",
    trakt_id: "1388",
    imdb_id: "tt0903747",
    tmdb_id: "1396",
    tvdb_id: "81189",
    url: "https://trakt.tv/shows/breaking-bad",
    released: "2008-01-21",
    runtime: "45",
    episode_count: "62",
    genres: "drama",
  },
  "2017-08-10T13:16:20Z": {
    type: "show",
    title: "Boardwalk Empire",
    year: "2010",
    trakt_rating: "8.52197",
    trakt_id: "1610",
    imdb_id: "tt0979432",
    tmdb_id: "1621",
    tvdb_id: "84947",
    url: "https://trakt.tv/shows/boardwalk-empire",
    released: "2010-09-20",
    runtime: "60",
    episode_count: "56",
    genres: "crime,drama",
  },
  "2017-08-10T13:16:06Z": {
    type: "show",
    title: "Blackadder",
    year: "1983",
    trakt_rating: "8.67948",
    trakt_id: "60940",
    imdb_id: "tt0084988",
    tmdb_id: "7246",
    tvdb_id: "76736",
    url: "https://trakt.tv/shows/blackadder",
    released: "1983-06-14",
    runtime: "32",
    episode_count: "24",
    genres: "comedy",
  },
  "2017-08-10T13:13:33Z": {
    type: "show",
    title: "Battlestar Galactica",
    year: "2004",
    trakt_rating: "8.91928",
    trakt_id: "1959",
    imdb_id: "tt0407362",
    tmdb_id: "1972",
    tvdb_id: "73545",
    url: "https://trakt.tv/shows/battlestar-galactica-2004",
    released: "2004-10-19",
    runtime: "44",
    episode_count: "73",
    genres: "drama,fantasy,science-fiction,action,adventure",
  },
  "2017-08-10T13:13:17Z": {
    type: "show",
    title: "The Bastard Executioner",
    year: "2015",
    trakt_rating: "5.95349",
    trakt_id: "96556",
    imdb_id: "tt4218618",
    tmdb_id: "63349",
    tvdb_id: "279227",
    url: "https://trakt.tv/shows/the-bastard-executioner",
    released: "2015-09-16",
    runtime: "43",
    episode_count: "10",
    genres: "drama",
  },
  "2017-08-10T13:03:17Z": {
    type: "show",
    title: "Adventure Time",
    year: "2010",
    trakt_rating: "8.74926",
    trakt_id: "15195",
    imdb_id: "tt1305826",
    tmdb_id: "15260",
    tvdb_id: "152831",
    url: "https://trakt.tv/shows/adventure-time",
    released: "2010-04-05",
    runtime: "11",
    episode_count: "26",
    genres: "animation,comedy,fantasy,science-fiction",
  },
  "2017-08-10T13:02:23Z": {
    type: "show",
    title: "Above Suspicion",
    year: "2009",
    trakt_rating: "7.61905",
    trakt_id: "16273",
    imdb_id: "tt1247637",
    tmdb_id: "16343",
    tvdb_id: "84373",
    url: "https://trakt.tv/shows/above-suspicion",
    released: "2009-01-04",
    runtime: "60",
    episode_count: "11",
    genres: "drama",
  },
  "2017-08-10T02:11:07Z": {
    type: "show",
    title: "Wilfred",
    year: "2011",
    trakt_rating: "7.64588",
    trakt_id: "39352",
    imdb_id: "tt1703925",
    tmdb_id: "39525",
    tvdb_id: "239761",
    url: "https://trakt.tv/shows/wilfred-2011",
    released: "2011-06-24",
    runtime: "30",
    episode_count: "49",
    genres: "comedy",
  },
  "2017-08-10T02:09:56Z": {
    type: "show",
    title: "The Sopranos",
    year: "1999",
    trakt_rating: "9.14657",
    trakt_id: "1389",
    imdb_id: "tt0141842",
    tmdb_id: "1398",
    tvdb_id: "75299",
    url: "https://trakt.tv/shows/the-sopranos",
    released: "1999-01-11",
    runtime: "50",
    episode_count: "86",
    genres: "drama",
  },
  "2017-08-10T02:09:38Z": {
    type: "show",
    title: "The Brink",
    year: "2015",
    trakt_rating: "7.46294",
    trakt_id: "76507",
    imdb_id: "tt3216586",
    tmdb_id: "15907",
    tvdb_id: "278469",
    url: "https://trakt.tv/shows/the-brink",
    released: "2015-06-22",
    runtime: "30",
    episode_count: "10",
    genres: "comedy",
  },
  "2017-08-10T02:09:13Z": {
    type: "show",
    title: "Girls",
    year: "2012",
    trakt_rating: "7.19635",
    trakt_id: "42059",
    imdb_id: "tt1723816",
    tmdb_id: "42282",
    tvdb_id: "220411",
    url: "https://trakt.tv/shows/girls",
    released: "2012-04-16",
    runtime: "28",
    episode_count: "62",
    genres: "comedy,drama",
  },
  "2017-08-10T02:05:10Z": {
    type: "show",
    title: "Rome",
    year: "2005",
    trakt_rating: "8.6299",
    trakt_id: "1878",
    imdb_id: "tt0384766",
    tmdb_id: "1891",
    tvdb_id: "73508",
    url: "https://trakt.tv/shows/rome",
    released: "2005-08-29",
    runtime: "50",
    episode_count: "22",
    genres: "drama,action,adventure",
  },
  "2017-06-18T00:43:28Z": {
    type: "show",
    title: "Sense8",
    year: "2015",
    trakt_rating: "8.31003",
    trakt_id: "70384",
    imdb_id: "tt2431438",
    tmdb_id: "61664",
    tvdb_id: "268156",
    url: "https://trakt.tv/shows/sense8",
    released: "2015-06-05",
    runtime: "60",
    episode_count: "23",
    genres: "drama,fantasy,science-fiction",
  },
  "2017-05-19T14:09:05Z": {
    type: "show",
    title: "Hand of God",
    year: "2014",
    trakt_rating: "7.34463",
    trakt_id: "60767",
    imdb_id: "tt3973768",
    tmdb_id: "61263",
    tvdb_id: "284643",
    url: "https://trakt.tv/shows/hand-of-god",
    released: "2014-08-28",
    runtime: "60",
    episode_count: "20",
    genres: "drama",
  },
  "2017-05-19T14:08:19Z": {
    type: "show",
    title: "The Exorcist",
    year: "2016",
    trakt_rating: "7.85483",
    trakt_id: "107748",
    imdb_id: "tt5368542",
    tmdb_id: "67135",
    tvdb_id: "311816",
    url: "https://trakt.tv/shows/the-exorcist",
    released: "2016-09-24",
    runtime: "44",
    episode_count: "10",
    genres: "drama,mystery,horror,thriller",
  },
  "2017-05-19T14:05:26Z": {
    type: "show",
    title: "Hannibal",
    year: "2013",
    trakt_rating: "8.50443",
    trakt_id: "39825",
    imdb_id: "tt2243973",
    tmdb_id: "40008",
    tvdb_id: "259063",
    url: "https://trakt.tv/shows/hannibal",
    released: "2013-04-05",
    runtime: "43",
    episode_count: "39",
    genres: "drama,horror,crime",
  },
  "2016-10-03T18:45:00Z": {
    type: "show",
    title: "Braquo",
    year: "2009",
    trakt_rating: "7.97626",
    trakt_id: "37206",
    imdb_id: "tt1429534",
    tmdb_id: "37363",
    tvdb_id: "118451",
    url: "https://trakt.tv/shows/braquo",
    released: "2009-10-12",
    runtime: "50",
    episode_count: "32",
    genres: "drama,action,adventure",
  },
  "2016-08-29T01:00:00Z": {
    type: "show",
    title: "The Night Of",
    year: "2016",
    trakt_rating: "8.30072",
    trakt_id: "196851",
    imdb_id: "tt2401256",
    tmdb_id: "66276",
    tvdb_id: "310516",
    url: "https://trakt.tv/shows/the-night-of",
    released: "2016-07-11",
    runtime: "60",
    episode_count: "8",
    genres: "crime,drama,mystery",
  },
  "2016-05-10T02:00:00Z": {
    type: "show",
    title: "Damien",
    year: "2016",
    trakt_rating: "6.83564",
    trakt_id: "80770",
    imdb_id: "tt4337944",
    tmdb_id: "19033",
    tvdb_id: "282165",
    url: "https://trakt.tv/shows/damien",
    released: "2016-03-08",
    runtime: "43",
    episode_count: "10",
    genres: "drama,horror,fantasy,science-fiction",
  },
  "2016-04-04T04:01:00Z": {
    type: "show",
    title: "11.22.63",
    year: "2016",
    trakt_rating: "7.97588",
    trakt_id: "102771",
    imdb_id: "tt2879552",
    tmdb_id: "64464",
    tvdb_id: "301824",
    url: "https://trakt.tv/shows/11-22-63",
    released: "2016-02-15",
    runtime: "54",
    episode_count: "8",
    genres: "science-fiction,drama,fantasy,horror",
  },
  "2016-03-15T21:00:00Z": {
    type: "show",
    title: "Happy Valley",
    year: "2014",
    trakt_rating: "8.29676",
    trakt_id: "60750",
    imdb_id: "tt3428912",
    tmdb_id: "61244",
    tvdb_id: "280537",
    url: "https://trakt.tv/shows/happy-valley",
    released: "2014-04-29",
    runtime: "59",
    episode_count: "12",
    genres: "crime,drama",
  },
  "2016-03-02T01:30:00Z": {
    type: "show",
    title: "The Muppets",
    year: "2015",
    trakt_rating: "7.09934",
    trakt_id: "99029",
    imdb_id: "tt4651824",
    tmdb_id: "62815",
    tvdb_id: "295721",
    url: "https://trakt.tv/shows/the-muppets",
    released: "2015-09-23",
    runtime: "23",
    episode_count: "16",
    genres: "comedy",
  },
  "2015-12-16T02:00:00Z": {
    type: "show",
    title: "Manhattan",
    year: "2014",
    trakt_rating: "7.65581",
    trakt_id: "60632",
    imdb_id: "tt3231564",
    tmdb_id: "61112",
    tvdb_id: "278468",
    url: "https://trakt.tv/shows/manhattan",
    released: "2014-07-28",
    runtime: "43",
    episode_count: "23",
    genres: "drama,war",
  },
  "2002-11-04T00:00:00Z": {
    type: "show",
    title: "The Office",
    year: "2001",
    trakt_rating: "8.11766",
    trakt_id: "2976",
    imdb_id: "tt0290978",
    tmdb_id: "2996",
    tvdb_id: "78107",
    url: "https://trakt.tv/shows/the-office-2001",
    released: "2001-07-09",
    runtime: "30",
    episode_count: "12",
    genres: "comedy",
  },
};

export default series;
