/** @format */

import React from 'react';
import { withRouter } from 'react-router';
import base from '../base';

class Favourite extends React.Component {
	state = {
		favouriteMovies: [],
	};

	// reduce decimal to one place
	roundDecimal = (float) => {
		const rounded = Math.round(float * 10) / 10;
		return rounded;
	};

	// event delegation for the Movies links.
	handleClick(e, id, titleString, year) {
		e.preventDefault();
		this.goToMoviePage(id, titleString, year);
	}

	removeFromFavourites = (e, firebasekey) => {
		e.preventDefault();
		base.remove(`favouritemovies/${firebasekey}`, function (err) {
			if (!err) {
				//Router.transitionTo('dashboard');
			}
		});

		var element = document.getElementById(this.props.fsid.key);
		element.parentNode.removeChild(element);
	};

	// make a random color for the placeholder link
	// for movies with no poster available.
	getRandomBgColor = () => {
		var somecolors = [
			'c238c2',
			'c23838',
			'614875',
			'2548a1',
			'25a14c',
			'9ba125',
			'a17a25',
			'a12525',
			'f0820c',
			'd61773',
			'ce17d4',
			'601ea6',
			'2a1fa6',
			'0bb8db',
			'0dd91e',
			'388c3f',
			'378a73',
			'd45137',
		];

		const rand = Math.floor(Math.random() * somecolors.length);
		const uriSegA = 'https://dummyimage.com/300x450/';
		const uriSegB = '/ffffff.png&text=Poster+Not+Available';
		return uriSegA + somecolors[rand] + uriSegB;
	};
	// take movie title, return URI friendly string.
	goToMoviePage = (id, titleString, year) => {
		//alert('!');
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		const rooturi = '';
		const page = '/movies/' + id + '/' + tt + '-' + year;
		document.location = rooturi + page;
	};

	render() {
		if (this.props.mov === null || this.props.mov === undefined) {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='movie-item-style-2 movie-item-style-1'>loading</div>
				</React.Fragment>
			);
		} else {
			const { poster_path, title, vote_average, release_date, id } = this.props.mov;

			return (
				<React.Fragment>
					<div className='movie-item-style-2 movie-item-style-1' id={this.props.fsid.key}>
						<a
							onClick={(e) => {
								this.removeFromFavourites(e, this.props.fsid.key);
							}}
							title='Remove from Favourites'
							className='fancybox-item fancybox-close'
							href='#'
							style={{ right: '0px', top: '0px' }}></a>
						{poster_path == null && <img src={this.getRandomBgColor()} alt={title} />}
						{poster_path !== null && (
							<img src={'https://image.tmdb.org/t/p/w300/' + poster_path} alt={title} style={{ minHeight: '255px' }} />
						)}
						<div className='hvr-inner'>
							<a onClick={(e) => this.handleClick(e, `${id}`, `${title}`, `${release_date.split('-')[0]}`)}>
								Read more <i className='ion-android-arrow-dropright'></i>
							</a>
						</div>

						<div className='mv-item-infor'>
							<h6>
								<a
									style={{ cursor: 'pointer' }}
									onClick={(e) => this.handleClick(e, `${id}`, `${title}`, `${release_date.split('-')[0]}`)}>
									{title}
								</a>
								<br />
								<p className='rate'>
									<span>{release_date.split('-')[0]}</span>
								</p>
							</h6>
							<p className='rate'>
								<i className='ion-android-star'></i>
								<span>{this.roundDecimal(`${vote_average}`)}</span> /10
							</p>
						</div>
					</div>
				</React.Fragment>
			);
		}
	}
}

export default withRouter(Favourite);
