/** @format */

import React from 'react';
import Header from './Header';
import Footer from './Footer';
import movies from '../movies';
import Shows from './Shows';
import series from '../series';
import $ from 'jquery';

class ShowApp extends React.Component {
	state = {
		movies: {},
		series: {},
		pagedata: [],
		showdata: [],
		chunkSize: 60,
		startPage: 1,
		pageNum: 1,
		pageIndex: 0,
		cardCount: 0,
		listViewType: 'grid',
	};

	loadMoviesData(movieid, count) {
		fetch('https://api.themoviedb.org/3/movie/' + movieid + '?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((data) => {
				this.setState((prevState) => ({
					pagedata: prevState.pagedata.concat(data),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	loadSeriesData(showid, count) {
		fetch('https://api.themoviedb.org/3/tv/' + showid + '?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((data) => {
				this.setState((prevState) => ({
					showdata: prevState.showdata.concat(data),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	getPagination() {
		var status = $('#status');
		var preloader = $('#preloader');
		var body = $('body');
		preloader.css('display', 'block');
		status.css('display', 'block');

		Object.keys(this.state.movies)
			.slice(
				// prettier-ignore
				(this.state.chunkSize * this.props.match.params.pageNum) - this.state.chunkSize,
				this.props.match.params.pageNum * this.state.chunkSize
			)
			.map((key) => {
				this.loadMoviesData(this.state.movies[key].tmdb_id);
				console.log('show id:' + this.state.movies[key].tmdb_id);
			});
		body.delay(1000).css({ overflow: 'visible' });
		preloader.delay(1000).fadeOut('fast'); // will fade out the white DIV that covers the website.
		status.fadeIn(); // will first fade out the loading animation
	}

	getShowsPagination() {
		var status = $('#status');
		var preloader = $('#preloader');
		preloader.css('display', 'block');
		status.css('display', 'block');
		console.log('CALLED');

		Object.keys(this.state.series)
			.slice(
				// prettier-ignore
				(this.state.chunkSize * this.props.match.params.pageNum) - this.state.chunkSize,
				this.props.match.params.pageNum * this.state.chunkSize
			)
			.map((key) => {
				console.log('show id:' + this.state.series[key].tmdb_id);
				this.loadSeriesData(this.state.series[key].tmdb_id);
			});
	}
	setChunkSize = (amt) => {
		this.setState({ chunkSize: parseInt(amt) });
		this.getPagination();
	};

	setPageView = (viewtype) => {
		this.setState({ listViewType: viewtype });
		//alert(viewtype);
	};

	//prettier-ignore
	increasePageNum = (num) => {
		console.log('current page is: ' + this.state.pageNum);
		console.log('target page is: ' + (this.state.pageNum + 1));
		console.log();
		this.setState({ pageNum: (this.state.pageNum + 1) });
		this.setState({ pagedata: [] });
		document.location = '/tv/pages/' + num + '/'
	};

	//prettier-ignore
	decreasePageNum = (num) => {
		console.log('current page is: ' + this.state.pageNum);
		console.log('target page is: ' + (this.state.pageNum - 1));
		this.setState({ pageNum: (this.state.pageNum - 1) });
		this.setState({ pagedata: [] });
		document.location = '/tv/pages/' + num + '/'
	};

	goToMoviePage = (id, titleString, year) => {
		//alert('!');
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		const page = '/movies/' + id + '/' + tt + '-' + year;
		//`alert(page);
		document.location = page;
	};

	getRandomMovie = () => {
		const movieCount = Object.keys(this.state.movies).length;

		const randNum = Math.floor(Math.random() * movieCount);
		const randMovie = Object.keys(this.state.movies).slice(
			// prettier-ignore
			randNum,
			randNum + 1
		);

		const myid = this.state.movies[randMovie].tmdb_id;
		const mytitle = this.state.movies[randMovie].title;
		const myyear = this.state.movies[randMovie].year;
		this.goToMoviePage(myid, mytitle, myyear);
	};

	applyCss(path) {
		var status = $('#status');
		var preloader = $('#preloader');
		var body = $('body');
		preloader.css('display', 'block');
		status.css('display', 'block');
		$('.common-hero').css('background', 'url(' + path + ')  no-repeat');
		$('.common-hero').css('background-position', '100px 50%');
		$('.common-hero').css('background-size', ' cover');

		$('.ht-footer').css('background', 'url(' + path + ')  no-repeat');
		$('.ht-footer').css('background-position', '100px 50%');
		$('.ht-footer').css('background-size', ' cover');

		body.delay(1000).css({ overflow: 'visible' });
		preloader.delay(1000).fadeOut('fast'); // will fade out the white DIV that covers the website.
		status.fadeIn(); // will first fade 69out the loading animation
	}
	getReleaseYear = (date) => {
		const year = date.split('-')[0];
		return year;
	};
	getUriSafeTitle = (titleString) => {
		//alert('!');
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		return tt;
		//const page = '/movies/' + id + '/' + tt + '-' + year;
		//document.location = page;
	};
	loadBgimageData(randomshowid, count) {
		this.setState({ randImageData: [] });
		fetch('https://api.themoviedb.org/3/tv/' + randomshowid + '?api_key=83747b00d208337448cd133b2110d874')
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((rdata) => {
				this.setState((prevState) => ({
					randImageData: prevState.randImageData.concat(rdata),
				}));

				const myimgpath = 'https://image.tmdb.org/t/p/w1920_and_h800_multi_faces' + rdata.backdrop_path;

				this.applyCss(myimgpath);
				const footInfo = document.getElementById('randMovieInfo');
				const name = this.getUriSafeTitle(rdata.name);
				const year = this.getReleaseYear(rdata.first_air_date);
				footInfo.innerHTML = `The random background image is from <a href='/tv/${rdata.id}/${name}-${year}'>${rdata.name}</a>`;
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	getRandomBackgroundImage = () => {
		setTimeout(() => {
			const showCount = Object.keys(this.state.series).length;
			const randNum = Math.floor(Math.random() * showCount);
			const randShow = Object.keys(this.state.series).slice(
				// prettier-ignore
				randNum,
				randNum + 1
			);
			const myid = this.state.series[randShow].tmdb_id;
			this.loadBgimageData(myid);
		}, 250);
	};

	componentWillMount() {
		var status = $('#status');
		var preloader = $('#preloader');
		preloader.css('display', 'block');
		status.css('display', 'block');
	}

	componentDidMount() {
		this.setState({ series: series });
		this.setState({ movies: movies });
		setTimeout(() => {
			this.getPagination();
			this.getShowsPagination();
		}, 200);

		setTimeout(() => {
			this.getRandomBackgroundImage();
		}, 0);
	}

	render() {
		return (
			<React.Fragment>
				<Header getRandomMovie={this.getRandomMovie} />
				<div className='hero common-hero'>
					<div className='container'>
						<div className='row'>
							<div className='col-md-12'>
								<div className='hero-ct'>
									<ul className='breadcumb'>
										<li className='active'>
											<a href='/'>Home</a>
										</li>
										<li>
											<span className='ion-ios-arrow-right'></span> Series listing
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='page-single'>
					<div className='container'>
						<div className='row'>
							<Shows
								shows={this.state.series}
								showdata={this.state.showdata}
								movies={this.state.movies}
								pagedata={this.state.pagedata}
								chunkSize={this.state.chunkSize}
								setChunkSize={this.setChunkSize}
								startPage={this.state.startPage}
								pageNum={this.state.pageNum}
								increasePageNum={this.increasePageNum}
								decreasePageNum={this.decreasePageNum}
								listViewType={this.state.listViewType}
								setPageView={this.setPageView}
								pn={this.props.match.params.pageNum}
								getRandomMovie={this.getRandomMovie}
							/>
						</div>
					</div>
				</div>
				<Footer getRandomMovie={this.getRandomMovie} />
			</React.Fragment>
		);
	}
}

export default ShowApp;
