/** @format */

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Person from "./Person";
import Stars from "./Stars";
import Review from "./Review";
import movies from "../movies";
import $ from "jquery";
import SimilarMovie from "./SimilarMovie";
import MovieSocial from "./MovieSocial";
import base from "../base";

class MovieSingle extends React.Component {
  state = {
    movies: {},
    pagedata: [],
    pagecastdata: [],
    pagecrewdata: [],
    pageaddtldata: [],
    pagetrailerdata: [],
    pagesimilarmovsdata: [],
    pagemovieimagesdata: [],
    pagemoviekeywordsdata: [],
    pagemoviereviewsdata: [],
    pagemoviesocialdata: [],
    favouriteMovies: [],
    bgimgdata: [],
    filmIsRated: false,
    filmRating: 0,
    firebasekey: 5555555,
  };

  addToFavourites = (movieid) => {
    base.push(`favouritemovies`, {
      data: { movieId: movieid },
      then(err) {
        if (!err) {
          ///Router.transitionTo('dashboard');
        }
      },
    });
    this.getFavourites();
    document.getElementById("add-to-favourites").classList.add("hide-me");
    document.getElementById("remove-from-favourites").classList.add("show-me");
    document
      .getElementById("remove-from-favourites")
      .classList.remove("hide-me");
    document.getElementById("add-to-favourites").classList.remove("show-me");
    document.getElementById("movie-img-heart").classList.add("hide-me");
    document.getElementById("movie-img-heart").classList.remove("show-me");

    console.log("the movie is: " + movieid);
  };

  removeFromFavourites = (movieid) => {
    base.remove(`favouritemovies/${this.state.firebasekey}`, function (err) {
      if (!err) {
        //Router.transitionTo('dashboard');
      }
    });

    document.getElementById("remove-from-favourites").classList.add("hide-me");
    document.getElementById("add-to-favourites").classList.add("show-me");
    document
      .getElementById("remove-from-favourites")
      .classList.remove("show-me");
    document.getElementById("add-to-favourites").classList.remove("hide-me");
    document.getElementById("movie-img-heart").classList.add("hide-me");
    document.getElementById("movie-img-heart").classList.remove("show-me");
    console.log("the movie is: " + movieid);
  };

  // take person name, return URI friendly string.
  getPersonUriName = (name) => {
    const regex = / /gi;
    const regexDash = /---/gi;
    const regexspecial = /[:'()…,'!.?\][]/gi;
    const regexAmp = /&/gi;
    const regexSlash = /[/]/gi;
    const regexMisc = /½/gi;
    const regexSup2 = /[²]/gi;
    const regexSup3 = /[³]/gi;

    const tt = name
      .replace(regex, "-")
      .replace(regexDash, "-")
      .replace(regexspecial, "")
      .replace(regexAmp, "and")
      .replace(regexSlash, "-")
      .replace(regexMisc, "-and-a-half")
      .replace(regexSup2, "-2")
      .replace(regexSup3, "-3")
      .toLocaleLowerCase();

    return tt;
  };
  // reduce decimal to one place
  roundDecimal = (float) => {
    const rounded = Math.round(float * 10) / 10;
    return rounded;
  };

  // return the year fragment from a tmdb date node
  getReleaseYear = (date) => {
    const year = date.split("-")[0];
    return year;
  };

  // make a random color for the placeholder link
  // for movies with no poster available.
  getRandomBgColor = () => {
    var somecolors = [
      "c238c2",
      "c23838",
      "614875",
      "2548a1",
      "25a14c",
      "9ba125",
      "a17a25",
      "a12525",
      "f0820c",
      "d61773",
      "ce17d4",
      "601ea6",
      "2a1fa6",
      "0bb8db",
      "0dd91e",
      "388c3f",
      "378a73",
      "d45137",
    ];

    const rand = Math.floor(Math.random() * somecolors.length);
    const uriSegA = "https://dummyimage.com/300x450/";
    const uriSegB = "/ffffff.png&text=Poster+Not+Available";
    return uriSegA + somecolors[rand] + uriSegB;
  };

  colorKeyword(keyword) {
    var somecolors = [
      "6199d1",
      "4d8ccb",
      "3980c6",
      "3473b2",
      "2e669e",
      "28598a",
      "224d77",
      "1d4063",
      "17334f",
    ];
    const rand = Math.floor(Math.random() * somecolors.length);
    const hex = somecolors[rand];

    var cssobj = {
      backgroundColor: "#" + hex,
      margin: "5px 5px 5px 0",
      display: "inline-block",
      border: "1px solid #fff",
      color: "yellow",
    };
    return cssobj;
  }

  stripLeadZero(int) {
    if (int < 10) {
      return int.split("")[1];
    } else {
      return int;
    }
  }
  getFormattedDate(utcDate) {
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let dateFrags = utcDate.split("-");
    return (
      months[parseInt(dateFrags[1] - 1)] +
      " " +
      this.stripLeadZero(dateFrags[2]) +
      ", " +
      dateFrags[0]
    );
  }
  getRandomBgImage = () => {
    var somecolors = [
      "c238c2",
      "c23838",
      "614875",
      "2548a1",
      "25a14c",
      "9ba125",
      "a17a25",
      "a12525",
      "f0820c",
      "d61773",
      "ce17d4",
      "601ea6",
      "2a1fa6",
      "0bb8db",
      "0dd91e",
      "388c3f",
      "378a73",
      "d45137",
    ];

    const rand = Math.floor(Math.random() * somecolors.length);
    const uriSegA = "https://dummyimage.com/1920x800/";
    const uriSegB = "/ffffff.png&text=+";
    return uriSegA + somecolors[rand] + uriSegB;
  };

  loadMoviesData(movieid, count) {
    //console.log(movieid);
    var status = $("#status");
    var preloader = $("#preloader");
    var body = $("body");
    body.delay(0).css({ overflow: "visible" });
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        movieid +
        "?api_key=83747b00d208337448cd133b2110d874&language=en-US&append_to_response=release_dates"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((data) => {
        this.setState((prevState) => ({
          pagedata: prevState.pagedata.concat(data),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
    status.fadeOut(); // will first fade out the loading animation
    body.delay(1000).css({ overflow: "visible" });
    preloader.delay(1000).fadeOut("fast"); // will fade out the white DIV that covers the website.
  }

  loadMovieSocialData(movieId, count) {
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        movieId +
        "/external_ids?api_key=83747b00d208337448cd133b2110d874"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((socdata) => {
        this.setState((prevState) => ({
          pagemoviesocialdata: prevState.pagemoviesocialdata.concat(socdata),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  loadSimilarMovsData(movieid, count) {
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        movieid +
        "/similar?api_key=83747b00d208337448cd133b2110d874&language=en-US"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((smdata) => {
        this.setState((prevState) => ({
          pagesimilarmovsdata: prevState.pagesimilarmovsdata.concat(
            smdata.results
          ),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  loadCastData(movieid, count) {
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        movieid +
        "/credits?api_key=83747b00d208337448cd133b2110d874"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((data) => {
        this.setState((prevState) => ({
          pagecastdata: prevState.pagecastdata.concat(data.cast),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  loadCrewData(movieid, count) {
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        movieid +
        "/credits?api_key=83747b00d208337448cd133b2110d874"
    )
      .then((response) => response.json())
      .then((cdata) => {
        this.setState((prevState) => ({
          pagecrewdata: prevState.pagecrewdata.concat(cdata.crew),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  loadMoviesAdditionalData(movieid, count) {
    //console.log(movieid);
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        movieid +
        "?api_key=83747b00d208337448cd133b2110d874&append_to_response=release_dates"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((adata) => {
        this.setState((prevState) => ({
          pageaddtldata: prevState.pageaddtldata.concat(adata),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  loadMoviesTrailerData(movieid, count) {
    //console.log(movieid);
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        movieid +
        "/videos?api_key=83747b00d208337448cd133b2110d874&language=en-US"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((tdata) => {
        this.setState((prevState) => ({
          pagetrailerdata: prevState.pagetrailerdata.concat(tdata.results),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  loadMovieImagesData(movieid, count) {
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        movieid +
        "/images?api_key=83747b00d208337448cd133b2110d874&language=en-US"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((midata) => {
        this.setState((prevState) => ({
          pagemovieimagesdata: prevState.pagemovieimagesdata.concat(
            midata.profiles
          ),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  loadMovieKeywordsData(movieid, count) {
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        movieid +
        "/keywords?api_key=83747b00d208337448cd133b2110d874"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((kwdata) => {
        this.setState((prevState) => ({
          pagemoviekeywordsdata: prevState.pagemoviekeywordsdata.concat(
            kwdata.keywords
          ),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  loadMovieReviewsData(movieid, count) {
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        movieid +
        "/reviews?api_key=83747b00d208337448cd133b2110d874&language=en-US"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((rvdata) => {
        this.setState((prevState) => ({
          pagemoviereviewsdata: prevState.pagemoviereviewsdata.concat(
            rvdata.results
          ),
        }));
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  getPageData() {
    this.loadMoviesData(this.props.match.params.movieId);
    this.loadCastData(this.props.match.params.movieId);
    this.loadCrewData(this.props.match.params.movieId);
    this.loadMoviesAdditionalData(this.props.match.params.movieId);
    this.loadMoviesTrailerData(this.props.match.params.movieId);
    this.loadSimilarMovsData(this.props.match.params.movieId);
    this.loadMovieImagesData(this.props.match.params.movieId);
    this.loadMovieKeywordsData(this.props.match.params.movieId);
    this.loadMovieReviewsData(this.props.match.params.movieId);
    this.loadMovieSocialData(this.props.match.params.movieId);
  }

  /* fix this nasty shit later */
  scrolldetect = (event) => {
    //var stickySidebar = $('.sticky-sb');
    var stickySidebar = document.getElementsByClassName("sticky-sb");
    //var stickySidebar = document.querySelector('#sticky-sb');
    //alert(stickySidebar.length);

    var mainCt = document.getElementsByClassName("main-content");
    //alert(stickySidebar.length);
    //var mainCt = $('.main-content');
    if (stickySidebar.length > 0) {
      var stickyHeight = stickySidebar.height(),
        sidebarTop = stickySidebar.offset().top;
      //alert(stickyHeight + ' ' + sidebarTop);
    }

    window.addEventListener("scroll", function (event) {
      //alert(stickySidebar.length);
      var scrollTop = $(window).scrollTop();
      //alert(scrollTop);

      if (sidebarTop < scrollTop) {
        //stickySidebar.css('top', scrollTop - sidebarTop + 80);
        document.getElementById("sticky-sb").style.top =
          scrollTop - sidebarTop + 80;

        // stop the sticky sidebar at the footer to avoid overlapping
        var sidebarBottom = stickySidebar.offset().top + stickyHeight,
          stickyStop = mainCt.offset().top + mainCt.height();
        if (stickyStop < sidebarBottom) {
        }
      } else {
      }
    });
  };

  applyUserRating = (event) => {
    var x = event.target.getAttribute("rel");
    let ratingStars = document.querySelectorAll(".ion-ios-star-outline");
    let ratingStarsLit = document.querySelectorAll(".ion-ios-star");
    for (var index = 0; index < ratingStars.length; index++) {
      if (parseInt(ratingStars[index].getAttribute("rel")) <= parseInt(x)) {
        ratingStars[index].classList.add("ion-ios-star");
        ratingStars[index].classList.remove("ion-ios-star-outline");
      }
    }
    for (var index = 0; index < ratingStarsLit.length; index++) {
      if (parseInt(ratingStarsLit[index].getAttribute("rel")) > parseInt(x)) {
        ratingStarsLit[index].classList.remove("ion-ios-star");
        ratingStarsLit[index].classList.add("ion-ios-star-outline");
      }
    }

    const userrating = document.getElementById("userRating");
    const userratingtext = document.getElementById("userRatingText");

    if (parseInt(x) > 0) {
      userrating.classList.add("show-rating");
      userrating.classList.remove("hide-rating");
      userratingtext.innerHTML = x / 10 + "/10";
    } else {
      userrating.classList.remove("show-rating");
      userrating.classList.add("hide-rating");
    }
    event.preventDefault();
  };

  lockRating = () => {
    this.setState({ filmIsRated: true });
  };

  submitRating = () => {};

  resetRating = () => {
    if (this.state.filmIsRated !== true) {
      let ratingStars = document.querySelectorAll(".ion-ios-star-outline");
      let ratingStarsLit = document.querySelectorAll(".ion-ios-star");
      const userrating = document.getElementById("userRating");
      /* const userratingtext = document.getElementById("userRatingText"); */

      userrating.classList.remove("show-rating");
      userrating.classList.add("hide-rating");

      for (var index = 0; index < ratingStars.length; index++) {
        ratingStars[index].classList.remove("ion-ios-star");
        ratingStars[index].classList.add("ion-ios-star-outline");
      }
      for (var index = 0; index < ratingStarsLit.length; index++) {
        ratingStarsLit[index].classList.remove("ion-ios-star");
        ratingStarsLit[index].classList.add("ion-ios-star-outline");
      }
    }
  };

  toggleTabs = (event) => {
    var x = event.target.rel;
    var currentAttrValue = $(event.target).attr("rel");
    var tabsCurrent = $(".tabs #" + currentAttrValue);
    tabsCurrent.show().siblings().hide();
    // Change/remove current tab to active
    $(event.target)
      .parent("li")
      .addClass("active")
      .siblings()
      .removeClass("active");
    event.preventDefault();
  };

  toggleTabsFromPage = (event) => {
    var x = event.target.rel;
    var currentAttrValue = $(event.target).attr("rel");
    var tabsCurrent = $(".tabs #" + currentAttrValue);
    tabsCurrent.show().siblings().hide();

    $('.tab-links.tabs-mv li a[rel="' + currentAttrValue + '"]')
      .parent("li")
      .addClass("active")
      .siblings()
      .removeClass("active");
    event.preventDefault();
  };

  getDirector(id) {
    let theDirector;
    this.state.pagecrewdata.map((currElement) => {
      if (currElement.job === "Director") {
        theDirector = currElement.name;
      }
    });
    return theDirector;
  }

  getDirectorLink(id) {
    let theDirectorId;
    let theDirectorName;
    this.state.pagecrewdata.map((currElement) => {
      if (currElement.job === "Director") {
        theDirectorId = currElement.id;
        theDirectorName = this.getPersonUriName(currElement.name);
      }
    });
    return "/people/" + theDirectorId + "/" + theDirectorName;
  }

  getWriter(id) {
    let theWriter;
    this.state.pagecrewdata.map((currElement) => {
      if (currElement.job === "Screenplay" || currElement.job === "Writer") {
        theWriter = currElement.name;
      }
    });
    return theWriter;
  }

  getWriterLink(id) {
    let theWriterId;
    let theWriterName;
    this.state.pagecrewdata.map((currElement) => {
      if (currElement.job === "Screenplay") {
        theWriterId = currElement.id;
        theWriterName = this.getPersonUriName(currElement.name);
      }
    });
    return "/people/" + theWriterId + "/" + theWriterName;
  }

  getDirectorPic(id) {
    let theDirectorImage;
    this.state.pagecrewdata.map((currElement) => {
      if (currElement.job === "Director") {
        theDirectorImage = currElement.profile_path;
      }
    });
    return "https://image.tmdb.org/t/p/w45/" + theDirectorImage;
  }

  getYouTubeTrailer(id) {
    let thelink;
    this.state.pagetrailerdata.map((currElement) => {
      if (currElement.type === "Trailer") {
        thelink = currElement.key;
      }
    });
    return "https://www.youtube.com/watch?v=" + thelink;
  }

  goToMoviePage = (id, titleString, year) => {
    const regex = / /gi;
    const regexDash = /---/gi;
    const regexspecial = /[:'()…,'!.?\][]/gi;
    const regexAmp = /&/gi;
    const regexSlash = /[/]/gi;
    const regexMisc = /½/gi;
    const regexSup2 = /[²]/gi;
    const regexSup3 = /[³]/gi;

    const tt = titleString
      .replace(regex, "-")
      .replace(regexDash, "-")
      .replace(regexspecial, "")
      .replace(regexAmp, "and")
      .replace(regexSlash, "-")
      .replace(regexMisc, "-and-a-half")
      .replace(regexSup2, "-2")
      .replace(regexSup3, "-3")
      .toLocaleLowerCase();

    //const rooturi = '';
    const page = "/movies/" + id + "/" + tt + "-" + year;
    //this.props.history.push(`${page}`);
    document.location = page;
  };

  getRandomMovie = () => {
    const movieCount = Object.keys(this.state.movies).length;

    const randNum = Math.floor(Math.random() * movieCount);
    const randMovie = Object.keys(this.state.movies).slice(
      // prettier-ignore
      randNum,
      randNum + 1
    );

    const myid = this.state.movies[randMovie].tmdb_id;
    const mytitle = this.state.movies[randMovie].title;
    const myyear = this.state.movies[randMovie].year;
    this.goToMoviePage(myid, mytitle, myyear);
  };

  applyCss(path) {
    const bgpath = 'url("' + path + '") no-repeat!important';
    $(".ht-footer").css("background", "url(" + path + ")  no-repeat");
    $(".ht-footer").css("background-position", "100px 50%");
    $(".ht-footer").css("background-size", " cover");
  }

  loadBgimageData(movid, count) {
    this.setState({ bgimgdata: [] });
    fetch(
      "https://api.themoviedb.org/3/movie/" +
        movid +
        "?api_key=83747b00d208337448cd133b2110d874"
    )
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((rdata) => {
        this.setState((prevState) => ({
          bgimgdata: prevState.bgimgdata.concat(rdata),
        }));
        const myimgpath =
          "https://image.tmdb.org/t/p/w1920_and_h800_multi_faces" +
          rdata.backdrop_path;

        if (myimgpath !== null && myimgpath !== undefined) {
          this.applyCss(myimgpath);
        }
      })
      .catch((err) => console.error(this.props.url, err.toString()));
  }

  getFooterBgImage = () => {
    setTimeout(() => {
      this.loadBgimageData(this.props.match.params.movieId);
    }, 250);
  };

  getFavourites() {
    const { params } = this.props.match;
    base.fetch(`favouritemovies`, {
      context: this,
      asArray: true,
      then(data) {
        console.log(data);
        this.setState({
          favouriteMovies: data,
        });
        Object.keys(data).map((key, index) => {
          console.log("key: " + key + " data: " + data[key].movieId);
          if (data[key].movieId === params.movieId) {
            this.setState({
              firebasekey: data[key].key,
            });
            document
              .getElementById("add-to-favourites")
              .classList.add("hide-me");
            document
              .getElementById("remove-from-favourites")
              .classList.add("show-me");
            document.getElementById("movie-img-heart").classList.add("show-me");
          }
        });
      },
    });
  }

  componentDidMount() {
    this.setState({ movies: movies });
    this.getPageData();
    this.scrolldetect();
    this.getFavourites();
    setTimeout(() => {
      this.getFooterBgImage();
    }, 1000);

    const { params } = this.props.match;
    console.log("mounted!" + params.movieId);
  }

  componentWillUnmount() {
    base.removeBinding(this.ref);
  }

  render() {
    if (this.state.pagedata === null || this.state.pagedata === undefined) {
      // Render loading state ...
      const index = this.state.pagedata.count;
      return (
        <React.Fragment>
          <div className="movie-item-style-2 movie-item-style-1">loading</div>
        </React.Fragment>
      );
    } else {
      
      return (
        <React.Fragment>
          <Header getRandomMovie={this.getRandomMovie} />
          {this.state.pagedata.map((i, index) => {
            return (
              <React.Fragment key={`card-frag-${i.title}`}>
                {/* prettier-ignore */}
                {i.backdrop_path == null && (
                  <div
                    key={`rand-card-${i.title}`}
                    className="hero mv-single-hero"
                    style={{
                      background:
                        'url("' + this.getRandomBgImage() + '") no-repeat',
                    }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                    </div>
                  </div>
                )}
                {i.backdrop_path !== null && (
                  <div
                    key={`card-${i.title}`}
                    className="hero mv-single-hero"
                    style={{
                      background:
                        'url("https://image.tmdb.org/t/p/w1920_and_h800_multi_faces/' +
                        i.backdrop_path +
                        '") no-repeat',
                    }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12"></div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="page-single movie-single movie_single"
                  key={`card-single-${i.title}`}
                >
                  <div className="container">
                    <div className="row ipad-width2">
                      <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="movie-img sticky-sb" id="sticky-sb">
                          <div
                            id="movie-img-heart"
                            className="social-btn hide-me"
                            style={{
                              position: "absolute",
                              top: "20px",
                              left: "10px",
                              marginBottom: "20px",
                            }}
                          >
                            <a
                              className="parent-btn"
                              style={{ marginRight: "0px" }}
                            >
                              <i className="ion-heart"></i>
                            </a>
                          </div>
                          {i.poster_path == null && (
                            <img src={this.getRandomBgColor()} alt={i.title} />
                          )}
                          {i.poster_path !== null && (
                            <img
                              src={
                                "https://image.tmdb.org/t/p/w300" +
                                i.poster_path
                              }
                              alt={i.title}
                            />
                          )}
                          {this.state.pagetrailerdata.length > 0 && (
                            <div className="movie-btn">
                              <div className="btn-transform transform-vertical red">
                                <div>
                                  <a href="#" className="item item-1 redbtn">
                                    {" "}
                                    <i className="ion-play"></i> Watch Trailer
                                  </a>
                                </div>
                                <div>
                                  <a
                                    href={this.getYouTubeTrailer(i.id)}
                                    className="item item-2 redbtn fancybox-media hvr-grow"
                                  >
                                    <i className="ion-play"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-12 col-xs-12">
                        <div
                          className="movie-single-ct main-content"
                          id="main-content"
                        >
                          <h1 className="bd-hd">
                            {i.title}{" "}
                            <span>{this.getReleaseYear(i.release_date)}</span>
                          </h1>
                          {Object.keys(this.state.pagemoviesocialdata).map(
                            (key, index) => (
                              <MovieSocial
                                key={key}
                                details={this.state.pagemoviesocialdata[key]}
                                movid={i.id}
                                index={index}
                                addToFavourites={this.addToFavourites}
                                removeFromFavourites={this.removeFromFavourites}
                              >
                                {key}
                              </MovieSocial>
                            )
                          )}
                          <div className="movie-rate">
                            <div className="rate">
                              <i className="ion-android-star"></i>
                              <p>
                                <span>
                                  {this.roundDecimal(`${i.vote_average}`)}
                                </span>{" "}
                                /10
                                <br />
                                {this.state.pagemoviereviewsdata.length > 0 && (
                                  <span className="rv">
                                    {this.state.pagemoviereviewsdata.length}{" "}
                                    Reviews
                                  </span>
                                )}
                              </p>
                            </div>
                            <div
                              className="rate-star"
                              onMouseLeave={(e) => this.resetRating(e)}
                            >
                              <p style={{ fontSize: "14px" }}>
                                Rate This Movie:{" "}
                              </p>
                              <i
                                style={{ width: "10px", height: "30px" }}
                                rel="0"
                                onMouseOver={(e) => this.applyUserRating(e)}
                                onClick={(e) => this.lockRating(e)}
                              ></i>
                              <i
                                className="ion-ios-star-outline"
                                rel="10"
                                onMouseOver={(e) => this.applyUserRating(e)}
                                onClick={(e) => this.lockRating(e)}
                              ></i>
                              <i
                                className="ion-ios-star-outline"
                                rel="20"
                                onMouseOver={(e) => this.applyUserRating(e)}
                                onClick={(e) => this.lockRating(e)}
                              ></i>
                              <i
                                className="ion-ios-star-outline"
                                rel="30"
                                onMouseOver={(e) => this.applyUserRating(e)}
                                onClick={(e) => this.lockRating(e)}
                              ></i>
                              <i
                                className="ion-ios-star-outline"
                                rel="40"
                                onMouseOver={(e) => this.applyUserRating(e)}
                                onClick={(e) => this.lockRating(e)}
                              ></i>
                              <i
                                className="ion-ios-star-outline"
                                rel="50"
                                onMouseOver={(e) => this.applyUserRating(e)}
                                onClick={(e) => this.lockRating(e)}
                              ></i>
                              <i
                                className="ion-ios-star-outline"
                                rel="60"
                                onMouseOver={(e) => this.applyUserRating(e)}
                                onClick={(e) => this.lockRating(e)}
                              ></i>
                              <i
                                className="ion-ios-star-outline"
                                rel="70"
                                onMouseOver={(e) => this.applyUserRating(e)}
                                onClick={(e) => this.lockRating(e)}
                              ></i>
                              <i
                                className="ion-ios-star-outline"
                                rel="80"
                                onMouseOver={(e) => this.applyUserRating(e)}
                                onClick={(e) => this.lockRating(e)}
                              ></i>
                              <i
                                className="ion-ios-star-outline"
                                rel="90"
                                onMouseOver={(e) => this.applyUserRating(e)}
                                onClick={(e) => this.lockRating(e)}
                              ></i>
                              <i
                                className="ion-ios-star-outline"
                                rel="100"
                                onMouseOver={(e) => this.applyUserRating(e)}
                                onClick={(e) => this.lockRating(e)}
                              ></i>
                              <p
                                id="userRating"
                                className="hide-rating"
                                style={{
                                  fontSize: "14px",
                                  paddingLeft: "15px",
                                }}
                              >
                                Your Rating :{" "}
                                <span
                                  id="userRatingText"
                                  style={{ color: "#f5b50a" }}
                                ></span>
                              </p>
                            </div>
                          </div>
                          <div className="movie-tabs">
                            <div className="tabs">
                              <ul className="tab-links tabs-mv">
                                <li className="active">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    rel="overview"
                                    onClick={(e) => this.toggleTabs(e)}
                                  >
                                    Overview
                                  </a>
                                </li>
                                {this.state.pagemoviereviewsdata.length > 0 && (
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      rel="reviews"
                                      onClick={(e) => this.toggleTabs(e)}
                                    >
                                      {" "}
                                      Reviews
                                    </a>
                                  </li>
                                )}
                                <li>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    rel="cast"
                                    onClick={(e) => this.toggleTabs(e)}
                                  >
                                    {" "}
                                    Cast & Crew{" "}
                                  </a>
                                </li>
                                {this.state.pagetrailerdata.length > 0 && (
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      rel="media"
                                      onClick={(e) => this.toggleTabs(e)}
                                    >
                                      {" "}
                                      Videos
                                    </a>
                                  </li>
                                )}
                                {this.state.pagesimilarmovsdata.length > 0 && (
                                  <li>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      rel="moviesrelated"
                                      onClick={(e) => this.toggleTabs(e)}
                                    >
                                      {" "}
                                      Similar Movies
                                    </a>
                                  </li>
                                )}
                              </ul>
                              <div className="tab-content">
                                <div id="overview" className="tab active">
                                  <div className="row">
                                    <div className="col-md-8 col-sm-12 col-xs-12">
                                      <p>{i.overview}</p>
                                      {this.state.pagetrailerdata.length >
                                        0 && (
                                        <div className="title-hd-sm">
                                          <h4>Videos</h4>
                                          <a
                                            style={{ cursor: "pointer" }}
                                            rel="media"
                                            onClick={(e) =>
                                              this.toggleTabsFromPage(e)
                                            }
                                            className="time"
                                          >
                                            All{" "}
                                            {this.state.pagetrailerdata.length}{" "}
                                            Videos{" "}
                                            <i className="ion-ios-arrow-right"></i>
                                          </a>
                                        </div>
                                      )}
                                      <div
                                        className="mvsingle-item ov-item"
                                        style={{ display: "inline-flex" }}
                                      >
                                        {this.state.pagetrailerdata
                                          .slice(
                                            // prettier-ignore
                                            0,
                                            3
                                          )
                                          .map((x, index) => (
                                            <div
                                              className="vd-it"
                                              style={{ textAlign: "center" }}
                                              key={`youtubelink-${index}`}
                                            >
                                              <img
                                                style={{
                                                  padding: "5px 10px 5px 10px",
                                                  width: "120px",
                                                  border: "1 px solid #fff",
                                                  width: "120px",
                                                }}
                                                className="vd-img"
                                                src="/images/uploads/trailer-icon.png"
                                                alt=""
                                              />
                                              <a
                                                className="fancybox-media hvr-grow"
                                                href={
                                                  "https://www.youtube.com/watch?v=" +
                                                  x.key
                                                }
                                              >
                                                <img
                                                  src="/images/uploads/play-vd.png"
                                                  alt=""
                                                />
                                              </a>
                                            </div>
                                          ))}
                                      </div>
                                      <div className="title-hd-sm">
                                        <h4>cast</h4>
                                        <a
                                          className="time toggleButt"
                                          style={{ cursor: "pointer" }}
                                          rel="cast"
                                          onClick={(e) =>
                                            this.toggleTabsFromPage(e)
                                          }
                                        >
                                          {" "}
                                          Full Cast & Crew{" "}
                                        </a>
                                      </div>
                                      <div className="mvcast-item">
                                        {Object.keys(this.state.pagecastdata)
                                          .slice(
                                            // prettier-ignore
                                            0,
                                            10
                                          )
                                          .map((key, index) => (
                                            <Person
                                              key={`person1-${index}`}
                                              details={
                                                this.state.pagecastdata[key]
                                              }
                                              index={index}
                                            >
                                              {key}
                                            </Person>
                                          ))}
                                      </div>
                                      {/* review */}
                                      {this.state.pagemoviereviewsdata.length >
                                        0 && (
                                        <div className="title-hd-sm">
                                          <h4>Reviews</h4>

                                          <a
                                            className="time toggleButt"
                                            style={{ cursor: "pointer" }}
                                            rel="reviews"
                                            onClick={(e) =>
                                              this.toggleTabsFromPage(e)
                                            }
                                          >
                                            See All{" "}
                                            {
                                              this.state.pagemoviereviewsdata
                                                .length
                                            }{" "}
                                            Reviews{" "}
                                            <i className="ion-ios-arrow-right"></i>
                                          </a>
                                        </div>
                                      )}

                                      {this.state.pagemoviereviewsdata.length >
                                        0 && (
                                        <div>
                                          {Object.keys(
                                            this.state.pagemoviereviewsdata
                                          )
                                            .slice(0, 2)
                                            .map((key, index) => (
                                              <Review
                                                key={`review-${index}`}
                                                details={
                                                  this.state
                                                    .pagemoviereviewsdata[key]
                                                }
                                                index={index}
                                                count={
                                                  this.state
                                                    .pagemoviereviewsdata.length
                                                }
                                              >
                                                {key}
                                              </Review>
                                            ))}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-4 col-xs-12 col-sm-12">
                                      <div className="sb-it">
                                        <h6>Director: </h6>
                                        <p>
                                          <a href={this.getDirectorLink(i.id)}>
                                            {this.getDirector(i.id)}
                                          </a>
                                        </p>
                                      </div>
                                      <div className="sb-it">
                                        <h6>Writer: </h6>
                                        <p>
                                          <a href={this.getWriterLink(i.id)}>
                                            {this.getWriter(i.id)}
                                          </a>
                                        </p>
                                      </div>
                                      <div className="sb-it">
                                        <h6>Stars: </h6>
                                        <p>
                                          {Object.keys(this.state.pagecastdata)
                                            .slice(0, 5)
                                            .map((key, index) => (
                                              <Stars
                                                key={`stars-${index}`}
                                                details={
                                                  this.state.pagecastdata[key]
                                                }
                                                index={index}
                                              >
                                                {key}
                                              </Stars>
                                            ))}
                                        </p>
                                      </div>
                                      {i.genres.length > 0 && (
                                        <div className="sb-it">
                                          <h6>Genres:</h6>
                                          <p className="tags">
                                            {i.genres.map((x) => (
                                              <span
                                                key={`genres-${x.name}`}
                                                className="time"
                                                style={{
                                                  display: "inlineBlock",
                                                }}
                                              >
                                                <a
                                                  style={this.colorKeyword()} /* href={`/search/genre/${x.id}`} */
                                                >
                                                  {x.name}
                                                </a>
                                              </span>
                                            ))}
                                          </p>
                                        </div>
                                      )}
                                      <div className="sb-it">
                                        <h6>Release Date:</h6>
                                        <p>
                                          {this.getFormattedDate(
                                            i.release_date
                                          )}
                                        </p>
                                      </div>
                                      <div className="sb-it">
                                        <h6>Run Time:</h6>
                                        <p>{i.runtime + " Minutes"}</p>
                                      </div>
                                      <div className="sb-it">
                                        {i.release_dates.results.map(
                                          (j, index) =>
                                            j.iso_3166_1 == "US" &&
                                            j.release_dates[0].certification !==
                                              "" && (
                                              <h6
                                                key={`tags-${
                                                  index + j.iso_3166_1
                                                }`}
                                              >
                                                MMPA Rating:
                                              </h6>
                                            )
                                        )}

                                        {i.release_dates.results.map(
                                          (j) =>
                                            j.iso_3166_1 == "US" && (
                                              <p
                                                key={`cert-${
                                                  index + j.iso_3166_1
                                                }`}
                                              >
                                                {
                                                  j.release_dates[0]
                                                    .certification
                                                }
                                              </p>
                                            )
                                        )}
                                      </div>
                                      {this.state.pagemoviekeywordsdata.length >
                                        0 && (
                                        <div className="sb-it">
                                          <h6>Plot Keywords:</h6>
                                          <p className="tags">
                                            {this.state.pagemoviekeywordsdata.map(
                                              (x) => (
                                                <span
                                                  key={`keyword-${x.name}`}
                                                  className="time"
                                                  style={{
                                                    display: "inlineBlock",
                                                  }}
                                                >
                                                  <a
                                                    style={this.colorKeyword()}
                                                    href={`/search/keyword/${x.id}`}
                                                  >
                                                    {x.name}
                                                  </a>
                                                </span>
                                              )
                                            )}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div id="reviews" className="tab review">
                                  <div className="row">
                                    <div className="rv-hd">
                                      <div className="div">
                                        <h3>Reviews of</h3>
                                        <h2>{i.title}</h2>
                                      </div>
                                    </div>
                                    <div className="topbar-filter">
                                      <p>
                                        Found{" "}
                                        <span>
                                          {
                                            this.state.pagemoviereviewsdata
                                              .length
                                          }{" "}
                                          reviews
                                        </span>{" "}
                                        in total
                                      </p>
                                      <label>Filter by:</label>
                                      <select>
                                        <option value="popularity">
                                          Popularity Descending
                                        </option>
                                        <option value="popularity">
                                          Popularity Ascending
                                        </option>
                                        <option value="rating">
                                          Rating Descending
                                        </option>
                                        <option value="rating">
                                          Rating Ascending
                                        </option>
                                        <option value="date">
                                          Release date Descending
                                        </option>
                                        <option value="date">
                                          Release date Ascending
                                        </option>
                                      </select>
                                    </div>
                                    {this.state.pagemoviereviewsdata.length >
                                      0 && (
                                      <div>
                                        {Object.keys(
                                          this.state.pagemoviereviewsdata
                                        ).map((key, index) => (
                                          <Review
                                            key={`review-${index}`}
                                            details={
                                              this.state.pagemoviereviewsdata[
                                                key
                                              ]
                                            }
                                            index={index}
                                            count={
                                              this.state.pagemoviereviewsdata
                                                .length
                                            }
                                          >
                                            {key}
                                          </Review>
                                        ))}
                                      </div>
                                    )}
                                    <div className="topbar-filter">
                                      <label>Reviews per page:</label>
                                      <select>
                                        <option value="range">5 Reviews</option>
                                        <option value="saab">10 Reviews</option>
                                      </select>
                                      <div className="pagination2">
                                        <span>Page 1 of 6:</span>
                                        <a className="active" href="#">
                                          1
                                        </a>
                                        <a href="#">2</a>
                                        <a href="#">3</a>
                                        <a href="#">4</a>
                                        <a href="#">5</a>
                                        <a href="#">6</a>
                                        <a href="#">
                                          <i className="ion-arrow-right-b"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div id="cast" className="tab">
                                  <div className="row">
                                    <h3>Cast & crew of</h3>
                                    <h2>{i.title}</h2>

                                    <div className="title-hd-sm">
                                      <h4>Directors & Credit Writers</h4>
                                    </div>
                                    <div className="mvcast-item">
                                      <div className="cast-it">
                                        <div className="cast-left">
                                          <img
                                            src={this.getDirectorPic(i.id)}
                                            alt=""
                                          />
                                          <a href={this.getDirectorLink(i.id)}>
                                            {this.getDirector(i.id)}
                                          </a>
                                        </div>
                                        <p>... Director</p>
                                      </div>
                                    </div>
                                    <div className="title-hd-sm">
                                      <h4>Cast</h4>
                                    </div>
                                    <div className="mvcast-item">
                                      {Object.keys(this.state.pagecastdata).map(
                                        (key, index) => (
                                          <Person
                                            key={`Person-${index}`}
                                            details={
                                              this.state.pagecastdata[key]
                                            }
                                            index={index}
                                          >
                                            {key}
                                          </Person>
                                        )
                                      )}
                                    </div>
                                    <div className="title-hd-sm">
                                      <h4>Directors & Credit Writers</h4>
                                    </div>
                                    <div className="mvcast-item">
                                      {Object.keys(this.state.pagecrewdata).map(
                                        (key, index) => (
                                          <Person
                                            key={`crew-${index}`}
                                            details={
                                              this.state.pagecrewdata[key]
                                            }
                                            index={index}
                                          >
                                            {key}
                                          </Person>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div id="media" className="tab">
                                  <div className="row">
                                    <div className="rv-hd">
                                      <div>
                                        <h3>Videos related to</h3>
                                        <h2>{i.title}</h2>
                                      </div>
                                    </div>
                                    <div className="title-hd-sm">
                                      <h4>
                                        Videos{" "}
                                        <span>
                                          ({this.state.pagetrailerdata.length})
                                        </span>
                                      </h4>
                                    </div>
                                    <div
                                      className="mvsingle-item media-item"
                                      style={{
                                        display: "block",
                                      }}
                                    >
                                      {this.state.pagetrailerdata.map(
                                        (x, index) => (
                                          <div
                                            className="vd-item"
                                            style={{
                                              display: "inline-flex",
                                              width: "150px",
                                            }}
                                            key={`youtube-${x.name}`}
                                          >
                                            <div
                                              className="vd-it"
                                              style={{ textAlign: "center" }}
                                            >
                                              <img
                                                style={{
                                                  padding: "5px 10px 5px 10px",
                                                  width: "150px",
                                                }}
                                                className="vd-img"
                                                src="/images/uploads/trailer-icon.png"
                                                alt=""
                                              />
                                              <a
                                                className="fancybox-media hvr-grow"
                                                href={
                                                  "https://www.youtube.com/watch?v=" +
                                                  x.key
                                                }
                                              >
                                                <img
                                                  src="/images/uploads/play-vd.png"
                                                  alt=""
                                                />
                                              </a>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div id="moviesrelated" className="tab">
                                  <div className="row">
                                    <h3>Movies similar to</h3>
                                    <h2>{i.title}</h2>
                                    <div className="topbar-filter">
                                      <p>
                                        Found{" "}
                                        <span>
                                          {
                                            this.state.pagesimilarmovsdata
                                              .length
                                          }{" "}
                                          movies
                                        </span>{" "}
                                        in total
                                      </p>
                                      <label>Sort by:</label>
                                      <select>
                                        <option value="popularity">
                                          Popularity Descending
                                        </option>
                                        <option value="popularity">
                                          Popularity Ascending
                                        </option>
                                        <option value="rating">
                                          Rating Descending
                                        </option>
                                        <option value="rating">
                                          Rating Ascending
                                        </option>
                                        <option value="date">
                                          Release date Descending
                                        </option>
                                        <option value="date">
                                          Release date Ascending
                                        </option>
                                      </select>
                                    </div>
                                    {/*  */}
                                    {Object.keys(
                                      this.state.pagesimilarmovsdata
                                    ).map((key, index) => (
                                      <SimilarMovie
                                        key={`similar-${index}`}
                                        details={
                                          this.state.pagesimilarmovsdata[key]
                                        }
                                        pagedetails={this.state.pagedata}
                                        castdetails={this.state.pagecastdata}
                                        crewdetails={this.state.pagecrewdata}
                                        index={index}
                                      >
                                        {key}
                                      </SimilarMovie>
                                    ))}
                                    {/*  */}
                                    <div className="topbar-filter">
                                      <label>Movies per page:</label>
                                      <select>
                                        <option value="range">5 Movies</option>
                                        <option value="saab">10 Movies</option>
                                      </select>
                                      <div className="pagination2">
                                        <span>Page 1 of 2:</span>
                                        <a className="active" href="#">
                                          1
                                        </a>
                                        <a href="#">2</a>
                                        <a href="#">
                                          <i className="ion-arrow-right-b"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
          <Footer />
        </React.Fragment>
      );
    }
  }
}

export default MovieSingle;
