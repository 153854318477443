/** @format */

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from './App';
import ShowApp from './ShowApp';
import Favourites from './Favourites';
import Home from './Home';
import NotFound from './NotFound';
import MovieSingle from './MovieSingle';
import PeopleSingle from './PeopleSingle';
import SeriesSingle from './SeriesSingle';
import SeriesSeasonSingle from './SeriesSeasonSingle';
import SeriesEpisodeSingle from './SeriesEpisodeSingle';
import Search from './Search';

const Router = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path='/' component={Home} />
			<Route exact path='/search' component={Search} />
			<Route exact path='/search/:searchType/:type' component={Search} />
			<Route exact path='/movies/pages/:pageNum/' component={App} />
			<Route exact path='/favourites/pages/:pageNum/' component={Favourites} />
			<Route exact path='/tv/pages/:pageNum/' component={ShowApp} />
			<Route exact path='/movies/:movieId/:movieTitle' component={MovieSingle} />
			<Route exact path='/tv/:showId/:showTitle' component={SeriesSingle} />
			<Route exact path='/tv/:showId/:showTitle/:showSeason' component={SeriesSeasonSingle} />
			<Route exact path='/tv/:showId/:showTitle/:showSeason/:episodeNumber' component={SeriesEpisodeSingle} />
			<Route exact path='/people/:personId/:personName' component={PeopleSingle} />
			<Route component={NotFound} />
		</Switch>
	</BrowserRouter>
);

export default Router;
