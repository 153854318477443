/** @format */

import React from 'react';
import { withRouter } from 'react-router';

class PersonSocial extends React.Component {
	// event delegation for the Movies links.

	render() {
		if (this.props.details === null || this.props.details === undefined) {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='movie-item-style-2 movie-item-style-1'>loading</div>
				</React.Fragment>
			);
		} else {
			const { facebook_id, instagram_id, twitter_id } = this.props.details;

			return (
				<React.Fragment>
					<div className='social-link cebsingle-socail'>
						{facebook_id !== null && (
							<a href={'https://facebook.com/' + facebook_id} target='_blank'>
								<i className='ion-social-facebook'></i>
							</a>
						)}
						{twitter_id !== null && (
							<a href={'https://twitter.com/' + twitter_id} target='_blank'>
								<i className='ion-social-twitter'></i>
							</a>
						)}
						{instagram_id !== null && (
							<a href={'https://instagram.com/' + instagram_id} target='_blank'>
								<i className='ion-social-instagram'></i>
							</a>
						)}
						{facebook_id == null && twitter_id == null && instagram_id == null && (
							<a>
								<i></i>
							</a>
						)}
					</div>
				</React.Fragment>
			);
		}
	}
}

export default withRouter(PersonSocial);
