/** @format */

import React from 'react';
import { withRouter } from 'react-router';

class Person extends React.Component {
	// take person name, return URI friendly string.
	getPersonUriName = (name) => {
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = name
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		return tt;
	};

	// event delegation for the Movies links.
	handleClick(e, id, titleString, year) {
		e.preventDefault();
		this.goToMoviePage(id, titleString, year);
	}

	// make a random color for the placeholder link
	// for movies with no poster available.
	getRandomBgColor = () => {
		var somecolors = [
			'c238c2',
			'c23838',
			'614875',
			'2548a1',
			'25a14c',
			'9ba125',
			'a17a25',
			'a12525',
			'f0820c',
			'd61773',
			'ce17d4',
			'601ea6',
			'2a1fa6',
			'0bb8db',
			'0dd91e',
			'388c3f',
			'378a73',
			'd45137',
		];

		const rand = Math.floor(Math.random() * somecolors.length);
		const uriSegA = 'https://dummyimage.com/45x67/';
		const uriSegB = '/ffffff.png&text=Poster+Not+Available';
		return uriSegA + somecolors[rand] + uriSegB;
	};

	render() {
		if (this.props.details === null || this.props.details === undefined) {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='movie-item-style-2 movie-item-style-1'>loading</div>
				</React.Fragment>
			);
		} else {
			const { character, id, name, profile_path, job } = this.props.details;

			return (
				<React.Fragment>
					<div className='cast-it'>
						<div className='cast-left'>
							{profile_path == null && <img src={this.getRandomBgColor()} alt={profile_path} />}
							{profile_path !== null && <img src={'https://image.tmdb.org/t/p/w45/' + profile_path} alt={name} title={name} />}
							<a href={'/people/' + id + '/' + this.getPersonUriName(name)}>{name}</a>
						</div>

						{!job && <p>... {character}</p>}
						{job && <p>... {job}</p>}
					</div>
				</React.Fragment>
			);
		}
	}
}

export default withRouter(Person);
