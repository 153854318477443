/** @format */

import React from 'react';
import Header from './Header';
import Footer from './Footer';
import FilmographyItem from './FilmographyItem';
import TelevisionItem from './TelevisionItem';
import PersonSocial from './PersonSocial';
import movies from '../movies';
import $ from 'jquery';

class PeopleSingle extends React.Component {
	state = {
		movies: {},
		pagedata: [],
		pagecastdata: [],
		pagecrewdata: [],
		pageaddtldata: [],
		pagepersondata: [],
		pagepersoncreditsdata: [],
		pagepersontvcreditsdata: [],
		pagepersonimagesdata: [],
		pagepersonsocialdata: [],
	};

	getPersonVocation = (vocation) => {
		if (vocation === 'Acting') return 'Actor';
		if (vocation === 'Directing') return 'Director';
		if (vocation === 'Writing') return 'Writer';
		if (vocation === 'Editing') return 'Editor';
		else return vocation;
	};
	// reduce decimal to one place
	roundDecimal = (float) => {
		const rounded = Math.round(float * 10) / 10;
		return rounded;
	};

	// return the year fragment from a tmdb date node
	getReleaseYear = (date) => {
		const year = date.split('-')[0];
		return year;
	};

	// make a random color for the placeholder link
	// for movies with no poster available.
	getRandomBgColor = () => {
		var somecolors = [
			'c238c2',
			'c23838',
			'614875',
			'2548a1',
			'25a14c',
			'9ba125',
			'a17a25',
			'a12525',
			'f0820c',
			'd61773',
			'ce17d4',
			'601ea6',
			'2a1fa6',
			'0bb8db',
			'0dd91e',
			'388c3f',
			'378a73',
			'd45137',
		];

		const rand = Math.floor(Math.random() * somecolors.length);
		const uriSegA = 'https://dummyimage.com/300x450/';
		const uriSegB = '/ffffff.png&text=Poster+Not+Available';
		return uriSegA + somecolors[rand] + uriSegB;
	};

	stripLeadZero(int) {
		if (int < 10) {
			return int.split('')[1];
		} else {
			return int;
		}
	}
	getFormattedDate(utcDate) {
		let months = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];
		let dateFrags = utcDate.split('-');
		return months[parseInt(dateFrags[1] - 1)] + ' ' + this.stripLeadZero(dateFrags[2]) + ', ' + dateFrags[0];
	}
	getRandomBgImage = () => {
		var somecolors = [
			'c238c2',
			'c23838',
			'614875',
			'2548a1',
			'25a14c',
			'9ba125',
			'a17a25',
			'a12525',
			'f0820c',
			'd61773',
			'ce17d4',
			'601ea6',
			'2a1fa6',
			'0bb8db',
			'0dd91e',
			'388c3f',
			'378a73',
			'd45137',
		];

		const rand = Math.floor(Math.random() * somecolors.length);
		const uriSegA = 'https://dummyimage.com/1920x800/';
		const uriSegB = '/ffffff.png&text=+';
		return uriSegA + somecolors[rand] + uriSegB;
	};

	loadPersonCreditsData(personid, count) {
		fetch(
			'https://api.themoviedb.org/3/person/' +
				personid +
				'/movie_credits?api_key=83747b00d208337448cd133b2110d874&language=en-US'
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((crdata) => {
				this.setState((prevState) => ({
					pagepersoncreditsdata: prevState.pagepersoncreditsdata.concat(crdata.cast),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	loadPersonTvCreditsData(personid, count) {
		fetch(
			'https://api.themoviedb.org/3/person/' +
				personid +
				'/tv_credits?api_key=83747b00d208337448cd133b2110d874&language=en-US'
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((tvcrdata) => {
				this.setState((prevState) => ({
					pagepersontvcreditsdata: prevState.pagepersontvcreditsdata.concat(tvcrdata.cast),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	loadPersonImagesData(personid, count) {
		fetch(
			'https://api.themoviedb.org/3/person/' +
				personid +
				'/images?api_key=83747b00d208337448cd133b2110d874&language=en-US'
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((tidata) => {
				this.setState((prevState) => ({
					pagepersonimagesdata: prevState.pagepersonimagesdata.concat(tidata.profiles),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	loadPersonData(personid, count) {
		var status = $('#status');
		var preloader = $('#preloader');
		var body = $('body');
		body.delay(0).css({ overflow: 'hidden' });
		status.fadeOut(); // will first fade out the loading animation
		fetch(
			'https://api.themoviedb.org/3/person/' +
				personid +
				'?api_key=83747b00d208337448cd133b2110d874&language=en-US&append_to_response=credits'
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((adata) => {
				this.setState((prevState) => ({
					pagepersondata: prevState.pagepersondata.concat(adata),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
		status.fadeOut(); // will first fade out the loading animation
		body.delay(1000).css({ overflow: 'visible' });
		preloader.delay(1000).fadeOut('fast'); // will fade out the white DIV that covers the website.
	}

	loadPersonSocialData(personid, count) {
		fetch(
			'https://api.themoviedb.org/3/person/' +
				personid +
				'/external_ids?api_key=83747b00d208337448cd133b2110d874&language=en-US'
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((socdata) => {
				this.setState((prevState) => ({
					pagepersonsocialdata: prevState.pagepersonsocialdata.concat(socdata),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
	}

	getPageData() {
		this.loadPersonData(this.props.match.params.personId);
		this.loadPersonCreditsData(this.props.match.params.personId);
		this.loadPersonTvCreditsData(this.props.match.params.personId);
		this.loadPersonImagesData(this.props.match.params.personId);
		this.loadPersonSocialData(this.props.match.params.personId);
	}

	goToMoviePage = (id, titleString, year) => {
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		//const rooturi = '';
		const page = '/movies/' + id + '/' + tt + '-' + year;
		//this.props.history.push(`${page}`);
		document.location = page;
	};

	getRandomMovie = () => {
		const movieCount = Object.keys(this.state.movies).length;

		const randNum = Math.floor(Math.random() * movieCount);
		const randMovie = Object.keys(this.state.movies).slice(
			// prettier-ignore
			randNum,
			randNum + 1
		);

		const myid = this.state.movies[randMovie].tmdb_id;
		const mytitle = this.state.movies[randMovie].title;
		const myyear = this.state.movies[randMovie].year;
		this.goToMoviePage(myid, mytitle, myyear);
	};

	toggleTabs = (event) => {
		var currentAttrValue = $(event.target).attr('rel');
		var tabsCurrent = $('.tabs #' + currentAttrValue);
		tabsCurrent.show().siblings().hide();
		// Change/remove current tab to active
		$(event.target).parent('li').addClass('active').siblings().removeClass('active');
		event.preventDefault();
	};

	toggleTabsFromPage = (event) => {
		var currentAttrValue = $(event.target).attr('rel');
		var tabsCurrent = $('.tabs #' + currentAttrValue);
		tabsCurrent.show().siblings().hide();

		$('.tab-links.tabs-mv li a[rel="' + currentAttrValue + '"]')
			.parent('li')
			.addClass('active')
			.siblings()
			.removeClass('active');
		event.preventDefault();
	};

	getDirector(id) {
		let theDirector;
		this.state.pagecastdata.map((currElement) => {
			if (id === currElement.id) {
				var thisArr = currElement.crew;
				thisArr.forEach(function (item) {
					if (item.job === 'Director') {
						theDirector = item.name;
					}
				});
			}
		});
		return theDirector;
	}

	getDirectorPic(id) {
		let theDirectorImage;
		this.state.pagecastdata.map((currElement) => {
			if (id === currElement.id) {
				var thisArr = currElement.crew;
				thisArr.forEach(function (item) {
					if (item.job === 'Director') {
						theDirectorImage = item.profile_path;
					}
				});
			}
		});
		return 'https://image.tmdb.org/t/p/w45/' + theDirectorImage;
	}

	componentDidMount() {
		this.setState({ movies: movies });
		this.getPageData();
	}

	render() {
		if (this.state.pagepersondata === null || this.state.pagepersondata === undefined) {
			// Render loading state ...
			const index = this.state.pagedata.count;
			return (
				<React.Fragment>
					<div className='movie-item-style-2 movie-item-style-1'>loading</div>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<Header getRandomMovie={this.getRandomMovie} />
					{this.state.pagepersondata.map((i, index) => {
						return (
							<React.Fragment key={`card-frag-${i.name}`}>
								{i.profile_path === null && (
									<div
										key={`card-${i.name}`}
										className='hero hero3'
										style={{
											background: 'url("' + this.getRandomBgImage() + '") no-repeat',
										}}>
										<div className='container'>
											<div className='row'>
												<div className='col-md-12'></div>
											</div>
										</div>
									</div>
								)}

								{i.profile_path !== null && (
									<div
										key={`card-${i.name}`}
										className='hero hero3'
										style={{
											background:
												'url("https://image.tmdb.org/t/p/w1920_and_h800_multi_faces/' + i.profile_path + '") no-repeat',
											height: '538px',
										}}>
										<div className='container'>
											<div className='row'>
												<div className='col-md-12'></div>
											</div>
										</div>
									</div>
								)}

								<div className='page-single movie-single cebleb-single'>
									<div className='container'>
										<div className='row ipad-width'>
											<div className='col-md-4 col-sm-12 col-xs-12'>
												<div className='mv-ceb curved'>
													{i.profile_path === null && <img src={this.getRandomBgColor()} alt={i.name} />}
													{i.profile_path !== null && (
														<img src={'https://image.tmdb.org/t/p/w300' + i.profile_path} alt={i.name} />
													)}
												</div>
											</div>
											<div className='col-md-8 col-sm-12 col-xs-12'>
												<div className='movie-single-ct'>
													<h1 className='bd-hd'>{i.name}</h1>
													<p className='ceb-single'>Known for : {i.known_for_department}</p>
													{Object.keys(this.state.pagepersonsocialdata).map((key, index) => (
														<PersonSocial key={key} details={this.state.pagepersonsocialdata[key]} index={index}>
															{key}
														</PersonSocial>
													))}

													<div className='movie-tabs'>
														<div className='tabs'>
															<ul className='tab-links tabs-mv'>
																<li className='active'>
																	<a
																		style={{ cursor: 'pointer' }}
																		rel='overviewceb'
																		onClick={(e) => this.toggleTabs(e)}>
																		Overview
																	</a>
																</li>
																{i.biography !== null && i.biography !== undefined && i.biography !== '' && (
																	<li>
																		<a
																			style={{ cursor: 'pointer' }}
																			rel='biography'
																			onClick={(e) => this.toggleTabs(e)}>
																			Biography
																		</a>
																	</li>
																)}
																{this.state.pagepersonimagesdata.length > 0 && (
																	<li>
																		<a style={{ cursor: 'pointer' }} rel='mediaceb' onClick={(e) => this.toggleTabs(e)}>
																			Photos
																		</a>
																	</li>
																)}
																{this.state.pagepersoncreditsdata.length > 0 && (
																	<li>
																		<a
																			style={{ cursor: 'pointer' }}
																			rel='filmography'
																			onClick={(e) => this.toggleTabs(e)}>
																			Filmography
																		</a>
																	</li>
																)}
																{this.state.pagepersontvcreditsdata.length > 0 && (
																	<li>
																		<a
																			style={{ cursor: 'pointer' }}
																			rel='television'
																			onClick={(e) => this.toggleTabs(e)}>
																			Television
																		</a>
																	</li>
																)}
															</ul>
															<div className='tab-content'>
																<div id='overviewceb' className='tab active'>
																	<div className='row'>
																		<div className='col-md-8 col-sm-12 col-xs-12'>
																			{i.biography !== null && i.biography !== undefined && i.biography !== '' && (
																				<>
																					<p>{i.biography}</p>

																					<p className='time'>
																						<a
																							style={{ cursor: 'pointer' }}
																							rel='biography'
																							onClick={(e) => this.toggleTabsFromPage(e)}
																							className='time'>
																							See full bio <i className='ion-ios-arrow-right'></i>
																						</a>
																					</p>
																				</>
																			)}
																			{this.state.pagepersonimagesdata.length > 0 && (
																				<div className='title-hd-sm'>
																					<h4>Photos</h4>

																					<a
																						style={{ cursor: 'pointer' }}
																						rel='mediaceb'
																						onClick={(e) => this.toggleTabsFromPage(e)}
																						className='time'>
																						All {this.state.pagepersonimagesdata.length} Photos{' '}
																						<i className='ion-ios-arrow-right'></i>
																					</a>
																				</div>
																			)}
																			<div className='mvsingle-item ov-item'>
																				{this.state.pagepersonimagesdata.slice(0, 3).map((x, index) => (
																					<a
																						key={`photo-${index}`}
																						className='img-lightbox'
																						data-fancybox-group='gallery'
																						href={'https://image.tmdb.org/t/p/w500/' + x.file_path}>
																						<img
																							key={i.poster_path}
																							src={'https://image.tmdb.org/t/p/w154/' + x.file_path}
																						/>
																					</a>
																				))}
																			</div>
																			{this.state.pagepersoncreditsdata.length > 0 && (
																				<>
																					<div className='title-hd-sm'>
																						<h4>filmography</h4>

																						<a
																							style={{ cursor: 'pointer' }}
																							rel='filmography'
																							onClick={(e) => this.toggleTabsFromPage(e)}
																							className='time'>
																							Full Filmography
																						</a>
																					</div>

																					<div className='mvcast-item'>
																						{Object.keys(this.state.pagepersoncreditsdata)
																							.slice(
																								// prettier-ignore
																								0,
																								5
																							)
																							.map((key, index) => (
																								<FilmographyItem
																									key={key}
																									details={this.state.pagepersoncreditsdata[key]}
																									index={index}>
																									{key}
																								</FilmographyItem>
																							))}
																					</div>
																				</>
																			)}
																			{this.state.pagepersontvcreditsdata.length > 0 && (
																				<>
																					<div className='title-hd-sm'>
																						<h4>Television</h4>

																						<a
																							style={{ cursor: 'pointer' }}
																							rel='television'
																							onClick={(e) => this.toggleTabsFromPage(e)}
																							className='time'>
																							All Television Appearances
																						</a>
																					</div>

																					<div className='mvcast-item'>
																						{Object.keys(this.state.pagepersontvcreditsdata)
																							.slice(
																								// prettier-ignore
																								0,
																								5
																							)
																							.map((key, index) => (
																								<TelevisionItem
																									key={key}
																									tvdetails={this.state.pagepersontvcreditsdata[key]}
																									index={index}>
																									{key}
																								</TelevisionItem>
																							))}
																					</div>
																				</>
																			)}
																		</div>
																		<div className='col-md-4 col-xs-12 col-sm-12'>
																			<div className='sb-it'>
																				<h6>Full name: </h6>
																				<p>{i.name}</p>
																			</div>
																			{i.birthday !== null && (
																				<div className='sb-it'>
																					<h6>Date of Birth: </h6>

																					<p>{this.getFormattedDate(i.birthday)}</p>
																				</div>
																			)}
																			{i.deathday !== null && (
																				<div className='sb-it'>
																					<h6>Date of Death: </h6>

																					<p>{this.getFormattedDate(i.deathday)}</p>
																				</div>
																			)}
																			{i.place_of_birth !== null && (
																				<div className='sb-it'>
																					<h6>Birthplace: </h6>
																					<p>{i.place_of_birth}</p>
																				</div>
																			)}
																		</div>
																	</div>
																</div>
																<div id='biography' className='tab'>
																	<div className='row'>
																		<div className='rv-hd'>
																			<div>
																				<h3>Biography of</h3>
																				<h2>{i.name}</h2>
																			</div>
																		</div>
																		<p>{i.biography}</p>
																	</div>
																</div>
																<div id='mediaceb' className='tab'>
																	<div className='row'>
																		<div className='rv-hd'>
																			<div>
																				<h3>Photos of</h3>
																				<h2>{i.name}</h2>
																			</div>
																		</div>

																		<div className='title-hd-sm'>
																			<h4>
																				Photos <span> ({this.state.pagepersonimagesdata.length})</span>
																			</h4>
																		</div>
																		<div className='mvsingle-item' style={{ display: 'block' }}>
																			{this.state.pagepersonimagesdata.map((x, index) => (
																				<a
																					key={`photo2-${index}`}
																					className='img-lightbox'
																					data-fancybox-group='gallery'
																					href={'https://image.tmdb.org/t/p/w500/' + x.file_path}>
																					<img
																						style={{
																							padding: '5px 10px 5px 10px',
																						}}
																						key={i.poster_path}
																						src={'https://image.tmdb.org/t/p/w92/' + x.file_path}
																					/>
																				</a>
																			))}
																		</div>
																	</div>
																</div>
																<div id='filmography' className='tab'>
																	<div className='row'>
																		<div className='rv-hd'>
																			<div>
																				<h3>Filmography of</h3>
																				<h2>{i.name}</h2>
																			</div>
																		</div>
																		<div className='topbar-filter'>
																			<p>
																				Found <span>{this.state.pagepersoncreditsdata.length} movies</span> in total
																			</p>
																			<label>Filter by:</label>
																			<select>
																				<option value='popularity'>Popularity Descending</option>
																				<option value='popularity'>Popularity Ascending</option>
																				<option value='rating'>Rating Descending</option>
																				<option value='rating'>Rating Ascending</option>
																				<option value='date'>Release date Descending</option>
																				<option value='date'>Release date Ascending</option>
																			</select>
																		</div>

																		<div className='mvcast-item'>
																			{Object.keys(this.state.pagepersoncreditsdata).map((key, index) => (
																				<FilmographyItem
																					key={key}
																					details={this.state.pagepersoncreditsdata[key]}
																					index={index}>
																					{key}
																				</FilmographyItem>
																			))}
																		</div>
																	</div>
																</div>
																<div id='television' className='tab'>
																	<div className='row'>
																		<div className='rv-hd'>
																			<div>
																				<h2>{i.name}</h2>
																				<h3>Television Appearances</h3>
																			</div>
																		</div>
																		<div className='topbar-filter'>
																			<p>
																				Found <span>{this.state.pagepersoncreditsdata.length} shows</span> in total
																			</p>
																			<label>Filter by:</label>
																			<select>
																				<option value='popularity'>Popularity Descending</option>
																				<option value='popularity'>Popularity Ascending</option>
																				<option value='rating'>Rating Descending</option>
																				<option value='rating'>Rating Ascending</option>
																				<option value='date'>Release date Descending</option>
																				<option value='date'>Release date Ascending</option>
																			</select>
																		</div>

																		<div className='mvcast-item'>
																			{Object.keys(this.state.pagepersontvcreditsdata).map((key, index) => (
																				<TelevisionItem
																					key={key}
																					tvdetails={this.state.pagepersontvcreditsdata[key]}
																					index={index}>
																					{key}
																				</TelevisionItem>
																			))}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</React.Fragment>
						);
					})}
					<Footer getRandomMovie={this.getRandomMovie} />
				</React.Fragment>
			);
		}
	}
}

export default PeopleSingle;
