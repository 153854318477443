/** @format */

import React from 'react';

class HomeSidebar extends React.Component {
	getPersonUriName = (name) => {
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = name
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		return tt;
	};

	// make a random color for the placeholder link
	// for movies with no poster available.
	getRandomBgColor = () => {
		var somecolors = [
			'c238c2',
			'c23838',
			'614875',
			'2548a1',
			'25a14c',
			'9ba125',
			'a17a25',
			'a12525',
			'f0820c',
			'd61773',
			'ce17d4',
			'601ea6',
			'2a1fa6',
			'0bb8db',
			'0dd91e',
			'388c3f',
			'378a73',
			'd45137',
		];

		const rand = Math.floor(Math.random() * somecolors.length);
		const uriSegA = 'https://dummyimage.com/45x67/';
		const uriSegB = '/ffffff.png&text=Poster+Not+Available';
		return uriSegA + somecolors[rand] + uriSegB;
	};

	render() {
		if (this.props.pagedata === null || this.props.pagedata === undefined) {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='movie-item-style-2 movie-item-style-1'>loading</div>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<div className='sidebar'>
						{/* <div className='ads'>
							<img
								src='/images/uploads/ads1.png'
								alt=''
								width='336'
								height='296'
							/>
						</div> */}
						<div className='celebrities'>
							<h4 className='sb-title'>Spotlight Celebrities</h4>

							{this.props.pagedata.slice(0, 6).map((i) => {
								return (
									<div className='celeb-item' key={i.name}>
										<a href='#'>
											{i.profile_path == null && (
												<img
													src={this.getRandomBgColor()}
													alt={i.profile_path}
												/>
											)}
											{i.profile_path !== null && (
												<img
													src={
														'https://image.tmdb.org/t/p/w45/' + i.profile_path
													}
													alt=''
												/>
											)}
										</a>
										<div className='celeb-author'>
											<h6>
												<a
													href={
														'/people/' +
														i.id +
														'/' +
														this.getPersonUriName(i.name)
													}>
													{i.name}
												</a>
											</h6>
											<span>Actor</span>
										</div>
									</div>
								);
							})}
							{/* <a href='#' className='btn'>
								See all celebrities<i className='ion-ios-arrow-right'></i>
							</a> */}
						</div>
					</div>
				</React.Fragment>
			);
		}
	}
}

export default HomeSidebar;
