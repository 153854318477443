/** @format */

import React from 'react';
import Show from './Show';
import ShowListView from './ShowListView';
import $ from 'jquery';

class Shows extends React.Component {
	countRef = React.createRef();

	state = {
		pageNum: 0,
	};

	// event delegation for the Movies amt select options.
	setChunkSize = (event) => {
		const amt = this.countRef.current.value;
		this.props.setChunkSize(amt);
	};

	increasePageNum = (event, int) => {
		var status = $('#status');
		var preloader = $('#preloader');
		preloader.css('display', 'block');
		status.css('display', 'block');
		this.props.increasePageNum(int);
	};

	decreasePageNum = (event, int) => {
		var status = $('#status');
		var preloader = $('#preloader');
		preloader.css('display', 'block');
		status.css('display', 'block');
		this.props.decreasePageNum(int);
	};

	setPageView = (event, type) => {
		this.props.setPageView(type);
	};

	componentDidMount() {
		
	}

	render() {
		// get movie count from data.
		const shows = Object.keys(this.props.shows);
		const showsTotal = shows.length;
		//let pn = this.state.match.params.pageNum;

		return (
			<div className='col-md-12 col-sm-12 col-xs-12'>
				<div className='topbar-filter fw'>
					<p>
						Displaying {/* prettier-ignore */}
						<span>
							{(' ' + this.props.chunkSize * this.props.pn) - this.props.chunkSize + 1}{' - '}
							{(this.props.pn * this.props.chunkSize) + ' '}
								{'of ' + showsTotal}
							</span>{' '}
						total shows
					</p>
					<label>Sort by:</label>
					<select>
						<option value='popularity'>Popularity Descending</option>
						<option value='popularity'>Popularity Ascending</option>
						<option value='rating'>Rating Descending</option>
						<option value='rating'>Rating Ascending</option>
						<option value='date'>Release date Descending</option>
						<option value='date'>Release date Ascending</option>
					</select>
					{this.props.listViewType === 'list' && (
						<>
							<a className='list'>
								<i className='ion-ios-list-outline active'></i>
							</a>
							<a className='grid' onClick={(e) => this.setPageView(e, 'grid')}>
								<i className='ion-grid'></i>
							</a>
						</>
					)}

					{this.props.listViewType === 'grid' && (
						<>
							<a className='list' onClick={(e) => this.setPageView(e, 'list')}>
								<i className='ion-ios-list-outline'></i>
							</a>
							<a className='grid'>
								<i className='ion-grid active'></i>
							</a>
						</>
					)}
				</div>
				{/* prettier-ignore */}
				{this.props.listViewType === 'grid' && (
					<div className='flex-wrap-movielist mv-grid-fw'>
						{Object.keys(this.props.shows)
							.slice(this.props.chunkSize * this.props.pn - this.props.chunkSize, this.props.pn * this.props.chunkSize)
							.map((key, index) => (
								<Show key={key} details={this.props.shows[key]} show={this.props.showdata[index]} index={index}>
									{key}
								</Show>
							))}
					</div>
				)}
				{this.props.listViewType === 'list' && (
					<div className='flex-wrap-movielist mv-grid-fw'>
						{Object.keys(this.props.movies)
							.slice(
								this.props.chunkSize * this.props.pageNum - this.props.chunkSize,
								this.props.pageNum * this.props.chunkSize
							)
							.map((key, index) => (
								<ShowListView key={key} details={this.props.shows[key]} show={this.props.showdata[index]} index={index}>
									{key}
								</ShowListView>
							))}
					</div>
				)}
				<div className='topbar-filter'>
					<label>Shows per page:</label>
					<select id='movsPerPage' ref={this.countRef} onChange={this.setChunkSize}>
						<option defaultValue value='20'>
							20 Shows
						</option>
						<option value='30'>30 Shows</option>
						<option value='40'>40 Shows</option>
						<option value='80'>80 Shows</option>
					</select>

					<div className='pagination2'>
						<span>
							Page {this.props.pn} of {Math.ceil(showsTotal / this.props.chunkSize)}:
						</span>

						{this.props.pn > this.props.startPage + 3 && (
							<>
								<a href='#'>{this.props.startPage}</a>
								<a href='#'>{this.props.startPage + 1}</a>
								<a href='#'>{this.props.startPage + 2}</a>
								<a href='#'>...</a>
							</>
						)}
						{this.props.pn < showsTotal / this.props.chunkSize + 2 && (
							<>
								<a href='#'>{Math.ceil(showsTotal / this.props.chunkSize - 2)}</a>
								<a href='#'>{Math.ceil(showsTotal / this.props.chunkSize - 1)}</a>
								<a href='#'>{Math.ceil(showsTotal / this.props.chunkSize)}</a>
							</>
						)}
						<a>
							<i
								style={{ cursor: 'pointer' }}
								id='prev-page-arrow'
								className='ion-arrow-left-b'
								onClick={(e) => this.decreasePageNum(e, parseInt(this.props.pn) - 1)}></i>
						</a>
						{this.props.pn <= Math.ceil(showsTotal / this.props.chunkSize - 1) && (
							<a>
								<i
									style={{ cursor: 'pointer' }}
									id='next-page-arrow'
									className='ion-arrow-right-b'
									onClick={(e) => this.increasePageNum(e, parseInt(this.props.pn) + 1)}></i>
							</a>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default Shows;
