/** @format */

import React from 'react';
import { withRouter } from 'react-router';

class Review extends React.Component {
	// event delegation for the Movies links.

	render() {
		if (this.props.details === null || this.props.details === undefined) {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='movie-item-style-2 movie-item-style-1'>loading</div>
				</React.Fragment>
			);
		} else {
			const index = this.props.index;
			const count = this.props.count;
			const { author, content } = this.props.details;

			return (
				<React.Fragment>
					{index < count && (
						<div className='mv-user-review-item'>
							<p className='time'>
								by <a href='#'> {author}</a>
							</p>
							<p>{content}</p>
							<br></br>
						</div>
					)}
					{index === count && (
						<div className='mv-user-review-item last'>
							<p className='time'>
								by <a href='#'> {author}</a>
							</p>
							<p>{content}</p>
							<br></br>
						</div>
					)}
				</React.Fragment>
			);
		}
	}
}

export default withRouter(Review);
