/** @format */

import React from 'react';
import $ from 'jquery';

class Header extends React.Component {
	state = {
		searchResultsData: [],
	};

	searchRef = React.createRef();
	searchType = React.createRef();

	loadSearchResultsData(type, query) {
		this.setState({
			searchResultsData: [],
		});
		//console.log(movieid);
		var status = $('#status');
		var preloader = $('#preloader');
		var body = $('body');
		body.delay(0).css({ overflow: 'visible' });
		fetch(
			'https://api.themoviedb.org/3/search/' +
				type +
				'?api_key=83747b00d208337448cd133b2110d874&language=en-US&query=' +
				query +
				'&page=1&include_adult=false'
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((data) => {
				this.setState((prevState) => ({
					searchResultsData: prevState.searchResultsData.concat(data.results),
				}));
			})
			.catch((err) => console.error(this.props.url, err.toString()));
		status.fadeOut(); // will first fade out the loading animation
		body.delay(1000).css({ overflow: 'visible' });
		preloader.delay(1000).fadeOut('fast'); // will fade out the white DIV that covers the website.
	}

	searchFor() {
		const srch = this.searchRef.current.value == '' ? 'nothing' : this.searchRef.current.value;

		console.log('going to search for ' + srch + ' in ' + this.searchType.current.value);
		this.loadSearchResultsData(this.searchType.current.value, this.searchRef.current.value);
	}

	keydetect = () => {
		window.addEventListener('keydown', (e) => {
			//console.log(e.keyCode);
			//console.log(e.key);
			if (e.keyCode == 13) {
				console.log('fire away!');
				this.searchFor();
			}
		});
	};

	componentDidMount() {

		var logInForm = $('#login-content');
		logInForm.css("display", "block");

	}
	render() {
		return (
			<React.Fragment>
				<div>
					
				<div className="login-wrapper" id="login-content">
					<div className="login-content">
						<a href="#" className="close">x</a>
						<h3>TMDB Login</h3>
						<form /* method="post" action="login.php" */>
							{/* <div className="row">
								<label htmlFor="username">
									Username:
									<input type="text" name="username" id="username" placeholder="Hugh Jackman" pattern="^[a-zA-Z][a-zA-Z0-9-_\.]{8,20}$" required="required" />
								</label>
							</div>
						
							<div className="row">
								<label htmlFor="password">
									Password:
									<input type="password" name="password" id="password" placeholder="******" pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" required="required" />
								</label>
							</div> */}
							<div className="row">
								{/* <div className="remember">
									<div>
										<input type="checkbox" name="remember" value="Remember me"></input><span>Remember me</span>
									</div>
									<a href="#">Forget password ?</a>
								</div> */}
							</div>
						
						<div className="row">
							<button /* type="submit */ onClick={(e) => this.props.tmdbLogin(e)}>Login</button>
						</div>
						</form>
						<div className="row">
							{/* <p>Or via social</p>
							<div className="social-btn-2">
								<a className="fb" href="#"><i className="ion-social-facebook"></i>Facebook</a>
								<a className="tw" href="#"><i className="ion-social-twitter"></i>twitter</a>
							</div> */}
						</div>
					</div>
					</div>
					
					<header className='ht-header'>
						<div className='container'>
							<nav className='navbar navbar-default navbar-custom'>
								<div className='navbar-header logo'>
									<div className='navbar-toggle' data-toggle='collapse' data-target='#bs-example-navbar-collapse-1'>
										<span className='sr-only'>Toggle navigation</span>
										<div id='nav-icon1'>
											<span></span>
											<span></span>
											<span></span>
										</div>
									</div>
									<a href='/'>
										{/* <img className='logo' src='/images/logo1.png' alt='' width='119' height='58' /> */}
										<img className='logo' src='/images/891ca13dc164fca04e20d1e768f3a70b.png' alt='' width='232' />

										
									</a>
								</div>

								<div className='collapse navbar-collapse flex-parent' id='bs-example-navbar-collapse-1'>
									<ul className='nav navbar-nav flex-child-menu menu-left'>
										<li className='hidden'>
											<a href='#page-top'></a>
										</li>
										<li className='dropdown first'>
											<a href='/' className='btn btn-default dropdown-toggle lv1'>
												Home
											</a>
										</li>
										<li className='dropdown first'>
											<a href='/movies/pages/1/' className='btn btn-default dropdown-toggle lv1'>
												movies
											</a>
										</li>
										<li className='dropdown first'>
											<a href='/tv/pages/1/' className='btn btn-default dropdown-toggle lv1'>
												shows
											</a>
										</li>
										<li className='dropdown first'>
											<a onClick={(e) => this.props.getRandomMovie(e)} className='btn btn-default dropdown-toggle lv1'>
												Random Movie
											</a>
										</li>
										<li className='dropdown first'>
											<a href='/favourites/pages/1/' className='btn btn-default dropdown-toggle lv1'>
												favourites
											</a>
										</li>
										<li className='dropdown first'>
											<a href='/search/' className='btn btn-default dropdown-toggle lv1'>
												search
											</a>
										</li>
									</ul>
									<ul className="nav navbar-nav flex-child-menu menu-right">
										              
										{/* <li><a href="#">Help</a></li> */}
										<li className="loginLink"><a href="#">TMDB LOG In</a></li>
										{/* <li className="btn signupLink"><a href="#">sign up</a></li> */}
									</ul>
								</div>
							</nav>
						</div>
					</header>
				</div>
			</React.Fragment>
		);
	}
}

export default Header;
