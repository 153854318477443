/** @format */

import React from 'react';
import { withRouter } from 'react-router';

class Result extends React.Component {
	getPersonUriName = (name) => {
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = name
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		return tt;
	};

	colorKeyword(keyword) {
		var somecolors = ['6199d1', '4d8ccb', '3980c6', '3473b2', '2e669e', '28598a', '224d77', '1d4063', '17334f'];
		const rand = Math.floor(Math.random() * somecolors.length);
		const hex = somecolors[rand];

		var cssobj = {
			backgroundColor: '#' + hex,
			margin: '5px 5px 5px 0',
			display: 'inline-block',
			border: '1px solid #fff',
			color: 'yellow',
			padding: '2px 10px 2px 10px',
		};
		return cssobj;
	}
	roundDecimal = (float) => {
		const rounded = Math.round(float * 10) / 10;
		return rounded;
	};
	// event delegation for the Movies links.
	handleTVClick(e, id, titleString, year) {
		e.preventDefault();
		//alert('!');
		this.goToShowPage(id, titleString, year);
	}

	// reduce decimal to one place
	roundDecimal = (float) => {
		const rounded = Math.round(float * 10) / 10;
		return rounded;
	};

	goToShowPage = (id, titleString, year) => {
		console.log('CALLED!');
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		const rooturi = '';
		const page = '/tv/' + id + '/' + tt + '-' + year;
		//this.props.history.push(`${page}`);
		document.location = rooturi + page;
	};

	// event delegation for the Movies links.
	handleMovieClick(e, id, titleString, year) {
		e.preventDefault();
		this.goToMoviePage(id, titleString, year);
	}

	// make a random color for the placeholder link
	// for movies with no poster available.
	getRandomBgColor = () => {
		var somecolors = [
			'c238c2',
			'c23838',
			'614875',
			'2548a1',
			'25a14c',
			'9ba125',
			'a17a25',
			'a12525',
			'f0820c',
			'd61773',
			'ce17d4',
			'601ea6',
			'2a1fa6',
			'0bb8db',
			'0dd91e',
			'388c3f',
			'378a73',
			'd45137',
		];

		const rand = Math.floor(Math.random() * somecolors.length);
		const uriSegA = 'https://dummyimage.com/300x450/';
		const uriSegB = '/ffffff.png&text=Poster+Not+Available';
		return uriSegA + somecolors[rand] + uriSegB;
	};
	// take movie title, return URI friendly string.
	goToMoviePage = (id, titleString, year) => {
		//alert('!');
		const regex = / /gi;
		const regexDash = /---/gi;
		const regexspecial = /[:'()…,'!.?\][]/gi;
		const regexAmp = /&/gi;
		const regexSlash = /[/]/gi;
		const regexMisc = /½/gi;
		const regexSup2 = /[²]/gi;
		const regexSup3 = /[³]/gi;

		const tt = titleString
			.replace(regex, '-')
			.replace(regexDash, '-')
			.replace(regexspecial, '')
			.replace(regexAmp, 'and')
			.replace(regexSlash, '-')
			.replace(regexMisc, '-and-a-half')
			.replace(regexSup2, '-2')
			.replace(regexSup3, '-3')
			.toLocaleLowerCase();

		const rooturi = '';
		const page = '/movies/' + id + '/' + tt + '-' + year;
		//this.props.history.push(`${page}`);
		document.location = rooturi + page;
	};

	render() {
		if (this.props.details === null || this.props.details === undefined) {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='movie-item-style-2 movie-item-style-1'>loading</div>
				</React.Fragment>
			);
		} else {
			const {
				poster_path,
				title,
				vote_average,
				release_date,
				id,
				name,
				profile_path,
				first_air_date,
			} = this.props.details;

			return (
				<React.Fragment>
					{this.props.searchType === 'movie' && (
						<div className='movie-item-style-2 movie-item-style-1'>
							{poster_path === null && <img src={this.getRandomBgColor()} alt={title} />}
							{poster_path !== null && (
								<img
									src={'https://image.tmdb.org/t/p/w300/' + poster_path}
									alt={title}
									style={{ minHeight: '255px' }}
								/>
							)}
							<div className='hvr-inner'>
								{release_date !== undefined && (
									<a onClick={(e) => this.handleMovieClick(e, `${id}`, `${title}`, `${release_date.split('-')[0]}`)}>
										Read more <i className='ion-android-arrow-dropright'></i>
									</a>
								)}
							</div>
							<div className='mv-item-infor'>
								<h6>
									{release_date !== undefined && (
										<a
											style={{ cursor: 'pointer' }}
											onClick={(e) => this.handleMovieClick(e, `${id}`, `${title}`, `${release_date.split('-')[0]}`)}>
											{title}
										</a>
									)}
									<br />
									<p className='rate'>{release_date !== undefined && <span>{release_date.split('-')[0]}</span>}</p>
								</h6>
								<p className='rate'>
									<i className='ion-android-star'></i>
									<span>{this.roundDecimal(`${vote_average}`)}</span> /10
								</p>
							</div>
						</div>
					)}
					<div className='sb-it'>
						{this.props.searchType === 'keyword' && (
							<div className='movie-item-style-2 movie-item-style-1'>
								<p className='tags'>
									<span
										key={`keyword-${name}`}
										className='time'
										style={{
											display: 'inlineBlock',
										}}>
										<a style={this.colorKeyword()} href={`/search/keyword/${id}`}>
											{name}
										</a>
									</span>
								</p>
							</div>
						)}
					</div>
					{this.props.searchType === 'person' && (
						<div className='movie-item-style-2 movie-item-style-1'>
							{profile_path === null && <img src={this.getRandomBgColor()} alt={name} />}
							{profile_path !== null && (
								<img
									src={'https://image.tmdb.org/t/p/w300/' + profile_path}
									alt={name}
									style={{ minHeight: '255px' }}
								/>
							)}

							<div className='mv-item-infor'>
								<h6>
									<a href={'/people/' + id + '/' + this.getPersonUriName(name)}>{name}</a>
								</h6>
							</div>
						</div>
					)}
					{this.props.searchType === 'tv' && (
						<div>
							{first_air_date !== undefined && (
								<div className='movie-item-style-2 movie-item-style-1'>
									{poster_path === null && <img src={this.getRandomBgColor()} alt={name} />}
									{poster_path !== null && (
										<img
											src={'https://image.tmdb.org/t/p/w300/' + poster_path}
											alt={name}
											style={{ minHeight: '255px' }}
										/>
									)}

									<div className='hvr-inner'>
										<a
											style={{ cursor: 'pointer' }}
											onClick={(e) => this.handleTVClick(e, `${id}`, `${name}`, `${first_air_date.split('-')[0]}`)}>
											Read more <i className='ion-android-arrow-dropright'></i>
										</a>
									</div>

									<div className='mv-item-infor'>
										<h6>
											<a
												style={{ cursor: 'pointer' }}
												onClick={(e) => this.handleTVClick(e, `${id}`, `${name}`, `${first_air_date.split('-')[0]}`)}>
												{name}
											</a>
											<br />
											<p className='rate'>
												<span>{first_air_date.split('-')[0]}</span>
											</p>
										</h6>
										<p className='rate'>
											<i className='ion-android-star'></i>
											<span>{this.roundDecimal(`${vote_average}`)}</span> /10
										</p>
									</div>
								</div>
							)}
						</div>
					)}
				</React.Fragment>
			);
		}
	}
}

export default withRouter(Result);
