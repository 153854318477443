/** @format */

import React from 'react';
import Result from './Result';
import $ from 'jquery';

class SearchResults extends React.Component {
	countRef = React.createRef();

	state = {
		pageNum: 0,
	};

	// event delegation for the Movies amt select options.
	setChunkSize = (event) => {
		const amt = this.countRef.current.value;
		this.props.setChunkSize(amt);
	};

	increasePageNum = (event, int) => {
		var status = $('#status');
		var preloader = $('#preloader');
		preloader.css('display', 'block');
		status.css('display', 'block');
		this.props.increasePageNum(int);
	};

	decreasePageNum = (event, int) => {
		var status = $('#status');
		var preloader = $('#preloader');
		preloader.css('display', 'block');
		status.css('display', 'block');
		this.props.decreasePageNum(int);
	};

	setPageView = (event, type) => {
		this.props.setPageView(type);
	};

	componentDidMount() {
		
	}

	render() {
		return (
			<div style={{ paddingTop: '0px' }}>
				{this.props.listViewType === 'grid' && (
					<>
						{this.props.keyword !== 'default word' && <p>Search based on "{this.props.keyword.name}" keyword.</p>}
						{this.props.searchType !== 'keyword' && (
							<div className='flex-wrap-movielist mv-grid-fw'>
								{this.props.results.length === 0 && <p>Sorry, nothing was found.</p>}
								{Object.keys(this.props.results).map((key, index) => (
									<Result key={key} details={this.props.results[key]} index={index} searchType={this.props.searchType}>
										{key}
									</Result>
								))}
							</div>
						)}
						{this.props.searchType === 'keyword' && (
							<div className='flex-wrap-movielist mv-grid-fw'>
								{this.props.keywordresults.length === 0 && <p>Sorry, nothing was found.</p>}
								{Object.keys(this.props.keywordresults).map((key, index) => (
									<Result
										key={key}
										details={this.props.keywordresults[key]}
										keydetails={this.props.keywordresults[key]}
										index={index}
										searchType={this.props.searchType}>
										{key}
									</Result>
								))}
							</div>
						)}
					</>
				)}
			</div>
		);
	}
}

export default SearchResults;
