/** @format */

import React from 'react';
import $ from 'jquery';

class NotFound extends React.Component {
	componentDidMount() {
		var status = $('#status');
		var preloader = $('#preloader');
		var body = $('body');
		preloader.css('display', 'block');
		status.css('display', 'block');
		body.delay(1000).css({ overflow: 'visible' });
		preloader.delay(1000).fadeOut('fast'); // will fade out the white DIV that covers the website.
		status.fadeIn(); // will first fade out the loading animation
	}

	render() {
		return (
			<React.Fragment>
				<div className='page-single-2'>
					<div className='container'>
						<div className='row'>
							<div className='middle-content'>
								<a href='/'>
									<img className='md-logo' src='/images/891ca13dc164fca04e20d1e768f3a70b.png' alt='Rodflix Logo' />
								</a>
								<img src='/images/uploads/err-img.png' alt='Error Image' />
								<h1>Page not found!</h1>
								<a href='/' className='redbtn'>
									go home
								</a>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default NotFound;
