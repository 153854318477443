/** @format */

import React from 'react';

class SeriesSocial extends React.Component {
	// event delegation for the Movies links.

	render() {
		if (this.props.details === null || this.props.details === undefined) {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='movie-item-style-2 movie-item-style-1'>loading</div>
				</React.Fragment>
			);
		} else {
			const { facebook_id, instagram_id, twitter_id } = this.props.details;

			return (
				<React.Fragment>
					<div className='social-btn'>
						<a href='#' className='parent-btn'>
							<i className='ion-heart'></i> Add to Favorite
						</a>
						<div className='hover-bnt'>
							{facebook_id !== null &&
								twitter_id !== null &&
								instagram_id !== null && (
									<>
										<a href='#' className='parent-btn'>
											<i className='ion-android-share-alt'></i>Social
										</a>
										<div className='hvr-item'>
											{facebook_id !== null && (
												<a
													href={'https://facebook.com/' + facebook_id}
													target='_blank'
													className='hvr-grow'>
													<i className='ion-social-facebook'></i>
												</a>
											)}
											{twitter_id !== null && (
												<a
													href={'https://twitter.com/' + twitter_id}
													target='_blank'
													className='hvr-grow'>
													<i className='ion-social-twitter'></i>
												</a>
											)}
											{instagram_id !== null && (
												<a
													href={'https://instagram.com/' + instagram_id}
													target='_blank'
													className='hvr-grow'>
													<i className='ion-social-instagram'></i>
												</a>
											)}
										</div>
									</>
								)}
							{facebook_id == null &&
								twitter_id == null &&
								instagram_id == null && (
									<div className='hvr-item'>
										<a>
											<i></i>
										</a>
									</div>
								)}
						</div>
					</div>
				</React.Fragment>
			);
		}
	}
}

export default SeriesSocial;
